import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IProfileAssignmentNumbersFeatureState } from './interfaces';
import { paginationAdapter } from './profile-assignment-numbers.adapter';
import { featureKey } from './profile-assignment-numbers.reducer';

const selectProfileAssignmentNumbersState =
  createFeatureSelector<IProfileAssignmentNumbersFeatureState>(featureKey);
export const selectProfileAssignmentNumbersEntityState = createSelector(
  selectProfileAssignmentNumbersState,
  (state) => state.entityState
);
export const profileAssignmentNumbersPaginationSelectors = paginationAdapter.paginationSelectors(
  selectProfileAssignmentNumbersState,
  null,
  'pagination',
  'entityState',
  false
);
