import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { isString } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IQueryParams, StateService } from '@locumsnest/core';

import { IHospitalStaffBankUnavailabilityEntity } from '../../interfaces/api/hospital-staff-bank-unavailability-entity';
import { SetCollectionMessage } from './hospital-staff-bank-unavailability.messages';
import { HospitalStaffBankUnavailabilityPersistenceService } from './hospital-staff-bank-unavailability.persistence.service';
import { selectAllHospitalStaffBankUnavailabilities } from './hospital-staff-bank-unavailability.selectors';

@Injectable({
  providedIn: 'root',
})
export class HospitalStaffBankUnavailabilityService extends StateService<IHospitalStaffBankUnavailabilityEntity> {
  constructor(
    protected store: Store,
    protected persistenceService: HospitalStaffBankUnavailabilityPersistenceService
  ) {
    super();
  }

  getAll() {
    return this.store.pipe(select(selectAllHospitalStaffBankUnavailabilities));
  }

  loadByProfileId(id): Observable<Action> {
    return this.fetch(id) as Observable<SetCollectionMessage>;
  }

  fetch(profileId?: string) {
    if (isString(profileId)) {
      const queryParams: IQueryParams = {};
      queryParams['profile'] = profileId;
      return this.persistenceService
        .retrieve(queryParams)
        .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));
    }
    return this.persistenceService
      .retrieve()
      .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));
  }
}
