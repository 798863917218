import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isNil, isNumber, isString } from 'lodash-es';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { PaginatedStateService } from '@locumsnest/core';
import { Query } from '@locumsnest/core/src';

import { ExternalStaffingProviderService } from '../../external-staffing-provider/+state/external-staffing-provider.service';
import { IExternalStaffingCandidateDocumentEntity } from '../../interfaces/api/external-staffing-candidate-document-entity';
import { DocumentTypes, IPassportDocument } from '../../profile/+state/interfaces';
import { paginationAdapter } from './external-staffing-candidate-document.adapter';
import {
  DeleteOneMessage,
  externalStaffingCandidateDocumentPaginationMessages,
  ResetExternalStaffingCandidateDocumentPaginationMessage,
  UpsertExternalStaffingCandidateDocumentPageMessage,
  UpsertMultipleMessage,
  UpsertOneMessage,
} from './external-staffing-candidate-document.messages';
import { ExternalStaffingCandidateDocumentPersistenceService } from './external-staffing-candidate-document.persistence.service';
import {
  externalStaffingCandidateDocumentPaginationSelectors,
  getBidDocumentNamespace,
  selectExternalStaffingCandidateDocumentEntityState,
} from './external-staffing-candidate-document.selectors';

@Injectable({
  providedIn: 'root',
})
export class ExternalStaffingCandidateDocumentService extends PaginatedStateService<
  IExternalStaffingCandidateDocumentEntity,
  UpsertExternalStaffingCandidateDocumentPageMessage,
  ResetExternalStaffingCandidateDocumentPaginationMessage,
  UpsertMultipleMessage
> {
  constructor(
    protected store: Store,
    protected persistenceService: ExternalStaffingCandidateDocumentPersistenceService,
    protected externalStaffingProviderService: ExternalStaffingProviderService,
  ) {
    super();
  }
  get paginationMessages() {
    return externalStaffingCandidateDocumentPaginationMessages;
  }

  get paginationSelectors() {
    return externalStaffingCandidateDocumentPaginationSelectors;
  }

  get entityStateSelector() {
    return selectExternalStaffingCandidateDocumentEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get loadingMessages() {
    return paginationAdapter.getLoadingMessages();
  }

  initializeProviderDocumentsForProfile(profile: string, provider: number, trust: number) {
    return this.initializePagination(
      getBidDocumentNamespace(profile, provider, trust),
      {},
      { profile, provider, trust },
    );
  }

  getAllForBid(profileId: string, providerId: number, trustId: number) {
    return this.getAll().pipe(
      filter((x) => !!x),
      map((x) =>
        x.filter(
          (p) => p.profile === profileId && p.provider === providerId && p.trust === trustId,
        ),
      ),
    );
  }
  getAllForProfile(profileId: string, trustId: number) {
    return this.getAll().pipe(
      filter((x) => !!x),
      map((x) => x.filter((p) => p.profile === profileId && p.trust === trustId)),
    );
  }
  getPassPortDoc(document, provider?) {
    return {
      id: document.id,
      url: document.file,
      display: document.title,
      ownerName: provider?.name,
      expirationDate: document.expirationDate,
      type: DocumentTypes.certificate,
    };
  }
  getExternalStaffingCandidateDocuments(profileId: string, providerId: number, trustId: number) {
    return this.getAllForBid(profileId, providerId, trustId).pipe(
      map((documents) => documents.map((document) => this.getPassPortDoc(document))),
    );
  }

  getExternalStaffingCandidateDocumentsWithProvider(
    profileId: string,
    trustId: number,
  ): Observable<IPassportDocument[]> {
    return this.getAllForProfile(profileId, trustId).pipe(
      switchMap((documents) => {
        const docs = documents.map((document) =>
          this.externalStaffingProviderService
            .getOne(document.provider)
            .pipe(map((provider) => this.getPassPortDoc(document, provider))),
        );
        return combineLatest(docs);
      }),
    );
  }

  create(document: IExternalStaffingCandidateDocumentEntity<string>) {
    return this.persistenceService
      .create<
        IExternalStaffingCandidateDocumentEntity<string>,
        IExternalStaffingCandidateDocumentEntity<Date>
      >(document)
      .pipe(map((entity) => new UpsertOneMessage({ entity })));
  }

  update(document: IExternalStaffingCandidateDocumentEntity<string>) {
    return this.persistenceService
      .update<
        IExternalStaffingCandidateDocumentEntity<string>,
        IExternalStaffingCandidateDocumentEntity<Date>
      >(document.id, document)
      .pipe(map((entity) => new UpsertOneMessage({ entity })));
  }

  delete(id: number) {
    return this.persistenceService.delete<string>(id).pipe(map(() => new DeleteOneMessage({ id })));
  }

  fetch(query?: Query) {
    if (isNil(query)) {
      return this.loadAllPages('default', {});
    }
    if (isString(query) || isNumber(query))
      return this.persistenceService
        .retrieve(query)
        .pipe(map((entity) => new UpsertOneMessage({ entity })));
    return this.persistenceService
      .retrieve(query)
      .pipe(map(({ results }) => new UpsertMultipleMessage({ entities: results })));
  }
}
