import { Injectable } from '@angular/core';
import { LocumsNestEndpointConfig } from 'apps/hospital-admin/src/app/core/constants';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { IJobListingTags } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class JobListingTagsPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IJobListingTags
> {
  protected override readonly endpoint = 'jobListingTags';

  bulkDelete(queryParams: { ids: number[] }) {
    return this.delete<never>(
      '',
      {},
      {
        queryParams,
        controllerResource: 'jobListingTagsBulkDelete',
      },
    );
  }
}
