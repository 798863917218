import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';

import { IStaffbankRejectionReason } from './interfaces';
import { paginationAdapter } from './staff-bank-rejection-reason.adapter';

export const {
  ResetLoadingStateMessage,
  ResetPaginationMessage,
  SetLoadingStateMessage,
  UpsertMultiplePagesMessage,
  UpsertPageMessage,
} = paginationAdapter.getMessages();

export const paginationMessages = paginationAdapter.getMessages();
export class UpsertStaffbankRejectionReasonPageMessage extends UpsertPageMessage {}
export class UpsertStaffbankRejectionReasonMultiplePagesMessage extends UpsertMultiplePagesMessage {}
export class ResetStaffbankRejectionReasonPaginationMessage extends ResetPaginationMessage {}

export enum StaffbankRejectionReasonMessageTypes {
  SET_COLLECTION = '[StaffbankRejectionReason][Message] Set Collection',
  ADD_ONE = '[StaffbankRejectionReason][Message] Add One',
  UPDATE_ONE = '[StaffbankRejectionReason][Message] Update One',
  UPSERT_ONE = '[StaffbankRejectionReason][Message] Upsert One',
  DELETE_ONE = '[StaffbankRejectionReason][Message] Delete One',
  ADD_MULTIPLE = '[StaffbankRejectionReason][Message] Add All',
  DELETE_MULTIPLE = '[StaffbankRejectionReason][Message] Delete Many',
  UPSERT_MULTIPLE = '[StaffbankRejectionReason][Message] Upsert Many',
  UPDATE_MULTIPLE = '[StaffbankRejectionReason][Message] Update Many',
}

export class SetCollectionMessage implements Action {
  readonly type = StaffbankRejectionReasonMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IStaffbankRejectionReason[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = StaffbankRejectionReasonMessageTypes.ADD_ONE;
  constructor(public payload: { entity: IStaffbankRejectionReason }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = StaffbankRejectionReasonMessageTypes.UPSERT_ONE;
  constructor(public payload: { entity: IStaffbankRejectionReason }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = StaffbankRejectionReasonMessageTypes.ADD_MULTIPLE;
  constructor(public payload: { entities: IStaffbankRejectionReason[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = StaffbankRejectionReasonMessageTypes.UPSERT_MULTIPLE;
  constructor(public payload: { entities: IStaffbankRejectionReason[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = StaffbankRejectionReasonMessageTypes.UPDATE_ONE;
  constructor(public payload: { entity: Update<IStaffbankRejectionReason> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = StaffbankRejectionReasonMessageTypes.UPDATE_MULTIPLE;
  constructor(public payload: { entities: Update<IStaffbankRejectionReason>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = StaffbankRejectionReasonMessageTypes.DELETE_ONE;
  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = StaffbankRejectionReasonMessageTypes.DELETE_MULTIPLE;
  constructor(public payload: { ids: string[] }) {}
}

export type StaffbankRejectionReasonMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
