import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';

import { IHospitalProfileTagEntity } from '../../interfaces/api/hospital-profile-tag-entity';
import { paginationAdapter } from './hospital-profile-tag.adapter';

export const {
  ResetLoadingStateMessage,
  ResetPaginationMessage,
  SetLoadingStateMessage,
  UpsertMultiplePagesMessage,
  UpsertPageMessage,
} = paginationAdapter.getMessages();

export const paginationMessages = paginationAdapter.getMessages();

export class UpsertHospitalProfileTagPageMessage extends UpsertPageMessage {}
export class UpsertHospitalProfileTagMultiplePagesMessage extends UpsertMultiplePagesMessage {}
export class ResetHospitalProfileTagPaginationMessage extends ResetPaginationMessage {}

export enum HospitalProfileTagSignalTypes {
  LOAD_PROFILE_TAGS = '[HospitalProfileTag][Signal] Load Collection',
}
export enum HospitalProfileTagMessageTypes {
  SET_COLLECTION = '[HospitalProfileTag][Message] Set Collection',
  ADD_ONE = '[HospitalProfileTag][Message] Add One',
  UPDATE_ONE = '[HospitalProfileTag][Message] Update One',
  UPSERT_ONE = '[HospitalProfileTag][Message] Upsert One',
  DELETE_ONE = '[HospitalProfileTag][Message] Delete One',
  ADD_MULTIPLE = '[HospitalProfileTag][Message] Add All',
  DELETE_MULTIPLE = '[HospitalProfileTag][Message] Delete Many',
  UPSERT_MULTIPLE = '[HospitalProfileTag][Message] Upsert Many',
  UPDATE_MULTIPLE = '[HospitalProfileTag][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = HospitalProfileTagMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IHospitalProfileTagEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = HospitalProfileTagMessageTypes.ADD_ONE;
  constructor(public payload: { entity: IHospitalProfileTagEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = HospitalProfileTagMessageTypes.UPSERT_ONE;
  constructor(public payload: { entity: IHospitalProfileTagEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = HospitalProfileTagMessageTypes.ADD_MULTIPLE;
  constructor(public payload: { entities: IHospitalProfileTagEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = HospitalProfileTagMessageTypes.UPSERT_MULTIPLE;
  constructor(public payload: { entities: IHospitalProfileTagEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = HospitalProfileTagMessageTypes.UPDATE_ONE;
  constructor(public payload: { entity: Update<IHospitalProfileTagEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = HospitalProfileTagMessageTypes.UPDATE_MULTIPLE;
  constructor(public payload: { entities: Update<IHospitalProfileTagEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = HospitalProfileTagMessageTypes.DELETE_ONE;
  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = HospitalProfileTagMessageTypes.DELETE_MULTIPLE;
  constructor(public payload: { ids: string[] }) {}
}

export type HospitalProfileTagMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
