import { inject, Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import { HospitalOfficerService } from 'apps/hospital-admin/src/app/hospital-officer/+state/hospital-officer.service';
import { difference, orderBy } from 'lodash-es';
import { unbox } from 'ngrx-forms';
import {
  combineLatest,
  concat,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  startWith,
  switchMap,
} from 'rxjs';

import { PaginatedStateService } from '@locumsnest/core/src';
import { Time } from '@locumsnest/core/src/lib/helpers';
import { filterNotNil } from '@locumsnest/core/src/lib/ngrx/operators';

import { ITag, ITagEntity } from './interfaces';
import { ISelectedTag } from './interfaces/tag-form-state';
import {
  selectDeleteModeSelectedTagsControl,
  selectDeleteModeSelectedTagsValue,
  selectEligibilityWarningValue,
  selectHasChangesOnTags,
  selectInitialSelectedTagsValue,
  selectMaxTagsErrorValue,
  selectMyTagsDeleteModeValue,
  selectOrganisationTagsDeleteModeValue,
  selectSearchKeywordValue,
  selectSelectedTagsControl,
  selectSelectedTagsValue,
} from './tag-form/tag-form.selectors';
import {
  paginationMessages,
  ResetTagPaginationMessage,
  UpsertMultipleMessage,
  UpsertTagPageMessage,
} from './tag.messages';
import { TagPersistenceService } from './tag.persistence.service';
import { selectTagEntityState, tagPaginationSelectors } from './tag.selectors';

@Injectable({
  providedIn: 'root',
})
export class TagService extends PaginatedStateService<
  ITagEntity,
  UpsertTagPageMessage,
  ResetTagPaginationMessage,
  UpsertMultipleMessage
> {
  protected persistenceService = inject(TagPersistenceService);
  private hospitalOfficerService = inject(HospitalOfficerService);
  public readonly minSearchStringLength = 2;
  public readonly myTagsNamespace = 'my-tags';
  public readonly organisationTagsNamespace = 'organisation-tags';
  public readonly organisationSearchTagsNamespace = 'org-tag-search';

  get paginationMessages() {
    return paginationMessages;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return tagPaginationSelectors;
  }

  get entityStateSelector() {
    return selectTagEntityState;
  }

  fetch(search: string) {
    return this.initializePagination('default', {}, { search, pageSize: 50 });
  }

  loadByIds(ids: number[]) {
    return this.persistenceService
      .retrieve({ tag: ids })
      .pipe(map((res) => new UpsertMultipleMessage({ entities: res.results })));
  }

  createTag(display: string) {
    return this.persistenceService.create<
      { tag: Pick<ITag, 'display'> & { type: string } },
      ITagEntity
    >({
      tag: {
        display,
        type: 'job-listing',
      },
    });
  }

  loadMyTags() {
    return this.hospitalOfficerService.getAssigned().pipe(
      filterNotNil(),
      switchMap((hospitalOfficer) =>
        this.loadAllPages(this.myTagsNamespace, {}, { officer_id: hospitalOfficer.id }),
      ),
    );
  }

  loadOrganisationTags() {
    return this.hospitalOfficerService.getAssigned().pipe(
      filterNotNil(),
      switchMap((hospitalOfficer) =>
        this.initializePagination(
          this.organisationTagsNamespace,
          {},
          { officer_id__not: hospitalOfficer.id },
        ),
      ),
    );
  }

  loadMoreOrganisationTags() {
    return this.hospitalOfficerService.getAssigned().pipe(
      filterNotNil(),
      switchMap((hospitalOfficer) =>
        this.loadNext(this.organisationTagsNamespace, {}, { officer_id__not: hospitalOfficer.id }),
      ),
    );
  }

  getSearchFieldValue() {
    return this.store.pipe(select(selectSearchKeywordValue));
  }

  getSelectedTagsIds() {
    return this.store.pipe(
      select(selectSelectedTagsValue),
      map((tags) => unbox(tags)),
    );
  }

  getDeleteModeSelectedTagsIds() {
    return this.store.pipe(
      select(selectDeleteModeSelectedTagsValue),
      map((tags) => unbox(tags)),
    );
  }

  getInitialSelectedTagsIds() {
    return this.store.pipe(
      select(selectInitialSelectedTagsValue),
      map((tags) => unbox(tags)),
    );
  }

  getFormHasChangesOnSelectedTags(): Observable<boolean> {
    return this.store.pipe(select(selectHasChangesOnTags));
  }

  getMyTags(): Observable<ITagEntity[]> {
    return this.getAll(this.myTagsNamespace);
  }

  getMyTagsCount() {
    return this.getTotalCount(this.myTagsNamespace);
  }

  getHasMaxTagsError() {
    return this.store.pipe(select(selectMaxTagsErrorValue));
  }

  getShowEligibilityWarning() {
    return this.store.pipe(select(selectEligibilityWarningValue));
  }

  getMyTagsDeleteMode() {
    return this.store.pipe(select(selectMyTagsDeleteModeValue));
  }

  getOrganisationTagsDeleteMode() {
    return this.store.pipe(select(selectOrganisationTagsDeleteModeValue));
  }

  getOrganisationTags(): Observable<ITagEntity[]> {
    return this.getAll(this.organisationTagsNamespace);
  }

  getOrganisationTotalCount() {
    return this.getTotalCount(this.organisationTagsNamespace);
  }

  getDeleteMode() {
    return combineLatest([this.getMyTagsDeleteMode(), this.getOrganisationTagsDeleteMode()]).pipe(
      map(
        ([myTagsDeleteMode, organisationTagsDeleteMode]) =>
          myTagsDeleteMode || organisationTagsDeleteMode,
      ),
      distinctUntilChanged(),
    );
  }

  getTagsSelectedFormControl() {
    return this.getDeleteMode().pipe(
      switchMap((deleteMode) => {
        if (deleteMode) return this.store.pipe(select(selectDeleteModeSelectedTagsControl));
        return this.store.pipe(select(selectSelectedTagsControl));
      }),
    );
  }

  getHasDeleteModeWarning(jobListingTagsIds: number[]): Observable<boolean> {
    return this.getDeleteMode().pipe(
      switchMap((deleteMode) => {
        if (!deleteMode) return of(false);
        return combineLatest([
          this.getMyTagsDeleteMode().pipe(
            switchMap((myTagsDeleteMode) =>
              myTagsDeleteMode
                ? this.getMyTagsOptions(jobListingTagsIds)
                : this.getOrganisationTagsOptions(jobListingTagsIds),
            ),
          ),
          this.getDeleteModeSelectedTagsIds(),
        ]).pipe(
          map(([myTags, selectedTags]) =>
            myTags.some((tag) => tag.hasWarning && selectedTags.includes(tag.id)),
          ),
        );
      }),
    );
  }

  getMyTagsSearchResult() {
    return this.getSearchFieldValue().pipe(
      filter((x) => x.trim().length > this.minSearchStringLength),
      debounceTime(500),
      switchMap((value) =>
        this.getMyTags().pipe(
          map((myTags) =>
            myTags.filter((t) => t.tag.display.toLowerCase().includes(value.trim().toLowerCase())),
          ),
        ),
      ),
    );
  }

  getOrganisationTagsSearchResult() {
    return this.getAllAfterLoading(this.organisationSearchTagsNamespace);
  }

  noExactResult(): Observable<boolean> {
    return this.getSearchFieldValue().pipe(
      switchMap(() =>
        concat(
          of(false),
          combineLatest([
            this.getSearchFieldValue().pipe(debounceTime(1500)),
            this.getMyTagsSearchResult(),
            this.getOrganisationTagsSearchResult(),
            this.isLoading(this.organisationSearchTagsNamespace),
          ]).pipe(
            map(([value, myTags, organisationTags, loading]) => {
              if (!value || value.length <= this.minSearchStringLength || loading) return true;

              return (
                myTags
                  .map((t) => t.tag.display.toLowerCase())
                  .includes(value.trim().toLowerCase()) ||
                organisationTags
                  .map((t) => t.tag.display.toLowerCase())
                  .includes(value.trim().toLowerCase())
              );
            }),

            map((found) => !found),
            distinctUntilChanged(),
          ),
        ).pipe(distinctUntilChanged()),
      ),
    );
  }

  getMyTagsOptions(jobListingTagsIds: number[]): Observable<ISelectedTag[]> {
    return combineLatest([this.getMyTags(), this.getInitialSelectedTagsIds()]).pipe(
      map(([myTags, selectedTags]) => {
        const options = myTags.map((myTag) =>
          this.getISelectedTag(
            myTag,
            selectedTags.includes(myTag.tag.id),
            jobListingTagsIds.includes(myTag.tag.id),
          ),
        );

        return orderBy(options, ['isAdded', (tag) => tag.title.toLowerCase()], ['desc', 'asc']);
      }),
      startWith([]),
    );
  }

  getOrganisationTagsOptions(jobListingTagIdsLoaded: number[]): Observable<ISelectedTag[]> {
    return combineLatest([
      this.getMyTags(),
      this.getOrganisationTags(),
      this.getInitialSelectedTagsIds(),
      this.getEntityDict(),
    ]).pipe(
      map(([myTags, organisationTags, selectedTags, tagsDict]) => {
        const options = organisationTags.map((organisationTag) =>
          this.getISelectedTag(
            organisationTag,
            selectedTags.includes(organisationTag.tag.id),
            jobListingTagIdsLoaded.includes(organisationTag.tag.id),
          ),
        );

        jobListingTagIdsLoaded.forEach((loaded) => {
          const organisationTagExists = !!organisationTags.find((t) => t.tag.id === loaded);
          const myTagExists = !!myTags.find((t) => t.tag.id === loaded);
          if (!organisationTagExists && !myTagExists) {
            const tagEntity = tagsDict[loaded];
            if (tagEntity) {
              const selectedTag = this.getISelectedTag(tagEntity, true, true);
              options.push(selectedTag);
            }
          }
        });

        return orderBy(options, ['isAdded', (tag) => tag.title.toLowerCase()], ['desc', 'asc']);
      }),
      startWith([]),
    );
  }

  getMyTagsSearchResultOptions() {
    return combineLatest([
      this.getMyTagsSearchResult(),
      this.getInitialSelectedTagsIds(),
      this.getSelectedTagsIds(),
    ]).pipe(
      map(([myTags, selectedTags, selectedTagIds]) => {
        const tags: ISelectedTag[] = [];

        myTags.forEach((myTag) => {
          const isAdded = selectedTags.includes(myTag.tag.id);
          if (!isAdded)
            tags.push(this.getISelectedTag(myTag, selectedTagIds.includes(myTag.tag.id)));
        });

        return tags;
      }),
    );
  }

  getOrganisationTagsSearchResultOptions() {
    return combineLatest([
      this.getOrganisationTagsSearchResult(),
      this.getInitialSelectedTagsIds(),
      this.getSelectedTagsIds(),
    ]).pipe(
      map(([organisationTags, selectedTags, selectedTagIds]) => {
        const tags: ISelectedTag[] = [];

        organisationTags.forEach((organisationTag) => {
          const isAdded = selectedTags.includes(organisationTag.tag.id);
          if (!isAdded)
            tags.push(
              this.getISelectedTag(
                organisationTag,
                selectedTagIds.includes(organisationTag.tag.id),
              ),
            );
        });

        return tags;
      }),
    );
  }

  deleteTags(ids: number[]) {
    return this.persistenceService.bulkDelete({ ids });
  }

  tagsToBeFetched(jobListingTagIdsLoaded: number[]) {
    return combineLatest([
      this.getAll(),
      this.getAllAfterLoading(this.myTagsNamespace),
      this.getAllAfterLoading(this.organisationTagsNamespace),
    ]).pipe(
      map(([tagEntities]) => {
        const ids = tagEntities.map((entity) => entity.tag.id);
        return difference(jobListingTagIdsLoaded, ids);
      }),
    );
  }

  private getISelectedTag(
    tagEntity: ITagEntity,
    isAdded = false,
    hasWarning = false,
  ): ISelectedTag {
    const { tag, createdAt } = tagEntity;

    return {
      id: tag.id,
      // 0 or 1 day diff
      isNew: Time.getDifferenceInDaysFromNow(createdAt) >= -1,
      title: tag.display,
      value: tag.display.trim().toLowerCase(),
      isAdded,
      hasWarning,
    };
  }
}
