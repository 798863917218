import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import {
  MessageableFactory,
  SignalableFactory,
  createPaginatedStateAdapter,
} from '@locumsnest/core/src';
import { IStaffBankMembershipRequestEntity } from '../../interfaces/api/staff-bank-membership-request-entity';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';

export const adapter: EntityAdapter<IStaffBankMembershipRequestEntity> =
  createEntityAdapter<IStaffBankMembershipRequestEntity>({});

export const messageableFactory = MessageableFactory.forFeature<'StaffBankMembershipRequest'>(
  'StaffBankMembershipRequest'
);
export const signalableFactory = SignalableFactory.forFeature<'StaffBankMembershipRequest'>(
  'StaffBankMembershipRequest'
);
export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const loadingAdapter =
  createLoadingStateAdapter<'StaffBankMembershipRequest'>(messageableFactory);

export const initialState = adapter.getInitialState({
  selectedStaffBankMembershipRequestId: null,
});
