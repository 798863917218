import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { IProfileStatisticsEntity } from '../../interfaces/api/profile-statistics-entity';

export enum ProfileStatisticsSignalTypes {
  LOAD_PROFILE_STATISTICS = '[ProfileStatistics][Signal] Load Collection',
}

export enum ProfileStatisticsMessageTypes {
  ADD_ONE = '[ProfileStatistics][Message] Add One',
  UPDATE_ONE = '[ProfileStatistics][Message] Update One',
  UPSERT_ONE = '[ProfileStatistics][Message] Upsert One',
  DELETE_ONE = '[ProfileStatistics][Message] Delete One',
}

export class AddOneMessage implements Action {
  readonly type = ProfileStatisticsMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IProfileStatisticsEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = ProfileStatisticsMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IProfileStatisticsEntity }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = ProfileStatisticsMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IProfileStatisticsEntity> }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = ProfileStatisticsMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export type ProfileStatisticsMessages =
  | AddOneMessage
  | UpsertOneMessage
  | UpdateOneMessage
  | DeleteOneMessage;
