import { adapter, initialState, paginationAdapter } from './hospital-profile-label.adapter';
import {
  HospitalProfileLabelMessages,
  HospitalProfileLabelMessageTypes as MessageTypes,
} from './hospital-profile-label.messages';
import { IHospitalProfileLabelEntityState } from './interfaces';

export * from './interfaces';

export const featureKey = 'hospitalProfileLabels';

export const paginationReducer = paginationAdapter.createReducer();

export function entityReducer(
  state = initialState,
  action: HospitalProfileLabelMessages
): IHospitalProfileLabelEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.entity, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.entity, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.entities, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.entities, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }
    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  paginationAdapter.getEntitySelectors();
