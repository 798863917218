import { MessageableFactory } from '@locumsnest/core/src/lib/ngrx';
import { IProfileSearchFormState } from '../interfaces/profile-search-form-state';
// initialization of mixin factory

const actionableFactory = MessageableFactory.forFeature<'ProfessionalRegistrationForm'>(
  'ProfessionalRegistrationForm'
);
export class InitializeProfessionalRegistrationFormMessage extends actionableFactory.create<
  'Initialize',
  { profileSearchFormState?: Partial<IProfileSearchFormState> }
>('Initialize') {}

export type ProfileSearchFormMessages = InitializeProfessionalRegistrationFormMessage;
