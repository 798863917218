import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IPayRateTypeEntity } from '../../interfaces/api/pay-rate-type-entity';

@Injectable({
  providedIn: 'root',
})
export class PayRateTypePersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IPayRateTypeEntity
> {
  protected readonly endpoint = 'payRateType';
}
