import { createSelector } from '@ngrx/store';

import { selectApplicationState } from './../application.selectors';

export const selectListState = createSelector(selectApplicationState, (state) => state.listState);

export const selectDeclineFormState = createSelector(
  selectListState,
  (state) => state.declineForms
);

export const selectApproveFormState = createSelector(
  selectListState,
  (state) => state.approveForms
);

export const selectDeclineKeys = createSelector(selectListState, (state) =>
  Object.keys(state.declineForms).length ? Object.keys(state.declineForms) : null
);

export const selectSelectedAdjacentApplicationRows = createSelector(
  selectListState,
  (state) => state.selectedAdjacentApplicationRows
);
