import { fromJS } from 'immutable';
import {
  cloneDeep,
  flatMap,
  get,
  groupBy,
  isEmpty,
  isNil,
  keys,
  maxBy,
  minBy,
  pick,
  set,
  sortBy,
  transform,
  values,
} from 'lodash-es';
import {
  AbstractControlState,
  box,
  Boxed,
  FormArrayState,
  FormControlState,
  FormGroupState,
  unbox,
} from 'ngrx-forms';
import { v5 as uuidv5 } from 'uuid';

import { IEnumeration } from '@locumsnest/core/src';
import { Time } from '@locumsnest/core/src/lib/helpers';
import { getFilesArrayFromFile } from '@locumsnest/core/src/lib/helpers/file';
import {
  DATE_TIME_PICKER_FORMAT,
  DATE_TIME_SERVER_ACCEPT_FORMAT,
} from '@locumsnest/core/src/lib/types/constants';

import { JOB_LISTING_FORM_VERSION, UUIDV5_NAMESPACE } from '../../../core/constants';
import { IGradeRateEntity } from '../../../interfaces/api/grade-rate-entity';
import { IJobListingEntity } from '../../../interfaces/api/job-listing-entity';
import {
  IJobListingTemplateEntity,
  IJobListingTemplatePayload,
  IJobListingTemplatePayloadV1,
} from '../../../interfaces/api/job-listing-template-entity';
import { IPensionCategoryEntity } from '../../../interfaces/api/pension-category-entity';
import { IProfessionSpecialtyEntity } from '../../../interfaces/api/profession-specialty-entity';
import { IGradesCellingRates } from '../../../interfaces/pay-rate-types';
import {
  IExtendedJobListingFormWizardState,
  IFlatRateFormState,
  IJobFragmentFormState,
  IJobListingExtendedGradeFormState,
  IJobListingFormWizardState,
  IPeriodFormState,
  IShiftCreationFormState,
  IShiftSchedulerFormState,
  ITimeFragmentFormState,
} from '../interfaces/job-listing-form-state';

export const formatTimestamp = (timestamp: string | number) =>
  Time.getMoment(timestamp).format(DATE_TIME_SERVER_ACCEPT_FORMAT);

export const getFormStateForKey =
  <F>() =>
  (state: F): FormGroupState<IJobListingFormWizardState> =>
    state['key'];
export const getGrades = (form: FormGroupState<IJobListingFormWizardState>) =>
  form.value.gradesSection.grades;
export const getProfessionSpecialtyValue = (form: FormGroupState<IJobListingFormWizardState>) =>
  form.value.shiftCreation.professionSpecialty;
export const getProfessionValue = (form: FormGroupState<IJobListingFormWizardState>) =>
  form.value.shiftCreation.profession;
export const getSiteValue = (form: FormGroupState<IJobListingFormWizardState>) =>
  form.value.shiftCreation.site;
export const getJobListingGradeValues = (form: FormGroupState<IJobListingFormWizardState>) =>
  form.value.gradesSection.grades.map((listingGrade) => listingGrade.grade);
export const getFormVacancyReason = (form: FormGroupState<IJobListingFormWizardState>) =>
  form.value.shiftCreation.reasonForVacancy;
export const getFormId = (state: FormGroupState<IJobListingFormWizardState>) =>
  state.value.shiftCreation.id;
export const getCostCentreNumberValue = (form: FormGroupState<IJobListingFormWizardState>) =>
  form.value.shiftScheduler.costCentreNumber;

export const getCurrentJobListingFormVersion = JOB_LISTING_FORM_VERSION.currentVersion;

const parseTemplateListingTimeList = (time: string) =>
  time
    ? 'T' +
      Time.getMoment(Time.getDateTime(Time.getDate(), time))
        .format(DATE_TIME_PICKER_FORMAT)
        .split('T')[1]
    : Time.getMoment().startOf('date').format(DATE_TIME_PICKER_FORMAT);

const parseListingTimes = (time: moment.MomentInput) =>
  'T' + Time.getMoment(time).format(DATE_TIME_SERVER_ACCEPT_FORMAT).split('T')[1];

const composeTemplate = (jobListingTemplate: IJobListingTemplatePayload<string>) => ({
  ...jobListingTemplate,
  applicationDeadline: null,
  grades: jobListingTemplate.grades.map((grade) => ({
    ...grade,
    jobFragments: grade.jobFragments.map((jobFragment) => {
      const { fromTime, toTime } = jobFragment.timeFragment;

      return {
        ...jobFragment,
        timeFragment: {
          id: uuidv5(`${fromTime}-${toTime}`, UUIDV5_NAMESPACE.timeFragment),
          fromTime: +fromTime,
          toTime: +toTime,
        },
      };
    }),
  })),
});

export const transformedJobListingTemplateBeforeLoadV1ToV2 = (
  jobListingTemplate: IJobListingTemplatePayloadV1,
) => {
  const endTime = parseTemplateListingTimeList(jobListingTemplate.times[0].endTime);
  const startTime = parseTemplateListingTimeList(jobListingTemplate.times[0].startTime);

  return composeTemplate({ ...jobListingTemplate, startTime, endTime });
};

export const transformedJobListingTemplateBeforeLoadV2 = (
  jobListingTemplate: IJobListingTemplatePayload,
) => {
  const endTime = parseListingTimes(jobListingTemplate.endTime);
  const startTime = parseListingTimes(jobListingTemplate.startTime);

  return composeTemplate({ ...jobListingTemplate, startTime, endTime });
};

const templateAdapters = {
  v1: { v2: transformedJobListingTemplateBeforeLoadV1ToV2 },
  v2: { v2: transformedJobListingTemplateBeforeLoadV2 },
};

export const transformJobListingTemplateBeforeLoad = (va: IJobListingTemplateEntity) => {
  if (va.version === 'v2')
    return templateAdapters[va.version][getCurrentJobListingFormVersion](va.jobListing);
  return templateAdapters[va.version][getCurrentJobListingFormVersion](va.jobListing);
};

export const isCustomApplicationDeadLine = (startTime: Date, applicationDeadline: Date) =>
  applicationDeadline < startTime;

export const getEntityTimeFragment = ({ id, fromTime }) => ({
  id,
  fromTime: +fromTime,
});

export const getEntityTimeFragments = (entityState) =>
  entityState.grades.reduce((accumulator: { [id: string]: ITimeFragmentFormState }, grade) => {
    const timeFragments = grade.jobFragments.reduce(
      (innerAccumulator, jobFragment) => ({
        ...innerAccumulator,
        [jobFragment.timeFragment.id]: getEntityTimeFragment(jobFragment.timeFragment),
      }),
      {},
    );
    return {
      ...accumulator,
      ...timeFragments,
    };
  }, {});

export const getEntityFlatRateEnabled = (entityFormState: IJobListingFormWizardState) =>
  !!entityFormState.gradesSection.grades.filter((grade) => get(grade, 'flatRate.rate')).length;

export const getEntityFile = (entityState: IJobListingEntity | IJobListingTemplatePayload) => {
  const { files } = entityState;
  if (isEmpty(files)) {
    return '';
  }
  return JSON.stringify({
    id: files[0].id,
    name: files[0].title,
    base64EncodedFile: files[0].file,
  });
};

export const getNormalizedEntityFlatRateFormState = (flatRate): IFlatRateFormState =>
  isNil(flatRate) ? { rate: null, rateCurrency: 'GBP' } : flatRate;

export const getTotalWorkingHours = (formState: FormGroupState<IShiftSchedulerFormState>) =>
  Time.getDurationAsHours(formState.value.startTime, formState.value.endTime) || 0;

export const isEmploymentPeriodInThePast = (startTime: string | number | Date) =>
  Time.getDate(startTime) < Time.getDate();

export const isRepeatingDateInPast = (
  repetitionDates: Boxed<string[]>,
  isRepeating: boolean,
  startTime: string,
) =>
  isRepeating
    ? unbox(repetitionDates).some((date) => {
        const fullDate = Time.getTimeFromDateAndSetToOtherDate(startTime, date);
        return isEmploymentPeriodInThePast(fullDate.toISOString());
      })
    : false;

export const isEmploymentPeriodInvalid = (
  state: FormGroupState<IShiftSchedulerFormState>,
  workingHoursLimit: number,
) => {
  const totalWorkingHoursInSec = getTotalWorkingHours(state) * 3600;
  const { startTime } = state.value;
  const { repetitionDates, isRepeating } = state.value;
  return (
    totalWorkingHoursInSec > workingHoursLimit ||
    totalWorkingHoursInSec <= 0 ||
    (!state.value.consentBackdatedShifts &&
      (isEmploymentPeriodInThePast(startTime) ||
        isRepeatingDateInPast(repetitionDates, isRepeating, startTime)))
  );
};

export const isApplicationDeadlineDateTimeInValid = (
  state: FormGroupState<IShiftSchedulerFormState>,
) => {
  const startTime = state.value.startTime;
  const { applicationDeadline } = state.value;
  const formatStartTime = Time.getMomentFromDateTimePickerFormat(startTime);

  return Time.isValidMoment(formatStartTime)
    ? +Time.getMomentFromDateTimePickerFormat(applicationDeadline) >
        +Time.getMomentFromDateTimePickerFormat(startTime)
    : true;
};

export const isRequiredDropdownSelect = (
  formState: FormGroupState<IShiftCreationFormState>,
  dropdownOptionName: string,
) => {
  const selectOption = formState.value[dropdownOptionName];
  return selectOption === 'null' || isNil(selectOption) || selectOption === '';
};

export const checkIfPensionCategoryIsRequired = (
  formState: FormGroupState<IExtendedJobListingFormWizardState>,
) =>
  formState.value.shiftCreation.pensionCategory === null &&
  formState.userDefinedProperties['hospitalSectorCode'] === 'PRIMARY_CARE';

export const isSectionDetailsInvalid = (formState: FormGroupState<IJobListingFormWizardState>) => {
  const { title, professionSpecialty, site } = formState.controls.shiftCreation.controls;
  const { startTime, endTime, applicationDeadline, repetitionDates } =
    formState.controls.shiftScheduler.controls;
  return (
    startTime.isInvalid ||
    endTime.isInvalid ||
    title.isInvalid ||
    professionSpecialty.isInvalid ||
    site.isInvalid ||
    applicationDeadline.isInvalid ||
    repetitionDates.isInvalid
  );
};

export const isSectionDescriptionInvalid = (
  formState: FormGroupState<IJobListingFormWizardState>,
) => {
  const { reasonForVacancy, details, file } = formState.controls.shiftCreation.controls;
  const { costCentreNumber } = formState.controls.shiftScheduler.controls;

  return (
    reasonForVacancy.isInvalid || details.isInvalid || costCentreNumber.isInvalid || file.isInvalid
  );
};

export const getIsExternalJobListingMode = (
  formState: FormGroupState<IJobListingFormWizardState>,
) => !isNil(formState.value.shiftCreation.externalJobListingId);

const gradeSectionHasNestedFieldsInvalid = (
  grades: FormGroupState<IJobListingExtendedGradeFormState>[],
) => {
  let hasNestedFieldsInvalid = false;
  for (let i = 0; i < grades['length']; i++) {
    const gradeObj = grades[i];
    if (gradeObj['controls'].grade.isEnabled) {
      if (gradeObj.isInvalid) {
        hasNestedFieldsInvalid = true;
      }
    }
  }
  return hasNestedFieldsInvalid;
};

export const getGrateRates = (gradeRates: IGradeRateEntity[]): IGradesCellingRates[] =>
  gradeRates.map((gradeRate) => ({
    grade: gradeRate.grade,
    celling: +gradeRate.salary,
  }));

export const getHasTimeFragments = (
  formState: FormGroupState<IExtendedJobListingFormWizardState>,
): boolean =>
  !!keys(get(formState.controls.shiftScheduler, 'controls.timeFragments.value', {})).length;

export const getProfession = (formState: FormGroupState<IJobListingFormWizardState>) =>
  get(formState.controls.shiftCreation, 'controls.profession.value');

export const getEmploymentPeriod = (
  formState: FormGroupState<IExtendedJobListingFormWizardState>,
): IPeriodFormState => {
  const period = {
    startTime: formState.controls.shiftScheduler.controls.startTime,
    endTime: formState.controls.shiftScheduler.controls.endTime,
  };
  return period;
};

export const getMinTimeFragment = (
  timeFragments: FormGroupState<{ [id: string]: ITimeFragmentFormState }>,
) => {
  const timeFragmentDict = timeFragments.value;
  return minBy(keys(timeFragmentDict), function (o) {
    return Time.getMoment(timeFragmentDict[o].fromTime);
  });
};

export const getDefaultApplicationDeadline = (
  formState: FormGroupState<IExtendedJobListingFormWizardState>,
): string => {
  const employmentPeriod = getEmploymentPeriod(formState);
  return employmentPeriod.startTime.value
    ? formatTimestamp(employmentPeriod.startTime.value)
    : null;
};

export const descriptionAndFiles = ([formState, enumeration]: [
  FormGroupState<IExtendedJobListingFormWizardState>,
  IEnumeration[],
]) => {
  const { value } = formState;
  const { file, details, reasonForVacancy } = value.shiftCreation;
  const { costCentreNumber } = value.shiftScheduler;
  const fileName = file && JSON.parse(file).name;
  return {
    fileName,
    details,
    reasonForVacancy: get(
      enumeration.find((x) => x.val === reasonForVacancy),
      'display',
    ),
    costCentreNumber,
  };
};

export const isFormDisabled = ([formState, isFormInEditMode]: [
  FormGroupState<IExtendedJobListingFormWizardState>,
  boolean,
]) =>
  isEmploymentPeriodInThePast(formState.value.shiftScheduler.startTime) &&
  isFormInEditMode &&
  !formState.controls.shiftScheduler.controls.startTime.isDirty &&
  !formState.controls.shiftScheduler.controls.endTime.isDirty;

export const getPensionCategory = ([formState, pensionCategories]: [
  FormGroupState<IExtendedJobListingFormWizardState>,
  IPensionCategoryEntity[],
]) => {
  const { value } = formState;
  const { pensionCategory } = value.shiftCreation;
  const selectedPensionCategory = pensionCategories.find((x) => x.val === pensionCategory);

  return selectedPensionCategory;
};

export const getJobListingGradesValue = (
  formState: FormGroupState<IExtendedJobListingFormWizardState>,
) => formState.controls.gradesSection.controls.grades.value;

export const getJobListingGradesEntityValue = (
  formState: FormGroupState<IExtendedJobListingFormWizardState>,
  formatEntityTimestamp: (t: number) => Date | string = formatTimestamp,
) => {
  const grades = getJobListingGradesValue(formState);
  return fromJS(grades)
    .map((grade) => {
      if (grade) {
        const jobFragments = grade.get('jobFragments').map((jf) => {
          const fromTime = formatEntityTimestamp(jf.getIn(['timeFragment', 'fromTime']));
          const toTime = formatEntityTimestamp(jf.getIn(['timeFragment', 'toTime']));
          jf = jf.setIn(['timeFragment', 'fromTime'], fromTime);
          jf = jf.setIn(['timeFragment', 'toTime'], toTime);
          return jf;
        });
        return grade.set('jobFragments', jobFragments);
      }
    })
    .toJS();
};

export const getJobListingGrades = (
  formState: FormGroupState<IExtendedJobListingFormWizardState>,
) => get(formState, 'controls.gradesSection.controls.grades.controls');

export const getJobListingGrade =
  (gradeId: number) => (formState: FormGroupState<IExtendedJobListingFormWizardState>) => {
    const jobListingGradeControls = getJobListingGrades(formState);
    return jobListingGradeControls.find((g) => get(g, 'controls.grade.value') === gradeId);
  };

export const getHasJobListingGrades = (
  formState:
    | FormGroupState<IExtendedJobListingFormWizardState>
    | FormGroupState<IJobListingFormWizardState>,
): boolean => !!get(formState, 'controls.gradesSection.controls.grades.controls.length');

export const getHasFlatRate = (
  formState: FormGroupState<IExtendedJobListingFormWizardState>,
): boolean => {
  const jobListingGradeControls = get(formState, 'controls.gradesSection.controls.grades.controls');
  return !!jobListingGradeControls.find((g) => get(g, 'controls.flatRate.isEnabled'));
};

export const getTimeFragments = (
  formState:
    | FormGroupState<IExtendedJobListingFormWizardState>
    | FormGroupState<IJobListingFormWizardState>,
): FormGroupState<{ [id: string]: ITimeFragmentFormState }> =>
  get(formState.controls.shiftScheduler, 'controls.timeFragments');

export const getTemplateJobListing = (
  jobListingTemplates: IJobListingTemplateEntity[],
  jobListingTemplateId: number,
) => {
  const template = jobListingTemplates.find((t) => t.id === jobListingTemplateId);
  if (!isNil(template)) {
    return template.jobListing;
  }
  return null;
};

// TODO: FIX RETURN AND INPUT TYPES
export const getTemplateFromEntity = (
  jobListing: IJobListingTemplateEntity['jobListing'] | IJobListingEntity,
) => {
  jobListing = cloneDeep(jobListing);
  return pick(jobListing, [
    'applicationDeadline',
    'availablePositions',
    'costCentreNumber',
    'details',
    'endDate',
    'endTime',
    'extendedHours',
    'extraEmails',
    'files',
    'grades',
    'listingType',
    'pensionCategory',
    'profession',
    'site',
    'specialty',
    'startDate',
    'startTime',
    'times',
    'title',
    'nonResidentOnCall',
  ]) as any;
};

export const getTemplate = (
  jobListingTemplates: IJobListingTemplateEntity[],
  jobListingTemplateId: number,
) => {
  const template = jobListingTemplates.find((t) => t.id === jobListingTemplateId);

  if (!isNil(template.jobListing)) {
    return { ...template, jobListing: getTemplateFromEntity(template.jobListing) };
  }
  return null;
};

export const getTimeFragmentsOutOfRange = (
  formState: FormGroupState<IExtendedJobListingFormWizardState>,
) => {
  const timeFragmentDict = getTimeFragments(formState).value;
  const startTime = +Time.getMoment(formState.value.shiftScheduler.startTime);
  const endTime = +Time.getMoment(formState.value.shiftScheduler.endTime);

  return Object.values(timeFragmentDict)
    .filter(
      (timeFragment) => !(startTime < timeFragment.fromTime && timeFragment.fromTime < endTime),
    )
    .map((timeFragment: ITimeFragmentFormState) => timeFragment.id);
};

export const getMaxTimeFragment = (
  timeFragments: FormGroupState<{ [id: string]: ITimeFragmentFormState }>,
) => {
  const timeFragmentDict = timeFragments.value;
  const maxKey = maxBy(keys(timeFragmentDict), function (o) {
    return Time.getMoment(timeFragmentDict[o].fromTime);
  });
  return timeFragmentDict[maxKey];
};

export const getJobFragments = (
  endTime: FormControlState<string>,
  timeFragments: FormGroupState<{ [id: string]: ITimeFragmentFormState }>,
  jobFragments: FormArrayState<IJobFragmentFormState<number | string>>,
) => {
  const timeFragmentsArray: AbstractControlState<ITimeFragmentFormState>[] = sortBy(
    values(timeFragments.controls),
    (tf) => get(tf, 'controls.fromTime.value'),
  );

  return jobFragments.controls.map((jobFragment) => {
    const newJobFragment = cloneDeep(jobFragment);
    const timeFragmentId = get(jobFragment, 'controls.timeFragment.value');
    const timeFragment = cloneDeep(timeFragments.controls[timeFragmentId]);

    set(newJobFragment, 'controls.timeFragment', timeFragment);
    set(
      newJobFragment,
      'value.timeFragment.fromTime',
      get(timeFragment, 'controls.fromTime.value'),
    );

    if (timeFragment) {
      const fromTimeIndex = timeFragmentsArray.findIndex(
        ({ value }) => value.fromTime === timeFragment.value.fromTime,
      );
      const toTimeIsEndTime = fromTimeIndex >= timeFragmentsArray.length - 1;

      const toTime = toTimeIsEndTime
        ? endTime
        : get(timeFragmentsArray[fromTimeIndex + 1], 'controls.fromTime');

      let toTimeValue: number;
      const isValidMoment = Time.isValidMoment(toTime.value);

      if (isValidMoment) toTimeValue = +Time.getMoment(toTime.value);
      // format is THH:mm (loaded from template)
      else toTimeValue = +Time.getMoment(Time.formatDBDate() + toTime.value);

      const newToTime = cloneDeep(toTime);
      set(newToTime, 'value', toTimeValue);
      set(newJobFragment, 'controls.timeFragment.controls.toTime', newToTime);
      set(newJobFragment, 'value.timeFragment.toTime', toTimeValue);
      set(newJobFragment, 'controls.timeFragment.value.toTime', toTimeValue);
    }

    return newJobFragment;
  });
};

export const isSectionGradesInvalid = (
  formState: FormGroupState<IExtendedJobListingFormWizardState>,
) => {
  const { grades } = formState.controls.gradesSection.controls;
  const gradesControls = grades['controls'].filter((x) => x.controls.grade.isDisabled === false);
  const isGradesNestedFieldsInvalid = gradeSectionHasNestedFieldsInvalid(gradesControls);
  const isPensionSectionRequiredInvalid = checkIfPensionCategoryIsRequired(formState);

  return (
    gradesControls.length === 0 || isGradesNestedFieldsInvalid || isPensionSectionRequiredInvalid
  );
};

export const getEntityGrades = (entityState: IJobListingEntity | IJobListingTemplatePayload) =>
  entityState.grades.map((grade) => ({
    ...grade,
    flatRate: getNormalizedEntityFlatRateFormState(grade.flatRate),
    jobFragments: grade.jobFragments.map((jobFragment) => ({
      ...jobFragment,
      timeFragment: jobFragment.timeFragment.id,
    })),
  }));

export const getEntity =
  (
    formatEntityTimestamp: (t: number) => Date | string = formatTimestamp,
    conversationProperties = {},
  ) =>
  (formState: FormGroupState<IExtendedJobListingFormWizardState>): IJobListingEntity<Date> => {
    const {
      applicationDeadline,
      startTime,
      endTime,
      availablePositions,
      extendedHours,
      repetitionDates,
      isRepeating,
      remainingPositionsToFill,
      costCentreNumber,
      costCentreNumberChange,
    } = cloneDeep(formState.value.shiftScheduler);

    const {
      id,
      title,
      listingType,
      professionSpecialty,
      site,
      roster,
      details,
      detailsChange,
      reasonForVacancy,
      reasonForVacancyChange,
      extraEmails,
      file,
      pensionCategory,
      externalJobListingId,
      published,
      crossCoveringProfessionSpecialties,
      nonResidentOnCall,
    } = cloneDeep(formState.value.shiftCreation);

    // eslint-disable-next-line max-len
    const { shiftEscalated, shiftEscalatedForAgencies } = cloneDeep(formState.value.gradesSection);

    const listing = {
      id,
      title,
      startTime: Time.getDate(startTime),
      endTime: Time.getDate(endTime),
      applicationDeadline: Time.getDate(applicationDeadline),
      listingType,
      professionSpecialty,
      site,
      roster,
      details,
      detailsChange,
      availablePositions: isRepeating ? 1 : +availablePositions,
      costCentreNumber,
      costCentreNumberChange,
      reasonForVacancy,
      reasonForVacancyChange,
      grades: getJobListingGradesEntityValue(formState, formatEntityTimestamp),
      extraEmails: extraEmails
        .filter((e) => !isEmpty(e.name) && !isEmpty(e.email))
        .map(({ id: emailId, name, email }) => ({ id: emailId, name, extraEmail: email })),
      files: getFilesArrayFromFile(file),
      extendedHours,
      pensionCategory,
      repetitionDates: unbox(repetitionDates),
      published,
      remainingPositionsToFill,
      externalJobListingId,
      rateViolationReason: formState.value.gradesSection.rateViolationReason,
      shiftEscalated,
      crossCoveringProfessionSpecialties: flatMap(crossCoveringProfessionSpecialties, (state) =>
        unbox(state.professionSpecialties),
      ),
      shiftEscalatedForAgencies:
        shiftEscalatedForAgencies === undefined ? false : shiftEscalatedForAgencies,
      nonResidentOnCall,
      ...conversationProperties,
    };
    return listing;
  };

export const getExtendedFormState = (formState: FormGroupState<IJobListingFormWizardState>) => {
  const gradeControlsCount = get(
    formState,
    'controls.gradesSection.controls.grades.controls',
  ).length;
  const timeFragments = getTimeFragments(formState);
  let formStateMap = fromJS(formState);
  //@todo could iterate via immutable
  for (let i = 0; i < gradeControlsCount; i++) {
    const jobFragments = get(
      formState,
      `controls.gradesSection.controls.grades.controls[${i}].controls.jobFragments`,
    );
    const jobFragmentControls = fromJS(
      getJobFragments(
        formState.controls.shiftScheduler.controls.endTime,
        timeFragments,
        jobFragments,
      ),
    );

    //===Set the Value of virtual `toTime` job fragment controls
    formStateMap = formStateMap
      .setIn(
        [
          'controls',
          'gradesSection',
          'controls',
          'grades',
          'controls',
          i,
          'controls',
          'jobFragments',
          'controls',
        ],
        jobFragmentControls,
      )
      //===Set the Value of job fragments that have virtual `toTime` fields in JobFragment group
      .setIn(
        ['controls', 'gradesSection', 'controls', 'grades', 'value', i, 'jobFragments'],
        jobFragmentControls.map((jf) => jf.value),
      );
  }

  //===Update grade Values
  formStateMap = formStateMap.setIn(
    ['controls', 'gradesSection', 'controls', 'grades', 'value'],
    formStateMap
      .getIn(['controls', 'gradesSection', 'controls', 'grades', 'value'])
      .map((grade, i) =>
        //===Set the Value of job fragments that have virtual
        //`toTime` fields in JobListingGrade group
        grade
          .set(
            'jobFragments',
            grade
              .get('jobFragments')
              .map((jf, j) =>
                formStateMap.getIn([
                  'controls',
                  'gradesSection',
                  'controls',
                  'grades',
                  'controls',
                  i,
                  'controls',
                  'jobFragments',
                  'controls',
                  j,
                  'value',
                ]),
              ),
          )
          //===Remove disabled flat rates
          .set(
            'flatRate',
            formStateMap.getIn([
              'controls',
              'gradesSection',
              'controls',
              'grades',
              'controls',
              i,
              'controls',
              'flatRate',
              'isEnabled',
            ])
              ? grade.get('flatRate')
              : undefined,
          ),
      )
      //===FILTER DISABLED CONTROLS
      // caution keep this as the last transformation to keep state access simpler
      // we might be able to pipe to another selector in the future:
      // https://github.com/MrWolfZ/ngrx-forms/issues/99
      .filter((value, index) =>
        formStateMap.getIn([
          'controls',
          'gradesSection',
          'controls',
          'grades',
          'controls',
          index,
          'controls',
          'grade',
          'isEnabled',
        ]),
      ),
  );

  return formStateMap.toJS() as unknown as FormGroupState<IExtendedJobListingFormWizardState>;
};
export const getCrossCoveringProfessionSpecialtyFormState = (
  crossCoveringProfessionSpecialties,
  professionSpecialties,
) => {
  const crossCoveringProfessionSpecialtiesDict = groupBy(
    professionSpecialties.filter((x) => crossCoveringProfessionSpecialties.includes(x.id)),
    'profession',
  );
  return transform(
    crossCoveringProfessionSpecialtiesDict,
    function (result, value, key) {
      return result.push({
        profession: parseInt(key, 10),
        professionSpecialties: box(value.map((x) => x.id)),
      });
    },
    [],
  );
};
export const getEntityFormState = (
  entityState: IJobListingEntity,
  profession: number,
  professionSpecialties: IProfessionSpecialtyEntity[],
): IJobListingFormWizardState => {
  const {
    id,
    title,
    applicationDeadline,
    listingType,
    professionSpecialty,
    site,
    startTime,
    endTime,
    details,
    detailsChange,
    costCentreNumber,
    costCentreNumberChange,
    reasonForVacancy,
    reasonForVacancyChange,
    extraEmails,
    availablePositions,
    extendedHours,
    pensionCategory,
    remainingPositionsToFill,
    externalJobListingId,
    ward,
    roster,
    rateViolationReason,
    shiftEscalated,
    shiftEscalatedForAgencies,
    published,
    crossCoveringProfessionSpecialties,
    nonResidentOnCall,
  } = entityState;
  const timeFragments = getEntityTimeFragments(entityState);
  const grades = getEntityGrades(entityState);
  const file = getEntityFile(entityState);

  return {
    shiftCreation: {
      id,
      title,
      templateId: null,
      file,
      listingType,
      profession,
      professionSpecialty,
      site,
      details,
      detailsChange,
      reasonForVacancy: isNil(reasonForVacancy) ? null : reasonForVacancy,
      published,
      extraEmails: extraEmails.map(({ id: emailId, name, extraEmail }) => ({
        id: emailId,
        name,
        email: extraEmail,
      })),
      pensionCategory: isNil(pensionCategory) ? null : pensionCategory,
      externalJobListingId,
      ward,
      roster,
      reasonForVacancyChange,
      jobListingNotes: null,
      copyNoteAcrossRepetitionDate: false,
      crossCoveringProfessionSpecialties: getCrossCoveringProfessionSpecialtyFormState(
        crossCoveringProfessionSpecialties,
        professionSpecialties,
      ),
      nonResidentOnCall,
    },
    shiftScheduler: {
      costCentreNumberChange,
      costCentreNumber: isNil(costCentreNumber) ? '' : costCentreNumber,
      startTime: Time.formatDateByFormat(startTime, DATE_TIME_PICKER_FORMAT),
      endTime: Time.formatDateByFormat(endTime, DATE_TIME_PICKER_FORMAT),
      applicationDeadline: Time.formatDateByFormat(applicationDeadline, DATE_TIME_PICKER_FORMAT),
      isRepeating: false,
      timeFragments,
      availablePositions: +availablePositions,
      hasCustomApplicationDeadLine: isCustomApplicationDeadLine(startTime, applicationDeadline),
      extendedHours: isNil(extendedHours) ? false : extendedHours,
      repetitionDates: box([]),
      remainingPositionsToFill,
      consentBackdatedShifts: false,
    },
    gradesSection: {
      rateViolationReason,
      shiftEscalated,
      shiftEscalatedForAgencies,
      grades,
    },
  };
};

export const getEntityFormStateForTemplateJobListing = (
  entityState,
  professionSpecialty: number,
  professionSpecialties: IProfessionSpecialtyEntity[],
  isCostCodeV2: boolean,
): IJobListingFormWizardState => {
  const {
    title,
    listingType,
    profession,
    site,
    details,
    costCentreNumber,
    reasonForVacancy,
    extraEmails,
    availablePositions,
    extendedHours,
    pensionCategory,
    remainingPositionsToFill,
    endTime,
    startTime,
    crossCoveringProfessionSpecialties = [],
    nonResidentOnCall,
  } = entityState;
  const timeFragments = getEntityTimeFragments(entityState);
  const grades = getEntityGrades(entityState);
  const file = getEntityFile(entityState);

  return {
    shiftCreation: {
      id: null,
      file,
      title,
      listingType,
      profession,
      professionSpecialty,
      site,
      details,
      detailsChange: false,
      reasonForVacancy: isNil(reasonForVacancy) ? null : reasonForVacancy,
      reasonForVacancyChange: false,
      extraEmails: extraEmails.map(({ id: emailId, name, extraEmail }) => ({
        id: emailId,
        name,
        email: extraEmail,
      })),
      pensionCategory: isNil(pensionCategory) ? null : pensionCategory,
      externalJobListingId: null,
      jobListingNotes: null,
      copyNoteAcrossRepetitionDate: true,
      crossCoveringProfessionSpecialties: getCrossCoveringProfessionSpecialtyFormState(
        crossCoveringProfessionSpecialties,
        professionSpecialties,
      ),
      nonResidentOnCall: isNil(nonResidentOnCall) ? false : nonResidentOnCall,
    },
    shiftScheduler: {
      costCentreNumber: isNil(costCentreNumber) || isCostCodeV2 ? null : costCentreNumber,
      costCentreNumberChange: false,
      hasCustomApplicationDeadLine: false,
      extendedHours: isNil(extendedHours) ? false : extendedHours,
      applicationDeadline: null,
      timeFragments,
      availablePositions: +availablePositions,
      repetitionDates: box([]),
      isRepeating: false,
      endTime,
      startTime,
      remainingPositionsToFill: isNil(remainingPositionsToFill) ? null : remainingPositionsToFill,
      consentBackdatedShifts: false,
    },
    gradesSection: {
      rateViolationReason: null,
      shiftEscalated: false,
      shiftEscalatedForAgencies: false,
      grades,
    },
  };
};
