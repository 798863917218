import { Injectable } from '@angular/core';
import { LocumsNestEndpointConfig } from 'apps/hospital-admin/src/app/core/constants';
import { IHospitalProfessionConfigurationEntity } from 'apps/hospital-admin/src/app/interfaces/api/hospital-profession-configuration-entity';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

@Injectable({
  providedIn: 'root',
})
export class HospitalProfessionConfigurationPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IHospitalProfessionConfigurationEntity
> {
  protected override readonly endpoint = 'hospitalProfessionConfiguration';
}
