import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ISelectOption } from '@locumsnest/components/src';
import { PaginatedStateService } from '@locumsnest/core/src';

import { IHospitalProfileTagEntity } from '../../interfaces/api/hospital-profile-tag-entity';
import { ProfileTagService } from '../../profile-tag/+state/profile-tag.service';
import {
  paginationMessages,
  ResetHospitalProfileTagPaginationMessage,
  UpsertHospitalProfileTagPageMessage,
  UpsertMultipleMessage,
} from './hospital-profile-tag.messages';
import { HospitalProfileTagPersistenceService } from './hospital-profile-tag.persistence.service';
import {
  hospitalProfileTagPaginationSelectors,
  selectHospitalProfileTagEntityState,
} from './hospital-profile-tag.selectors';

@Injectable({
  providedIn: 'root',
})
export class HospitalProfileTagService extends PaginatedStateService<
  IHospitalProfileTagEntity,
  UpsertHospitalProfileTagPageMessage,
  ResetHospitalProfileTagPaginationMessage,
  UpsertMultipleMessage
> {
  constructor(
    protected store: Store,
    protected persistenceService: HospitalProfileTagPersistenceService,
    private profileTagService: ProfileTagService
  ) {
    super();
  }

  get paginationMessages() {
    return paginationMessages;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return hospitalProfileTagPaginationSelectors;
  }

  get entityStateSelector() {
    return selectHospitalProfileTagEntityState;
  }

  getHospitalProfileTagOptions(): Observable<ISelectOption[]> {
    return this.getAll().pipe(
      switchMap((hospitalProfileTags) =>
        combineLatest(
          hospitalProfileTags.map((hospitalProfileTag) =>
            this.profileTagService.getOneOrNone(hospitalProfileTag.tag).pipe(
              map((profileTag) => {
                if (profileTag)
                  return { ...profileTag, hospitalProfileTagId: hospitalProfileTag.id };
              })
            )
          )
        )
      ),
      map((tags) => {
        const filteredTags = tags.filter((x) => !!x);
        return filteredTags.map((tag) => {
          const option: ISelectOption = {
            id: tag.hospitalProfileTagId,
            name: tag.display,
            description: tag.officerDescription,
          };
          return option;
        });
      })
    );
  }

  fetch() {
    return this.loadAllPages('default', {});
  }
}
