import { DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { MessageableFactory, SignalableFactory } from '@locumsnest/core/src';

import { JobListingFormGroupState } from '../interfaces';
import { createBaseEffects } from './form.effects.adapter';
import { createJobListingFormMessages } from './form.messages.adapter';
import { createReducer, getFormId, getInitialFormState } from './form.reducer.adapter';
import { createJobListingFormSelectors } from './form.selectors.adapter';
import { createJobListingFormSignals } from './form.signals.adapter';
import { IJobListingAlertHandlers } from './interfaces';

export function creteJobListingFormAdapter<T extends string>(
  featureName: T,
  selectJobListingFormWizardState: MemoizedSelector<
    object,
    JobListingFormGroupState,
    DefaultProjectorFn<JobListingFormGroupState>
  >,
  path,
  alertHandlers: IJobListingAlertHandlers
) {
  const messageableFactory = MessageableFactory.forFeature<T>(featureName);
  const signalableFactory = SignalableFactory.forFeature<T>(featureName);
  const signals = createJobListingFormSignals(signalableFactory);
  const messages = createJobListingFormMessages(messageableFactory);
  const selectors = createJobListingFormSelectors(selectJobListingFormWizardState);
  const FORM_ID = getFormId(featureName);
  const INITIAL_FORM_STATE = getInitialFormState(FORM_ID);
  return {
    getMessages: () => messages,
    getSignals: () => signals,
    createReducer: () => createReducer(messages, FORM_ID, INITIAL_FORM_STATE),
    createBaseEffects: () =>
      createBaseEffects(
        selectors,
        signals,
        messages,
        FORM_ID,
        INITIAL_FORM_STATE,
        path,
        alertHandlers
      ),
    getSelectors: () => selectors,
    FORM_ID,
    INITIAL_FORM_STATE,
  };
}
