import { IStaffBankMembershipStats } from '../../../interfaces/api/staff-bank-membership-entity';
import { alertStateAdapter, messageableFactory as actionableFactory } from '../ui/ui.adapter';

// initialization of mixin factory
export const {
  ResetErrorMessage: ResetErrorAdapterMessage,
  AlertErrorMessage: AlertErrorAdapterMessage,
} = alertStateAdapter.getMessages();
export class ResetErrorMessage extends ResetErrorAdapterMessage {}
export class AlertErrorMessage extends AlertErrorAdapterMessage {}

export class InitializeStatsMessage extends actionableFactory.create<
  'Initialize Stats',
  Record<string, never>
>('Initialize Stats') {}

export class SetStatsMessage extends actionableFactory.create<
  'Set Stats Message',
  { stats: IStaffBankMembershipStats }
>('Set Stats Message') {}

export type StatsMessages =
  | InitializeStatsMessage
  | SetStatsMessage
  | ResetErrorMessage
  | AlertErrorMessage;
