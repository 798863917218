import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IReferenceEntity } from '../../interfaces/api/reference-entity';
import {
  MessageableFactory,
  SignalableFactory,
  createPaginatedSubresourceStateAdapter,
} from '@locumsnest/core/src';

export function sortByDate(a: IReferenceEntity, b: IReferenceEntity): number {
  return a.createdAt.valueOf() - b.createdAt.valueOf();
}

function getId(obj: IReferenceEntity) {
  return obj.id;
}

export const adapter: EntityAdapter<IReferenceEntity> = createEntityAdapter<IReferenceEntity>({
  selectId: (entity: IReferenceEntity) => entity.id,
  sortComparer: sortByDate,
});
export const messageableFactory = MessageableFactory.forFeature<'Reference'>('Reference');
export const signalableFactory = SignalableFactory.forFeature<'Reference'>('Reference');
export const paginationAdapter = createPaginatedSubresourceStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter,
  getId
);

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedReferenceId: null,
});
