import { FormGroupState } from 'ngrx-forms';

import { IFee } from '../../../interfaces/api/external-staffing-candidate-bid-entity';
import { isFlatFee, isPercentageFee } from '../../type-guards';

export const validateAgencyPercentageFee = (state: FormGroupState<IFee>) => () => {
  if (isPercentageFee(state.value)) {
    const feePercentage = +state.value.feePercentage;
    if (feePercentage > 100 || feePercentage < 0) {
      return { agencyFeePercentage: true };
    }
  }
  return null;
};

export const validateAgencyFlatFee = (state: FormGroupState<IFee>) => () => {
  if (isFlatFee(state.value)) {
    const fee = +state.value.fee;
    if (0 > fee) {
      return { agencyFee: true };
    }
  }
  return null;
};
