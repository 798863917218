import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { ICertificateTypeEntity } from '../../interfaces/api/certificate-type-entity';

@Injectable({
  providedIn: 'root',
})
export class CertificateTypePersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  ICertificateTypeEntity
> {
  protected readonly endpoint = 'certificateType';
}
