import { IUpdateMetaData } from './../../../../../../libs/core/src/lib/ngrx/cached-state';
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { IApplicationEntity } from '../../interfaces/api/application-entity';
import { paginationAdapter } from './application.adapter';

export const ApplicationPaginationMessages = paginationAdapter.getMessages();

export class UpsertApplicationPageMessage extends ApplicationPaginationMessages.UpsertPageMessage {}
export class UpsertApplicationMultiplePagesMessage extends ApplicationPaginationMessages.UpsertMultiplePagesMessage {}
export class ResetApplicationPaginationMessage extends ApplicationPaginationMessages.ResetPaginationMessage {}

export enum ApplicationSignalTypes {
  LOAD_APPLICATIONS = '[Application][Signal] Load Collection',
}

export enum ApplicationMessageTypes {
  SET_COLLECTION = '[Application][Message] Set Collection',
  ADD_ONE = '[Application][Message] Add One',
  UPDATE_ONE = '[Application][Message] Update One',
  UPSERT_ONE = '[Application][Message] Upsert One',
  DELETE_ONE = '[Application][Message] Delete One',
  ADD_MULTIPLE = '[Application][Message] Add All',
  DELETE_MULTIPLE = '[Application][Message] Delete Many',
  UPSERT_MULTIPLE = '[Application][Message] Upsert Many',
  UPDATE_MULTIPLE = '[Application][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = ApplicationMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IApplicationEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = ApplicationMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IApplicationEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = ApplicationMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IApplicationEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = ApplicationMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IApplicationEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = ApplicationMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IApplicationEntity[]; metaData?: IUpdateMetaData }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = ApplicationMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IApplicationEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = ApplicationMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IApplicationEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = ApplicationMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = ApplicationMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type ApplicationMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
