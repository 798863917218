import {
  StaffBankCollaborationGroupMessageTypes as MessageTypes,
  StaffBankCollaborationGroupMessages,
} from './staff-bank-collaboration-group.messages';
import { IStaffBankCollaborationGroupEntityState } from './interfaces';
import { adapter, initialState } from './staff-bank-collaboration-group.adapter';

export * from './interfaces';

export function reducer(
  state = initialState,
  action: StaffBankCollaborationGroupMessages
): IStaffBankCollaborationGroupEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.staffBankCollaborationGroup, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.staffBankCollaborationGroup, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.staffBankCollaborationGroups, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.staffBankCollaborationGroups, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.staffBankCollaborationGroups, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.staffBankCollaborationGroup, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.staffBankCollaborationGroups, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }
    default: {
      return state;
    }
  }
}
export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
