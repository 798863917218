import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';

import { StateService } from '@locumsnest/core';
import { filterNonEmpty } from '@locumsnest/core/src/lib/ngrx/operators';

import { ICertificateTypeEntity } from '../../interfaces/api/certificate-type-entity';
import { SetCollectionMessage } from './certificate-type.messages';
import { CertificateTypePersistenceService } from './certificate-type.persistence.service';
import {
  selectAllCertificateTypes,
  selectCertificateTypeById,
  selectCertificateTypeBySlug,
  selectCertificateTypeOptions,
} from './certificate-type.selectors';

@Injectable({
  providedIn: 'root',
})
export class CertificateTypeService extends StateService<ICertificateTypeEntity> {
  constructor(
    private store: Store,
    protected persistenceService: CertificateTypePersistenceService
  ) {
    super();
  }
  getById(id: number) {
    return this.store.pipe(select(selectCertificateTypeById(id)));
  }
  getAll() {
    return this.store.pipe(select(selectAllCertificateTypes), filterNonEmpty);
  }
  getCertificateTypeOptions() {
    return this.store.pipe(
      select(selectCertificateTypeOptions),
      filter((options) => options && options.length > 0)
    );
  }
  getCertificateTypeBySlug(slug: string) {
    return this.store.pipe(select(selectCertificateTypeBySlug(slug)));
  }
  getCertificateTypeName(id: number) {
    return this.store.pipe(
      select(selectCertificateTypeById(id)),
      map((certificateType) => (certificateType !== null ? certificateType.name : null))
    );
  }
  fetch() {
    return this.persistenceService
      .retrieve()
      .pipe(map(({ results }) => new SetCollectionMessage({ certificateTypes: results })));
  }
}
