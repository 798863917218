import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { ISafeboxReferenceEntity } from '../../interfaces/api/safebox-reference-entity';
import {
  MessageableFactory,
  SignalableFactory,
  createPaginatedSubresourceStateAdapter,
} from '@locumsnest/core/src';

export function sortByDate(a: ISafeboxReferenceEntity, b: ISafeboxReferenceEntity): number {
  return a.createdAt.valueOf() - b.createdAt.valueOf();
}

function getId(obj: ISafeboxReferenceEntity) {
  return obj.id;
}

export const adapter: EntityAdapter<ISafeboxReferenceEntity> =
  createEntityAdapter<ISafeboxReferenceEntity>({
    selectId: (entity: ISafeboxReferenceEntity) => entity.id,
    sortComparer: sortByDate,
  });
export const messageableFactory =
  MessageableFactory.forFeature<'SafeboxReference'>('SafeboxReference');
export const signalableFactory =
  SignalableFactory.forFeature<'SafeboxReference'>('SafeboxReference');
export const paginationAdapter = createPaginatedSubresourceStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter,
  getId
);

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedSafeboxReferenceId: null,
});
