import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { ICertificateEntity } from '../../interfaces/api/certificate-entity';
import { MessageableFactory, SignalableFactory } from '@locumsnest/core/src';
import { createPaginatedSubresourceStateAdapter } from '@locumsnest/core/src/lib/adapters/paginated-subresource-state-adapter';

export function sortByDate(a: ICertificateEntity, b: ICertificateEntity): number {
  return a.createdAt.valueOf() - b.createdAt.valueOf();
}

function getId(obj: ICertificateEntity) {
  return obj.id;
}

export const adapter: EntityAdapter<ICertificateEntity> = createEntityAdapter<ICertificateEntity>({
  selectId: (entity: ICertificateEntity) => entity.id,
  sortComparer: sortByDate,
});
export const messageableFactory = MessageableFactory.forFeature<'Certificate'>('Certificate');
export const signalableFactory = SignalableFactory.forFeature<'Certificate'>('Certificate');
export const paginationAdapter = createPaginatedSubresourceStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter,
  getId
);

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedCertificateId: null,
});
