import {
  ComplianceMessageMessageTypes as MessageTypes,
  ComplianceMessageMessages,
} from './compliance-message.messages';
import { IComplianceMessageEntityState } from './interfaces';
import { adapter, initialState, paginationAdapter } from './compliance-message.adapter';
import { IPaginatedSubresourceEntityState } from '@locumsnest/core/src';
import { IComplianceMessageEntity } from '../../interfaces/api/compliance-message-entity';

export * from './interfaces';

export const paginationReducer = paginationAdapter.createReducer();
export type IComplianceMessagePaginatedEntityState = IComplianceMessageEntityState &
  IPaginatedSubresourceEntityState<IComplianceMessageEntity>;
export function entityReducer(
  state = initialState,
  action: ComplianceMessageMessages
): IComplianceMessageEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.entity, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.entity, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.entities, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.entities, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }
    default: {
      return state;
    }
  }
}

export function paginatedEntityStateReducer(
  state: IComplianceMessagePaginatedEntityState,
  action: ComplianceMessageMessages
) {
  let pagination;

  if (state) {
    pagination = state.pagination;
  }

  return {
    ...entityReducer(state, action),
    pagination: {
      ...paginationReducer(pagination, action),
    },
  };
}
export const { selectIds, selectEntities, selectAll, selectTotal } =
  paginationAdapter.getEntitySelectors();
