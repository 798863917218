import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ISelectOption } from '@locumsnest/components/src/lib/interfaces';

import { selectEntities } from '../../application-status/+state/application-status.reducer';
import { IStaffingCascadeStatusEntity } from '../../interfaces/api/staffing-cascade-status-entity';
import {
  IStaffingCascadeStatusEntityState,
  IStaffingCascadeStatusFeatureState,
  IStaffingCascadeStatusSharedState,
} from './interfaces';
import { selectAll } from './staffing-cascade-status.reducer';

export const getEntityState = (state: { entityState: IStaffingCascadeStatusEntityState }) =>
  state.entityState;

export const getStaffingCascadeStatus =
  (staffingCascadeStatusId: number) =>
  (staffingCascadeStatuses: IStaffingCascadeStatusEntity[]) => {
    const staffingCascadeStatus = staffingCascadeStatuses.find(
      (status) => status.val === staffingCascadeStatusId
    );

    if (staffingCascadeStatus) {
      return staffingCascadeStatus.display;
    }
    return '';
  };

export const getStaffingCascadeStatusCodeById =
  (staffingCascadeStatusId: number) =>
  (staffingCascadeStatuses: IStaffingCascadeStatusEntity[]) => {
    const staffingCascadeStatus = staffingCascadeStatuses.find(
      (status) => status.val === staffingCascadeStatusId
    );

    if (staffingCascadeStatus) {
      return staffingCascadeStatus.code;
    }
    return null;
  };

export const getStaffingCascadeStatusOptions = (
  staffingCascadeStatuses: IStaffingCascadeStatusEntity[]
): ISelectOption[] =>
  staffingCascadeStatuses
    .map((staffingCascadeStatus) => ({
      id: staffingCascadeStatus.val,
      name: staffingCascadeStatus.display + ' Cascades',
    }))
    .filter((options) => options && options.name !== 'Cancelled Cascades');

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectStaffingCascadeStatusState = createFeatureSelector<
  IStaffingCascadeStatusFeatureState & IStaffingCascadeStatusSharedState
>('staffingCascadeStatuses');
export const selectStaffingCascadeStatusEntityState = createSelector(
  selectStaffingCascadeStatusState,
  getEntityState
);

export const selectAllStaffingCascadeStatuses = createSelector(
  selectStaffingCascadeStatusEntityState,
  selectAll
);

export const selectStaffingCascadeStatusDict = createSelector(
  selectStaffingCascadeStatusEntityState,
  selectEntities
);

export const selectStaffingCascadeStatus = (staffingCascadeStatusId) =>
  createSelector(selectStaffingCascadeStatusDict, (statuses) => statuses[staffingCascadeStatusId]);

export const selectStaffingCascadeStatusCode = (staffingCascadeStatusId) =>
  createSelector(selectStaffingCascadeStatus(staffingCascadeStatusId), (status) => status.code);

export const selectStaffingCascadeStatusCodeById = (staffingCascadeStatusId) =>
  createSelector(
    selectAllStaffingCascadeStatuses,
    getStaffingCascadeStatusCodeById(staffingCascadeStatusId)
  );

export const selectStaffingCascadeStatusOptions = createSelector(
  selectAllStaffingCascadeStatuses,
  getStaffingCascadeStatusOptions
);
