import { ISelectedCandidate } from '../interfaces/pre-match-form-state';
import { messageableFactory as actionableFactory } from './pre-match-form.adapter';

export class InitializePreMatchFormMessage extends actionableFactory.create<
  'Initialize Pre Match Confirmation Message',
  Record<string, never>
>('Initialize Pre Match Confirmation Message') {}

export class AddCandidateMessage extends actionableFactory.create<
  'Add Candidate Message',
  { selectedCandidate: ISelectedCandidate }
>('Add Candidate Message') {}

export class RemoveCandidateMessage extends actionableFactory.create<
  'Remove Candidate Message',
  { id: string }
>('Remove Candidate Message') {}

export class UpdateShowMyListMessage extends actionableFactory.create<
  'Update Show My List Message',
  { show: boolean }
>('Update Show My List Message') {}

export class ClearInvitationMessageMessage extends actionableFactory.create<
  'Clear Invitation Message Message',
  Record<string, never>
>('Clear Invitation Message Message') {}

export class ShowHidePreMatchMessageMessage extends actionableFactory.create<
  'Show Hide Pre-Match Message Message',
  { show: boolean }
>('Show Hide Pre-Match Message Message') {}

export class ClearSearchMessage extends actionableFactory.create<
  'Clear Search',
  Record<string, never>
>('Clear Search') {}

export type PreMatchWidgetUiMessages =
  | InitializePreMatchFormMessage
  | AddCandidateMessage
  | RemoveCandidateMessage
  | UpdateShowMyListMessage
  | ClearInvitationMessageMessage
  | ShowHidePreMatchMessageMessage
  | ClearSearchMessage;
