import { IPaginatedEntityState } from '@locumsnest/core/src/lib/interfaces/pagination';
import { IJobListingConversationProfileEntity } from '../../interfaces/api/job-listing-conversation-profile-entity';
import { IJobListingConversationProfileEntityState } from './interfaces';
import {
  adapter,
  initialState,
  loadingAdapter,
  paginationAdapter,
} from './job-listing-conversation-profile.adapter';
import {
  JobListingConversationProfileMessages,
  JobListingConversationProfileMessageTypes as MessageTypes,
} from './job-listing-conversation-profile.messages';

export * from './interfaces';

const _loadingStateReducer = loadingAdapter.createReducer();
const _paginationReducer = paginationAdapter.createReducer();

export type IJobListingConversationProfilePaginatedEntityState =
  IJobListingConversationProfileEntityState &
    IPaginatedEntityState<IJobListingConversationProfileEntity>;

export function loadingStateReducer(state, action) {
  return _loadingStateReducer(state, action);
}

export function paginationReducer(state, action) {
  return _paginationReducer(state, action);
}

export function reducer(
  state = initialState,
  action: JobListingConversationProfileMessages
): IJobListingConversationProfileEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.entity, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.entity, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.entities, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.entities, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }
    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  paginationAdapter.getEntitySelectors();
