import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import {
  createPaginatedSubresourceStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src';
import { IHospitalStaffBankUnavailabilityEntity } from '../../interfaces/api/hospital-staff-bank-unavailability-entity';

export function sortByDate(
  a: IHospitalStaffBankUnavailabilityEntity,
  b: IHospitalStaffBankUnavailabilityEntity
): number {
  return a.fromDate.valueOf() - b.fromDate.valueOf();
}

function getId(obj: IHospitalStaffBankUnavailabilityEntity) {
  return obj.id;
}

export const adapter: EntityAdapter<IHospitalStaffBankUnavailabilityEntity> =
  createEntityAdapter<IHospitalStaffBankUnavailabilityEntity>({
    selectId: (entity: IHospitalStaffBankUnavailabilityEntity) => entity.id,
    sortComparer: sortByDate,
  });

export const messageableFactory = MessageableFactory.forFeature<'HospitalStaffBankUnavailability'>(
  'HospitalStaffBankUnavailability'
);
export const signalableFactory = SignalableFactory.forFeature<'HospitalStaffBankUnavailability'>(
  'HospitalStaffBankUnavailability'
);
export const paginationAdapter = createPaginatedSubresourceStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter,
  getId
);

export const initialState = adapter.getInitialState({
  selectedStaffBankUnavailabilityId: null,
});
