/* eslint-disable @typescript-eslint/member-ordering */

import { Injectable, Injector } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import {
  difference,
  flatMap,
  fromPairs,
  get,
  groupBy,
  isNil,
  isNumber,
  isString,
  maxBy,
  range,
  sortBy,
  uniq,
} from 'lodash-es';
import { DateRange } from 'moment-range';
import { FormGroupState, unbox } from 'ngrx-forms';
import {
  combineLatest,
  concat,
  distinctUntilChanged,
  filter,
  first,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  startWith,
  switchMap,
  takeUntil,
  timer,
} from 'rxjs';

import { ISelectGroupOption, ISelectOption, SelectOption } from '@locumsnest/components';
import { IQueryParams, PaginatedStateService, Query } from '@locumsnest/core/src';
import { ISetValueForResourceListMessageConstructor } from '@locumsnest/core/src/lib/adapters/singleton-resource-adapter';
import { Time, UrlHelpers } from '@locumsnest/core/src/lib/helpers';
import { DateTime } from '@locumsnest/core/src/lib/helpers/date-time';
import { formatNamespaceWithId } from '@locumsnest/core/src/lib/helpers/util';
import { filterNotNil } from '@locumsnest/core/src/lib/ngrx/operators';
import { CurrencySymbolPipe } from '@locumsnest/core/src/lib/pipes';
import { selectParams } from '@locumsnest/core/src/lib/router/router-selectors';
import {
  DATE_FORMAT,
  DATE_FORMAT_DB,
  DAY_NAMES_MAP,
} from '@locumsnest/core/src/lib/types/constants';
import {
  ActionEnum,
  ApplicationExplicitActionEnum,
  IBidGroupDetails,
  ICalendarShiftSummary,
} from '@locumsnest/dashboard-ui/src/lib/interfaces';
import { JobListingTagsService } from '@locumsnest/job-listing-tags/src';
import { InitializeListPageTagSignal, TagService } from '@locumsnest/tag';

import { ApplicationStatusService } from '../../application-status/+state/application-status.service';
import { ApplicationStatusCodes } from '../../application-status/+state/interfaces';
import { AuthGroupService } from '../../auth-group/+state/auth-group.service';
import { AUTH_GROUPS } from '../../auth-group/constants';
import { BankHolidayService } from '../../bank-holiday/+state/bank-holiday.service';
import { DEFAULT_CURRENCY, WEEKDAYS } from '../../core/constants';
import { RouterService } from '../../core/services';
import {
  IApplicationService,
  IStaffingCascadeService,
  LoadDependenciesSettings,
  LoadProfileSettings,
} from '../../core/services/interfaces';
import {
  APPLICATION_SERVICE_TOKEN,
  STAFFING_CASCADE_SERVICE_TOKEN,
} from '../../core/services/state/opaque-tokens';
import { ExternalStaffingCandidateBidStatusService } from '../../external-staffing-candidate-bid-status/+state/external-staffing-candidate-bid-status.service';
import {
  ExternalStaffingCandidateBidStatusCodes,
  isNegativeBidResponseCode,
} from '../../external-staffing-candidate-bid-status/+state/interfaces/codes';
import { ExternalStaffingCandidateBidStatusAlertTypePipe } from '../../external-staffing-candidate-bid-status/pipes/external-staffing-candidate-bid-status-alert-type.pipe';
import * as externalStaffingCandidateBidMessages from '../../external-staffing-candidate-bid/+state/external-staffing-candidate-bid.messages';
import { ExternalStaffingCandidateBidService } from '../../external-staffing-candidate-bid/+state/external-staffing-candidate-bid.service';
import { calculateApprovedFlatRate } from '../../external-staffing-candidate-bid/+state/form/form.reducer';
import { isFlatFee, isPercentageFee } from '../../external-staffing-candidate-bid/type-guards';
import { ExternalStaffingProviderService } from '../../external-staffing-provider/+state/external-staffing-provider.service';
import { GradeService } from '../../grade/+state/grade.service';
import { HospitalOfficerService } from '../../hospital-officer/+state/hospital-officer.service';
import { HospitalService } from '../../hospital/+state/hospital.services';
import {
  IApprovedRateEntity,
  IRateDefinitionEntity,
} from '../../interfaces/api/approved-rate-entity';
import { IAttributeEntity } from '../../interfaces/api/attribute';
import { IBankHolidayEntity } from '../../interfaces/api/bank-holiday-entity';
import { IExternalJobListingEntity } from '../../interfaces/api/external-job-listing-entity';
import { IExternalStaffingApprovedRateEntity } from '../../interfaces/api/external-staffing-approved-rate-entity';
import {
  IBidFragment,
  IExternalStaffingCandidateBidEntity,
  IExternalStaffingCandidateBidParams,
  IExternalStaffingCandidateBidRow,
  IListingBid,
} from '../../interfaces/api/external-staffing-candidate-bid-entity';
import { IExternalStaffingCandidateBidStatusEntity } from '../../interfaces/api/external-staffing-candidate-bid-status-entity';
import { IGradeEntity } from '../../interfaces/api/grade-entity';
import { IHospitalEntity } from '../../interfaces/api/hospital-entity';
import {
  BulkUnpublishJobListingPayload,
  IFlatRateEntity,
  IGradeDetails,
  IJobFragmentEntity,
  IJobFragmentRatesEntity,
  IJobListingBookingStats,
  IJobListingEntity,
  IJobListingEntityWithRateDefinitions,
  IJobListingGradeEntity,
  IJobListingListing,
  IJobListingParams,
  IJobListingRow,
  IJobListingSubmitButton,
  IJobListingWithBookingStats,
  IRowEnum,
  JobListingNotePostPayload,
  StaffingCascadeStatusCode,
  ViolationRateWarnings,
} from '../../interfaces/api/job-listing-entity';
import { IProfessionEntity } from '../../interfaces/api/profession-entity';
import { IProfessionSpecialtyEntity } from '../../interfaces/api/profession-specialty-entity';
import { IProfileEntity } from '../../interfaces/api/profile-entity';
import { ISubSpecialtyEntity } from '../../interfaces/api/sub-specialty-entity';
import { Currency, IPayRateType } from '../../interfaces/pay-rate-types';
import { ISubSpecialty } from '../../interfaces/specialty';
import {
  selectJobListingConversationProfileFormState,
  selectSelectedCandidateList,
} from '../../job-listing-conversation-profile/+state/job-listing-conversation-profile.selectors';
import { JobListingTypeService } from '../../job-listing-type/+state/job-listing-type.service';
import {
  selectCopyNoteAcrossRepetitionDateFormValue,
  selectCostCentreNumberValue,
  selectExtendedFormGradesArray,
  selectExtendedJobListingFormWizardState,
  selectIsExternalJobListingMode,
  selectJobListingFormNotesValue,
  selectJobListingFormPrimaryProfessionSpecialtyValue,
  selectJobListingFormProfessionSpecialtyValues,
  selectJobListingFormStartTimeValue,
  selectRateViolationReasonControl,
  selectRateViolationReasonValue,
  selectRemainingPositionsToFill,
  selectRepetitionDatesValue,
  selectShiftSchedulerControl,
  selectStartEndRepetition,
  selectTimeFragmentsControl,
} from '../../job-listing/+state/form/form.reducer';
import {
  JobListingMessageTypes,
  JobListingPaginationMessages,
  ResetJobListingPaginationMessage,
  SetCollectionMessage,
  UpsertJobListingPageMessage,
  UpsertMultipleMessage,
  UpsertOneMessage,
} from '../../job-listing/+state/job-listing.actions';
import { bookingStatsAdapter, loadingAdapter } from '../../job-listing/+state/job-listing.adapter';
import {
  selectApplicationSelectedPage,
  selectListingNotFound,
  selectLoadingInProgress,
  selectShowingAnyForm,
  selectShowShiftDetailsCard,
  selectSubmissionInProgress,
} from '../../job-listing/+state/ui/ui.selectors';
import { PayRateTypeService } from '../../pay-rate-type/+state/pay-rate-type.service';
import { PermissionService } from '../../permission/+state/permission.service';
import { ProfessionSpecialtyService } from '../../profession-specialty/+state/profession-specialty.service';
import { ProfessionService } from '../../profession/+state/profession.service';
import { ProfileFlagService } from '../../profile-flag/+state/profile-flag.service';
import { ProfileService } from '../../profile/+state/profile.service';
import { SiteService } from '../../site/+state/site.service';
import { SpecialtyService } from '../../specialty/+state/specialty.service';
import { StaffingCascadeStatusService } from '../../staffing-cascade-status/+state/staffing-cascade-status.service';
import { SubSpecialtyService } from '../../sub-specialty/+state/sub-specialty.service';
import { TrustExternalStaffingProviderTierService } from '../../trust-external-staffing-provider-tier/+state/trust-external-staffing-provider-tier.service';
import {
  selectExpandedJobListings,
  selectJobListingsSelectedPage,
  selectSelectedJobListings,
  selectSelectedJobListingsCount,
  selectSortedField,
} from '../+state/search-filter-form';
import { IApplicationGroupDetails } from './../../../../../../libs/dashboard-ui/src/lib/interfaces/calendar-shift-summary';
import {
  ApprovedRateService,
  IGradeRate,
} from './../../approved-rate/+state/approved-rate.service';
import { selectJobListingFormWizardState } from './form/form.adapter';
import { formatTimestamp, getEntity, isEmploymentPeriodInThePast } from './form/form.selectors';
import { IExtendedJobFragmentFormState, IJobListingExtendedGradeFormState } from './interfaces';
import { JobListingPermissionService } from './job-listing.permission.service';
import { JobListingPersistenceService } from './job-listing.persistence.service';
import {
  bookingStatsSelectors,
  jobListingPaginationSelectors,
  loadingStateSelectors,
  selectAllJobListings,
  selectEnabledJobListingGrades,
  selectIsCrossCoveringForm,
  selectIsEmploymentPeriodInPast,
  selectIsFormInvalid,
  selectIsRepeatingDateInPast,
  selectIsSectionDescriptionInvalid,
  selectIsSectionDetailsInvalid,
  selectIsSectionGradesInvalid,
  selectJobListingByCascadeId,
  selectJobListingById,
  selectJobListingByIds,
  selectJobListingCanEscalateOrRemove,
  selectJobListingCanEscalateOrRemoveForAgencies,
  selectJobListingEntityState,
  selectJobListingFormCrossCoveringProfessions,
  selectJobListingFormGradeValues,
  selectJobListingFormId,
  selectJobListingFormNonResidentOnCallValue,
  selectJobListingFormProfessionValue,
  selectJobListingFormSiteValue,
  selectJobListingGradeControl,
  selectJobListingGradesAreValid,
  selectJobListingLockedState,
  selectJobListingProfessionSpecialty,
  selectJobListingPublishedState,
  selectJobListingPublishingOfficer,
  selectJobListingsByDate,
  selectProfessionGradesAreValid,
  selectSelectedJobListingId,
  selectShiftCreationControlIsInvalid,
  selectShiftSchedulerControlIsInvalid,
} from './job-listing.selectors';
import { selectors } from './wizard/wizard.adapter';

@Injectable({
  providedIn: 'root',
})
export class JobListingService extends PaginatedStateService<
  IJobListingEntity,
  UpsertJobListingPageMessage,
  ResetJobListingPaginationMessage,
  UpsertMultipleMessage
> {
  private currencySymbol = new CurrencySymbolPipe();
  private externalBidStatusAlertType = new ExternalStaffingCandidateBidStatusAlertTypePipe();
  // eslint-disable-next-line max-len, @typescript-eslint/naming-convention
  protected readonly SetValueForJobListingBookingStatsMessageClass: ISetValueForResourceListMessageConstructor<
    number,
    IJobListingBookingStats
  > = bookingStatsAdapter.getMessages().SetValueForResourceList;
  protected readonly selectBookingStats = bookingStatsSelectors.selectSubResource;
  protected readonly selectBookingStatsDictionary = bookingStatsSelectors.selectSubResourceState;

  // protected readonly bookingStatsPaddingValue = { bookingCount: 0 };
  constructor(
    protected store: Store,
    protected persistenceService: JobListingPersistenceService,
    protected specialtyService: SpecialtyService,
    protected gradeService: GradeService,
    protected profileService: ProfileService,
    protected applicationStatusService: ApplicationStatusService,
    protected injector: Injector,
    protected permissionService: PermissionService,
    protected hospitalService: HospitalService,
    protected siteService: SiteService,
    protected jobListingTypeService: JobListingTypeService,
    protected externalStaffingCandidateBidService: ExternalStaffingCandidateBidService,
    protected externalStaffingCandidateBidStatusService: ExternalStaffingCandidateBidStatusService,
    protected externalStaffingProviderService: ExternalStaffingProviderService,
    protected staffingCascadeStatusService: StaffingCascadeStatusService,
    protected approvedRateService: ApprovedRateService,
    protected payRateTypeService: PayRateTypeService,
    protected professionService: ProfessionService,
    protected subSpecialtyService: SubSpecialtyService,
    protected professionSpecialtyService: ProfessionSpecialtyService,
    protected routerService: RouterService,
    protected profileFlagService: ProfileFlagService,
    protected bankHolidayService: BankHolidayService,
    protected jobListingPermissionService: JobListingPermissionService,
    protected hospitalOfficerService: HospitalOfficerService,
    private authGroupService: AuthGroupService,
    private jobListingTagsService: JobListingTagsService,
    private tagsService: TagService,
    private trustExternalStaffingProviderTierService: TrustExternalStaffingProviderTierService,
  ) {
    super();
  }
  get paginationMessages() {
    return JobListingPaginationMessages;
  }

  get paginationSelectors() {
    return jobListingPaginationSelectors;
  }

  get entityStateSelector() {
    return selectJobListingEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get setCollectionMessage() {
    return SetCollectionMessage;
  }

  get loadingMessages() {
    return loadingAdapter.getMessages();
  }

  get applicationService(): IApplicationService {
    return this.injector.get(APPLICATION_SERVICE_TOKEN);
  }

  get staffingCascadeService(): IStaffingCascadeService {
    return this.injector.get(STAFFING_CASCADE_SERVICE_TOKEN);
  }

  getLatestJobListingNotes(jobListing: number) {
    return this.persistenceService.latestListingNotes(jobListing).pipe(
      map(({ results }) => {
        if (results.length) {
          return results[0];
        }
        return null;
      }),
    );
  }

  getLatestJobListingNoteForSelectedJobListing() {
    return this.getFormIdValue().pipe(switchMap((id) => this.getLatestJobListingNotes(id)));
  }

  loadBookingStats(listingIds: number[]) {
    if (listingIds.length) {
      return this.persistenceService.loadBookingStats(listingIds).pipe(
        map(({ results }) => {
          const entities = results.map((result) => ({
            resourceId: result.listing,
            subResource: result,
          }));
          return new this.SetValueForJobListingBookingStatsMessageClass(entities);
        }),
      );
    }
    return of<Action>();
  }

  getBookingStats(resourceId: number) {
    return this.store.pipe(select(this.selectBookingStats(resourceId)), filterNotNil());
  }

  getSortedField() {
    return this.store.pipe(select(selectSortedField));
  }

  getAll() {
    return this.store.pipe(select(selectAllJobListings));
  }

  getSelectedJobListingId() {
    return this.store.pipe(select(selectSelectedJobListingId));
  }

  getAllAfterLoading() {
    return this.store.pipe(select(loadingStateSelectors.selectLoadingState)).pipe(
      filter((loadingState) => loadingState.isLoaded === true),
      mergeMap(() => this.getAll()),
    );
  }

  updateCascade(listing: number, staffingCascade: number) {
    return this.getOne(listing).pipe(
      first(),
      map(
        (listingEntity) => new UpsertOneMessage({ entity: { ...listingEntity, staffingCascade } }),
      ),
    );
  }

  hasPendingApplications(id: number) {
    return this.applicationService.getApplicationsByJobListingIds([id]).pipe(
      map((applications) => applications.filter((application) => application.bookingStatus).length),
      distinctUntilChanged(),
    );
  }

  formListingHasPendingApplications() {
    return this.getJobListingFormId().pipe(
      mergeMap((id) => this.applicationService.getPendingApplicationsForListing(id)),
      map((applications) => !!get(applications, 'length')),
      distinctUntilChanged(),
    );
  }

  getOneImmediately(id: number) {
    return this.store.pipe(select(selectJobListingById(id)), first());
  }

  getShowLockButton() {
    return combineLatest([
      this.hasLockShiftRatesPermission(),
      this.hasUnLockShiftRatesPermission(),
      this.getLockedStatus(),
    ]).pipe(
      map(
        ([hasLockShiftRatesPermission, hasUnlockShiftRatesPermission, lockedState]) =>
          (hasLockShiftRatesPermission && !lockedState) ||
          (hasUnlockShiftRatesPermission && lockedState),
      ),
    );
  }

  loadOneIfNotExists(id: number) {
    return this.getOneImmediately(id).pipe(
      mergeMap((listing) => {
        if (!listing) {
          return this.loadOne(id);
        }
        return of<Action>();
      }),
    );
  }

  getOne(id: number) {
    return this.store.pipe(
      select(selectJobListingById(id)),
      filter((x) => !!x),
    );
  }

  getSiteOptionsForTrust(hospital: number) {
    return this.siteService.getOptionsByTrustIds([hospital]);
  }

  getOneByCascadeIdWithSubSpecialty(
    cascadeId: number,
  ): Observable<
    IJobListingEntity<
      Date,
      ISubSpecialtyEntity,
      number,
      IProfessionSpecialtyEntity<ISubSpecialtyEntity, number>
    >
  > {
    return this.getJobListingByCascadeId(cascadeId).pipe(
      filter((x) => !isNil(x)),
      switchMap((jobListing) =>
        this.professionSpecialtyService.getOneWithSpecialty(jobListing.professionSpecialty).pipe(
          map((professionSpecialty) => ({
            ...jobListing,
            professionSpecialty,
          })),
        ),
      ),
    );
  }

  getOneWithSubSpecialty(
    jobListingId: number,
  ): Observable<
    IJobListingEntity<
      Date,
      ISubSpecialtyEntity,
      number,
      IProfessionSpecialtyEntity<ISubSpecialtyEntity, number>
    >
  > {
    return this.getOne(jobListingId).pipe(
      switchMap((jobListing) =>
        this.professionSpecialtyService.getOneWithSpecialty(jobListing.professionSpecialty).pipe(
          map((professionSpecialty) => ({
            ...jobListing,
            professionSpecialty,
          })),
        ),
      ),
    );
  }

  getPublishedState(id: number) {
    return this.getOne(id).pipe(
      map((listing) => listing.published),
      distinctUntilChanged(),
    );
  }

  getFormPublishedState(): Observable<boolean> {
    return this.getFormIdValue().pipe(mergeMap((id: number) => this.getPublishedState(id)));
  }

  getLockedStatus(): Observable<boolean> {
    return concat(
      of(false),
      this.getFormIdValue().pipe(
        mergeMap((id) =>
          this.getOne(id).pipe(
            map((listing) => listing.ratesLocked),
            distinctUntilChanged(),
          ),
        ),
      ),
    ).pipe(distinctUntilChanged());
  }

  hasLockShiftRatesPermission() {
    return this.permissionService.hasCurrentPermission('lock_job_listing_rates');
  }

  hasUnLockShiftRatesPermission() {
    return this.permissionService.hasCurrentPermission('unlock_job_listing_rates');
  }

  canDeleteOrganisationTags() {
    return this.authGroupService.getOfficerBelongsToAuthGroup('Remove Organisation Tag Officer');
  }

  getShowRatedLockedFilter() {
    return combineLatest([
      this.hasLockShiftRatesPermission(),
      this.hasUnLockShiftRatesPermission(),
      this.hospitalService.getHospitalShiftLockRates(),
    ]).pipe(
      map(
        ([
          hasLockPermission,
          hasUnlockPermission,
          { lockShiftRatesCeiling, lockShiftIfRatesViolated },
        ]) =>
          hasLockPermission ||
          hasUnlockPermission ||
          !!lockShiftRatesCeiling ||
          lockShiftIfRatesViolated,
      ),
    );
  }

  getJobListingsByDate(date: string, loadAdhocs: boolean = true, loadUnpublished: boolean = true) {
    return this.store.pipe(select(selectJobListingsByDate(date, loadAdhocs, loadUnpublished)));
  }

  getJobListingsWithApplicationsByDate(namespace: string) {
    return this.getConsecutivePageEntities(namespace).pipe(
      switchMap((jobListings): Observable<ICalendarShiftSummary[]> => {
        if (!jobListings.length) {
          return of<ICalendarShiftSummary[]>([]);
        }
        return combineLatest(
          jobListings.map((jobListing) =>
            combineLatest([
              this.professionSpecialtyService.getOneWithSpecialty(jobListing.professionSpecialty),
              this.applicationService.getAllByListingIdWithDetails(jobListing.id),
              jobListing.staffingCascade
                ? this.externalStaffingCandidateBidService.getAllByCascadeIdWithDetails(
                    jobListing.staffingCascade,
                  )
                : of([]),
              this.staffingCascadeService.getStaffingCascadeStatusCode(jobListing.staffingCascade),
            ]).pipe(
              map(
                ([
                  professionSpecialty,
                  applicationRows,
                  externalStaffingCandidateBidRows,
                  cascadeStatusCode,
                ]) => {
                  const subSpecialty = professionSpecialty.specialty;
                  const groupApplications = this.getApplicationGroups(applicationRows);

                  const groupExternalStaffingCandidateBids =
                    this.getExternalStaffingCandidateBidsGroups(externalStaffingCandidateBidRows);

                  return this.getCalendarShiftSummary(
                    jobListing,
                    subSpecialty,
                    { groupApplications, applicationsCount: applicationRows.length },
                    {
                      groupExternalStaffingCandidateBids,
                      bidsCount: externalStaffingCandidateBidRows.length,
                    },
                    cascadeStatusCode,
                  );
                },
              ),
            ),
          ),
        );
      }),
      map((summaries) =>
        summaries.sort((a, b) => {
          if (a.published === true && b.published !== true) return -1;
          if (b.published === true && a.published !== true) return 1;

          return a.startTime > b.startTime ? 1 : a.startTime < b.startTime ? -1 : 0;
        }),
      ),
    );
  }

  fetch(
    query?: Query,
    loadDependenciesSettings: LoadDependenciesSettings | Record<string, never> = {},
  ) {
    if (isString(query) || isNumber(query))
      return this.persistenceService.retrieve(query).pipe(
        mergeMap((entity) => {
          const actions: Observable<Action>[] = [of(new UpsertOneMessage({ entity }))];

          if (loadDependenciesSettings.loadApplications) {
            actions.push(
              this.applicationService.loadByListingIds(
                [entity.id],
                loadDependenciesSettings.loadProfileSetting,
              ),
            );
          }

          if (entity.staffingCascade > 0 && loadDependenciesSettings.loadBids) {
            actions.push(
              this.externalStaffingCandidateBidService.loadByStaffingCascadeIds(
                [entity.staffingCascade],
                loadDependenciesSettings.loadProfileSetting,
              ),
            );
          }

          if (loadDependenciesSettings.loadSites) {
            actions.push(this.siteService.loadManyIfNotExists([entity.site]));
          }

          return merge(...actions);
        }),
      );

    return this.persistenceService.retrieve(query).pipe(
      mergeMap(({ results }) => {
        const actions: Observable<Action>[] = [
          of(new UpsertMultipleMessage({ entities: results })),
        ];

        if (loadDependenciesSettings.loadApplications) {
          const ids: number[] = [];
          for (const result of results) {
            ids.push(result.id);
          }

          actions.push(
            this.applicationService.loadByListingIds(
              ids,
              loadDependenciesSettings.loadProfileSetting,
            ),
          );
        }

        const staffingCascadeIds: number[] = results.map((result) => result.staffingCascade);
        const siteIds: number[] = results.map((result) => result.site);

        if (staffingCascadeIds.length > 0 && loadDependenciesSettings.loadBids) {
          actions.push(
            this.externalStaffingCandidateBidService.loadByStaffingCascadeIds(
              staffingCascadeIds,
              loadDependenciesSettings.loadProfileSetting,
            ),
          );
        }

        if (siteIds.length > 0 && loadDependenciesSettings.loadSites) {
          actions.push(this.siteService.loadManyIfNotExists(uniq(siteIds)));
        }

        return merge(...actions);
      }),
    );
  }

  getSubmissionInProgress(): Observable<boolean> {
    return this.store.pipe(select(selectSubmissionInProgress));
  }

  getLoadingInProgress(): Observable<boolean> {
    return this.store.pipe(select(selectLoadingInProgress));
  }

  getListingNotFound(): Observable<boolean> {
    return this.store.pipe(select(selectListingNotFound));
  }

  getFormNotesValue(): Observable<string> {
    return this.store.pipe(select(selectJobListingFormNotesValue));
  }

  getFormCopyNoteAcrossRepetitionDateValue(): Observable<boolean> {
    return this.store.pipe(select(selectCopyNoteAcrossRepetitionDateFormValue));
  }

  getFormProfessionValue(): Observable<number> {
    return this.store.pipe(select(selectJobListingFormProfessionValue));
  }

  getFormPrimaryProfessionSpecialtyValue(): Observable<number> {
    return this.store.pipe(select(selectJobListingFormPrimaryProfessionSpecialtyValue));
  }

  getFormProfessionSpecialtyValues() {
    return this.store.pipe(select(selectJobListingFormProfessionSpecialtyValues));
  }

  getFormNonResidentOnCallValue() {
    return this.store.pipe(select(selectJobListingFormNonResidentOnCallValue));
  }

  getFormPrimarySpecialty() {
    return this.getFormPrimaryProfessionSpecialtyValue().pipe(
      switchMap((professionSpecialtyId) =>
        this.professionSpecialtyService.getOne(professionSpecialtyId),
      ),
      map(({ specialty }) => specialty),
    );
  }

  getFormSpecialties() {
    return this.getFormProfessionSpecialtyValues().pipe(
      switchMap((professionSpecialtyIds) =>
        combineLatest(
          professionSpecialtyIds.map((professionSpecialtyId) =>
            this.professionSpecialtyService.getOne(professionSpecialtyId).pipe(
              map(({ specialty }) => specialty),
              distinctUntilChanged(),
            ),
          ),
        ),
      ),
    );
  }
  getFormCrossCoveringProfessions() {
    return this.store.pipe(select(selectJobListingFormCrossCoveringProfessions));
  }

  getIsCrossCoveringForm() {
    return this.store.pipe(select(selectIsCrossCoveringForm));
  }

  getFormGradeValues(): Observable<number[]> {
    return this.store.pipe(select(selectJobListingFormGradeValues));
  }

  getFormSiteValue(): Observable<number> {
    return this.store.pipe(select(selectJobListingFormSiteValue));
  }

  /**
   * Includes cross-covering professions and primary profession
   *
   * @memberof JobListingService
   */
  getFormSelectedProfessions() {
    return combineLatest([
      this.getFormProfessionValue(),
      this.getFormCrossCoveringProfessions(),
    ]).pipe(map(([profession, ccProfessions]) => [...new Set([profession, ...ccProfessions])]));
  }

  getSelectedProfessionDetails() {
    return this.getFormSelectedProfessions().pipe(
      switchMap((professions) =>
        combineLatest(professions.map((profession) => this.professionService.getOne(profession))),
      ),
    );
  }

  getFormStartTimeValue(): Observable<Date> {
    return this.store.pipe(select(selectJobListingFormStartTimeValue));
  }

  getFormIdValue() {
    return this.store.pipe(select(selectJobListingFormId));
  }

  getCurrentJoblistingPublishedState(): Observable<boolean> {
    return this.getFormIdValue().pipe(
      filter((id) => !!id),
      switchMap((id) => this.store.pipe(select(selectJobListingPublishedState(id)))),
    );
  }

  canImportBid() {
    return combineLatest([
      this.jobListingPermissionService.canImportBid(),
      this.getFormStartTimeValue(),
      this.getCurrentJoblistingPublishedState(),
    ]).pipe(
      map(([canImport, startTime, published]) => canImport && published && +startTime < Date.now()),
    );
  }

  getSubSpecialtyWithCategory(): Observable<ISubSpecialty> {
    return combineLatest([
      this.professionSpecialtyService.getAllActiveWithSpecialty(),
      this.getFormPrimaryProfessionSpecialtyValue(),
    ]).pipe(
      map(([professionSpecialties, selectedProfessionSpecialty]) => {
        const professionSpecialty = professionSpecialties.find(
          (ps) => ps.id === selectedProfessionSpecialty,
        );

        return professionSpecialty ? professionSpecialty.specialty : null;
      }),
    );
  }

  getCurrentListingNamespace(namespace): Observable<string> {
    return this.store.pipe(select(selectParams)).pipe(
      map((params) => formatNamespaceWithId(namespace, +params['id'])),
      distinctUntilChanged(),
    );
  }

  getCurrentListing(): Observable<IJobListingEntity> {
    return this.store.pipe(select(selectParams)).pipe(
      mergeMap((params) => this.getOne(+params['id'])),
      distinctUntilChanged(),
    );
  }
  isPendingListing(listing: IJobListingEntity<Date>) {
    return Time.getDate(listing.startTime) > Time.getDate();
  }
  getCurrentListingIsPending() {
    return this.getSelectedJobListing().pipe(
      map((listing) => this.isPendingListing(listing)),
      distinctUntilChanged(),
    );
  }
  getListingIsPending(id) {
    return this.getOne(id).pipe(
      map((listing) => this.isPendingListing(listing)),
      distinctUntilChanged(),
    );
  }
  loadCurrentListingCascadeStats() {
    return this.getCurrentListing().pipe(
      filter((listing) => !!listing),
      first(),
      mergeMap(({ staffingCascade }) => {
        if (staffingCascade) {
          return this.staffingCascadeService.loadStatsIfNotExists(staffingCascade);
        }
        return of<Action>();
      }),
    );
  }

  getListingBidCount(id) {
    return this.getOne(id).pipe(
      switchMap(({ staffingCascade }) =>
        this.externalStaffingCandidateBidService.getAllOrEmptyByCascadeId(staffingCascade),
      ),
      map((bids) => bids.length),
    );
  }
  getFormSpecialtyOptions(): Observable<ISelectGroupOption[]> {
    const selectedProfessionSpecialty$ = this.getFormPrimaryProfessionSpecialtyValue();
    const selectedProfessions$ = this.getFormProfessionValue().pipe(
      map((profession) => [profession]),
    );
    return this.specialtyService.getSpecialtyOptionsForProfession(
      selectedProfessions$,
      of(null),
      selectedProfessionSpecialty$,
    );
  }

  getDisplayPrematchWidget(): Observable<boolean> {
    return this.isFormDisabled().pipe(
      map((formDisabled) => !formDisabled),
      distinctUntilChanged(),
    );
  }

  getFormSiteOptions() {
    const selectedSite$ = this.getFormSiteValue();
    return this.siteService.getPreferredSiteOptions(selectedSite$);
  }

  getFormGradeProfessionDetailsList() {
    const selectedProfession$ = this.getFormSelectedProfessions();
    return selectedProfession$.pipe(
      switchMap((professionIds) =>
        combineLatest(professionIds.map((profession) => this.professionService.getOne(profession))),
      ),
      switchMap((professionEntities) =>
        this.getFormGrades().pipe(
          switchMap((grades) =>
            combineLatest(grades.map((g) => this.gradeService.getGrade(g.value.grade))),
          ),
          map((grades) =>
            grades.map((g) => ({
              ...g,
              profession: professionEntities.find((x) => x.id === g.profession),
            })),
          ),
        ),
      ),
    );
  }

  getEntityGradeProfessions(entity: IJobListingEntity) {
    return combineLatest(
      entity.grades.map(({ grade }) => this.gradeService.getGradeProfession(grade)),
    );
  }

  getFormProfessionGrades(): Observable<
    Record<number, { grades: IGradeEntity[]; isValid: boolean }>
  > {
    const selectedProfession$ = this.getFormSelectedProfessions();
    return selectedProfession$.pipe(
      switchMap((selectedProfessions) =>
        this.gradeService.getHospitalProfessionGrades(selectedProfessions),
      ),
      map((grades) => groupBy(grades, 'profession')),
      switchMap((grades) =>
        combineLatest(
          Object.keys(grades).map((profession) =>
            this.getProfessionGradeValidity(+profession).pipe(
              map((isValid) => [profession, { grades: grades[profession], isValid }]),
            ),
          ),
        ).pipe(map((professionArray) => fromPairs(professionArray))),
      ),
    );
  }

  getFormGradeValidity(grades: number[]) {
    return this.store.pipe(select(selectJobListingGradesAreValid(grades)));
  }
  getProfessionGradeValidity(profession: number) {
    return this.store.pipe(select(selectProfessionGradesAreValid(profession)));
  }
  getFormGrades() {
    return this.store.pipe(select(selectEnabledJobListingGrades));
  }
  getFormGradeControl(id: number) {
    return this.store.pipe(select(selectJobListingGradeControl(id)));
  }

  getJobListingConversationProfileFormState() {
    return this.store.pipe(select(selectJobListingConversationProfileFormState));
  }

  getJoblistingPublishingOfficerId(id: number) {
    return this.store.pipe(select(selectJobListingPublishingOfficer(id)));
  }

  getJoblistingPublishingOfficerUserId(listingId: number) {
    return this.getJoblistingPublishingOfficerId(listingId).pipe(
      switchMap((officerId) => this.hospitalOfficerService.getOneUserId(officerId)),
    );
  }

  getJobListingFormState() {
    return this.store.pipe(select(selectJobListingFormWizardState));
  }

  getSelectedCandidateList() {
    return this.store.pipe(select(selectSelectedCandidateList));
  }

  getFormState() {
    return this.store.pipe(select(selectExtendedJobListingFormWizardState));
  }
  isSectionDetailsInvalid() {
    return this.store.pipe(select(selectIsSectionDetailsInvalid));
  }
  isSectionDescriptionInvalid() {
    return this.store.pipe(select(selectIsSectionDescriptionInvalid));
  }
  isSectionGradesInvalid() {
    return this.store.pipe(select(selectIsSectionGradesInvalid));
  }
  isFormInvalid() {
    return this.store.pipe(select(selectIsFormInvalid));
  }

  getShiftCreationIsInvalid() {
    return this.store.pipe(select(selectShiftCreationControlIsInvalid));
  }

  getShiftSchedulerIsInvalid() {
    return this.store.pipe(select(selectShiftSchedulerControlIsInvalid));
  }

  getGradesSectionIsInvalid() {
    return this.getFormProfessionGrades().pipe(
      map((professions) =>
        Object.values(professions).reduce((acc, { isValid }) => acc || !isValid, false),
      ),
    );
  }

  getEntityFromFormState(conversationProperties = {}) {
    return this.getFormState().pipe(map(getEntity(formatTimestamp, conversationProperties)));
  }

  isCascadedToAgencies() {
    return combineLatest([
      this.getJobListingFormId().pipe(filterNotNil()),
      this.staffingCascadeStatusService.getApprovedStatusValue().pipe(filterNotNil()),
    ]).pipe(
      mergeMap(([listingId, approvedStatus]) =>
        this.staffingCascadeService.getForListing(listingId).pipe(
          map((staffingCascade) => {
            if (staffingCascade) {
              return staffingCascade.tierActions[0]?.status === approvedStatus;
            }

            return false;
          }),
        ),
      ),
    );
  }

  getRemainingPositionsToFill() {
    return this.store.pipe(select(selectRemainingPositionsToFill));
  }

  getSubmissionProgressInterval(
    intervalDuration: number,
    initialValueDuration: number,
  ): Observable<number> {
    return this.getSubmissionInProgress().pipe(
      mergeMap((inProgress) => {
        if (inProgress) {
          return timer(initialValueDuration, intervalDuration).pipe(
            takeUntil(this.getSubmissionInProgress().pipe(filter((p) => !p))),
          );
        }
        return of(null);
      }),
    );
  }

  loadByDate(
    date: Date,
    loadApplications: boolean = false,
    loadAdhocs: boolean = true,
    loadProfile: LoadProfileSettings = null,
  ): Observable<Action> {
    const loadDependenciesSettings: LoadDependenciesSettings = {
      loadApplications,
      loadProfileSetting: loadProfile,
    };

    return this.fetch(
      { start_date: Time.formatDate(date, DATE_FORMAT_DB), adhoc: loadAdhocs.toString() },
      loadDependenciesSettings,
    ) as Observable<UpsertMultipleMessage>;
  }

  loadOne(
    id: number,
    loadApplications: boolean = false,
    loadProfile: LoadProfileSettings = null,
    loadBids: boolean = false,
  ): Observable<Action> {
    const loadDependenciesSettings: LoadDependenciesSettings = {
      loadApplications,
      loadBids,
      loadProfileSetting: loadProfile,
    };

    const loadAction$ = this.fetch(id, loadDependenciesSettings) as Observable<UpsertOneMessage>;

    return loadAction$;
  }

  loadByIds(
    ids: number[],
    loadApplications: boolean = false,
    loadProfile: LoadProfileSettings = null,
    loadBids: boolean = false,
  ) {
    const loadDependenciesSettings: LoadDependenciesSettings = {
      loadApplications,
      loadBids,
      loadProfileSetting: loadProfile,
    };

    return concat(
      of(new this.loadingMessages.SetLoadingMessage({})),
      this.fetch({ id: ids }, loadDependenciesSettings),
      of(new this.loadingMessages.ResetLoadingMessage({})),
    );
  }

  loadByStaffingCascadeIds(ids: number[], loadDependenciesSettings: LoadDependenciesSettings) {
    return concat(
      of(new this.loadingMessages.SetLoadingMessage({})),
      this.fetch({ staffingCascade: ids }, loadDependenciesSettings),
      of(new this.loadingMessages.ResetLoadingMessage({})),
    );
  }

  loadAllPagesAndLoadDependencies(namespace: string) {
    return this.loadAllPages(namespace, null).pipe(
      mergeMap((action) => this.loadDependencies(action)),
      mergeMap((x) => x),
    );
  }

  isInEditMode() {
    return this.store.pipe(select(selectParams)).pipe(
      map((params) => !isNil(params['id'])),
      distinctUntilChanged(),
    );
  }
  isExternalJobListingMode() {
    return this.store.pipe(select(selectIsExternalJobListingMode));
  }

  isFormDisabled() {
    return this.getCanCreateEditPastShift().pipe(
      switchMap((getCanCreateEditPastShift) =>
        getCanCreateEditPastShift
          ? of(false)
          : combineLatest([this.getIsEmploymentPeriodInPast(), this.isInEditMode()]).pipe(
              map(
                ([employmentPeriodInThePast, isFormInEditMode]) =>
                  isFormInEditMode && employmentPeriodInThePast,
              ),
              distinctUntilChanged(),
            ),
      ),
      distinctUntilChanged(),
    );
  }

  getCanCreateEditPastShift(): Observable<boolean> {
    return combineLatest([
      this.hospitalService.getHospitalAllowBackdatedShifts(),
      this.permissionService.hasCurrentPermission('add_backdated_joblisting'),
    ]).pipe(map(([allowBackdatedShifts, hasPermission]) => allowBackdatedShifts && hasPermission));
  }

  prepareParams(jobListingParams) {
    let filters: IQueryParams = {};
    for (const param in jobListingParams) {
      if (jobListingParams.hasOwnProperty(param)) {
        if (this.hasParamsToMap(param)) {
          filters = { ...filters, ...jobListingParams[param] };
        } else {
          filters = UrlHelpers.addQueryParams(filters, param, jobListingParams[param]);
        }
      }
    }
    return filters;
  }

  initializePaginationAndLoadDependencies(
    namespace: string,
    jobListingParams: IJobListingParams,
    loadDependenciesSettings?: LoadDependenciesSettings,
    resetState: boolean = false,
  ) {
    const filters = this.prepareParams(jobListingParams);
    return this.initializePagination(namespace, {}, filters, resetState).pipe(
      mergeMap((action) => merge(...this.loadDependencies(action, loadDependenciesSettings))),
    );
  }

  initializeBidPaginationAndLoadDependencies(
    namespace: string,
    externalStaffingCandidateBidParams: IExternalStaffingCandidateBidParams,
  ) {
    const filters = this.prepareParams(externalStaffingCandidateBidParams);
    return this.externalStaffingCandidateBidService
      .initializePagination(namespace, {}, filters)
      .pipe(
        mergeMap((action) => {
          const actions$ = of<Action>(action);
          const payload = (action as externalStaffingCandidateBidMessages.UpsertMultipleMessage)
            .payload;

          if (payload.entities) {
            const cascadeIds = uniq(payload.entities.map((x) => x.staffingCascade));

            const profiles = uniq(payload.entities.map((x) => x.profile));

            return merge(
              this.loadByStaffingCascadeIds(cascadeIds, {}),
              this.profileService.loadByIds(profiles),
              actions$,
            );
          }

          return actions$;
        }),
      );
  }

  loadNextBidAndLoadDependencies(
    namespace: string,
    externalStaffingCandidateBidParams: IExternalStaffingCandidateBidParams,
  ) {
    const filters = this.prepareParams(externalStaffingCandidateBidParams);

    return this.externalStaffingCandidateBidService.loadNext(namespace, {}, filters).pipe(
      mergeMap((action: Action) => {
        const actions$ = of<Action>(action);
        const payload = (action as externalStaffingCandidateBidMessages.UpsertMultipleMessage)
          .payload;

        if (payload.entities) {
          const cascadeIds = payload.entities.map((x) => x.staffingCascade);

          const profiles = payload.entities.map((x) => x.profile);

          return merge(
            this.loadByStaffingCascadeIds(cascadeIds, {}),
            this.profileService.loadByIds(profiles),
            actions$,
          );
        }
        return actions$;
      }),
    );
  }

  loadNextAndLoadDependencies(
    namespace: string,
    jobListingParams: IJobListingParams,
    loadDependenciesSettings: LoadDependenciesSettings,
  ) {
    let filters: IQueryParams = {};

    for (const param in jobListingParams) {
      if (jobListingParams.hasOwnProperty(param)) {
        if (this.hasParamsToMap(param)) {
          filters = { ...filters, ...jobListingParams[param] };
        } else {
          filters = UrlHelpers.addQueryParams(filters, param, jobListingParams[param]);
        }
      }
    }

    return this.loadNext(namespace, {}, filters).pipe(
      mergeMap((action) => this.loadDependencies(action, loadDependenciesSettings)),
      mergeMap((x) => x),
    );
  }

  refreshCurrentPageAndLoadDependencies(
    namespace: string,
    jobListingParams: IJobListingParams,
    loadDependenciesSettings?: LoadDependenciesSettings,
  ) {
    return this.refreshCurrentPage(namespace, {}, jobListingParams as IQueryParams).pipe(
      mergeMap((action) => merge(...this.loadDependencies(action, loadDependenciesSettings))),
    );
  }
  loadPageAndLoadDependencies(
    namespace: string,
    page: number,
    jobListingParams: IJobListingParams,
    loadDependenciesSettings?: LoadDependenciesSettings,
  ) {
    let filters: IQueryParams = {};

    for (const param in jobListingParams) {
      if (jobListingParams.hasOwnProperty(param)) {
        if (this.hasParamsToMap(param)) {
          filters = { ...filters, ...jobListingParams[param] };
        } else {
          filters = UrlHelpers.addQueryParams(filters, param, jobListingParams[param]);
        }
      }
    }

    return this.loadPage(namespace, {}, page, filters).pipe(
      mergeMap((action) => merge(...this.loadDependencies(action, loadDependenciesSettings))),
    );
  }

  getCurrentPageIds(namespace: string) {
    return this.store.pipe(
      select(selectJobListingsSelectedPage),
      switchMap((pageNumber) => this.getPageEntityIds(namespace, pageNumber)),
    );
  }

  getSelectedJobListings() {
    return this.store.pipe(select(selectSelectedJobListings));
  }

  getSelectedJobListingsProfessionIds(): Observable<number[]> {
    return this.getSelectedJobListings().pipe(
      filter((x) => !!x.length),
      switchMap((listings) =>
        combineLatest(listings.map((listing) => this.getProfessionId(listing))),
      ),
      map((professions) => [...new Set(professions)]),
    );
  }

  getSelectedJobListingsListings(): Observable<IJobListingEntity[]> {
    return this.getSelectedJobListings().pipe(
      switchMap((ids) => combineLatest(ids.map((id) => this.getOne(id)))),
    );
  }

  getSelectedJobListingsListingsWithProfession(): Observable<
    (IJobListingEntity & { profession: number })[]
  > {
    return this.getSelectedJobListings().pipe(
      switchMap((ids) => combineLatest(ids.map((id) => this.getOneWithProfession(id)))),
    );
  }

  getOneWithProfession(id: number): Observable<IJobListingEntity & { profession: number }> {
    return this.getOne(id).pipe(
      switchMap((listing) =>
        this.getProfessionId(listing.id).pipe(
          map((profession) => ({
            ...listing,
            profession,
          })),
        ),
      ),
    );
  }

  getSelectedJobListingsGroupedByProfessionId() {
    return this.getSelectedJobListings().pipe(
      switchMap((listings) =>
        combineLatest(
          listings.map((listing) =>
            this.getProfessionId(listing).pipe(
              map((professionId) => ({ listingId: listing, professionId })),
            ),
          ),
        ),
      ),
      map((listings) => groupBy(listings, (listing) => listing.professionId)),
    );
  }

  getSelectedJobListingsCount() {
    return this.store.pipe(select(selectSelectedJobListingsCount));
  }

  getSelectedJobListingHaveLockState(lockState: boolean): Observable<boolean> {
    return this.getSelectedJobListings().pipe(
      mergeMap((selectedJobListingIds) => {
        if (selectedJobListingIds.length) {
          return combineLatest(
            selectedJobListingIds.map((id) => this.getEntityLockedState(id)),
          ).pipe(map((values) => values.every((value) => value === lockState)));
        }
        return of(true);
      }),
    );
  }

  getDisableEscalateOrRemoveButton(remove = false): Observable<boolean> {
    return this.getSelectedJobListings().pipe(
      mergeMap((selectedJobListingIds) => {
        if (selectedJobListingIds.length) {
          return combineLatest(
            selectedJobListingIds.map((id) =>
              this.store.pipe(select(selectJobListingCanEscalateOrRemove(id, remove))),
            ),
          ).pipe(map((values) => values.every((value) => value)));
        }
        return of(true);
      }),
    );
  }

  disablePreMatchButton(): Observable<boolean> {
    return combineLatest([
      this.disableQuickActionButton(),
      this.getCanCreateEditPastShift(),
      this.getSelectedJobListingsRemainingPositionsToFill().pipe(startWith([])),
    ]).pipe(
      switchMap(([disableQuickActionButton, getCanCreateEditPastShift, positionsToFill]) => {
        if (disableQuickActionButton) return of(disableQuickActionButton);
        if (positionsToFill.every((p) => p === 0)) return of(true);
        if (getCanCreateEditPastShift) return of(false);
        return this.getSelectedJobListingsListings().pipe(
          map((listings) => listings.every((l) => isEmploymentPeriodInThePast(l.startTime))),
          distinctUntilChanged(),
        );
      }),
      distinctUntilChanged(),
    );
  }

  getDisableEscalateOrRemoveButtonForAgencies(remove = false): Observable<boolean> {
    return this.getSelectedJobListings().pipe(
      mergeMap((selectedJobListingIds) => {
        if (selectedJobListingIds.length) {
          return combineLatest(
            selectedJobListingIds.map((id) =>
              this.store.pipe(select(selectJobListingCanEscalateOrRemoveForAgencies(id, remove))),
            ),
          ).pipe(map((values) => values.some((value) => value)));
        }
        return of(true);
      }),
    );
  }

  getEntityLockedState(id: number) {
    return this.store.pipe(select(selectJobListingLockedState(id)));
  }

  getSelectedListingsArePending() {
    return this.getSelectedJobListings().pipe(
      mergeMap((listings) => {
        if (listings.length) {
          return combineLatest(
            listings.map((listingId) => this.getListingIsPending(listingId)),
          ).pipe(map((pendingStatuses) => pendingStatuses.every((x) => x)));
        }
        return of(false);
      }),
      distinctUntilChanged(),
    );
  }

  getProfessionId(id: number) {
    return this.store.pipe(
      select(selectJobListingProfessionSpecialty(id)),
      switchMap((pf) => this.professionSpecialtyService.getProfessionId(pf)),
    );
  }

  getCanAuthorizeSelectedListingCascades() {
    return combineLatest([
      this.getSelectedListingsArePending(),
      this.getSelectedJobListings(),
      this.staffingCascadeService.getPendingListingIds(),
      this.staffingCascadeService.getRejectedListingIds(),
    ]).pipe(
      map(
        ([pending, selectedListingIds, pendingListingIds, rejectedListingIds]) =>
          pending &&
          difference(selectedListingIds, [...pendingListingIds, ...rejectedListingIds]).length ===
            0,
      ),
    );
  }

  getCanRejectSelectedListingCascades() {
    return combineLatest([
      this.getSelectedListingsArePending(),
      this.getSelectedJobListings(),
      this.staffingCascadeService.getPendingListingIds(),
      this.staffingCascadeService.getApprovedListingIds(),
    ]).pipe(
      map(
        ([pending, selectedListingIds, pendingListingIds, approvedListingIds]) =>
          pending &&
          difference(selectedListingIds, [...pendingListingIds, ...approvedListingIds]).length ===
            0,
      ),
    );
  }

  getCanCancelSelectedShiftCascades(): Observable<boolean> {
    return combineLatest([
      this.getSelectedJobListings(),
      this.staffingCascadeStatusService.getCancelledStatusValue(),
    ]).pipe(
      switchMap(([selectedJobListingIds, cancelledStatusValue]) => {
        if (selectedJobListingIds.length) {
          return combineLatest(
            selectedJobListingIds.map((id) => this.staffingCascadeService.getForListing(id)),
          ).pipe(
            map((values) =>
              values.every((value) => value && value.status !== cancelledStatusValue),
            ),
          );
        }
        return of(false);
      }),
    );
  }

  getShowShiftDetailsCard() {
    return this.store.pipe(select(selectShowShiftDetailsCard));
  }

  getExpandedJobListings() {
    return this.store.pipe(select(selectExpandedJobListings));
  }

  getExpandedJobListingEntities() {
    return this.getExpandedJobListings().pipe(
      mergeMap((listingIds) =>
        combineLatest(listingIds.map((id) => this.getOne(id) as Observable<IJobListingEntity>)),
      ),
    );
  }

  getJobListingByCascadeId(cascadeId: number) {
    return this.store.pipe(select(selectJobListingByCascadeId(cascadeId)));
  }

  getJobListingById(id: number) {
    return this.store.pipe(select(selectJobListingById(id)));
  }

  getJobListingByIds(ids: number[]) {
    return this.store.pipe(select(selectJobListingByIds(ids)));
  }

  getJobListingFormId() {
    return this.store.pipe(select(selectJobListingFormId));
  }

  getCurrentListingExternalStaffingBookingCount() {
    return this.getCurrentListing().pipe(
      mergeMap(({ staffingCascade }) =>
        this.staffingCascadeService.getExternalStaffingBookingCount(staffingCascade),
      ),
      startWith(0),
    );
  }

  //MD: Return an array of numbers with the page
  //from 1 to the current selected page on job listing list
  getJobListingSelectedPages(): Observable<number[]> {
    return this.store.pipe(
      select(selectJobListingsSelectedPage),
      map((currentPage) => range(1, currentPage)),
    );
  }

  //MD: Calculate the loaded rows until the current selected page
  getLoadedRowsCount(namespace: string): Observable<number> {
    return this.getJobListingSelectedPages().pipe(
      mergeMap((selectedPages) => this.getMultiPageEntitiesCount(namespace, selectedPages)),
    );
  }

  getJobListingListCurrentPageCascadeNotes(namespace) {
    return this.getCurrentPageIds(namespace).pipe(
      filter((x) => !!x),
      mergeMap((listingIds: number[]) =>
        this.staffingCascadeService.getLastPendingTierNotesForListingList(listingIds),
      ),
    );
  }

  getJobListingsCurrentPage(namespace: string) {
    return this.store.pipe(
      select(selectJobListingsSelectedPage),
      switchMap((pageNumber) => this.getPageEntitiesAfterLoading(namespace, pageNumber)),
    );
  }

  getJobListingsCurrentPageWithBookingStats(
    namespace: string,
  ): Observable<IJobListingWithBookingStats[]> {
    return this.getJobListingsCurrentPage(namespace).pipe(
      mergeMap((listings) => {
        if (listings.length) {
          return combineLatest(
            listings.map((listing) =>
              this.getBookingStats(listing.id).pipe(
                map((bookingStats) => ({
                  ...listing,
                  bookingStats,
                })),
              ),
            ),
          );
        }
        return of([]);
      }),
    );
  }

  getTagsOptions(): Observable<ISelectOption[]> {
    return this.tagsService
      .getAll()
      .pipe(map((tags) => tags.map((tag) => ({ id: tag.tag.id, name: tag.tag.display }))));
  }

  getJobListingListCurrentPage(namespace: string, includeBookingStats = false) {
    const bookingStats$ = includeBookingStats
      ? this.getJobListingsCurrentPageWithBookingStats(namespace)
      : this.getJobListingsCurrentPage(namespace);

    return combineLatest([
      bookingStats$,
      this.professionSpecialtyService.getAllActiveWithDetails(),
      this.getTotalCount(namespace),
      this.getTotalPages(namespace),
      this.getSelectedJobListings(),
      this.gradeService.getAll(),
      this.getExpandedJobListings(),
      this.getJobListingListCurrentPageCascadeNotes(namespace),
      this.staffingCascadeService.getAll(),
      this.staffingCascadeStatusService.getAll(),
      this.getJobListingsCurrentPage(namespace).pipe(
        switchMap((listings) => {
          if (!listings.length) return of({});
          return this.jobListingTagsService.getTagsIdsDictionaryByListingIds(
            listings.map((listing) => listing.id),
          );
        }),
      ),
      this.tagsService.getEntityDict(),
      this.siteService.getEntityDict(),
      this.hospitalService.getEntityDict(),
    ]).pipe(
      map(
        ([
          jobListings,
          professionSpecialtiesWithDetails,
          totalCount,
          totalPages,
          selectedJobListings,
          grades,
          expandedJobListings,
          cascadeNotes,
          staffingCascades,
          staffingCascadeStatuses,
          jobListingTags,
          tagsDict,
          siteDict,
          hospitalDict,
        ]): IJobListingListing => {
          const jobListingRows = jobListings.map((jobListing) => {
            const professionSpecialty = professionSpecialtiesWithDetails.find(
              (x) => x.id === jobListing.professionSpecialty,
            );

            const subSpecialty = professionSpecialty?.specialty || null;
            const profession = professionSpecialty?.profession.title || '';
            const site = siteDict[jobListing.site]?.name || '';
            const hospital = hospitalDict[siteDict[jobListing.site]?.trust]?.name || '';
            const grade = grades.find((x) => x.id === jobListing.grades[0].grade);
            const bookingStats: IJobListingBookingStats = get(jobListing, 'bookingStats', null);

            const currentStaffingCascade =
              jobListing.staffingCascade > 0
                ? staffingCascades.find(
                    (staffingCascade) => staffingCascade.id === jobListing.staffingCascade,
                  )
                : null;

            let staffingCascadeStatusCode: StaffingCascadeStatusCode;

            if (currentStaffingCascade) {
              staffingCascadeStatusCode = staffingCascadeStatuses.find(
                (currentStatus) =>
                  currentStatus.val ===
                  currentStaffingCascade.tierActions.find(
                    (x) => x.status === currentStaffingCascade.status,
                  )?.status,
              )?.code;
            }
            const tags =
              jobListingTags[jobListing.id]?.tags
                .map((tagId) => tagsDict[tagId]?.tag)
                .filter((x) => !!x)
                .sort((a, b) => a.display.localeCompare(b.display)) || [];

            const jobListingRow: IJobListingRow = {
              id: jobListing.id,
              startTime: jobListing.startTime,
              endTime: jobListing.endTime,
              title: '#' + jobListing.id + ' - ' + jobListing.title,
              specialty: subSpecialty?.title || null,
              grade: grade ? grade.title : null,
              positionsCount: jobListing.availablePositions,
              applicationsCount: isNil(bookingStats) ? null : bookingStats.totalCandidateCount,
              remainingPositionsToFill: isNil(bookingStats)
                ? null
                : jobListing.availablePositions - bookingStats.bookingCount,
              selected: selectedJobListings.includes(jobListing.id),
              expanded: expandedJobListings.includes(jobListing.id),
              staffingCascade: jobListing.staffingCascade,
              cascadeNotes: cascadeNotes[jobListing.id],
              tierNo: currentStaffingCascade
                ? currentStaffingCascade.tierActions.findIndex(
                    (x) => x.tier === currentStaffingCascade.tier,
                  ) + 1
                : null,
              staffingCascadeStatusCode,
              ratesLocked: jobListing.ratesLocked,
              externalJobListingId: jobListing.externalJobListingId,
              outOfSync: jobListing.outOfSync,
              shiftEscalated: jobListing.shiftEscalated,
              spiScore: jobListing.spiScore,
              employmentPeriodInPast: isEmploymentPeriodInThePast(jobListing.startTime),
              shiftEscalatedForAgencies:
                jobListing.shiftEscalatedForAgencies === undefined
                  ? false
                  : jobListing.shiftEscalatedForAgencies,
              tags,
              profession,
              hospital,
              site,
            };

            return jobListingRow;
          });

          const jobListingListing: IJobListingListing = {
            jobListingRows,
            totalCount,
            totalPages,
          };

          return jobListingListing;
        },
      ),
    );
  }

  getUpdateShiftButtons(id: number) {
    return combineLatest([
      this.getOne(id),
      this.permissionService.hasCurrentPermission('publish_job_listing'),
      this.permissionService.hasCurrentPermission('unpublish_job_listing'),
      this.hospitalService.getAssigned(),
    ]).pipe(
      map(
        ([
          jobListing,
          hasPublishJobListingPermission,
          hasUnpublishJobListingPermission,
          assignedHospital,
        ]: [IJobListingEntity, boolean, boolean, IHospitalEntity]): IJobListingSubmitButton[] => {
          const jobListingSubmitButtons: IJobListingSubmitButton[] = [];

          if (jobListing) {
            if (
              (jobListing.published === false || jobListing.published === null) &&
              hasPublishJobListingPermission
            ) {
              const button: IJobListingSubmitButton = {
                id: 3,
                text: 'Publish',
                type: 'accept',
                code: 'publish',
              };

              if (assignedHospital.hasRestrictedOfficers) {
                button.text = 'Authorise';
              }

              jobListingSubmitButtons.push(button);
            }
            if (
              (jobListing.published === true || jobListing.published === null) &&
              hasUnpublishJobListingPermission
            ) {
              jobListingSubmitButtons.push({
                id: 5,
                text: 'Unpublish',
                type: 'decline',
                code: 'unpublish',
              });
            }
            if (
              jobListing.published === false &&
              assignedHospital.hasRestrictedOfficers &&
              !hasPublishJobListingPermission
            ) {
              jobListingSubmitButtons.push({
                id: 6,
                text: 'Submit for authorisation',
                type: 'accept',
                code: 'submitForAuth',
              });
            }
          }
          return jobListingSubmitButtons;
        },
      ),
    );
  }

  isRostering(): Observable<boolean> {
    return combineLatest([this.getJobListingFormState(), this.jobListingTypeService.getAll()]).pipe(
      map(([jobListingFormState, jobListingTypes]) => {
        const rosteringId = jobListingTypes
          ? get(
              jobListingTypes.find((x) => x.code === 'ROSTERED'),
              'val',
              null,
            )
          : null;

        return jobListingFormState.value.shiftCreation.listingType === rosteringId;
      }),
    );
  }

  isRepeating(): Observable<boolean> {
    return combineLatest([this.getJobListingFormState()]).pipe(
      map(([jobListingFormState]) => jobListingFormState.value.shiftScheduler.isRepeating),
    );
  }

  getJobListingsWithBidsByDate(namespace: string) {
    return this.getConsecutivePageEntities(namespace).pipe(
      switchMap(
        (
          jobListings: IJobListingEntity<Date, number, number, number>[],
        ): Observable<ICalendarShiftSummary[]> => {
          if (!jobListings.length) {
            return of([]);
          }
          return combineLatest(
            jobListings.map((jobListing) =>
              this.professionSpecialtyService
                .getOneWithSpecialty(jobListing.professionSpecialty)
                .pipe(
                  switchMap((professionSpecialty) =>
                    this.externalStaffingCandidateBidService
                      .getAllByCascadeIdWithDetails(jobListing.staffingCascade)
                      .pipe(
                        map((bidsWithStatus) =>
                          this.getAgencyCalendarShiftSummary(
                            jobListing,
                            professionSpecialty.specialty,
                            this.getExternalStaffingCandidateBidsGroups(bidsWithStatus),
                            bidsWithStatus.length,
                          ),
                        ),
                      ),
                  ),
                ),
            ),
          );
        },
      ),
      map((x) =>
        x.sort((a, b) => {
          if (a.published === true && b.published !== true) return -1;
          if (b.published === true && a.published !== true) return 1;

          return a.startTime > b.startTime ? 1 : a.startTime < b.startTime ? -1 : 0;
        }),
      ),
    );
  }

  getBidsByJobListingIds(listingIds: number[]): Observable<IListingBid[]> {
    return combineLatest([
      this.getJobListingByIds(listingIds),
      this.externalStaffingCandidateBidService.getAll(),
    ]).pipe(
      map(([jobListings, bids]) =>
        bids
          .map((bid) => ({
            ...bid,
            listing: jobListings.find(
              (jobListing) => jobListing.staffingCascade === bid.staffingCascade,
            ),
          }))
          .filter((x) => !!x.listing)
          .map((bid) => ({
            ...bid,
            listing: bid.listing.id,
          })),
      ),
    );
  }

  getExpandedJobListingBids() {
    return this.getExpandedJobListings().pipe(
      mergeMap((expandedJobListings: number[]) => this.getBidsByJobListingIds(expandedJobListings)),
    );
  }

  getApplicationSelectedPage() {
    return this.store.pipe(select(selectApplicationSelectedPage));
  }

  getJobListingPendingApplicationsCount(): Observable<number> {
    return this.getJobListingFormId().pipe(
      switchMap((id) => this.applicationService.getPendingApplicationsForListing(id)),
      map((applications) => applications.length),
    );
  }

  getJobListingPendingBidsCount(): Observable<number> {
    return this.getCurrentListing().pipe(
      switchMap(({ staffingCascade }) =>
        this.externalStaffingCandidateBidService.getPendingByCascadeId(staffingCascade),
      ),
      map((staffingCascades) => staffingCascades.length),
    );
  }

  getJobListingPendingApplicationsAndBidsCount(): Observable<number> {
    return combineLatest([
      this.getJobListingPendingApplicationsCount(),
      this.getJobListingPendingBidsCount(),
    ]).pipe(map(([pendingApplications, pendingBids]) => pendingApplications + pendingBids));
  }

  getActiveWizardStep() {
    return this.store.pipe(select(selectors.selectActiveStep));
  }

  getProfessionWizardStep(): Observable<number | null> {
    return this.getActiveWizardStep().pipe(map((x) => (x > 2 ? x - 3 : null)));
  }

  getActiveWizardFormProfession() {
    return combineLatest([this.getProfessionWizardStep(), this.getFormSelectedProfessions()]).pipe(
      map(([step, professionSpecialties]) => (step === null ? null : professionSpecialties[step])),
      distinctUntilChanged(),
    );
  }

  getFormApprovedRates() {
    return combineLatest([
      this.getFormProfessionSpecialtyValues(),
      this.getFormNonResidentOnCallValue(),
    ]).pipe(
      switchMap(([professionSpecialtyIds, nonResidentOnCall]) =>
        combineLatest(
          professionSpecialtyIds.map((professionSpecialtyId) =>
            this.professionSpecialtyService.getOne(professionSpecialtyId).pipe(
              map(({ specialty }) => specialty),
              distinctUntilChanged(),
              switchMap((specialty) =>
                this.approvedRateService.getByGradeForSpecialty(specialty, nonResidentOnCall),
              ),
            ),
          ),
        ),
      ),
      map((approvedRates) =>
        approvedRates.reduce((acc, currentApprovedRates) => {
          for (const grade in currentApprovedRates) {
            if (currentApprovedRates.hasOwnProperty(grade)) {
              acc[grade] ??= [];
              acc[grade].push(...currentApprovedRates[grade]);
            }
          }
          return acc;
        }, {}),
      ),
    );
  }

  getBankHolidaysForCalendar() {
    return this.hospitalService.getAssigned().pipe(
      filterNotNil(),
      switchMap((hospital) =>
        hospital.usesBankHolidayRates
          ? this.bankHolidayService
              .getAll()
              .pipe(map((entities) => entities.map((e) => Time.formatDate(e.date))))
          : of<string[]>([]),
      ),
    );
  }

  getPendingViolationWindowsForForm(includeRepetitions: boolean) {
    return combineLatest([
      this.getFormApprovedRates(),
      this.payRateTypeService.getDefaultTimeBasedPayRateTypeOption(),
      this.hospitalService.getHospitalDetails(),
      this.bankHolidayService.getAll(),
      this.store.pipe(select(selectStartEndRepetition)),
      this.getActiveWizardFormProfession(),
      this.getGradesArray(),
    ]).pipe(
      filter(
        ([, , , , { startTime, endTime }, profession]) =>
          Time.isValidMoment(startTime) && Time.isValidMoment(endTime) && profession !== null,
      ),
      map(
        ([
          approvedRates,
          defaultPayRateType,
          hospitalDetails,
          bankHolidays,
          { startTime, endTime, repetitionDates },
          profession,
          grades,
        ]) =>
          fromPairs(
            grades.controls
              .filter(
                (c) =>
                  !c.userDefinedProperties['ratesApproved'] &&
                  c.userDefinedProperties['profession'] === profession,
              )
              .map((jobListingGrade) => [
                jobListingGrade.value.grade,
                this.getViolatedApprovedRatesForFormGrade(
                  jobListingGrade,
                  approvedRates[jobListingGrade.value.grade] || [],
                  startTime,
                  endTime,
                  defaultPayRateType.id,
                  hospitalDetails.usesBankHolidayRates ? bankHolidays : [],
                  includeRepetitions ? unbox(repetitionDates) : null,
                ),
              ]),
          ),
      ),
    );
  }

  getViolatedApprovedRatesForForm(includeRepetitions: boolean) {
    return combineLatest([
      this.getFormApprovedRates(),
      this.payRateTypeService.getDefaultTimeBasedPayRateTypeOption(),
      this.hospitalService.getHospitalDetails(),
      this.bankHolidayService.getAll(),
      this.store.pipe(select(selectStartEndRepetition)),
      this.store.pipe(select(selectors.selectActiveStep)),
      this.getGradesArray(),
    ]).pipe(
      filter(
        ([, , , , { startTime, endTime }, step]) =>
          Time.isValidMoment(startTime) && Time.isValidMoment(endTime) && step > 2,
      ),
      map(
        ([
          approvedRates,
          defaultPayRateType,
          hospitalDetails,
          bankHolidays,
          { startTime, endTime, repetitionDates },
          _,
          grades,
        ]) =>
          fromPairs(
            grades.controls.map((jobListingGrade) => [
              jobListingGrade.value.grade,
              this.getViolatedApprovedRatesForFormGrade(
                jobListingGrade,
                approvedRates[jobListingGrade.value.grade] || [],
                startTime,
                endTime,
                defaultPayRateType.id,
                hospitalDetails.usesBankHolidayRates ? bankHolidays : [],
                includeRepetitions ? unbox(repetitionDates) : null,
              ),
            ]),
          ),
      ),
    );
  }

  getPendingViolationWindowsExistForForm(includeRepetitions: boolean) {
    return this.getPendingViolationWindowsForForm(includeRepetitions).pipe(
      map((violations) => !!Object.values(violations).filter((val) => val.length).length),
    );
  }

  getViolatedApprovedRatesExistForForm(includeRepetitions: boolean) {
    return this.getViolatedApprovedRatesForForm(includeRepetitions).pipe(
      map((violations) => !!Object.values(violations).filter((val) => val.length).length),
    );
  }

  getLockRatesCeilingExceeded(): Observable<boolean> {
    return combineLatest([
      this.getFormState(),
      this.hospitalService.getHospitalShiftLockRates(),
    ]).pipe(
      map(([form, hospitalLockRates]) => {
        if (hospitalLockRates.lockShiftRatesCeiling) {
          return form.value.gradesSection.grades.some((gradeControl) =>
            gradeControl.jobFragments.some(
              (fragmentFormState) =>
                +fragmentFormState.payRate.rate >= +hospitalLockRates.lockShiftRatesCeiling,
            ),
          );
        }
        return false;
      }),
    );
  }

  getViolatedApprovedRatesForSpecialty(
    flatRate: number,
    gradeControls: FormGroupState<IExtendedJobFragmentFormState>[],
    approvedRatesForSpecialty: IApprovedRateEntity[],
    startDate: string,
    endDate: string,
    defaultPayRateTypeId: number,
    bankHolidays: IBankHolidayEntity[],
    repetitionDate?: string,
  ) {
    const violatedApprovedRatesForSpecialty: ViolationRateWarnings[] = [];
    const flatRates: IJobFragmentRatesEntity[] = [];
    gradeControls.forEach((f) => {
      const fragmentRate = get(f, ['value', 'payRate', 'rate']);
      const timeFragment = get(f, ['value', 'timeFragment']);
      const fragmentCalloutRate = get(f, ['value', 'payRate', 'nonResidentCalloutRate']);

      if (!fragmentRate || !timeFragment || approvedRatesForSpecialty.length === 0) {
        return;
      }

      let jobFromValue = timeFragment.fromTime;
      let jobToValue = timeFragment.toTime;

      if (isString(jobToValue)) {
        jobToValue = Time.toTimeStamp(endDate, jobToValue);
      }

      if (repetitionDate) {
        const shiftStartDate = Time.getMoment(startDate);
        const repetitionDiff = Time.getMoment(repetitionDate).diff(shiftStartDate, 'days');
        const jobFrom = Time.getMoment(jobFromValue).add(repetitionDiff, 'days');
        const jobTo = Time.getMoment(jobToValue).add(repetitionDiff, 'days');
        jobFromValue = jobFrom.unix() * 1000;
        jobToValue = jobTo.unix() * 1000;
      }

      const period = DateTime.rangeForDateFormStartEndTime(jobFromValue, jobToValue);

      const approvedRateFragments = this.generateListingJobFragmentsForRates(
        period,
        approvedRatesForSpecialty,
        defaultPayRateTypeId,
        bankHolidays,
      );

      approvedRateFragments.forEach((rate) => {
        if (rate.payRate.rate && +fragmentRate > +rate.payRate.rate) {
          violatedApprovedRatesForSpecialty.push({
            rate: +rate.payRate.rate,
            fromTime: rate.timeFragment.fromTime,
            toTime: rate.timeFragment.toTime,
            calloutWarning: false,
            flatRate: false,
          });
        }
        if (
          rate.payRate.nonResidentCalloutRate &&
          +fragmentCalloutRate > +rate.payRate.nonResidentCalloutRate
        ) {
          violatedApprovedRatesForSpecialty.push({
            rate: +rate.payRate.nonResidentCalloutRate,
            fromTime: rate.timeFragment.fromTime,
            toTime: rate.timeFragment.toTime,
            calloutWarning: true,
            flatRate: false,
          });
        }
        if (flatRate && rate.flatRate) {
          flatRates.push(rate);
        }
      });
    });
    const approvedFlatRate = maxBy(flatRates, (rate) => rate.flatRate); // -infinity if empty
    if (approvedFlatRate && flatRate > approvedFlatRate.flatRate) {
      violatedApprovedRatesForSpecialty.push({
        rate: approvedFlatRate.flatRate,
        fromTime: approvedFlatRate.timeFragment.fromTime,
        toTime: approvedFlatRate.timeFragment.toTime,
        calloutWarning: false,
        flatRate: true,
      });
    }

    return violatedApprovedRatesForSpecialty;
  }

  getBidRows() {
    return combineLatest([
      this.getExpandedJobListingBids(),
      this.getExpandedJobListingBids().pipe(
        map((externalStaffingCandidateBids) =>
          externalStaffingCandidateBids.map(
            (externalStaffingCandidateBid) => externalStaffingCandidateBid.profile,
          ),
        ),
        mergeMap((ids: string[]) =>
          this.profileService
            .getProfilesByIds(ids)
            .pipe(filter((profile) => profile.every((value) => !!value))),
        ),
      ),
      this.externalStaffingCandidateBidStatusService.getAll(),
    ]).pipe(
      map(
        ([externalStaffingCandidateBids, profiles, externalStaffingCandidateBidStatuses]: [
          IListingBid[],
          IProfileEntity[],
          IExternalStaffingCandidateBidStatusEntity[],
        ]): IExternalStaffingCandidateBidRow[] =>
          this.constructListRows(
            externalStaffingCandidateBids,
            profiles,
            externalStaffingCandidateBidStatuses,
          ),
      ),
    );
  }
  /**
   * Displayed on right hand of bid
   * @todo refactor - identification done
   * @param {IJobFragmentEntity} fragment
   * @return {*}
   * @memberof JobListingService
   */
  getFragmentDetails(fragment: IJobFragmentEntity) {
    if (!fragment) {
      return '';
    }
    const start = Time.formatTime(fragment.timeFragment.fromTime);
    const end = Time.formatTime(fragment.timeFragment.toTime);
    const calloutRate = fragment.payRate.nonResidentCalloutRate
      ? (+fragment.payRate.nonResidentCalloutRate).toFixed(2)
      : null;
    const currency = this.currencySymbol.transform(fragment.payRate.rateCurrency);
    return (
      currency +
      (+fragment.payRate.rate).toFixed(2) +
      ' per hour from ' +
      start +
      ' to ' +
      end +
      '\n' +
      (calloutRate ? `(call-out rate: ${currency}${calloutRate} per hour)` : '')
    );
  }

  getBidDetails(fragment: IBidFragment) {
    if (!fragment) {
      return '';
    }
    const currency = this.currencySymbol.transform(fragment.payRateCurrency);
    const start = Time.formatTime(fragment.fromTime);
    const end = Time.formatTime(fragment.toTime);
    const calloutRate = fragment.nonResidentCalloutRate
      ? (+fragment.nonResidentCalloutRate).toFixed(2)
      : null;
    return (
      currency +
      (+fragment.payRate).toFixed(2) +
      ' per hour from ' +
      start +
      ' to ' +
      end +
      ' ' +
      (calloutRate ? `(call-out rate: ${currency}${calloutRate} per hour)` : '')
    );
  }

  getFlatRateDetails(flatRate: IFlatRateEntity) {
    if (!flatRate) {
      return '';
    }
    return (
      this.currencySymbol.transform(flatRate.rateCurrency) +
      (+flatRate.rate).toFixed(2) +
      ' per shift'
    );
  }

  getFragmentsTotal(bid: IExternalStaffingCandidateBidEntity) {
    let totalRate = 0;
    if (bid.flatRate) {
      totalRate += +bid.flatRate;
    }
    bid.bidFragments.forEach((fragment) => {
      const hours = (fragment.toTime.getTime() - fragment.fromTime.getTime()) / (1000 * 60 * 60);
      const fragmentAgencyFee = +fragment.payRate * hours;
      totalRate += fragmentAgencyFee;
    });
    return totalRate;
  }

  getAgencyTake(bid: IExternalStaffingCandidateBidEntity) {
    if (!isNil(bid.providerFee)) {
      if (isPercentageFee(bid.providerFee)) {
        const fee = +bid.providerFee.feePercentage;
        const fragmentTotal = +this.getFragmentsTotal(bid);
        let totalRate;
        if (bid.providerFee.excluded) {
          totalRate = fragmentTotal;
        } else {
          totalRate = fragmentTotal / ((100 - fee) / 100);
        }
        const agencyTake = (totalRate * fee) / 100;
        return agencyTake;
      }

      if (isFlatFee(bid.providerFee)) {
        return bid.providerFee.fee;
      }
    }

    return null;
  }

  getMyTagsOptions() {
    return this.jobListingTagsService
      .getAll()
      .pipe(switchMap((jts) => this.tagsService.getMyTagsOptions(jts.map((jt) => jt.tag))));
  }

  getHasDeleteModeWarning() {
    return this.jobListingTagsService
      .getAll()
      .pipe(switchMap((jts) => this.tagsService.getHasDeleteModeWarning(jts.map((jt) => jt.tag))));
  }

  getOrganisationTagsOptions() {
    return this.jobListingTagsService
      .getAll()
      .pipe(
        switchMap((jts) => this.tagsService.getOrganisationTagsOptions(jts.map((jt) => jt.tag))),
      );
  }

  getCandidateTakeDisplay(bid: IExternalStaffingCandidateBidEntity, isAgencyView = true) {
    /**
     * Displayed on the outside card of the bid
     */
    const total = +this.getFragmentsTotal(bid);
    const defaultCurrency = this.currencySymbol.transform(DEFAULT_CURRENCY);
    let candidateTake = '';

    let rateAdjustment = 1;
    if (bid.flatRate) {
      if (isAgencyView) {
        candidateTake += `<b>Flat Rate:</b> ${defaultCurrency}${bid.flatRate}<br/>`;
      } else {
        const flatRateHours = (bid.endTime.getTime() - bid.startTime.getTime()) / (1000 * 60 * 60);
        const totalFlatRate = +bid.flatRate;
        candidateTake += `<br/><b>Flat Rate:</b> ${defaultCurrency}${totalFlatRate} `;
        const flatRateCurrency = this.currencySymbol.transform(bid.flatRateCurrency);
        candidateTake +=
          ` (representing ${flatRateCurrency}${(+bid.flatRate / flatRateHours).toFixed(2)} ` +
          `ph for ${flatRateHours} hours)`;
      }
    }

    if (!isAgencyView && !isNil(bid.providerFee)) {
      if (isFlatFee(bid.providerFee)) {
        const agencyTotal = +bid.providerFee.fee;
        rateAdjustment = 1 + agencyTotal / total;
      }
      if (!isNil(bid.providerFee) && isPercentageFee(bid.providerFee)) {
        if (bid.providerFee.excluded) {
          rateAdjustment = 1 + +bid.providerFee.feePercentage / 100;
        } else {
          rateAdjustment = 100 / (100 - +bid.providerFee.feePercentage);
        }
      }
    }

    if (!isAgencyView && bid.bidFragments.length > 0) {
      candidateTake += '<br/><b>Total hourly charge rates</b><br/>';
    }

    bid.bidFragments.forEach((fragment) => {
      const from = Time.formatTime(new Date(fragment.fromTime));
      const to = Time.formatTime(new Date(fragment.toTime));
      const rate = +fragment.payRate * rateAdjustment;
      const currency = this.currencySymbol.transform(fragment.payRateCurrency);
      const calloutRate = fragment.nonResidentCalloutRate
        ? (+fragment.nonResidentCalloutRate).toFixed(2)
        : null;

      const agencyCalloutRate = fragment.nonResidentCalloutAgencyFee
        ? (+fragment.nonResidentCalloutAgencyFee).toFixed(2)
        : null;
      const totalCallOutRates = (+calloutRate + +agencyCalloutRate).toFixed(2);
      const hours = Time.getDurationAsHours(new Date(fragment.fromTime), new Date(fragment.toTime));

      let fragmentTake = '';

      if (isAgencyView) {
        fragmentTake = `<b>${from} - ${to}` + `</b> @ ${currency}${(+rate).toFixed(2)}`;
      } else {
        fragmentTake =
          `<b>${from} - ${to} (${hours}hrs)` +
          `</b> @ ${currency}${(+fragment.payRate + +fragment.agencyFee).toFixed(2)}`;
      }
      const fragmentTotal = !isAgencyView
        ? (hours * (+fragment.payRate + +fragment.agencyFee)).toFixed(2)
        : (hours * +rate).toFixed(2);

      if (!isAgencyView) {
        fragmentTake +=
          `, (${currency}${fragment.payRate} - candidate ` +
          (fragment.agencyFee !== null ? `+ ${currency}${fragment.agencyFee} - agency` : ``) +
          `) = <b>${currency}${fragmentTotal}</b>`;
      }

      if (isAgencyView) {
        fragmentTake += calloutRate
          ? `<br/>(call-out rate: ${currency}${calloutRate} per hour)`
          : '';
      } else {
        fragmentTake += calloutRate
          ? `, [call-out rate: <b>${currency}${totalCallOutRates}ph</b> ` +
            `(${currency}${calloutRate} - candidate` +
            (agencyCalloutRate !== null ? ` + ${currency}${agencyCalloutRate} - agency)` : `)`) +
            `], `
          : ', ';
      }

      candidateTake += fragmentTake + '<br/>';
    });

    candidateTake = candidateTake.replace(/,\s*$/, '');

    if (isAgencyView) {
      const totalString = total.toFixed(2);
      candidateTake += `Total: ${this.currencySymbol.transform(DEFAULT_CURRENCY)}${totalString}`;
    }

    if (bid.bidFragments.some((f) => f.nonResidentCalloutRate) && isAgencyView) {
      candidateTake += `</br></br>*Total is calculated without call-out charges`;
    }
    return candidateTake;
  }

  getTotalPayout(bid: IExternalStaffingCandidateBidEntity) {
    const agencyTake = this.getAgencyTake(bid);
    const candidateTake = +this.getFragmentsTotal(bid);
    return +agencyTake + candidateTake;
  }

  getTotalPayoutDisplay(bid: IExternalStaffingCandidateBidEntity) {
    const totalTake = (+this.getTotalPayout(bid)).toFixed(2);
    return `${this.currencySymbol.transform(DEFAULT_CURRENCY)}${totalTake}`;
  }

  getTotalPayoutBreakDownDisplay(bid: IExternalStaffingCandidateBidEntity) {
    const agencyTake = (+this.getAgencyTake(bid)).toFixed(2);
    const candidateTake = (+this.getFragmentsTotal(bid)).toFixed(2);
    const totalTake = (+this.getTotalPayout(bid)).toFixed(2);

    let feePercentage = '';
    if (!isNil(bid.providerFee) && isPercentageFee(bid.providerFee)) {
      feePercentage = ` (${+bid.providerFee.feePercentage}%)`;
    }

    const currency = this.currencySymbol.transform(DEFAULT_CURRENCY);

    return (
      `<b>Bid Breakdown:</b> ${this.getCandidateTakeDisplay(bid, false)}
      <b>Total charge rate:</b> ${currency}${totalTake} out of which ` +
      `Candidate gets: ${currency}${candidateTake} and Agency gets:` +
      ` ${currency}${agencyTake}${feePercentage}`
    );
  }

  getJobListingGradeDetails(jobListing: IJobListingEntity, grades: IGradeEntity[]) {
    const gradeDetails: IGradeDetails[] = [];
    for (const grade of jobListing.grades) {
      const gradeTitle = grades.find((x) => x.id === grade.grade).title;
      const jobFragments: string[] = [];

      if (grade?.flatRate?.rate !== null) {
        jobFragments.push(this.getFlatRateDetails(grade.flatRate));
      }
      if (grade?.jobFragments[0]?.payRate.rate !== null) {
        for (const fragment of grade.jobFragments) {
          jobFragments.push(this.getFragmentDetails(fragment));
        }
        gradeDetails.push({
          title: gradeTitle,
          details: jobFragments,
        });
      }
    }

    return gradeDetails;
  }

  getGradeDetails(jobListing: IJobListingEntity, grade: IGradeEntity) {
    let gradeDetails: IGradeDetails;
    const jobListingGrade = jobListing.grades.find((x) => x.grade === grade.id);

    if (jobListingGrade) {
      const gradeTitle = grade.title;
      let flatRate = '';
      const jobFragments: string[] = [];

      if (jobListingGrade?.flatRate !== null) {
        flatRate = this.getFlatRateDetails(jobListingGrade.flatRate);
        gradeDetails = {
          title: gradeTitle,
          details: [flatRate],
        };
        return gradeDetails;
      }

      if (jobListingGrade?.jobFragments[0]?.payRate.rate !== null) {
        for (const fragment of jobListingGrade.jobFragments) {
          jobFragments.push(this.getFragmentDetails(fragment));
        }
        gradeDetails = {
          title: gradeTitle,
          details: jobFragments,
        };
      }
    }
    return gradeDetails;
  }

  getEmploymentPeriod(id) {
    return this.getOne(id).pipe(
      map((applicationListing) => ({
        startTime: applicationListing.startTime,
        endTime: applicationListing.endTime,
      })),
    );
  }

  getCurrentListingBids() {
    return this.getSelectedJobListing()
      .pipe(filter((jl) => !!jl))
      .pipe(
        mergeMap(({ staffingCascade }) =>
          this.externalStaffingCandidateBidService.getAllOrEmptyByCascadeId(staffingCascade),
        ),
      );
  }

  getCurrentListingFirstBid() {
    return this.getCurrentListingBids().pipe(
      map((cascades) => get(cascades, 0, null)),
      distinctUntilChanged(),
    );
  }

  getCurrentListingFirstBidId() {
    return this.getCurrentListingFirstBid().pipe(
      map((cascade) => get(cascade, 'id', null)),
      distinctUntilChanged(),
    );
  }

  /**
   * Generates fragments based on approved rates for all the duration of the listing
   * also ensures that generated fragments  are valid and do not contain gaps
   * @param listingDateRange
   * @param rates
   */
  generateMultiDateFragments<T extends IApprovedRateEntity | IExternalStaffingApprovedRateEntity>(
    listingDateRange: DateRange,
    rates: T[],
    bankHolidays: IBankHolidayEntity[],
  ): IJobFragmentRatesEntity[] {
    const fragments = flatMap(
      Array.from(listingDateRange.clone().snapTo('day').by('days')),
      (date, i, collection) => {
        const startDate = i === 0 ? listingDateRange.start : date;
        const endDate = i === collection.length - 1 ? listingDateRange.end : collection[i + 1];
        return this.generateFragments(
          DateTime.rangeForStartEnd(startDate, endDate),
          rates,
          bankHolidays,
        );
      },
    );
    return this.connectJobFragments(fragments);
  }

  /**
   * Generates fragments based on approved rates for a single date
   * @param employmentPeriod
   * @param rates
   */
  generateFragments<T extends IApprovedRateEntity | IExternalStaffingApprovedRateEntity>(
    listingRange: DateRange,
    rates: T[],
    bankHolidays: IBankHolidayEntity[],
  ): IJobFragmentRatesEntity[] {
    // const sortedRates = sortBy(rates, (rate) => rate.rateDefinition.startTime);

    const weekdayPriority = Object.values(WEEKDAYS);

    const sortedRates = rates.sort((a, b) => {
      const aStart = a.rateDefinition.startTime;
      const bStart = b.rateDefinition.startTime;
      if (aStart > bStart) {
        return 1;
      }
      if (bStart > aStart) {
        return -1;
      }

      const aWeekdayPriority = weekdayPriority.indexOf(a.rateDefinition.weekday);
      const bWeekdayPriority = weekdayPriority.indexOf(b.rateDefinition.weekday);

      if (aWeekdayPriority > bWeekdayPriority) {
        return 1;
      }
      if (bWeekdayPriority > aWeekdayPriority) {
        return -1;
      }

      return 0;
    });

    const isBankShift = bankHolidays.some(
      (a) => Time.formatDate(a.date) === listingRange.start.format(DATE_FORMAT),
    );

    return sortedRates
      .map((rate) => {
        const rateRange = this.getRateRangeForEmploymentPeriod(listingRange, rate.rateDefinition);

        if (rateRange) {
          const isBank = isBankShift && rate.rateDefinition.weekday === WEEKDAYS.BANK_HOLIDAYS;

          const isWeekendShift = listingRange.start.day() === 6 || listingRange.start.day() === 0;

          const isWeekend =
            !isBankShift && isWeekendShift && rate.rateDefinition.weekday === WEEKDAYS.WEEKEND;

          const isWeekday =
            !isBankShift && !isWeekendShift && rate.rateDefinition.weekday.includes('Weekday');

          const isSpecificDay =
            !isBankShift &&
            rate.rateDefinition.weekday.includes(DAY_NAMES_MAP[listingRange.start.day()]);

          const isAnyDay = !isBankShift && rate.rateDefinition.weekday.includes('Any Day');

          if (isBank || isWeekend || isWeekday || isSpecificDay || isAnyDay) {
            const start =
              listingRange.start.diff(rateRange.start) >= 0 ? listingRange.start : rateRange.start;
            const end =
              rateRange.end.diff(listingRange.end) >= 0 ? listingRange.end : rateRange.end;
            listingRange = DateTime.rangeForDateFormStartEndTime(+end, +listingRange.end);

            if (!start.isSame(end)) {
              return {
                timeFragment: {
                  fromTime: start.toDate(),
                  toTime: end.toDate(),
                  rateDefinition: rate.rateDefinition.id,
                },
                payRate: {
                  rate: rate.rate.toString(),
                  rateCurrency: rate.rateCurrency as Currency,
                  payRateType: rate.payRateType,
                  nonResidentCalloutRate: rate.nonResidentCalloutRate?.toString() || null,
                  nonResidentCalloutRateCurrency: rate.nonResidentCalloutRate
                    ? ('GBP' as Currency)
                    : null,
                },
                agencyFee: (rate as IExternalStaffingApprovedRateEntity).agencyFee,
                nonResidentCalloutAgencyFee: (rate as IExternalStaffingApprovedRateEntity)
                  .nonResidentCalloutAgencyFee,
                flatRate: (rate as IExternalStaffingApprovedRateEntity).flatRate,
                approvedRate: rate.id,
              };
            }
          }
        }
      })
      .filter((x) => !!x);
  }

  /**
   * Returns rate range for 24 hour employment period
   *
   *
   * @param employmentPeriod
   * @param rateDefinition
   */
  getRateRangeForEmploymentPeriod(
    employmentPeriod: DateRange,
    rateDefinition: IRateDefinitionEntity,
  ) {
    const { startTime, endTime } = rateDefinition;
    const listingStartDate = employmentPeriod.start.clone().startOf('day').toDate();
    // const listingEndDate = employmentPeriod.end.clone().startOf('day').toDate();
    const rateRange = DateTime.roundedRangeForPeriod({
      startDate: listingStartDate,
      endDate: listingStartDate,
      startTime,
      endTime,
    });
    return employmentPeriod.overlaps(rateRange) ? rateRange : null;
  }
  /**
   * Connects unrelated fragments by setting their toTime to the fromTime of
   * the next chronological fragment
   * @param fragments
   */
  connectJobFragments(fragments: IJobFragmentRatesEntity[]): IJobFragmentRatesEntity[] {
    const jobFragments = sortBy(fragments, ({ timeFragment }) => timeFragment.fromTime);
    return jobFragments.map((fragment, i, fragmentsList) => {
      if (i < fragments.length - 1) {
        return {
          ...fragment,
          timeFragment: {
            ...fragment.timeFragment,
            toTime: fragmentsList[i + 1].timeFragment.fromTime,
          },
        };
      }
      return fragment;
    });
  }

  getDefaultJobFragments(listingDateRange: DateRange, defaultPayRateTypeId: number) {
    return [
      {
        timeFragment: {
          fromTime: listingDateRange.start.toDate(),
          toTime: listingDateRange.end.toDate(),
          rateDefinition: null as number | null,
        },
        payRate: {
          rate: null,
          rateCurrency: 'GBP' as Currency,
          payRateType: defaultPayRateTypeId,
          nonResidentCalloutRate: null,
          nonResidentCalloutRateCurrency: null,
        },
        approvedRate: null,
      },
    ];
  }

  generateListingJobFragmentsForRates<
    T extends IExternalStaffingApprovedRateEntity | IApprovedRateEntity,
  >(
    listingDateRange: DateRange,
    rates: T[],
    defaultPayRateTypeId: number,
    bankHolidays: IBankHolidayEntity[],
  ): IJobFragmentRatesEntity[] {
    const jobFragments = this.generateMultiDateFragments(listingDateRange, rates, bankHolidays);
    if (jobFragments.length) {
      return jobFragments;
    }
    return this.getDefaultJobFragments(listingDateRange, defaultPayRateTypeId);
  }

  getApprovedRatesListing<T extends IExternalStaffingApprovedRateEntity | IApprovedRateEntity>(
    listing: IExternalJobListingEntity | IJobListingEntity,
    defaultListingType: number,
    defaultPayRateType: SelectOption<IPayRateType>,
    gradeRates: IGradeRate<T>[],
    bankHolidays: IBankHolidayEntity[],
  ): Observable<IJobListingEntityWithRateDefinitions> {
    const employmentPeriod = DateTime.rangeForStartEnd(
      Time.getMoment(listing.startTime),
      Time.getMoment(listing.endTime),
    );
    const grades: IJobListingGradeEntity<IJobFragmentRatesEntity>[] = gradeRates.map((rates) => {
      const jobFragments = this.generateListingJobFragmentsForRates(
        employmentPeriod,
        rates.rates,
        defaultPayRateType.id,
        bankHolidays,
      );
      const flatRate = calculateApprovedFlatRate(jobFragments);
      return {
        grade: rates.grade,
        flatRate: flatRate
          ? {
              rate: flatRate,
              rateCurrency: 'GBP',
            }
          : null,
        jobFragments,
      };
    });
    return this.createRecommendedRatesListing(
      {
        ...listing,
        grades,
      } as IJobListingEntityWithRateDefinitions,
      defaultListingType,
      listing.startTime ?? null,
    );
  }

  getDirectApprovedRatesListing(
    listing: IJobListingEntity | IExternalJobListingEntity,
    defaultListingType: number,
    defaultPayRateType: SelectOption<IPayRateType>,
    bankHolidays: IBankHolidayEntity[],
  ) {
    return this.getRecommendedRates(listing).pipe(
      switchMap((gradeRates) =>
        this.getApprovedRatesListing(
          listing,
          defaultListingType,
          defaultPayRateType,
          gradeRates,
          bankHolidays,
        ),
      ),
    );
  }

  getRecommendedRates(listing: IJobListingEntity | IExternalJobListingEntity) {
    return this.getSpecialtyFromExternalJobListing(listing).pipe(
      switchMap((specialty) =>
        this.approvedRateService.getGradeDefaults(
          listing.grades.map(({ grade }) => grade),
          specialty,
          !!(listing as IJobListingEntity).nonResidentOnCall,
        ),
      ),
    );
  }

  getSpecialtyFromExternalJobListing(listing: IExternalJobListingEntity | IJobListingEntity) {
    if ((listing as IExternalJobListingEntity).specialty) {
      return of((listing as IExternalJobListingEntity).specialty);
    } else {
      return this.professionSpecialtyService
        .getOne((listing as IJobListingEntity).professionSpecialty)
        .pipe(
          map((professionSpecialty) => professionSpecialty.specialty),
          distinctUntilChanged(),
        );
    }
  }

  getProfessionSpecialtyFromExternalJobListing<
    T extends IExternalJobListingEntity | IJobListingEntity =
      | IExternalJobListingEntity
      | IJobListingEntity,
  >(listing: T) {
    if ((listing as IJobListingEntity).professionSpecialty) {
      return of((listing as IJobListingEntity).professionSpecialty);
    } else {
      return this.professionSpecialtyService
        .getOneByAttributes({
          profession: get(listing as IExternalJobListingEntity, 'profession'),
          specialty: get(listing as IExternalJobListingEntity, 'specialty'),
        })
        .pipe(
          map((professionSpecialty) => professionSpecialty.id),
          distinctUntilChanged(),
        );
    }
  }

  createRecommendedRatesListing<
    T extends IExternalJobListingEntity | IJobListingEntity =
      | IExternalJobListingEntity
      | IJobListingEntity,
  >(listing: T, defaultListingType: number, applicationDeadline: Date): Observable<T> {
    return this.getProfessionSpecialtyFromExternalJobListing<T>(listing).pipe(
      map((professionSpecialty) => ({
        title: listing.title,
        details: listing.details,
        detailsChange: false,
        professionSpecialty,
        site: listing.site,
        roster: listing.roster,
        reasonForVacancy: listing.reasonForVacancy,
        reasonForVacancyChange: false,
        availablePositions: listing.availablePositions,
        externalJobListingId: listing.id,
        startTime: listing.startTime,
        endTime: listing.endTime,
        costCentreNumber: listing.costCentreNumber,
        costCentreNumberChange: false,
        listingType: defaultListingType,
        extraEmails: [],
        files: [],
        repetitionDates: [],
        extendedHours: false,
        remainingPositionsToFill: listing.availablePositions,
        grades: listing.grades,
        applicationDeadline,
        pensionCategory: null,
        shiftEscalated: false,
        spiScore: null,
        crossCoveringProfessionSpecialties: [],
        shiftEscalatedForAgencies:
          listing.shiftEscalatedForAgencies === undefined
            ? false
            : listing.shiftEscalatedForAgencies,
      })),
    ) as Observable<T>;
  }

  getSelectedJobListing(): Observable<IJobListingEntity> {
    return this.getSelectedJobListingId().pipe(mergeMap((listingId) => this.getOne(listingId)));
  }

  getSelectedJobListingProfession(): Observable<IProfessionEntity> {
    return combineLatest([
      this.getSelectedJobListing(),
      this.professionSpecialtyService.getAllWithProfession(),
    ]).pipe(
      filter((jl) => !!jl),
      map(([jobListing, professionSpecialties]) =>
        professionSpecialties
          ? professionSpecialties.find((x) => x.id === jobListing.professionSpecialty).profession
          : null,
      ),
      distinctUntilChanged(),
    );
  }

  defaultToSelectedJobListing(jobListing: IJobListingEntity | null | undefined) {
    return !isNil(jobListing)
      ? of(jobListing)
      : this.getSelectedJobListing().pipe(filter((jl) => !!jl));
  }
  getCurrentListingSite(jobListing: IJobListingEntity = null) {
    return this.defaultToSelectedJobListing(jobListing).pipe(
      switchMap(({ site: id }) => this.siteService.getOne(id).pipe(filter((site) => !!site))),
      distinctUntilChanged(),
    );
  }
  getCurrentListingHospitalId(jobListing: IJobListingEntity = null) {
    return this.defaultToSelectedJobListing(jobListing).pipe(
      switchMap(({ site: id }) => this.siteService.getTrustOf(id).pipe(filter((trust) => !!trust))),
      distinctUntilChanged(),
    );
  }
  getCurrentListingHospital(jobListing: IJobListingEntity = null) {
    return this.getCurrentListingHospitalId(jobListing).pipe(
      mergeMap((id) => this.hospitalService.getOne(id).pipe(filter((trust) => !!trust))),
    );
  }
  getCurrentListingHospitalDeniesBidCounterOffers(jobListing: IJobListingEntity = null) {
    return this.getCurrentListingHospital(jobListing).pipe(
      map((h) => h.enforceApprovedAgencyRates),
      distinctUntilChanged(),
    );
  }
  getCurrentListingHospitalDeniesCustomAgencyFees(jobListing: IJobListingEntity = null) {
    return this.getCurrentListingHospital(jobListing).pipe(
      map((h) => h.enforceApprovedAgencyFees),
      distinctUntilChanged(),
    );
  }
  getCurrentListingStartTime() {
    return this.getSelectedJobListing().pipe(
      map((x) => x.startTime),
      distinctUntilChanged(),
    );
  }
  loadCurrentListingHospital() {
    return merge(
      this.getSelectedJobListing().pipe(
        first(),
        mergeMap(({ site: id }) => this.siteService.loadOneIfNotExists(id)),
      ),
      this.getCurrentListingSite().pipe(
        first(),
        mergeMap(({ trust: id }) => this.hospitalService.loadOneIfNotExists(id)),
      ),
    );
  }

  getSpiScore(id: number) {
    return this.getJobListingById(id).pipe(map((jobListing) => jobListing?.spiScore));
  }

  public getIsEmploymentPeriodInPast() {
    return this.store.pipe(select(selectIsEmploymentPeriodInPast));
  }

  public getIsRepeatingDateInPast() {
    return this.store.pipe(select(selectIsRepeatingDateInPast));
  }

  public getShiftSchedulerControl() {
    return this.store.pipe(select(selectShiftSchedulerControl));
  }

  public getTimeFragmentsControl() {
    return this.store.pipe(select(selectTimeFragmentsControl));
  }

  public getGradesArray() {
    return this.store.pipe(select(selectExtendedFormGradesArray));
  }

  public getRateViolationReasonControl() {
    return this.store.pipe(select(selectRateViolationReasonControl));
  }

  public getRateViolationReasonValue() {
    return this.store.pipe(select(selectRateViolationReasonValue));
  }

  public getCostCentreNumberValue() {
    return this.store.pipe(select(selectCostCentreNumberValue));
  }

  public getRepetitionDatesValue() {
    return this.store.pipe(select(selectRepetitionDatesValue));
  }

  public unpublish(ids: number[]) {
    return this.persistenceService.unpublish(ids);
  }

  public bulkUnpublish(listings: BulkUnpublishJobListingPayload[]) {
    return this.persistenceService.bulkUnpublish(listings);
  }

  public bulkCreateListingNotes(listings: JobListingNotePostPayload[]) {
    return this.persistenceService.bulkCreateListingNotes(listings);
  }

  public disableBulkUnpublishButton(): Observable<boolean> {
    return this.getSelectedJobListings().pipe(
      switchMap((listings) => {
        if (listings.length) return combineLatest(listings.map((l) => this.getBookingStats(l)));
        return of<IJobListingBookingStats[]>([]);
      }),
      map((res) => res.every((r) => r.totalCandidateCount > 0)),
    );
  }

  public getShowingAnyForm(): Observable<boolean> {
    return this.store.pipe(select(selectShowingAnyForm));
  }

  public disableQuickActionButton(): Observable<boolean> {
    return combineLatest([this.getSelectedJobListingsCount(), this.getShowingAnyForm()]).pipe(
      map(([jobListingsCount, showingAnyForm]) => !jobListingsCount || showingAnyForm),
    );
  }

  public getUnpublishButtonText() {
    return this.authGroupService
      .getOfficerBelongsToAuthGroup(AUTH_GROUPS.UNLOCK_SHIFT_RATES)
      .pipe(map((hasUnlockShiftRates) => (hasUnlockShiftRates ? 'REJECT' : 'UNPUBLISH')));
  }

  public blurBidRates(): Observable<boolean> {
    return this.authGroupService.getOfficerBelongsToAuthGroup(AUTH_GROUPS.CASCADE_AUTHORIZER).pipe(
      switchMap((hasPermission) => {
        if (hasPermission) return of(false);
        return concat(
          of(true),
          this.hospitalService.getAssignedBlurBidRates().pipe(filterNotNil()),
        );
      }),
    );
  }

  public getPrematchWarningsOnSelected(): Observable<string[]> {
    return combineLatest([
      this.displayPastWarningOnSelected(),
      this.displayFullyBookedWarningOnSelected(),
    ]).pipe(
      map(([past, fullyBooked]) => {
        const warnings = ['Pre-match invitations will only be sent to eligible candidates'];
        if (past) warnings.unshift(past);
        if (fullyBooked) warnings.unshift(fullyBooked);
        return warnings;
      }),
    );
  }

  public displayPastWarningOnSelected(): Observable<string | null> {
    return this.getSelectedJobListingsListings().pipe(
      map((listings) => listings.some((l) => isEmploymentPeriodInThePast(l.startTime))),
      map((isInPast) => (isInPast ? 'One or more shifts have start date in the past' : null)),
    );
  }

  public displayFullyBookedWarningOnSelected(): Observable<string | null> {
    return this.getSelectedJobListingsRemainingPositionsToFill().pipe(
      map((positions) => positions.some((position) => position === 0)),
      map((fullyBooked) => (fullyBooked ? 'One or more shifts are fully booked' : null)),
    );
  }

  private getSelectedJobListingsRemainingPositionsToFill(): Observable<number[]> {
    return this.getSelectedJobListingsListings().pipe(
      switchMap((listings) =>
        combineLatest(
          listings.map((listing) =>
            this.getBookingStats(listing.id).pipe(
              map((bookingStats) => ({
                availablePositions: listing.availablePositions,
                bookingCount: bookingStats.bookingCount,
              })),
            ),
          ),
        ),
      ),
      map((stats) => stats.map((s) => s.availablePositions - s.bookingCount)),
    );
  }

  private hasParamsToMap(param: string): boolean {
    return ['published', 'cascaded', 'job_status', 'rates_locked', 'shift_escalated'].includes(
      param,
    );
  }

  private getApplicationGroups<T extends { bookingStatus?: IAttributeEntity }>(
    applicationsWithStatus: T[],
  ): { [action: number]: T[] } {
    const initialGroupApplications = {
      [ActionEnum.Approved]: [],
      [ActionEnum.Cancelled]: [],
      [ApplicationExplicitActionEnum.Pending]: [],
      [ApplicationExplicitActionEnum.Other]: [],
    };

    return {
      ...initialGroupApplications,
      ...groupBy(applicationsWithStatus, (application) => {
        const applicationStatus = application.bookingStatus;
        if (
          applicationStatus.code === ApplicationStatusCodes.APPROVED ||
          applicationStatus.code === ApplicationStatusCodes.COMPLETED
        ) {
          return ActionEnum.Approved;
        }
        if (applicationStatus.code === ApplicationStatusCodes.CANCELED) {
          return ActionEnum.Cancelled;
        }
        if (applicationStatus.code === ApplicationStatusCodes.APPLICATION_RECEIVED) {
          return ApplicationExplicitActionEnum.Pending;
        }
        return ApplicationExplicitActionEnum.Other;
      }),
    };
  }

  private getCalendarShiftSummary(
    jobListing: IJobListingEntity,
    subSpecialty: ISubSpecialtyEntity,
    { groupApplications, applicationsCount }: IApplicationGroupDetails,
    { groupExternalStaffingCandidateBids, bidsCount }: IBidGroupDetails,
    staffingCascadeStatusCode: string,
  ): ICalendarShiftSummary {
    const approvedApplications: string[] = groupApplications[ActionEnum.Approved].map(
      (application) => {
        const profile = application.profile;
        return `${profile.firstName} ${profile.lastName} (Bank)`;
      },
    );

    const approvedBids: string[] = groupExternalStaffingCandidateBids[ActionEnum.Approved].map(
      (externalStaffingCandidateBid) => {
        const profile = externalStaffingCandidateBid.profile;
        return `${profile.firstName} ${profile.lastName} (Agency)`;
      },
    );

    const cancelledApplications: string[] = groupApplications[ActionEnum.Cancelled].map(
      (application) => {
        const profile = application.profile;
        return `${profile.firstName} ${profile.lastName}`;
      },
    );

    const cancelledBids: string[] = groupExternalStaffingCandidateBids[ActionEnum.Cancelled].map(
      (externalStaffingCandidateBid) => {
        const profile = externalStaffingCandidateBid.profile;
        return `${profile.firstName} ${profile.lastName}`;
      },
    );
    const pendingApplications: number =
      groupApplications[ApplicationExplicitActionEnum.Pending].length;

    const pendingExternalStaffingCandidateBids: number =
      groupExternalStaffingCandidateBids[ApplicationExplicitActionEnum.Pending].length;

    let noActivity = 0;
    if (approvedApplications.length + approvedBids.length !== jobListing.availablePositions) {
      noActivity =
        jobListing.availablePositions - approvedApplications.length - approvedBids.length;
    }
    const pending = pendingApplications + pendingExternalStaffingCandidateBids;
    const cancelled = cancelledApplications.concat(cancelledBids);
    const approved = approvedApplications.concat(approvedBids);
    return {
      title: jobListing.title,
      startTime: jobListing.startTime,
      endTime: jobListing.endTime,
      subSpecialty,
      pending,
      cancelled,
      approved,
      totalApplications: applicationsCount + bidsCount,
      id: jobListing.id,
      noActivity,
      published: jobListing.published,
      totalPositions: jobListing.availablePositions,
      cascadedToAgencies: !isNil(staffingCascadeStatusCode),
      cascadedStatusCode: staffingCascadeStatusCode || null,
      isApplication: true,
    };
  }

  loadPublishingOfficersFor(selectedJobListings: number[]) {
    return combineLatest(
      selectedJobListings.map((selectedJobListing) =>
        this.getJoblistingPublishingOfficerId(selectedJobListing),
      ),
    ).pipe(
      first(),
      switchMap((ids) => this.hospitalOfficerService.loadByIds(ids)),
    );
  }

  public getAssignedProviderTrustIds() {
    return this.externalStaffingProviderService
      .getAssigned()
      .pipe(
        switchMap((provider) =>
          this.trustExternalStaffingProviderTierService.getTrustIdsByProviderId(provider.id),
        ),
      );
  }

  private loadDependencies(
    action: Action,
    loadDependenciesSettings: LoadDependenciesSettings | Record<string, never> = {},
  ) {
    const actions: Observable<Action>[] = [of(action)];

    if (
      action.type === JobListingMessageTypes.UPSERT_MULTIPLE ||
      action.type === JobListingMessageTypes.SET_COLLECTION
    ) {
      const payload = (action as UpsertMultipleMessage).payload;
      if (loadDependenciesSettings) {
        const jobListings = payload.entities.map((x) => x.id);
        const cascadeIds = payload.entities.map((x) => x.staffingCascade).filter((x) => !!x);

        if (cascadeIds.length > 0 && loadDependenciesSettings.loadStaffingCascades) {
          actions.push(this.staffingCascadeService.loadByJobListingIds(jobListings));
        }
        if (loadDependenciesSettings.loadBookingStats) {
          actions.push(this.loadBookingStats(jobListings));
        }
        if (loadDependenciesSettings.loadApplications || loadDependenciesSettings.loadBids) {
          if (jobListings.length > 0 && loadDependenciesSettings.loadApplications) {
            actions.push(
              this.applicationService.loadByListingIds(
                jobListings,
                loadDependenciesSettings.loadProfileSetting,
              ),
            );
          }

          if (cascadeIds.length > 0 && loadDependenciesSettings.loadBids) {
            actions.push(
              this.externalStaffingCandidateBidService.loadByStaffingCascadeIds(
                cascadeIds,
                loadDependenciesSettings.loadProfileSetting,
              ),
            );
          }
        }

        if (loadDependenciesSettings.loadTags) {
          actions.push(of(new InitializeListPageTagSignal({ listings: jobListings })));
        }

        if (loadDependenciesSettings.loadHospitals) {
          const loadHospitalsAction = this.getAssignedProviderTrustIds().pipe(
            switchMap((ids) => this.hospitalService.loadManyIfNotExists(ids)),
          );

          actions.push(loadHospitalsAction);
        }
      }
    }

    return actions;
  }

  private getExternalStaffingCandidateBidsGroups<T extends { bookingStatus?: IAttributeEntity }>(
    bidsWithStatus: T[],
  ): { [action: number]: T[] } {
    const initialGroupExternalStaffingCandidateBids = {
      [ActionEnum.Approved]: [],
      [ActionEnum.Cancelled]: [],
      [ApplicationExplicitActionEnum.Pending]: [],
      [ApplicationExplicitActionEnum.Other]: [],
    };
    return {
      ...initialGroupExternalStaffingCandidateBids,
      ...groupBy(bidsWithStatus, (externalStaffingCandidateBid) => {
        const code = externalStaffingCandidateBid.bookingStatus.code;
        if (
          code === ExternalStaffingCandidateBidStatusCodes.APPROVED ||
          code === ExternalStaffingCandidateBidStatusCodes.COMPLETED
        ) {
          return ActionEnum.Approved;
        }
        if (code === ExternalStaffingCandidateBidStatusCodes.CANCELED) {
          return ActionEnum.Cancelled;
        }
        if (code === ExternalStaffingCandidateBidStatusCodes.SUBMITTED) {
          return ApplicationExplicitActionEnum.Pending;
        }
        return ApplicationExplicitActionEnum.Other;
      }),
    };
  }

  private getAgencyCalendarShiftSummary(
    jobListing: IJobListingEntity,
    subSpecialty: ISubSpecialty,
    groupExternalStaffingCandidateBids: {
      [key: string]: IExternalStaffingCandidateBidEntity<
        number,
        number,
        IProfileEntity,
        IAttributeEntity
      >[];
    },
    bidsCount,
  ): ICalendarShiftSummary {
    const approved = groupExternalStaffingCandidateBids[ActionEnum.Approved].map(
      (bid) => `${bid.profile.firstName} ${bid.profile.lastName}`,
    );

    const cancelled = groupExternalStaffingCandidateBids[ActionEnum.Cancelled].map(
      (bid) => `${bid.profile.firstName} ${bid.profile.lastName}`,
    );

    const pendingExternalStaffingCandidateBids =
      groupExternalStaffingCandidateBids[ApplicationExplicitActionEnum.Pending].length;

    return {
      title: jobListing.title,
      startTime: jobListing.startTime,
      endTime: jobListing.endTime,
      subSpecialty,
      pending: pendingExternalStaffingCandidateBids,
      cancelled,
      approved,
      totalApplications: bidsCount,
      id: jobListing.id,
      noActivity: jobListing.availablePositions,
      published: jobListing.published,
      totalPositions: jobListing.availablePositions,
      isApplication: false,
    };
  }

  private getViolatedApprovedRatesForFormGrade(
    jobListingGrade: FormGroupState<IJobListingExtendedGradeFormState>,
    approvedRatesForSpecialty: IApprovedRateEntity[],
    startDate: string,
    endDate: string,
    defaultPayRateTypeId: number,
    bankHolidays: IBankHolidayEntity[],
    repetitionDates?: string[],
  ) {
    const violatedApprovedRatesForSpecialty: ViolationRateWarnings[] = [];
    const gradeControls = get(
      jobListingGrade,
      ['controls', 'jobFragments', 'controls'],
      [],
    ) as FormGroupState<IExtendedJobFragmentFormState>[];

    violatedApprovedRatesForSpecialty.push(
      ...this.getViolatedApprovedRatesForSpecialty(
        +(jobListingGrade.value.flatRate.rate || 0),
        gradeControls,
        approvedRatesForSpecialty,
        startDate,
        endDate,
        defaultPayRateTypeId,
        bankHolidays,
      ),
    );

    if (repetitionDates) {
      repetitionDates.forEach((date) => {
        violatedApprovedRatesForSpecialty.push(
          ...this.getViolatedApprovedRatesForSpecialty(
            +(jobListingGrade.value.flatRate.rate || 0),
            gradeControls,
            approvedRatesForSpecialty,
            startDate,
            endDate,
            defaultPayRateTypeId,
            bankHolidays,
            date,
          ),
        );
      });
    }

    return violatedApprovedRatesForSpecialty;
  }

  private constructListRows(
    externalStaffingCandidateBids: IListingBid[],
    profiles: IProfileEntity[],
    bidStatuses: IExternalStaffingCandidateBidStatusEntity[],
  ): IExternalStaffingCandidateBidRow[] {
    return externalStaffingCandidateBids.map((externalStaffingCandidateBid) => {
      const profile = profiles
        ? profiles.find((x) => x.id === externalStaffingCandidateBid.profile)
        : null;

      const status = bidStatuses
        ? bidStatuses.find((x) => x.val === externalStaffingCandidateBid.bookingStatus)
        : null;
      let statusCode = '';

      if (status) statusCode = status.code;

      let reasonForCancellation = '';
      if (isNegativeBidResponseCode(status.code)) {
        const bidActions = externalStaffingCandidateBid.bidActions.filter(
          (x) => x.status === status.val,
        );
        reasonForCancellation = get(bidActions, [bidActions.length - 1, 'notes'], '');
      }

      let agencyAmount: string;
      if (!isNil(externalStaffingCandidateBid.providerFee)) {
        if (externalStaffingCandidateBid.providerFee.hasOwnProperty('feePercentage')) {
          agencyAmount =
            +get(externalStaffingCandidateBid.providerFee, 'feePercentage') === 0
              ? null
              : this.externalStaffingCandidateBidService.getAgencyAmount(
                  externalStaffingCandidateBid,
                );
        } else if (externalStaffingCandidateBid.providerFee.hasOwnProperty('fee')) {
          agencyAmount =
            +get(externalStaffingCandidateBid.providerFee, 'fee') === 0
              ? null
              : this.externalStaffingCandidateBidService.getAgencyAmount(
                  externalStaffingCandidateBid,
                );
        }
      }

      const externalStaffingCandidateBidRow: IExternalStaffingCandidateBidRow = {
        id: externalStaffingCandidateBid.id,
        jobListingId: externalStaffingCandidateBid.listing,
        profileId: externalStaffingCandidateBid.profile,
        profilePictureUrl: profile ? profile.photo : null,
        name: profile ? profile.firstName + ' ' + profile.lastName : null,
        statusDisplay: status ? status.display : null,
        statusFont: this.externalBidStatusAlertType.transform(statusCode),
        statusOrder: status ? status.val : null,
        candidateCurrency:
          externalStaffingCandidateBid.bidFragments.length > 0
            ? externalStaffingCandidateBid.bidFragments[0].payRateCurrency
            : externalStaffingCandidateBid.flatRateCurrency,
        candidateAmount: this.externalStaffingCandidateBidService.getCandidateAmount(
          externalStaffingCandidateBid,
        ),
        agencyAmount,
        directEngagement: externalStaffingCandidateBid.directEngagementCandidate
          ? 'Direct Engagement'
          : 'Non Direct Engagement',
        createdAt: externalStaffingCandidateBid.createdAt,
        reasonForCancellation,
        rowType: IRowEnum.BID,
      };

      return externalStaffingCandidateBidRow;
    });
  }
}
