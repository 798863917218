import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { isNumber, isString } from 'lodash-es';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { Query, StateService } from '@locumsnest/core/src';
import { filterNonEmpty } from '@locumsnest/core/src/lib/ngrx/operators';

import {
  IDeclineFormsState,
  IDeclineFormsStateWithExtras,
} from '../../application/+state/interfaces/list-state';
import {
  selectApproveFormState,
  selectDeclineFormState,
} from '../../application/+state/list-state';
import { IDeclineApplicationReasonEntity } from '../../interfaces/api/decline-application-reason-entity';
import { UpsertMultipleMessage, UpsertOneMessage } from './decline-application-reason.messages';
import { DeclineApplicationReasonPersistenceService } from './decline-application-reason.persistence.service';
import {
  selectAllDeclineApplicationReasons,
  selectAllDeclineApplicationReasonsSetByOfficer,
  selectDeclineApplicationReason,
  selectDeclineApplicationReasonByCode,
  selectDeclineApplicationReasonOptions,
} from './decline-application-reason.selectors';

@Injectable({
  providedIn: 'root',
})
export class DeclineApplicationReasonService extends StateService<IDeclineApplicationReasonEntity> {
  constructor(
    private store: Store,
    protected persistenceService: DeclineApplicationReasonPersistenceService
  ) {
    super();
  }

  getAll() {
    return this.store.pipe(select(selectAllDeclineApplicationReasons), filterNonEmpty);
  }

  getDeclineApplicationReason(id: number) {
    return this.store.pipe(select(selectDeclineApplicationReason(id)));
  }

  getAllSetByOfficer() {
    return this.store.pipe(select(selectAllDeclineApplicationReasonsSetByOfficer));
  }

  getDeclineApplicationReasonOptions() {
    return this.store.pipe(select(selectDeclineApplicationReasonOptions));
  }

  getDeclineApplicationReasonByCode(code: string) {
    return this.store.pipe(select(selectDeclineApplicationReasonByCode(code)));
  }

  getApproveFormState() {
    return this.store.pipe(select(selectApproveFormState));
  }

  getDeclineApplicationsReasonWithExtras() {
    return combineLatest(
      this.store.pipe(select(selectDeclineFormState)),
      this.getDeclineApplicationReasonByCode('OTHER')
    ).pipe(
      map(
        ([formState, otherOption]: [
          IDeclineFormsState,
          IDeclineApplicationReasonEntity
        ]): IDeclineFormsStateWithExtras => {
          const declineForms: IDeclineFormsStateWithExtras = {};

          for (const key in formState) {
            if (formState.hasOwnProperty(key) && otherOption !== undefined) {
              const innerKey = parseInt(key, 10);
              declineForms[key] = {
                formGroup: formState[innerKey],
                isOtherReasonSelected:
                  formState[key].controls.declineReason.value === otherOption.id,
              };
            }
          }

          return declineForms;
        }
      )
    );
  }

  fetch(query?: Query) {
    if (isString(query) || isNumber(query))
      return this.persistenceService.retrieve(query).pipe(
        mergeMap((declineApplicationReason) => {
          const actions: Observable<Action>[] = [
            of(new UpsertOneMessage({ declineApplicationReason })),
          ];

          return actions;
        }),
        mergeMap((x) => x)
      );

    return this.persistenceService.retrieve(query).pipe(
      mergeMap(({ results }) => {
        const actions: Observable<Action>[] = [
          of(new UpsertMultipleMessage({ declineApplicationReasons: results })),
        ];

        return actions;
      }),
      mergeMap((x) => x)
    );
  }
}
