import { createSelector } from '@ngrx/store';
import { FormGroupState } from 'ngrx-forms';

import { ICertificateTypeEntity } from '../../../interfaces/api/certificate-type-entity';
import { ISafeboxCertificateEntity } from '../../../interfaces/api/safebox-certificate-entity';
import { ISafeboxReferenceEntity } from '../../../interfaces/api/safebox-reference-entity';
import { State } from '../interfaces';
import { selectProfileState } from '../profile.selectors';
import { State as UIState } from '../ui';
import { ICertificateEntity } from './../../../interfaces/api/certificate-entity';
import { IReferenceEntity } from './../../../interfaces/api/reference-entity';
import {
  DocumentTypes,
  IPassportDocument,
  ISafeboxDocument,
  IUploadFileFormState,
} from './../interfaces/profile-ui-state';
import { alertStateAdapter } from './ui.adapter';

// #region getSelectors

export const getDigitalPassportDocuments = (
  references: IReferenceEntity[],
  certificates: ICertificateEntity[],
  certificateTypes: ICertificateTypeEntity[],
  copiedCertificates: number[] = [],
  copiedReferences: number[] = []
): IPassportDocument[] => {
  const documents: IPassportDocument[] = [];

  let count = 0;
  for (const reference of references) {
    if (!reference.received) continue;

    count++;
    documents.push({
      display: 'Reference ' + count,
      type: DocumentTypes.reference,
      url: '',
      text: reference.referenceText,
      id: reference.id,
      original: reference,
      isCopied: copiedReferences.some((x) => x === reference.id),
    });
  }

  for (const certificate of certificates) {
    count++;
    const type = certificateTypes.find((a) => a.id === certificate.type);
    let display = certificate.title;

    // if type is found and the type is not 'other' then show type's name not certificate's title
    if (type && type.slug !== 'other') {
      display = type.name;
    }

    documents.push({
      display,
      type: DocumentTypes.certificate,
      url: certificate.file !== null ? certificate.file.file : '',
      text: '',
      id: certificate.id,
      original: certificate,
      expirationDate: certificate.expirationDate,
      isCopied: copiedCertificates.some((x) => x === certificate.id),
    });
  }
  return documents;
};

export const getDigitalSafeboxReferences = (
  safeboxReferences: ISafeboxReferenceEntity[]
): ISafeboxDocument[] => {
  const documents: ISafeboxDocument[] = [];

  let count = 0;
  for (const safeboxReference of safeboxReferences) {
    count++;
    documents.push({
      display: 'Reference ' + count,
      type: DocumentTypes.reference,
      url: '',
      text: safeboxReference.referenceText,
      id: safeboxReference.id,
      expirationDate: safeboxReference.expirationDate,
      original: safeboxReference,
    });
  }

  return documents.sort((a, b) => {
    if (a.expirationDate === null) return 1;
    if (b.expirationDate === null) return -1;
    return a.expirationDate > b.expirationDate ? 1 : a.expirationDate < b.expirationDate ? -1 : 0;
  });
};

export const getDigitalSafeboxCertificates = (
  safeboxCertificates: ISafeboxCertificateEntity[],
  certificateTypes: ICertificateTypeEntity[]
): ISafeboxDocument[] => {
  const documents: ISafeboxDocument[] = [];

  for (const safeboxCertificate of safeboxCertificates) {
    const type = certificateTypes.find((a) => a.id === safeboxCertificate.type);
    let display = safeboxCertificate.title;

    // if type is found and the type is not 'other' then show type's name not certificate's title
    if (type && type.slug !== 'other') {
      display = type.name;
    }

    documents.push({
      display,
      type: DocumentTypes.certificate,
      url: safeboxCertificate.file !== null ? safeboxCertificate.file.file : '',
      text: '',
      id: safeboxCertificate.id,
      expirationDate: safeboxCertificate.expirationDate,
      original: safeboxCertificate,
    });
  }

  return documents.sort((a, b) => {
    if (a.expirationDate === null) return 1;
    if (b.expirationDate === null) return -1;
    return a.expirationDate > b.expirationDate ? 1 : a.expirationDate < b.expirationDate ? -1 : 0;
  });
};

export const getComplianceMessageFormState = (uiState: UIState) => uiState.complianceMessageForm;

export const getNoteFormState = (uiState: UIState) => uiState.noteFormState;

export const getUploadFileFormState = (uiState: UIState) => uiState.safeboxUploadFileFormState;

export const getUploadFileType = (uploadFileFormState: FormGroupState<IUploadFileFormState>) =>
  uploadFileFormState.controls.type.value;

export const getCopiedCertificatesUiState = (uiState: UIState) => uiState.copiedCertificates;

export const getCopiedReferencesUiState = (uiState: UIState) => uiState.copiedReferences;

export const getSelectedTabUiState = (uiState: UIState) => uiState.selectedTab;

export const getShowFlaggedNotesUiState = (uiState: UIState) => uiState.showFlaggedNotes;

export const getShowFlaggedNotesForProfileUiState = (uiState: UIState) =>
  uiState.showFlaggedNotesProfileIds;

export const getShowFlaggedNotesForProfile = (profileId: string) => (uiState: UIState) =>
  uiState.showFlaggedNotesProfileIds[profileId];

export const getCloneDocumentToHospitalFormState = (uiState: UIState) =>
  uiState.cloneDocumentToHospitalFormState;

export const getUiState = (state: State) => state.uiState;

const selectors = alertStateAdapter.getSelectors('alertState');

// #endregion getSelectors

// #region selectSelectors

export const selectProfileUiState = createSelector(selectProfileState, getUiState);

export const selectEmploymentHistoryFilterUiState = createSelector(
  selectProfileUiState,
  (profileUi) => profileUi.employmentHistoryFilter
);

export const selectProfileAssignmentNumbersFilterUiState = createSelector(
  selectProfileUiState,
  (profileUi) => profileUi.profileAssignmentNumbersFilter
);

export const selectComplianceMessageFormState = createSelector(
  selectProfileUiState,
  getComplianceMessageFormState
);

export const selectNoteFormState = createSelector(selectProfileUiState, getNoteFormState);

export const selectUploadFileFormState = createSelector(
  selectProfileUiState,
  getUploadFileFormState
);

export const selectUploadFileType = createSelector(selectUploadFileFormState, getUploadFileType);

export const selectCopiedCertificatesUiState = createSelector(
  selectProfileUiState,
  getCopiedCertificatesUiState
);

export const selectCopiedReferencesUiState = createSelector(
  selectProfileUiState,
  getCopiedReferencesUiState
);

export const selectShowFlaggedNotesUiState = createSelector(
  selectProfileUiState,
  getShowFlaggedNotesUiState
);

export const selectShowFlaggedNotesForProfileUiState = createSelector(
  selectProfileUiState,
  getShowFlaggedNotesForProfileUiState
);

export const selectShowFlaggedNotesForProfile = (profileId: string) =>
  createSelector(selectProfileUiState, getShowFlaggedNotesForProfile(profileId));

export const selectProfileAlertToDisplay = createSelector(
  selectProfileUiState,
  selectors.selectAlertState
);

export const selectCloneDocumentToHospitalFormState = createSelector(
  selectProfileUiState,
  getCloneDocumentToHospitalFormState
);

export const selectSelectedTab = createSelector(selectProfileUiState, getSelectedTabUiState);

// #endregion selectSelectors
