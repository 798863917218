import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, map, Observable, startWith } from 'rxjs';

import { SelectOption } from '@locumsnest/components/src/lib/interfaces';
import { StateService } from '@locumsnest/core';
import { filterNonEmpty } from '@locumsnest/core/src/lib/ngrx/operators';

import { HospitalService } from '../../hospital/+state/hospital.services';
import { IPayRateTypeEntity } from '../../interfaces/api/pay-rate-type-entity';
import { IPayRateType } from '../../interfaces/pay-rate-types';
import { SetCollectionMessage } from './pay-rate-type.messages';
import { PayRateTypePersistenceService } from './pay-rate-type.persistence.service';
import { selectAllPayRateTypes, selectPayRateTypeOptions } from './pay-rate-type.selectors';

@Injectable({
  providedIn: 'root',
})
export class PayRateTypeService extends StateService<IPayRateTypeEntity> {
  constructor(
    private store: Store,
    protected persistenceService: PayRateTypePersistenceService,
    private hospitalService: HospitalService
  ) {
    super();
  }
  getAll() {
    return this.store.pipe(select(selectAllPayRateTypes)).pipe(filterNonEmpty);
  }

  getPayRateTypeOptions(): Observable<SelectOption<IPayRateType>[]> {
    const options$: Observable<SelectOption<IPayRateType>[]> = this.store
      .pipe(select(selectPayRateTypeOptions))
      .pipe(
        filter((options) => options && options.length > 0),
        map((options) =>
          options.map((option) => ({
            ...option,
            name: `per ${option.name}`,
          }))
        )
      );
    const perShift = {
      id: -1,
      isFlat: true,
      name: 'per shift',
      slug: 'psh',
    };

    return options$.pipe(
      map((options: SelectOption<IPayRateType>[]) => [...options, perShift]),
      startWith([])
    );
  }

  getDefaultTimeBasedPayRateTypeOption() {
    return this.getPayRateTypeOptions().pipe(
      map((options) => options.filter((option) => !option.isFlat)[0]),
      filter((option) => !!option)
    );
  }
  fetch() {
    return this.persistenceService
      .retrieve()
      .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));
  }
}
