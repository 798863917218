import { MessageableFactory } from '@locumsnest/core/src';
import { SignalableFactory } from '@locumsnest/core/src';
import { createAlertStateAdapter } from '@locumsnest/core/src/lib/adapters/alert-state-adapter';

export const messageableFactory = MessageableFactory.forFeature<'ExternalStaffingCandidateBidForm'>(
  'ExternalStaffingCandidateBidForm'
);
export const signalableFactory = SignalableFactory.forFeature<'ExternalStaffingCandidateBidForm'>(
  'ExternalStaffingCandidateBidForm'
);
export const alertStateAdapter = createAlertStateAdapter(signalableFactory, messageableFactory);
export const { errorHandler, conditionalErrorHandler } = alertStateAdapter.getAlertHandlers();
export const { ResetErrorMessage, AlertErrorMessage } = alertStateAdapter.getMessages();
