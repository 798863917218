import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IHospitalProfileTagEntity } from '../../interfaces/api/hospital-profile-tag-entity';

@Injectable({
  providedIn: 'root',
})
export class HospitalProfileTagPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IHospitalProfileTagEntity
> {
  protected readonly endpoint = 'hospitalProfileTag';
}
