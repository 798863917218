import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { isString } from 'lodash-es';
import { combineLatest, map, mergeMap, Observable, of } from 'rxjs';

import { IQueryParams, PaginatedSubresourceStateService, RequestOptions } from '@locumsnest/core';
import { IEmploymentHistory } from '@locumsnest/profile-ui/src/interfaces/employment-history-entity';

import { ApplicationStatusService } from '../../application-status/+state/application-status.service';
import { HospitalService } from '../../hospital/+state/hospital.services';
import { IApplicationHistoryEntity } from '../../interfaces/api/application-history-entity';
import { SubSpecialtyService } from '../../sub-specialty/+state/sub-specialty.service';
import {
  ApplicationHistoryMessageTypes,
  ResetApplicationHistoryPaginationMessage,
  SetCollectionMessage,
  UpsertApplicationHistoryPageMessage,
  UpsertMultipleMessage,
} from './application-history.messages';
import { ApplicationHistoryPersistenceService } from './application-history.persistence.service';
import {
  applicationHistoryPaginationSelectors,
  selectAllApplicationHistories,
  selectApplicationHistoriesByProfileId,
  selectApplicationHistoryEntityState,
} from './application-history.selectors';

export interface ApplicationHistoryRequestOptions {
  profileId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApplicationHistoryService extends PaginatedSubresourceStateService<
  IApplicationHistoryEntity,
  UpsertApplicationHistoryPageMessage,
  ResetApplicationHistoryPaginationMessage,
  UpsertMultipleMessage,
  RequestOptions<ApplicationHistoryRequestOptions>
> {
  constructor(
    protected store: Store,
    protected persistenceService: ApplicationHistoryPersistenceService,
    protected hospitalService: HospitalService,
    protected applicationStatusService: ApplicationStatusService,
    protected subSpecialtyService: SubSpecialtyService,
  ) {
    super();
  }

  get upsertMessageClass() {
    return UpsertApplicationHistoryPageMessage;
  }
  get clearMessageClass() {
    return ResetApplicationHistoryPaginationMessage;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return applicationHistoryPaginationSelectors;
  }

  get entityStateSelector() {
    return selectApplicationHistoryEntityState;
  }

  getAll() {
    return this.store.pipe(select(selectAllApplicationHistories));
  }

  loadByProfileId(id): Observable<Action> {
    return this.fetch(id) as Observable<SetCollectionMessage>;
  }

  getAllByProfileId(profileId: string) {
    return this.store.pipe(select(selectApplicationHistoriesByProfileId(profileId)));
  }

  loadAllPagesAndLoadDependencies(
    namespace: string,
    profileId: string,
    requestOptions: RequestOptions<ApplicationHistoryRequestOptions>,
    filters: IQueryParams = {},
  ) {
    return this.loadAllPages(namespace, profileId, requestOptions, filters).pipe(
      mergeMap((action) => this.loadDependencies(action)),
      mergeMap((x) => x),
    );
  }

  initializePaginationAndLoadDependencies(
    namespace: string,
    profileId: string,
    requestOptions: RequestOptions<ApplicationHistoryRequestOptions>,
    filters: IQueryParams = {},
  ) {
    return this.initializePagination(namespace, profileId, requestOptions, filters).pipe(
      mergeMap((action) => this.loadDependencies(action)),
      mergeMap((x) => x),
    );
  }

  loadNextAndLoadDependencies(
    namespace: string,
    profileId: string,
    requestOptions: RequestOptions<ApplicationHistoryRequestOptions>,
    filters: IQueryParams = {},
  ) {
    return this.loadNext(namespace, profileId, requestOptions, filters).pipe(
      mergeMap((action) => this.loadDependencies(action)),
      mergeMap((x) => x),
    );
  }

  private loadDependencies(action) {
    const actions: Observable<Action>[] = [of(action)];
    if (action.type === ApplicationHistoryMessageTypes.UPSERT_MULTIPLE) {
      const payload = (action as UpsertMultipleMessage).payload;
      const employers = payload.entities.map((x) => x.employer);

      if (employers.length > 0) actions.push(this.hospitalService.loadByIds(employers));
    }

    return actions;
  }

  getConsecutivePageEntitiesWithNames(
    namespace: string,
    profileId: string,
  ): Observable<IEmploymentHistory[]> {
    return combineLatest([
      this.getConsecutivePageEntities(namespace, profileId),
      this.applicationStatusService.getAll(),
    ]).pipe(
      map(([applicationHistories, applicationStatuses]) =>
        applicationHistories.map<IEmploymentHistory>((job) => {
          const status = applicationStatuses.find((x) => x.val === job.status);
          return {
            ...job,
            employerName: job.place,
            statusName: status?.display || '',
            specialtyName: job.specialty,
            id: job.applicationId,
            startDate: job.startDateTime,
            endDate: job.endDateTime,
            details: '',
          };
        }),
      ),
    );
  }

  fetch(profileId?: string) {
    if (isString(profileId))
      return this.persistenceService
        .retrieve(null, { pathParams: { profileId } })
        .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));

    return this.persistenceService
      .retrieve()
      .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));
  }

  getRequestOptions(profileId: string): RequestOptions<ApplicationHistoryRequestOptions> {
    return { pathParams: { profileId } };
  }
}
