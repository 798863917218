import { ProfessionService } from 'apps/hospital-admin/src/app/profession/+state/profession.service';
import { GradeService } from './../../grade/+state/grade.service';
import { Injectable } from '@angular/core';
import { IGlobalFilterService } from '@locumsnest/core/src/lib/services/interfaces';
import { CommonOfficerPreferenceFilterService } from '../../core/+state/filter.service';
import { IGlobalFilters } from '../../core/+state/interfaces/global-filters';
import { PreferredProfessionSpecialtyService } from '../../preferred-profession-specialty/+state/preferred-profession-specialty.service';
import { PreferredProfessionService } from '../../preferred-profession/+state/preferred-profession.service';
import { PreferredSiteService } from '../../preferred-site/+state/preferred-site.service';
import { MicroAppService } from '@locumsnest/core/src/lib/micro-app/micro-app.service';
import { PreferredWardService } from '../../preferred-ward/+state/preferred-ward.service';
import { PreferredRosterService } from '../../preferred-roster/+state/preferred-roster.service';
import { HospitalOfficerService } from '../../hospital-officer/+state/hospital-officer.service';
import { RosterService } from '../../roster/+state/roster.service';

@Injectable({
  providedIn: 'root',
})
export class MatchFilterService
  extends CommonOfficerPreferenceFilterService
  implements IGlobalFilterService<IGlobalFilters | Record<string, never>>
{
  constructor(
    protected preferredProfessionSpecialtyService: PreferredProfessionSpecialtyService,
    protected preferredSiteService: PreferredSiteService,
    protected preferredWardService: PreferredWardService,
    protected preferredRosterService: PreferredRosterService,
    protected preferredProfessionService: PreferredProfessionService,
    protected hospitalOfficerService: HospitalOfficerService,
    protected rosterService: RosterService,
    protected gradeService: GradeService,
    protected professionService: ProfessionService,
    protected microAppService: MicroAppService
  ) {
    super();
  }
}
