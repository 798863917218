import { signalableFactory as actionableFactory } from './search-filter-form.adapter';

export class InitializeNewOpportunityListSignal extends actionableFactory.create<
  'Initialize New Opportunity List',
  { namespace: string }
>('Initialize New Opportunity List') {}

export class LoadMoreNewOpportunityListSignal extends actionableFactory.create<
  'Load More New Opportunity List',
  { namespace: string }
>('Load More New Opportunity List') {}

export class SubmitNewOpportunitySearchFilterFormSignal extends actionableFactory.create<
  'Submit New Opportunity Search Filter Form Signal',
  { namespace: string }
>('Submit New Opportunity Search Filter Form Signal') {}

export class InitializeHospitalCertificateListSignal extends actionableFactory.create<
  'Initialize Hospital Certificate List',
  { namespace: string }
>('Initialize Hospital Certificate List') {}

export class LoadMoreHospitalCertificateListSignal extends actionableFactory.create<
  'Load More Hospital Certificate List',
  { namespace: string }
>('Load More Hospital Certificate List') {}

export class SubmitHospitalCertificateSearchFilterFormSignal extends actionableFactory.create<
  'Submit Hospital Certificate Search Filter Form Signal',
  { namespace: string }
>('Submit Hospital Certificate Search Filter Form Signal') {}
