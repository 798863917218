import { Injectable } from '@angular/core';
import { get } from 'lodash-es';
import { combineLatest, map, mergeMap, Observable } from 'rxjs';

import { IEmploymentHistory } from '@locumsnest/profile-ui/src/interfaces/employment-history-entity';

import { ApplicationHistoryService } from '../../../application-history/+state/application-history.service';
import { ExternalStaffingCandidateBidStatusService } from '../../../external-staffing-candidate-bid-status/+state/external-staffing-candidate-bid-status.service';
import { ExternalStaffingCandidateBidService } from '../../../external-staffing-candidate-bid/+state/external-staffing-candidate-bid.service';
import { ExternalStaffingProviderService } from '../../../external-staffing-provider/+state/external-staffing-provider.service';
import { JobPositionService } from '../../../job-position/+state/job-position.service';
import { EmploymentHistoryFilterTypes } from '../../+state/interfaces';
import { ProfileService } from '../../+state/profile.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileDetailPageService {
  constructor(
    private profileService: ProfileService,
    private applicationHistoryService: ApplicationHistoryService,
    private jobPositionService: JobPositionService,
    private externalStaffingCandidateBidService: ExternalStaffingCandidateBidService,
    private externalStaffingProviderService: ExternalStaffingProviderService,
    private externalStaffingCandidateBidStatusService: ExternalStaffingCandidateBidStatusService,
  ) {}

  getSelectedProfileEmploymentHistory(namespace: string): Observable<IEmploymentHistory[]> {
    return combineLatest([
      this.profileService.getSelectedProfileId(),
      this.profileService.getEmploymentHistoryFilterUiState(),
    ]).pipe(
      mergeMap(([profileId, historyFilter]) =>
        this.getProfileEmploymentHistory(namespace, profileId, historyFilter),
      ),
    );
  }

  getProfileEmploymentHistory(
    namespace: string,
    id: string,
    historyFilter = EmploymentHistoryFilterTypes.none,
  ) {
    return combineLatest([
      this.applicationHistoryService.getConsecutivePageEntitiesWithNames(namespace, id),
      this.jobPositionService.getConsecutivePageEntities(namespace, id).pipe(
        map((entities) =>
          entities.map<IEmploymentHistory>((e) => ({
            details: e.details,
            employerName: e.placeOfEmployment,
            endDateTime: e.endDate,
            id: e.id,
            listingId: 'N/A',
            specialtyName: null,
            startDateTime: e.startDate,
            statusName: null,
          })),
        ),
      ),
      this.applicationHistoryService.areAllLoaded(namespace, id),

      combineLatest([
        this.externalStaffingProviderService.getEntityDict(),
        this.externalStaffingCandidateBidService.getConsecutivePageEntities(`${namespace}-${id}`),
        this.externalStaffingCandidateBidStatusService.getAll(),
      ]).pipe(
        map(([providers, entities, status]) =>
          entities.map<IEmploymentHistory>((e) => ({
            details: '',
            employerName: providers[e.staffingProvider]?.name || 'N/A',
            endDateTime: e.endTime,
            id: e.id,
            listingId: 'N/A',
            specialtyName: '',
            startDateTime: e.startTime,
            statusName: status.find((s) => s.val === e.status)?.display,
          })),
        ),
      ),
    ]).pipe(
      map(([applicationHistory, jobPositions, allApplicationHistoryLoaded, bids]) =>
        this.getEmploymentHistory(
          applicationHistory,
          jobPositions,
          bids,
          allApplicationHistoryLoaded,
          historyFilter,
        ),
      ),
    );
  }

  getEmploymentHistory(
    applicationHistory: IEmploymentHistory[],
    jobPositions: IEmploymentHistory[],
    bids: IEmploymentHistory[],
    allLoaded: boolean,
    historyFilter: EmploymentHistoryFilterTypes,
  ) {
    let jobs = jobPositions;
    let employmentHistory = applicationHistory;

    if (historyFilter === EmploymentHistoryFilterTypes.none) {
      if (applicationHistory.length > 0 && !allLoaded) {
        // sort application history to get the last application date
        applicationHistory.sort((a, b) =>
          a.startDateTime > b.startDateTime ? -1 : a.startDateTime < b.startDateTime ? 1 : 0,
        );

        // filter out job positions out of application history range
        jobs = jobPositions.filter(
          (x) => x.startDateTime < applicationHistory[applicationHistory.length - 1].startDateTime,
        );
      }

      employmentHistory = [...applicationHistory, ...jobs, ...bids];
    }

    if (historyFilter === EmploymentHistoryFilterTypes.job) {
      employmentHistory = jobPositions;
    }

    if (historyFilter === EmploymentHistoryFilterTypes.bids) {
      employmentHistory = bids;
    }

    // sort results
    return employmentHistory.sort((a, b) =>
      this.getEmploymentStartTime(a) > this.getEmploymentStartTime(b)
        ? -1
        : this.getEmploymentStartTime(a) < this.getEmploymentStartTime(b)
          ? 1
          : 0,
    );
  }

  private getEmploymentStartTime(entry: IEmploymentHistory) {
    return get(entry, 'startDate', get(entry, 'startDateTime')) as Date;
  }
}
