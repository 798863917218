import { MessageableFactory } from '@locumsnest/core/src';
import { SignalableFactory } from '@locumsnest/core/src';
import { createAlertStateAdapter } from '@locumsnest/core/src/lib/adapters/alert-state-adapter';
import { createExpandableStateAdapter } from '@locumsnest/core/src/lib/adapters/expandable-state-adapter';

export const messageableFactory =
  MessageableFactory.forFeature<'StaffBankMembershipSearchFilterForm'>(
    'StaffBankMembershipSearchFilterForm'
  );
export const signalableFactory =
  SignalableFactory.forFeature<'StaffBankMembershipSearchFilterForm'>(
    'StaffBankMembershipSearchFilterForm'
  );
export const alertStateAdapter = createAlertStateAdapter(signalableFactory, messageableFactory);
export const { errorHandler, conditionalErrorHandler, alertHandler } =
  alertStateAdapter.getAlertHandlers();
export const { ResetErrorMessage, AlertErrorMessage } = alertStateAdapter.getMessages();
export const { alertSignalToMessage } = alertStateAdapter.getEffectOperators();

export const expandableStateAdapter =
  createExpandableStateAdapter<'StaffBankMembershipSearchFilterForm'>(
    signalableFactory,
    messageableFactory
  );
export const { ToggleExpandableSignal, SetExpandExpandableSignal } =
  expandableStateAdapter.getSignals();
