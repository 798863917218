import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { isNil, isNumber, isString } from 'lodash-es';
import { map, switchMap } from 'rxjs/operators';

import { PaginatedStateService } from '@locumsnest/core';
import { Query } from '@locumsnest/core/src';

import { HospitalService } from '../../hospital/+state/hospital.services';
import { ITrustExternalStaffingProviderTierEntity } from '../../interfaces/api/trust-external-staffing-provider-tier-entity';
import { paginationAdapter } from './trust-external-staffing-provider-tier.adapter';
import {
  ResetTrustExternalStaffingProviderTierPaginationMessage,
  trustExternalStaffingProviderTierPaginationMessages,
  UpsertMultipleMessage,
  UpsertOneMessage,
  UpsertTrustExternalStaffingProviderTierPageMessage,
} from './trust-external-staffing-provider-tier.messages';
import { TrustExternalStaffingProviderTierPersistenceService } from './trust-external-staffing-provider-tier.persistence.service';
import {
  selectAllTrustExternalStaffingProviderTiers,
  selectAllTrustExternalStaffingProviderTiersForHospital,
  selectNegotiatedRatesByProviderHospital,
  selectTrustExternalStaffingProviderTierEntityState,
  trustExternalStaffingProviderTierPaginationSelectors,
} from './trust-external-staffing-provider-tier.selectors';

@Injectable({
  providedIn: 'root',
})
export class TrustExternalStaffingProviderTierService extends PaginatedStateService<
  ITrustExternalStaffingProviderTierEntity,
  UpsertTrustExternalStaffingProviderTierPageMessage,
  ResetTrustExternalStaffingProviderTierPaginationMessage,
  UpsertMultipleMessage
> {
  constructor(
    protected store: Store,
    protected persistenceService: TrustExternalStaffingProviderTierPersistenceService,
    protected hospitalService: HospitalService,
  ) {
    super();
  }
  get paginationMessages() {
    return trustExternalStaffingProviderTierPaginationMessages;
  }

  get paginationSelectors() {
    return trustExternalStaffingProviderTierPaginationSelectors;
  }

  get entityStateSelector() {
    return selectTrustExternalStaffingProviderTierEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get loadingMessages() {
    return paginationAdapter.getLoadingMessages();
  }

  getAll() {
    return this.store.pipe(select(selectAllTrustExternalStaffingProviderTiers));
  }

  getAllForHospital(hospital: number) {
    return this.store.pipe(
      select(selectAllTrustExternalStaffingProviderTiersForHospital(hospital)),
    );
  }

  getAllForForAssignedHospital() {
    return this.hospitalService
      .getAssignedHospitalId()
      .pipe(switchMap((hospital) => this.getAllForHospital(hospital)));
  }

  getForProviderHospital(provider: number, trust: number) {
    return this.getAllAfterLoading().pipe(
      map((trustProviderTiers) =>
        trustProviderTiers.find((x) => x.provider === provider && x.trust === trust),
      ),
    );
  }

  fetch(query?: Query) {
    if (isNil(query)) {
      return this.loadAllPages('default', {});
    }
    if (isString(query) || isNumber(query))
      return this.persistenceService
        .retrieve(query)
        .pipe(map((entity) => new UpsertOneMessage({ entity })));
    return this.persistenceService
      .retrieve(query)
      .pipe(map(({ results }) => new UpsertMultipleMessage({ entities: results })));
  }

  getNegotiatedRatesByProviderHospital(providerId: number, hospitalId: number) {
    return this.store.pipe(select(selectNegotiatedRatesByProviderHospital(providerId, hospitalId)));
  }

  getTrustIdsByProviderId(provider: number) {
    return this.getAllAfterLoading().pipe(
      map((trustProviderTiers) =>
        trustProviderTiers
          .filter((trustProvider) => trustProvider.provider === provider)
          .map((trustProvider) => trustProvider.trust),
      ),
    );
  }
}
