import { combineReducers } from '@ngrx/store';

import { IPaginatedEntityState } from '@locumsnest/core/src/lib/interfaces/pagination';

import { IApprovedRateEntity } from '../../interfaces/api/approved-rate-entity';
import { adapter, initialState, loadingAdapter, paginationAdapter } from './approved-rate.adapter';
import {
  ApprovedRateMessages,
  ApprovedRateMessageTypes as MessageTypes,
} from './approved-rate.messages';
import { IApprovedRateEntityState } from './interfaces';

export * from './interfaces';
export const featureKey = 'approvedRates';

const loadingStateReducer = loadingAdapter.createReducer();

export const paginationReducer = paginationAdapter.createReducer();

export type IApprovedRatePaginatedEntityState = IApprovedRateEntityState &
  IPaginatedEntityState<IApprovedRateEntity>;

export function entityReducer(
  state = initialState,
  action: ApprovedRateMessages
): IApprovedRateEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.entity, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.entity, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.entities, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.entities, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }
    default: {
      return state;
    }
  }
}

export function reducer(state, action) {
  return combineReducers({
    entityState: entityReducer,
    entityLoadingState: loadingStateReducer,
    pagination: paginationReducer,
  })(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  paginationAdapter.getEntitySelectors();
