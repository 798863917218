import { MessageableFactory } from '@locumsnest/core/src';
import { SignalableFactory } from '@locumsnest/core/src';
import { createAlertStateAdapter } from '@locumsnest/core/src/lib/adapters/alert-state-adapter';
import { createExpandableStateAdapter } from '@locumsnest/core/src/lib/adapters/expandable-state-adapter';

export const messageableFactory = MessageableFactory.forFeature<'ApplicationAlertSearchFilterForm'>(
  'ApplicationAlertSearchFilterForm'
);
export const signalableFactory = SignalableFactory.forFeature<'ApplicationAlertSearchFilterForm'>(
  'ApplicationAlertSearchFilterForm'
);
export const alertStateAdapter = createAlertStateAdapter(signalableFactory, messageableFactory);
export const { errorHandler, conditionalErrorHandler, alertHandler } =
  alertStateAdapter.getAlertHandlers();
export const { ResetErrorMessage, AlertErrorMessage } = alertStateAdapter.getMessages();

export const expandableStateAdapter =
  createExpandableStateAdapter<'ApplicationAlertSearchFilterForm'>(
    signalableFactory,
    messageableFactory
  );
export const { ToggleExpandableSignal, SetExpandExpandableSignal } =
  expandableStateAdapter.getSignals();
