import { IAttributeEntity } from 'apps/hospital-admin/src/app/interfaces/api/attribute';
import { IExternalStaffingCandidateBidEntity } from 'apps/hospital-admin/src/app/interfaces/api/external-staffing-candidate-bid-entity';
import { IProfileEntity } from 'apps/hospital-admin/src/app/interfaces/api/profile-entity';

import { IApplicationEntity } from './application-details';
import { ISubSpecialty } from './specialty';

export interface ICalendarShiftSummary {
  title: string;
  startTime: Date;
  endTime: Date;
  subSpecialty: ISubSpecialty;
  totalApplications: number;
  cancelled: string[];
  approved: string[];
  pending: number;
  id: number;
  noActivity: number;
  published: boolean;
  totalPositions: number;
  cascadedToAgencies?: boolean;
  cascadedStatusCode?: string;
  isApplication: boolean;
}

export interface IApplicationGroups<
  U extends IAttributeEntity | number = number,
  P extends IProfileEntity | string = string,
> {
  [action: number]: IApplicationEntity<U, P>[];
}
export interface IApplicationGroupDetails {
  groupApplications: IApplicationGroups<IAttributeEntity, IProfileEntity>;
  applicationsCount: number;
}

export interface IExternalStaffingCandidateBidGroups<
  U extends IAttributeEntity | number = number,
  P extends IProfileEntity | string = string,
> {
  [action: number]: IExternalStaffingCandidateBidEntity<any, any, P, U>[];
}

export interface IBidGroupDetails {
  groupExternalStaffingCandidateBids: IExternalStaffingCandidateBidGroups<
    IAttributeEntity,
    IProfileEntity
  >;
  bidsCount: number;
}
export interface ICalendarShiftAction {
  type: ActionEnum;
  actionedBy: string;
}

export enum ActionEnum {
  Cancelled = 'CANCELLED',
  Approved = 'APPROVED',
  NoActivity = 'NO_ACTIVITY',
  MoreThanOneApplications = 'WITH_APPLICATIONS',
}

export enum ApplicationExplicitActionEnum {
  Pending = 'PENDING',
  Other = 'OTHER',
}

export type ApplicationActions = ActionEnum | ApplicationExplicitActionEnum;
