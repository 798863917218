import { inject, Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import { filter, map, Observable } from 'rxjs';

import { PaginatedStateService } from '@locumsnest/core/src';

import { IStaffbankRejectionReason } from './interfaces';
import {
  paginationMessages,
  ResetStaffbankRejectionReasonPaginationMessage,
  SetCollectionMessage,
  UpsertMultipleMessage,
  UpsertStaffbankRejectionReasonPageMessage,
} from './staff-bank-rejection-reason.messages';
import { StaffbankRejectionReasonsPersistenceService } from './staff-bank-rejection-reason.persistence.service';
import {
  selectRejectionReasonsByIds,
  selectStaffbankRejectionReasonByCode,
  selectStaffbankRejectionReasonsEntityState,
  staffbankRejectionReasonsPaginationSelectors,
} from './staff-bank-rejection-reason.selectors';

@Injectable({
  providedIn: 'root',
})
export class StaffbankRejectionReasonsService extends PaginatedStateService<
  IStaffbankRejectionReason,
  UpsertStaffbankRejectionReasonPageMessage,
  ResetStaffbankRejectionReasonPaginationMessage,
  UpsertMultipleMessage
> {
  protected persistenceService = inject(StaffbankRejectionReasonsPersistenceService);

  get paginationMessages() {
    return paginationMessages;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return staffbankRejectionReasonsPaginationSelectors;
  }

  get entityStateSelector() {
    return selectStaffbankRejectionReasonsEntityState;
  }

  fetch(ids: number[], active = true) {
    return this.persistenceService
      .retrieve(undefined, { queryParams: { active, id: ids } })
      .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));
  }

  getByIds(ids: number[]) {
    return this.store.pipe(select(selectRejectionReasonsByIds(ids)));
  }

  getOtherId(): Observable<number> {
    return this.store.pipe(
      select(selectStaffbankRejectionReasonByCode('OTHER')),
      filter((x) => !!x),
      map((other) => other?.id || 0),
    );
  }
}
