import { Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import { isNumber, isString } from 'lodash-es';
import { combineLatest, filter, map, merge, mergeMap, Observable, of } from 'rxjs';

import { PaginatedStateService } from '@locumsnest/core';
import { Query } from '@locumsnest/core/src';

import { IExternalStaffingProviderEntity } from '../../interfaces/api/external-staffing-provider-entity';
import { TrustExternalStaffingProviderTierService } from '../../trust-external-staffing-provider-tier/+state/trust-external-staffing-provider-tier.service';
import { paginationAdapter } from './external-staffing-provider.adapter';
import {
  paginationMessages,
  ResetExternalStaffingProviderPaginationMessage,
  SetAssignedMessage,
  UpsertExternalStaffingProviderPageMessage,
  UpsertMultipleMessage,
  UpsertOneMessage,
} from './external-staffing-provider.messages';
import { ExternalStaffingProviderPersistenceService } from './external-staffing-provider.persistence.service';
import {
  externalStaffingProviderPaginationSelectors,
  selectAllExternalStaffingProviders,
  selectAssignedExternalStaffingProvider,
  selectAssignedExternalStaffingProviderId,
  selectAssignedExternalStaffingProviderName,
  selectExternalStaffingProviderEntityState,
  selectExternalStaffingProviderOptions,
} from './external-staffing-provider.selectors';

@Injectable({
  providedIn: 'root',
})
export class ExternalStaffingProviderService extends PaginatedStateService<
  IExternalStaffingProviderEntity,
  UpsertExternalStaffingProviderPageMessage,
  ResetExternalStaffingProviderPaginationMessage,
  UpsertMultipleMessage
> {
  constructor(
    protected persistenceService: ExternalStaffingProviderPersistenceService,
    protected trustExternalStaffingProvideTierService: TrustExternalStaffingProviderTierService,
  ) {
    super();
  }

  get paginationMessages() {
    return paginationMessages;
  }

  get paginationSelectors() {
    return externalStaffingProviderPaginationSelectors;
  }

  get entityStateSelector() {
    return selectExternalStaffingProviderEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get loadingMessages() {
    return paginationAdapter.getLoadingMessages();
  }

  getExternalStaffingProviderOptions() {
    return this.store.pipe(select(selectExternalStaffingProviderOptions));
  }

  getAssignedHospitalExternalStaffingProviderOptions() {
    return combineLatest([
      this.trustExternalStaffingProvideTierService.getAllForForAssignedHospital(),
      this.getExternalStaffingProviderOptions(),
    ]).pipe(
      map(([trustProviderTiers, options]) =>
        options.filter(({ id }) => trustProviderTiers.find(({ provider }) => provider === id)),
      ),
    );
  }

  getAll() {
    return this.store.pipe(select(selectAllExternalStaffingProviders));
  }

  getAssigned(): Observable<IExternalStaffingProviderEntity> {
    return this.store.pipe(
      select(selectAssignedExternalStaffingProvider),
      filter((x) => !!x),
    );
  }

  getAssignedName(): Observable<string> {
    return this.store.pipe(
      select(selectAssignedExternalStaffingProviderName),
      filter((x) => !!x),
    );
  }
  getAssignedIdOrNone() {
    return this.store.pipe(select(selectAssignedExternalStaffingProviderId));
  }
  getAssignedId(): Observable<number> {
    return this.getAssignedIdOrNone().pipe(filter((x) => !!x));
  }

  loadCurrent() {
    return this.persistenceService.retrieveCurrent<IExternalStaffingProviderEntity>().pipe(
      mergeMap((result) => {
        let currentId = null;

        if (result) {
          currentId = result.id;
        }

        return merge(
          of(new UpsertOneMessage({ entity: result })),
          of(new SetAssignedMessage({ id: currentId })),
        );
      }),
    );
  }

  fetch(query?: Query) {
    //@todo generalize this and move it to base service
    if (isString(query) || isNumber(query))
      return this.persistenceService
        .retrieve(query)
        .pipe(map((entity) => new UpsertOneMessage({ entity })));
    return this.loadAllPages('default', {});
  }
}
