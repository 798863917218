import { createSelector } from '@ngrx/store';

import { State } from '../interfaces';
import { selectProfileState } from '../profile.selectors';

export const getFormState = (state: State) => state.formState;

export const selectProfileFormState = createSelector(selectProfileState, getFormState);

export const selectProfileLabels = createSelector(
  selectProfileFormState,
  (formState) => formState.controls.profileLabels
);

export const selectProfileTags = createSelector(
  selectProfileFormState,
  (formState) => formState.controls.profileTags
);
