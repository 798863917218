import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { isNil, isNumber, isString, range } from 'lodash-es';
import { FormGroupState } from 'ngrx-forms';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  switchMap,
} from 'rxjs';

import {
  IArrowDirectionsEnum,
  IQueryParams,
  PaginatedStateService,
  Query,
} from '@locumsnest/core/src';
import { Time, UrlHelpers } from '@locumsnest/core/src/lib/helpers';
import { StaffbankRejectionReasonsService } from '@locumsnest/staff-bank-rejection-reason/src';

import { HospitalOfficerService } from '../../hospital-officer/+state/hospital-officer.service';
import { IProfessionEntity } from '../../interfaces/api/profession-entity';
import { IProfileEntity } from '../../interfaces/api/profile-entity';
import {
  IStaffBankMembershipRequestEntity,
  IStaffBankMembershipRequestListing,
  IStaffBankMembershipRequestParams,
  IStaffBankMembershipRequestRow,
} from '../../interfaces/api/staff-bank-membership-request-entity';
import { IStaffBankMembershipRequestStatusEntity } from '../../interfaces/api/staff-bank-membership-request-status-entity';
import {
  LoadMoreHospitalCertificateListSignal,
  LoadMoreNewOpportunityListSignal,
} from '../../passport-dashboard/+state/search-filter-form/search-filter-form.signals';
import { NavigateToStaffBankRequestSignal } from '../../passport-dashboard/+state/ui/ui.signals';
import {
  IDashboardList,
  IDashboardRow,
  ListTypeEnum,
} from '../../passport-dashboard/containers/interface';
import { ProfessionService } from '../../profession/+state/profession.service';
import { ProfileNoteService } from '../../profile-note/+state/profile-note.service';
import { ProfileService } from '../../profile/+state/profile.service';
import { selectShowFlaggedNotesForProfileUiState } from '../../profile/+state/ui';
import { getApprovedStaffBankMembershipRequestStatus } from '../../staff-bank-membership-request-status/+state/staff-bank-membership-request-status.selectors';
import { StaffBankMembershipRequestStatusService } from '../../staff-bank-membership-request-status/+state/staff-bank-membership-request-status.service';
import { StaffBankMembershipService } from '../../staff-bank-membership/+state/staff-bank-membership.service';
import {
  selectExpandedStaffBankRequests,
  selectNotesFormState,
  selectRejectedStaffBankRequests,
  selectRejectFormState,
  selectSelectedStaffBankMembershipRequests,
  selectStaffBankMembershipRequestsSearchFilterFormState,
  selectStaffBankMembershipRequestsSelectedPage,
} from '../+state/staff-bank-membership-requests-search-filter-form';
import {
  INoteFormState,
  IRejectRequestFormState,
  IStaffBankMembershipRequestDependencies,
} from './interfaces';
import {
  ResetStaffBankMembershipRequestPaginationMessage,
  StaffBankMembershipRequestMessageTypes,
  StaffBankMembershipRequestPaginationMessages,
  UpsertMultipleMessage,
  UpsertOneMessage,
  UpsertStaffBankMembershipRequestPageMessage,
} from './staff-bank-membership-request.messages';
import { StaffBankMembershipRequestPersistenceService } from './staff-bank-membership-request.persistence.service';
import {
  selectAllStaffBankMembershipRequests,
  selectStaffBankMembershipRequestByProfileId,
  selectStaffBankMembershipRequestByProfileIds,
  selectStaffBankMembershipRequestEntityState,
  staffBankMembershipRequestPaginationSelectors,
} from './staff-bank-membership-request.selectors';
import {
  AddSelectedStaffBankMembershipRequestSignal,
  ExportStaffBankMembershipRequestsSignal,
  InitializeSearchFilterFormSignal,
  RejectStaffBankMembershipRequestSignal,
  RemoveSelectedStaffBankMembershipRequestSignal,
  SelectAllInCurrentPageSignal,
  SubmitSearchFilterFormSignal,
  UnselectAllInCurrentPageSignal,
  UpdateSelectedPageUsingArrowsSignal,
  UpdateSelectedPageUsingInputSignal,
  UpdateStaffBankMembershipRequestSignal,
} from './staff-bank-membership-requests-search-filter-form/staff-bank-membership-requests-search-filter-form.signals';

@Injectable({
  providedIn: 'root',
})
export class StaffBankMembershipRequestService extends PaginatedStateService<
  IStaffBankMembershipRequestEntity,
  UpsertStaffBankMembershipRequestPageMessage,
  ResetStaffBankMembershipRequestPaginationMessage,
  UpsertMultipleMessage
> {
  constructor(
    protected store: Store,
    protected persistenceService: StaffBankMembershipRequestPersistenceService,
    private profileService: ProfileService,
    private staffBankMembershipRequestStatusService: StaffBankMembershipRequestStatusService,
    private staffBankMembershipService: StaffBankMembershipService,
    private professionService: ProfessionService,
    private profileNoteService: ProfileNoteService,
    private hospitalOfficerService: HospitalOfficerService,
    private staffbankRejectionReasonsService: StaffbankRejectionReasonsService,
  ) {
    super();
  }

  get paginationMessages() {
    return StaffBankMembershipRequestPaginationMessages;
  }

  get paginationSelectors() {
    return staffBankMembershipRequestPaginationSelectors;
  }

  get entityStateSelector() {
    return selectStaffBankMembershipRequestEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  getAll() {
    return this.store.pipe(select(selectAllStaffBankMembershipRequests));
  }

  getOneByProfileId(id) {
    return this.store.pipe(select(selectStaffBankMembershipRequestByProfileId(id)));
  }

  getByProfileIds(ids) {
    return this.store.pipe(select(selectStaffBankMembershipRequestByProfileIds(ids)));
  }

  getStaffBankMembershipRequestsByStatusCode(namespace: string, statusCode: string) {
    return this.staffBankMembershipRequestStatusService
      .getStaffBankMembershipRequestStatusByCode(statusCode)
      .pipe(
        mergeMap((status: IStaffBankMembershipRequestStatusEntity) => {
          if (!status) {
            return of(null);
          }
          return this.loadAllPages(namespace, {}, { status: status.id });
        }),
        filter((x) => x !== null),
      );
  }

  updateStatus(id: number, status: number): Observable<IStaffBankMembershipRequestEntity> {
    return this.persistenceService.updateStatus(id, status);
  }

  reject(id: number, reason: number, other: string): Observable<IStaffBankMembershipRequestEntity> {
    return this.persistenceService.reject(id, reason, other);
  }

  fetch(query?: Query) {
    if (isString(query) || isNumber(query))
      return this.persistenceService
        .retrieve(query)
        .pipe(map((entity) => new UpsertOneMessage({ entity })));
    return this.persistenceService
      .retrieve(query)
      .pipe(map(({ results }) => new UpsertMultipleMessage({ entities: results })));
  }

  getStaffBankMembershipRequestsCurrentPage(namespace: string) {
    return this.store.pipe(
      select(selectStaffBankMembershipRequestsSelectedPage),
      switchMap((pageNumber) => this.getPageEntities(namespace, pageNumber)),
    );
  }

  getSelectedStaffBankMembershipRequests() {
    return this.store.pipe(select(selectSelectedStaffBankMembershipRequests));
  }

  //MD: Return an array of numbers with the page
  // from 1 to the current selected page on job listing list
  getStaffBankMembershipRequestSelectedPages(): Observable<number[]> {
    return this.store.pipe(
      select(selectStaffBankMembershipRequestsSelectedPage),
      map((currentPage) => range(1, currentPage)),
    );
  }

  //MD: Calculate the loaded rows until the current selected page
  getLoadedRowsCount(namespace: string): Observable<number> {
    return this.getStaffBankMembershipRequestSelectedPages().pipe(
      mergeMap((selectedPages) => this.getMultiPageEntitiesCount(namespace, selectedPages)),
    );
  }

  getStaffBankMembershipRequestsCountByNamespace(namespace: string) {
    return this.getTotalCount(namespace);
  }

  loadByProfileId(id: string) {
    return this.fetch({ profile: id }) as Observable<UpsertMultipleMessage>;
  }

  loadByProfileIds(ids: string[]) {
    return this.loadAll({ profile: ids });
  }

  loadAllPagesAndLoadDependencies(
    namespace: string,
    dependencies: IStaffBankMembershipRequestDependencies,
  ) {
    return this.loadAllPages(namespace, null).pipe(
      mergeMap((action) => this.loadDependencies(action, dependencies)),
    );
  }

  initializePaginationAndLoadDependencies(
    namespace: string,
    staffBankMembershipRequestParams: IStaffBankMembershipRequestParams,
    dependencies: IStaffBankMembershipRequestDependencies,
  ) {
    let filters: IQueryParams = {};

    for (const param in staffBankMembershipRequestParams) {
      if (staffBankMembershipRequestParams.hasOwnProperty(param)) {
        filters = UrlHelpers.addQueryParams(
          filters,
          param,
          staffBankMembershipRequestParams[param],
        );
      }
    }

    return this.initializePagination(namespace, {}, filters).pipe(
      mergeMap((action) => this.loadDependencies(action, dependencies)),
    );
  }

  loadNextAndLoadDependencies(
    namespace: string,
    staffBankMembershipRequestParams: IStaffBankMembershipRequestParams,
    dependencies: IStaffBankMembershipRequestDependencies,
  ) {
    let filters: IQueryParams = {};

    for (const param in staffBankMembershipRequestParams) {
      if (staffBankMembershipRequestParams.hasOwnProperty(param)) {
        filters = UrlHelpers.addQueryParams(
          filters,
          param,
          staffBankMembershipRequestParams[param],
        );
      }
    }

    return this.loadNext(namespace, {}, filters).pipe(
      mergeMap((action) => this.loadDependencies(action, dependencies)),
    );
  }

  loadPageAndLoadDependencies(
    namespace: string,
    page: number,
    staffBankMembershipRequestParams: IStaffBankMembershipRequestParams,
    dependencies: IStaffBankMembershipRequestDependencies,
  ) {
    let filters: IQueryParams = {};

    for (const param in staffBankMembershipRequestParams) {
      if (staffBankMembershipRequestParams.hasOwnProperty(param)) {
        filters = UrlHelpers.addQueryParams(
          filters,
          param,
          staffBankMembershipRequestParams[param],
        );
      }
    }

    return this.loadPage(namespace, {}, page, filters).pipe(
      mergeMap((action) => this.loadDependencies(action, dependencies)),
    );
  }

  getExpandedStaffBankRequests() {
    return this.store.pipe(select(selectExpandedStaffBankRequests));
  }

  getRejectedRequests() {
    return this.store.pipe(select(selectRejectedStaffBankRequests));
  }

  getStaffBankMembershipRequestsListCurrentPage(namespace: string) {
    return combineLatest([
      this.getStaffBankMembershipRequestsCurrentPage(namespace),
      this.getStaffBankMembershipRequestsCurrentPage(namespace).pipe(
        switchMap((staffBankMembershipRequests) =>
          combineLatest(
            staffBankMembershipRequests.map((staffBankMembershipRequest) =>
              this.profileService.getCommonStaffBankCollaborationByProfileId(
                staffBankMembershipRequest.profile,
              ),
            ),
          ),
        ),
      ),
      this.getTotalPages(namespace),
      this.getSelectedStaffBankMembershipRequests(),
      this.getProfilesForStaffbankMembershipAfterCurrentPageLoad(namespace),
      this.staffBankMembershipRequestStatusService.getAll(),
      this.getExpandedStaffBankRequests(),
      this.professionService.getAll(),
      this.profileNoteService.getAll(),
      this.hospitalOfficerService.getAllHospitalOfficerWithUsers(),
      this.store.pipe(select(selectShowFlaggedNotesForProfileUiState)),
      this.getRejectedRequests(),
      this.staffbankRejectionReasonsService.getAll(),
      this.staffBankMembershipRequestStatusService.getWithdrawStaffBankMembershipRequestStatus(),
      this.staffBankMembershipRequestStatusService.getRejectStaffBankMembershipRequestStatus(),
    ]).pipe(
      map(
        ([
          staffBankMembershipRequests,
          staffBankCollaborations,
          totalPages,
          selectedStaffBankMembershipRequests,
          profiles,
          staffBankMembershipRequestStatuses,
          expandedStaffBankRequests,
          professions,
          profileNotes,
          officersWithUsers,
          showFlaggedNotesForProfileIds,
          rejectedRequests,
          staffbankRejectionReasons,
          withdrawStatus,
          rejectedStatus,
        ]): IStaffBankMembershipRequestListing => {
          const staffBankMembershipRequestRows = staffBankMembershipRequests.map(
            (staffBankMembershipRequest, i) => {
              const profile = profiles
                ? profiles.find((x) => x.id === staffBankMembershipRequest.profile)
                : null;
              const memberShipStatus = staffBankMembershipRequest
                ? staffBankMembershipRequestStatuses.find(
                    (x) => x.id === staffBankMembershipRequest.status,
                  )
                : null;
              const approvedStaffBankMembershipStatus = getApprovedStaffBankMembershipRequestStatus(
                staffBankMembershipRequestStatuses,
              );

              const pendingDays = Time.getMoment().diff(
                Time.getMoment(staffBankMembershipRequest.createdDate),
                'days',
              );

              const profileProfession =
                !isNil(profile) && professions
                  ? professions.find((x) => x.id === profile.profession)
                  : null;

              const profession = profileProfession ? profileProfession.title : null;

              let profileNotesByProfile =
                profile && profileNotes.length > 0
                  ? profileNotes.filter((x) => x.profile === profile.id)
                  : [];

              if (
                Object.entries(showFlaggedNotesForProfileIds).length > 0 &&
                showFlaggedNotesForProfileIds[profile.id]
              ) {
                profileNotesByProfile = profileNotesByProfile.filter((x) => x.flagged === true);
              }

              const profileNotesWithOfficer = profileNotesByProfile
                .map((profileNote) => {
                  const user = officersWithUsers.find((x) => x.id === profileNote.createdBy);

                  return {
                    ...profileNote,
                    createdBy: user === undefined ? null : user.user,
                  };
                })
                .sort((a, b) =>
                  a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0,
                );

              const rejectedReason =
                staffBankMembershipRequest.rejectionReasonOther ||
                (staffBankMembershipRequest.rejectionReason
                  ? staffbankRejectionReasons.find(
                      (rejectionReason) =>
                        rejectionReason.id === staffBankMembershipRequest.rejectionReason,
                    )?.display
                  : null);

              const staffBankMembershipRequestRow: IStaffBankMembershipRequestRow = {
                id: staffBankMembershipRequest.id,
                name: profile ? profile.firstName + ' ' + profile.lastName : '',
                registrationNumber: profile ? profile.registrationNumber : '',
                profileId: profile ? profile.id : '',
                appliedForShifts: staffBankMembershipRequest.appliedForShifts ? 'Yes' : 'No',
                unlistedMember: staffBankMembershipRequest.unlistedMember ? 'Yes' : 'No',
                memberOfCollab: staffBankCollaborations[i]?.map((a) => a.name).join('\n'),
                specialistDate: profile?.srDate,
                pendingDays: pendingDays === 1 ? pendingDays + ' day' : pendingDays + ' days',
                recruitmentStatus: memberShipStatus,
                noOfNotes: profileNotesByProfile.length,
                expanded: expandedStaffBankRequests.includes(staffBankMembershipRequest.id),
                isApproved:
                  staffBankMembershipRequest.status === approvedStaffBankMembershipStatus.id,
                selected: selectedStaffBankMembershipRequests.includes(
                  staffBankMembershipRequest.id,
                ),
                isWithdraw: staffBankMembershipRequest.status === withdrawStatus.id,
                isRejected: staffBankMembershipRequest.status === rejectedStatus.id,
                profession,
                profileNotes: profileNotesWithOfficer,
                rejected: rejectedRequests.includes(staffBankMembershipRequest.id),
                rejectedReason,
              };

              return staffBankMembershipRequestRow;
            },
          );

          const staffBankMembershipRequestListing: IStaffBankMembershipRequestListing = {
            staffBankMembershipRequestRows,
            totalPages,
          };

          return staffBankMembershipRequestListing;
        },
      ),
    );
  }

  getConsecutiveNewOpportunityEntities(namespace: string) {
    return this.getConsecutivePageEntities(namespace);
  }

  getProfilesForStaffbankMembershipAfterCurrentPageLoad(namespace) {
    return this.getStaffBankMembershipRequestsCurrentPage(namespace).pipe(
      mergeMap((staffBankMembershipRequests: IStaffBankMembershipRequestEntity[]) => {
        if (!staffBankMembershipRequests.length) {
          return of<IProfileEntity[]>([]);
        }
        const staffBankMembershipRequestProfileIds = staffBankMembershipRequests.map(
          (staffBankMembershipRequest) => staffBankMembershipRequest.profile,
        );
        return this.profileService.getMultiple(staffBankMembershipRequestProfileIds);
      }),
      distinctUntilChanged(),
    );
  }

  getProfilesAfterCurrentPageLoad(namespace: string) {
    return this.getConsecutivePageEntities(namespace).pipe(
      mergeMap((staffBankMembershipRequests: IStaffBankMembershipRequestEntity[]) => {
        if (!staffBankMembershipRequests.length) {
          return of<IProfileEntity[]>([]);
        }
        const staffBankMembershipRequestProfileIds = staffBankMembershipRequests.map(
          (staffBankMembershipRequest) => staffBankMembershipRequest.profile,
        );
        return this.profileService.getMultiple(staffBankMembershipRequestProfileIds);
      }),
      distinctUntilChanged(),
    );
  }

  getNewOpportunityList(namespace: string) {
    return combineLatest([
      this.getConsecutiveNewOpportunityEntities(namespace),
      this.getProfilesAfterCurrentPageLoad(namespace),
      this.professionService.getAll(),
      this.staffBankMembershipRequestStatusService.getAll(),
    ]).pipe(
      map(
        ([newOpportunities, profiles, professions, statuses]: [
          IStaffBankMembershipRequestEntity[],
          IProfileEntity[],
          IProfessionEntity[],
          IStaffBankMembershipRequestStatusEntity[],
        ]): IDashboardList => {
          let alertCount = 0;
          const newOpportunityListItems = newOpportunities.map((newOpportunity) => {
            const profile = newOpportunity
              ? profiles.find((x) => x.id === newOpportunity.profile)
              : null;
            const status = newOpportunity
              ? statuses.find((x) => x.id === newOpportunity.status)
              : null;

            const profileProfession =
              !isNil(profile) && professions
                ? professions.find((x) => x.id === profile.profession)
                : null;

            const profession = profileProfession ? profileProfession.title : null;

            const membershipRequest: IDashboardRow = {
              id: newOpportunity.id,
              profession,
              actioned: false, //pending
              createdDate: newOpportunity.createdDate,
              status: status ? status.code : null,
              profile: profile ? profile.id : '',
              registrationNo: profile ? profile.registrationNumber : '',
              profileName: profile ? profile.firstName + ' ' + profile.lastName : '',
              alertCount: 0,
            };

            alertCount = alertCount + membershipRequest.alertCount;

            return membershipRequest;
          });

          //MD: remove the undefined entities
          const filteredList = newOpportunityListItems.filter(function (newOpportunity) {
            return newOpportunity != null;
          });

          const newOpportunityList: IDashboardList = {
            alertCount,
            rows: filteredList,
            listType: ListTypeEnum.OPPORTUNITIES,
          };

          return newOpportunityList;
        },
      ),
    );
  }

  getStaffBankMembershipRequestsSearchFilterFormState() {
    return this.store.pipe(select(selectStaffBankMembershipRequestsSearchFilterFormState));
  }

  getNotesFormState(): Observable<FormGroupState<INoteFormState>> {
    return this.store.pipe(select(selectNotesFormState));
  }

  getRejectFormState(): Observable<FormGroupState<IRejectRequestFormState>> {
    return this.store.pipe(select(selectRejectFormState));
  }

  //#region dispatchEvents

  dispatchAddSelectedStaffBankMembershipRequestSignal(id: number) {
    this.store.dispatch(new AddSelectedStaffBankMembershipRequestSignal({ id }));
  }

  dispatchExportStaffBankMembershipRequestsSignal() {
    this.store.dispatch(new ExportStaffBankMembershipRequestsSignal({}));
  }

  dispatchInitializeSearchFilterFormSignal(namespace: string) {
    this.store.dispatch(new InitializeSearchFilterFormSignal({ namespace }));
  }

  dispatchRemoveSelectedStaffBankMembershipRequestSignal(id: number) {
    this.store.dispatch(new RemoveSelectedStaffBankMembershipRequestSignal({ id }));
  }

  dispatchSelectAllInCurrentPageSignal(namespace: string) {
    this.store.dispatch(new SelectAllInCurrentPageSignal({ namespace }));
  }

  dispatchSubmitSearchFilterFormSignal(namespace: string) {
    this.store.dispatch(new SubmitSearchFilterFormSignal({ namespace }));
  }

  dispatchUnselectAllInCurrentPageSignal(namespace: string) {
    this.store.dispatch(new UnselectAllInCurrentPageSignal({ namespace }));
  }

  dispatchUpdateSelectedPageUsingArrowsSignal(direction: IArrowDirectionsEnum, namespace: string) {
    this.store.dispatch(new UpdateSelectedPageUsingArrowsSignal({ direction, namespace }));
  }

  dispatchUpdateSelectedPageUsingInputSignal(newSelectedPage: number, namespace: string) {
    this.store.dispatch(new UpdateSelectedPageUsingInputSignal({ newSelectedPage, namespace }));
  }

  dispatchUpdateStaffBankMembershipRequestSignal(id: number, membershipStatus: number) {
    this.store.dispatch(new UpdateStaffBankMembershipRequestSignal({ id, membershipStatus }));
  }

  dispatchRejectStaffBankMembershipRequestSignal(id: number) {
    this.store.dispatch(new RejectStaffBankMembershipRequestSignal({ id }));
  }

  dispatchNavigateToStaffBankRequestSignal(status?: string, profile?: string) {
    this.store.dispatch(new NavigateToStaffBankRequestSignal({ status, profile }));
  }

  dispatchLoadMoreHospitalCertificateListSignal(namespace: string) {
    this.store.dispatch(new LoadMoreHospitalCertificateListSignal({ namespace }));
  }

  dispatchLoadMoreNewOpportunityListSignal(namespace: string) {
    this.store.dispatch(new LoadMoreNewOpportunityListSignal({ namespace }));
  }

  // #endregion dispatchEvents

  private loadDependencies(action: Action, dependencies: IStaffBankMembershipRequestDependencies) {
    const actions: Observable<Action>[] = [of(action)];
    if (action.type === StaffBankMembershipRequestMessageTypes.UPSERT_MULTIPLE) {
      const payload = (action as UpsertMultipleMessage).payload;

      if (dependencies.loadProfile) {
        const profileIds = payload.entities.map((x) => x.profile);

        if (profileIds.length > 0) {
          actions.push(this.profileService.loadByIds(profileIds));

          if (dependencies.loadMemberships) {
            actions.push(
              this.staffBankMembershipService.loadByProfileIds(
                profileIds,
                dependencies.loadHospital,
              ),
            );
          }

          if (dependencies.loadProfileNotes) {
            //dummy namespace we won't query by namespace
            actions.push(
              this.profileNoteService.loadByProfileIds(
                'staffBankMembershipProfileNotes',
                profileIds,
              ),
            );
          }
        }
      }
    }

    return merge(...actions);
  }
}
