import {
  createFormGroupState,
  createFormStateReducerWithUpdate,
  FormGroupState,
  setUserDefinedProperty,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { maxLength, required } from 'ngrx-forms/validation';

import { ICloneDocumentToHospitalFormState } from '@locumsnest/core/src';
import { IAlertState } from '@locumsnest/core/src/lib/adapters/alert-state-adapter/interfaces';
import { IContactFormState } from '@locumsnest/core/src/lib/adapters/contact-form-adapter';
import { INoteComponentState } from '@locumsnest/core/src/lib/adapters/note-component-adapter';

import {
  DEFAULT_INITIAL_CLONE_DOCUMENT_FORM_STATE,
  validationReducer,
} from '../../../../../../../libs/core/src/lib/adapters/clone-document-to-hospital-form-adapter/clone-document-to-hospital-form-state';
import { NOTE_COMPONENT_VALIDATORS } from '../../../profile-note/+state/profile-note.reducer';
import {
  EmploymentHistoryFilterTypes,
  IProfileUiState,
  IUploadFileFormState,
  ProfileAssignmentNumbersFilterTypes,
} from '../interfaces';
import {
  alertStateAdapter,
  cloneDocumentToHospitalFromAdapter,
  noteFromAdapter,
} from './ui.adapter';
import {
  AddCopiedCertificateMessage,
  AddCopiedReferenceMessage,
  DisableSubmitButtonMessage,
  EnableSubmitButtonMessage,
  InitializeCloneDocumentToHospitalMessage,
  InitializeComplianceMessageMessage,
  InitializeNoteAreaMessage,
  InitializeProfileUiMessage,
  ProfileUiMessages,
  RemoveFileMessage,
  SelectTabMessage,
  SetEmploymentHistoryFilterMessage,
  SetOtherCertificateTypeMessage,
  SetProfileAssignmentNumbersFilterMessage,
  UpdateShowFlaggedProfileNotesForProfileMessage,
  UpdateShowFlaggedProfileNotesMessage,
} from './ui.messages';
import { isExpirationDateInFuture, isOtherTypeSelected } from './ui.validators';

export { IProfileUiState } from '../interfaces';
export * from './ui.selectors';
export type State = IProfileUiState & { alertState?: IAlertState } & {
  complianceMessageForm?: FormGroupState<IContactFormState>;
};
export const FORM_ID = 'PROFILE_UPLOAD_FILE_FORM';
export const NOTE_FORM_ID = 'PROFILE_NOTE_FORM';
export const COMPLIANCE_MESSAGE_FORM_ID = 'COMPLIANCE_MESSAGE_FORM';
export const CLONE_DOCUMENT_TO_HOSPITAL_FORM_ID = 'CLONE_DOCUMENT_TO_HOSPITAL_FORM_ID';

export const INITIAL_UPLOAD_FILE_FORM_STATE: IUploadFileFormState = {
  file: '',
  type: null,
  expirationDate: null,
  title: '',
};

export const INITIAL_NOTE_FORM_STATE: INoteComponentState = {
  note: '',
};

export const INITIAL_COMPLIANCE_MESSAGE_FORM_STATE: IContactFormState = {
  message: '',
  subject: null,
};

export const INITIAL_CLONE_DOCUMENT_TO_HOSPITAL_FORM_STATE: ICloneDocumentToHospitalFormState =
  DEFAULT_INITIAL_CLONE_DOCUMENT_FORM_STATE;

const fileFormGroupValidator = updateGroup<IUploadFileFormState>({
  file: validate<string>([required]),
  type: validate<number>([required]),
  expirationDate: (expirationDate, state) =>
    validate<Date>([isExpirationDateInFuture(state)])(expirationDate),
  title: (title, state) => validate<string>([isOtherTypeSelected(state)])(title),
});

const contactFormValidator = updateGroup<IContactFormState>({
  message: validate<string>([required, maxLength(1024)]),
  subject: validate<number>([required]),
});

export const INITIAL_UI_STATE: IProfileUiState = {
  employmentHistoryFilter: EmploymentHistoryFilterTypes.none,
  profileAssignmentNumbersFilter: ProfileAssignmentNumbersFilterTypes.none,
  safeboxUploadFileFormState: createFormGroupState<IUploadFileFormState>(
    FORM_ID,
    INITIAL_UPLOAD_FILE_FORM_STATE
  ),
  noteFormState: createFormGroupState<INoteComponentState>(NOTE_FORM_ID, INITIAL_NOTE_FORM_STATE),
  complianceMessageFormState: createFormGroupState<IContactFormState>(
    COMPLIANCE_MESSAGE_FORM_ID,
    INITIAL_COMPLIANCE_MESSAGE_FORM_STATE
  ),
  cloneDocumentToHospitalFormState: createFormGroupState<ICloneDocumentToHospitalFormState>(
    CLONE_DOCUMENT_TO_HOSPITAL_FORM_ID,
    INITIAL_CLONE_DOCUMENT_TO_HOSPITAL_FORM_STATE
  ),
  copiedCertificates: [],
  copiedReferences: [],
  selectedTab: 'general',
  showFlaggedNotes: false,
  showFlaggedNotesProfileIds: {},
  alertState: {
    message: '',
    type: '',
    displayAlertMessage: false,
  },
};

const alertStateReducer = alertStateAdapter.createReducer({});

export const fileUploadFormStateReducer =
  createFormStateReducerWithUpdate<IUploadFileFormState>(fileFormGroupValidator);

export function fileUploadFormReducer(
  state: FormGroupState<IUploadFileFormState> = createFormGroupState<IUploadFileFormState>(
    FORM_ID,
    INITIAL_UPLOAD_FILE_FORM_STATE
  ),
  action
) {
  switch (action.type) {
    case RemoveFileMessage.TYPE: {
      const otherCertificateType = state.userDefinedProperties['otherCertificateType'];
      state = createFormGroupState<IUploadFileFormState>(FORM_ID, INITIAL_UPLOAD_FILE_FORM_STATE);
      state = setUserDefinedProperty('otherCertificateType', otherCertificateType)(state);
      break;
    }
    case SetOtherCertificateTypeMessage.TYPE:
      state = setUserDefinedProperty(
        'otherCertificateType',
        (action as SetOtherCertificateTypeMessage).payload.certificateType
      )(state);
      break;
    default:
      break;
  }

  state = fileUploadFormStateReducer(state, action);

  return state;
}

export const noteFormStateReducer =
  noteFromAdapter.createNoteComponentReducer(NOTE_COMPONENT_VALIDATORS);

export function noteFormReducer(
  state: FormGroupState<INoteComponentState> = createFormGroupState<INoteComponentState>(
    FORM_ID,
    INITIAL_NOTE_FORM_STATE
  ),
  action
) {
  switch (action.type) {
    case InitializeNoteAreaMessage.TYPE:
      state = createFormGroupState<INoteComponentState>(FORM_ID, INITIAL_NOTE_FORM_STATE);
      break;
    default:
      break;
  }

  state = noteFormStateReducer(state, action);

  return state;
}

export const cloneDocumentToHospitalFormStateReducer =
  cloneDocumentToHospitalFromAdapter.createCloneDocumentToHospitalFormStateReducer();

export function cloneDocumentToHospitalFormReducer(
  // eslint-disable-next-line max-len
  state: FormGroupState<ICloneDocumentToHospitalFormState> = createFormGroupState<ICloneDocumentToHospitalFormState>(
    FORM_ID,
    INITIAL_CLONE_DOCUMENT_TO_HOSPITAL_FORM_STATE
  ),
  action
) {
  switch (action.type) {
    case InitializeCloneDocumentToHospitalMessage.TYPE:
      state = createFormGroupState<ICloneDocumentToHospitalFormState>(
        FORM_ID,
        INITIAL_CLONE_DOCUMENT_TO_HOSPITAL_FORM_STATE
      );
      state = validationReducer(state);
      break;
    default:
      break;
  }

  state = cloneDocumentToHospitalFormStateReducer(state, action);

  return state;
}

const contactFormStateReducer =
  createFormStateReducerWithUpdate<IContactFormState>(contactFormValidator);

export function contactFormReducer(
  state: FormGroupState<IContactFormState> = createFormGroupState<IContactFormState>(
    COMPLIANCE_MESSAGE_FORM_ID,
    INITIAL_COMPLIANCE_MESSAGE_FORM_STATE
  ),
  action
) {
  switch (action.type) {
    case InitializeComplianceMessageMessage.TYPE:
      state = createFormGroupState<IContactFormState>(
        COMPLIANCE_MESSAGE_FORM_ID,
        INITIAL_COMPLIANCE_MESSAGE_FORM_STATE
      );
      break;
    case EnableSubmitButtonMessage.TYPE:
      state = setUserDefinedProperty('enableSubmitButton', true)(state);
      break;
    case DisableSubmitButtonMessage.TYPE:
      state = setUserDefinedProperty('enableSubmitButton', false)(state);
      break;
    default:
      break;
  }

  state = contactFormValidator(state);
  state = contactFormStateReducer(state, action);

  return state;
}

export function uiReducer(state: State = INITIAL_UI_STATE, action: ProfileUiMessages) {
  switch (action.type) {
    case InitializeProfileUiMessage.TYPE:
      return { ...INITIAL_UI_STATE };
      break;
    case SetEmploymentHistoryFilterMessage.TYPE:
      state = {
        ...state,
        employmentHistoryFilter: (action as SetEmploymentHistoryFilterMessage).payload.filter,
      };
      break;
    case SetProfileAssignmentNumbersFilterMessage.TYPE:
      state = {
        ...state,
        profileAssignmentNumbersFilter: (action as SetProfileAssignmentNumbersFilterMessage).payload
          .filter,
      };
      break;
    case AddCopiedCertificateMessage.TYPE: {
      const copiedCertificates: number[] = [];

      for (const existingCopiedCertificate of state.copiedCertificates) {
        copiedCertificates.push(existingCopiedCertificate);
      }

      copiedCertificates.push((action as AddCopiedCertificateMessage).payload.id);
      state = { ...state, copiedCertificates };
      break;
    }
    case AddCopiedReferenceMessage.TYPE: {
      const copiedReferences: number[] = [];

      for (const existingCopiedReference of state.copiedReferences) {
        copiedReferences.push(existingCopiedReference);
      }

      copiedReferences.push((action as AddCopiedReferenceMessage).payload.id);
      state = { ...state, copiedReferences };
      break;
    }
    case SelectTabMessage.TYPE:
      state = { ...state, selectedTab: (action as SelectTabMessage).payload.tab };
      break;

    case UpdateShowFlaggedProfileNotesMessage.TYPE:
      state = {
        ...state,
        showFlaggedNotes: (action as UpdateShowFlaggedProfileNotesMessage).payload.showFlaggedNotes,
      };
      break;
    case UpdateShowFlaggedProfileNotesForProfileMessage.TYPE:
      {
        const payload = (action as UpdateShowFlaggedProfileNotesForProfileMessage).payload;

        state = {
          ...state,
          showFlaggedNotesProfileIds: {
            ...state.showFlaggedNotesProfileIds,
            [payload.profileId]: payload.showFlaggedNotes,
          },
        };
      }
      break;
    default:
      break;
  }

  state = {
    ...state,
    safeboxUploadFileFormState: fileUploadFormReducer(state.safeboxUploadFileFormState, action),
  };
  state = { ...state, noteFormState: noteFormReducer(state.noteFormState, action) };
  state = {
    ...state,
    complianceMessageForm: contactFormReducer(state.complianceMessageForm, action),
  };
  state = {
    ...state,
    cloneDocumentToHospitalFormState: cloneDocumentToHospitalFormReducer(
      state.cloneDocumentToHospitalFormState,
      action
    ),
  };

  return state;
}

export function reducer(state: State, action) {
  const ui = uiReducer(state, action);
  const alertState = alertStateReducer(state ? state.alertState : undefined, action);

  return {
    ...ui,
    alertState,
  };
}
