import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { reducers } from './+state';
import { TagFormEffects } from './+state/tag-form/tag-form.effects';
import { featureKey } from './+state/tag.reducer';

export const tagProviders: EnvironmentProviders[] = [
  importProvidersFrom([
    StoreModule.forFeature(featureKey, reducers),
    EffectsModule.forFeature(TagFormEffects),
  ]),
];
