import { Action } from '@ngrx/store';
import { IGmcStatusEntity } from '../../interfaces/api/gmc-status-entity';

export enum GmcStatusSignalTypes {
  LOAD_GMC_STATUS = '[GmcStatus][Signal] Load Collection',
}
export enum GmcStatusMessageTypes {
  SET_COLLECTION = '[GmcStatus][Message] Set Collection',
  ADD_ONE = '[GmcStatus][Message] Add One',
  UPDATE_ONE = '[GmcStatus][Message] Update One',
  UPSERT_ONE = '[GmcStatus][Message] Upsert One',
  DELETE_ONE = '[GmcStatus][Message] Delete One',
  ADD_MULTIPLE = '[GmcStatus][Message] Add All',
  DELETE_MULTIPLE = '[GmcStatus][Message] Delete Many',
  UPSERT_MULTIPLE = '[GmcStatus][Message] Upsert Many',
  UPDATE_MULTIPLE = '[GmcStatus][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = GmcStatusMessageTypes.SET_COLLECTION;
  constructor(public payload: { gmcStatuses: IGmcStatusEntity[] }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = GmcStatusMessageTypes.UPSERT_ONE;

  constructor(public payload: { gmcStatus: IGmcStatusEntity }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = GmcStatusMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { gmcStatuses: IGmcStatusEntity[] }) {}
}

export type GmcStatusMessages = SetCollectionMessage | UpsertOneMessage | UpsertMultipleMessage;
