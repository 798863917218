import {
  IProfileStatisticsEntityState,
  IProfileStatisticsFeatureState,
  IProfileStatisticsSharedState,
} from './interfaces';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { selectAll } from './profile-statistics.reducer';
import { IProfileStatisticsEntity } from '../../interfaces/api/profile-statistics-entity';

export const getEntityState = (state: { entityState: IProfileStatisticsEntityState }) =>
  state.entityState;

export const getProfileStatistics = (profile: string) => (entities: IProfileStatisticsEntity[]) =>
  entities.find((entity) => entity.profile === profile);

export const selectProfileStatisticsState =
  createFeatureSelector<IProfileStatisticsFeatureState & IProfileStatisticsSharedState>(
    'profileStatistics'
  );

export const selectProfileStatisticsEntityState = createSelector(
  selectProfileStatisticsState,
  getEntityState
);

export const selectAllProfileStatistics = createSelector(
  selectProfileStatisticsEntityState,
  selectAll
);

export const selectProfileStatistics = (profile: string) =>
  createSelector(selectAllProfileStatistics, getProfileStatistics(profile));
