import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IStaffBankMembershipRequestEntity } from '../../interfaces/api/staff-bank-membership-request-entity';

@Injectable({
  providedIn: 'root',
})
export class StaffBankMembershipRequestPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IStaffBankMembershipRequestEntity
> {
  protected readonly endpoint = 'staffBankMembershipRequest';

  updateStatus(id: number, status: number) {
    return this.create<{ status: number }, IStaffBankMembershipRequestEntity>(
      { status },
      {
        pathParams: { id },
        controllerResource: 'updateStatus',
        skipSerializer: true,
      },
    );
  }

  reject(id: number, rejectionReason: number, rejectionReasonOther: string) {
    const payload: { rejectionReason: number; rejectionReasonOther?: string } = { rejectionReason };

    if (rejectionReasonOther) Object.assign(payload, { rejectionReasonOther });

    return this.create<typeof payload, IStaffBankMembershipRequestEntity>(payload, {
      pathParams: { id },
      controllerResource: 'staffbankMembershipReject',
      skipSerializer: true,
    });
  }
}
