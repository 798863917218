import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { flatMap, isNumber, isString } from 'lodash-es';
import { combineLatest, map, Observable } from 'rxjs';

import { ISelectOption } from '@locumsnest/components/src';
import { Query, StateService } from '@locumsnest/core/src';
import { filterNonEmpty } from '@locumsnest/core/src/lib/ngrx/operators';

import { HospitalService } from '../../hospital/+state/hospital.services';
import { IStaffBankCollaborationGroupEntity } from '../../interfaces/api/staff-bank-collaboration-group-entity';
import { UpsertMultipleMessage, UpsertOneMessage } from './staff-bank-collaboration-group.messages';
import { StaffBankCollaborationGroupPersistenceService } from './staff-bank-collaboration-group.persistence.service';
import {
  selectAllStaffBankCollaborationGroups,
  selectStaffBankCollaborationGroupsByHospitalIds,
} from './staff-bank-collaboration-group.selectors';

@Injectable({
  providedIn: 'root',
})
export class StaffBankCollaborationGroupService extends StateService<IStaffBankCollaborationGroupEntity> {
  constructor(
    private store: Store,
    protected persistenceService: StaffBankCollaborationGroupPersistenceService,
    private hospitalService: HospitalService,
  ) {
    super();
  }

  getAll() {
    return this.store.pipe(select(selectAllStaffBankCollaborationGroups), filterNonEmpty);
  }

  getGroupsByHospitalIds(ids: number[]) {
    return this.store.pipe(select(selectStaffBankCollaborationGroupsByHospitalIds(ids)));
  }

  getStaffBankCollaborationGroupTrustOptions(): Observable<ISelectOption[]> {
    return combineLatest([
      this.getAll(),
      this.hospitalService.getAssigned(),
      this.hospitalService.getAll(),
    ]).pipe(
      map(([collaborationGroups, assignedHospital, hospitals]) => {
        const options: ISelectOption[] = [];
        collaborationGroups.map((collabGroup) => {
          if (collabGroup.hospitals.includes(assignedHospital.id)) {
            collabGroup.hospitals.map((hospitalId) => {
              if (
                hospitals.find(
                  (hospital) => hospital.id === hospitalId && assignedHospital.id !== hospitalId,
                ) &&
                !options.find((option) => option.id === hospitalId)
              ) {
                const option: ISelectOption = {
                  id: hospitalId,
                  name: hospitals.find((hospital) => hospital.id === hospitalId).name,
                };
                options.push(option);
              }
            });
          }
        });
        return options;
      }),
    );
  }

  getStaffBankCollaborationGroupTrustIds(includeCurrentTrust = true): Observable<number[]> {
    return combineLatest([this.getAll(), this.hospitalService.getAssigned()]).pipe(
      map(([collaborationGroups, assignedHospital]) => {
        const commonColGroups = assignedHospital.staffBankCollaborationGroups
          .map((group) => collaborationGroups.find((cGroup) => cGroup.id === group))
          .filter((x) => !!x);
        const acc = includeCurrentTrust ? [assignedHospital.id] : [];
        const common = commonColGroups
          .map((g) => g.hospitals)
          .reduce((arr, val) => arr.concat(val), acc);

        return [...new Set(common)];
      }),
    );
  }
  getAssignedHospitalStaffBankCollaborationGroups(): Observable<
    IStaffBankCollaborationGroupEntity[]
  > {
    return combineLatest([this.getAll(), this.hospitalService.getAssignedHospitalId()]).pipe(
      map(([collaborationGroups, assignedHospitalId]) =>
        collaborationGroups.filter((g) => g.hospitals.includes(assignedHospitalId)),
      ),
    );
  }

  getAssignedHospitalStaffBankCollaborationGroupHospitals(): Observable<number[]> {
    return this.getAssignedHospitalStaffBankCollaborationGroups().pipe(
      map((collaborationGroups) => [
        ...new Set(
          flatMap(collaborationGroups, (collaborationGroup) => collaborationGroup.hospitals),
        ),
      ]),
    );
  }

  fetch(query?: Query) {
    if (isString(query) || isNumber(query))
      return this.persistenceService
        .retrieve(query)
        .pipe(
          map(
            (staffBankCollaborationGroup) => new UpsertOneMessage({ staffBankCollaborationGroup }),
          ),
        );
    return this.persistenceService
      .retrieve(query)
      .pipe(
        map(({ results }) => new UpsertMultipleMessage({ staffBankCollaborationGroups: results })),
      );
  }
}
