import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IStaffbankRejectionReasonFeatureState } from './interfaces';
import { paginationAdapter } from './staff-bank-rejection-reason.adapter';
import { featureKey, selectAll } from './staff-bank-rejection-reason.reducer';

const selectStaffbankRejectionReasonsState =
  createFeatureSelector<IStaffbankRejectionReasonFeatureState>(featureKey);
export const selectStaffbankRejectionReasonsEntityState = createSelector(
  selectStaffbankRejectionReasonsState,
  (state) => state.entityState,
);
export const staffbankRejectionReasonsPaginationSelectors = paginationAdapter.paginationSelectors(
  selectStaffbankRejectionReasonsState,
  null,
  'pagination',
  'entityState',
  false,
);

const selectAllEntities = createSelector(selectStaffbankRejectionReasonsEntityState, selectAll);

export const selectStaffbankRejectionReasonByCode = (code: string) =>
  createSelector(selectAllEntities, (reasons) => reasons.find((status) => status.code === code));

export const selectRejectionReasonsByIds = (ids: number[]) =>
  createSelector(selectStaffbankRejectionReasonsEntityState, (entities) =>
    ids.map((id) => entities.entities[id]),
  );
