import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  IPassportDashboardFeatureState,
  IPassportDashboardSharedState,
  IPassportDashboardUiState,
} from './interfaces';

/**
 * Create Feature Selectors based on the above selectors
 */

export const getUiState = (state: IPassportDashboardFeatureState & IPassportDashboardSharedState) =>
  state.uiState;

const getShowAlertList = (uiState: IPassportDashboardUiState) => uiState.showAlertList;
const getShowDiagnostic = (uiState: IPassportDashboardUiState) => uiState.showDiagnostic;

export const selectPassportDashboardState =
  createFeatureSelector<IPassportDashboardFeatureState & IPassportDashboardSharedState>(
    'passportDashboard'
  );

export const selectPassportDashboardUiState = createSelector(
  selectPassportDashboardState,
  getUiState
);

export const selectShowAlertList = createSelector(selectPassportDashboardUiState, getShowAlertList);
export const selectShowDiagnostic = createSelector(
  selectPassportDashboardUiState,
  getShowDiagnostic
);
