import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IJobPositionEntity } from '../../interfaces/api/job-position-entity';
import { MessageableFactory, SignalableFactory } from '@locumsnest/core';
import { createPaginatedSubresourceStateAdapter } from '@locumsnest/core/src/lib/adapters';

export function sortByDate(a: IJobPositionEntity, b: IJobPositionEntity): number {
  return a.startDate > b.startDate ? 0 : 1;
}

export const adapter: EntityAdapter<IJobPositionEntity> = createEntityAdapter<IJobPositionEntity>({
  sortComparer: sortByDate,
});

export const messageableFactory = MessageableFactory.forFeature<'JobPosition'>('JobPosition');
export const signalableFactory = SignalableFactory.forFeature<'JobPosition'>('JobPosition');
export const paginationAdapter = createPaginatedSubresourceStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedJobPositionId: null,
});
