import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { IProfileNoteEntity } from '../../interfaces/api/profile-note-entity';
import { paginationAdapter } from './profile-note.adapter';

export const ProfileNotePaginationMessages = paginationAdapter.getMessages();

export class UpsertProfileNotePageMessage extends ProfileNotePaginationMessages.UpsertPageMessage {}
export class UpsertProfileNoteMultiplePagesMessage extends ProfileNotePaginationMessages.UpsertMultiplePagesMessage {}
export class ResetProfileNotePaginationMessage extends ProfileNotePaginationMessages.ResetPaginationMessage {}

export enum ProfileNoteSignalTypes {
  LOAD_PROFILE_NOTES = '[ProfileNote][Signal] Load Collection',
}

export enum ProfileNoteMessageTypes {
  SET_COLLECTION = '[ProfileNote][Message] Set Collection',
  ADD_ONE = '[ProfileNote][Message] Add One',
  UPDATE_ONE = '[ProfileNote][Message] Update One',
  UPSERT_ONE = '[ProfileNote][Message] Upsert One',
  DELETE_ONE = '[ProfileNote][Message] Delete One',
  ADD_MULTIPLE = '[ProfileNote][Message] Add All',
  DELETE_MULTIPLE = '[ProfileNote][Message] Delete Many',
  UPSERT_MULTIPLE = '[ProfileNote][Message] Upsert Many',
  UPDATE_MULTIPLE = '[ProfileNote][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = ProfileNoteMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IProfileNoteEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = ProfileNoteMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IProfileNoteEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = ProfileNoteMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IProfileNoteEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = ProfileNoteMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IProfileNoteEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = ProfileNoteMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IProfileNoteEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = ProfileNoteMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IProfileNoteEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = ProfileNoteMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IProfileNoteEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = ProfileNoteMessageTypes.DELETE_ONE;

  constructor(public payload: { id: number }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = ProfileNoteMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type ProfileNoteMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
