import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import {
  IStaffingCascadeEntity,
  IStaffingCascadeEntityStats,
} from '../../interfaces/api/staffing-cascade-entity';
import {
  createSingletonResourceStateAdapter,
  ISingletonResourceAdapter,
} from '@locumsnest/core/src/lib/adapters/singleton-resource-adapter';

import { MessageableFactory, SignalableFactory } from '@locumsnest/core/src/';
export function sortByName(a: IStaffingCascadeEntity, b: IStaffingCascadeEntity): number {
  return a.id - b.id;
}

export const adapter: EntityAdapter<IStaffingCascadeEntity> =
  createEntityAdapter<IStaffingCascadeEntity>({
    sortComparer: sortByName,
  });

export const messageableFactory =
  MessageableFactory.forFeature<'StaffingCascade'>('StaffingCascade');
export const signalableFactory = SignalableFactory.forFeature<'StaffingCascade'>('StaffingCascade');

export const statsAdapter: ISingletonResourceAdapter<number, IStaffingCascadeEntityStats> =
  createSingletonResourceStateAdapter<'StaffingCascade', number, IStaffingCascadeEntityStats>(
    messageableFactory,
    'stats'
  );
export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedStaffingCascadeId: null,
});
