import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { isNumber, isString } from 'lodash-es';
import { filter, map } from 'rxjs/operators';

import { AttributeStateService, Query } from '@locumsnest/core/src';

import { IJobListingTypeEntity } from '../../interfaces/api/job-listing-type-entity';
import { UpsertMultipleMessage, UpsertOneMessage } from './job-listing-type.messages';
import { JobListingTypePersistenceService } from './job-listing-type.persistence.service';
import {
  selectAllJobListingTypes,
  selectDefaultJobListingType,
  selectJobListingType,
  selectJobListingTypeOptions,
} from './job-listing-type.selectors';

@Injectable({
  providedIn: 'root',
})
export class JobListingTypeService extends AttributeStateService<IJobListingTypeEntity> {
  constructor(
    private store: Store,
    protected persistenceService: JobListingTypePersistenceService
  ) {
    super();
  }

  getJobListingTypeOptions() {
    return this.store.pipe(
      select(selectJobListingTypeOptions),
      filter((options) => options && options.length > 0)
    );
  }

  getAll() {
    return this.store.pipe(select(selectAllJobListingTypes));
  }

  getOne(id: number) {
    return this.store.pipe(select(selectJobListingType(id)));
  }

  getDefaultJobListingType() {
    return this.store.pipe(select(selectDefaultJobListingType));
  }

  fetch(query?: Query) {
    if (isString(query) || isNumber(query))
      return this.persistenceService
        .retrieve(query)
        .pipe(map((jobListingType) => new UpsertOneMessage({ jobListingType })));
    return this.persistenceService
      .retrieve(query)
      .pipe(map((results) => new UpsertMultipleMessage({ jobListingTypes: results })));
  }
}
