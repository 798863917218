import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IStaffingCascadeTimeWindowEntity } from '../../interfaces/api/staffing-cascade-time-window-entity';

export function sortByName(
  a: IStaffingCascadeTimeWindowEntity,
  b: IStaffingCascadeTimeWindowEntity
): number {
  return a.id - b.id;
}

export const adapter: EntityAdapter<IStaffingCascadeTimeWindowEntity> =
  createEntityAdapter<IStaffingCascadeTimeWindowEntity>({
    sortComparer: sortByName,
  });

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedStaffingCascadeTimeWindowId: null,
});
