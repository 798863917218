import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { IApplicationHistoryEntity } from '../../interfaces/api/application-history-entity';
import { paginationAdapter } from './application-history.adapter';

export const { UpsertPageMessage, ResetPaginationMessage } = paginationAdapter.getMessages();

export class UpsertApplicationHistoryPageMessage extends UpsertPageMessage {}
export class ResetApplicationHistoryPaginationMessage extends ResetPaginationMessage {}

export enum ApplicationHistorySignalTypes {
  LOAD_APPLICATION_HISTORIES = '[ApplicationHistory][Signal] Load Collection',
}
export enum ApplicationHistoryMessageTypes {
  SET_COLLECTION = '[ApplicationHistory][Message] Set Collection',
  ADD_ONE = '[ApplicationHistory][Message] Add One',
  UPDATE_ONE = '[ApplicationHistory][Message] Update One',
  UPSERT_ONE = '[ApplicationHistory][Message] Upsert One',
  DELETE_ONE = '[ApplicationHistory][Message] Delete One',
  ADD_MULTIPLE = '[ApplicationHistory][Message] Add All',
  DELETE_MULTIPLE = '[ApplicationHistory][Message] Delete Many',
  UPSERT_MULTIPLE = '[ApplicationHistory][Message] Upsert Many',
  UPDATE_MULTIPLE = '[ApplicationHistory][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = ApplicationHistoryMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IApplicationHistoryEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = ApplicationHistoryMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IApplicationHistoryEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = ApplicationHistoryMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IApplicationHistoryEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = ApplicationHistoryMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IApplicationHistoryEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = ApplicationHistoryMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IApplicationHistoryEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = ApplicationHistoryMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IApplicationHistoryEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = ApplicationHistoryMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IApplicationHistoryEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = ApplicationHistoryMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = ApplicationHistoryMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type ApplicationHistoryMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | UpsertApplicationHistoryPageMessage
  | ResetApplicationHistoryPaginationMessage;
