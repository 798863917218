import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';

import { IProfileTagEntity } from '../../interfaces/api/profile-tag-entity';
import { paginationAdapter } from './profile-tag.adapter';

export const {
  ResetLoadingStateMessage,
  ResetPaginationMessage,
  SetLoadingStateMessage,
  UpsertMultiplePagesMessage,
  UpsertPageMessage,
} = paginationAdapter.getMessages();

export const paginationMessages = paginationAdapter.getMessages();

export class UpsertProfileTagPageMessage extends UpsertPageMessage {}
export class UpsertProfileTagMultiplePagesMessage extends UpsertMultiplePagesMessage {}
export class ResetProfileTagPaginationMessage extends ResetPaginationMessage {}

export enum ProfileTagSignalTypes {
  LOAD_PROFILE_TAGS = '[ProfileTag][Signal] Load Collection',
}
export enum ProfileTagMessageTypes {
  SET_COLLECTION = '[ProfileTag][Message] Set Collection',
  ADD_ONE = '[ProfileTag][Message] Add One',
  UPDATE_ONE = '[ProfileTag][Message] Update One',
  UPSERT_ONE = '[ProfileTag][Message] Upsert One',
  DELETE_ONE = '[ProfileTag][Message] Delete One',
  ADD_MULTIPLE = '[ProfileTag][Message] Add All',
  DELETE_MULTIPLE = '[ProfileTag][Message] Delete Many',
  UPSERT_MULTIPLE = '[ProfileTag][Message] Upsert Many',
  UPDATE_MULTIPLE = '[ProfileTag][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = ProfileTagMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IProfileTagEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = ProfileTagMessageTypes.ADD_ONE;
  constructor(public payload: { entity: IProfileTagEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = ProfileTagMessageTypes.UPSERT_ONE;
  constructor(public payload: { entity: IProfileTagEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = ProfileTagMessageTypes.ADD_MULTIPLE;
  constructor(public payload: { entities: IProfileTagEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = ProfileTagMessageTypes.UPSERT_MULTIPLE;
  constructor(public payload: { entities: IProfileTagEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = ProfileTagMessageTypes.UPDATE_ONE;
  constructor(public payload: { entity: Update<IProfileTagEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = ProfileTagMessageTypes.UPDATE_MULTIPLE;
  constructor(public payload: { entities: Update<IProfileTagEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = ProfileTagMessageTypes.DELETE_ONE;
  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = ProfileTagMessageTypes.DELETE_MULTIPLE;
  constructor(public payload: { ids: string[] }) {}
}

export type ProfileTagMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
