import { MessageableFactory, SignalableFactory } from '@locumsnest/core/src';
import { createExpandableStateAdapter } from '@locumsnest/core/src/lib/adapters/expandable-state-adapter';

export const messageableFactory =
  MessageableFactory.forFeature<'StaffBankMembershipRequestsSearchFilterForm'>(
    'StaffBankMembershipRequestsSearchFilterForm',
  );
export const signalableFactory =
  SignalableFactory.forFeature<'StaffBankMembershipRequestsSearchFilterForm'>(
    'StaffBankMembershipRequestsSearchFilterForm',
  );

export const expandableStateAdapter =
  createExpandableStateAdapter<'StaffBankMembershipRequestsSearchFilterForm'>(
    signalableFactory,
    messageableFactory,
  );
export const { ToggleExpandableSignal, SetExpandExpandableSignal } =
  expandableStateAdapter.getSignals();
