import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { paginationAdapter } from './external-staffing-provider-officer.adapter';
import { IExternalStaffingProviderOfficerEntity } from '../../interfaces/api/external-staffing-provider-officer-entity';

export const ExternalStaffingProviderOfficerPaginationMessages = paginationAdapter.getMessages();

export class UpsertExternalStaffingProviderOfficerPageMessage extends ExternalStaffingProviderOfficerPaginationMessages.UpsertPageMessage {}
export class UpsertExternalStaffingProviderOfficerMultiplePagesMessage extends ExternalStaffingProviderOfficerPaginationMessages.UpsertMultiplePagesMessage {}
export class ResetExternalStaffingProviderOfficerPaginationMessage extends ExternalStaffingProviderOfficerPaginationMessages.ResetPaginationMessage {}

export enum ExternalStaffingProviderOfficerSignalTypes {
  // eslint-disable-next-line max-len
  LOAD_EXTERNAL_STAFFING_PROVIDER_OFFICER = '[ExternalStaffingProviderOfficer][Signal] Load Collection',
}
export enum ExternalStaffingProviderOfficerMessageTypes {
  SET_COLLECTION = '[ExternalStaffingProviderOfficer][Message] Set Collection',
  ADD_ONE = '[ExternalStaffingProviderOfficer][Message] Add One',
  UPDATE_ONE = '[ExternalStaffingProviderOfficer][Message] Update One',
  UPSERT_ONE = '[ExternalStaffingProviderOfficer][Message] Upsert One',
  DELETE_ONE = '[ExternalStaffingProviderOfficer][Message] Delete One',
  ADD_MULTIPLE = '[ExternalStaffingProviderOfficer][Message] Add All',
  DELETE_MULTIPLE = '[ExternalStaffingProviderOfficer][Message] Delete Many',
  UPSERT_MULTIPLE = '[ExternalStaffingProviderOfficer][Message] Upsert Many',
  UPDATE_MULTIPLE = '[ExternalStaffingProviderOfficer][Message] Update Many',
  SET_ASSIGNED = '[ExternalStaffingProviderOfficer][Message] Set Assigned',
}
export class SetCollectionMessage implements Action {
  readonly type = ExternalStaffingProviderOfficerMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IExternalStaffingProviderOfficerEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = ExternalStaffingProviderOfficerMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IExternalStaffingProviderOfficerEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = ExternalStaffingProviderOfficerMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IExternalStaffingProviderOfficerEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = ExternalStaffingProviderOfficerMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IExternalStaffingProviderOfficerEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = ExternalStaffingProviderOfficerMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IExternalStaffingProviderOfficerEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = ExternalStaffingProviderOfficerMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IExternalStaffingProviderOfficerEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = ExternalStaffingProviderOfficerMessageTypes.UPDATE_MULTIPLE;

  constructor(
    public payload: {
      entities: Update<IExternalStaffingProviderOfficerEntity>[];
    }
  ) {}
}

export class DeleteOneMessage implements Action {
  readonly type = ExternalStaffingProviderOfficerMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = ExternalStaffingProviderOfficerMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}
export class SetAssignedMessage implements Action {
  readonly type = ExternalStaffingProviderOfficerMessageTypes.SET_ASSIGNED;

  constructor(public payload: { id: number }) {}
}
export type ExternalStaffingProviderOfficerMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | SetAssignedMessage;
