import {
  addArrayControl,
  box,
  createFormGroupState,
  createFormStateReducerWithUpdate,
  FormGroupState,
  markAsPristine,
  markAsUntouched,
  removeArrayControl,
  setValue,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { maxLength, required } from 'ngrx-forms/validation';

import { IJobListingEntity } from '../../../interfaces/api/job-listing-entity';
import { getFilterIdByName, ListFilterOption } from '../../filters/list';
import { IJobListingSearchFilterFormState } from '../interfaces/job-listing-search-filter-form-state';
import {
  AddExpandedJobListingMessage,
  AddSelectedJobListingMessage,
  ClearNotesMessage,
  ClearNotifyUsersMessage,
  ClearSelectedJobListingListMessage,
  InitializeJobListingSearchFilterFormMessage,
  JobListingSearchFilterFormMessages,
  RemoveExpandedJobListingMessage,
  RemoveSelectedJobListingMessage,
  SelectAllCurrentPageMessage,
  UnselectAllCurrentPageMessage,
  UpdateFilterFormMessage,
  UpdateSelectedPageMessage,
  UpdateSortedFieldMessage,
} from './search-filter-form.messages';

export * from './search-filter-form.selectors';
export const FORM_ID = 'JOB_LISTING_SEARCH_FILTER_FORM';
export type State = FormGroupState<IJobListingSearchFilterFormState>;

export const JOB_LISTING_FILTERS_FORM_INITIAL_VALUES: IJobListingSearchFilterFormState = {
  searchKeyword: null,
  fromDate: null,
  toDate: null,
  grade: null,
  applicationStatus: null,
  jobStatus: getFilterIdByName('all-upcoming', ListFilterOption.Status),
  published: null,
  cascaded: null,
  pendingOnly: false,
  selectedPage: 1,
  selectedJobListings: [],
  expandedJobListings: [],
  declinedApplications: [],
  sortedField: null,
  listingType: null,
  bidStatus: null,
  ratesLocked: null,
  tier: box<number[]>([]),
  escalated: null,
  notes: '',
  notifyUsers: false,
  hasPreMatchConversations: false,
  showAllTags: false,
  tags: box<number[]>([]),
  nonResidentOnCall: null,
  hospital: null,
  profession: null,
  site: null,
  directEngagement: null,
  specialty: null,
};

export const INITIAL_FORM_STATE = createFormGroupState<IJobListingSearchFilterFormState>(
  FORM_ID,
  JOB_LISTING_FILTERS_FORM_INITIAL_VALUES,
);

const formValidationReducer = (formState: FormGroupState<IJobListingSearchFilterFormState>) =>
  updateGroup<IJobListingSearchFilterFormState>({
    notes: validate<string>([required, maxLength(2048)]),
  })(formState);

export const formStateReducer = createFormStateReducerWithUpdate<IJobListingSearchFilterFormState>(
  [],
);

function findIndex(selectedJobListings: number[], id: number): number {
  return selectedJobListings.findIndex((x) => x === id);
}

export function formReducer(
  state: FormGroupState<IJobListingSearchFilterFormState> = INITIAL_FORM_STATE,
  action: JobListingSearchFilterFormMessages,
) {
  let jobListings: IJobListingEntity[] = [];

  switch (action.type) {
    case InitializeJobListingSearchFilterFormMessage.TYPE:
      state = createFormGroupState<IJobListingSearchFilterFormState>(
        FORM_ID,
        (action as InitializeJobListingSearchFilterFormMessage).payload
          .jobListingSearchFilterFormState,
      );
      state = updateGroup<IJobListingSearchFilterFormState>({})(state);
      break;
    case UpdateSelectedPageMessage.TYPE:
      state = updateGroup<IJobListingSearchFilterFormState>({
        selectedPage: setValue((action as UpdateSelectedPageMessage).payload.selectedPage),
      })(state);
      break;
    case AddSelectedJobListingMessage.TYPE:
      state = updateGroup<IJobListingSearchFilterFormState>({
        selectedJobListings: addArrayControl((action as AddSelectedJobListingMessage).payload.id),
      })(state);
      break;
    case RemoveSelectedJobListingMessage.TYPE:
      state = updateGroup<IJobListingSearchFilterFormState>({
        selectedJobListings: removeArrayControl(
          findIndex(
            state.controls.selectedJobListings.value,
            (action as RemoveSelectedJobListingMessage).payload.id,
          ),
        ),
      })(state);
      break;
    case SelectAllCurrentPageMessage.TYPE:
      jobListings = (action as SelectAllCurrentPageMessage).payload.jobListings;

      jobListings.forEach((jobListing) => {
        if (findIndex(state.controls.selectedJobListings.value, jobListing.id) < 0) {
          state = updateGroup<IJobListingSearchFilterFormState>({
            selectedJobListings: addArrayControl(jobListing.id),
          })(state);
        }
      });
      break;
    case UnselectAllCurrentPageMessage.TYPE:
      jobListings = (action as UnselectAllCurrentPageMessage).payload.jobListings;

      jobListings.forEach(
        (jobListing) =>
          (state = updateGroup<IJobListingSearchFilterFormState>({
            selectedJobListings: removeArrayControl(
              findIndex(state.controls.selectedJobListings.value, jobListing.id),
            ),
          })(state)),
      );
      break;
    case ClearSelectedJobListingListMessage.TYPE:
      state = updateGroup<IJobListingSearchFilterFormState>({
        selectedJobListings: setValue([]),
      })(state);
      break;
    case AddExpandedJobListingMessage.TYPE:
      if (
        findIndex(
          state.controls.expandedJobListings.value,
          (action as AddExpandedJobListingMessage).payload.id,
        ) < 0
      ) {
        state = updateGroup<IJobListingSearchFilterFormState>({
          expandedJobListings: addArrayControl((action as AddExpandedJobListingMessage).payload.id),
        })(state);
      }
      break;
    case RemoveExpandedJobListingMessage.TYPE:
      state = updateGroup<IJobListingSearchFilterFormState>({
        expandedJobListings: removeArrayControl(
          findIndex(
            state.controls.expandedJobListings.value,
            (action as RemoveExpandedJobListingMessage).payload.id,
          ),
        ),
      })(state);
      break;
    case UpdateSortedFieldMessage.TYPE:
      state = updateGroup<IJobListingSearchFilterFormState>({
        sortedField: setValue((action as UpdateSortedFieldMessage).payload.sortedField),
      })(state);
      break;

    case ClearNotesMessage.TYPE:
      state = updateGroup<IJobListingSearchFilterFormState>({
        notes: (notes) => setValue('')(notes),
      })(state);

      state = markAsUntouched(state);
      state = markAsPristine(state);
      break;

    case UpdateFilterFormMessage.TYPE: {
      const filters = (action as UpdateFilterFormMessage).payload.filters;
      state = updateGroup<IJobListingSearchFilterFormState>({
        sortedField: setValue(filters.sortedField),
        fromDate: setValue(filters.fromDate),
        toDate: setValue(filters.toDate),
        searchKeyword: setValue(filters.searchKeyword),
        grade: setValue(filters.grade),
        applicationStatus: setValue(filters.applicationStatus),
        jobStatus: setValue(filters.jobStatus),
        published: setValue(filters.published),
        cascaded: setValue(filters.cascaded),
        pendingOnly: setValue(filters.pendingOnly),
        listingType: setValue(filters.listingType),
        bidStatus: setValue(filters.bidStatus),
        ratesLocked: setValue(filters.ratesLocked),
        tier: setValue(filters.tier),
        escalated: setValue(filters.escalated),
        hasPreMatchConversations: setValue(filters.hasPreMatchConversations),
        showAllTags: setValue(filters.showAllTags),
        tags: setValue(filters.tags),
        nonResidentOnCall: setValue(filters.nonResidentOnCall),
        hospital: setValue(filters.hospital),
        profession: setValue(filters.profession),
        site: setValue(filters.site),
        directEngagement: setValue(filters.directEngagement),
        specialty: setValue(filters.specialty),
      })(state);
      break;
    }
    case ClearNotifyUsersMessage.TYPE:
      state = updateGroup<IJobListingSearchFilterFormState>({
        notifyUsers: (notifyUsers) => setValue(false)(notifyUsers),
      })(state);

      state = markAsUntouched(state);
      state = markAsPristine(state);
      break;
  }

  state = formStateReducer(state, action);
  state = formValidationReducer(state);

  return state;
}

export function reducer(state: State, action) {
  return formReducer(state, action);
}
