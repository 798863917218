import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src/';
import { createEntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';

import { IHospitalProfileTagEntity } from '../../interfaces/api/hospital-profile-tag-entity';

const namespace = 'HospitalProfileTag';

const adapter = createEntityAdapter<IHospitalProfileTagEntity>({ selectId: (entity) => entity.id });
const messageableFactory = MessageableFactory.forFeature<typeof namespace>(namespace);
const signalableFactory = SignalableFactory.forFeature<typeof namespace>(namespace);
const initialState = adapter.getInitialState({});
const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export { adapter, initialState, paginationAdapter };
