import { IFileEntity } from '../../interfaces/api/hospital-certificate-entity';

export interface IDashboardList {
  readonly rows: IDashboardRow[];
  readonly listType: ListTypeEnum;
  readonly alertCount: number;
}

export interface IDashboardRow {
  readonly id: number;
  readonly createdDate: Date;
  readonly profileName: string;
  readonly profession: string;
  readonly profile: string;
  readonly registrationNo: string;
  readonly status?: string;
  readonly file?: IFileEntity;
  readonly actioned?: boolean;
  readonly alertCount: number;
}

export enum ListTypeEnum {
  OPPORTUNITIES,
  CERTIFICATES,
}

export enum FunFactEnum {
  ACTIVE_PEOPLE,
  WORKERS,
  APPLIED,
  ENGAGED,
}
