import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { isNil, isNumber, isString } from 'lodash-es';
import { merge, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';

import { PaginatedStateService } from '@locumsnest/core';
import { Query } from '@locumsnest/core/src';

import { IExternalStaffingProviderOfficerEntity } from '../../interfaces/api/external-staffing-provider-officer-entity';
import { toSessionUser } from './../../../../../../libs/core/src/lib/ngrx/operators/index';
import { paginationAdapter } from './external-staffing-provider-officer.adapter';
import {
  ExternalStaffingProviderOfficerPaginationMessages,
  ResetExternalStaffingProviderOfficerPaginationMessage,
  SetAssignedMessage,
  UpsertExternalStaffingProviderOfficerPageMessage,
  UpsertMultipleMessage,
  UpsertOneMessage,
} from './external-staffing-provider-officer.messages';
import { ExternalStaffingProviderOfficerPersistenceService } from './external-staffing-provider-officer.persistence.service';
import {
  ExternalStaffingProviderOfficerPaginationSelectors,
  getAssignedUser,
  selectAllExternalStaffingProviderOfficers,
  selectAssignedExternalStaffingProviderOfficer,
  selectExternalStaffingProviderOfficerEntityState,
} from './external-staffing-provider-officer.selectors';

@Injectable({
  providedIn: 'root',
})
export class ExternalStaffingProviderOfficerService extends PaginatedStateService<
  IExternalStaffingProviderOfficerEntity,
  UpsertExternalStaffingProviderOfficerPageMessage,
  ResetExternalStaffingProviderOfficerPaginationMessage,
  UpsertMultipleMessage
> {
  constructor(
    protected store: Store,
    protected persistenceService: ExternalStaffingProviderOfficerPersistenceService
  ) {
    super();
  }
  get paginationMessages() {
    return ExternalStaffingProviderOfficerPaginationMessages;
  }

  get paginationSelectors() {
    return ExternalStaffingProviderOfficerPaginationSelectors;
  }

  get entityStateSelector() {
    return selectExternalStaffingProviderOfficerEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get loadingMessages() {
    return paginationAdapter.getLoadingMessages();
  }

  getAll() {
    return this.store.pipe(select(selectAllExternalStaffingProviderOfficers));
  }

  getAssigned() {
    return this.store
      .pipe(select(selectAssignedExternalStaffingProviderOfficer))
      .pipe(filter((x) => !!x));
  }

  getAssignedUserDetails() {
    return this.getAssigned().pipe(map(getAssignedUser), distinctUntilChanged());
  }
  loadCurrent() {
    return this.persistenceService.retrieveCurrent<IExternalStaffingProviderOfficerEntity>().pipe(
      toSessionUser('user.email'),
      mergeMap((result) => {
        let currentId = null;

        if (result) {
          currentId = result.id;
        }

        return merge(
          of(new UpsertOneMessage({ entity: result })),
          of(new SetAssignedMessage({ id: currentId }))
        );
      })
    );
  }

  fetch(query?: Query) {
    if (isNil(query)) {
      return this.loadAllPages('default', {});
    }
    if (isString(query) || isNumber(query))
      return this.persistenceService
        .retrieve(query)
        .pipe(map((entity) => new UpsertOneMessage({ entity })));
    return this.persistenceService
      .retrieve(query)
      .pipe(map(({ results }) => new UpsertMultipleMessage({ entities: results })));
  }
}
