import { entries, get } from 'lodash-es';
import {
  AbstractControlState,
  FormArrayState,
  FormGroupState,
  NgrxFormControlId,
  setUserDefinedProperty,
} from 'ngrx-forms';

/**
 * Returns array of id and index from array control id
 * @param id
 */
export const parseArrayControlId = (id: NgrxFormControlId) => {
  const idParts = id.split('.');
  const index = parseInt(idParts.pop(), 10);
  const arrayControlId: NgrxFormControlId = idParts.join('.');
  return [arrayControlId, index] as [NgrxFormControlId, number];
};

export const getSiblingControlId = (id: NgrxFormControlId, siblingControlName: string) => {
  const idParts = id.split('.');
  idParts.pop();
  return [...idParts, siblingControlName].join('.');
};

export const setUserDefinedProperties = (userDefinedProperties) => (state) => {
  for (const [key, value] of entries(userDefinedProperties)) {
    state = setUserDefinedProperty(state, key, value);
  }
  return state;
};

export const getControl = <T, V extends AbstractControlState<unknown>>(
  v: FormGroupState<T>,
  formId: string,
  controlId: string,
): V => {
  const path = controlId.replace(`${formId}.`, '').replace(/\./g, '.controls.');
  return get(v.controls, path);
};

export const getLastArrayControl = <V extends FormArrayState<unknown>>(array: V) =>
  array.controls[array.controls.length - 1];
