import { INoteComponentState } from '@locumsnest/core/src/lib/adapters/note-component-adapter';

import { INoteComponentValidator } from './interfaces';
import {
  createNoteComponentReducer,
  DEFAULT_INITIAL_FORM_STATE,
  DEFAULT_VALIDATORS,
} from './note-component-state';

export * from './interfaces';
export const createNoteComponentAdapter = (formID) => ({
  createNoteComponentReducer(
    validator: INoteComponentValidator = DEFAULT_VALIDATORS,
    initialFormState: INoteComponentState = DEFAULT_INITIAL_FORM_STATE
  ) {
    return createNoteComponentReducer(formID, validator, initialFormState);
  },
});
