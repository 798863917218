import { createSelector } from '@ngrx/store';
import { selectExternalStaffingCandidateBidState } from '../external-staffing-candidate-bid.selectors';
import { State } from '../interfaces';
import { IExternalStaffingCandidateBidUiState } from '../interfaces/external-staffing-candidate-bid-ui-state';

// #region getSelectors

export const getUiState = (state: State) => state.uiState;

const getActiveStepOne = (uiState: IExternalStaffingCandidateBidUiState) => uiState.activeStepOne;

const getActiveStepTwo = (uiState: IExternalStaffingCandidateBidUiState) => uiState.activeStepTwo;

const getCompleteStepOne = (uiState: IExternalStaffingCandidateBidUiState) =>
  uiState.completeStepOne;

const getCompleteStepTwo = (uiState: IExternalStaffingCandidateBidUiState) =>
  uiState.completeStepTwo;

const getSelectedBid = (uiState: IExternalStaffingCandidateBidUiState) => uiState.selectedBid;

const getShowFileUploader = (uiState: IExternalStaffingCandidateBidUiState) =>
  uiState.showFileUploader;

// #region selectSelectors

// #endregion getSelectors

// #region selectSelectors

export const selectExternalStaffingCandidateBidUiState = createSelector(
  selectExternalStaffingCandidateBidState,
  getUiState
);

export const selectActiveStepOne = createSelector(
  selectExternalStaffingCandidateBidUiState,
  getActiveStepOne
);

export const selectActiveStepTwo = createSelector(
  selectExternalStaffingCandidateBidUiState,
  getActiveStepTwo
);

export const selectCompleteStepOne = createSelector(
  selectExternalStaffingCandidateBidUiState,
  getCompleteStepOne
);

export const selectCompleteStepTwo = createSelector(
  selectExternalStaffingCandidateBidUiState,
  getCompleteStepTwo
);

export const selectSelectedBid = createSelector(
  selectExternalStaffingCandidateBidUiState,
  getSelectedBid
);

export const selectShowFileUploader = createSelector(
  selectExternalStaffingCandidateBidUiState,
  getShowFileUploader
);

// #endregion selectSelectors
