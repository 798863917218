import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IProfileTagEntity } from '../../interfaces/api/profile-tag-entity';

@Injectable({
  providedIn: 'root',
})
export class ProfileTagPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IProfileTagEntity
> {
  protected readonly endpoint = 'profileTag';
}
