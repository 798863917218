import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IJobPositionEntity } from '../../interfaces/api/job-position-entity';
import { selectAll } from './job-position.reducer';
import {
  IJobPositionEntityState,
  IJobPositionFeatureState,
  IJobPositionSharedState,
} from './interfaces';
import { paginationAdapter } from './job-position.adapter';

export const getEntityState = (state: { entityState: IJobPositionEntityState }) =>
  state.entityState;

export const getJobPositionsByProfileId =
  (profileId: string) => (jobPositions: IJobPositionEntity[]) =>
    jobPositions.filter((jobPosition) => jobPosition.profile === profileId);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectJobPositionState =
  createFeatureSelector<IJobPositionFeatureState & IJobPositionSharedState>('jobPositions');
export const selectJobPositionEntityState = createSelector(selectJobPositionState, getEntityState);
export const selectAllJobPositions = createSelector(selectJobPositionEntityState, selectAll);
export const selectJobPositionsByProfileId = (profileId: string) =>
  createSelector(selectAllJobPositions, getJobPositionsByProfileId(profileId));

export const jobPositionPaginationSelectors = paginationAdapter.getSubresourcePaginationSelectors(
  selectJobPositionEntityState
);
