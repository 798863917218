import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { combineLatest, filter, first, map, Observable, of, startWith, switchMap, tap } from 'rxjs';

import { IGlobalAlertHandler } from '@locumsnest/core/src';
import {
  IAlertData,
  IErrorHandlerMessageConfig,
} from '@locumsnest/core/src/lib/adapters/alert-state-adapter/alert-handler';
import { MicroAppService } from '@locumsnest/core/src/lib/micro-app/micro-app.service';

import { RouterService } from '.';
import { AuthGroupService } from '../../../auth-group/+state/auth-group.service';
import { ChameleonService } from '../../../hospital-officer/+state/chameleon-service';
import { HospitalOfficerService } from '../../../hospital-officer/+state/hospital-officer.service';
import { HospitalService } from '../../../hospital/+state/hospital.services';
import { PreferredPassportProfessionService } from '../../../preferred-passport-profession/+state/preferred-passport-profession.service';
import { PreferredProfessionService } from '../../../preferred-profession/+state/preferred-profession.service';
import { PreferredSpecialtyCategoryService } from '../../../preferred-specialty-category/+state/preferred-specialty-category.service';
import { ProfessionService } from '../../../profession/+state/profession.service';
import { SiteService } from '../../../site/+state/site.service';
import { SpecialtyService } from '../../../specialty/+state/specialty.service';
import {
  alertHandler,
  conditionalErrorHandler,
  ResetErrorSignal,
} from '../../+state/ui/ui.adapter';
import {
  selectLayoutUiAlertState,
  selectShowFiltersPanel,
  selectShowHeaderFilterInformation,
  // selectShowMessagingPanel,
  selectShowProductPanel,
} from '../../+state/ui/ui.selectors';
import { PRODUCT_CODES } from '../../constants';
import { IHeaderFilterInfo } from '../interfaces';
import { LayoutPersistenceService } from '../persistence/layout.persistence.service';

@Injectable()
export class LayoutService implements IGlobalAlertHandler {
  constructor(
    private store: Store,
    protected persistenceService: LayoutPersistenceService,
    protected microAppService: MicroAppService,
    protected preferredSpecialtyCategoryService: PreferredSpecialtyCategoryService,
    protected preferredPassportProfessionService: PreferredPassportProfessionService,
    protected preferredProfessionService: PreferredProfessionService,
    private authGroupService: AuthGroupService,
    private siteService: SiteService,
    private professionService: ProfessionService,
    private specialtyService: SpecialtyService,
    private hospitalService: HospitalService,
    private chameleonService: ChameleonService,
    private hospitalOfficerService: HospitalOfficerService,
    private routerService: RouterService,
  ) {}

  getShowHeaderFilterInformation() {
    return this.store.pipe(select(selectShowHeaderFilterInformation));
  }

  handleError<T>(errorHandlerMessageConfig?: IErrorHandlerMessageConfig) {
    return conditionalErrorHandler(errorHandlerMessageConfig);
  }

  handleAlert(alertData: IAlertData, inputStream?: Observable<Action>) {
    return alertHandler(alertData, inputStream);
  }

  resetAlert() {
    return this.store.dispatch(new ResetErrorSignal({}));
  }

  getAlertState() {
    return this.store.pipe(select(selectLayoutUiAlertState));
  }

  getShowFiltersPanel() {
    return this.store.pipe(select(selectShowFiltersPanel));
  }

  getShowMessagingPanel() {
    return this.routerService
      .getCurrentPath()
      .pipe(map((path) => path.includes('messaging-panel')));
  }

  getShowProductPanel() {
    return this.store.pipe(select(selectShowProductPanel));
  }

  getHeaderFilterInformation() {
    return combineLatest([
      this.microAppService.getMicroAppCode(),
      this.preferredSpecialtyCategoryService.getSpecialtyCategoriesNames().pipe(startWith([])),
      this.microAppService.getMicroAppCode().pipe(
        switchMap((product) => {
          if (product === PRODUCT_CODES.MATCH || product === PRODUCT_CODES.LINK) {
            return this.preferredProfessionService.getPreferredProfessionNames();
          } else if (product === PRODUCT_CODES.PASSPORT_PLUS) {
            return this.preferredPassportProfessionService.getPreferredProfessionNames();
          }

          return of<string[]>([]);
        }),
      ),
    ]).pipe(
      map(
        ([microAppCode, specialtyCategoriesNames, passportProfessionNames]: [
          string,
          string[],
          string[],
        ]): IHeaderFilterInfo => {
          let headerFilterInfo: IHeaderFilterInfo;

          switch (microAppCode) {
            case PRODUCT_CODES.MATCH:
            case PRODUCT_CODES.LINK:
              headerFilterInfo = {
                selectedFilters: specialtyCategoriesNames,
                emptyText: 'No departments selected',
                isClickable: true,
              };
              return headerFilterInfo;
            case PRODUCT_CODES.PASSPORT_PLUS:
              headerFilterInfo = {
                selectedFilters: passportProfessionNames,
                emptyText: 'No professions selected',
                isClickable: true,
              };
              return headerFilterInfo;
            default:
              headerFilterInfo = {
                selectedFilters: [],
                emptyText: '',
                isClickable: false,
              };
              return headerFilterInfo;
          }
        },
      ),
      startWith({
        selectedFilters: [],
        emptyText: '',
        isClickable: false,
      }),
    );
  }

  initializeChameleon() {
    return combineLatest([
      this.hospitalOfficerService.getAssigned(),
      this.hospitalOfficerService
        .getHospitalOfficerPersonalDetails()
        .pipe(filter((officer) => !!officer)),
      this.hospitalService.getAssigned(),
      this.siteService.getAllAfterLoading(),
      this.authGroupService.getAllMy(),
      this.professionService.getAllAfterLoading(),
      this.specialtyService.getAllAfterLoading(),
    ]).pipe(
      first(),
      tap(
        ([
          hospitalOfficer,
          hospitalOfficerPersonalDetails,
          hospital,
          sites,
          authGroups,
          professions,
          specialties,
        ]) => {
          const sitesNames = sites.map((site) => site.name);
          const authGroupsNames = authGroups.map((group) => group.name);
          const professionsNames = professions.map((profession) => profession.title);
          const specialtiesNames = specialties.map((specialty) => specialty.title);

          this.chameleonService.identify(
            {
              ...hospitalOfficer,
              firstName: hospitalOfficerPersonalDetails.firstName,
              lastName: hospitalOfficerPersonalDetails.lastName,
            },
            sitesNames.toString(),
            authGroupsNames.toString(),
            professionsNames.toString(),
            specialtiesNames.toString(),
            hospital.name,
          );
        },
      ),
    );
  }
}
