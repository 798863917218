import {
  ProfileNoteMessageTypes as MessageTypes,
  ProfileNoteMessages,
} from './profile-note.messages';
import { IProfileNoteEntityState } from './interfaces';
import { adapter, initialState, paginationAdapter } from './profile-note.adapter';
import { INoteComponentValidator } from '@locumsnest/core/src/lib/adapters/note-component-adapter';
import { required, maxLength } from 'ngrx-forms/validation';
import { IProfileNoteEntity } from '../../interfaces/api/profile-note-entity';
import { IPaginatedEntityState } from '@locumsnest/core/src/lib/interfaces/pagination';

export * from './interfaces';

export const NOTE_COMPONENT_VALIDATORS: INoteComponentValidator = {
  note: [required, maxLength(1000)],
};

export const paginationReducer = paginationAdapter.createReducer();
export type IProfileNotePaginatedEntityState = IProfileNoteEntityState &
  IPaginatedEntityState<IProfileNoteEntity>;
export function entityReducer(
  state = initialState,
  action: ProfileNoteMessages
): IProfileNoteEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.entity, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.entity, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.entities, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.entities, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }
    default: {
      return state;
    }
  }
}

export function paginatedEntityStateReducer(
  state: IProfileNotePaginatedEntityState,
  action: ProfileNoteMessages
) {
  let pagination;

  if (state) {
    pagination = state.pagination;
  }

  return {
    ...entityReducer(state, action),
    pagination: {
      ...paginationReducer(pagination, action),
    },
  };
}
export const { selectIds, selectEntities, selectAll, selectTotal } =
  paginationAdapter.getEntitySelectors();
