import {
  adapter,
  initialState,
  paginationAdapter,
} from './external-staffing-candidate-bid.adapter';
import {
  ExternalStaffingCandidateBidMessages,
  ExternalStaffingCandidateBidMessageTypes as MessageTypes,
} from './external-staffing-candidate-bid.messages';
import { IExternalStaffingCandidateBidEntityState } from './interfaces';

export * from './interfaces';

export const paginationReducer = paginationAdapter.createReducer();

export function entityReducer(
  state = initialState,
  action: ExternalStaffingCandidateBidMessages
): IExternalStaffingCandidateBidEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.entity, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.entity, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.entities, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.entities, state, action.payload.metaData);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }
    case MessageTypes.SET_SELECTED: {
      return {
        ...state,
        selectedExternalStaffingCandidateBidId: action.payload.id,
      };
    }
    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  paginationAdapter.getEntitySelectors();
