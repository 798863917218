import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get } from 'lodash-es';

import { IExternalStaffingProviderOfficerEntity } from '../../interfaces/api/external-staffing-provider-officer-entity';
import { paginationAdapter } from './external-staffing-provider-officer.adapter';
import { selectAll } from './external-staffing-provider-officer.reducer';
import {
  IExternalStaffingProviderOfficerEntityState,
  IExternalStaffingProviderOfficerFeatureState,
  IExternalStaffingProviderOfficerSharedState,
} from './interfaces';

export const getEntityState = (state: {
  entityState: IExternalStaffingProviderOfficerEntityState;
}) => state.entityState;
export const getAssignedId = (state: IExternalStaffingProviderOfficerEntityState) =>
  state.assignedExternalStaffingProviderOfficerId;
export const getAssignedEntity = (state: IExternalStaffingProviderOfficerEntityState) =>
  get(state.entities, getAssignedId(state), null);

export const getAssignedUser = (entity: IExternalStaffingProviderOfficerEntity) => entity.user;
/**
 * Create Feature Selectors based on the above selectors
 */
export const selectExternalStaffingProviderOfficerState = createFeatureSelector<
  IExternalStaffingProviderOfficerFeatureState & IExternalStaffingProviderOfficerSharedState
>('externalStaffingProviderOfficers');

export const selectExternalStaffingProviderOfficerEntityState = createSelector(
  selectExternalStaffingProviderOfficerState,
  getEntityState
);

export const selectAllExternalStaffingProviderOfficers = createSelector(
  selectExternalStaffingProviderOfficerEntityState,
  selectAll
);
export const selectAssignedExternalStaffingProviderOfficerId = createSelector(
  selectExternalStaffingProviderOfficerEntityState,
  getAssignedId
);
export const selectAssignedExternalStaffingProviderOfficer = createSelector(
  selectExternalStaffingProviderOfficerEntityState,
  getAssignedEntity
);
export const selectAssignedUserDetails = createSelector(
  selectAssignedExternalStaffingProviderOfficer,
  getAssignedUser
);

export const ExternalStaffingProviderOfficerPaginationSelectors =
  paginationAdapter.paginationSelectors(
    selectExternalStaffingProviderOfficerState,
    null,
    'pagination',
    'entityState',
    false
  );
