import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src/';
import { createEntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';

import { IProfileLabelEntity } from '../../interfaces/api/profile-label-entity';

const namespace = 'ProfileLabel';

const adapter = createEntityAdapter<IProfileLabelEntity>({ selectId: (entity) => entity.id });
const messageableFactory = MessageableFactory.forFeature<typeof namespace>(namespace);
const signalableFactory = SignalableFactory.forFeature<typeof namespace>(namespace);
const initialState = adapter.getInitialState({});
const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export { adapter, initialState, paginationAdapter };
