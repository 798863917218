import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { get, isNil } from 'lodash-es';
import { unbox } from 'ngrx-forms';
import {
  catchError,
  concat,
  debounce,
  EMPTY,
  filter,
  first,
  map,
  merge,
  mergeMap,
  of,
  takeUntil,
  timer,
} from 'rxjs';

import {
  IGlobalAlertHandler,
  IQueryParams,
  ISerializableFile,
  ofSignalType,
} from '@locumsnest/core/src';
import { GLOBAL_ALERT_HANDLER } from '@locumsnest/core/src/lib/adapters/alert-state-adapter/opaque-tokens';
import { Time } from '@locumsnest/core/src/lib/helpers';
import { BaseEffects } from '@locumsnest/core/src/lib/ngrx/effect';
import { silenceLastPageReachError } from '@locumsnest/core/src/lib/ngrx/operators';
import { selectParams } from '@locumsnest/core/src/lib/router/router-selectors';
import { HospitalProfessionConfigurationService } from '@locumsnest/hospital-profession-configuration/src';
import { ProfileAssignmentNumbersService } from '@locumsnest/profile-assignment-numbers/src';

import { ApplicationHistoryService } from '../../../application-history/+state/application-history.service';
import { ApplicationStatusService } from '../../../application-status/+state/application-status.service';
import { CertificateTypeService } from '../../../certificate-type/+state/certificate-type.service';
import { CertificateService } from '../../../certificate/+state/certificate.service';
import * as complianceMessageMessages from '../../../compliance-message/+state/compliance-message.messages';
import { ComplianceMessagePersistenceService } from '../../../compliance-message/+state/compliance-message.persistence.service';
import { ComplianceMessageService } from '../../../compliance-message/+state/compliance-message.service';
import { ExternalStaffingCandidateBidStatusService } from '../../../external-staffing-candidate-bid-status/+state/external-staffing-candidate-bid-status.service';
import { ExternalStaffingCandidateBidService } from '../../../external-staffing-candidate-bid/+state/external-staffing-candidate-bid.service';
import { ExternalStaffingCandidateDocumentService } from '../../../external-staffing-candidate-document/+state/external-staffing-candidate-document.service';
import { ExternalStaffingProviderService } from '../../../external-staffing-provider/+state/external-staffing-provider.service';
import { GmcStatusService } from '../../../gmc-status/+state/gmc-status.service';
import { GradeService } from '../../../grade/+state/grade.service';
import { HospitalStaffBankUnavailabilityReasonService } from '../../../hospital-staff-bank-unavailability-reason/+state/hospital-staff-bank-unavailability-reason.service';
import { HospitalStaffBankUnavailabilityService } from '../../../hospital-staff-bank-unavailability/+state/hospital-staff-bank-unavailability.service';
import { HospitalService } from '../../../hospital/+state/hospital.services';
import { ICertificateTypeEntity } from '../../../interfaces/api/certificate-type-entity';
import { IComplianceMessageEntity } from '../../../interfaces/api/compliance-message-entity';
import {
  IProfileNoteEntity,
  IProfileNoteParams,
} from '../../../interfaces/api/profile-note-entity';
import {
  ICloneCertificateToHospitalEntity,
  ISafeboxCertificateEntity,
  ISafeboxCertificatePostEntity,
} from '../../../interfaces/api/safebox-certificate-entity';
import {
  ICloneReferenceToHospitalEntity,
  ISafeboxReferenceEntity,
  ISafeboxReferencePostEntity,
} from '../../../interfaces/api/safebox-reference-entity';
import { JobPositionService } from '../../../job-position/+state/job-position.service';
import { ProfessionService } from '../../../profession/+state/profession.service';
import * as profileNoteMessages from '../../../profile-note/+state/profile-note.messages';
import { ProfileNotePersistenceService } from '../../../profile-note/+state/profile-note.persistence.service';
import { ProfileNoteService } from '../../../profile-note/+state/profile-note.service';
import { ProfileStatisticsService } from '../../../profile-statistics/+state/profile-statistics.service';
import { ReferenceService } from '../../../reference/+state/reference.service';
import { RevalidationStatusService } from '../../../revalidation-status/+state/revalidation-status.service';
import * as safeboxCertificateMessages from '../../../safebox-certificate/+state/safebox-certificate.messages';
import { SafeboxCertificatePersistenceService } from '../../../safebox-certificate/+state/safebox-certificate.persistence.service';
import { SafeboxCertificateService } from '../../../safebox-certificate/+state/safebox-certificate.service';
import * as safeboxReferenceMessages from '../../../safebox-reference/+state/safebox-reference.messages';
import { SafeboxReferencePersistenceService } from '../../../safebox-reference/+state/safebox-reference.persistence.service';
import { SafeboxReferenceService } from '../../../safebox-reference/+state/safebox-reference.service';
import { StaffBankCollaborationGroupService } from '../../../staff-bank-collaboration-group/+state/staff-bank-collaboration-group.service';
import { ClearProfileNoteMessage } from '../../../staff-bank-membership-request/+state/staff-bank-membership-requests-search-filter-form/staff-bank-membership-requests-search-filter-form.messages';
import { StaffBankMembershipService } from '../../../staff-bank-membership/+state/staff-bank-membership.service';
import { StaffBankUnavailabilityReasonService } from '../../../staff-bank-unavailability-reason/+state/staff-bank-unavailability-reason.service';
import { SelectProfileMessage } from '../profile.messages';
import { ProfileService } from '../profile.service';
import {
  EmploymentHistoryFilterTypes,
  ProfileAssignmentNumbersFilterTypes,
  ProfileUiTabs,
} from './../interfaces/profile-ui-state';
import { alertSignalToMessage } from './ui.adapter';
import {
  AddCopiedCertificateMessage,
  AddCopiedReferenceMessage,
  DisableSubmitButtonMessage,
  EnableSubmitButtonMessage,
  InitializeCloneDocumentToHospitalMessage,
  InitializeComplianceMessageMessage,
  InitializeNoteAreaMessage,
  InitializeProfileUiMessage,
  RemoveFileMessage,
  SelectTabMessage,
  SetEmploymentHistoryFilterMessage,
  SetOtherCertificateTypeMessage,
  SetProfileAssignmentNumbersFilterMessage,
  UpdateShowFlaggedProfileNotesForProfileMessage,
  UpdateShowFlaggedProfileNotesMessage,
} from './ui.messages';
import {
  selectComplianceMessageFormState,
  selectShowFlaggedNotesForProfile,
  selectShowFlaggedNotesUiState,
  selectUploadFileFormState,
} from './ui.selectors';
import {
  CloneCertificateToHospitalSignal,
  CloneReferenceToHospitalSignal,
  CopyCertificateToSafeboxSignal,
  CopyReferenceToSafeboxSignal,
  DeleteProfileNoteForSelectedProfileSignal,
  DeleteProfileNoteSignal,
  DeleteSafeboxCertificateSignal,
  DeleteSafeboxReferenceSignal,
  InitializeCloneDocumentToHospitalSignal,
  InitializeProfileUiSignal,
  LoadMoreComplianceMessagesUiSignal,
  LoadMoreEmploymentHistoryUiSignal,
  LoadMoreProfileNotesUiSignal,
  MarkProfileNoteAsFlaggedSignal,
  MarkProfileNoteAsUnFlaggedSignal,
  RemoveFileSignal,
  SelectTabSignal,
  SendComplianceMessageUiSignal,
  SetEmploymentHistoryFilterUiSignal,
  SetProfileAssignmentNumbersFilterUiSignal,
  SubmitNewProfileNoteForSelectedProfileSignal,
  SubmitNewProfileNoteSignal,
  ToggleFlaggedProfileNotesForProfileSignal,
  ToggleFlaggedProfileNotesSignal,
  UpdateCertificateExpirationDateSignal,
  UpdateReferenceExpirationDateSignal,
  UploadFileSignal,
} from './ui.signals';

@Injectable()
export class ProfileUiEffects extends BaseEffects {
  initializeProfileUiSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(InitializeProfileUiSignal),
      mergeMap((action) => {
        const { namespace } = action.payload;
        return this.store.pipe(
          select(selectParams),
          filter((params) => !!get(params, 'id')),
          map((params) => [params['id'], get(params, 'tab', null)]),
          mergeMap(([id, tab]) => {
            const profileNotesParams: IProfileNoteParams = {
              profile: [id],
            };

            return merge(
              of(new InitializeProfileUiMessage({})),
              this.externalStaffingProviderService.load(),
              this.externalStaffingCandidateBidStatusService.load(),
              this.profileService.loadOne(id, false),
              this.referenceService.loadAllPages(
                namespace,
                id,
                this.referenceService.getRequestOptions(id),
              ),
              this.externalStaffingCandidateDocumentService.loadAllPages(
                'default',
                {},
                { profile: id },
              ),
              this.certificateService.loadAllPages(
                namespace,
                id,
                this.certificateService.getRequestOptions(id),
              ),
              this.safeboxCertificateService.loadAllPages(
                namespace,
                id,
                this.safeboxCertificateService.getRequestOptions(id),
              ),
              this.safeboxReferenceService.loadAllPages(
                namespace,
                id,
                this.safeboxReferenceService.getRequestOptions(id),
              ),
              this.staffBankMembershipService.loadByProfileId(id, true),
              this.hospitalService.loadActiveHospitals(),
              this.jobPositionService.loadAllPagesAndLoadEmployers(
                namespace,
                id,
                this.jobPositionService.getRequestOptions(id),
              ),
              this.applicationHistoryService.initializePaginationAndLoadDependencies(
                namespace,
                id,
                this.applicationHistoryService.getRequestOptions(id),
              ),
              this.profileNoteService.initializePaginationAndLoadDependencies(
                namespace,
                profileNotesParams,
              ),
              this.externalStaffingCandidateBidService.initializePaginationForProfile(
                namespace,
                id,
              ),
              this.complianceMessageService.initializePaginationAndLoadDependencies(
                namespace,
                id,
                this.complianceMessageService.getRequestOptions(id),
              ),
              this.profileStatisticsService.loadProfileStatisticsByProfileId(id),
              this.professionService.loadAllPages(namespace, {}),
              this.applicationStatusService.load(),
              this.revalidationStatusService.load(),
              this.hospitalProfessionConfigurationService.load(),
              of(new SelectProfileMessage({ id })),
              of(new SelectTabSignal({ tab })),
              of(new EnableSubmitButtonMessage()),
              this.certificateTypeService.load(),
              this.staffBankCollaborationGroupService.load(),
              this.gmcStatusService.load(),
              this.certificateTypeService
                .getCertificateTypeBySlug('other')
                .pipe(
                  map(
                    (certificateType: ICertificateTypeEntity) =>
                      new SetOtherCertificateTypeMessage({ certificateType }),
                  ),
                ),
              this.staffBankUnavailabilityReasonService.load(),
              this.hospStaffBankUnavailabilityReasonService.load(),
              this.hospitalStaffBankUnavailabilityService.loadByProfileId(id),
              this.profileAssignmentNumbersService.loadByProfileIds([id], null),
              this.gradeService.load(),
            );
          }),
          takeUntil(this.getDestroyAction(InitializeProfileUiSignal.TYPE)),
        );
      }),
    ),
  );

  sendComplianceMessageUiSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SendComplianceMessageUiSignal.TYPE),
      debounce(() => timer(1000)),
      concatLatestFrom(() => [
        this.store.pipe(select(selectComplianceMessageFormState)),
        this.profileService.getSelectedProfile(),
      ]),
      mergeMap(([_, complianceMessageFormState, selectedProfile]) => {
        const profileId = selectedProfile.id;

        const complianceMessage: IComplianceMessageEntity = {
          subject: complianceMessageFormState.controls.subject.value,
          messageText: complianceMessageFormState.controls.message.value,
        };

        // eslint-disable-next-line @ngrx/no-dispatch-in-effects
        this.store.dispatch(new DisableSubmitButtonMessage());

        return this.complianceMessagePersistenceService
          .create<IComplianceMessageEntity, IComplianceMessageEntity>(complianceMessage, {
            pathParams: { profileId },
            skipSerializer: true,
          })
          .pipe(
            mergeMap((response: IComplianceMessageEntity) =>
              merge(
                this.alertHandlerService.handleAlert(
                  {
                    message: 'Compliance message sent successfully!',
                    type: 'success',
                  },
                  this.actions$,
                ),
                of(
                  new complianceMessageMessages.AddOneMessage({
                    entity: response,
                  }),
                ),
                of(new InitializeComplianceMessageMessage()),
              ),
            ),
            catchError(
              this.alertHandlerService.handleError({
                errorEventMessageHandler: (message) =>
                  `An error occurred while sending the compliance message: ${message}`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! Compliance message can’t be sent. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Compliance message can’t be sent. Please try again in a few minutes',
              }),
            ),
          );
      }),
    ),
  );

  selectTabSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SelectTabSignal.TYPE),
      map((action) => {
        const tab = (action as SelectTabSignal).payload.tab as ProfileUiTabs;

        if (Object.values(ProfileUiTabs).includes(tab)) {
          return new SelectTabMessage({ tab });
        }
      }),
      filter((x) => !!x),
    ),
  );

  loadMoreEmploymentHistoryUiSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(LoadMoreEmploymentHistoryUiSignal),
      concatLatestFrom(() => [
        this.profileService.getSelectedProfile(),
        this.profileService.getEmploymentHistoryFilterUiState(),
      ]),
      mergeMap(([action, profile, employmentHistoryFilter]) => {
        const { namespace } = action.payload;
        if (profile.id) {
          switch (employmentHistoryFilter) {
            case EmploymentHistoryFilterTypes.application:
              return this.applicationHistoryService.loadNextAndLoadDependencies(
                namespace,
                profile.id,
                this.applicationHistoryService.getRequestOptions(profile.id),
              );

            case EmploymentHistoryFilterTypes.bids:
              return this.externalStaffingCandidateBidService.loadNextForProfile(
                namespace,
                profile.id,
              );

            default:
              return EMPTY;
          }
        }
        return EMPTY;
      }),
      silenceLastPageReachError(),
    ),
  );

  setEmploymentHistoryFilterUiSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(SetEmploymentHistoryFilterUiSignal),
      map(({ payload }) => new SetEmploymentHistoryFilterMessage(payload)),
    ),
  );

  setProfileAssignmentNumbersFilterUiSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetProfileAssignmentNumbersFilterUiSignal.TYPE),
      concatLatestFrom(() => [this.profileService.getSelectedProfile()]),
      mergeMap(([action, selectedProfile]) => {
        const payload = (action as SetProfileAssignmentNumbersFilterUiSignal).payload;
        let activeFilter = null;

        if (payload.filter === ProfileAssignmentNumbersFilterTypes.active) {
          activeFilter = true;
        } else if (payload.filter === ProfileAssignmentNumbersFilterTypes.inactive) {
          activeFilter = false;
        }

        return merge(
          of(new SetProfileAssignmentNumbersFilterMessage(payload)),
          this.profileAssignmentNumbersService.loadByProfileIds([selectedProfile.id], activeFilter),
        );
      }),
    ),
  );

  removeFileSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveFileSignal>(RemoveFileSignal.TYPE),
      map(() => new RemoveFileMessage()),
    ),
  );

  uploadFileSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UploadFileSignal>(UploadFileSignal.TYPE),
      concatLatestFrom(() => [
        this.store.pipe(select(selectUploadFileFormState)),
        this.profileService.getSelectedProfile(),
        this.store
          .pipe(select(selectUploadFileFormState))
          .pipe(
            mergeMap((uploadFileForm) =>
              this.certificateTypeService.getCertificateTypeName(
                uploadFileForm.controls.type.value,
              ),
            ),
          ),
      ]),
      mergeMap(([_, fileFormState, selectedProfile, certificateTypeName]) => {
        const profileId = selectedProfile.id;
        const file: ISerializableFile = JSON.parse(fileFormState.controls.file.value);

        const safeboxCertificate: ISafeboxCertificatePostEntity = {
          file: {
            file: file.base64EncodedFile,
            title: file.name,
          },
          title:
            fileFormState.controls.title.value === ''
              ? certificateTypeName
              : fileFormState.controls.title.value,
          details: '',
          certificateDate: null,
          expirationDate:
            fileFormState.controls.expirationDate.value === null
              ? null
              : fileFormState.controls.expirationDate.value.toString(),
          type: fileFormState.controls.type.value,
          profile: profileId,
        };

        return this.safeboxCertificatePersistenceService
          .create<ISafeboxCertificatePostEntity, ISafeboxCertificateEntity>(safeboxCertificate, {
            pathParams: { profileId },
            skipSerializer: true,
          })
          .pipe(
            mergeMap((response: ISafeboxCertificateEntity) =>
              merge(
                this.alertHandlerService.handleAlert(
                  { message: 'File uploaded successfully!', type: 'success' },
                  this.actions$,
                ),
                of(
                  new safeboxCertificateMessages.AddOneMessage({
                    entity: response,
                  }),
                ),
                of(new RemoveFileMessage()),
              ),
            ),
            catchError(
              this.alertHandlerService.handleError({
                errorEventMessageHandler: (message) =>
                  `An error occurred while uploading the file: ${message}`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! File can’t be uploaded. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! File can’t be uploaded. Please try again in a few minutes',
              }),
            ),
          );
      }),
    ),
  );

  updateCertificateExpirationDateSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateCertificateExpirationDateSignal>(UpdateCertificateExpirationDateSignal.TYPE),
      mergeMap((action) => {
        const { payload } = action.payload;
        const originalDocument = payload.document.original as ISafeboxCertificateEntity;

        let certificateDate = null;
        if (originalDocument.certificateDate) {
          certificateDate = Time.formatDate(originalDocument.certificateDate);
        }

        const safeboxCertificate: ISafeboxCertificatePostEntity = {
          id: payload.document.id,
          file: { ...originalDocument.file },
          title: originalDocument.title,
          certificateDate,
          details: originalDocument.details,
          expirationDate: isNil(payload.expirationDate)
            ? null
            : Time.formatDate(payload.expirationDate),
          type: originalDocument.type,
          profile: originalDocument.profile,
        };

        const profileId = safeboxCertificate.profile;

        return this.safeboxCertificatePersistenceService
          .update<ISafeboxCertificatePostEntity, ISafeboxCertificateEntity>(
            payload.document.id,
            safeboxCertificate,
            undefined,
            {
              pathParams: { profileId },
            },
          )
          .pipe(
            mergeMap((response: ISafeboxCertificateEntity) =>
              merge(
                this.alertHandlerService.handleAlert(
                  {
                    message: 'Expiration date updated successfully!',
                    type: 'success',
                  },
                  this.actions$,
                ),
                of(
                  new safeboxCertificateMessages.UpdateOneMessage({
                    entity: {
                      id: response.id,
                      changes: response,
                    },
                  }),
                ),
              ),
            ),
            catchError(
              this.alertHandlerService.handleError({
                errorEventMessageHandler: (message) =>
                  `An error occurred while updating the expiration date: ${message}`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! Expiration date can't be updated. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Expiration date can’t be updated. Please try again in a few minutes',
              }),
            ),
          );
      }),
    ),
  );

  updateReferenceExpirationDateSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateReferenceExpirationDateSignal>(UpdateReferenceExpirationDateSignal.TYPE),
      mergeMap((action) => {
        const { payload } = action.payload;
        const originalDocument = payload.document.original as ISafeboxReferenceEntity;

        const safeboxReference: ISafeboxReferencePostEntity = {
          id: payload.document.id,
          lastUpdate: Time.getDate(),
          refereeName: originalDocument.refereeName,
          refereeEmail: originalDocument.refereeEmail,
          screenshotProof: originalDocument.screenshotProof,
          referenceText: originalDocument.referenceText,
          expirationDate: Time.formatDate(payload.expirationDate),
          profile: originalDocument.profile,
        };

        const profileId = safeboxReference.profile;

        return this.safeboxReferencePersistenceService
          .update<ISafeboxReferencePostEntity, ISafeboxReferenceEntity>(
            payload.document.id,
            { ...safeboxReference },
            undefined,
            {
              pathParams: { profileId },
            },
          )
          .pipe(
            mergeMap((response: ISafeboxReferenceEntity) =>
              merge(
                this.alertHandlerService.handleAlert(
                  {
                    message: 'Expiration date updated successfully!',
                    type: 'success',
                  },
                  this.actions$,
                ),
                of(
                  new safeboxReferenceMessages.UpdateOneMessage({
                    entity: {
                      id: response.id,
                      changes: response,
                    },
                  }),
                ),
              ),
            ),
            catchError(
              this.alertHandlerService.handleError({
                errorEventMessageHandler: (message) =>
                  `An error occurred while updating the expiration date: ${message}`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! Expiration date can't be updated. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Expiration date can’t be updated. Please try again in a few minutes',
              }),
            ),
          );
      }),
    ),
  );

  deleteSafeboxCertificateSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteSafeboxCertificateSignal>(DeleteSafeboxCertificateSignal.TYPE),
      concatLatestFrom(() => this.profileService.getSelectedProfile()),
      mergeMap(([action, selectedProfile]) => {
        const profileId = selectedProfile.id;
        return this.safeboxCertificatePersistenceService
          .delete(action.payload.document.id, undefined, {
            pathParams: { profileId },
          })
          .pipe(
            mergeMap(() =>
              merge(
                this.alertHandlerService.handleAlert(
                  {
                    message: 'Safebox certificate deleted successfully!',
                    type: 'success',
                  },
                  this.actions$,
                ),
                of(
                  new safeboxCertificateMessages.DeleteOneMessage({
                    id: action.payload.document.id,
                  }),
                ),
                of(
                  new AddCopiedReferenceMessage({
                    id: action.payload.document.id,
                  }),
                ),
              ),
            ),
            catchError(
              this.alertHandlerService.handleError({
                errorEventMessageHandler: (message) =>
                  `An error occurred while deleting the safebox certificate: ${message}`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! Safebox certificate can't be deleted. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Safebox certificate can’t be deleted. Please try again in a few minutes',
              }),
            ),
          );
      }),
    ),
  );

  deleteSafeboxReferenceSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteSafeboxReferenceSignal>(DeleteSafeboxReferenceSignal.TYPE),
      concatLatestFrom(() => this.profileService.getSelectedProfile()),
      mergeMap(([action, selectedProfile]) => {
        const id = selectedProfile.id;
        return this.safeboxReferencePersistenceService
          .delete(action.payload.document.id, undefined, {
            pathParams: { profileId: id },
          })
          .pipe(
            mergeMap(() =>
              merge(
                this.alertHandlerService.handleAlert(
                  {
                    message: 'Safebox reference deleted successfully!',
                    type: 'success',
                  },
                  this.actions$,
                ),
                of(
                  new safeboxReferenceMessages.DeleteOneMessage({
                    id: action.payload.document.id,
                  }),
                ),
                of(
                  new AddCopiedReferenceMessage({
                    id: action.payload.document.id,
                  }),
                ),
              ),
            ),
            catchError(
              this.alertHandlerService.handleError({
                errorEventMessageHandler: (message) =>
                  `An error occurred while deleting the safebox reference: ${message}`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! Safebox reference can't be deleted. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Safebox reference can’t be deleted. Please try again in a few minutes',
              }),
            ),
          );
      }),
    ),
  );

  copyCertificateToSafeboxSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CopyCertificateToSafeboxSignal>(CopyCertificateToSafeboxSignal.TYPE),
      concatLatestFrom(() => this.profileService.getSelectedProfile()),
      mergeMap(([action, selectedProfile]) => {
        const profileId = selectedProfile.id;
        return this.safeboxCertificateService
          .copyFromCertificate(action.payload.document.id, profileId)
          .pipe(
            mergeMap((response: ISafeboxCertificateEntity) =>
              merge(
                this.alertHandlerService.handleAlert(
                  {
                    message: 'Certificate copied successfully to digital safebox!',
                    type: 'success',
                  },
                  this.actions$,
                ),
                of(
                  new safeboxCertificateMessages.AddOneMessage({
                    entity: response,
                  }),
                ),
                of(
                  new AddCopiedCertificateMessage({
                    id: action.payload.document.id,
                  }),
                ),
              ),
            ),
            catchError(
              this.alertHandlerService.handleError({
                errorEventMessageHandler: (message) =>
                  `An error occurred while copying the certificate to digital safebox: ${message}`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! Certificate can’t be copied to digital safebox. '
                  +'The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Certificate can’t be copied to digital safebox. ' +
                  'Please try again in a few minutes',
              }),
            ),
          );
      }),
    ),
  );

  copyReferenceToSafeboxSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CopyReferenceToSafeboxSignal>(CopyReferenceToSafeboxSignal.TYPE),
      concatLatestFrom(() => this.profileService.getSelectedProfile()),
      mergeMap(([action, selectedProfile]) => {
        const profileId = selectedProfile.id;
        return this.safeboxReferenceService
          .copyFromReference(action.payload.document.id, profileId)
          .pipe(
            mergeMap((response: ISafeboxReferenceEntity) =>
              merge(
                this.alertHandlerService.handleAlert(
                  {
                    message: 'Reference copied successfully to digital safebox!',
                    type: 'success',
                  },
                  this.actions$,
                ),
                of(
                  new safeboxReferenceMessages.AddOneMessage({
                    entity: response,
                  }),
                ),
                of(
                  new AddCopiedReferenceMessage({
                    id: action.payload.document.id,
                  }),
                ),
              ),
            ),
            catchError(
              this.alertHandlerService.handleError({
                errorEventMessageHandler: (message) =>
                  `An error occurred while copying the reference to digital safebox: ${message}`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! Reference can’t be copied to digital safebox. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Reference can’t be copied to digital safebox. ' +
                  'Please try again in a few minutes',
              }),
            ),
          );
      }),
    ),
  );

  initializeCloneDocumentToHospitalSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<InitializeCloneDocumentToHospitalSignal>(InitializeCloneDocumentToHospitalSignal.TYPE),
      map(() => new InitializeCloneDocumentToHospitalMessage()),
    ),
  );

  cloneReferenceToHospitalSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CloneReferenceToHospitalSignal>(CloneReferenceToHospitalSignal.TYPE),
      concatLatestFrom(() => [
        this.profileService.getSelectedProfile(),
        this.profileService.getCloneDocumentToHospitalFormState(),
      ]),
      mergeMap(([_, selectedProfile, formState]) => {
        const profileId = selectedProfile.id;
        const data: ICloneReferenceToHospitalEntity = {
          safeboxReferences: unbox(formState.value.documents),
          hospitals: unbox(formState.value.hospitals),
        };
        return this.safeboxReferenceService.cloneReferenceToHospital(data, profileId).pipe(
          mergeMap(() =>
            merge(
              this.alertHandlerService.handleAlert(
                {
                  message: 'References shared successfully to hospital!',
                  type: 'success',
                },
                this.actions$,
              ),
              of(new InitializeCloneDocumentToHospitalMessage()),
            ),
          ),
          catchError(
            this.alertHandlerService.handleError({
              errorEventMessageHandler: (message) =>
                `An error occurred while sharing the references to hospital: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Reference can’t be shared to hospital. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Reference can’t be shared to hospital. ' +
                'Please try again in a few minutes',
            }),
          ),
        );
      }),
    ),
  );

  cloneCertificateToHospitalSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CloneCertificateToHospitalSignal>(CloneCertificateToHospitalSignal.TYPE),
      concatLatestFrom(() => [
        this.profileService.getSelectedProfile(),
        this.profileService.getCloneDocumentToHospitalFormState(),
      ]),
      mergeMap(([_, selectedProfile, formState]) => {
        const profileId = selectedProfile.id;
        const data: ICloneCertificateToHospitalEntity = {
          safeboxCertificates: unbox(formState.value.documents),
          hospitals: unbox(formState.value.hospitals),
        };
        return this.safeboxCertificateService.cloneCertificateToHospital(data, profileId).pipe(
          mergeMap(() =>
            merge(
              this.alertHandlerService.handleAlert(
                {
                  message: 'Certificates shared successfully to hospital!',
                  type: 'success',
                },
                this.actions$,
              ),
              of(new InitializeCloneDocumentToHospitalMessage()),
            ),
          ),
          catchError(
            this.alertHandlerService.handleError({
              errorEventMessageHandler: (message) =>
                `An error occurred while sharing the references to hospital: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Certificate can’t be shared to hospital. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Certificate can’t be shared to hospital. ' +
                'Please try again in a few minutes',
            }),
          ),
        );
      }),
    ),
  );

  deleteProfileNoteForSelectedProfileSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteProfileNoteForSelectedProfileSignal>(
        DeleteProfileNoteForSelectedProfileSignal.TYPE,
      ),
      concatLatestFrom(() => this.profileService.getSelectedProfile()),
      mergeMap(([action, selectedProfile]) => {
        const namespace = (action as DeleteProfileNoteForSelectedProfileSignal).payload.namespace;

        const profileId = selectedProfile.id;

        const profileNotesParams: IQueryParams = {
          profile: [profileId],
        };

        return this.profileNotePersistenceService.delete(action.payload.note.id).pipe(
          mergeMap(() =>
            merge(
              concat(
                this.profileNoteService.refreshCurrentPage(namespace, {}, profileNotesParams),
                of(
                  new profileNoteMessages.DeleteOneMessage({
                    id: action.payload.note.id,
                  }),
                ),
                this.alertHandlerService.handleAlert(
                  { message: 'Note deleted successfully!!', type: 'success' },
                  this.actions$,
                ),
              ),
            ),
          ),
          catchError(
            this.alertHandlerService.handleError({
              errorEventMessageHandler: (message) =>
                `An error occurred while deleting the note: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Note can't be deleted. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Note can’t be deleted. Please try again in a few minutes',
            }),
          ),
        );
      }),
    ),
  );

  deleteProfileNoteSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteProfileNoteSignal>(DeleteProfileNoteSignal.TYPE),
      mergeMap((action) =>
        this.profileNotePersistenceService.delete(action.payload.noteId).pipe(
          mergeMap(() =>
            merge(
              of(
                new profileNoteMessages.DeleteOneMessage({
                  id: action.payload.noteId,
                }),
              ),
              this.alertHandlerService.handleAlert(
                { message: 'Note deleted successfully!!', type: 'success' },
                this.actions$,
              ),
            ),
          ),
          catchError(
            this.alertHandlerService.handleError({
              errorEventMessageHandler: (message) =>
                `An error occurred while deleting the note: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Note can't be deleted. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Note can’t be deleted. Please try again in a few minutes',
            }),
          ),
        ),
      ),
    ),
  );

  submitNewProfileNoteForSelectedProfileSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SubmitNewProfileNoteForSelectedProfileSignal>(
        SubmitNewProfileNoteForSelectedProfileSignal.TYPE,
      ),
      concatLatestFrom(() => this.profileService.getSelectedProfile()),
      mergeMap(([action, selectedProfile]) => {
        const namespace = (action as SubmitNewProfileNoteForSelectedProfileSignal).payload
          .namespace;

        const profileId = selectedProfile.id;

        const profileNote: IProfileNoteEntity = {
          profile: profileId,
          noteText: action.payload.note,
        };

        const profileNotesParams: IProfileNoteParams = {
          profile: [profileId],
        };

        return this.profileNotePersistenceService.create(profileNote).pipe(
          mergeMap(() =>
            merge(
              this.alertHandlerService.handleAlert(
                { message: 'Note submitted successfully!', type: 'success' },
                this.actions$,
              ),
              this.profileNoteService.initializePaginationAndLoadDependencies(
                namespace,
                profileNotesParams,
              ),
              of(
                new UpdateShowFlaggedProfileNotesMessage({
                  showFlaggedNotes: false,
                }),
              ),
              of(new InitializeNoteAreaMessage()),
            ),
          ),
          catchError(
            this.alertHandlerService.handleError({
              errorEventMessageHandler: (message) =>
                `An error occurred while submitting the note: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Note can’t be submitted. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Note can’t be submitted. Please try again in a few minutes',
            }),
          ),
        );
      }),
    ),
  );

  submitNewProfileNoteSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SubmitNewProfileNoteSignal>(SubmitNewProfileNoteSignal.TYPE),
      mergeMap((action) => {
        const profileId = action.payload.profileId;

        const profileNote: IProfileNoteEntity = {
          profile: profileId,
          noteText: action.payload.note,
        };

        return this.profileNotePersistenceService.create(profileNote).pipe(
          mergeMap((response: IProfileNoteEntity) =>
            merge(
              this.alertHandlerService.handleAlert(
                { message: 'Note submitted successfully!', type: 'success' },
                this.actions$,
              ),
              of(
                new profileNoteMessages.AddOneMessage({
                  entity: response,
                }),
              ),
              of(
                new ClearProfileNoteMessage({
                  id: +action.payload.staffBankMembershipRequestId,
                }),
              ),
            ),
          ),
          catchError(
            this.alertHandlerService.handleError({
              errorEventMessageHandler: (message) =>
                `An error occurred while submitting the note: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Note can’t be submitted. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Note can’t be submitted. Please try again in a few minutes',
            }),
          ),
        );
      }),
    ),
  );

  loadMoreProfileNotesUiSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadMoreProfileNotesUiSignal.TYPE),
      concatLatestFrom(() => this.store.pipe(select(selectShowFlaggedNotesUiState))),
      mergeMap(([action, showFlaggedNotes]) => {
        const namespace = (action as LoadMoreProfileNotesUiSignal).payload.namespace;

        return this.store.pipe(
          select(selectParams),
          filter((params) => !!params),
          map((params) => +params['id']),
          mergeMap((id) => {
            const profileNotesParams: IProfileNoteParams = {
              profile: [id.toString()],
              flagged: !showFlaggedNotes,
            };

            return this.profileNoteService
              .loadNextAndLoadDependencies(namespace, profileNotesParams)
              .pipe(silenceLastPageReachError());
          }),
        );
      }),
    ),
  );

  loadMoreComplianceMessagesUiSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadMoreComplianceMessagesUiSignal.TYPE),
      mergeMap((action: LoadMoreComplianceMessagesUiSignal) => {
        const { namespace } = action.payload;

        return this.store.pipe(
          select(selectParams),
          filter((params) => !!params),
          map((params) => params['id']),
          mergeMap((id) =>
            this.complianceMessageService
              .loadNextAndLoadDependencies(
                namespace,
                id,
                this.complianceMessageService.getRequestOptions(id),
              )
              .pipe(silenceLastPageReachError()),
          ),
        );
      }),
    ),
  );

  markProfileNoteAsFlaggedSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkProfileNoteAsFlaggedSignal>(MarkProfileNoteAsFlaggedSignal.TYPE),
      mergeMap((action) => {
        const profileNoteId = action.payload.profileNoteId;

        return this.profileNotePersistenceService.markProfileNoteAsFlagged(profileNoteId).pipe(
          mergeMap((response: IProfileNoteEntity) =>
            merge(
              this.alertHandlerService.handleAlert(
                { message: 'Note flagged successfully!', type: 'success' },
                this.actions$,
              ),
              of(
                new profileNoteMessages.UpdateOneMessage({
                  entity: {
                    id: response.id,
                    changes: response,
                  },
                }),
              ),
            ),
          ),
          catchError(
            this.alertHandlerService.handleError({
              errorEventMessageHandler: (message) =>
                `An error occurred while flagging the note: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Note can’t be flagged. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Note can’t be flagged. Please try again in a few minutes',
            }),
          ),
        );
      }),
    ),
  );

  markProfileNoteAsUnFlaggedSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkProfileNoteAsUnFlaggedSignal>(MarkProfileNoteAsUnFlaggedSignal.TYPE),
      mergeMap((action) => {
        const profileNoteId = action.payload.profileNoteId;

        return this.profileNotePersistenceService.markProfileNoteAsUnFlagged(profileNoteId).pipe(
          mergeMap((response: IProfileNoteEntity) =>
            merge(
              this.alertHandlerService.handleAlert(
                { message: 'Note unflagged successfully!', type: 'success' },
                this.actions$,
              ),
              of(
                new profileNoteMessages.UpdateOneMessage({
                  entity: {
                    id: response.id,
                    changes: response,
                  },
                }),
              ),
            ),
          ),
          catchError(
            this.alertHandlerService.handleError({
              errorEventMessageHandler: (message) =>
                `An error occurred while unflagging the note: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Note can’t be unflagged. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Note can’t be unflagged. Please try again in a few minutes',
            }),
          ),
        );
      }),
    ),
  );

  toggleFlaggedProfileNotesSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToggleFlaggedProfileNotesSignal.TYPE),
      concatLatestFrom(() => [
        this.store.pipe(select(selectShowFlaggedNotesUiState)),
        this.profileService.getSelectedProfile(),
      ]),
      mergeMap(([action, showFlaggedNotes, selectedProfile]) => {
        const payload = (action as ToggleFlaggedProfileNotesSignal).payload;

        let profileNotesParams: IProfileNoteParams = {
          profile: [selectedProfile.id],
        };

        if (!showFlaggedNotes) {
          profileNotesParams = {
            ...profileNotesParams,
            flagged: !showFlaggedNotes,
          };
        }

        return merge(
          this.profileNoteService.initializePaginationAndLoadDependencies(
            payload.namespace,
            profileNotesParams,
          ),
          of(new UpdateShowFlaggedProfileNotesMessage({ showFlaggedNotes: !showFlaggedNotes })),
        );
      }),
    ),
  );

  toggleFlaggedProfileNotesForProfileSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToggleFlaggedProfileNotesForProfileSignal.TYPE),
      mergeMap((action) => {
        const payload = (action as ToggleFlaggedProfileNotesForProfileSignal).payload;

        return this.store.pipe(
          select(selectShowFlaggedNotesForProfile(payload.profileId)),
          first(),
          map(
            (showFlaggedNotes: boolean) =>
              new UpdateShowFlaggedProfileNotesForProfileMessage({
                profileId: payload.profileId,
                showFlaggedNotes: !showFlaggedNotes,
              }),
          ),
        );
      }),
    ),
  );

  alertSignal$ = createEffect(() => this.actions$.pipe(alertSignalToMessage));

  constructor(
    protected actions$: Actions,
    private store: Store,
    private profileService: ProfileService,
    private staffBankMembershipService: StaffBankMembershipService,
    private certificateTypeService: CertificateTypeService,
    private complianceMessageService: ComplianceMessageService,
    private complianceMessagePersistenceService: ComplianceMessagePersistenceService,
    private gmcStatusService: GmcStatusService,
    private certificateService: CertificateService,
    private referenceService: ReferenceService,
    private staffBankCollaborationGroupService: StaffBankCollaborationGroupService,
    private applicationHistoryService: ApplicationHistoryService,
    private jobPositionService: JobPositionService,
    private applicationStatusService: ApplicationStatusService,
    private safeboxCertificateService: SafeboxCertificateService,
    private safeboxCertificatePersistenceService: SafeboxCertificatePersistenceService,
    private safeboxReferenceService: SafeboxReferenceService,
    private safeboxReferencePersistenceService: SafeboxReferencePersistenceService,
    private profileNoteService: ProfileNoteService,
    private profileNotePersistenceService: ProfileNotePersistenceService,
    private profileStatisticsService: ProfileStatisticsService,
    private revalidationStatusService: RevalidationStatusService,
    private professionService: ProfessionService,
    private staffBankUnavailabilityReasonService: StaffBankUnavailabilityReasonService,
    private hospStaffBankUnavailabilityReasonService: HospitalStaffBankUnavailabilityReasonService,
    private hospitalStaffBankUnavailabilityService: HospitalStaffBankUnavailabilityService,
    private externalStaffingCandidateDocumentService: ExternalStaffingCandidateDocumentService,
    private externalStaffingProviderService: ExternalStaffingProviderService,
    private externalStaffingCandidateBidStatusService: ExternalStaffingCandidateBidStatusService,
    private profileAssignmentNumbersService: ProfileAssignmentNumbersService,
    private hospitalProfessionConfigurationService: HospitalProfessionConfigurationService,
    private gradeService: GradeService,
    private hospitalService: HospitalService,
    private externalStaffingCandidateBidService: ExternalStaffingCandidateBidService,
    @Inject(GLOBAL_ALERT_HANDLER)
    private alertHandlerService: IGlobalAlertHandler,
  ) {
    super();
  }
}
