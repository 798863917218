/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import { Action, select } from '@ngrx/store';
import { get, isNil, isNumber, isString, uniq, uniqBy } from 'lodash-es';
import {
  combineLatest,
  concat,
  distinctUntilChanged,
  EMPTY,
  filter,
  first,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  switchMap,
  timer,
} from 'rxjs';

import { IQueryParams, PaginatedStateService } from '@locumsnest/core';
import { Query } from '@locumsnest/core/src';
import { ISetValueForResourceMessageConstructor } from '@locumsnest/core/src/lib/adapters/singleton-resource-adapter';
import { Time } from '@locumsnest/core/src/lib/helpers';
import { CurrencySymbolPipe } from '@locumsnest/core/src/lib/pipes';

import { DEFAULT_CURRENCY } from '../../core/constants';
import { LoadProfileSettings } from '../../core/services/interfaces';
import { ExternalStaffingProviderOfficerService } from '../../external-staffing-provider-officer/+state/external-staffing-provider-officer.service';
import { IExternalStaffingCandidateBidStatusEntity } from '../../interfaces/api/external-staffing-candidate-bid-status-entity';
import { IProfileEntity } from '../../interfaces/api/profile-entity';
import { ProfileFlagService } from '../../profile-flag/+state/profile-flag.service';
import { ProfileService } from '../../profile/+state/profile.service';
import { StaffBankMembershipRequestService } from '../../staff-bank-membership-request/+state/staff-bank-membership-request.service';
import { StaffBankMembershipService } from '../../staff-bank-membership/+state/staff-bank-membership.service';
import { ExternalStaffingCandidateBidStatusService } from './../../external-staffing-candidate-bid-status/+state/external-staffing-candidate-bid-status.service';
import {
  IAdjacentBidEntity,
  IBidFragmentWithRates,
  IExternalStaffingCandidateBidEntity,
  IJobListingEntityWithRates,
} from './../../interfaces/api/external-staffing-candidate-bid-entity';
import { getBidMultiResourceId } from './../util/multi-resource';
import {
  adjacentBidsAdapter,
  paginationAdapter,
  STAFFING_CASCADE_INDEX,
} from './external-staffing-candidate-bid.adapter';
import {
  ExternalStaffingCandidateBidMessageTypes,
  externalStaffingCandidateBidPaginationMessages,
  ResetExternalStaffingCandidateBidPaginationMessage,
  UpsertExternalStaffingCandidateBidPageMessage,
  UpsertMultipleMessage,
  UpsertOneMessage,
} from './external-staffing-candidate-bid.messages';
import { ExternalStaffingCandidateBidPersistenceService } from './external-staffing-candidate-bid.persistence.service';
import {
  externalStaffingCandidateBidPaginationSelectors,
  selectAllExternalStaffingCandidateBids,
  selectExternalStaffingCandidateBid,
  selectExternalStaffingCandidateBidEntityState,
  selectExternalStaffingCandidateBidState,
} from './external-staffing-candidate-bid.selectors';
import { calculateApprovedFlatRate, INITIAL_FORM_ENTITY_STATE } from './form/form.reducer';
import * as formSelectors from './form/form.selectors';
import { ISignature } from './interfaces/signature';
import { selectExternalStaffingCandidateBidUiFormState } from './ui-form/ui-form.selectors';
import {
  selectActiveStepOne,
  selectActiveStepTwo,
  selectCompleteStepOne,
  selectCompleteStepTwo,
  selectExternalStaffingCandidateBidUiState,
  selectSelectedBid,
  selectShowFileUploader,
} from './ui/ui.selectors';

@Injectable({
  providedIn: 'root',
})
export class ExternalStaffingCandidateBidService extends PaginatedStateService<
  IExternalStaffingCandidateBidEntity,
  UpsertExternalStaffingCandidateBidPageMessage,
  ResetExternalStaffingCandidateBidPaginationMessage,
  UpsertMultipleMessage
> {
  static readonly DEFAULT_LOAD_PROFILE_SETTINGS: LoadProfileSettings = {
    loadProfileFlag: true,
    loadProfile: false,
    loadStaffBanks: false,
    loadStaffBankRequests: false,
    loadAssignmentNumbers: false,
    loadCandidateBids: false,
  };

  protected readonly SetValueForAdjacentBidsMessageClass: ISetValueForResourceMessageConstructor<
    string,
    IAdjacentBidEntity[]
  > = adjacentBidsAdapter.getMessages().SetValueForResource;
  protected readonly adjacentBidSelectors = adjacentBidsAdapter.getSelectors(
    selectExternalStaffingCandidateBidState,
  );

  private currencySymbol = new CurrencySymbolPipe();
  constructor(
    protected persistenceService: ExternalStaffingCandidateBidPersistenceService,
    private profileService: ProfileService,
    private staffBankMembershipService: StaffBankMembershipService,
    private staffBankMembershipRequestService: StaffBankMembershipRequestService,
    private profileFlagService: ProfileFlagService,
    private externalStaffingProviderOfficerService: ExternalStaffingProviderOfficerService,
    private externalStaffingCandidateBidStatusService: ExternalStaffingCandidateBidStatusService,
  ) {
    super();
  }
  get paginationMessages() {
    return externalStaffingCandidateBidPaginationMessages;
  }

  get paginationSelectors() {
    return externalStaffingCandidateBidPaginationSelectors;
  }

  get entityStateSelector() {
    return selectExternalStaffingCandidateBidEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get loadingMessages() {
    return paginationAdapter.getLoadingMessages();
  }

  getAll() {
    return this.store.pipe(select(selectAllExternalStaffingCandidateBids));
  }

  getOne(id) {
    return this.store.pipe(
      select(selectExternalStaffingCandidateBid(id)),
      filter((bid) => !!bid),
    );
  }

  filterByCascadeId(bids: IExternalStaffingCandidateBidEntity[], cascadeId: number) {
    return bids.filter(({ staffingCascade }) => staffingCascade === cascadeId);
  }
  getOneImmediately(id: number) {
    return this.store.pipe(select(selectExternalStaffingCandidateBid(id)), first());
  }
  getAllAfterLoadingByCascadeId(cascadeId: number) {
    return this.getAllAfterLoading().pipe(map((bids) => this.filterByCascadeId(bids, cascadeId)));
  }

  getAllOrEmptyByCascadeId(cascadeId: number) {
    return this.getMultipleOrEmptyByIndex(STAFFING_CASCADE_INDEX, cascadeId);
  }

  getAllByCascadeId(cascadeId: number) {
    return this.getMultipleByIndex(STAFFING_CASCADE_INDEX, cascadeId);
  }

  getPendingByCascadeId(cascadeId: number) {
    return combineLatest([
      this.externalStaffingCandidateBidStatusService.getSubmittedStatusCode(),
      this.getAllOrEmptyByCascadeId(cascadeId),
    ]).pipe(map(([status, bids]) => bids.filter((bid) => bid.status === status.val)));
  }

  getAllByCascadeIdWithDetails(cascadeId: number) {
    return this.getDetails(this.getAllByCascadeId(cascadeId));
  }
  getAdjacentBidDict() {
    return this.store.pipe(select(this.adjacentBidSelectors.selectSubResourceState));
  }
  getFormState() {
    return this.store.pipe(select(formSelectors.selectExternalStaffingCandidateBidFormState));
  }

  getProviderFeeIsInvalid() {
    return this.store.pipe(select(formSelectors.selectProviderFeeIsInvalid));
  }

  getSelectedProfileId() {
    return this.store.pipe(select(formSelectors.selectProfileId));
  }
  getUploadFileFormState() {
    return this.store.pipe(select(formSelectors.selectUploadFileFormState));
  }

  getFormProfileId() {
    return this.store.pipe(select(formSelectors.selectProfileId));
  }
  getFormDirectEngagementCandidate() {
    return this.store.pipe(select(formSelectors.selectDirectEngagementCandidate));
  }

  getFormLegacyApprovedFee() {
    return this.store.pipe(select(formSelectors.selectLegacyApprovedFee));
  }
  getFormLegacyApprovedFeeExists() {
    return this.store.pipe(select(formSelectors.selectLegacyApprovedFeeExists));
  }
  getFormAgencyFeeApproved() {
    return this.store.pipe(select(formSelectors.selectFormFeeApproved));
  }
  getFormGrade() {
    return this.store.pipe(select(formSelectors.selectGradeId));
  }
  getFormStaffingProvider() {
    return this.store.pipe(select(formSelectors.selectStaffingProvider));
  }
  getFormId() {
    return this.store.pipe(
      select(formSelectors.selectCurrentExternalCandidate),
      distinctUntilChanged(),
    );
  }

  getAllForProfile(profile: string) {
    return this.getAll().pipe(map((entities) => entities.filter((e) => e.profile === profile)));
  }

  getExistingBidSignature(id): Observable<ISignature> {
    return this.getOne(id).pipe(
      map(({ submittedBy, lastUpdate }) => ({ ...submittedBy, signedAt: lastUpdate })),
    );
  }
  getNewBidSignature(signatureUpdateInterval = 60 * 1000): Observable<ISignature> {
    return combineLatest([
      this.externalStaffingProviderOfficerService.getAssignedUserDetails(),
      timer(0, signatureUpdateInterval),
    ]).pipe(
      map(([userDetails]) => ({
        ...userDetails,
        signedAt: Time.getDate(),
      })),
    );
  }

  getCurrentSignature(newSignatureUpdateInterval = 60 * 1000): Observable<ISignature> {
    return this.getFormId().pipe(
      mergeMap((formId) => {
        if (formId) {
          return this.getExistingBidSignature(formId);
        }
        return this.getNewBidSignature(newSignatureUpdateInterval);
      }),
    );
  }

  getExternalStaffingCandidateBidUiFormState() {
    return this.store.pipe(select(selectExternalStaffingCandidateBidUiFormState));
  }

  getExternalStaffingCandidateBidUiState() {
    return this.store.pipe(select(selectExternalStaffingCandidateBidUiState));
  }

  getActiveStepOne() {
    return this.store.pipe(select(selectActiveStepOne));
  }

  getActiveStepTwo() {
    return this.store.pipe(select(selectActiveStepTwo));
  }

  getCompleteStepOne() {
    return this.store.pipe(select(selectCompleteStepOne));
  }

  getCompleteStepTwo() {
    return this.store.pipe(select(selectCompleteStepTwo));
  }

  getShowFileUploader() {
    return this.store.pipe(select(selectShowFileUploader));
  }

  getSelectedBid() {
    return this.store.pipe(select(selectSelectedBid));
  }
  getSelectedBidEntity() {
    return this.getSelectedBid().pipe(switchMap((id) => this.getOne(id)));
  }

  loadOneIfNotExists(id: number) {
    return this.getOneImmediately(id).pipe(
      mergeMap((bid) => {
        if (bid) {
          return of<Action>();
        }
        return this.fetchOne(id, {
          ...ExternalStaffingCandidateBidService.DEFAULT_LOAD_PROFILE_SETTINGS,
          loadProfileFlag: false,
        });
      }),
    );
  }

  loadCascadeBidList(cascadeID: number) {
    const namespace = `Cascade ${cascadeID} bid list`;
    return this.isPendingOrLoaded(namespace).pipe(
      first(),
      mergeMap((loaded) => {
        if (!loaded) {
          return this.loadByStaffingCascadeIds([cascadeID], {
            ...ExternalStaffingCandidateBidService.DEFAULT_LOAD_PROFILE_SETTINGS,
            loadProfileFlag: false,
            loadProfile: true,
          });
        }
      }),
    );
  }

  loadByStaffingCascadeIds(ids: number[], loadProfile: LoadProfileSettings = null) {
    return this.loadAllWithDependencies({ staffingCascade: ids }, loadProfile);
  }

  loadAllWithDependencies(params, loadProfileSettings) {
    return this.loadAll(params).pipe(
      mergeMap((action) => merge(of(action), this.loadDependencies(action, loadProfileSettings))),
    );
  }

  loadByIds(ids: number[], loadProfileSettings: LoadProfileSettings) {
    return concat(
      of(new this.loadingMessages.SetLoadingMessage({})),
      this.fetch({ id: ids }, loadProfileSettings),
      of(new this.loadingMessages.ResetLoadingMessage({})),
    );
  }

  fetchLast(query: IQueryParams) {
    return this.persistenceService
      .retrieve({
        ...query,
        ordering: '-created_at',
        pageSize: 1,
      })
      .pipe(map((results) => results.results[0]));
  }

  fetchOneForProfileIfNotExists(profileId: string) {
    return this.getAll().pipe(
      first(),
      map((entities) => entities.filter((x) => x.profile === profileId)),
      switchMap((entities) => {
        if (entities.length) return EMPTY;
        return this.persistenceService
          .retrieve({
            profile: profileId,
            pageSize: 1,
          })
          .pipe(map((res) => new UpsertMultipleMessage({ entities: res.results })));
      }),
    );
  }

  fetchBidsForProfileExcludingProvider(id: number, profileId: string) {
    const shouldLoadMore$ = this.getAll().pipe(
      first(),
      map((res) => res.filter((x) => x.profile === profileId)),
      map((res) => uniqBy(res, 'staffingProvider')),
      map((res) => res.length < 2),
    );

    return shouldLoadMore$.pipe(
      switchMap((shouldLoadMore) => {
        if (shouldLoadMore) {
          return this.persistenceService
            .retrieve({
              staffing_provider__not: id,
              profile: profileId,
              pageSize: 1,
            })
            .pipe(map((res) => new UpsertMultipleMessage({ entities: res.results })));
        }
        return EMPTY;
      }),
    );
  }

  fetchLastByStartTime(query: IQueryParams) {
    return this.persistenceService
      .retrieve({
        ...query,
        ordering: '-start_time',
        pageSize: 1,
      })
      .pipe(map((results) => results.results[0]));
  }

  private fetchOne(query: string | number, loadProfileSettings: LoadProfileSettings) {
    return this.persistenceService.retrieve(query).pipe(
      mergeMap((entity) => {
        const actions: Observable<Action>[] = [of(new UpsertOneMessage({ entity }))];
        if (!isNil(loadProfileSettings) && !isNil(entity.profile)) {
          if (loadProfileSettings.loadProfile) {
            actions.push(this.profileService.loadOne(entity.profile));

            if (loadProfileSettings.loadProfileFlag) {
              actions.push(this.profileFlagService.loadByProfileIds([entity.profile]));
            }
          }
          if (loadProfileSettings.loadStaffBanks) {
            actions.push(this.staffBankMembershipService.loadByProfileIds([entity.profile]));
          }
          if (loadProfileSettings.loadStaffBankRequests) {
            actions.push(this.staffBankMembershipRequestService.loadByProfileIds([entity.profile]));
          }
        }
        return merge(...actions);
      }),
    );
  }
  private getDetails(
    bids$: Observable<IExternalStaffingCandidateBidEntity<number, number, string, number>[]>,
  ): Observable<
    IExternalStaffingCandidateBidEntity<
      number,
      number,
      IProfileEntity,
      IExternalStaffingCandidateBidStatusEntity
    >[]
  > {
    return bids$.pipe(
      switchMap((bids) => {
        if (!bids.length) {
          return of([]);
        }
        return combineLatest(
          bids.map((bid) =>
            combineLatest([
              this.externalStaffingCandidateBidStatusService.getOne(bid.bookingStatus),
              this.profileService.getOne(bid.profile),
            ]).pipe(map(([bookingStatus, profile]) => ({ ...bid, bookingStatus, profile }))),
          ),
        );
      }),
    );
  }
  private fetchMultiple(
    query?: IQueryParams,
    // eslint-disable-next-line max-len
    loadProfileSettings: LoadProfileSettings = ExternalStaffingCandidateBidService.DEFAULT_LOAD_PROFILE_SETTINGS,
    namespace = 'default',
  ) {
    return this.loadAllPages(namespace, {}, query).pipe(
      mergeMap((action) => merge(of(action), this.loadDependencies(action, loadProfileSettings))),
    );
  }

  loadDependencies(action, loadProfileSettings: LoadProfileSettings) {
    const actions: Observable<Action>[] = [];
    if (
      action.type === ExternalStaffingCandidateBidMessageTypes.UPSERT_MULTIPLE ||
      action.type === ExternalStaffingCandidateBidMessageTypes.SET_COLLECTION
    ) {
      const profileIds: string[] = uniq(
        (action as UpsertMultipleMessage).payload.entities.map((result) => result.profile),
      );

      if (!isNil(loadProfileSettings) && profileIds.length > 0) {
        if (loadProfileSettings.loadProfile) {
          actions.push(this.profileService.loadByIds(profileIds));

          if (loadProfileSettings.loadProfileFlag) {
            actions.push(this.profileFlagService.loadByProfileIds(profileIds));
          }
        }

        if (loadProfileSettings.loadStaffBanks) {
          actions.push(this.staffBankMembershipService.loadByProfileIds(profileIds));
        }

        if (loadProfileSettings.loadStaffBankRequests) {
          actions.push(this.staffBankMembershipRequestService.loadByProfileIds(profileIds));
        }

        if (loadProfileSettings.loadCandidateBids) {
          (action as UpsertMultipleMessage).payload.entities.forEach((e) => {
            actions.push(this.fetchBidsForProfileExcludingProvider(e.staffingProvider, e.profile));
          });
        }
      }
    }

    return actions.length ? merge(...actions) : EMPTY;
  }

  fetch(
    query?: Query,
    // eslint-disable-next-line max-len
    loadProfileSettings: LoadProfileSettings = ExternalStaffingCandidateBidService.DEFAULT_LOAD_PROFILE_SETTINGS,
    namespace = 'default',
  ) {
    if (isString(query) || isNumber(query)) {
      return this.fetchOne(query, loadProfileSettings);
    }
    return this.fetchMultiple(query, loadProfileSettings, namespace);
  }

  getCandidateAmount(externalStaffingCandidateBid: IExternalStaffingCandidateBidEntity) {
    let candidateCost = 0;
    const bidFragments = externalStaffingCandidateBid.bidFragments;

    for (let i = 0; i < bidFragments.length; i++) {
      const totalHours = Time.getMoment(bidFragments[i].toTime).diff(
        Time.getMoment(bidFragments[i].fromTime),
        'hours',
        true,
      );
      const totalPay = totalHours * parseFloat(bidFragments[i].payRate);
      candidateCost += totalPay;
    }

    if (!isNil(externalStaffingCandidateBid.flatRate)) {
      candidateCost += parseFloat(externalStaffingCandidateBid.flatRate as string);
    }

    return candidateCost;
  }

  getEntityFormState(id) {
    return this.getOne(id).pipe(
      map((entity) => formSelectors.getEntityFormState(entity, INITIAL_FORM_ENTITY_STATE)),
      distinctUntilChanged(),
    );
  }

  getNewEntityFormState(entity: IExternalStaffingCandidateBidEntity) {
    return formSelectors.getEntityFormState(entity, INITIAL_FORM_ENTITY_STATE);
  }

  getNewFragmentsFormState(fragments: IBidFragmentWithRates[]) {
    return formSelectors.getFragmentsFormState(fragments);
  }
  getBidFlatRate(listing: IJobListingEntityWithRates, grade: number) {
    const listingGrade = listing.grades.find((x) => x.grade === grade);
    if (isNil(listingGrade)) {
      return null;
    }
    return calculateApprovedFlatRate(listingGrade.jobFragments);
  }
  getBidFragments(listing: IJobListingEntityWithRates, grade: number) {
    const listingGrade = listing.grades.find((x) => x.grade === grade);
    if (isNil(listingGrade)) {
      return [];
    }
    return listingGrade.jobFragments.map((jf) => ({
      payRate: jf.payRate.rate,
      payRateCurrency: jf.payRate.rateCurrency,
      nonResidentCalloutRate: jf.payRate.nonResidentCalloutRate,
      nonResidentCalloutRateCurrency: jf.payRate.nonResidentCalloutRateCurrency,
      fromTime: jf.timeFragment.fromTime,
      toTime: jf.timeFragment.toTime,
      payRateType: jf.payRate.payRateType,
      agencyFee: jf.agencyFee,
      nonResidentCalloutAgencyFee: jf.nonResidentCalloutAgencyFee,
      flatRate: jf.flatRate + '',
      approvedRate: jf.approvedRate,
    }));
  }

  getAgencyAmount(
    externalStaffingCandidateBid: IExternalStaffingCandidateBidEntity<number, number, any, any>,
  ): string {
    const candidateCost = this.getCandidateAmount(externalStaffingCandidateBid);
    const fee = +get(externalStaffingCandidateBid.providerFee, 'fee', 0);
    if (fee > 0) {
      return (
        this.currencySymbol.transform(
          get(externalStaffingCandidateBid.providerFee, 'feeCurrency', DEFAULT_CURRENCY),
        ) + fee
      );
    }

    return (
      this.currencySymbol.transform(
        get(externalStaffingCandidateBid.providerFee, 'feeCurrency', DEFAULT_CURRENCY),
      ) +
      (
        (candidateCost * +get(externalStaffingCandidateBid.providerFee, 'feePercentage', 0)) /
        100
      ).toFixed(2)
    );
  }

  importBid(e: IExternalStaffingCandidateBidEntity) {
    return this.persistenceService
      .importBid(e)
      .pipe(map((entity) => new UpsertOneMessage({ entity })));
  }

  create(e: IExternalStaffingCandidateBidEntity) {
    return this.persistenceService
      .create(e)
      .pipe(map((entity) => new UpsertOneMessage({ entity })));
  }

  update(id: number, e: IExternalStaffingCandidateBidEntity) {
    return this.persistenceService
      .update(id, e)
      .pipe(map((entity) => new UpsertOneMessage({ entity })));
  }

  getProfilesAfterCurrentPageLoad(namespace) {
    return this.getConsecutivePageEntities(namespace).pipe(
      mergeMap((bids: IExternalStaffingCandidateBidEntity[]) => {
        if (!bids.length) {
          return of([]);
        }

        const bidProfileIds = bids.map((bid) => bid.profile);
        return this.profileService.getMultiple(bidProfileIds);
      }),
      distinctUntilChanged(),
    );
  }

  loadAdjacent(multiResourceId: string, profile: string, startTime: Date) {
    return this.persistenceService.fetchAdjacent(profile, startTime).pipe(
      map(
        (subResource) =>
          new this.SetValueForAdjacentBidsMessageClass({
            resourceId: multiResourceId,
            subResource: subResource.map((adjacentBid) => ({
              ...adjacentBid,
              multiResourceId: getBidMultiResourceId(adjacentBid.bidId),
            })),
          }),
      ),
    );
  }

  getBidStartTime(id: number) {
    return this.getOne(id).pipe(
      map(({ startTime }) => startTime),
      distinctUntilChanged(),
    );
  }

  getBidProfile(id: number) {
    return this.getOne(id).pipe(
      map(({ profile }) => profile),
      distinctUntilChanged(),
    );
  }
  getBidDirectEngagementCandidate(id: number) {
    return this.getOne(id).pipe(
      map(({ directEngagementCandidate }) => directEngagementCandidate),
      distinctUntilChanged(),
    );
  }

  initializePaginationForProfile(namespace: string, profile: string) {
    return this.initializePagination(
      `${namespace}-${profile}`,
      {},
      { profile, ordering: '-start_time' },
    );
  }

  loadNextForProfile(namespace: string, profile: string) {
    return this.loadNext(`${namespace}-${profile}`, {}, { profile, ordering: '-start_time' });
  }
}
