import { ISelectOption } from '@locumsnest/components/src/lib/interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IStaffingCascadeTimeWindowEntity } from '../../interfaces/api/staffing-cascade-time-window-entity';
import {
  IStaffingCascadeTimeWindowEntityState,
  IStaffingCascadeTimeWindowFeatureState,
  IStaffingCascadeTimeWindowSharedState,
} from './interfaces';
import { selectAll } from './staffing-cascade-time-window.reducer';

export const getEntityState = (state: { entityState: IStaffingCascadeTimeWindowEntityState }) =>
  state.entityState;

export const getStaffingCascadeTimeWindowOptions = (
  staffingCascadeTimeWindows: IStaffingCascadeTimeWindowEntity[]
): ISelectOption[] =>
  staffingCascadeTimeWindows.map((staffingCascadeTimeWindow) => ({
    id: staffingCascadeTimeWindow.id,
    name: `${staffingCascadeTimeWindow.professionSpecialty}`,
  }));

export const getByProfessionSpecialtyId =
  (professionSpecialtyId) => (staffingCascadeTimeWindows: IStaffingCascadeTimeWindowEntity[]) =>
    staffingCascadeTimeWindows.find(
      (staffingCascadeTimeWindow) =>
        staffingCascadeTimeWindow.professionSpecialty === professionSpecialtyId
    );

export const getId = (staffingCascadeTimeWindow: IStaffingCascadeTimeWindowEntity) =>
  staffingCascadeTimeWindow.id;

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectStaffingCascadeTimeWindowState = createFeatureSelector<
  IStaffingCascadeTimeWindowFeatureState & IStaffingCascadeTimeWindowSharedState
>('staffingCascadeTimeWindows');

export const selectStaffingCascadeTimeWindowEntityState = createSelector(
  selectStaffingCascadeTimeWindowState,
  getEntityState
);

export const selectAllStaffingCascadeTimeWindows = createSelector(
  selectStaffingCascadeTimeWindowEntityState,
  selectAll
);

export const selectStaffingCascadeTimeWindowOptions = createSelector(
  selectAllStaffingCascadeTimeWindows,
  getStaffingCascadeTimeWindowOptions
);

export const selectStaffingCascadeTimeWindowByProfessionSpecialtyId = (
  professionSpecialtyId: number
) =>
  createSelector(
    selectAllStaffingCascadeTimeWindows,
    getByProfessionSpecialtyId(professionSpecialtyId)
  );
