import { isNil } from 'lodash-es';
import { FormGroupState } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

import { Time } from '@locumsnest/core/src/lib/helpers';
import { DATE_FORMAT_DB, URL_DATE_FORMAT } from '@locumsnest/core/src/lib/types/constants';

import { IUploadFileFormState } from '../interfaces';

export const isOtherTypeSelected = (state: FormGroupState<IUploadFileFormState>) => () =>
  state.controls.type.value === state.userDefinedProperties['otherCertificateType'].id
    ? required(state.controls.title.value)
    : null;

export const isExpirationDateInFuture = (state: FormGroupState<IUploadFileFormState>) => () => {
  if (isNil(state.controls.expirationDate.value)) {
    return null;
  }
  const today = Time.getMoment();
  const expirationDate = Time.getMoment(
    Time.getMoment(state.controls.expirationDate.value, URL_DATE_FORMAT).format(DATE_FORMAT_DB)
  );

  return !expirationDate.isValid() || expirationDate < today
    ? { earlierThanToday: { verboseName: 'expiration date' } }
    : null;
};
