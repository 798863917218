import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash-es';
import { FormGroupState } from 'ngrx-forms';

import { IPrematched } from '../../interfaces/api/job-listing-entity';
import {
  IJobListingConversationProfileEntityState,
  IJobListingConversationProfileFeatureState,
  IJobListingConversationProfileSharedState,
} from './interfaces';
import { IPreMatchFormState } from './interfaces/pre-match-form-state';
import { loadingAdapter, paginationAdapter } from './job-listing-conversation-profile.adapter';
import { selectAll } from './job-listing-conversation-profile.reducer';

export const getEntityState = (state: { entityState: IJobListingConversationProfileEntityState }) =>
  state.entityState;

const getShowMyList = (formState: FormGroupState<IPreMatchFormState>) => formState.value.showMyList;
const getInvitationMessage = (formState: FormGroupState<IPreMatchFormState>) =>
  formState.value.invitationMessage;

export const getJobListingConversationProfileIds = (
  state: IJobListingConversationProfileEntityState,
) => (state.ids as string[]).map((id: string) => state.entities[id].profile);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectJobListingConversationProfileState = createFeatureSelector<
  IJobListingConversationProfileFeatureState & IJobListingConversationProfileSharedState
>('jobListingConversationProfiles');

export const selectJobListingConversationProfileEntityState = createSelector(
  selectJobListingConversationProfileState,
  getEntityState,
);

export const selectAllJobListingConversationProfiles = createSelector(
  selectJobListingConversationProfileEntityState,
  selectAll,
);

export const selectAllJobListingConversationProfileIds = createSelector(
  selectJobListingConversationProfileEntityState,
  getJobListingConversationProfileIds,
);

export const selectJobListingConversationProfileFormState = createSelector(
  selectJobListingConversationProfileState,
  (state) => state.formState,
);

export const selectShowMyList = createSelector(
  selectJobListingConversationProfileFormState,
  getShowMyList,
);

export const selectInvitationMessage = createSelector(
  selectJobListingConversationProfileFormState,
  getInvitationMessage,
);

export const selectSelectedCandidateList = createSelector(
  selectJobListingConversationProfileFormState,
  (formState) => formState.value.selectedCandidateList || [],
);

export const selectSelectedCandidateProfiles = createSelector(
  selectSelectedCandidateList,
  (selectedCandidateList) =>
    selectedCandidateList.map((selectedCandidate) => {
      if (!isEmpty(selectedCandidate.invitationMessage.trim())) {
        return {
          profile: selectedCandidate.id,
          invitationMessage: selectedCandidate.invitationMessage,
        };
      } else {
        return {
          profile: selectedCandidate.id,
        };
      }
    }),
);

export const selectPropagatePrematchValue = createSelector(
  selectJobListingConversationProfileFormState,
  (formState) => formState.value.propagateToAllShifts,
);

export const selectPrematchProperties = createSelector(
  selectSelectedCandidateProfiles,
  selectPropagatePrematchValue,
  (selectedCandidateProfiles, propagatePrematchValue) =>
    ({
      prematchProfiles: selectedCandidateProfiles,
      propagatePrematch: propagatePrematchValue,
    }) as IPrematched,
);

export const jobListingConversationProfilePaginationSelectors =
  paginationAdapter.paginationSelectors(
    selectJobListingConversationProfileState,
    loadingAdapter,
    'pagination',
    'entityState',
    false,
  );

export const loadingStateSelectors = loadingAdapter.getSelectors(
  selectJobListingConversationProfileState,
);
