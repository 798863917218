import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IProfileStatisticsEntity } from '../../interfaces/api/profile-statistics-entity';

@Injectable({
  providedIn: 'root',
})
export class ProfileStatisticsPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IProfileStatisticsEntity,
  IProfileStatisticsEntity
> {
  protected readonly endpoint = 'profileStatistics';
}
