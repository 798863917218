/* eslint-disable @typescript-eslint/naming-convention */
import { IBulkRequestSummary } from '../interfaces/job-listing-ui-state';
import { messageableFactory as actionableFactory } from './ui.adapter';

export class InitializeUiStateMessage extends actionableFactory.create<
  'Initialize Ui State Message',
  Record<string, never>
>('Initialize Ui State Message') {}

export class SetFormLoadedTemplateMessage extends actionableFactory.create<
  'Set Form Loaded Template Info Message',
  { name: string; id: number; isTemplateLoaded: boolean }
>('Set Form Loaded Template Info Message') {}

export class SubmissionInProgressMessage extends actionableFactory.create<
  'Submission In Progress',
  Record<string, never>
>('Submission In Progress') {}

export class SubmissionCompleteMessage extends actionableFactory.create<
  'Submission Complete',
  Record<string, never>
>('Submission Complete') {}

export class LoadingInProgressMessage extends actionableFactory.create<
  'Loading In Progress',
  Record<string, never>
>('Loading In Progress') {}

export class LoadingCompleteMessage extends actionableFactory.create<
  'Loading Complete',
  Record<string, never>
>('Loading Complete') {}

export class ListingNotFoundMessage extends actionableFactory.create<
  'Listing Not Found',
  Record<string, never>
>('Listing Not Found') {}

export class ShiftPublishedSuccessfullyMessage extends actionableFactory.create<
  'Shift Published Successfully',
  Record<string, never>
>('Shift Published Successfully') {}

export class ToggleShowCascadeFormMessage extends actionableFactory.create<
  'Toggle Show Cascade Form',
  Record<string, never>
>('Toggle Show Cascade Form') {}

export class ToggleShowNoteFormMessage extends actionableFactory.create<
  'Toggle Show Note Form',
  Record<string, never>
>('Toggle Show Note Form') {}

export class ToggleShowTagFormMessage extends actionableFactory.create<
  'Toggle Show Tag Form',
  Record<string, never>
>('Toggle Show Tag Form') {}

export class ToggleShowBulkUnpublishFormMessage extends actionableFactory.create<
  'Toggle Show Unpublish Form',
  Record<string, never>
>('Toggle Show Unpublish Form') {}

export class ToggleShowPreMatchWidgetMessage extends actionableFactory.create<
  'Toggle Show Pre Match Widget',
  Record<string, never>
>('Toggle Show Pre Match Widget') {}

export class SetSummarySubmissionMessage extends actionableFactory.create<
  'Set Summary Submission',
  IBulkRequestSummary
>('Set Summary Submission') {}

export class UpdateApplicationSelectedPageMessage extends actionableFactory.create<
  'Update Application Selected Page Message',
  { selectedPage: number }
>('Update Application Selected Page Message') {}

export class UpdateShowShiftDetailsCardMessage extends actionableFactory.create<
  'Update Show Shift Details Card Message',
  { show?: boolean }
>('Update Show Shift Details Card Message') {}

export type UIMessages =
  | SetFormLoadedTemplateMessage
  | SubmissionInProgressMessage
  | SubmissionCompleteMessage
  | ShiftPublishedSuccessfullyMessage
  | ToggleShowCascadeFormMessage
  | ToggleShowNoteFormMessage
  | ToggleShowBulkUnpublishFormMessage
  | SetSummarySubmissionMessage
  | InitializeUiStateMessage
  | UpdateApplicationSelectedPageMessage
  | UpdateShowShiftDetailsCardMessage
  | LoadingCompleteMessage
  | LoadingInProgressMessage
  | ListingNotFoundMessage
  | ToggleShowPreMatchWidgetMessage
  | ToggleShowTagFormMessage;
