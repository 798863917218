import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import {
  IStaffingCascadeBulkResponse,
  IStaffingCascadeEntity,
  IStaffingCascadeEntityStats,
} from '../../interfaces/api/staffing-cascade-entity';

@Injectable({
  providedIn: 'root',
})
export class StaffingCascadePersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IStaffingCascadeEntity
> {
  protected readonly endpoint = 'staffingCascade';

  cascadeBulkAuthorize(ids: number[]) {
    const data = ids.map((id) => ({ id }));

    return this.create<{ id: number }[], IStaffingCascadeEntity[]>(data, {
      controllerResource: 'cascadeBulkAuthorize',
      skipSerializer: true,
    });
  }

  loadStats(id: number) {
    return this.retrieve<IStaffingCascadeEntityStats>(null, {
      pathParams: { id },
      controllerResource: 'cascadeStats',
    });
  }

  cascadeBulkReject(ids: number[]) {
    const data = ids.map((id) => ({ id }));

    return this.create<{ id: number }[], IStaffingCascadeEntity[]>(data, {
      controllerResource: 'cascadeBulkReject',
      skipSerializer: true,
    });
  }

  cascadeBulkCascade(ids: number[], notes: string) {
    const data = ids.map((id) => ({ listing: id, notes }));

    return this.create<{ listing: number; notes: string }[], IStaffingCascadeBulkResponse>(data, {
      controllerResource: 'cascadeBulkCascade',
      skipSerializer: true,
    });
  }

  cascadeBulkCancel(ids: number[]) {
    const data = ids.map((id) => ({ tier_action: id }));

    return this.create<{ tier_action: number }[], IStaffingCascadeBulkResponse>(data, {
      controllerResource: 'cascadeBulkCancel',
      skipSerializer: true,
    });
  }

  requestTier(id: string, notes: string, tier: number) {
    return this.create<{ tier: number; notes: string }, IStaffingCascadeEntity>(
      { tier, notes },
      { pathParams: { id }, controllerResource: 'requestTier', skipSerializer: true },
    );
  }

  cancelTier(staffingCascadeId: number, tierActionId: number) {
    return this.create<{ tier_action: number }, IStaffingCascadeEntity>(
      { tier_action: tierActionId },
      {
        pathParams: { id: staffingCascadeId },
        controllerResource: 'cancelTier',
        skipSerializer: true,
      },
    );
  }
}
