import {
  IStaffBankMembershipRequestEntityState,
  IStaffBankMembershipRequestFeatureState,
  IStaffBankMembershipRequestSharedState,
} from './interfaces';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { selectAll } from './staff-bank-membership-request.reducer';
import { IStaffBankMembershipRequestEntity } from '../../interfaces/api/staff-bank-membership-request-entity';
import { paginationAdapter, loadingAdapter } from './staff-bank-membership-request.adapter';

export const getEntityState = (state: { entityState: IStaffBankMembershipRequestEntityState }) =>
  state.entityState;

export const getStaffBankMembershipRequest =
  (requestId: number) => (requests: IStaffBankMembershipRequestEntity[]) =>
    requests.find((request) => request.id === requestId);

export const getStaffBankMembershipRequestByProfileId =
  (profileId: string) => (requests: IStaffBankMembershipRequestEntity[]) =>
    requests.find((request) => request.profile === profileId);

export const getStaffBankMembershipRequestByProfileIds =
  (profileIds: string[]) => (staffBankMembershipRequests: IStaffBankMembershipRequestEntity[]) =>
    staffBankMembershipRequests.filter(
      (staffBankMembershipRequest) => profileIds.indexOf(staffBankMembershipRequest.profile) >= 0
    );

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectStaffBankMembershipRequestState = createFeatureSelector<
  IStaffBankMembershipRequestFeatureState & IStaffBankMembershipRequestSharedState
>('staffBankMembershipRequests');

export const selectStaffBankMembershipRequestEntityState = createSelector(
  selectStaffBankMembershipRequestState,
  getEntityState
);

export const selectAllStaffBankMembershipRequests = createSelector(
  selectStaffBankMembershipRequestEntityState,
  selectAll
);

export const selectStaffBankMembershipRequest = (requestId) =>
  createSelector(selectAllStaffBankMembershipRequests, getStaffBankMembershipRequest(requestId));

export const selectStaffBankMembershipRequestByProfileId = (profileId) =>
  createSelector(
    selectAllStaffBankMembershipRequests,
    getStaffBankMembershipRequestByProfileId(profileId)
  );

export const selectStaffBankMembershipRequestByProfileIds = (profileIds) =>
  createSelector(
    selectAllStaffBankMembershipRequests,
    getStaffBankMembershipRequestByProfileIds(profileIds)
  );

export const staffBankMembershipRequestPaginationSelectors = paginationAdapter.paginationSelectors(
  selectStaffBankMembershipRequestEntityState,
  loadingAdapter
);
