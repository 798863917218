import { createFormGroupState, createFormStateReducerWithUpdate, FormGroupState } from 'ngrx-forms';

import { IStaffingCascadeFormState } from '../interfaces';
import { InitializeStaffingCascadeFormMessage } from './form.messages';

export const FORM_ID = 'CASCADE_SHIFT';
const INITIAL_FORM_STATE = createFormGroupState<IStaffingCascadeFormState>(FORM_ID, {
  notes: '',
});
const formStateReducer = createFormStateReducerWithUpdate<IStaffingCascadeFormState>([]);

export function reducer(
  state: FormGroupState<IStaffingCascadeFormState> = INITIAL_FORM_STATE,
  action
) {
  switch (action.type) {
    case InitializeStaffingCascadeFormMessage.TYPE:
      state = createFormGroupState<IStaffingCascadeFormState>(FORM_ID, INITIAL_FORM_STATE.value);
      break;
  }

  state = formStateReducer(state, action);

  return state;
}
