import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { IStaffBankMembershipRequestStatusEntity } from '../../interfaces/api/staff-bank-membership-request-status-entity';

export enum StaffBankMembershipRequestStatusSignalTypes {
  LOAD_STAFF_BANK_MEMBERSHIP_REQUEST_STATUS = '[SBMembershipRequestStatus][Signal] Load Collection',
}
export enum StaffBankMembershipRequestStatusMessageTypes {
  SET_COLLECTION = '[StaffBankMembershipRequestStatus][Message] Set Collection',
  ADD_ONE = '[StaffBankMembershipRequestStatus][Message] Add One',
  UPDATE_ONE = '[StaffBankMembershipRequestStatus][Message] Update One',
  UPSERT_ONE = '[StaffBankMembershipRequestStatus][Message] Upsert One',
  DELETE_ONE = '[StaffBankMembershipRequestStatus][Message] Delete One',
  ADD_MULTIPLE = '[StaffBankMembershipRequestStatus][Message] Add All',
  DELETE_MULTIPLE = '[StaffBankMembershipRequestStatus][Message] Delete Many',
  UPSERT_MULTIPLE = '[StaffBankMembershipRequestStatus][Message] Upsert Many',
  UPDATE_MULTIPLE = '[StaffBankMembershipRequestStatus][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = StaffBankMembershipRequestStatusMessageTypes.SET_COLLECTION;
  constructor(
    public payload: {
      staffBankMembershipRequestStatuses: IStaffBankMembershipRequestStatusEntity[];
    }
  ) {}
}

export class AddOneMessage implements Action {
  readonly type = StaffBankMembershipRequestStatusMessageTypes.ADD_ONE;

  constructor(
    public payload: { staffBankMembershipRequestStatus: IStaffBankMembershipRequestStatusEntity }
  ) {}
}

export class UpsertOneMessage implements Action {
  readonly type = StaffBankMembershipRequestStatusMessageTypes.UPSERT_ONE;

  constructor(
    public payload: { staffBankMembershipRequestStatus: IStaffBankMembershipRequestStatusEntity }
  ) {}
}

export class AddMultipleMessage implements Action {
  readonly type = StaffBankMembershipRequestStatusMessageTypes.ADD_MULTIPLE;

  constructor(
    public payload: {
      staffBankMembershipRequestStatuses: IStaffBankMembershipRequestStatusEntity[];
    }
  ) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = StaffBankMembershipRequestStatusMessageTypes.UPSERT_MULTIPLE;

  constructor(
    public payload: {
      staffBankMembershipRequestStatuses: IStaffBankMembershipRequestStatusEntity[];
    }
  ) {}
}

export class UpdateOneMessage implements Action {
  readonly type = StaffBankMembershipRequestStatusMessageTypes.UPDATE_ONE;

  constructor(
    public payload: {
      staffBankMembershipRequestStatus: Update<IStaffBankMembershipRequestStatusEntity>;
    }
  ) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = StaffBankMembershipRequestStatusMessageTypes.UPDATE_MULTIPLE;

  constructor(
    public payload: {
      staffBankMembershipRequestStatuses: Update<IStaffBankMembershipRequestStatusEntity>[];
    }
  ) {}
}

export class DeleteOneMessage implements Action {
  readonly type = StaffBankMembershipRequestStatusMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = StaffBankMembershipRequestStatusMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type StaffBankMembershipRequestStatusMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
