import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IBankHolidayEntity } from '../../interfaces/api/bank-holiday-entity';

@Injectable({
  providedIn: 'root',
})
export class BankHolidayPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IBankHolidayEntity
> {
  protected readonly endpoint = 'bankHoliday';
}
