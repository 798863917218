import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ISafeboxCertificateEntity } from '../../interfaces/api/safebox-certificate-entity';
import { selectAll } from './safebox-certificate.reducer';
import {
  ISafeboxCertificateEntityState,
  ISafeboxCertificateFeatureState,
  ISafeboxCertificateSharedState,
} from './interfaces';
import { paginationAdapter } from './safebox-certificate.adapter';

export const getEntityState = (state: { entityState: ISafeboxCertificateEntityState }) =>
  state.entityState;

export const getSafeboxCertificatesByProfileId =
  (profileId: string) => (safeboxCertificates: ISafeboxCertificateEntity[]) =>
    safeboxCertificates.filter((safeboxCertificate) => safeboxCertificate.profile === profileId);

export const getSafeboxCertificateById =
  (id: number) => (safeboxCertificates: ISafeboxCertificateEntity[]) =>
    safeboxCertificates.find((safeboxCertificate) => safeboxCertificate.id === id);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectSafeboxCertificateState =
  createFeatureSelector<ISafeboxCertificateFeatureState & ISafeboxCertificateSharedState>(
    'safeboxCertificates'
  );

export const selectSafeboxCertificateEntityState = createSelector(
  selectSafeboxCertificateState,
  getEntityState
);
export const selectAllSafeboxCertificates = createSelector(
  selectSafeboxCertificateEntityState,
  selectAll
);
export const selectSafeboxCertificatesByProfileId = (profileId: string) =>
  createSelector(selectAllSafeboxCertificates, getSafeboxCertificatesByProfileId(profileId));

export const selectSafeboxCertificateById = (id: number) =>
  createSelector(selectAllSafeboxCertificates, getSafeboxCertificateById(id));

export const safeboxCertificatePaginationSelectors =
  paginationAdapter.getSubresourcePaginationSelectors(selectSafeboxCertificateEntityState);
