import { MessageableFactory } from '@locumsnest/core/src/lib/ngrx';

import { IGradeRateEntity } from '../../../interfaces/api/grade-rate-entity';
import { ISectorEntity } from '../../../interfaces/api/sector-entity';
import { IJobListingFormWizardState } from '../interfaces/job-listing-form-state';

// initialization of mixin factory
// todo: remove fromTemplate flag when a better solution for non-dirty invalid fields is implemented
export function createJobListingFormMessages<T extends string>(
  actionableFactory: MessageableFactory<T>,
) {
  class InitializeJobListingFormMessage extends actionableFactory.create<
    'Initialize JobListingForm',
    {
      jobListingFormState: IJobListingFormWizardState;
      fromTemplate: boolean;
      hospitalSector: ISectorEntity;
      globalDescription?: string;
      globalCostCentreNumber?: string;
      globalReasonForVacancy?: number;
      listingGradeProfessions: number[];
    }
  >('Initialize JobListingForm') {}

  class ResetJobListingFormMessage extends actionableFactory.create<
    'Reset JobListingForm',
    Record<string, never>
  >('Reset JobListingForm') {}

  class EnableJobListingGradeMessage extends actionableFactory.create<
    'Enable Job Listing Grade',
    { grade: number }
  >('Enable Job Listing Grade') {}

  class EnableProfessionGradesMessage extends actionableFactory.create<
    'Enable Profession Grades',
    { profession: number }
  >('Enable Profession Grades') {}
  class AddJobListingGradeMessage extends actionableFactory.create<
    'ADD Job Listing Grade',
    { grade: number; defaultTimeBasedPayRateType: number; profession: number }
  >('ADD Job Listing Grade') {}

  class DisableJobListingGradeMessage extends actionableFactory.create<
    'Disable Job Listing Grade',
    { grade: number }
  >('Disable Job Listing Grade') {}

  class DisableProfessionGradesMessage extends actionableFactory.create<
    'Disable Profession Grades',
    { profession: number }
  >('Disable Profession Grades') {}
  class DisableNonPrimaryProfessionGradesMessage extends actionableFactory.create<
    'Disable Non Primary Profession Grades',
    void
  >('Disable Non Primary Profession Grades') {}
  class ClearJobListingGradeMessage extends actionableFactory.create<
    'Clear Job Listing Grade',
    { grade: number }
  >('Clear Job Listing Grade') {}

  class DisableFlatRateMessage extends actionableFactory.create<
    'Disable Flat Rate',
    Record<string, never>
  >('Disable Flat Rate') {}

  class EnableFlatRateMessage extends actionableFactory.create<
    'Enable Flat Rate',
    Record<string, never>
  >('Enable Flat Rate') {}

  class ApplyGradeRateCeilingMessage extends actionableFactory.create<
    'Apply Grade Rate Ceiling',
    { gradeRates: IGradeRateEntity[]; actionId: string }
  >('Apply Grade Rate Ceiling') {}

  class SetExtendedHoursMessage extends actionableFactory.create<
    'Set Extended Hours',
    { value: boolean }
  >('Set Extended Hours') {}

  class AcceptWarningForGradeRateCeilingMessage extends actionableFactory.create<
    'Accept Warning For GradeRate Ceiling',
    { grade: number; timeFragment: string | number }
  >('Accept Warning For GradeRate Ceiling') {}

  class ResetGradeJobFragmentsRatesMessage extends actionableFactory.create<
    'Reset Grade JobFragments Rates',
    { grade: number; ceiling: number }
  >('Reset Grade JobFragments Rates') {}

  class SetDefaultApplicationDeadlineMessage extends actionableFactory.create<
    'Set Default Application Deadline',
    { defaultApplicationDeadline: string; customApplicationDeadlineChecked: boolean }
  >('Set Default Application Deadline') {}

  class AddExtraEmailMessage extends actionableFactory.create<
    'Add Extra Email',
    Record<string, never>
  >('Add Extra Email') {}

  class DeleteExtraEmailMessage extends actionableFactory.create<
    'Delete Extra Email',
    { emailIndex: number }
  >('Delete Extra Email') {}

  class ClearExtraEmailsMessage extends actionableFactory.create<
    'Clear Extra Emails',
    Record<string, never>
  >('Clear Extra Emails') {}

  class RemoveFileMessage extends actionableFactory.create<'Remove File', { fileName: string }>(
    'Remove File',
  ) {}

  class UpdateGradeJobFragmentsMessage extends actionableFactory.create<
    'Update Grade Job Fragments Message',
    { payRateType?: number }
  >('Update Grade Job Fragments Message') {}
  class RemoveGradeJobFragmentsMessage extends actionableFactory.create<
    'Remove Grade JobFragments',
    Record<string, never>
  >('Remove Grade JobFragments') {
    timeFragment;
  }
  class AddTimeFragmentMessage extends actionableFactory.create<
    'Add Time Fragment',
    { id: string | number; fromTime: number }
  >('Add Time Fragment') {}

  class UpdateTimeFragmentMessage extends actionableFactory.create<
    'Update Time Fragment',
    { id: string | number; fromTime: number }
  >('Update Time Fragment') {}

  class RemoveTimeFragmentMessage extends actionableFactory.create<
    'Remove Time Fragment',
    { id: string | number }
  >('Remove Time Fragment') {}

  class UpdateGradeFlatRatesMessage extends actionableFactory.create<
    'Update Grade Flat Rate',
    Record<string, never>
  >('Update Grade Flat Rate') {}
  //@todo move this to Entity
  class CreateJobListingMessage extends actionableFactory.create<
    'Create Job Listing',
    Record<string, never>
  >('Create Job Listing') {}

  class UpdateJobFragmentRateMessage extends actionableFactory.create<
    'Update Job Fragment Rate',
    {
      gradeId: number;
      timeFragmentId: string | number;
      rate: string;
      nonResidentCalloutRate: string;
      nonResidentCalloutRateCurrency: string;
    }
  >('Update Job Fragment Rate') {}

  class PublishJobListingMessage extends actionableFactory.create<
    'Publish Job Listing Message',
    Record<string, never>
  >('Publish Job Listing Message') {}

  class UnpublishJobListingMessage extends actionableFactory.create<
    'Unpublish Job Listing Message',
    Record<string, never>
  >('Unpublish Job Listing Message') {}

  class SubmitForAuthorizationJobListingMessage extends actionableFactory.create<
    'Submit For Authorization Job Listing Message',
    Record<string, never>
  >('Submit For Authorization Job Listing Message') {}

  class ChangeRemainingPositionsToFillMessage extends actionableFactory.create<
    'Change Remaining Positions To Fill',
    { delta: number }
  >('Change Remaining Positions To Fill') {}

  class RemoveJobListingGradesMessage extends actionableFactory.create<
    'Remove Grades',
    { grades: number[] }
  >('Remove Grades') {}

  class ResetProfessionDetailsMessage extends actionableFactory.create<
    'Reset profession details',
    Record<string, never>
  >('Reset profession details') {}

  class ResetCostCentreNumberMessage extends actionableFactory.create<
    'Reset Cost Centre Number Message',
    Record<string, never>
  >('Reset Cost Centre Number Message') {}

  class DetailsChangeMessage extends actionableFactory.create<
    'JobListing Details Change',
    Record<string, never>
  >('JobListing Details Change') {}

  class CostCentreNumberChangeMessage extends actionableFactory.create<
    'JobListing Cost Centre Number Change',
    Record<string, never>
  >('JobListing Cost Centre Number Change') {}

  class ReasonForVacancyChangeMessage extends actionableFactory.create<
    'JobListing Reason for Vacancy Change',
    Record<string, never>
  >('JobListing Reason for Vacancy Change') {}

  class SetCostCentreNumberMessage extends actionableFactory.create<
    'Set Cost Centre Number Message',
    { costCentreNumber: string }
  >('Set Cost Centre Number Message') {}

  class ClearJobListingNotesMessage extends actionableFactory.create<
    'Clear Job Listing Notes Message',
    Record<string, never>
  >('Clear Job Listing Notes Message') {}

  return {
    InitializeJobListingFormMessage,
    ResetJobListingFormMessage,
    EnableJobListingGradeMessage,
    DisableJobListingGradeMessage,
    ClearJobListingGradeMessage,
    AddJobListingGradeMessage,
    SetDefaultApplicationDeadlineMessage,
    AddExtraEmailMessage,
    DeleteExtraEmailMessage,
    ClearExtraEmailsMessage,
    UpdateGradeJobFragmentsMessage,
    AddTimeFragmentMessage,
    DisableFlatRateMessage,
    EnableFlatRateMessage,
    EnableProfessionGradesMessage,
    DisableProfessionGradesMessage,
    DisableNonPrimaryProfessionGradesMessage,
    RemoveTimeFragmentMessage,
    UpdateGradeFlatRatesMessage,
    RemoveGradeJobFragmentsMessage,
    ResetGradeJobFragmentsRatesMessage,
    ApplyGradeRateCeilingMessage,
    AcceptWarningForGradeRateCeilingMessage,
    RemoveFileMessage,
    UpdateTimeFragmentMessage,
    SetExtendedHoursMessage,
    PublishJobListingMessage,
    UnpublishJobListingMessage,
    SubmitForAuthorizationJobListingMessage,
    ChangeRemainingPositionsToFillMessage,
    RemoveJobListingGradesMessage,
    ResetProfessionDetailsMessage,
    ResetCostCentreNumberMessage,
    DetailsChangeMessage,
    CostCentreNumberChangeMessage,
    ReasonForVacancyChangeMessage,
    SetCostCentreNumberMessage,
    CreateJobListingMessage,
    ClearJobListingNotesMessage,
    UpdateJobFragmentRateMessage,
  };
}
