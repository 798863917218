import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { isNil, isNumber, isString, range } from 'lodash-es';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, switchMap } from 'rxjs/operators';

import { IQueryParams, PaginatedStateService, Query } from '@locumsnest/core/src';
import { Time, UrlHelpers } from '@locumsnest/core/src/lib/helpers';
import { DATE_FORMAT } from '@locumsnest/core/src/lib/types/constants';

import { HospitalService } from '../../hospital/+state/hospital.services';
import { IHospitalEntity } from '../../interfaces/api/hospital-entity';
import { IProfileEntity } from '../../interfaces/api/profile-entity';
import {
  IStaffBankMembershipEntity,
  IStaffBankMembershipListing,
  IStaffBankMembershipParams,
  IStaffBankMembershipRow,
} from '../../interfaces/api/staff-bank-membership-entity';
import { selectStatsState } from '../../passport-dashboard/+state/stats/stats.reducer';
import { FunFactEnum } from '../../passport-dashboard/containers/interface';
import { ProfessionService } from '../../profession/+state/profession.service';
import { LoadProfilesByIdsSignal } from '../../profile/+state/profile.signals';
import {
  selectSelectedStaffBankMemberships,
  selectSortedField,
  selectStaffBankMembershipsSelectedPage,
} from './search-filter-form/search-filter-form.selectors';
import {
  ResetStaffBankMembershipPaginationMessage,
  StaffBankMembershipMessageTypes,
  StaffBankMembershipPaginationMessages,
  UpsertMultipleMessage,
  UpsertOneMessage,
  UpsertStaffBankMembershipPageMessage,
} from './staff-bank-membership.messages';
import { StaffBankMembershipPersistenceService } from './staff-bank-membership.persistence.service';
import {
  selectAllStaffBankMemberships,
  selectStaffBankMembershipByProfile,
  selectStaffBankMembershipByProfiles,
  selectStaffBankMembershipEntityState,
  selectStaffBankMembershipHospitalsIdsByProfile,
  staffBankMembershipPaginationSelectors,
} from './staff-bank-membership.selectors';

@Injectable({
  providedIn: 'root',
})
export class StaffBankMembershipService extends PaginatedStateService<
  IStaffBankMembershipEntity,
  UpsertStaffBankMembershipPageMessage,
  ResetStaffBankMembershipPaginationMessage,
  UpsertMultipleMessage
> {
  constructor(
    protected store: Store,
    protected persistenceService: StaffBankMembershipPersistenceService,
    private hospitalService: HospitalService,
    private professionService: ProfessionService,
  ) {
    super();
  }

  get paginationMessages() {
    return StaffBankMembershipPaginationMessages;
  }

  get paginationSelectors() {
    return staffBankMembershipPaginationSelectors;
  }

  get entityStateSelector() {
    return selectStaffBankMembershipEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  getAll() {
    return this.store.pipe(select(selectAllStaffBankMemberships));
  }

  getByProfileId(id: string) {
    return this.store.pipe(select(selectStaffBankMembershipByProfile(id)));
  }

  getByProfileIds(ids: string[]) {
    return this.store.pipe(select(selectStaffBankMembershipByProfiles(ids)));
  }

  getHospitalsByProfileId(id: string) {
    return this.store.pipe(
      select(selectStaffBankMembershipHospitalsIdsByProfile(id)),
      mergeMap((ids) => this.hospitalService.getByIds(ids)),
    );
  }

  postLoad(results: IStaffBankMembershipEntity[], loadHospitals = false) {
    if (loadHospitals) {
      return this.hospitalService.loadByIds(results.map((entity) => entity.hospital));
    }
    return of<Action>();
  }

  loadAllWithDependencies(filters: IQueryParams = {}, loadHospitals = false) {
    return this.loadAll(filters).pipe(
      mergeMap((action) =>
        merge(of(action), this.postLoad(action.payload.entities, loadHospitals)),
      ),
    );
  }

  fetch(query?: Query, loadHospitals = false) {
    if (isString(query) || isNumber(query))
      return this.persistenceService
        .retrieve(query)
        .pipe(
          mergeMap((entity) =>
            merge(of(new UpsertOneMessage({ entity })), this.postLoad([entity], loadHospitals)),
          ),
        );

    return this.persistenceService
      .retrieve(query)
      .pipe(
        mergeMap(({ results }) =>
          merge(
            of(new UpsertMultipleMessage({ entities: results })),
            this.postLoad(results, loadHospitals),
          ),
        ),
      );
  }

  loadByProfileId(id, loadHospitals = false) {
    const loadAction$ = this.fetch(
      { profile: id },
      loadHospitals,
    ) as Observable<UpsertMultipleMessage>;

    return loadAction$;
  }

  loadByProfileIds(ids, loadHospitals = false) {
    if (!ids.length) {
      return of<Action>();
    }
    return this.loadAllWithDependencies({ profile: ids }, loadHospitals);
  }

  public isPartOfOurBank(
    profileId: string,
    assignedHospital: IHospitalEntity,
  ): Observable<boolean> {
    return this.getByProfileId(profileId).pipe(
      map(
        (memberships) =>
          assignedHospital &&
          memberships.find((x) => x.hospital === assignedHospital.id) !== undefined,
      ),
    );
  }

  public isProfileOnVisaRestrictions(
    profileId: string,
    assignedHospital: IHospitalEntity,
  ): Observable<boolean> {
    return this.getByProfileId(profileId).pipe(
      map((memberships) =>
        assignedHospital &&
        memberships.find((x) => x.hospital === assignedHospital.id) !== undefined
          ? memberships.find((x) => x.hospital === assignedHospital.id).profileIsOnVisaRestrictions
          : null,
      ),
    );
  }

  getStaffBankMembershipsCurrentPage(namespace: string) {
    return this.store.pipe(
      select(selectStaffBankMembershipsSelectedPage),
      switchMap((pageNumber) => this.getPageEntities(namespace, pageNumber)),
    );
  }

  getSelectedStaffBankMemberships() {
    return this.store.pipe(select(selectSelectedStaffBankMemberships));
  }

  //MD: Return an array of numbers with the page from 1 to the current selected page
  getStaffBankMembershipSelectedPages(): Observable<number[]> {
    return this.store.pipe(
      select(selectStaffBankMembershipsSelectedPage),
      map((currentPage) => range(1, currentPage)),
    );
  }

  //MD: Calculate the loaded rows until the current selected page
  getLoadedRowsCount(namespace: string): Observable<number> {
    return this.getStaffBankMembershipSelectedPages().pipe(
      mergeMap((selectedPages) => this.getMultiPageEntitiesCount(namespace, selectedPages)),
    );
  }

  getSortedField() {
    return this.store.pipe(select(selectSortedField));
  }

  loadOne(id: number): Observable<Action> {
    const loadAction$ = this.fetch(id) as Observable<UpsertOneMessage>;

    return loadAction$;
  }

  loadByIds(ids: number[]) {
    return this.fetch({ id: ids });
  }

  loadAllPagesAndLoadDependencies(namespace: string) {
    return this.loadAllPages(namespace, null).pipe(
      mergeMap((action) => this.loadDependencies(action)),
      mergeMap((x) => x),
    );
  }

  initializePaginationAndLoadDependencies(
    namespace: string,
    staffBankMembershipParams: IStaffBankMembershipParams,
    loadProfile: boolean = false,
  ) {
    let filters: IQueryParams = {};

    for (const param in staffBankMembershipParams) {
      if (staffBankMembershipParams.hasOwnProperty(param)) {
        filters = UrlHelpers.addQueryParams(filters, param, staffBankMembershipParams[param]);
      }
    }

    return this.initializePagination(namespace, {}, filters).pipe(
      mergeMap((action) => this.loadDependencies(action, loadProfile)),
      mergeMap((x) => x),
    );
  }

  loadNextAndLoadDependencies(
    namespace: string,
    staffBankMembershipParams: IStaffBankMembershipParams,
    loadProfile: boolean = false,
  ) {
    let filters: IQueryParams = {};

    for (const param in staffBankMembershipParams) {
      if (staffBankMembershipParams.hasOwnProperty(param)) {
        if (param === 'published' || param === 'cascaded' || param === 'job_status') {
          filters = { ...filters, ...staffBankMembershipParams[param] };
        } else {
          filters = UrlHelpers.addQueryParams(filters, param, staffBankMembershipParams[param]);
        }
      }
    }

    return this.loadNext(namespace, {}, filters).pipe(
      mergeMap((action) => this.loadDependencies(action, loadProfile)),
      mergeMap((x) => x),
    );
  }

  loadPageAndLoadDependencies(
    namespace: string,
    page: number,
    staffBankMembershipParams: IStaffBankMembershipParams,
    loadProfile: boolean = false,
  ) {
    let filters: IQueryParams = {};

    for (const param in staffBankMembershipParams) {
      if (staffBankMembershipParams.hasOwnProperty(param)) {
        filters = UrlHelpers.addQueryParams(filters, param, staffBankMembershipParams[param]);
      }
    }

    return this.loadPage(namespace, {}, page, filters).pipe(
      mergeMap((action) => this.loadDependencies(action, loadProfile)),
      mergeMap((x) => x),
    );
  }

  private loadDependencies(action, loadProfile: boolean = false) {
    const actions: Observable<Action>[] = [of(action)];
    if (action.type === StaffBankMembershipMessageTypes.UPSERT_MULTIPLE) {
      const payload = (action as UpsertMultipleMessage).payload;

      if (loadProfile) {
        const profileIds = payload.entities.map((x) => x.profile);

        if (profileIds.length > 0) actions.push(of(new LoadProfilesByIdsSignal({ profileIds })));
      }
    }

    return actions;
  }

  getProfilesAfterCurrentPageLoad(namespace): Observable<string[]> {
    return this.getStaffBankMembershipsCurrentPage(namespace).pipe(
      mergeMap((staffBankMemberships) => {
        if (!staffBankMemberships.length) {
          return of([]);
        }
        const profileIds = staffBankMemberships.map(
          (staffBankMembership) => staffBankMembership.profile,
        );
        return of(profileIds);
      }),
      distinctUntilChanged(),
    );
  }

  getStaffBankMembershipListCurrentPage(namespace: string, profiles: IProfileEntity[]) {
    return combineLatest([
      this.getStaffBankMembershipsCurrentPage(namespace),
      this.getTotalCount(namespace),
      this.getTotalPages(namespace),
      this.getSelectedStaffBankMemberships(),
      this.professionService.getAll(),
    ]).pipe(
      map(
        ([
          staffBankMemberships,
          totalCount,
          totalPages,
          selectedStaffBankMemberships,
          professions,
        ]): IStaffBankMembershipListing => {
          const staffBankMembershipRows = staffBankMemberships.map((staffBankMembership) => {
            const profile = profiles
              ? profiles.find((x) => x.id === staffBankMembership.profile)
              : null;

            const profileProfession =
              !isNil(profile) && professions
                ? professions.find((x) => x.id === profile.profession)
                : null;

            const profession = profileProfession ? profileProfession.title : null;

            const staffBankMembershipRow: IStaffBankMembershipRow = {
              id: staffBankMembership.id,
              name: profile ? profile.firstName + ' ' + profile.lastName : '',
              payrollNo: staffBankMembership.payrollNumber,
              profileId: profile ? profile.id : '',
              registrationNumber: profile ? profile.registrationNumber : '',
              statusDisplay: profile ? profile.statusDisplay : '',
              staffBankDateAdded:
                staffBankMembership.staffBankDateAdded !== null
                  ? Time.formatDateFromDatePickerFormat(
                      staffBankMembership.staffBankDateAdded,
                      DATE_FORMAT,
                    )
                  : staffBankMembership.createdAt !== null
                    ? Time.formatDate(staffBankMembership.createdAt, DATE_FORMAT)
                    : null,
              hasRegisteredOnApp: staffBankMembership.registeredOnApp,
              selected: selectedStaffBankMemberships.includes(staffBankMembership.id),
              canPractice: profile ? profile.canPractice : false,
              profession,
            };

            return staffBankMembershipRow;
          });

          const staffBankMembershipListing: IStaffBankMembershipListing = {
            staffBankMembershipRows,
            totalCount,
            totalPages,
          };

          return staffBankMembershipListing;
        },
      ),
    );
  }

  getFunFactCount(randomMessageId: number) {
    return this.store.pipe(
      select(selectStatsState),
      map((stats) => {
        switch (randomMessageId) {
          case FunFactEnum.ACTIVE_PEOPLE:
            return stats.sixMonths;
          case FunFactEnum.APPLIED:
            return stats.allTime;
          case FunFactEnum.ENGAGED:
            return stats.oneMonth;
          case FunFactEnum.WORKERS:
            return stats.registeredOnApp;
          default:
            return 0;
        }
      }),
      distinctUntilChanged(),
    );
  }
}
