import {
  box,
  createFormGroupState,
  createFormStateReducerWithUpdate,
  FormGroupState,
  setValue,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { maxLength } from 'ngrx-forms/validation';

import { ITagFormState } from '../interfaces/tag-form-state';
import {
  ClearDeleteModeSelectedTagsMessage,
  ClearSelectedTagsMessage,
  ClearTagSearchMessage,
  InitializeTagFormMessage,
  ResetMaxTagsErrorMessage,
  SetMaxTagsErrorMessage,
  TagWidgetUiMessages,
  ToggleMyTagsDeleteModeMessage,
  ToggleOrganisationTagsDeleteModeMessage,
} from './tag-form.messages';

export const FORM_ID = 'TAG_FORM';
export type State = FormGroupState<ITagFormState>;

const INITIAL_FORM_STATE = createFormGroupState<ITagFormState>(FORM_ID, {
  searchKeyword: '',
  selectedTags: box([]),
  showMyList: false,
  invitationMessage: '',
  initialSelectedTagsState: box([]),
  deleteModeSelectedTags: box([]),
  maxTagsError: false,
  myTagsDeleteMode: false,
  organisationTagsDeleteMode: false,
  eligibilityWarning: false,
});

const formValidationReducer = (formState: FormGroupState<ITagFormState>) =>
  updateGroup<ITagFormState>({
    invitationMessage: validate<string>([maxLength(1024)]),
  })(formState);

const formStateReducer = createFormStateReducerWithUpdate<ITagFormState>([]);

export function formReducer(
  state: FormGroupState<ITagFormState> = INITIAL_FORM_STATE,
  action: TagWidgetUiMessages,
) {
  switch (action.type) {
    case InitializeTagFormMessage.TYPE: {
      if (action.payload.initialValues)
        return createFormGroupState<ITagFormState>(FORM_ID, { ...action.payload.initialValues });

      return { ...INITIAL_FORM_STATE };
    }

    case ClearSelectedTagsMessage.TYPE:
      state = updateGroup<ITagFormState>({
        selectedTags: setValue(box([] as number[])),
      })(state);
      break;

    case ClearDeleteModeSelectedTagsMessage.TYPE:
      state = updateGroup<ITagFormState>({
        deleteModeSelectedTags: setValue(box([] as number[])),
      })(state);
      break;

    case ClearTagSearchMessage.TYPE:
      state = updateGroup<ITagFormState>({
        searchKeyword: setValue(''),
      })(state);
      break;

    case SetMaxTagsErrorMessage.TYPE:
      state = updateGroup<ITagFormState>({
        maxTagsError: setValue(true),
      })(state);
      break;

    case ResetMaxTagsErrorMessage.TYPE:
      state = updateGroup<ITagFormState>({
        maxTagsError: setValue(false),
      })(state);
      break;

    case ToggleMyTagsDeleteModeMessage.TYPE:
      state = updateGroup<ITagFormState>({
        myTagsDeleteMode: setValue(!state.value.myTagsDeleteMode),
      })(state);
      break;

    case ToggleOrganisationTagsDeleteModeMessage.TYPE:
      state = updateGroup<ITagFormState>({
        organisationTagsDeleteMode: setValue(!state.value.organisationTagsDeleteMode),
      })(state);
      break;
  }

  state = formStateReducer(state, action);
  state = formValidationReducer(state);

  return state;
}

export function reducer(state: State, action: TagWidgetUiMessages) {
  return formReducer(state, action);
}
