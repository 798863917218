import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';

import { StateService } from '@locumsnest/core';
import { filterNonEmpty } from '@locumsnest/core/src/lib/ngrx/operators';

import { IStaffBankUnavailabilityReasonEntity } from '../../interfaces/api/staff-bank-unavailability-reason-entity';
import { SetCollectionMessage } from './staff-bank-unavailability-reason.messages';
import { StaffBankUnavailabilityReasonPersistenceService } from './staff-bank-unavailability-reason.persistence.service';
import {
  selectAllStaffBankUnavailabilityReasons,
  selectStaffBankUnavailabilityReasonOptions,
} from './staff-bank-unavailability-reason.selectors';

@Injectable({
  providedIn: 'root',
})
export class StaffBankUnavailabilityReasonService extends StateService<IStaffBankUnavailabilityReasonEntity> {
  constructor(
    private store: Store,
    protected persistenceService: StaffBankUnavailabilityReasonPersistenceService
  ) {
    super();
  }

  getAll() {
    return this.store.pipe(select(selectAllStaffBankUnavailabilityReasons), filterNonEmpty);
  }

  getStaffBankUnavailabilityReasonOptions() {
    return this.store.pipe(
      select(selectStaffBankUnavailabilityReasonOptions),
      filter((options) => options && options.length > 0)
    );
  }

  fetch() {
    return this.persistenceService
      .retrieve()
      .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));
  }
}
