import { StaffBankMembershipState } from '../interfaces';
import { FormGroupState } from 'ngrx-forms';
import { createSelector } from '@ngrx/store';
import { IStaffBankMembershipSearchFilterFormState } from '../interfaces/staff-bank-membership-search-filter-form-state';
import { selectStaffBankMembershipState } from '../../../staff-bank-membership/+state/staff-bank-membership.selectors';
import { alertStateAdapter } from './search-filter-form.adapter';

export const getSearchFilterFormState = (state: StaffBankMembershipState) =>
  state.searchFilterFormState;

const getStaffBankMembershipsSelectedPage = (
  formState: FormGroupState<IStaffBankMembershipSearchFilterFormState>
) => formState.value.selectedPage;

const getSelectedStaffBankMemberships = (
  formState: FormGroupState<IStaffBankMembershipSearchFilterFormState>
) => formState.value.selectedStaffBankMemberships;

const getSearchKeyword = (formState: FormGroupState<IStaffBankMembershipSearchFilterFormState>) =>
  formState.value.searchKeyword;

const getSortedField = (formState: FormGroupState<IStaffBankMembershipSearchFilterFormState>) =>
  formState.value.sortedField;

const selectors = alertStateAdapter.getSelectors('alertState');

export const selectStaffBankMembershipSearchFilterFormState = createSelector(
  selectStaffBankMembershipState,
  getSearchFilterFormState
);

export const selectStaffBankMembershipsSelectedPage = createSelector(
  selectStaffBankMembershipSearchFilterFormState,
  getStaffBankMembershipsSelectedPage
);

export const selectSelectedStaffBankMemberships = createSelector(
  selectStaffBankMembershipSearchFilterFormState,
  getSelectedStaffBankMemberships
);

export const selectSearchKeyword = createSelector(
  selectStaffBankMembershipSearchFilterFormState,
  getSearchKeyword
);

export const selectStaffBankMembershipsAlertToDisplay = createSelector(
  selectStaffBankMembershipSearchFilterFormState,
  selectors.selectAlertState
);

export const selectSortedField = createSelector(
  selectStaffBankMembershipSearchFilterFormState,
  getSortedField
);
