import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

// @todo  implement configuration service once we settle
@Injectable()
export class ConfigurationService {
  constructor(private store: Store) {}

  defaultsToFlatRate() {
    return of(false);
  }
}
