import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { IDeclineApplicationReasonEntity } from '../../interfaces/api/decline-application-reason-entity';

export enum DeclineApplicationReasonSignalTypes {
  LOAD_DECLINE_APPLICATION_REASONS = '[DeclineApplicationReason][Signal] Load Collection',
}
export enum DeclineApplicationReasonMessageTypes {
  SET_COLLECTION = '[DeclineApplicationReason][Message] Set Collection',
  ADD_ONE = '[DeclineApplicationReason][Message] Add One',
  UPDATE_ONE = '[DeclineApplicationReason][Message] Update One',
  UPSERT_ONE = '[DeclineApplicationReason][Message] Upsert One',
  DELETE_ONE = '[DeclineApplicationReason][Message] Delete One',
  ADD_MULTIPLE = '[DeclineApplicationReason][Message] Add All',
  DELETE_MULTIPLE = '[DeclineApplicationReason][Message] Delete Many',
  UPSERT_MULTIPLE = '[DeclineApplicationReason][Message] Upsert Many',
  UPDATE_MULTIPLE = '[DeclineApplicationReason][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = DeclineApplicationReasonMessageTypes.SET_COLLECTION;
  constructor(public payload: { declineApplicationReasons: IDeclineApplicationReasonEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = DeclineApplicationReasonMessageTypes.ADD_ONE;

  constructor(public payload: { declineApplicationReason: IDeclineApplicationReasonEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = DeclineApplicationReasonMessageTypes.UPSERT_ONE;

  constructor(public payload: { declineApplicationReason: IDeclineApplicationReasonEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = DeclineApplicationReasonMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { declineApplicationReasons: IDeclineApplicationReasonEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = DeclineApplicationReasonMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { declineApplicationReasons: IDeclineApplicationReasonEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = DeclineApplicationReasonMessageTypes.UPDATE_ONE;

  constructor(
    public payload: { declineApplicationReason: Update<IDeclineApplicationReasonEntity> }
  ) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = DeclineApplicationReasonMessageTypes.UPDATE_MULTIPLE;

  constructor(
    public payload: { declineApplicationReasons: Update<IDeclineApplicationReasonEntity>[] }
  ) {}
}

export class DeleteOneMessage implements Action {
  readonly type = DeclineApplicationReasonMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = DeclineApplicationReasonMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type DeclineApplicationReasonMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
