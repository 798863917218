import { createSelector } from '@ngrx/store';

import { State } from '../interfaces';
import { selectLayoutState } from '../layout.selectors';
import { alertStateAdapter } from './ui.adapter';

// #region getSelectors

export const getUi = (state: State) => state.uiState;

// #endregion getSelectors

// #region selectSelectors

export const selectLayoutUi = createSelector(selectLayoutState, getUi);

export const selectLayoutUiState = createSelector(selectLayoutUi, ({ state }) => state);

export const selectShowHeaderFilterInformation = createSelector(
  selectLayoutUiState,
  (layoutUiState) => layoutUiState.showHeaderFilterInformation,
);

export const selectShowFiltersPanel = createSelector(
  selectLayoutUiState,
  (layoutUiState) => layoutUiState.showFiltersPanel,
);

export const selectShowProductPanel = createSelector(
  selectLayoutUiState,
  (layoutUiState) => layoutUiState.showProductPanel,
);

const { selectAlertState } = alertStateAdapter.getSelectors('alertState');

export const selectLayoutUiAlertState = createSelector(selectLayoutUi, selectAlertState);
// #endregion selectSelectors
