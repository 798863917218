import { createSelector } from '@ngrx/store';

import { selectStaffBankMembershipRequestState } from '../../../staff-bank-membership-request/+state/staff-bank-membership-request.selectors';

export const selectStaffBankMembershipRequestsSearchFilterFormState = createSelector(
  selectStaffBankMembershipRequestState,
  (state) => state.searchFilterFormState,
);

export const selectStaffBankMembershipRequestsSelectedPage = createSelector(
  selectStaffBankMembershipRequestsSearchFilterFormState,
  (state) => state.value.selectedPage,
);

export const selectSelectedStaffBankMembershipRequests = createSelector(
  selectStaffBankMembershipRequestsSearchFilterFormState,
  (state) => state.value.selectedStaffBankMembershipRequests,
);

export const selectMembershipRequestStatus = createSelector(
  selectStaffBankMembershipRequestsSearchFilterFormState,
  (state) => state.value.membershipRequestStatus,
);

export const selectExpandedStaffBankRequests = createSelector(
  selectStaffBankMembershipRequestsSearchFilterFormState,
  (state) => state.value.expandedStaffBankRequests,
);

export const selectRejectedStaffBankRequests = createSelector(
  selectStaffBankMembershipRequestsSearchFilterFormState,
  (state) => state.value.rejectedRequests,
);

export const selectNotesFormState = createSelector(
  selectStaffBankMembershipRequestsSearchFilterFormState,
  (state) => state.controls.notesFormState,
);

export const selectRejectFormState = createSelector(
  selectStaffBankMembershipRequestsSearchFilterFormState,
  (state) => state.controls.rejectedRequestFormState,
);

export const selectCollaborativeMemberFilterNotAll = createSelector(
  selectStaffBankMembershipRequestsSearchFilterFormState,
  (state) => state.value.collaborativeMember !== 1,
);
