import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { isString } from 'lodash-es';
import { map, mergeMap, Observable, of } from 'rxjs';

import { IQueryParams, PaginatedSubresourceStateService, RequestOptions } from '@locumsnest/core';

import { HospitalService } from '../../hospital/+state/hospital.services';
import { IJobPositionEntity } from '../../interfaces/api/job-position-entity';
import {
  ResetJobPositionPaginationMessage,
  SetCollectionMessage,
  UpsertJobPositionPageMessage,
  UpsertMultipleMessage,
} from './job-position.messages';
import { JobPositionPersistenceService } from './job-position.persistence.service';
import {
  jobPositionPaginationSelectors,
  selectAllJobPositions,
  selectJobPositionEntityState,
  selectJobPositionsByProfileId,
} from './job-position.selectors';

export interface JobPositionRequestOptions {
  profileId: string;
}

@Injectable({
  providedIn: 'root',
})
export class JobPositionService extends PaginatedSubresourceStateService<
  IJobPositionEntity,
  UpsertJobPositionPageMessage,
  ResetJobPositionPaginationMessage,
  UpsertMultipleMessage
> {
  constructor(
    protected store: Store,
    protected persistenceService: JobPositionPersistenceService,
    protected hospitalService: HospitalService,
  ) {
    super();
  }

  get upsertMessageClass() {
    return UpsertJobPositionPageMessage;
  }
  get clearMessageClass() {
    return ResetJobPositionPaginationMessage;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return jobPositionPaginationSelectors;
  }

  get entityStateSelector() {
    return selectJobPositionEntityState;
  }

  getAll() {
    return this.store.pipe(select(selectAllJobPositions));
  }

  loadAllPagesAndLoadEmployers(
    namespace: string,
    profileId: string,
    requestOptions: RequestOptions<JobPositionRequestOptions>,
    filters: IQueryParams = {},
  ) {
    return this.loadAllPages(namespace, profileId, requestOptions, filters);
  }

  hasJobPositions(profileId: string): Observable<boolean> {
    return this.store.pipe(
      select(selectJobPositionsByProfileId(profileId)),
      map((jobPositions) => jobPositions.length > 0),
    );
  }

  fetch(profileId?: string, loadEmployers = false) {
    const mapResults = mergeMap(({ results: entities }) => {
      const actions: Observable<Action>[] = [of(new SetCollectionMessage({ entities }))];
      // add action to load employers
      if (loadEmployers) {
        const employers: number[] = [];
        for (const job of entities) {
          employers.push(job.employer);
        }
        actions.push(this.hospitalService.loadByIds(employers));
      }

      return actions;
    });

    if (isString(profileId))
      return this.persistenceService.retrieve(null, { pathParams: { profileId } }).pipe(
        mapResults,
        mergeMap((x) => x),
      );

    return this.persistenceService.retrieve().pipe(
      mapResults,
      mergeMap((x) => x),
    );
  }

  getRequestOptions(profileId: string): RequestOptions<JobPositionRequestOptions> {
    return { pathParams: { profileId } };
  }
}
