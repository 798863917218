import { signalableFactory as actionableFactory } from './ui.adapter';

export class InitializePassportDashboardSignal extends actionableFactory.create<
  'Initialize Passport Dashboard',
  { namespace: string; pendingAlertsNamespace: string }
>('Initialize Passport Dashboard') {}

export class InitializePassportDashboardBannerWidgetSignal extends actionableFactory.create<
  'Initialize Passport Dashboard Banner',
  { namespace: string; status: string }
>('Initialize Passport Dashboard Banner') {}

export class InitializePassportDashboardFunFactBannerWidgetSignal extends actionableFactory.create<
  'Initialize Passport Dashboard Fun Fact Banner',
  Record<string, never>
>('Initialize Passport Dashboard Fun Fact Banner') {}

export class NavigateToStaffBankRequestSignal extends actionableFactory.create<
  'Navigate To StaffBank Request Signal',
  { status?: string; profile?: string }
>('Navigate To StaffBank Request Signal') {}

export class ShowHideAlertListUiSignal extends actionableFactory.create<
  'Show Hide Alert List Ui',
  { show: boolean }
>('Show Hide Alert List Ui') {}

export class ShowHideDiagnosticUiSignal extends actionableFactory.create<
  'Show Hide Diagnostic Ui',
  { show: boolean }
>('Show Hide Diagnostic Ui') {}

export class MarkCertificateAsActionedSignal extends actionableFactory.create<
  'Mark Certificate As Actioned',
  { certificateId: string }
>('Mark Certificate As Actioned') {}

export class MarkAsReadSignal extends actionableFactory.create<'Mark As Read', { id: number }>(
  'Mark As Read'
) {}

export class LoadMorePassportAlertsSignal extends actionableFactory.create<
  'Load More Passport Alerts',
  { namespace: string }
>('Load More Passport Alerts') {}
