import {
  addArrayControl,
  addGroupControl,
  createFormGroupState,
  createFormStateReducerWithUpdate,
  FormGroupState,
  removeArrayControl,
  removeGroupControl,
  setValue,
  SetValueAction,
  updateGroup,
} from 'ngrx-forms';

import { INoteComponentState } from '@locumsnest/core/src/lib/adapters/note-component-adapter';
import { DEFAULT_INITIAL_FORM_STATE } from '@locumsnest/core/src/lib/adapters/note-component-adapter/note-component-state';

import { IStaffBankMembershipRequestEntity } from '../../../interfaces/api/staff-bank-membership-request-entity';
import {
  INoteFormState,
  IStaffBankMembershipRequestsSearchFilterFormState,
} from '../interfaces/staff-bank-membership-requests-search-filter-form-state';
import {
  AddExpandedStaffBankRequestMessage,
  AddRejectMembershipMessage,
  AddSelectedStaffBankMembershipRequestMessage,
  ClearProfileNoteMessage,
  ClearSelectedStaffBankMembershipRequestsMessage,
  InitializeSearchFilterFormMessage,
  RemoveExpandedStaffBankRequestMessage,
  RemoveRejectMembershipMessage,
  RemoveSelectedStaffBankMembershipRequestMessage,
  SelectAllCurrentPageMessage,
  StaffBankMembershipRequestsSearchFilterFormMessages,
  UnselectAllCurrentPageMessage,
  UpdateSelectedPageMessage,
} from './staff-bank-membership-requests-search-filter-form.messages';
import { rejectionValidation } from './staff-bank-membership-requests-search-filter-form.validators';

export * from './staff-bank-membership-requests-search-filter-form.selectors';
export const FORM_ID = 'STAFF_BANK_MEMBERSHIP_REQUESTS_SEARCH_FILTER_FORM';
export type State = FormGroupState<IStaffBankMembershipRequestsSearchFilterFormState>;

export const INITIAL_FORM_VALUES: IStaffBankMembershipRequestsSearchFilterFormState = {
  selectedPage: 1,
  selectedStaffBankMembershipRequests: [],
  searchKeyword: null,
  membershipRequestStatus: null,
  expandedStaffBankRequests: [],
  rejectedRequests: [],
  notesFormState: {},
  specialistDate: 1,
  unlistedMember: 1,
  collaborativeMember: 1,
  rejectedRequestFormState: {},
};

export const INITIAL_FORM_STATE =
  createFormGroupState<IStaffBankMembershipRequestsSearchFilterFormState>(
    FORM_ID,
    INITIAL_FORM_VALUES,
  );

const formValidationReducer = (
  formState: FormGroupState<IStaffBankMembershipRequestsSearchFilterFormState>,
) =>
  updateGroup<IStaffBankMembershipRequestsSearchFilterFormState>({
    rejectedRequestFormState: rejectionValidation,
  })(formState);

export const formStateReducer =
  createFormStateReducerWithUpdate<IStaffBankMembershipRequestsSearchFilterFormState>([]);

function findIndex(selectedStaffBankMembershipRequest: number[], id: number): number {
  return selectedStaffBankMembershipRequest.findIndex((x) => x === id);
}

export function formReducer(
  state: FormGroupState<IStaffBankMembershipRequestsSearchFilterFormState> = INITIAL_FORM_STATE,
  action: StaffBankMembershipRequestsSearchFilterFormMessages | SetValueAction<string | number>,
) {
  let staffBankMembershipRequests: IStaffBankMembershipRequestEntity[] = [];

  switch (action.type) {
    case InitializeSearchFilterFormMessage.TYPE:
      state = createFormGroupState<IStaffBankMembershipRequestsSearchFilterFormState>(
        FORM_ID,
        (action as InitializeSearchFilterFormMessage).payload.searchFilterFormState,
      );
      state = updateGroup<IStaffBankMembershipRequestsSearchFilterFormState>({})(state);
      break;
    case UpdateSelectedPageMessage.TYPE:
      state = updateGroup<IStaffBankMembershipRequestsSearchFilterFormState>({
        selectedPage: setValue((action as UpdateSelectedPageMessage).payload.selectedPage),
      })(state);
      break;
    case AddSelectedStaffBankMembershipRequestMessage.TYPE:
      state = updateGroup<IStaffBankMembershipRequestsSearchFilterFormState>({
        selectedStaffBankMembershipRequests: addArrayControl(
          (action as AddSelectedStaffBankMembershipRequestMessage).payload.id,
        ),
      })(state);
      break;
    case RemoveSelectedStaffBankMembershipRequestMessage.TYPE:
      state = updateGroup<IStaffBankMembershipRequestsSearchFilterFormState>({
        selectedStaffBankMembershipRequests: removeArrayControl(
          findIndex(
            state.controls.selectedStaffBankMembershipRequests.value,
            (action as RemoveSelectedStaffBankMembershipRequestMessage).payload.id,
          ),
        ),
      })(state);
      break;
    case SelectAllCurrentPageMessage.TYPE:
      staffBankMembershipRequests = (action as SelectAllCurrentPageMessage).payload
        .staffBankMembershipRequests;

      staffBankMembershipRequests.forEach((staffBankMembership) => {
        if (
          findIndex(
            state.controls.selectedStaffBankMembershipRequests.value,
            staffBankMembership.id,
          ) < 0
        ) {
          state = updateGroup<IStaffBankMembershipRequestsSearchFilterFormState>({
            selectedStaffBankMembershipRequests: addArrayControl(staffBankMembership.id),
          })(state);
        }
      });
      break;
    case UnselectAllCurrentPageMessage.TYPE:
      staffBankMembershipRequests = (action as UnselectAllCurrentPageMessage).payload
        .staffBankMembershipRequests;

      staffBankMembershipRequests.forEach(
        (staffBankMembership) =>
          (state = updateGroup<IStaffBankMembershipRequestsSearchFilterFormState>({
            selectedStaffBankMembershipRequests: removeArrayControl(
              findIndex(
                state.controls.selectedStaffBankMembershipRequests.value,
                staffBankMembership.id,
              ),
            ),
          })(state)),
      );
      break;
    case ClearSelectedStaffBankMembershipRequestsMessage.TYPE:
      state = updateGroup<IStaffBankMembershipRequestsSearchFilterFormState>({
        selectedStaffBankMembershipRequests: (values) => {
          for (const value of values.value) {
            values = removeArrayControl(0)(values);
          }
          return values;
        },
      })(state);
      break;
    case AddExpandedStaffBankRequestMessage.TYPE:
      if (
        findIndex(
          state.value.expandedStaffBankRequests,
          (action as AddExpandedStaffBankRequestMessage).payload.id,
        ) < 0
      ) {
        const rowId = (action as AddExpandedStaffBankRequestMessage).payload.id;
        state = updateGroup<IStaffBankMembershipRequestsSearchFilterFormState>({
          expandedStaffBankRequests: addArrayControl(rowId),
          notesFormState: addGroupControl<{ [id: number]: INoteComponentState }>(
            rowId,
            DEFAULT_INITIAL_FORM_STATE,
          ),
        })(state);
      }
      break;

    case AddRejectMembershipMessage.TYPE: {
      const rowId = (action as AddRejectMembershipMessage).payload.id;
      state = updateGroup<IStaffBankMembershipRequestsSearchFilterFormState>({
        rejectedRequests: addArrayControl(rowId),
        rejectedRequestFormState: addGroupControl(rowId, {
          rejectDetails: '',
          rejectReason: null,
          isOtherReasonSelected: false,
        }),
      })(state);

      break;
    }

    case RemoveRejectMembershipMessage.TYPE: {
      const rowId = (action as RemoveRejectMembershipMessage).payload.id;
      state = updateGroup<IStaffBankMembershipRequestsSearchFilterFormState>({
        rejectedRequests: removeArrayControl(
          findIndex(state.controls.rejectedRequests.value, rowId),
        ),
        rejectedRequestFormState: removeGroupControl(rowId),
      })(state);
      break;
    }

    case RemoveExpandedStaffBankRequestMessage.TYPE: {
      const rowId = (action as RemoveExpandedStaffBankRequestMessage).payload.id;
      const index = findIndex(state.controls.expandedStaffBankRequests.value, rowId);
      if (index === -1) break;
      state = updateGroup<IStaffBankMembershipRequestsSearchFilterFormState>({
        expandedStaffBankRequests: removeArrayControl(index),
        notesFormState: removeGroupControl<{ [id: number]: INoteComponentState }>(rowId as number),
      })(state);
      break;
    }
    case ClearProfileNoteMessage.TYPE: {
      const requestId = action.payload.id;

      state = updateGroup<IStaffBankMembershipRequestsSearchFilterFormState>({
        notesFormState: updateGroup<INoteFormState>({
          [requestId]: updateGroup<INoteComponentState>({
            note: setValue(''),
          }),
        }),
      })(state);
      break;
    }
  }

  state = formStateReducer(state, action);
  state = formValidationReducer(state);

  return state;
}

export function reducer(state: State, action) {
  return formReducer(state, action);
}
