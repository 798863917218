import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get } from 'lodash-es';

import { ISelectOption } from '@locumsnest/components/src/lib/interfaces';

import { IGmcStatusEntity } from '../../interfaces/api/gmc-status-entity';
import { selectAll } from './gmc-status.reducer';
import { IGmcStatusEntityState, IGmcStatusFeatureState, IGmcStatusSharedState } from './interfaces';

export const getEntityState = (state: { entityState: IGmcStatusEntityState }) => state.entityState;

export const getGmcStatusOptions = (gmcStatuses: IGmcStatusEntity[]): ISelectOption[] =>
  gmcStatuses
    .map((gmcStatus) => ({
      id: gmcStatus.val,
      name: gmcStatus.display,
      disabled: gmcStatus.code === 'OTHER',
    }))
    .sort((a, b) => +get(a, 'disabled', false) - +get(b, 'disabled', false));

export const getGmcStatus = (gmcStatusId: number) => (gmcStatuses: IGmcStatusEntity[]) => {
  const gmcStatus = gmcStatuses.find((status) => status.val === gmcStatusId);

  if (gmcStatus) {
    return gmcStatus.display;
  }
  return '';
};

export const getGmcStatusCodeById = (gmcStatusId: number) => (gmcStatuses: IGmcStatusEntity[]) => {
  const gmcStatus = gmcStatuses.find((status) => status.val === gmcStatusId);

  if (gmcStatus) {
    return gmcStatus.code;
  }
  return '';
};

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectGmcStatusState = createFeatureSelector<
  IGmcStatusFeatureState & IGmcStatusSharedState
>('gmcStatuses');
export const selectGmcStatusEntityState = createSelector(selectGmcStatusState, getEntityState);

export const selectAllGmcStatuses = createSelector(selectGmcStatusEntityState, selectAll);

export const selectGmcStatusOptions = createSelector(selectAllGmcStatuses, getGmcStatusOptions);

export const selectGmcStatus = (gmcStatusId) =>
  createSelector(selectAllGmcStatuses, getGmcStatus(gmcStatusId));

export const selectGmcStatusCodeById = (gmcStatusId) =>
  createSelector(selectAllGmcStatuses, getGmcStatusCodeById(gmcStatusId));
