import { MessageableFactory } from '@locumsnest/core/src';
import { SignalableFactory } from '@locumsnest/core/src';
import { createExpandableStateAdapter } from '@locumsnest/core/src/lib/adapters/expandable-state-adapter';

const namespace = 'PreMatchForm';

export const messageableFactory = MessageableFactory.forFeature<typeof namespace>(namespace);
export const signalableFactory = SignalableFactory.forFeature<typeof namespace>(namespace);
export const expandableStateAdapter = createExpandableStateAdapter<typeof namespace>(
  signalableFactory,
  messageableFactory
);
export const { ToggleExpandableSignal, SetExpandExpandableSignal } =
  expandableStateAdapter.getSignals();
