import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IStaffingCascadeTimeWindowEntity } from '../../interfaces/api/staffing-cascade-time-window-entity';

@Injectable({
  providedIn: 'root',
})
export class StaffingCascadeTimeWindowPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IStaffingCascadeTimeWindowEntity
> {
  protected readonly endpoint = 'staffingCascadeTimeWindow';
}
