import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src';
import { createEntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';

import { IExternalStaffingProviderEntity } from '../../interfaces/api/external-staffing-provider-entity';

const namespace = 'ExternalStaffingProvider';

export function sortByName(
  a: IExternalStaffingProviderEntity,
  b: IExternalStaffingProviderEntity
): number {
  return a.id - b.id;
}

const adapter = createEntityAdapter<IExternalStaffingProviderEntity>({
  sortComparer: sortByName,
});

const initialState = adapter.getInitialState({
  selectedExternalStaffingProviderId: null,
  assignedExternalStaffingProviderId: null,
});

const messageableFactory = MessageableFactory.forFeature<typeof namespace>(namespace);
const signalableFactory = SignalableFactory.forFeature<typeof namespace>(namespace);
const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export { adapter, initialState, paginationAdapter };
