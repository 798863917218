import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src';
import { createEntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';

import { IHospitalProfileLabelEntity } from '../../interfaces/api/hospital-profile-label-entity';

const namespace = 'HospitalProfileLabel';

const adapter = createEntityAdapter<IHospitalProfileLabelEntity>({
  selectId: (entity) => entity.id,
});

const messageableFactory = MessageableFactory.forFeature<typeof namespace>(namespace);
const signalableFactory = SignalableFactory.forFeature<typeof namespace>(namespace);
const initialState = adapter.getInitialState({});
const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export { adapter, initialState, paginationAdapter };
