import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { IReferenceEntity } from '../../interfaces/api/reference-entity';
import { paginationAdapter } from './reference.adapter';

export const { UpsertPageMessage, ResetPaginationMessage } = paginationAdapter.getMessages();

export class UpsertReferencePageMessage extends UpsertPageMessage {}
export class ResetReferencePaginationMessage extends ResetPaginationMessage {}

export enum ReferenceSignalTypes {
  LOAD_REFERENCES = '[Reference][Signal] Load Collection',
}
export enum ReferenceMessageTypes {
  SET_COLLECTION = '[Reference][Message] Set Collection',
  ADD_ONE = '[Reference][Message] Add One',
  UPDATE_ONE = '[Reference][Message] Update One',
  UPSERT_ONE = '[Reference][Message] Upsert One',
  DELETE_ONE = '[Reference][Message] Delete One',
  ADD_MULTIPLE = '[Reference][Message] Add All',
  DELETE_MULTIPLE = '[Reference][Message] Delete Many',
  UPSERT_MULTIPLE = '[Reference][Message] Upsert Many',
  UPDATE_MULTIPLE = '[Reference][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = ReferenceMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IReferenceEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = ReferenceMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IReferenceEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = ReferenceMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IReferenceEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = ReferenceMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IReferenceEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = ReferenceMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IReferenceEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = ReferenceMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IReferenceEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = ReferenceMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IReferenceEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = ReferenceMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = ReferenceMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type ReferenceMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | UpsertReferencePageMessage
  | ResetReferencePaginationMessage;
