import {
  messageableFactory as actionableFactory,
  alertStateAdapter,
} from './alert-search-filter-form.adapter';
import { IAlertSearchFilterFormState } from '../interfaces/alert-search-filter-form-state';

// initialization of mixin factory
export const {
  ResetErrorMessage: ResetErrorAdapterMessage,
  AlertErrorMessage: AlertErrorAdapterMessage,
} = alertStateAdapter.getMessages();
export class ResetErrorMessage extends ResetErrorAdapterMessage {}
export class AlertErrorMessage extends AlertErrorAdapterMessage {}

export class InitializeAlertSearchFilterFormMessage extends actionableFactory.create<
  'Initialize Alert Search Filter Form',
  { alertSearchFilterFormState: IAlertSearchFilterFormState }
>('Initialize Alert Search Filter Form') {}

export class UpdateSortedFieldMessage extends actionableFactory.create<
  'Update Sorted Field Message',
  { sortedField: string }
>('Update Sorted Field Message') {}

export type AlertSearchFilterFormMessages =
  | InitializeAlertSearchFilterFormMessage
  | ResetErrorMessage
  | AlertErrorMessage
  | UpdateSortedFieldMessage;
