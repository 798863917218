import { ITRemoveFilePayload } from '../interfaces';
import {
  IAddDayOnFragmentPayload,
  IAddDayOnTimeSheetPayload,
  IApprovalChangePayload,
  IChangeFragmentTypePayload,
  IDeleteFragmentPayload,
} from '../interfaces/time-sheet-form-state';
import {
  signalableFactory as actionableFactory,
  alertStateAdapter,
  ToggleExpandableSignal,
} from './form.adapter';

const { ResetErrorSignal } = alertStateAdapter.getSignals();

export class InitializeTimeSheetFormSignal extends actionableFactory.create<
  'Initialize TimeSheet Form',
  { namespace: string }
>('Initialize TimeSheet Form') {}

export class ResetTimeSheetFormSignal extends actionableFactory.create<
  'Reset TimeSheet Form',
  Record<string, never>
>('Reset TimeSheet Form') {}

export class InitializeAgencyTimeSheetFormSignal extends actionableFactory.create<
  'Initialize Agency TimeSheet Form',
  Record<string, never>
>('Initialize Agency TimeSheet Form') {}

export class ToggleExpandableTimeSheetSignal extends ToggleExpandableSignal {}

export type JobListingFormSignals = InitializeTimeSheetFormSignal | ToggleExpandableTimeSheetSignal;

export class RemoveFileSignal extends actionableFactory.create<'Remove File', ITRemoveFilePayload>(
  'Remove File',
) {}

export class ApprovalChangeSignal extends actionableFactory.create<
  'Approval Change',
  IApprovalChangePayload
>('Approval Change') {}

export class SubmitFormSignal extends actionableFactory.create<'Submit Form', void>(
  'Submit Form',
) {}

export class AddDayOnFragmentSignal extends actionableFactory.create<
  'Add Days to Fragment',
  IAddDayOnFragmentPayload
>('Add Days to Fragment') {}

export class DeleteFragmentSignal extends actionableFactory.create<
  'Delete Fragment',
  IDeleteFragmentPayload
>('Delete Fragment') {}

export class RemoveFlatRateSignal extends actionableFactory.create<'Remove Flat Rate', void>(
  'Remove Flat Rate',
) {}

export class AddFragmentSignal extends actionableFactory.create<'Add Fragment', void>(
  'Add Fragment',
) {}

export class ResetTimeSheetStateToInitialValuesSignal extends actionableFactory.create<
  'Reset Time Sheet State To Initial Values',
  void
>('Reset Time Sheet State To Initial Values') {}

export class ChangeFragmentTypeSignal extends actionableFactory.create<
  'Change Fragment Type',
  IChangeFragmentTypePayload
>('Change Fragment Type') {}

export class AddDayOnTimeSheetSignal extends actionableFactory.create<
  'Add Day On Start',
  IAddDayOnTimeSheetPayload
>('Add Day On Start') {}

export class ShowResetMessageSignal extends actionableFactory.create<'Show Reset Message', void>(
  'Show Reset Message',
) {}

export class AgencyConfirmSignal extends actionableFactory.create<
  'Agency Confirm Signal',
  Record<string, never>
>('Agency Confirm Signal') {}

export class AgencyRevertConfirmSignal extends actionableFactory.create<
  'Agency Revert Confirm Signal',
  Record<string, never>
>('Agency Revert Confirm Signal') {}

export class ToggleRecentActivitySignal extends actionableFactory.create<
  'Toggle Recent Activity Signal',
  Record<'value', boolean>
>('Toggle Recent Activity Signal') {}

export class AddTimesheetNoteSignal extends actionableFactory.create<
  'Add Timesheet Note Signal',
  { alert?: boolean }
>('Add Timesheet Note Signal') {}

export class PrefillCostCentreNumberSignal extends actionableFactory.create<
  'Prefill Cost Centre Number Signal',
  { value: string }
>('Prefill Cost Centre Number Signal') {}

export class ResetAlertSignal extends ResetErrorSignal {}

export class DownloadInvoicesSignal extends actionableFactory.create<
  'Download Invoices',
  Record<string, never>
>('Download Invoices') {}
