import { IProfileAssignmentNumbersEntity } from 'apps/hospital-admin/src/app/interfaces/api/profile-assignment-numbers-entity';

import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src';
import { createEntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';

const selectId = (entity: IProfileAssignmentNumbersEntity) =>
  entity.assignmentNumber + entity.effectiveStartDate;

const namespace = 'ProfileAssignmentNumbers';
const adapter = createEntityAdapter<IProfileAssignmentNumbersEntity>({ selectId });
const messageableFactory = MessageableFactory.forFeature<typeof namespace>(namespace);
const signalableFactory = SignalableFactory.forFeature<typeof namespace>(namespace);
const initialState = adapter.getInitialState({});
const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter,
  selectId,
);
export { adapter, initialState, paginationAdapter };
