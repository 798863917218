import {
  ApplicationHistoryMessageTypes as MessageTypes,
  ApplicationHistoryMessages,
  AddOneMessage,
  SetCollectionMessage,
  UpsertOneMessage,
  AddMultipleMessage,
  UpsertMultipleMessage,
  UpdateOneMessage,
  UpdateMultipleMessage,
  DeleteOneMessage,
  DeleteMultipleMessage,
} from './application-history.messages';
import { IApplicationHistoryEntityState } from './interfaces';
import { adapter, initialState, paginationAdapter } from './application-history.adapter';
import { IPaginatedSubresourceEntityState } from '@locumsnest/core/src/lib/adapters/paginated-subresource-state-adapter';
import { IApplicationHistoryEntity } from '../../interfaces/api/application-history-entity';

export * from './interfaces';

export const paginationReducer = paginationAdapter.createReducer();
export type IApplicationHistoryPaginatedEntityState = IApplicationHistoryEntityState &
  IPaginatedSubresourceEntityState<IApplicationHistoryEntity>;

export function entityReducer(
  state = initialState,
  action: ApplicationHistoryMessages
): IApplicationHistoryEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne((action as AddOneMessage).payload.entity, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne((action as UpsertOneMessage).payload.entity, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll((action as SetCollectionMessage).payload.entities, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany((action as AddMultipleMessage).payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany((action as UpsertMultipleMessage).payload.entities, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne((action as UpdateOneMessage).payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany((action as UpdateMultipleMessage).payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne((action as DeleteOneMessage).payload.id, state);
    }
    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany((action as DeleteMultipleMessage).payload.ids, state);
    }
    default: {
      return state;
    }
  }
}
export function paginatedEntityStateReducer(
  state: IApplicationHistoryPaginatedEntityState,
  action: ApplicationHistoryMessages
) {
  let pagination;

  if (state) {
    pagination = state.pagination;
  }

  return {
    ...entityReducer(state, action),
    pagination: {
      ...paginationReducer(pagination, action),
    },
  };
}
export const { selectIds, selectEntities, selectAll, selectTotal } =
  paginationAdapter.getEntitySelectors();
