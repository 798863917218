import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';

import { IExternalStaffingProviderTierEntity } from '../../interfaces/api/external-staffing-provider-tier-entity';
import { paginationAdapter } from './external-staffing-provider-tier.adapter';

export const {
  ResetLoadingStateMessage,
  ResetPaginationMessage,
  SetLoadingStateMessage,
  UpsertMultiplePagesMessage,
  UpsertPageMessage,
} = paginationAdapter.getMessages();

export const paginationMessages = paginationAdapter.getMessages();

export class UpsertExternalStaffingProviderTierPageMessage extends UpsertPageMessage {}
export class UpsertExternalStaffingProviderTierMultiplePagesMessage extends UpsertMultiplePagesMessage {}
export class ResetExternalStaffingProviderTierPaginationMessage extends ResetPaginationMessage {}

export enum ExternalStaffingProviderTierSignalTypes {
  LOAD_EXTERNAL_STAFFING_PROVIDER_TIERS = '[ExternalStaffingProviderTier][Signal] Load Collection',
}
export enum ExternalStaffingProviderTierMessageTypes {
  SET_COLLECTION = '[ExternalStaffingProviderTier][Message] Set Collection',
  ADD_ONE = '[ExternalStaffingProviderTier][Message] Add One',
  UPDATE_ONE = '[ExternalStaffingProviderTier][Message] Update One',
  UPSERT_ONE = '[ExternalStaffingProviderTier][Message] Upsert One',
  DELETE_ONE = '[ExternalStaffingProviderTier][Message] Delete One',
  ADD_MULTIPLE = '[ExternalStaffingProviderTier][Message] Add All',
  DELETE_MULTIPLE = '[ExternalStaffingProviderTier][Message] Delete Many',
  UPSERT_MULTIPLE = '[ExternalStaffingProviderTier][Message] Upsert Many',
  UPDATE_MULTIPLE = '[ExternalStaffingProviderTier][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = ExternalStaffingProviderTierMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IExternalStaffingProviderTierEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = ExternalStaffingProviderTierMessageTypes.ADD_ONE;
  constructor(public payload: { entity: IExternalStaffingProviderTierEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = ExternalStaffingProviderTierMessageTypes.UPSERT_ONE;
  constructor(public payload: { entity: IExternalStaffingProviderTierEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = ExternalStaffingProviderTierMessageTypes.ADD_MULTIPLE;
  constructor(public payload: { entities: IExternalStaffingProviderTierEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = ExternalStaffingProviderTierMessageTypes.UPSERT_MULTIPLE;
  constructor(public payload: { entities: IExternalStaffingProviderTierEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = ExternalStaffingProviderTierMessageTypes.UPDATE_ONE;
  constructor(public payload: { entity: Update<IExternalStaffingProviderTierEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = ExternalStaffingProviderTierMessageTypes.UPDATE_MULTIPLE;
  constructor(public payload: { entities: Update<IExternalStaffingProviderTierEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = ExternalStaffingProviderTierMessageTypes.DELETE_ONE;
  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = ExternalStaffingProviderTierMessageTypes.DELETE_MULTIPLE;
  constructor(public payload: { ids: string[] }) {}
}

export type ExternalStaffingProviderTierMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
