import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SelectOption } from '@locumsnest/components/src/lib/interfaces';

import { IPayRateType } from '../../interfaces/pay-rate-types';
import { IPayRateTypeFeatureState, IPayRateTypeSharedState } from './interfaces';
import { selectAll } from './pay-rate-type.reducer';

export const selectPayRateTypeState = createFeatureSelector<
  IPayRateTypeFeatureState & IPayRateTypeSharedState
>('payRateTypes');

export const selectPayRateTypeEntityState = createSelector(
  selectPayRateTypeState,
  (state) => state.entityState
);
export const selectAllPayRateTypes = createSelector(selectPayRateTypeEntityState, selectAll);
export const selectPayRateTypeOptions = createSelector(
  selectAllPayRateTypes,
  (payRateTypes): SelectOption<IPayRateType>[] =>
    payRateTypes.map((payRateType) => ({
      id: payRateType.id,
      name: payRateType.name,
      isFlat: false,
      slug: payRateType.slug,
    }))
);
