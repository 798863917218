import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { PaginatedStateService } from '@locumsnest/core/src';

import { IProfileTagEntity } from '../../interfaces/api/profile-tag-entity';
import {
  paginationMessages,
  ResetProfileTagPaginationMessage,
  UpsertMultipleMessage,
  UpsertProfileTagPageMessage,
} from './profile-tag.messages';
import { ProfileTagPersistenceService } from './profile-tag.persistence.service';
import {
  profileTagPaginationSelectors,
  selectProfileTagEntityState,
} from './profile-tag.selectors';

@Injectable({
  providedIn: 'root',
})
export class ProfileTagService extends PaginatedStateService<
  IProfileTagEntity,
  UpsertProfileTagPageMessage,
  ResetProfileTagPaginationMessage,
  UpsertMultipleMessage
> {
  constructor(protected store: Store, protected persistenceService: ProfileTagPersistenceService) {
    super();
  }

  get paginationMessages() {
    return paginationMessages;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return profileTagPaginationSelectors;
  }

  get entityStateSelector() {
    return selectProfileTagEntityState;
  }

  fetch() {
    return this.loadAllPages('default', {});
  }
}
