import { MessageableFactory } from '@locumsnest/core/src';
import { SignalableFactory } from '@locumsnest/core/src';
import { createAlertStateAdapter } from '@locumsnest/core/src/lib/adapters/alert-state-adapter';

export const messageableFactory =
  MessageableFactory.forFeature<'StaffingCascadeForm'>('StaffingCascadeForm');
export const signalableFactory =
  SignalableFactory.forFeature<'StaffingCascadeForm'>('StaffingCascadeForm');
export const alertStateAdapter = createAlertStateAdapter(signalableFactory, messageableFactory);
export const { errorHandler, conditionalErrorHandler } = alertStateAdapter.getAlertHandlers();
