import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src';
import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { ITrustExternalStaffingProviderTierEntity } from '../../interfaces/api/trust-external-staffing-provider-tier-entity';

export function sortByName(
  a: ITrustExternalStaffingProviderTierEntity,
  b: ITrustExternalStaffingProviderTierEntity
): number {
  return a.id - b.id;
}

export const adapter: EntityAdapter<ITrustExternalStaffingProviderTierEntity> =
  createEntityAdapter<ITrustExternalStaffingProviderTierEntity>({
    sortComparer: sortByName,
  });

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedTrustExternalStaffingProviderTierId: null,
});

export const messageableFactory =
  MessageableFactory.forFeature<'TrustExternalStaffingProviderTier'>(
    'TrustExternalStaffingProviderTier'
  );

export const signalableFactory = SignalableFactory.forFeature<'TrustExternalStaffingProviderTier'>(
  'TrustExternalStaffingProviderTier'
);

export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);
