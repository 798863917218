import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IStaffingCascadeStatusEntity } from '../../interfaces/api/staffing-cascade-status-entity';

export const adapter: EntityAdapter<IStaffingCascadeStatusEntity> =
  createEntityAdapter<IStaffingCascadeStatusEntity>({
    selectId: (staffingCascadeStatus: IStaffingCascadeStatusEntity) => staffingCascadeStatus.val,
  });

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedStaffingCascadeStatusId: null,
});
