import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IStaffBankUnavailabilityReasonEntity } from '../../interfaces/api/staff-bank-unavailability-reason-entity';

export function sortByName(
  a: IStaffBankUnavailabilityReasonEntity,
  b: IStaffBankUnavailabilityReasonEntity
): number {
  return a.display.localeCompare(b.display);
}

export const adapter: EntityAdapter<IStaffBankUnavailabilityReasonEntity> =
  createEntityAdapter<IStaffBankUnavailabilityReasonEntity>({
    sortComparer: sortByName,
  });

export const initialState = adapter.getInitialState({
  selectedStaffBankUnavailabilityReasonId: null,
});
