import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core';
import { createAlertStateAdapter } from '@locumsnest/core/src/lib/adapters/alert-state-adapter';
import { Time } from '@locumsnest/core/src/lib/helpers';
import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';

import { IJobListingTemplateEntity } from '../../interfaces/api/job-listing-template-entity';

const namespace = 'JobListingTemplatesEntity';

export const messageableFactory = MessageableFactory.forFeature<typeof namespace>(namespace);
export const signalableFactory = SignalableFactory.forFeature<typeof namespace>(namespace);

export const alertStateAdapter = createAlertStateAdapter(signalableFactory, messageableFactory);

export const { errorHandler, conditionalErrorHandler, alertHandler } =
  alertStateAdapter.getAlertHandlers();
export const { ResetErrorMessage, AlertErrorMessage } = alertStateAdapter.getMessages();

export function sortByDateUpdated(
  a: IJobListingTemplateEntity,
  b: IJobListingTemplateEntity,
): number {
  return Number(Time.getMoment(b.lastUpdate)) - Number(Time.getMoment(a.lastUpdate));
}

const adapter: EntityAdapter<IJobListingTemplateEntity> =
  createEntityAdapter<IJobListingTemplateEntity>({
    sortComparer: sortByDateUpdated,
  });

const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter,
);

const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedJobListingTemplateId: null,
});

export { adapter, initialState, paginationAdapter };
