import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IReferenceEntity } from '../../interfaces/api/reference-entity';
import { selectAll } from './reference.reducer';
import { IReferenceEntityState, IReferenceFeatureState, IReferenceSharedState } from './interfaces';
import { paginationAdapter } from './reference.adapter';

export const getEntityState = (state: { entityState: IReferenceEntityState }) => state.entityState;

export const getReferencesByProfileId = (profileId: string) => (references: IReferenceEntity[]) =>
  references.filter((reference) => reference.profile === profileId);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectReferenceState =
  createFeatureSelector<IReferenceFeatureState & IReferenceSharedState>('references');
export const selectReferenceEntityState = createSelector(selectReferenceState, getEntityState);
export const selectAllReferences = createSelector(selectReferenceEntityState, selectAll);
export const selectReferencesByProfileId = (profileId: string) =>
  createSelector(selectAllReferences, getReferencesByProfileId(profileId));

export const referencePaginationSelectors = paginationAdapter.getSubresourcePaginationSelectors(
  selectReferenceEntityState
);
