import { Injectable } from '@angular/core';

import {
  HttpApiPersistenceService,
  IPaginatedResponse,
  RequestOptions,
} from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { ICertificateEntity } from '../../interfaces/api/certificate-entity';

interface IEndpointOptions {
  profileId: string;
}

@Injectable({
  providedIn: 'root',
})
export class CertificatePersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  ICertificateEntity,
  IPaginatedResponse<ICertificateEntity>,
  ICertificateEntity,
  RequestOptions<IEndpointOptions>
> {
  protected readonly endpoint = 'certificate';
}
