import { createFeatureSelector, createSelector } from '@ngrx/store';

import { paginationAdapter } from './hospital-profile-tag.adapter';
import { featureKey } from './hospital-profile-tag.reducer';
import { IHospitalProfileTagFeatureState, IHospitalProfileTagSharedState } from './interfaces';

export const selectHospitalProfileTagState = createFeatureSelector<
  IHospitalProfileTagFeatureState & IHospitalProfileTagSharedState
>(featureKey);

export const selectHospitalProfileTagEntityState = createSelector(
  selectHospitalProfileTagState,
  (state) => state.entityState
);

export const hospitalProfileTagPaginationSelectors = paginationAdapter.paginationSelectors(
  selectHospitalProfileTagState,
  null,
  'pagination',
  'entityState',
  false
);
