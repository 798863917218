import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { paginationAdapter } from './hospital-staff-bank-unavailability.adapter';
import { IHospitalStaffBankUnavailabilityEntity } from '../../interfaces/api/hospital-staff-bank-unavailability-entity';

export const { UpsertPageMessage, ResetPaginationMessage } = paginationAdapter.getMessages();

export class UpsertHospitalStaffBankUnavailabilityPageMessage extends UpsertPageMessage {}
export class ResetHospitalStaffBankUnavailabilityPaginationMessage extends ResetPaginationMessage {}

export enum HospitalStaffBankUnavailabilitySignalTypes {
  LOAD_HOSPITAL_STAFF_BANK_UNAVAILABILITIES = '[HospStBankUnavailability][Signal] Load Collection',
}
export enum HospitalStaffBankUnavailabilityMessageTypes {
  SET_COLLECTION = '[HospitalStaffBankUnavailability][Message] Set Collection',
  ADD_ONE = '[HospitalStaffBankUnavailability][Message] Add One',
  UPDATE_ONE = '[HospitalStaffBankUnavailability][Message] Update One',
  UPSERT_ONE = '[HospitalStaffBankUnavailability][Message] Upsert One',
  DELETE_ONE = '[HospitalStaffBankUnavailability][Message] Delete One',
  ADD_MULTIPLE = '[HospitalStaffBankUnavailability][Message] Add All',
  DELETE_MULTIPLE = '[HospitalStaffBankUnavailability][Message] Delete Many',
  UPSERT_MULTIPLE = '[HospitalStaffBankUnavailability][Message] Upsert Many',
  UPDATE_MULTIPLE = '[HospitalStaffBankUnavailability][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = HospitalStaffBankUnavailabilityMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IHospitalStaffBankUnavailabilityEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = HospitalStaffBankUnavailabilityMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IHospitalStaffBankUnavailabilityEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = HospitalStaffBankUnavailabilityMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IHospitalStaffBankUnavailabilityEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = HospitalStaffBankUnavailabilityMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IHospitalStaffBankUnavailabilityEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = HospitalStaffBankUnavailabilityMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IHospitalStaffBankUnavailabilityEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = HospitalStaffBankUnavailabilityMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IHospitalStaffBankUnavailabilityEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = HospitalStaffBankUnavailabilityMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IHospitalStaffBankUnavailabilityEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = HospitalStaffBankUnavailabilityMessageTypes.DELETE_ONE;

  constructor(public payload: { id: number }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = HospitalStaffBankUnavailabilityMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: number[] }) {}
}

export type HospitalStaffBankUnavailabilityMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | UpsertHospitalStaffBankUnavailabilityPageMessage
  | ResetHospitalStaffBankUnavailabilityPaginationMessage;
