import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IHospitalProfileLabelEntity } from '../../interfaces/api/hospital-profile-label-entity';

@Injectable({
  providedIn: 'root',
})
export class HospitalProfileLabelPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IHospitalProfileLabelEntity
> {
  protected readonly endpoint = 'hospitalProfileLabel';
}
