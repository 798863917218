import { Injectable, Injector } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { isNil, isNumber, isString } from 'lodash-es';
import { SetUserDefinedPropertyAction } from 'ngrx-forms';
import {
  combineLatest,
  concat,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
} from 'rxjs';

import { ISelectOption } from '@locumsnest/components/src';
import { IMessage } from '@locumsnest/components/src/lib/interfaces/message';
import { IQueryParams, PaginatedStateService, Query } from '@locumsnest/core/src';
import { ProfileAssignmentNumbersService } from '@locumsnest/profile-assignment-numbers/src';

import { ApplicationHistoryService } from '../../application-history/+state/application-history.service';
import { CertificateTypeService } from '../../certificate-type/+state/certificate-type.service';
import { CertificateService } from '../../certificate/+state/certificate.service';
import { ComplianceMessageService } from '../../compliance-message/+state/compliance-message.service';
import { Gender } from '../../core/enumeration';
import { IStaffBankMembershipRequestService } from '../../core/services/interfaces';
import { STAFF_BANK_MEMBERSHIP_REQUEST_SERVICE_TOKEN } from '../../core/services/state/opaque-tokens';
import { ExternalStaffingCandidateDocumentService } from '../../external-staffing-candidate-document/+state/external-staffing-candidate-document.service';
import { GmcStatusService } from '../../gmc-status/+state/gmc-status.service';
import { GradeService } from '../../grade/+state/grade.service';
import { HospitalService } from '../../hospital/+state/hospital.services';
import { IProfessionEntity } from '../../interfaces/api/profession-entity';
import { IProfileEntity } from '../../interfaces/api/profile-entity';
import { IProfileNote } from '../../interfaces/api/profile-note-entity';
import { IRevalidationStatusEntity } from '../../interfaces/api/revalidation-status-entity';
import { IStaffBankCollaborationGroupEntity } from '../../interfaces/api/staff-bank-collaboration-group-entity';
import { IStaffBankMembershipEntity } from '../../interfaces/api/staff-bank-membership-entity';
import { IStaffBankMembershipRequestEntity } from '../../interfaces/api/staff-bank-membership-request-entity';
import { JobPositionService } from '../../job-position/+state/job-position.service';
import { ProfessionService } from '../../profession/+state/profession.service';
import { ProfileFlagService } from '../../profile-flag/+state/profile-flag.service';
import { ProfileNoteService } from '../../profile-note/+state/profile-note.service';
import { ProfileStatisticsService } from '../../profile-statistics/+state/profile-statistics.service';
import { ReferenceService } from '../../reference/+state/reference.service';
import { RevalidationStatusService } from '../../revalidation-status/+state/revalidation-status.service';
import { SafeboxCertificateService } from '../../safebox-certificate/+state/safebox-certificate.service';
import { SafeboxReferenceService } from '../../safebox-reference/+state/safebox-reference.service';
import { StaffBankCollaborationGroupService } from '../../staff-bank-collaboration-group/+state/staff-bank-collaboration-group.service';
import { StaffBankMembershipService } from '../../staff-bank-membership/+state/staff-bank-membership.service';
import { ProfileDetailPageComponent } from '../containers/profile-detail-page/profile-detail-page.component';
import {
  IPassportDocument,
  IProfileAssignmentNumberEntry,
  IProfileRegistryRecord,
  IProfileWithStaffBankStatus,
  ISafeboxDocument,
  ProfileAssignmentNumbersFilterTypes,
} from './interfaces';
import { InitializeProfessionalRegistrationFormMessage } from './profile-search-form/profile-search-form.messages';
import { PROFILE_REGISTRATION_SEARCH_FORM_ID } from './profile-search-form/profile-search-form.reducer';
import { loadingAdapter } from './profile.adapter';
import {
  ProfileMessageTypes,
  ProfilePaginationMessages,
  ResetProfilePaginationMessage,
  UpsertMultipleMessage,
  UpsertOneMessage,
  UpsertProfilePageMessage,
} from './profile.messages';
import { ProfilePersistenceService } from './profile.persistence.service';
import {
  getAssignmentNumbers,
  getDobRequiredProperty,
  loadingStateSelectors,
  profilePaginationSelectors,
  selectAllProfiles,
  selectProfileEntityState,
  selectProfilesByIds,
  selectProfileSearchFormEmail,
  selectProfileSearchFormRequiresDob,
  selectProfileSearchFormState,
  selectSelectedProfile,
} from './profile.selectors';
import {
  getDigitalPassportDocuments,
  getDigitalSafeboxCertificates,
  getDigitalSafeboxReferences,
  selectCloneDocumentToHospitalFormState,
  selectCopiedCertificatesUiState,
  selectCopiedReferencesUiState,
  selectEmploymentHistoryFilterUiState,
  selectNoteFormState,
  selectProfileAlertToDisplay,
  selectProfileAssignmentNumbersFilterUiState,
} from './ui/ui.selectors';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends PaginatedStateService<
  IProfileEntity,
  UpsertProfilePageMessage,
  ResetProfilePaginationMessage,
  UpsertMultipleMessage
> {
  constructor(
    protected injector: Injector,
    protected persistenceService: ProfilePersistenceService,
    protected store: Store,
    private applicationHistoryService: ApplicationHistoryService,
    private certificateService: CertificateService,
    private certificateTypeService: CertificateTypeService,
    private complianceMessageService: ComplianceMessageService,
    private externalStaffingCandidateDocumentService: ExternalStaffingCandidateDocumentService,
    private gmcStatusService: GmcStatusService,
    private hospitalService: HospitalService,
    private jobPositionService: JobPositionService,
    private professionService: ProfessionService,
    private profileFlagService: ProfileFlagService,
    private profileNoteService: ProfileNoteService,
    private profileStatisticsService: ProfileStatisticsService,
    private referenceService: ReferenceService,
    private revalidationStatusService: RevalidationStatusService,
    private safeboxCertificateService: SafeboxCertificateService,
    private safeboxReferenceService: SafeboxReferenceService,
    private staffBankCollaborationGroupService: StaffBankCollaborationGroupService,
    private profileAssignmentNumbersService: ProfileAssignmentNumbersService,
    private gradeService: GradeService,
    private staffBankMembershipService: StaffBankMembershipService,
  ) {
    super();
  }

  get paginationMessages() {
    return ProfilePaginationMessages;
  }

  get paginationSelectors() {
    return profilePaginationSelectors;
  }

  get entityStateSelector() {
    return selectProfileEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  private transformToRecord(
    profile: IProfileEntity,
    revalidationStatuses: IRevalidationStatusEntity[],
    professions: IProfessionEntity[],
  ): IProfileRegistryRecord {
    const status = revalidationStatuses.find((x) => x.val === profile.revalidationStatus);
    let gender = null;
    if (profile && !isNil(profile.gender)) gender = Gender[profile.gender].display;

    const profession = professions.find((x) => x.id === profile.profession);

    return {
      ...profile,
      revalidationStatus: status === undefined ? null : status.display,
      gender,
      profession,
    };
  }

  initializePaginationAndLoadDependencies(
    namespace: string,
    filters: IQueryParams,
    resetState: boolean = false,
    loadAssignmentNumber: boolean = false,
    loadStaffBankMemberships = false,
  ) {
    return this.initializePagination(namespace, {}, filters, resetState).pipe(
      mergeMap((action) =>
        this.loadDependencies(action, loadAssignmentNumber, loadStaffBankMemberships),
      ),
      mergeMap((x) => x),
    );
  }

  loadNextAndLoadDependencies(
    namespace: string,
    filters: IQueryParams,
    loadAssignmentNumber = false,
    loadStaffBankMemberships = false,
  ) {
    return this.loadNext(namespace, {}, filters).pipe(
      mergeMap((action) =>
        this.loadDependencies(action, loadAssignmentNumber, loadStaffBankMemberships),
      ),
      mergeMap((x) => x),
    );
  }

  private loadDependencies(action, loadAssignmentNumber = false, loadStaffBankMemberships = false) {
    const actions: Observable<Action>[] = [of(action)];
    if (action.type === ProfileMessageTypes.UPSERT_MULTIPLE) {
      const payload = (action as UpsertMultipleMessage).payload;
      if (loadAssignmentNumber) {
        const ids = payload.entities.map((x) => x.id);
        actions.push(this.profileAssignmentNumbersService.loadByProfileIds(ids));
      }

      if (loadStaffBankMemberships) {
        const ids = payload.entities.map((x) => x.id);
        actions.push(this.staffBankMembershipService.loadByProfileIds(ids));
      }
    }
    return actions;
  }

  getAll() {
    return this.store.pipe(select(selectAllProfiles));
  }

  getAllAfterLoading() {
    return this.store.pipe(select(loadingStateSelectors.selectLoadingState)).pipe(
      filter((loadingState) => loadingState.isLoaded === true),
      mergeMap(() => this.getAll()),
    );
  }

  get loadingMessages() {
    return loadingAdapter.getMessages();
  }

  getCloneDocumentToHospitalFormState() {
    return this.store.pipe(select(selectCloneDocumentToHospitalFormState));
  }

  getSelectedProfile(): Observable<IProfileEntity> {
    return this.store.pipe(
      select(selectSelectedProfile),
      filter((selectedProfile) => !isNil(selectedProfile)),
    );
  }

  getSelectedProfileId() {
    return this.getSelectedProfile().pipe(
      map((profile) => profile.id),
      distinctUntilChanged(),
    );
  }

  getEmploymentHistoryFilterUiState() {
    return this.store.pipe(select(selectEmploymentHistoryFilterUiState));
  }

  getSelectedProfileAssignmentNumbers(): Observable<IProfileAssignmentNumberEntry[]> {
    return combineLatest([
      this.getSelectedProfileId(),
      this.store.pipe(select(selectProfileAssignmentNumbersFilterUiState)),
    ]).pipe(
      mergeMap(([profileId, profileAssignmentNumber]) =>
        this.getProfileAssignmentNumbers(profileId, profileAssignmentNumber),
      ),
    );
  }

  getSelectedProfileNotes(): Observable<IProfileNote[]> {
    return this.getSelectedProfileId().pipe(
      mergeMap((profileId) =>
        this.profileNoteService.getProfileNotesWithOfficers(
          ProfileDetailPageComponent.namespace,
          profileId,
        ),
      ),
    );
  }

  getSelectedProfileWorkedHours(): Observable<number> {
    return this.getSelectedProfileId().pipe(
      mergeMap((profileId) => this.profileStatisticsService.getWorkedHours(profileId)),
    );
  }

  getSelectedProfileWorkedForOrganization(): Observable<boolean> {
    return this.getSelectedProfileId().pipe(
      mergeMap((profileId) =>
        this.profileStatisticsService.hasWorkedShiftsInOurOrganisation(profileId),
      ),
    );
  }

  getSelectedProfileWorkedForDepartment(): Observable<boolean> {
    return this.getSelectedProfileId().pipe(
      mergeMap((profileId) =>
        this.profileStatisticsService.hasWorkedShiftsInSimilarDepartment(profileId),
      ),
    );
  }

  getSelectedProfileLicensed(): Observable<boolean> {
    return this.getSelectedProfileId().pipe(mergeMap((profileId) => this.canPractice(profileId)));
  }

  getSelectedProfileGmcStatus(): Observable<string> {
    return this.getSelectedProfileId().pipe(
      mergeMap((profileId) => this.getOne(profileId)),
      mergeMap((profile) => this.gmcStatusService.getOne(profile.gmcStatus)),
    );
  }

  getSelectedProfileMessages(): Observable<IMessage[]> {
    return this.getSelectedProfileId().pipe(
      mergeMap((profileId) =>
        this.complianceMessageService.getComplianceMessagesWithOfficers(profileId),
      ),
    );
  }

  getSelectedProfilePassportDocuments() {
    return this.getSelectedProfileId().pipe(
      switchMap((profileId) => this.getDigitalPassportDocuments(profileId)),
    );
  }

  getSelectedProfilePassportCertificates(): Observable<IPassportDocument[]> {
    return combineLatest([
      this.getSelectedProfilePassportDocuments(),
      this.certificateTypeService.getAll(),
      this.store.pipe(select(selectCopiedCertificatesUiState)),
      this.store.pipe(select(selectCopiedReferencesUiState)),
    ]).pipe(
      map(([docs, certificateType, copiedCertificates, copiedReferences]) =>
        getDigitalPassportDocuments(
          docs.references,
          docs.certificates,
          certificateType,
          copiedCertificates,
          copiedReferences,
        ),
      ),
    );
  }

  getSelectedProfileSafeboxDocuments() {
    return this.getSelectedProfileId().pipe(
      switchMap((profileId) => this.getDigitalSafeboxDocuments(profileId)),
    );
  }

  getSelectedProfileSafeboxReferences() {
    return this.getSelectedProfileId().pipe(
      switchMap((profileId) => this.safeboxReferenceService.getByProfileId(profileId)),
    );
  }

  getSelectedProfileSafeboxCertificates() {
    return this.getSelectedProfileId().pipe(
      switchMap((profileId) => this.safeboxCertificateService.getByProfileId(profileId)),
    );
  }

  getSelectedProfileSafeBoxCertificates(): Observable<ISafeboxDocument[]> {
    return combineLatest([
      this.getSelectedProfileSafeboxCertificates(),
      this.certificateTypeService.getAll(),
    ]).pipe(
      map(([certificates, certificateType]) =>
        getDigitalSafeboxCertificates(certificates, certificateType),
      ),
    );
  }

  getSelectedProfileSafeBoxReferences(): Observable<ISafeboxDocument[]> {
    return this.getSelectedProfileSafeboxReferences().pipe(
      map((references) => getDigitalSafeboxReferences(references)),
    );
  }

  getSelectedProfileSafeBoxCertificateOptions(): Observable<ISelectOption[]> {
    return this.getSelectedProfileSafeBoxCertificates().pipe(
      map((documents) =>
        documents.map((document) => ({
          id: document.id,
          name: document.display,
          type: document.type,
        })),
      ),
    );
  }

  getSelectedProfileSafeBoxReferenceOptions(): Observable<ISelectOption[]> {
    return this.getSelectedProfileSafeBoxReferences().pipe(
      map((documents) =>
        documents.map((document) => ({
          id: document.id,
          name: document.display,
          type: document.type,
        })),
      ),
    );
  }

  get staffBankMembershipRequestService(): IStaffBankMembershipRequestService {
    return this.injector.get(STAFF_BANK_MEMBERSHIP_REQUEST_SERVICE_TOKEN);
  }

  getProfileAlertToDisplay() {
    return this.store.pipe(select(selectProfileAlertToDisplay));
  }

  getProfileSearchFormState() {
    return this.store.pipe(select(selectProfileSearchFormState));
  }

  getProfileSearchFilterFormEmail() {
    return this.store.pipe(select(selectProfileSearchFormEmail));
  }

  getProfileSearchFormEntityState() {
    return combineLatest([
      this.getProfileSearchFormState(),
      this.getProfileSearchFormRequiresDob(),
    ]).pipe(
      map(([formState, requiresDob]) => {
        if (requiresDob) {
          return formState.value;
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { dob, ...formStateWithoutDob } = formState.value;
        return formStateWithoutDob;
      }),
    );
  }

  getProfileSearchFormRequiresDob(): Observable<boolean> {
    return this.store.pipe(select(selectProfileSearchFormRequiresDob));
  }

  setRegistrationDobRequired(registrationNumber: string) {
    return new SetUserDefinedPropertyAction(
      PROFILE_REGISTRATION_SEARCH_FORM_ID,
      getDobRequiredProperty(registrationNumber),
      true,
    );
  }

  loadOne(id: string, form = false) {
    const loadAction$ = this.fetch(id) as Observable<UpsertOneMessage>;
    if (form) {
      return this.postLoadProfessionalRegistrationForm(loadAction$);
    }
    return loadAction$;
  }

  loadByIds(ids: string[]): Observable<Action> {
    return concat(
      of(new this.loadingMessages.SetLoadingMessage({})),
      this.loadAll({ id: ids }),
      of(new this.loadingMessages.ResetLoadingMessage({})),
    );
  }

  fetch(query?: Query) {
    //@todo generalize this and move it to base service
    if (isString(query) || isNumber(query))
      return this.persistenceService
        .retrieve(query)
        .pipe(map((profile) => new UpsertOneMessage({ entity: profile })));

    return this.persistenceService
      .retrieve(query)
      .pipe(map(({ results }) => new UpsertMultipleMessage({ entities: results })));
  }

  postLoadProfessionalRegistrationForm(loadAction$: Observable<UpsertOneMessage>) {
    return loadAction$.pipe(
      mergeMap((action) => {
        const { payload } = action;
        return [action, this.loadProfessionalRegistrationForm(payload.entity)];
      }),
    );
  }

  loadProfessionalRegistrationForm(profile: IProfileEntity) {
    return new InitializeProfessionalRegistrationFormMessage({
      profileSearchFormState: { ...profile, registrationNumber: profile.registrationNumber },
    });
  }

  postLoadUi(loadAction$: Observable<UpsertOneMessage>) {
    return loadAction$.pipe(
      mergeMap((action) => {
        const { payload } = action;
        return [action, this.loadProfessionalRegistrationForm(payload.entity)];
      }),
    );
  }

  getDigitalPassportDocuments(id: string) {
    // get certificates & references
    return combineLatest([
      this.referenceService.getByProfileId(id),
      this.certificateService.getByProfileId(id),
    ]).pipe(map(([references, certificates]) => ({ references, certificates })));
  }

  getDigitalSafeboxDocuments(id: string) {
    // get safebox certificates & references
    return combineLatest([
      this.safeboxReferenceService.getByProfileId(id),
      this.safeboxCertificateService.getByProfileId(id),
    ]).pipe(
      map(([safeboxReferences, safeboxCertificates]) => ({
        safeboxReferences,
        safeboxCertificates,
      })),
    );
  }

  getProfileAssignmentNumbers(
    id: string,
    // eslint-disable-next-line max-len
    profileAssignmentNumberFilter: ProfileAssignmentNumbersFilterTypes = ProfileAssignmentNumbersFilterTypes.none,
  ) {
    return combineLatest([
      this.profileAssignmentNumbersService
        .getAll()
        .pipe(map((profiles) => profiles.filter((profile) => profile.profile === id))),
      this.gradeService.getAll(),
      this.hospitalService.getAll(),
    ]).pipe(
      map(([profileAssignmentNumbers, grades, hospitals]) =>
        getAssignmentNumbers(
          profileAssignmentNumbers,
          grades,
          hospitals,
          profileAssignmentNumberFilter,
        ),
      ),
    );
  }

  getSelectedProfileHasEmploymentHistory(): Observable<boolean> {
    return this.getSelectedProfileId().pipe(
      mergeMap((profileId) => this.jobPositionService.hasJobPositions(profileId)),
    );
  }

  getProfileStatus(id: string): Observable<string> {
    return combineLatest([
      this.staffBankMembershipService.getByProfileId(id).pipe(filter((x) => !!x)),
      this.hospitalService.getAssigned(),
      this.hospitalService
        .getAssigned()
        .pipe(
          mergeMap((assignedHospital) =>
            this.staffBankCollaborationGroupService.getGroupsByHospitalIds([assignedHospital.id]),
          ),
        )
        .pipe(filter((x) => !!x)),
      this.staffBankMembershipRequestService.getOneByProfileId(id),
    ]).pipe(
      map(([memberships, assignedHospital, collaborationGroups, membershipRequest]) =>
        this.getMembershipStatus(
          memberships,
          assignedHospital.id,
          collaborationGroups,
          membershipRequest,
        ),
      ),
    );
  }

  getProfilesByIds(ids: string[]): Observable<IProfileEntity[]> {
    return this.store.pipe(select(selectProfilesByIds(ids)));
  }
  // todo : getOne
  getProfilesWithStaffBanks(
    registrationNumbers: string[],
  ): Observable<IProfileWithStaffBankStatus[]> {
    return combineLatest([
      this.staffBankMembershipService.getByProfileIds(registrationNumbers).pipe(filter((x) => !!x)),
      this.getAll(),
      this.hospitalService.getAssigned(),
      this.hospitalService
        .getAssigned()
        .pipe(
          distinctUntilChanged(),
          mergeMap((assignedHospital) =>
            this.staffBankCollaborationGroupService.getGroupsByHospitalIds([assignedHospital.id]),
          ),
        )
        .pipe(filter((x) => !!x)),
      this.staffBankMembershipRequestService.getByProfileIds(registrationNumbers),
      this.profileFlagService.getProfileFlagsByProfileIds(registrationNumbers),
    ]).pipe(
      map(
        ([
          memberships,
          profiles,
          assignedHospital,
          collaborationGroups,
          membershipRequests,
          profileFlags,
        ]) => {
          const profileWithStaffBankStatus: IProfileWithStaffBankStatus[] = [];

          for (let profile of profiles) {
            const membershipRequest = membershipRequests
              ? membershipRequests.find((x) => x.profile === profile.id)
              : null;
            const profileMemberships = memberships
              ? memberships.filter((x) => x.profile === profile.id)
              : [];
            const status = this.getMembershipStatus(
              profileMemberships,
              assignedHospital.id,
              collaborationGroups,
              membershipRequest,
            );

            const profileFlag = profileFlags.find((x) => x.profileId === profile.id);

            let staffBankStatusFont: string;

            switch (status) {
              case 'No Staffbank memberships':
              case 'Member of another Staffbank':
                staffBankStatusFont = 'error';
                break;
              case 'Staffbank application submitted':
                staffBankStatusFont = 'warning';
                break;
              default:
                staffBankStatusFont = 'success';
                break;
            }

            profile = {
              ...profile,
              hasFlaggedNote: profileFlag ? profileFlag.hasFlaggedNote : false,
            };

            profileWithStaffBankStatus.push({
              profile,
              staffBankStatus: status,
              staffBankStatusFont,
            });
          }
          return profileWithStaffBankStatus;
        },
      ),
    );
  }

  public getMembershipStatus(
    memberships: IStaffBankMembershipEntity[],
    trustId: number,
    collaborationGroups: IStaffBankCollaborationGroupEntity[],
    membershipRequest: IStaffBankMembershipRequestEntity,
  ): string {
    if (memberships.find((x) => x.hospital === trustId)) {
      return 'Member of your Staffbank';
    }

    const collaboration = collaborationGroups.filter((group) =>
      memberships.find((membership) => group.hospitals.indexOf(membership.hospital) >= 0),
    );

    if (collaboration.length === 1) {
      return 'Member of ' + collaboration[0].name;
    }

    if (collaboration.length > 1) {
      return 'Multiple Collaboratives: ' + collaboration.map((c) => c.name).join(', ');
    }

    if (memberships.length > 0) {
      return 'Member of another Staffbank';
    }

    if (membershipRequest) {
      return 'Staffbank application submitted';
    }

    return 'No Staffbank memberships';
  }

  getProfileHospitals = () =>
    this.getSelectedProfileId().pipe(
      mergeMap((profileId) => this.staffBankMembershipService.getHospitalsByProfileId(profileId)),
    );

  getStaffBankMembershipsDisplay() {
    return combineLatest([this.getProfileHospitals(), this.hospitalService.getAssigned()]).pipe(
      map(([hospitals, currentHospital]) => {
        if (hospitals.length > 0) {
          let display = '';

          // check for current hospital, which should be first in the list
          if (currentHospital && hospitals.find((x) => x.id === currentHospital.id)) {
            display += currentHospital.name;
            hospitals = hospitals.filter((x) => x.id !== currentHospital.id);
          }

          if (display !== '' && hospitals.length > 0) {
            display += ', ';
          }

          display += hospitals
            .filter((a) => a.active)
            .map((x) => x.name)
            .join(', ');

          return display;
        } else {
          return 'No Staffbank memberships';
        }
      }),
    );
  }

  getCommonStaffBankCollaborationByProfileIds(
    profileIds: string[],
  ): Observable<IStaffBankCollaborationGroupEntity[][]> {
    return combineLatest(
      profileIds.map((profile) => this.getCommonStaffBankCollaborationByProfileId(profile)),
    );
  }

  getCommonStaffBankCollaborationByProfileId(
    profileId: string,
  ): Observable<IStaffBankCollaborationGroupEntity[]> {
    return this.hospitalService.getAssigned().pipe(
      switchMap(({ staffBankCollaborationGroups }) => {
        if (!staffBankCollaborationGroups.length) return of([]);
        return this.staffBankMembershipService.getHospitalsByProfileId(profileId).pipe(
          mergeMap((hospitals) => {
            const ids = hospitals.map((x) => x.id);
            return this.staffBankCollaborationGroupService.getGroupsByHospitalIds(ids).pipe(
              map((staffBankCollaboration) => {
                const staffBankCollaborationIds = staffBankCollaboration.map((r) => r.id);
                const commonIds = staffBankCollaborationIds.some((r) =>
                  staffBankCollaborationGroups.includes(r),
                );
                return commonIds ? staffBankCollaboration : [];
              }),
            );
          }),
        );
      }),
    );
  }

  getStaffBankCollaborationGroupDisplay() {
    return this.getProfileHospitals()
      .pipe(
        mergeMap((hospitals) => {
          const ids = hospitals.map((x) => x.id);
          return this.staffBankCollaborationGroupService.getGroupsByHospitalIds(ids);
        }),
      )
      .pipe(
        map((groups) => {
          if (groups.length > 0) {
            let display = '';

            if (display !== '' && groups.length > 0) {
              display += ', ';
            }

            display += groups.map((x) => x.name).join(', ');

            return display;
          } else {
            return 'No Staffbank collaboration groups';
          }
        }),
      );
  }

  public isMemberOfCollaboration() {
    return this.getProfileHospitals()
      .pipe(
        mergeMap((hospitals) => {
          const ids = hospitals.map((x) => x.id);
          return this.staffBankCollaborationGroupService.getGroupsByHospitalIds(ids);
        }),
      )
      .pipe(map((groups) => groups.length > 0));
  }

  public canPractice(profileId: string): Observable<boolean> {
    return this.getOne(profileId).pipe(map((profile) => profile?.canPractice || false));
  }

  getSelectedProfileRegistryRecord() {
    return combineLatest([
      this.getSelectedProfile(),
      this.revalidationStatusService.getAll(),
      this.professionService.getAll(),
    ]).pipe(
      map(
        ([profile, revalidationStatuses, professions]: [
          IProfileEntity,
          IRevalidationStatusEntity[],
          IProfessionEntity[],
        ]): IProfileRegistryRecord =>
          this.transformToRecord(profile, revalidationStatuses, professions),
      ),
    );
  }

  getSelectedExternalStaffingCandidateDocuments() {
    return combineLatest([
      this.getSelectedProfileId(),
      this.hospitalService.getAssignedHospitalId(),
    ]).pipe(
      mergeMap(([profileId, trustId]) =>
        this.externalStaffingCandidateDocumentService.getExternalStaffingCandidateDocumentsWithProvider(
          profileId,
          trustId,
        ),
      ),
    );
  }

  getConsecutiveProfileEntities(namespace: string) {
    return this.getConsecutivePageEntities(namespace);
  }

  getNoteFormState() {
    return this.store.pipe(select(selectNoteFormState));
  }

  fetchBankDetails(id: string) {
    return this.persistenceService.getBankDetails(id).pipe(map((res) => res.results));
  }
}
