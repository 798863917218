import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IStaffBankUnavailabilityReasonEntity } from '../../interfaces/api/staff-bank-unavailability-reason-entity';

@Injectable({
  providedIn: 'root',
})
export class StaffBankUnavailabilityReasonPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IStaffBankUnavailabilityReasonEntity
> {
  protected readonly endpoint = 'staffBankUnavailabilityReason';
}
