import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { isString } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PaginatedSubresourceStateService, RequestOptions } from '@locumsnest/core';

import {
  ICloneCertificateToHospitalEntity,
  ISafeboxCertificateEntity,
  ISafeboxCertificatePostEntity,
} from '../../interfaces/api/safebox-certificate-entity';
import { AppState } from '../../reducers';
import {
  ResetSafeboxCertificatePaginationMessage,
  SetCollectionMessage,
  UpsertMultipleMessage,
  UpsertSafeboxCertificatePageMessage,
} from './safebox-certificate.messages';
import { SafeboxCertificatePersistenceService } from './safebox-certificate.persistence.service';
import {
  safeboxCertificatePaginationSelectors,
  selectAllSafeboxCertificates,
  selectSafeboxCertificateEntityState,
  selectSafeboxCertificatesByProfileId,
} from './safebox-certificate.selectors';

export interface SafeboxCertificateRequestOptions {
  profileId: string;
}

@Injectable({
  providedIn: 'root',
})
export class SafeboxCertificateService extends PaginatedSubresourceStateService<
  ISafeboxCertificateEntity,
  UpsertSafeboxCertificatePageMessage,
  ResetSafeboxCertificatePaginationMessage,
  UpsertMultipleMessage,
  RequestOptions<SafeboxCertificateRequestOptions>,
  ISafeboxCertificatePostEntity
> {
  constructor(
    protected store: Store,
    protected persistenceService: SafeboxCertificatePersistenceService
  ) {
    super();
  }
  get upsertMessageClass() {
    return UpsertSafeboxCertificatePageMessage;
  }
  get clearMessageClass() {
    return ResetSafeboxCertificatePaginationMessage;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return safeboxCertificatePaginationSelectors;
  }

  get entityStateSelector() {
    return selectSafeboxCertificateEntityState;
  }

  getAll() {
    return this.store.pipe(select(selectAllSafeboxCertificates));
  }

  loadByProfileId(id): Observable<Action> {
    return this.fetch(id) as Observable<SetCollectionMessage>;
  }

  getByProfileId(profileId: string) {
    return this.store.pipe(select(selectSafeboxCertificatesByProfileId(profileId)));
  }

  fetch(profileId?: string) {
    if (isString(profileId))
      return this.persistenceService
        .retrieve(null, { pathParams: { profileId } })
        .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));

    return this.persistenceService
      .retrieve()
      .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));
  }

  getRequestOptions(profileId: string): RequestOptions<SafeboxCertificateRequestOptions> {
    return { pathParams: { profileId } };
  }

  copyFromCertificate(
    certificateId: number,
    profileId: string
  ): Observable<ISafeboxCertificateEntity> {
    return this.persistenceService.copyFromCertificate(certificateId, profileId);
  }

  cloneCertificateToHospital(
    data: ICloneCertificateToHospitalEntity,
    profileId: string
  ): Observable<ISafeboxCertificateEntity> {
    return this.persistenceService.cloneCertificateToHospital(data, profileId);
  }
}
