import { ITagFormState } from '../interfaces/tag-form-state';
import { messageableFactory as actionableFactory } from './tag-form.adapter';

export class InitializeTagFormMessage extends actionableFactory.create<
  'Initialize Tag Form Message',
  { initialValues?: ITagFormState }
>('Initialize Tag Form Message') {}

export class UpdateShowMyListMessage extends actionableFactory.create<
  'Update Show My List Message',
  { show: boolean }
>('Update Show My List Message') {}

export class ClearSelectedTagsMessage extends actionableFactory.create<
  'Clear Selected Tags',
  Record<string, never>
>('Clear Selected Tags') {}

export class ClearDeleteModeSelectedTagsMessage extends actionableFactory.create<
  'Clear Delete Mode Selected Tags',
  Record<string, never>
>('Clear Delete Mode Selected Tags') {}

export class ShowHideTagMessageMessage extends actionableFactory.create<
  'Show Hide Tag Message',
  { show: boolean }
>('Show Hide Tag Message') {}

export class ClearTagSearchMessage extends actionableFactory.create<
  'Clear Tag Search',
  Record<string, never>
>('Clear Tag Search') {}

export class ToggleMyTagsDeleteModeMessage extends actionableFactory.create<
  'Toggle My Tags Delete Mode',
  Record<string, never>
>('Toggle My Tags Delete Mode') {}

export class ToggleOrganisationTagsDeleteModeMessage extends actionableFactory.create<
  'Toggle Organisation Tags Delete Mode',
  Record<string, never>
>('Toggle Organisation Tags Delete Mode') {}

export class SetMaxTagsErrorMessage extends actionableFactory.create<
  'Set Max Tags Error',
  Record<string, never>
>('Set Max Tags Error') {}

export class ResetMaxTagsErrorMessage extends actionableFactory.create<
  'Reset Max Tags Error',
  Record<string, never>
>('Reset Max Tags Error') {}

export class SearchTagMessage extends actionableFactory.create<'Search Tag', { search: string }>(
  'Search Tag',
) {}

export type TagWidgetUiMessages =
  | ClearDeleteModeSelectedTagsMessage
  | ClearSelectedTagsMessage
  | ClearTagSearchMessage
  | InitializeTagFormMessage
  | ResetMaxTagsErrorMessage
  | SetMaxTagsErrorMessage
  | ShowHideTagMessageMessage
  | ToggleMyTagsDeleteModeMessage
  | ToggleOrganisationTagsDeleteModeMessage
  | UpdateShowMyListMessage;
