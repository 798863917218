import { Injectable } from '@angular/core';
import { catchError, of } from 'rxjs';

import {
  HttpApiPersistenceService,
  IPaginatedResponse,
  RequestOptions,
} from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import {
  ICloneCertificateToHospitalEntity,
  ICopyFromCertificateEntity,
  ISafeboxCertificateEntity,
  ISafeboxCertificatePostEntity,
} from '../../interfaces/api/safebox-certificate-entity';

interface IEndpointOptions {
  profileId: string;
}

@Injectable({
  providedIn: 'root',
})
export class SafeboxCertificatePersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  ISafeboxCertificateEntity,
  IPaginatedResponse<ISafeboxCertificateEntity>,
  ISafeboxCertificatePostEntity,
  RequestOptions<IEndpointOptions>
> {
  protected readonly endpoint = 'safeboxCertificate';

  copyFromCertificate(certificateId: number, profileId: string) {
    const data: ICopyFromCertificateEntity = {
      id: certificateId,
    };

    return this.create<ICopyFromCertificateEntity, ISafeboxCertificateEntity>(data, {
      pathParams: { profileId },
      controllerResource: 'copyFromCertificate',
      skipSerializer: true,
    }).pipe(catchError((e) => of(e)));
  }

  cloneCertificateToHospital(data: ICloneCertificateToHospitalEntity, profileId: string) {
    return this.create<ICloneCertificateToHospitalEntity, ISafeboxCertificateEntity>(data, {
      pathParams: { profileId },
      controllerResource: 'cloneCertificateToHospital',
      skipSerializer: true,
    }).pipe(catchError((e) => of(e)));
  }
}
