import { get, isNil, maxBy, sumBy } from 'lodash-es';
import {
  createFormGroupState,
  createFormStateReducerWithUpdate,
  disable,
  enable,
  FormGroupState,
  markAsDirty,
  SetUserDefinedPropertyAction,
  setValue,
  SetValueAction,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { pattern, required } from 'ngrx-forms/validation';

import {
  makeMarkMultipleAsDirtyReducer,
  MarkMultipleAsDirtyAction,
  validateDateRange,
} from '@locumsnest/core/src';
import { IAlertState } from '@locumsnest/core/src/lib/adapters/alert-state-adapter/interfaces';
import { Time } from '@locumsnest/core/src/lib/helpers';
import { formatMoney, isNumeric } from '@locumsnest/core/src/lib/helpers/util';
import {
  applyPlural,
  applyVerboseName,
} from '@locumsnest/core/src/lib/ngrx/helpers/ngrx-forms/error-messages.helper';
import { setUserDefinedProperties } from '@locumsnest/core/src/lib/ngrx/helpers/ngrx-forms/util';
import { EMAIL_REGEX } from '@locumsnest/core/src/lib/types/constants';

import { IUploadFileFormState } from '../../../profile/+state/profile.reducer';
import { INITIAL_UPLOAD_FILE_FORM_STATE } from '../../../profile/+state/ui';
import { isExpirationDateInFuture } from '../../../profile/+state/ui/ui.validators';
import { ResourceTypes } from '../../constants';
import {
  IBidFragmentFormState,
  IExternalStaffingCandidateBidFormState,
} from '../interfaces/external-staffing-candidate-bid-form';
import { DEFAULT_CURRENCY } from './../../../core/constants';
import {
  IBidFragmentWithRates,
  IFee,
} from './../../../interfaces/api/external-staffing-candidate-bid-entity';
import { alertStateAdapter } from './form.adapter';
import {
  ExternalStaffingCandidateBidFormMessages,
  InitializeExternalStaffingCandidateBidFormMessage,
  RemoveDocumentMessage,
} from './form.messages';
import { validateAgencyFlatFee, validateAgencyPercentageFee } from './form.validators';

export const FORM_ID = 'EXTERNAL_CANDIDATE_FORM';
export const BID_FRAGMENTS_CONTROL_ID = `${FORM_ID}.bidFragments`;
export const PROVIDER_FEE_APPROVED_CONTROL_ID = `${FORM_ID}.providerFee.approvedRate`;
export const PROVIDER_FEE_PERCENTAGE_CONTROL_ID = FORM_ID + '.providerFee.feePercentage';
export const PROVIDER_FEE_CONTROL_ID = FORM_ID + '.providerFee.fee';
export const APPROVED_RATES = `${FORM_ID}.providerFee.approvedRate`;
export const FRAGMENT_AGENCY_FEE_FORM_CONTROL_REGEX = new RegExp(
  `^${BID_FRAGMENTS_CONTROL_ID}.\\d+.agencyFee$`.replace(/\./g, '\\.'),
);
export type State = FormGroupState<IExternalStaffingCandidateBidFormState>;
export const INITIAL_FORM_ENTITY_STATE: IExternalStaffingCandidateBidFormState = {
  id: null,
  candidateEmail: '',
  documentUploadFileFormState: INITIAL_UPLOAD_FILE_FORM_STATE,
  bankAccountNumber: null,
  sortCode: '',
  nameOnAccount: '',
  providerFee: {
    fee: null,
    feePercentage: '',
    feeCurrency: DEFAULT_CURRENCY,
    excluded: true,
    approvedRate: true,
    resourcetype: ResourceTypes.flat,
  },
  grade: null,
  approvedProposedRate: true,
  directEngagementCandidate: false,
  profile: null,
  startTime: null,
  endTime: null,
  staffingCascade: null,
  bidFragments: [],
  flatRate: null,
  flatRateCurrency: null,
  staffingProvider: null,
  documentDeclarationAccepted: null,
  candidateHasBankDetails: false,
  candidateHasPreferablePaymentMethod: false,
};
export const INITIAL_FORM_STATE = createFormGroupState<IExternalStaffingCandidateBidFormState>(
  FORM_ID,
  INITIAL_FORM_ENTITY_STATE,
);
export const LEGACY_APPROVED_FEE_USER_DEFINED_PROPERTY = 'legacyApprovedProviderFee';
export const SUPPORTING_DOCUMENT_DECLARATION_USER_DEFINED_PROPERTY =
  'supportingDocumentDeclaration';
export const APPROVED_RATES_USER_DEFINED_PROPERTY = 'approvedRates';
export const PER_FRAGMENT_RATES_APPLICABLE_USER_DEFINED_PROPERTY = 'perFragmentFeesApplicable';
export const DIRECT_ENGAGEMENT_ENFORCED_USER_DEFINED_PROPERTY = 'directEngagementLocked';
export const ACTIVE_CANDIDATE_DOCUMENT_COUNT_USER_DEFINED_PROPERTY = 'activeCandidateDocumentCount';
const getActiveCandidateDocumentCount = (
  state: FormGroupState<IExternalStaffingCandidateBidFormState>,
) => state.userDefinedProperties[ACTIVE_CANDIDATE_DOCUMENT_COUNT_USER_DEFINED_PROPERTY];
const getApprovedRates = (state: FormGroupState<IExternalStaffingCandidateBidFormState>) =>
  state.userDefinedProperties[APPROVED_RATES_USER_DEFINED_PROPERTY];
const getDirectEngagementEnforced = (
  state: FormGroupState<IExternalStaffingCandidateBidFormState>,
) => state.userDefinedProperties[DIRECT_ENGAGEMENT_ENFORCED_USER_DEFINED_PROPERTY];
const getSupportingDocumentDeclaration = (
  state: FormGroupState<IExternalStaffingCandidateBidFormState>,
) => state.userDefinedProperties[SUPPORTING_DOCUMENT_DECLARATION_USER_DEFINED_PROPERTY];
export const getLegacyApprovedFeeUserDefinedProperty = (
  state: FormGroupState<IExternalStaffingCandidateBidFormState>,
) => state.userDefinedProperties[LEGACY_APPROVED_FEE_USER_DEFINED_PROPERTY];
export const calculateFragmentBasedFee = (
  jobFragments: { agencyFee?: string | null; fromTime: number | Date; toTime: number | Date }[],
) => {
  if (jobFragments.every((f) => isNumeric(f?.agencyFee))) {
    return parseFloat(
      sumBy(jobFragments, (f) => +f.agencyFee * Time.getDuration(f.fromTime, f.toTime)).toFixed(2),
    );
  }
  return null;
};
export const formatProviderFee = (providerFee: IFee) => ({
  ...providerFee,
  fee: formatMoney(providerFee.fee),
});
const updateProviderFee = (
  state: FormGroupState<IExternalStaffingCandidateBidFormState>,
  providerFee: IFee,
) =>
  updateGroup<IExternalStaffingCandidateBidFormState>({
    providerFee: setValue(formatProviderFee(providerFee)),
  })(state);

export const calculateApprovedFlatRate = (bidFragments: { flatRate?: string | number | null }[]) =>
  +(maxBy(bidFragments, (jf) => +(jf.flatRate || 0))?.flatRate || 0) || null;

const setFee = (state, bidFragments: IBidFragmentWithRates[] | IBidFragmentFormState[]) => {
  const providerFee = {
    feeCurrency: DEFAULT_CURRENCY,
    ...state.value.providerFee,
    fee: calculateFragmentBasedFee(bidFragments),
    resourcetype: ResourceTypes.flat as string,
  };
  return updateProviderFee(state, providerFee);
};
const setApprovedFee = (
  state: FormGroupState<IExternalStaffingCandidateBidFormState>,
  bidFragments: IBidFragmentWithRates[] | IBidFragmentFormState[],
  legacyRateFallback = false,
) => {
  let providerFee: IFee | null = null;
  const legacyApprovedFee: IFee = getLegacyApprovedFeeUserDefinedProperty(state);
  const calculatedApprovedRate = calculateFragmentBasedFee(bidFragments);
  if (calculatedApprovedRate) {
    providerFee = {
      feeCurrency: DEFAULT_CURRENCY,
      ...state.value.providerFee,
      fee: calculatedApprovedRate,
      approvedRate: true as boolean,
      excluded: true as boolean,
      resourcetype: ResourceTypes.flat as string,
    };
  }
  if (!calculatedApprovedRate && legacyRateFallback && legacyApprovedFee) {
    providerFee = legacyApprovedFee;
  }

  if (providerFee) {
    state = updateProviderFee(state, providerFee);
  }
  return state;
};
const setApprovedFlatRate = (
  state: FormGroupState<IExternalStaffingCandidateBidFormState>,
  bidFragments: IBidFragmentWithRates[] | IBidFragmentFormState[],
) =>
  updateGroup<IExternalStaffingCandidateBidFormState>({
    flatRate: setValue(calculateApprovedFlatRate(bidFragments) as number | string | null),
  })(state);
const validateDocumentUploadFileForm = updateGroup<IUploadFileFormState>({
  file: validate<string>([required]),
  type: validate<number>([required]),
  expirationDate: (expirationDate, state) =>
    validate<Date>([isExpirationDateInFuture(state)])(expirationDate),
  title: validate<string>([required]),
});
const validateProviderFee = updateGroup<IFee<string>>({
  resourcetype: validate<string>([
    required,
    pattern(/^(ExternalStaffingProviderFlatFee|ExternalStaffingProviderPercentageFee)$/),
  ]),
  feePercentage: (feePercentage, state) =>
    validate<string>([validateAgencyPercentageFee(state)])(feePercentage),
  fee: (fee, state) => validate<string>([validateAgencyFlatFee(state)])(fee),
});
const validationReducer = updateGroup<IExternalStaffingCandidateBidFormState>({
  candidateEmail: validate<string>([required, pattern(EMAIL_REGEX)]),
  grade: validate<number>([required]),
  directEngagementCandidate: validate<boolean>([required]),
  profile: validate<string>([required]),
  staffingCascade: validate<number>([required]),
  documentUploadFileFormState: validateDocumentUploadFileForm,
  providerFee: validateProviderFee,
});

const validateDeclaration = (state) =>
  updateGroup<IExternalStaffingCandidateBidFormState>({
    documentDeclarationAccepted: validate([
      (value: boolean) => {
        if (getSupportingDocumentDeclaration(state)) {
          return {
            ...applyVerboseName(required(value || null), 'Document declaration acceptance'),
            ...(value
              ? applyPlural(
                  applyVerboseName(
                    required(getActiveCandidateDocumentCount(state) || null),
                    'Supporting document(s)  with an expiry date after the job' +
                      ` listing's start date`,
                  ),
                )
              : {}),
          };
        }

        return null;
      },
    ]),
  })(state);
export const formStateReducer =
  createFormStateReducerWithUpdate<IExternalStaffingCandidateBidFormState>(
    validateDateRange(
      {
        from: {
          verboseName: 'effective date',
          key: 'startTime',
        },
        to: {
          verboseName: 'expiration date',
          key: 'endTime',
        },
      },
      validationReducer,
    ),
    validateDeclaration,
  );
export const markMultipleAsTouchedReducer = makeMarkMultipleAsDirtyReducer(formStateReducer);
export const alertStateReducer = alertStateAdapter.createReducer();

export function formReducer(
  state = INITIAL_FORM_STATE,
  action:
    | ExternalStaffingCandidateBidFormMessages
    | RemoveDocumentMessage
    | SetValueAction<IUploadFileFormState | string | number | boolean | IBidFragmentWithRates[]>
    | SetUserDefinedPropertyAction
    | MarkMultipleAsDirtyAction,
) {
  switch (action.type) {
    case MarkMultipleAsDirtyAction.TYPE: {
      return markMultipleAsTouchedReducer(state, action);
    }
    case InitializeExternalStaffingCandidateBidFormMessage.TYPE:
      {
        const formState: Partial<IExternalStaffingCandidateBidFormState> = get(
          action,
          'payload.externalStaffingCandidateBidFormState',
          INITIAL_FORM_STATE.value,
        );
        state = createFormGroupState<IExternalStaffingCandidateBidFormState>(
          FORM_ID,
          formState as IExternalStaffingCandidateBidFormState,
        );
        if (action.payload.userDefinedProperties) {
          state = setUserDefinedProperties(action.payload.userDefinedProperties)(state);
        }
        if (
          (getDirectEngagementEnforced(state) && !state.value.id) ||
          state.value.directEngagementCandidate
        ) {
          state = updateGroup<IExternalStaffingCandidateBidFormState>({
            directEngagementCandidate: (controlState) => disable(setValue(controlState, true)),
          })(state);
        }
      }
      break;
    case RemoveDocumentMessage.TYPE:
      {
        action = new SetValueAction(
          FORM_ID + '.documentUploadFileFormState',
          INITIAL_UPLOAD_FILE_FORM_STATE,
        );
      }
      break;
    case SetValueAction.TYPE: {
      switch (action.controlId) {
        case PROVIDER_FEE_PERCENTAGE_CONTROL_ID:
        case PROVIDER_FEE_CONTROL_ID: {
          if (action.controlId === PROVIDER_FEE_CONTROL_ID) {
            action = {
              ...action,
              value: action.value,
            };
          }
          const setValueAction = action as SetValueAction<string>;
          const providerFee = {
            fee: setValueAction.value,
            feePercentage: setValueAction.value,
            ...state.controls.providerFee.value,
          };
          state = updateProviderFee(state, providerFee);
          state = markAsDirty(state);
          break;
        }

        case PROVIDER_FEE_APPROVED_CONTROL_ID: {
          const setValueAction = action as SetValueAction<boolean>;
          if (setValueAction.value) {
            state = setApprovedFee(
              state,
              getApprovedRates(state) as IBidFragmentWithRates[] | IBidFragmentFormState[],
              true,
            );
          }
          break;
        }
      }
    }
  }

  state = formStateReducer(state, action);

  // post update handling
  switch (action.type) {
    case SetValueAction.TYPE:
      if (FRAGMENT_AGENCY_FEE_FORM_CONTROL_REGEX.test(action.controlId)) {
        state = setFee(state, state.value.bidFragments);
      }
      break;
    case SetUserDefinedPropertyAction.TYPE:
      if (
        action.controlId === FORM_ID &&
        action.name === LEGACY_APPROVED_FEE_USER_DEFINED_PROPERTY
      ) {
        state = setApprovedFee(
          state,
          state.controls.bidFragments.value as IBidFragmentWithRates[] | IBidFragmentFormState[],
          true,
        );
      }
      if (action.controlId === FORM_ID && action.name === APPROVED_RATES_USER_DEFINED_PROPERTY) {
        const approvedRates: (IBidFragmentWithRates & {
          fromTime: number;
          toTime: number;
        })[] = action.value;
        state = formStateReducer(
          state,
          new SetUserDefinedPropertyAction(
            FORM_ID,
            PER_FRAGMENT_RATES_APPLICABLE_USER_DEFINED_PROPERTY,
            approvedRates.every((ar) => ar.agencyFee),
          ),
        );
        if (state.value.approvedProposedRate) {
          state = formStateReducer(
            state,
            new SetValueAction(BID_FRAGMENTS_CONTROL_ID, approvedRates),
          );
          setApprovedFlatRate(state, approvedRates);
        }

        if (
          state.value.providerFee.approvedRate ||
          (isNil(state.value.id) && approvedRates.length)
        ) {
          state = setApprovedFee(state, approvedRates, true);
        }
      }
      if (
        action.controlId === FORM_ID &&
        action.name === DIRECT_ENGAGEMENT_ENFORCED_USER_DEFINED_PROPERTY
      ) {
        state = updateGroup<IExternalStaffingCandidateBidFormState>({
          directEngagementCandidate: (controlState) => {
            if ((action as SetUserDefinedPropertyAction).value) {
              return disable(setValue(controlState, true));
            } else {
              return enable(controlState);
            }
          },
        })(state);
      }
      break;
  }
  state = validationReducer(state);

  return {
    ...state,
    alertState: alertStateReducer(
      (
        state as FormGroupState<IExternalStaffingCandidateBidFormState> & {
          alertState: IAlertState;
        }
      ).alertState,
      action,
    ),
  };
}
