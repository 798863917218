import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get, isNil } from 'lodash-es';
import { FormGroupState } from 'ngrx-forms';

import { Time } from '@locumsnest/core/src/lib/helpers';
import { DATE_FORMAT } from '@locumsnest/core/src/lib/types/constants';

import { IGradeEntity } from '../../interfaces/api/grade-entity';
import { IHospitalEntity } from '../../interfaces/api/hospital-entity';
import { IProfileAssignmentNumbersEntity } from '../../interfaces/api/profile-assignment-numbers-entity';
import { IProfileEntity } from '../../interfaces/api/profile-entity';
import { IProfileEntityState, IProfileFeatureState, IProfileSharedState } from './interfaces';
import {
  IProfileAssignmentNumberEntry,
  IProfilePersonalInfo,
  ProfileAssignmentNumbersFilterTypes,
} from './interfaces/profile-ui-state';
import { getFormState } from './profile-search-form/profile-search-form.selectors';
import { loadingAdapter, paginationAdapter } from './profile.adapter';
import { IProfileSearchFormState, selectAll, selectEntities } from './profile.reducer';

export const getAllProfiles = (entityState: IProfileEntityState) => entityState.entities;

export const getStaffBankUnavailabilitiesState = (state: IProfileFeatureState) =>
  state.staffBankUnavailabilitiesFormState;

export const getAssignmentNumbers = (
  profileAssignmentNumbers: IProfileAssignmentNumbersEntity[],
  grades: IGradeEntity[],
  hospitals: IHospitalEntity[],
  profileAssignmentNumberFilter: ProfileAssignmentNumbersFilterTypes,
): IProfileAssignmentNumberEntry[] => {
  if (profileAssignmentNumberFilter === ProfileAssignmentNumbersFilterTypes.active) {
    profileAssignmentNumbers = profileAssignmentNumbers.filter(
      (profileAssignmentNumber) => profileAssignmentNumber.active === true,
    );
  } else if (profileAssignmentNumberFilter === ProfileAssignmentNumbersFilterTypes.inactive) {
    profileAssignmentNumbers = profileAssignmentNumbers.filter(
      (profileAssignmentNumber) => profileAssignmentNumber.active === false,
    );
  }

  const profileAssignmentNumberEntries = profileAssignmentNumbers.map((profileAssignmentNumber) => {
    const gradeEntity = grades.find((grade) => grade.id === profileAssignmentNumber.grade);
    const hospitalEntity = hospitals.find(
      (hospital) => hospital.id === profileAssignmentNumber.trust,
    );
    return {
      assignmentNumber: profileAssignmentNumber.assignmentNumber,
      assignmentCategory: profileAssignmentNumber.assignmentCategory,
      grade: isNil(profileAssignmentNumber.grade)
        ? 'Available for all grades'
        : gradeEntity
          ? gradeEntity.title
          : '',
      active: profileAssignmentNumber.active ? 'Yes' : 'No',
      effectiveEndDate: !isNil(profileAssignmentNumber.effectiveEndDate)
        ? Time.formatDate(profileAssignmentNumber.effectiveEndDate, DATE_FORMAT)
        : 'N/A',
      effectiveStartDate: !isNil(profileAssignmentNumber.effectiveStartDate)
        ? Time.formatDate(profileAssignmentNumber.effectiveStartDate, DATE_FORMAT)
        : 'N/A',
      trust: hospitalEntity ? hospitalEntity.name : '',
      trustId: profileAssignmentNumber.trust,
      payerTrust: profileAssignmentNumber.payerTrust,
      userAssignmentStatus: profileAssignmentNumber.userAssignmentStatus,
    };
  });

  return profileAssignmentNumberEntries
    .filter((entry) => entry.payerTrust === entry.trustId)
    .sort((a, b) => {
      if (a.grade < b.grade) return -1;
      if (a.grade > b.grade) return 1;

      if (a.effectiveStartDate < b.effectiveStartDate) return -1;
      if (a.effectiveStartDate > b.effectiveStartDate) return 1;

      return 0;
    });
};

export const getGmcStatusId = (profile: IProfileEntity): number =>
  profile ? profile.gmcStatus : null;

export const getDobRequiredProperty = (registrationNumber: string) =>
  `dobRequiredProfile-${registrationNumber}`;

export const getProfileSearchFormRequiresDob = (form: FormGroupState<IProfileSearchFormState>) =>
  get(form.userDefinedProperties, getDobRequiredProperty(form.value.registrationNumber), false);

export const getPersonalInformation = (entity: IProfileEntity): IProfilePersonalInfo => {
  const photos: string[] = [];
  let profilePersonalInfo: IProfilePersonalInfo;

  if (entity) {
    if (entity.photosAccepted) {
      if (entity.identityProofPhoto) {
        photos.push(entity.identityProofPhoto);
      }

      if (entity.photo) {
        photos.push(entity.photo);
      }
    }

    profilePersonalInfo = {
      personalEmail: entity.personalEmail,
      telephone: entity.telephone,
      professionalEmail: entity.professionalEmail,
      address: entity.address,
      photos,
    };
  }

  return profilePersonalInfo;
};

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectProfileState = createFeatureSelector<IProfileFeatureState & IProfileSharedState>(
  'profiles',
);

export const selectProfileEntityState = createSelector(
  selectProfileState,
  (state) => state.entityState,
);
const selectProfileEntities = createSelector(selectProfileEntityState, selectEntities);

export const selectAllProfiles = createSelector(selectProfileEntityState, selectAll);

export const selectProfileSearchFormState = createSelector(selectProfileState, getFormState);
export const selectProfileSearchFormEmail = createSelector(
  selectProfileSearchFormState,
  (profile) => profile.value.email,
);
export const selectSelectedProfileId = createSelector(
  selectProfileEntityState,
  (state) => state.selectedProfileId,
);

export const selectProfile = (id: string) =>
  createSelector(selectProfileEntities, (entities) => entities[id]);

export const selectSelectedProfile = createSelector(
  selectProfileEntities,
  selectSelectedProfileId,
  (entities, selectedProfileId) => entities[selectedProfileId],
);

export const selectProfilesByIds = (ids: string[]) =>
  createSelector(selectProfileEntities, (entities) => ids.map((id) => entities[id]));

export const selectProfileGmcStatusId = createSelector(selectSelectedProfile, getGmcStatusId);

export const selectPersonalInformation = createSelector(
  selectSelectedProfile,
  getPersonalInformation,
);

export const selectProfileSearchFormRequiresDob = createSelector(
  selectProfileSearchFormState,
  getProfileSearchFormRequiresDob,
);

export const profilePaginationSelectors = paginationAdapter.paginationSelectors(
  selectProfileState,
  loadingAdapter,
  'pagination',
  'entityState',
  false,
);

export const loadingStateSelectors = loadingAdapter.getSelectors(selectProfileState);
