import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Time } from '@locumsnest/core/src/lib/helpers';

import { IBaseJobListingEntity, IJobListingEntity } from '../../interfaces/api/job-listing-entity';
import { State as IJobListingFeatureState } from './interfaces';
import { bookingStatsAdapter, loadingAdapter, paginationAdapter } from './job-listing.adapter';
import { selectAll, selectEntities } from './job-listing.reducer';

export {
  selectIsFormInvalid,
  selectIsSectionDescriptionInvalid,
  selectIsSectionDetailsInvalid,
  selectIsSectionGradesInvalid,
  selectJobListingFormGradeValues,
  selectJobListingFormId,
  selectJobListingFormPrimaryProfessionSpecialtyValue,
  selectJobListingFormWardValue,
  selectJobListingFormRosterValue,
  selectJobListingFormProfessionValue,
  selectJobListingFormNonResidentOnCallValue,
  selectJobListingFormSiteValue,
  selectJobListingFormVacancyReason,
  selectJobListingFromForm,
  selectJobListingGradeControl,
  selectIsEmploymentPeriodInPast,
  selectIsRepeatingDateInPast,
  selectShiftCreationControlIsInvalid,
  selectShiftSchedulerControlIsInvalid,
  selectGradesSectionControlIsInvalid,
  selectJobListingFormCrossCoveringProfessionSpecialtiesValue,
  selectJobListingFormCrossCoveringProfessions,
  selectIsCrossCoveringForm,
  selectJobListingGradesAreValid,
  selectProfessionGradesAreValid,
  selectEnabledJobListingGrades,
} from './form/form.reducer';

const getByCascadeId = (cascadeId: number) => (jobListings: IJobListingEntity[]) =>
  jobListings ? jobListings.find((x) => x.staffingCascade === cascadeId) : null;

export const getJobListingPeriod = (listing: IBaseJobListingEntity) => {
  const { startTime, endTime } = listing;

  return {
    startTime,
    endTime,
  };
};

export const getJobListingsByDate =
  (date: string, includeAdhocs: boolean = true, includeUnpublished: boolean = true) =>
  (jobListings: IJobListingEntity[]) =>
    jobListings.filter((jobListing) => {
      if (!includeAdhocs && jobListing.adhoc === true) {
        return false;
      }

      if (!includeUnpublished && jobListing.published === false) {
        return false;
      }

      return Time.formatDate(jobListing.startTime) === date;
    });

export const selectJobListingState = createFeatureSelector<IJobListingFeatureState>('jobListings');

export const selectJobListingEntityState = createSelector(
  selectJobListingState,
  (state) => state.entityState,
);

export const selectJobListingEntities = createSelector(selectJobListingEntityState, selectEntities);

export const selectSelectedJobListingId = createSelector(
  selectJobListingEntityState,
  (state) => state.selectedJobListingId,
);

export const selectAllJobListings = createSelector(selectJobListingEntityState, selectAll);

export const selectJobListingsByDate = (
  date: string,
  loadAdhocs: boolean,
  loadUnpublished: boolean,
) => createSelector(selectAllJobListings, getJobListingsByDate(date, loadAdhocs, loadUnpublished));

export const selectJobListingById = (id: number) =>
  createSelector(selectJobListingEntities, (entities) => entities[id]);

export const selectJobListingByIds = (ids: number[]) =>
  createSelector(selectJobListingEntities, (entities) => ids.map((id) => entities[id]));

export const selectJobListingByCascadeId = (cascadeId) =>
  createSelector(selectAllJobListings, getByCascadeId(cascadeId));

export const selectJobListingLockedState = (id: number) =>
  createSelector(selectJobListingById(id), (jobListing) => jobListing.ratesLocked);

export const selectJobListingEscalatedState = (id: number) =>
  createSelector(selectJobListingById(id), (jobListing) => jobListing.shiftEscalated);

export const selectJobListingProfessionSpecialty = (id: number) =>
  createSelector(selectJobListingById(id), (jobListing) => jobListing.professionSpecialty);

export const selectJobListingEscalatedForAgenciesState = (id: number) =>
  createSelector(selectJobListingById(id), (jobListing) => jobListing.shiftEscalatedForAgencies);

export const selectJobListingPublishedState = (id: number) =>
  createSelector(selectJobListingById(id), (jobListing) => jobListing.published);

export const selectJobListingStartDateTime = (id: number) =>
  createSelector(selectJobListingById(id), (jobListing) => Time.getDate(jobListing.startTime));

export const selectJobListingPublishingOfficer = (id: number) =>
  createSelector(selectJobListingById(id), (jobListing) => jobListing.publishingOfficer);

export const selectJobListingCanEscalateOrRemove = (id: number, remove = false) =>
  createSelector(
    selectJobListingEscalatedState(id),
    selectJobListingEscalatedForAgenciesState(id),
    selectJobListingPublishedState(id),
    (escalated, escalatedToAgencies, published) => {
      if (escalatedToAgencies && remove) return true;
      return remove ? !escalated : escalated || !published;
    },
  );

export const selectJobListingCanEscalateOrRemoveForAgencies = (id: number, remove = false) =>
  createSelector(
    selectJobListingEscalatedState(id),
    selectJobListingEscalatedForAgenciesState(id),
    selectJobListingPublishedState(id),
    selectJobListingStartDateTime(id),
    (escalateToBank, escalatedToAgencies, published, startDate) => {
      if (!escalateToBank && !remove) {
        return true;
      }

      return (
        (remove ? !escalatedToAgencies : escalatedToAgencies || !published) ||
        Time.isBefore(startDate, Time.getDate())
      );
    },
  );

export const jobListingPaginationSelectors = paginationAdapter.paginationSelectors(
  selectJobListingState,
  loadingAdapter,
  'pagination',
  'entityState',
  false,
);

export const loadingStateSelectors = loadingAdapter.getSelectors(selectJobListingState);
export const bookingStatsSelectors = bookingStatsAdapter.getSelectors(selectJobListingState);
