import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src';
import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IExternalStaffingProviderOfficerEntity } from '../../interfaces/api/external-staffing-provider-officer-entity';

export function sortByName(
  a: IExternalStaffingProviderOfficerEntity,
  b: IExternalStaffingProviderOfficerEntity
): number {
  return a.id - b.id;
}

export const adapter: EntityAdapter<IExternalStaffingProviderOfficerEntity> =
  createEntityAdapter<IExternalStaffingProviderOfficerEntity>({
    sortComparer: sortByName,
  });

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedExternalStaffingProviderOfficerId: null,
  assignedExternalStaffingProviderOfficerId: null,
});

export const messageableFactory = MessageableFactory.forFeature<'ExternalStaffingProviderOfficer'>(
  'ExternalStaffingProviderOfficer'
);

export const signalableFactory = SignalableFactory.forFeature<'ExternalStaffingProviderOfficer'>(
  'ExternalStaffingProviderOfficer'
);

export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);
