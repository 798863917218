import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IJobListingTagsFeatureState } from './interfaces';
import { paginationAdapter } from './job-listing-tags.adapter';
import { featureKey } from './job-listing-tags.reducer';

const selectJobListingTagsState = createFeatureSelector<IJobListingTagsFeatureState>(featureKey);
export const selectJobListingTagsEntityState = createSelector(
  selectJobListingTagsState,
  (state) => state.entityState,
);
export const jobListingTagsPaginationSelectors = paginationAdapter.paginationSelectors(
  selectJobListingTagsState,
  null,
  'pagination',
  'entityState',
  false,
);
