import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';

import { IHospitalProfileLabelEntity } from '../../interfaces/api/hospital-profile-label-entity';
import { paginationAdapter } from './hospital-profile-label.adapter';

export const {
  ResetLoadingStateMessage,
  ResetPaginationMessage,
  SetLoadingStateMessage,
  UpsertMultiplePagesMessage,
  UpsertPageMessage,
} = paginationAdapter.getMessages();

export const paginationMessages = paginationAdapter.getMessages();

export class UpsertHospitalProfileLabelPageMessage extends UpsertPageMessage {}
export class UpsertHospitalProfileLabelMultiplePagesMessage extends UpsertMultiplePagesMessage {}
export class ResetHospitalProfileLabelPaginationMessage extends ResetPaginationMessage {}

export enum HospitalProfileLabelSignalTypes {
  LOAD_PROFILE_LABELS = '[HospitalProfileLabel][Signal] Load Collection',
}
export enum HospitalProfileLabelMessageTypes {
  SET_COLLECTION = '[HospitalProfileLabel][Message] Set Collection',
  ADD_ONE = '[HospitalProfileLabel][Message] Add One',
  UPDATE_ONE = '[HospitalProfileLabel][Message] Update One',
  UPSERT_ONE = '[HospitalProfileLabel][Message] Upsert One',
  DELETE_ONE = '[HospitalProfileLabel][Message] Delete One',
  ADD_MULTIPLE = '[HospitalProfileLabel][Message] Add All',
  DELETE_MULTIPLE = '[HospitalProfileLabel][Message] Delete Many',
  UPSERT_MULTIPLE = '[HospitalProfileLabel][Message] Upsert Many',
  UPDATE_MULTIPLE = '[HospitalProfileLabel][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = HospitalProfileLabelMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IHospitalProfileLabelEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = HospitalProfileLabelMessageTypes.ADD_ONE;
  constructor(public payload: { entity: IHospitalProfileLabelEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = HospitalProfileLabelMessageTypes.UPSERT_ONE;
  constructor(public payload: { entity: IHospitalProfileLabelEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = HospitalProfileLabelMessageTypes.ADD_MULTIPLE;
  constructor(public payload: { entities: IHospitalProfileLabelEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = HospitalProfileLabelMessageTypes.UPSERT_MULTIPLE;
  constructor(public payload: { entities: IHospitalProfileLabelEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = HospitalProfileLabelMessageTypes.UPDATE_ONE;
  constructor(public payload: { entity: Update<IHospitalProfileLabelEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = HospitalProfileLabelMessageTypes.UPDATE_MULTIPLE;
  constructor(public payload: { entities: Update<IHospitalProfileLabelEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = HospitalProfileLabelMessageTypes.DELETE_ONE;
  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = HospitalProfileLabelMessageTypes.DELETE_MULTIPLE;
  constructor(public payload: { ids: string[] }) {}
}

export type HospitalProfileLabelMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
