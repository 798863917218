import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';

import { IProfileLabelEntity } from '../../interfaces/api/profile-label-entity';
import { paginationAdapter } from './profile-label.adapter';

export const {
  ResetLoadingStateMessage,
  ResetPaginationMessage,
  SetLoadingStateMessage,
  UpsertMultiplePagesMessage,
  UpsertPageMessage,
} = paginationAdapter.getMessages();

export const paginationMessages = paginationAdapter.getMessages();

export class UpsertProfileLabelPageMessage extends UpsertPageMessage {}
export class UpsertProfileLabelMultiplePagesMessage extends UpsertMultiplePagesMessage {}
export class ResetProfileLabelPaginationMessage extends ResetPaginationMessage {}

export enum ProfileLabelSignalTypes {
  LOAD_PROFILE_LABELS = '[ProfileLabel][Signal] Load Collection',
}
export enum ProfileLabelMessageTypes {
  SET_COLLECTION = '[ProfileLabel][Message] Set Collection',
  ADD_ONE = '[ProfileLabel][Message] Add One',
  UPDATE_ONE = '[ProfileLabel][Message] Update One',
  UPSERT_ONE = '[ProfileLabel][Message] Upsert One',
  DELETE_ONE = '[ProfileLabel][Message] Delete One',
  ADD_MULTIPLE = '[ProfileLabel][Message] Add All',
  DELETE_MULTIPLE = '[ProfileLabel][Message] Delete Many',
  UPSERT_MULTIPLE = '[ProfileLabel][Message] Upsert Many',
  UPDATE_MULTIPLE = '[ProfileLabel][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = ProfileLabelMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IProfileLabelEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = ProfileLabelMessageTypes.ADD_ONE;
  constructor(public payload: { entity: IProfileLabelEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = ProfileLabelMessageTypes.UPSERT_ONE;
  constructor(public payload: { entity: IProfileLabelEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = ProfileLabelMessageTypes.ADD_MULTIPLE;
  constructor(public payload: { entities: IProfileLabelEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = ProfileLabelMessageTypes.UPSERT_MULTIPLE;
  constructor(public payload: { entities: IProfileLabelEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = ProfileLabelMessageTypes.UPDATE_ONE;
  constructor(public payload: { entity: Update<IProfileLabelEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = ProfileLabelMessageTypes.UPDATE_MULTIPLE;
  constructor(public payload: { entities: Update<IProfileLabelEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = ProfileLabelMessageTypes.DELETE_ONE;
  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = ProfileLabelMessageTypes.DELETE_MULTIPLE;
  constructor(public payload: { ids: string[] }) {}
}

export type ProfileLabelMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
