export interface IStaffBankMembershipRequestStatusEntity {
  readonly id: number;
  readonly display: string;
  readonly code: string;
  readonly order: number;
  readonly setByOfficer: boolean;
}

export enum StaffBankMembershipRequestStatusEnum {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  UNDER_REVIEW = 'UNDER_REVIEW',
  REJECTED = 'REJECTED',
  RE_SUBMITTED = 'RE_SUBMITTED',
  WITHDRAW = 'WITHDRAW',
}
