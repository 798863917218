<div class="profile-page">
  <div class="container">
    <div class="row profile-status-bar">
      <div class="col-sm-12">
        <locumsnest-user-status-bar
          [photoUrl]="(profile$ | async)?.photo || ''"
          [name]="(profile$ | async)?.firstName + ' ' + (profile$ | async)?.lastName"
          [showVaccinationStatus]="false"
          [vaccinationStatus]="(profile$ | async)?.compliantWithVaccine"
        >
          <div class="status-bar">
            <div class="gmc">Registration Number #{{ (profile$ | async)?.registrationNumber }}</div>
            <div class="buttons">
              <locumsnest-circular-button
                label="Email "
                icon="email"
                url="mailto:{{ (profile$ | async)?.personalEmail }}"
              />
              <locumsnest-circular-button
                label="Share profile"
                icon="share"
                [url]="shareProfileUrl$ | async"
              />
            </div>
          </div>
        </locumsnest-user-status-bar>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="line"></div>
      </div>
    </div>

    <div class="row staffbank-info">
      <div class="col-sm-3">Staffbank Memberships</div>
      <div class="col-sm-9">
        <b>{{ staffBankMemberShips$ | async }}</b>
      </div>
    </div>

    <div *ngIf="staffBankCollaborationGroups$ | async" class="row staffbank-info">
      <div class="col-sm-3">Staffbank Collaboration Groups</div>
      <div class="col-sm-9">
        <b>{{ staffBankCollaborationGroups$ | async }}</b>
      </div>
    </div>

    <div *ngIf="agencyMemberships$ | async" class="row staffbank-info">
      <div class="col-sm-3">Agency Memberships</div>
      <div class="col-sm-9">
        <b>{{ agencyMemberships$ | async }}</b>
      </div>
    </div>

    <ng-container
      *ngIf="{
        tagOptions: hospitalProfileTagOptions$ | async,
        labelOptions: hospitalProfileLabelOptions$ | async
      } as vm"
    >
      <div *ngIf="vm.tagOptions?.length || vm.labelOptions?.length" class="row staffbank-info">
        <div class="col-sm-3">Tags</div>

        <div *ngIf="vm.tagOptions?.length" class="col-sm-3">
          <locumsnest-dropdown-grouping-select
            [placeholder]="'Select passive tags'"
            [options]="vm.tagOptions"
            [multiple]="true"
            [clearButtonPosition]="'right'"
            [clearable]="false"
            [hideSelected]="false"
            [extraClass]="'blue-theme'"
            [isDisabled]="disableIfNotTagsManager$ | async"
            [ngrxFormControlState]="profileTags$ | async"
            (addOne)="onSelectProfileTag($event, 'addOne')"
            (removeOne)="onSelectProfileTag($event, 'removeOne')"
          />
        </div>

        <div *ngIf="vm.labelOptions?.length" class="col-sm-3">
          <locumsnest-dropdown-grouping-select
            [placeholder]="'Select action tags'"
            [options]="vm.labelOptions"
            [multiple]="true"
            [clearable]="false"
            [isDisabled]="disableIfNotLabelsManager$ | async"
            [clearButtonPosition]="'right'"
            [hideSelected]="false"
            [extraClass]="'orange-theme'"
            [ngrxFormControlState]="profileLabels$ | async"
            (addOne)="onSelectProfileLabel($event, 'addOne')"
            (removeOne)="onSelectProfileLabel($event, 'removeOne')"
          />
        </div>
      </div>
    </ng-container>
    <div class="row tabs-container">
      <div class="col-sm-12">
        <locumsnest-tabs (selectedTab)="onSelectTab($event)">
          <div
            *locumsnestTab="
              'General';
              isActive: (selectedTab$ | async) === generalTab;
              id: generalTab
            "
          >
            <locumsnest-general-section
              [personalInformation]="personalInformation$ | async"
              [profile]="profile$ | async"
              [gmcStatus]="gmcStatus$ | async"
              [notes]="profileNotes$ | async"
              [noteFormState]="noteFormState$ | async"
              [workedHours]="workedHours$ | async"
              [partOfBank]="partOfBank$ | async"
              [memberOfCollaboration]="memberOfCollaboration$ | async"
              [licensed]="licensed$ | async"
              [workedForOrganization]="workedForOrganization$ | async"
              [workedForDepartment]="workedForDepartment$ | async"
              [hasEmploymentHistory]="hasEmploymentHistory$ | async"
              [profileOnVisaRestrictions]="profileOnVisaRestrictions$ | async"
              [showFlaggedNotes]="showFlaggedNotes$ | async"
              (loadMoreProfileNotes)="onLoadMoreProfileNotes()"
              (deleteNote)="onDeleteNote($event)"
              (flagNote)="onFlagNote($event)"
              (toggleFlaggedNotes)="onToggleFlaggedNotes($event)"
              (submitNewNote)="onSubmitNewNote($event)"
            />
          </div>
          <div
            *locumsnestTab="
              'Digital Passport';
              isActive: (selectedTab$ | async) === passportTab;
              id: passportTab
            "
          >
            <locumsnest-digital-passport-tab
              [passportDocs]="passportDocs$ | async"
              [allowFileCopy]="hasAddSafeboxCertificatePermission$ | async"
              [isSafebox]="false"
              [employmentHistoryFilter]="employmentHistoryFilter$ | async"
              [employmentHistoryFilterButtons]="employmentHistoryFilterButtons"
              [employmentHistory]="employmentHistory$ | async"
              [profileAssignmentNumbersFilter]="profileAssignmentNumbersFilter$ | async"
              [profileAssignmentNumberEntries]="profileAssignmentNumberEntries$ | async"
              [hasAddSafeboxCertificatePermission]="hasAddSafeboxCertificatePermission$ | async"
              [hasHospitalProfessionConfiguration]="hasHospitalProfessionConfiguration$ | async"
              (loadMoreEmploymentHistory)="onLoadMoreEmploymentHistory()"
              (updateEmploymentHistoryFilter)="onUpdateEmploymentHistoryFilter($event)"
              (updateProfileAssignmentNumbersFilter)="
                onUpdateProfileAssignmentNumbersFilter($event)
              "
              (copyToSafebox)="onCopyToSafebox($event)"
            />
          </div>

          <div
            *locumsnestTab="
              'Digital Safebox';
              isActive: (selectedTab$ | async) === safeboxTab;
              id: safeboxTab
            "
          >
            <locumsnest-digital-safebox-tab
              [safeboxCertificates]="certificateDocs$ | async"
              [safeboxReferences]="referenceDocs$ | async"
              [allowFileCopy]="false"
              [isSafebox]="true"
              [fileTypes]="subjectOptions$ | async"
              [file]="(uploadFileForm$ | async).controls.file"
              [isDisabled]="false"
              [fileUploadForm]="uploadFileForm$ | async"
              [isTitleVisible]="isTitleVisible$ | async"
              [showFileDetails]="true"
              [hasAddSafeboxCertificatePermission]="hasAddSafeboxCertificatePermission$ | async"
              [hasChangeSafeboxCertificatePermission]="
                hasChangeSafeboxCertificatePermission$ | async
              "
              [hasDeleteSafeboxCertificatePermission]="
                hasDeleteSafeboxCertificatePermission$ | async
              "
              [hasAddSafeboxReferencePermission]="hasAddSafeboxReferencePermission$ | async"
              (resetUploadFileForm)="onResetUploadFileForm()"
              (confirmUploadFile)="onConfirmUploadFile()"
              (renewExpirationDate)="onRenewExpirationDate($event)"
              (deleteDocument)="onDeleteDocument($event)"
              (shareDocuments)="onShareDocuments($event)"
            />
          </div>
          <div
            *locumsnestTab="
              'Send Message';
              isActive: (selectedTab$ | async) === sendMessageTab;
              id: sendMessageTab
            "
          >
            <locumsnest-message-section
              [titleNewMessage]="'Send message about compliance'"
              [subjectPlaceholder]="'The message is in relation to'"
              [subjectOptions]="subjectOptions$ | async"
              [messageNote]="'Type Message here'"
              [helpText]="
                'An email will be sent to the employee with your message and your contact details'
              "
              [buttonLabel]="'Submit'"
              [titlePreviousMessages]="'Previous Messages'"
              [messages]="messages$ | async"
              [contactForm]="contactForm$ | async"
              (sendMessage)="sendComplianceMessage()"
              (loadMore)="onLoadMoreComplianceMessages()"
            />
          </div>
          <div
            *locumsnestTab="
              'Unavailability';
              isActive: (selectedTab$ | async) === unavailabilityTab;
              id: unavailabilityTab
            "
          >
            <locumsnest-staff-bank-unavailability
              [unavailabilityForm]="unavailabilityForm$ | async"
              [canEdit]="partOfBank$ | async"
              [unavailabilityReasons]="unavailabilityReasonOptions$ | async"
              [unavailabilities]="unavailabilities$ | async"
            />
          </div>
          <div
            *locumsnestTab="
              'Agency Documents';
              isActive: (selectedTab$ | async) === agencyDocumentsTab;
              id: agencyDocumentsTab
            "
          >
            <locumsnest-agency-documents-tab [agencyDocs]="agencyDocs$ | async" />
          </div>
        </locumsnest-tabs>
      </div>
    </div>
  </div>
  <div *ngIf="showShareDocumentsModal" class="overlay">
    <div
      *ngIf="cloneDocumentToHospitalFormState$ | async as cloneDocumentToHospitalFormState"
      class="share-modal"
    >
      <locumsnest-modal
        [show]="showShareDocumentsModal"
        [showCloseButton]="false"
        [title]="getModalTitle((profile$ | async)?.firstName, shareDocumentCategory)"
        (close)="closeShareDocumentsModal()"
      >
        <ng-container body>
          <div *ngIf="shareDocumentCategory === 'Certificates'">
            <div class="section-title">Certificates</div>
            <locumsnest-dropdown-grouping-select
              [options]="safeboxCertificateOptions$ | async"
              [placeholder]="'Certificates'"
              [multiple]="true"
              [hideSelected]="true"
              [clearButtonPosition]="'right'"
              [extraClass]="'dark-blue-theme'"
              [maxDisplayItems]="100"
              [ngrxFormControlState]="cloneDocumentToHospitalFormState.controls.documents"
            />
          </div>
          <div *ngIf="shareDocumentCategory === 'References'">
            <div class="section-title">References</div>
            <locumsnest-dropdown-grouping-select
              [options]="safeboxReferenceOptions$ | async"
              [placeholder]="'References'"
              [multiple]="true"
              [clearButtonPosition]="'right'"
              [hideSelected]="true"
              [extraClass]="'dark-blue-theme'"
              [maxDisplayItems]="100"
              [ngrxFormControlState]="cloneDocumentToHospitalFormState.controls.documents"
            />
          </div>
          <div class="section-title">Receiving Organization</div>
          <locumsnest-dropdown-grouping-select
            [options]="staffBankCollaborationGroupTrustOptions$ | async"
            [placeholder]="'Receiving Organization'"
            [multiple]="true"
            [hideSelected]="true"
            [clearButtonPosition]="'right'"
            [extraClass]="'dark-blue-theme'"
            [maxDisplayItems]="100"
            [ngrxFormControlState]="cloneDocumentToHospitalFormState.controls.hospitals"
          />
          <div class="terms">
            <locumsnest-check-box
              [displayText]="
                'I confirm, in sharing these documents, I am complying with GDPR and other related privacy laws and organisation rules & policies, including but not limited to gaining appropriate consent to sharing and handling personal information'
              "
              [size]="'normal'"
              [theme]="'blue'"
              [ngrxFormControlState]="cloneDocumentToHospitalFormState.controls.gdprCheck"
            />
          </div>
          <div class="terms">
            <locumsnest-check-box
              [displayText]="
                'I understand that by law the worker can request to view any of their personal information being shared'
              "
              [size]="'normal'"
              [theme]="'blue'"
              [ngrxFormControlState]="cloneDocumentToHospitalFormState.controls.legalCheck"
            />
          </div>
        </ng-container>
        <ng-container footer>
          <div class="panel-footer">
            <div class="footer-buttons">
              <locumsnest-button
                [buttonType]="'onboarding-back'"
                [hasBoldFont]="true"
                [size]="'medium'"
                (click)="closeShareDocumentsModal()"
              >
                Close
              </locumsnest-button>
              <locumsnest-button
                [buttonType]="'onboarding-next'"
                [hasBoldFont]="true"
                [size]="'medium'"
                [disabled]="cloneDocumentToHospitalFormState.isInvalid"
                (click)="onShareButtonClicked(shareDocumentCategory)"
              >
                Share
              </locumsnest-button>
            </div>
          </div>
        </ng-container>
      </locumsnest-modal>
    </div>
  </div>
</div>
