import { messageableFactory as actionableFactory, alertStateAdapter } from './list-state.adapter';
import { IApplicationListState } from '../interfaces/list-state';
import { IDeclineApplicationReasonEntity } from '../../../interfaces/api/decline-application-reason-entity';

// initialization of mixin factory
export const {
  ResetErrorMessage: ResetErrorAdapterMessage,
  AlertErrorMessage: AlertErrorAdapterMessage,
} = alertStateAdapter.getMessages();
export class ResetErrorMessage extends ResetErrorAdapterMessage {}
export class AlertErrorMessage extends AlertErrorAdapterMessage {}

export class InitializeApplicationListStateMessage extends actionableFactory.create<
  'Initialize Application List State',
  { applicationListState?: IApplicationListState }
>('Initialize Application List State') {}

export class AddDeclinedApplicationMessage extends actionableFactory.create<
  'Add Declined Application Message',
  { id: number; otherReason: IDeclineApplicationReasonEntity }
>('Add Declined Application Message') {}

export class RemoveDeclinedApplicationMessage extends actionableFactory.create<
  'Remove Declined Application Message',
  { id: number }
>('Remove Declined Application Message') {}
export class SelectAdjacentApplicationRow extends actionableFactory.create<
  'Set Selected Adjacent Application Row',
  { multiResourceId: string }
>('Set Selected Adjacent Application Row') {}
export class DeSelectAdjacentApplicationRow extends actionableFactory.create<
  'Reset Selected Adjacent Application Row',
  { multiResourceId: string }
>('Reset Selected Adjacent Application Row') {}
export type ApplicationListStateMessages =
  | InitializeApplicationListStateMessage
  | ResetErrorMessage
  | AlertErrorMessage
  | AddDeclinedApplicationMessage
  | RemoveDeclinedApplicationMessage
  | SelectAdjacentApplicationRow
  | DeSelectAdjacentApplicationRow;
