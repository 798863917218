import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { IStaffBankCollaborationGroupEntity } from '../../interfaces/api/staff-bank-collaboration-group-entity';

export enum StaffBankCollaborationGroupSignalTypes {
  LOAD_STAFF_BANK_COLLABORATION_GROUPS = '[StaffBankCollaborationGroup][Signal] Load Collection',
}
export enum StaffBankCollaborationGroupMessageTypes {
  SET_COLLECTION = '[StaffBankCollaborationGroup][Message] Set Collection',
  ADD_ONE = '[StaffBankCollaborationGroup][Message] Add One',
  UPDATE_ONE = '[StaffBankCollaborationGroup][Message] Update One',
  UPSERT_ONE = '[StaffBankCollaborationGroup][Message] Upsert One',
  DELETE_ONE = '[StaffBankCollaborationGroup][Message] Delete One',
  ADD_MULTIPLE = '[StaffBankCollaborationGroup][Message] Add All',
  DELETE_MULTIPLE = '[StaffBankCollaborationGroup][Message] Delete Many',
  UPSERT_MULTIPLE = '[StaffBankCollaborationGroup][Message] Upsert Many',
  UPDATE_MULTIPLE = '[StaffBankCollaborationGroup][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = StaffBankCollaborationGroupMessageTypes.SET_COLLECTION;
  constructor(
    public payload: { staffBankCollaborationGroups: IStaffBankCollaborationGroupEntity[] }
  ) {}
}

export class AddOneMessage implements Action {
  readonly type = StaffBankCollaborationGroupMessageTypes.ADD_ONE;

  constructor(
    public payload: { staffBankCollaborationGroup: IStaffBankCollaborationGroupEntity }
  ) {}
}

export class UpsertOneMessage implements Action {
  readonly type = StaffBankCollaborationGroupMessageTypes.UPSERT_ONE;

  constructor(
    public payload: { staffBankCollaborationGroup: IStaffBankCollaborationGroupEntity }
  ) {}
}

export class AddMultipleMessage implements Action {
  readonly type = StaffBankCollaborationGroupMessageTypes.ADD_MULTIPLE;

  constructor(
    public payload: { staffBankCollaborationGroups: IStaffBankCollaborationGroupEntity[] }
  ) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = StaffBankCollaborationGroupMessageTypes.UPSERT_MULTIPLE;

  constructor(
    public payload: { staffBankCollaborationGroups: IStaffBankCollaborationGroupEntity[] }
  ) {}
}

export class UpdateOneMessage implements Action {
  readonly type = StaffBankCollaborationGroupMessageTypes.UPDATE_ONE;

  constructor(
    public payload: { staffBankCollaborationGroup: Update<IStaffBankCollaborationGroupEntity> }
  ) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = StaffBankCollaborationGroupMessageTypes.UPDATE_MULTIPLE;

  constructor(
    public payload: { staffBankCollaborationGroups: Update<IStaffBankCollaborationGroupEntity>[] }
  ) {}
}

export class DeleteOneMessage implements Action {
  readonly type = StaffBankCollaborationGroupMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = StaffBankCollaborationGroupMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type StaffBankCollaborationGroupMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
