import { cloneDeep } from 'lodash-es';
import {
  createFormGroupState,
  createFormStateReducerWithUpdate,
  FormGroupState,
  setUserDefinedProperty,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { maxLength, required } from 'ngrx-forms/validation';

import { IAlertState } from '@locumsnest/core/src/lib/adapters/alert-state-adapter/interfaces';

import {
  IApplicationListState,
  IDeclineFormsState,
  IDeclineFormState,
} from '../interfaces/list-state';
import { alertStateAdapter } from './list-state.adapter';
import {
  AddDeclinedApplicationMessage,
  ApplicationListStateMessages,
  DeSelectAdjacentApplicationRow,
  InitializeApplicationListStateMessage,
  RemoveDeclinedApplicationMessage,
  SelectAdjacentApplicationRow,
} from './list-state.messages';

export * from './list-state.selectors';
export const FORM_ID = 'JOB_LISTING_SEARCH_FILTER_FORM';
export type State = IApplicationListState & { alertState?: IAlertState };

export const INITIAL_STATE = {
  approveForms: {},
  declineForms: {},
  selectedAdjacentApplicationRows: [],
};

export const INITIAL_DECLINE_FORM_STATE: IDeclineFormState = {
  declineReason: null,
  declineDetails: '',
};

const declineFormValidationReducer = (formState: FormGroupState<IDeclineFormState>) =>
  updateGroup<IDeclineFormState>({
    declineReason: validate<number>([required]),
    declineDetails: (declineDetails, state) => {
      if (state.value.declineReason === state.userDefinedProperties.otherDeclineReasonId) {
        return validate<string>([required, maxLength(300)])(declineDetails);
      }
      return validate<string>([])(declineDetails);
    },
  })(formState);

export const approveFormReducer = createFormStateReducerWithUpdate<Record<string, unknown>>([]);
export const declineFormReducer = createFormStateReducerWithUpdate<IDeclineFormState>([]);

const alertStateReducer = alertStateAdapter.createReducer();

function declineFormsReducer(state: IDeclineFormsState, action: ApplicationListStateMessages) {
  const newState = {};
  for (const key in state) {
    if (state.hasOwnProperty(key)) {
      newState[key] = declineFormReducer(state[key], action);
      newState[key] = declineFormValidationReducer(newState[key]);
    }
  }
  return newState;
}

export function stateReducer(
  state: IApplicationListState = INITIAL_STATE,
  action: ApplicationListStateMessages,
): IApplicationListState {
  switch (action.type) {
    case InitializeApplicationListStateMessage.TYPE: {
      return { ...INITIAL_STATE, ...action.payload };
    }
    case SelectAdjacentApplicationRow.TYPE: {
      const { multiResourceId } = (action as SelectAdjacentApplicationRow).payload;
      return {
        ...state,
        selectedAdjacentApplicationRows: [
          ...state.selectedAdjacentApplicationRows,
          multiResourceId,
        ],
      };
    }
    case DeSelectAdjacentApplicationRow.TYPE: {
      const { multiResourceId } = action.payload;
      return {
        ...INITIAL_STATE,
        selectedAdjacentApplicationRows: state.selectedAdjacentApplicationRows.filter(
          (x) => x !== multiResourceId,
        ),
      };
    }
    case AddDeclinedApplicationMessage.TYPE:
      {
        const applicationId = (action as AddDeclinedApplicationMessage).payload.id;
        const otherReason = (action as AddDeclinedApplicationMessage).payload.otherReason;
        const declineForms = {
          ...state.declineForms,
        };

        declineForms[applicationId] = createFormGroupState<IDeclineFormState>(
          'DECLINE_APPLICATION_' + applicationId + '_FORM',
          INITIAL_DECLINE_FORM_STATE,
        );
        declineForms[applicationId] = setUserDefinedProperty(
          'otherDeclineReasonId',
          otherReason.id,
        )(declineForms[applicationId]);

        state = {
          ...state,
          declineForms,
        };
      }
      break;
    case RemoveDeclinedApplicationMessage.TYPE:
      {
        const applicationId = (action as RemoveDeclinedApplicationMessage).payload.id;
        if (state.declineForms.hasOwnProperty(applicationId)) {
          const declineForms = cloneDeep(state.declineForms);
          delete declineForms[applicationId];

          state = {
            ...state,
            declineForms,
          };
        }
      }
      break;
  }

  state = {
    ...state,
    declineForms: declineFormsReducer(state.declineForms, action),
  };

  return state;
}

export function reducer(state: State, action) {
  const form = stateReducer(state, action);
  const alertState = alertStateReducer(state ? state.alertState : undefined, action);
  return {
    ...form,
    alertState,
  };
}
