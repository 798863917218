import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { paginationAdapter } from './trust-external-staffing-provider-tier.adapter';
import { ITrustExternalStaffingProviderTierEntity } from '../../interfaces/api/trust-external-staffing-provider-tier-entity';

export const trustExternalStaffingProviderTierPaginationMessages = paginationAdapter.getMessages();

export class UpsertTrustExternalStaffingProviderTierPageMessage extends trustExternalStaffingProviderTierPaginationMessages.UpsertPageMessage {}
export class UpsertTrustExternalStaffingProviderTierMultiplePagesMessage extends trustExternalStaffingProviderTierPaginationMessages.UpsertMultiplePagesMessage {}
export class ResetTrustExternalStaffingProviderTierPaginationMessage extends trustExternalStaffingProviderTierPaginationMessages.ResetPaginationMessage {}

export enum TrustExternalStaffingProviderTierSignalTypes {
  // eslint-disable-next-line max-len
  LOAD_TRUST_EXTERNAL_STAFFING_PROVIDER_TIERS = '[TrustExternalStaffingProviderTier][Signal] Load Collection',
}
export enum TrustExternalStaffingProviderTierMessageTypes {
  SET_COLLECTION = '[TrustExternalStaffingProviderTier][Message] Set Collection',
  ADD_ONE = '[TrustExternalStaffingProviderTier][Message] Add One',
  UPDATE_ONE = '[TrustExternalStaffingProviderTier][Message] Update One',
  UPSERT_ONE = '[TrustExternalStaffingProviderTier][Message] Upsert One',
  DELETE_ONE = '[TrustExternalStaffingProviderTier][Message] Delete One',
  ADD_MULTIPLE = '[TrustExternalStaffingProviderTier][Message] Add All',
  DELETE_MULTIPLE = '[TrustExternalStaffingProviderTier][Message] Delete Many',
  UPSERT_MULTIPLE = '[TrustExternalStaffingProviderTier][Message] Upsert Many',
  UPDATE_MULTIPLE = '[TrustExternalStaffingProviderTier][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = TrustExternalStaffingProviderTierMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: ITrustExternalStaffingProviderTierEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = TrustExternalStaffingProviderTierMessageTypes.ADD_ONE;

  constructor(public payload: { entity: ITrustExternalStaffingProviderTierEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = TrustExternalStaffingProviderTierMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: ITrustExternalStaffingProviderTierEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = TrustExternalStaffingProviderTierMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: ITrustExternalStaffingProviderTierEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = TrustExternalStaffingProviderTierMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: ITrustExternalStaffingProviderTierEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = TrustExternalStaffingProviderTierMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<ITrustExternalStaffingProviderTierEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = TrustExternalStaffingProviderTierMessageTypes.UPDATE_MULTIPLE;

  constructor(
    public payload: {
      entities: Update<ITrustExternalStaffingProviderTierEntity>[];
    }
  ) {}
}

export class DeleteOneMessage implements Action {
  readonly type = TrustExternalStaffingProviderTierMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = TrustExternalStaffingProviderTierMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type TrustExternalStaffingProviderTierMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
