import { IPaginatedEntityState } from '@locumsnest/core/src/lib/interfaces/pagination';

import { IProfileFlagEntity } from '../../interfaces/api/profile-flag-entity';
import { IProfileFlagEntityState } from './interfaces';
import { adapter, initialState, paginationAdapter } from './profile-flag.adapter';
import {
  ProfileFlagMessageTypes as MessageTypes,
  ProfileFlagMessages,
} from './profile-flag.messages';

export * from './interfaces';

export const paginationReducer = paginationAdapter.createReducer();
export type IProfileFlagPaginatedEntityState = IProfileFlagEntityState &
  IPaginatedEntityState<IProfileFlagEntity>;
export function entityReducer(
  state = initialState,
  action: ProfileFlagMessages
): IProfileFlagEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.entity, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.entity, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.entities, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.entities, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }
    default: {
      return state;
    }
  }
}

export function paginatedEntityStateReducer(
  state: IProfileFlagPaginatedEntityState,
  action: ProfileFlagMessages
) {
  let pagination;

  if (state) {
    pagination = state.pagination;
  }

  return {
    ...entityReducer(state, action),
    pagination: {
      ...paginationReducer(pagination, action),
    },
  };
}
export const { selectIds, selectEntities, selectAll, selectTotal } =
  paginationAdapter.getEntitySelectors();
