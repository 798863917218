import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IExternalStaffingProviderOfficerEntity } from '../../interfaces/api/external-staffing-provider-officer-entity';

@Injectable({
  providedIn: 'root',
})
export class ExternalStaffingProviderOfficerPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IExternalStaffingProviderOfficerEntity
> {
  protected readonly endpoint = 'externalStaffingProviderOfficer';
}
