import { alertStateAdapter, messageableFactory as actionableFactory } from './form.adapter';

export const {
  ResetErrorMessage: ResetErrorAdapterMessage,
  AlertErrorMessage: AlertErrorAdapterMessage,
} = alertStateAdapter.getMessages();
export class ResetErrorMessage extends ResetErrorAdapterMessage {}
export class AlertErrorMessage extends AlertErrorAdapterMessage {}

export class InitializeStaffingCascadeFormMessage extends actionableFactory.create<
  'Initialize Staffing Cascade Confirmation Message',
  Record<string, never>
>('Initialize Staffing Cascade Confirmation Message') {}

export type StaffingCascadeWidgetUiMessages =
  | InitializeStaffingCascadeFormMessage
  | AlertErrorMessage
  | ResetErrorMessage;
