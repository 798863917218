import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src';
import { createEntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';

import { IExternalStaffingProviderTierEntity } from '../../interfaces/api/external-staffing-provider-tier-entity';

const namespace = 'ExternalStaffingProviderTier';

function sortByName(
  a: IExternalStaffingProviderTierEntity,
  b: IExternalStaffingProviderTierEntity
): number {
  return a.id - b.id;
}

const adapter = createEntityAdapter<IExternalStaffingProviderTierEntity>({
  sortComparer: sortByName,
});

const initialState = adapter.getInitialState({ selectedExternalStaffingProviderTierId: null });
const messageableFactory = MessageableFactory.forFeature<typeof namespace>(namespace);
const signalableFactory = SignalableFactory.forFeature<typeof namespace>(namespace);
const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export { adapter, initialState, paginationAdapter };
