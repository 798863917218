import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src';
import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IExternalStaffingCandidateDocumentEntity } from '../../interfaces/api/external-staffing-candidate-document-entity';

export function sortByName(
  a: IExternalStaffingCandidateDocumentEntity,
  b: IExternalStaffingCandidateDocumentEntity
): number {
  return a.id - b.id;
}

export const adapter: EntityAdapter<IExternalStaffingCandidateDocumentEntity> =
  createEntityAdapter<IExternalStaffingCandidateDocumentEntity>({
    sortComparer: sortByName,
  });

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedExternalStaffingCandidateDocumentId: null,
});

export const messageableFactory =
  MessageableFactory.forFeature<'ExternalStaffingCandidateDocument'>(
    'ExternalStaffingCandidateDocument'
  );

export const signalableFactory = SignalableFactory.forFeature<'ExternalStaffingCandidateDocument'>(
  'ExternalStaffingCandidateDocument'
);

export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);
