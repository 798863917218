import { inject, Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { isNumber, isString } from 'lodash-es';
import { mergeMap, Observable, of } from 'rxjs';

import { Query, StateService } from '@locumsnest/core/src';
import { filterNonEmpty } from '@locumsnest/core/src/lib/ngrx/operators';

import {
  IStaffBankMembershipRequestStatusEntity,
  StaffBankMembershipRequestStatusEnum,
} from '../../interfaces/api/staff-bank-membership-request-status-entity';
import {
  UpsertMultipleMessage,
  UpsertOneMessage,
} from './staff-bank-membership-request-status.messages';
import { StaffBankMembershipRequestStatusPersistenceService } from './staff-bank-membership-request-status.persistence.service';
import {
  selectAllStaffBankMembershipRequestStatuses,
  selectApprovedStaffBankMembershipRequestStatus,
  selectStaffBankMembershipRequestStatus,
  selectStaffBankMembershipRequestStatusByCode,
  selectStaffBankMembershipRequestStatusOptions,
} from './staff-bank-membership-request-status.selectors';

@Injectable({
  providedIn: 'root',
})
export class StaffBankMembershipRequestStatusService extends StateService<IStaffBankMembershipRequestStatusEntity> {
  protected persistenceService = inject(StaffBankMembershipRequestStatusPersistenceService);
  private store = inject(Store);

  getAll() {
    return this.store.pipe(select(selectAllStaffBankMembershipRequestStatuses), filterNonEmpty);
  }

  getStaffBankMembershipRequestStatus(id: number) {
    return this.store.pipe(select(selectStaffBankMembershipRequestStatus(id)));
  }

  getStaffBankMembershipRequestStatusOptions(setForOfficer = false) {
    return this.store.pipe(select(selectStaffBankMembershipRequestStatusOptions(setForOfficer)));
  }

  getStaffBankMembershipRequestStatusByCode(code: string) {
    return this.store.pipe(select(selectStaffBankMembershipRequestStatusByCode(code)));
  }

  getApprovedStaffBankMembershipRequestStatus() {
    return this.store.pipe(select(selectApprovedStaffBankMembershipRequestStatus));
  }

  getRejectStaffBankMembershipRequestStatus() {
    return this.getStaffBankMembershipRequestStatusByCode(
      StaffBankMembershipRequestStatusEnum.REJECTED,
    );
  }

  getWithdrawStaffBankMembershipRequestStatus() {
    return this.getStaffBankMembershipRequestStatusByCode(
      StaffBankMembershipRequestStatusEnum.WITHDRAW,
    );
  }

  fetch(query?: Query) {
    if (isString(query) || isNumber(query))
      return this.persistenceService.retrieve(query).pipe(
        mergeMap((staffBankMembershipRequestStatus) => {
          const actions: Observable<Action>[] = [
            of(new UpsertOneMessage({ staffBankMembershipRequestStatus })),
          ];

          return actions;
        }),
        mergeMap((x) => x),
      );

    return this.persistenceService.retrieve(query).pipe(
      mergeMap(({ results }) => {
        const actions: Observable<Action>[] = [
          of(new UpsertMultipleMessage({ staffBankMembershipRequestStatuses: results })),
        ];

        return actions;
      }),
      mergeMap((x) => x),
    );
  }
}
