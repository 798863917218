import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IStaffBankMembershipEntity } from '../../interfaces/api/staff-bank-membership-entity';
import { IStaffBankMembershipFeatureState, IStaffBankMembershipSharedState } from './interfaces';
import { loadingAdapter, paginationAdapter } from './staff-bank-membership.adapter';
import { selectAll, selectEntities } from './staff-bank-membership.reducer';

const getStaffBankMembershipByProfile =
  (id: string) => (staffBankMemberships: IStaffBankMembershipEntity[]) =>
    staffBankMemberships.filter((staffBankMembership) => staffBankMembership.profile === id);

const getStaffBankMembershipByProfiles =
  (ids: string[]) => (staffBankMemberships: IStaffBankMembershipEntity[]) =>
    staffBankMemberships
      ? staffBankMemberships.filter(
          (staffBankMembership) => ids.indexOf(staffBankMembership.profile) >= 0
        )
      : [];

const getHospitalIds = (memberships: IStaffBankMembershipEntity[]) =>
  memberships.map((x) => x.hospital);

/**
 * Create Feature Selectors based on the above selectors
 */

export const selectStaffBankMembershipState = createFeatureSelector<
  IStaffBankMembershipFeatureState & IStaffBankMembershipSharedState
>('staffBankMemberships');

export const selectStaffBankMembershipEntityState = createSelector(
  selectStaffBankMembershipState,
  (state) => state.entityState
);

export const selectStaffBankMembershipEntities = createSelector(
  selectStaffBankMembershipEntityState,
  selectEntities
);

export const selectAllStaffBankMemberships = createSelector(
  selectStaffBankMembershipEntityState,
  selectAll
);

export const selectStaffBankMembership = (id: number) =>
  createSelector(selectStaffBankMembershipEntities, (entities) => entities[id]);

export const selectStaffBankMembershipByProfile = (id: string) =>
  createSelector(selectAllStaffBankMemberships, getStaffBankMembershipByProfile(id));

export const selectStaffBankMembershipByProfiles = (ids: string[]) =>
  createSelector(selectAllStaffBankMemberships, getStaffBankMembershipByProfiles(ids));

export const selectStaffBankMembershipHospitalsIdsByProfile = (id: string) =>
  createSelector(selectStaffBankMembershipByProfile(id), getHospitalIds);

export const staffBankMembershipPaginationSelectors = paginationAdapter.paginationSelectors(
  selectStaffBankMembershipEntityState,
  loadingAdapter
);
