import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { IStaffingCascadeEntity } from '../../interfaces/api/staffing-cascade-entity';

export enum StaffingCascadeSignalTypes {
  LOAD_STAFFING_CASCADES = '[StaffingCascade][Signal] Load Collection',
}
export enum StaffingCascadeMessageTypes {
  SET_COLLECTION = '[StaffingCascade][Message] Set Collection',
  ADD_ONE = '[StaffingCascade][Message] Add One',
  UPDATE_ONE = '[StaffingCascade][Message] Update One',
  UPSERT_ONE = '[StaffingCascade][Message] Upsert One',
  DELETE_ONE = '[StaffingCascade][Message] Delete One',
  ADD_MULTIPLE = '[StaffingCascade][Message] Add All',
  DELETE_MULTIPLE = '[StaffingCascade][Message] Delete Many',
  UPSERT_MULTIPLE = '[StaffingCascade][Message] Upsert Many',
  UPDATE_MULTIPLE = '[StaffingCascade][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = StaffingCascadeMessageTypes.SET_COLLECTION;
  constructor(public payload: { staffingCascades: IStaffingCascadeEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = StaffingCascadeMessageTypes.ADD_ONE;

  constructor(public payload: { staffingCascade: IStaffingCascadeEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = StaffingCascadeMessageTypes.UPSERT_ONE;

  constructor(public payload: { staffingCascade: IStaffingCascadeEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = StaffingCascadeMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { staffingCascades: IStaffingCascadeEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = StaffingCascadeMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { staffingCascades: IStaffingCascadeEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = StaffingCascadeMessageTypes.UPDATE_ONE;

  constructor(public payload: { staffingCascade: Update<IStaffingCascadeEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = StaffingCascadeMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { staffingCascades: Update<IStaffingCascadeEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = StaffingCascadeMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = StaffingCascadeMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type StaffingCascadeMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
