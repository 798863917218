/* eslint-disable @typescript-eslint/naming-convention */
import {
  ISetEmploymentHistoryFilterPayload,
  ISetProfileAssignmentNumbersFilterPayload,
} from '../interfaces';
import { ICertificateTypeEntity } from './../../../interfaces/api/certificate-type-entity';
import { messageableFactory as actionableFactory, alertStateAdapter } from './ui.adapter';

// initialization of mixin factory
export const {
  ResetErrorMessage: ResetErrorAdapterMessage,
  AlertErrorMessage: AlertErrorAdapterMessage,
} = alertStateAdapter.getMessages();
export class ResetErrorMessage extends ResetErrorAdapterMessage {}
export class AlertErrorMessage extends AlertErrorAdapterMessage {}

export class InitializeProfileUiMessage extends actionableFactory.create<
  'Initialize Profile Ui Message',
  Record<string, never>
>('Initialize Profile Ui Message') {}

export class SetEmploymentHistoryFilterMessage extends actionableFactory.create<
  'Set Employment History Filters',
  ISetEmploymentHistoryFilterPayload
>('Set Employment History Filters') {}

export class SetProfileAssignmentNumbersFilterMessage extends actionableFactory.create<
  'Set Profile Assignment Numbers Filters',
  ISetProfileAssignmentNumbersFilterPayload
>('Set Profile Assignment Numbers Filters') {}

export class RemoveFileMessage extends actionableFactory.create<'Remove File', void>(
  'Remove File'
) {}

export class SetOtherCertificateTypeMessage extends actionableFactory.create<
  'Set Other Certificate Type Message',
  { certificateType: ICertificateTypeEntity }
>('Set Other Certificate Type Message') {}

export class AddCopiedCertificateMessage extends actionableFactory.create<
  'Add Copied Certificate Message',
  { id: number }
>('Add Copied Certificate Message') {}

export class AddCopiedReferenceMessage extends actionableFactory.create<
  'Add Copied Reference Message',
  { id: number }
>('Add Copied Reference Message') {}

export class InitializeNoteAreaMessage extends actionableFactory.create<
  'Initialize Note Area Message',
  void
>('Initialize Note Area Message') {}

export class InitializeComplianceMessageMessage extends actionableFactory.create<
  'Initialize Compliance Message Message',
  void
>('Initialize Compliance Message Message') {}

export class InitializeCloneDocumentToHospitalMessage extends actionableFactory.create<
  'Initialize Clone Document To Hospital Message',
  void
>('Initialize Clone Document To Hospital Message') {}

export class SelectTabMessage extends actionableFactory.create<
  'Select Tab Message',
  { tab: string }
>('Select Tab Message') {}

export class UpdateShowFlaggedProfileNotesMessage extends actionableFactory.create<
  'Update Show Flagged Profile Notes Message',
  { showFlaggedNotes: boolean }
>('Update Show Flagged Profile Notes Message') {}

export class UpdateShowFlaggedProfileNotesForProfileMessage extends actionableFactory.create<
  'Update Show Flagged Profile Notes For Profile Message',
  { profileId: string; showFlaggedNotes: boolean }
>('Update Show Flagged Profile Notes For Profile Message') {}

export class EnableSubmitButtonMessage extends actionableFactory.create<
  'Enable Submit Button Message',
  void
>('Enable Submit Button Message') {}

export class DisableSubmitButtonMessage extends actionableFactory.create<
  'Disable Submit Button Message',
  void
>('Disable Submit Button Message') {}

export type ProfileUiMessages =
  | InitializeProfileUiMessage
  | ResetErrorMessage
  | AlertErrorMessage
  | SetEmploymentHistoryFilterMessage
  | SetProfileAssignmentNumbersFilterMessage
  | RemoveFileMessage
  | AddCopiedCertificateMessage
  | AddCopiedReferenceMessage
  | InitializeNoteAreaMessage
  | SelectTabMessage
  | InitializeComplianceMessageMessage
  | InitializeCloneDocumentToHospitalMessage
  | EnableSubmitButtonMessage
  | DisableSubmitButtonMessage
  | UpdateShowFlaggedProfileNotesMessage
  | UpdateShowFlaggedProfileNotesForProfileMessage;
