import { createSelector } from '@ngrx/store';
import { State } from '../../../profile/+state/interfaces';
import { selectProfileState } from '../../../profile/+state/profile.selectors';
import { alertStateAdapter } from './staff-bank-unavailabilities-form.adapter';

const selectors = alertStateAdapter.getSelectors();
export const getFormState = (state: State) => state.staffBankUnavailabilitiesFormState;

export const selectStaffBankUnavailabilitiesFormState = createSelector(
  selectProfileState,
  getFormState
);

export const selectStaffBankUnavailabilitiesAlertToDisplay = createSelector(
  selectStaffBankUnavailabilitiesFormState,
  selectors.selectAlertState
);
