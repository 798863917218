import { ResourceTypes } from './constants';
import { IFee } from '../interfaces/api/external-staffing-candidate-bid-entity';

export function isPercentageFee(fee: IFee) {
  return fee.resourcetype === ResourceTypes.percentage;
}

export function isFlatFee(fee: IFee) {
  return fee.resourcetype === ResourceTypes.flat;
}
