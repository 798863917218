import { IAlertState } from '@locumsnest/core/src/lib/adapters/alert-state-adapter/interfaces';
import {
  createFormStateReducerWithUpdate,
  createFormGroupState,
  FormGroupState,
  updateGroup,
  setValue,
} from 'ngrx-forms';
import { alertStateAdapter } from './alert-search-filter-form.adapter';
import {
  InitializeAlertSearchFilterFormMessage,
  AlertSearchFilterFormMessages,
  UpdateSortedFieldMessage,
} from './alert-search-filter-form.messages';
import { IAlertSearchFilterFormState } from '../interfaces/alert-search-filter-form-state';

export * from './alert-search-filter-form.selectors';
export const FORM_ID = 'ALERT_SEARCH_FILTER_FORM';
export type State = FormGroupState<IAlertSearchFilterFormState> & { alertState?: IAlertState };

export const INITIAL_FORM_VALUES: IAlertSearchFilterFormState = {
  searchKeyword: null,
  sortedField: '-cancelled_date',
};

export const INITIAL_FORM_STATE = createFormGroupState<IAlertSearchFilterFormState>(
  FORM_ID,
  INITIAL_FORM_VALUES
);

const formValidationReducer = (formState: FormGroupState<IAlertSearchFilterFormState>) =>
  updateGroup<IAlertSearchFilterFormState>({})(formState);

export const formStateReducer = createFormStateReducerWithUpdate<IAlertSearchFilterFormState>([]);

const alertStateReducer = alertStateAdapter.createReducer();

export function formReducer(
  state: FormGroupState<IAlertSearchFilterFormState> = INITIAL_FORM_STATE,
  action: AlertSearchFilterFormMessages
) {
  switch (action.type) {
    case InitializeAlertSearchFilterFormMessage.TYPE:
      state = createFormGroupState<IAlertSearchFilterFormState>(
        FORM_ID,
        (action as InitializeAlertSearchFilterFormMessage).payload.alertSearchFilterFormState
      );
      state = updateGroup<IAlertSearchFilterFormState>({})(state);
      break;
    case UpdateSortedFieldMessage.TYPE:
      state = updateGroup<IAlertSearchFilterFormState>({
        sortedField: setValue((action as UpdateSortedFieldMessage).payload.sortedField),
      })(state);
      break;
  }

  state = formStateReducer(state, action);
  state = formValidationReducer(state);

  return state;
}

export function reducer(state: State, action) {
  const form = formReducer(state, action);
  const alertState = alertStateReducer(state ? state.alertState : undefined, action);
  return {
    ...form,
    alertState,
  };
}
