import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { IStaffBankMembershipRequestEntity } from '../../interfaces/api/staff-bank-membership-request-entity';
import { paginationAdapter } from './staff-bank-membership-request.adapter';

export const StaffBankMembershipRequestPaginationMessages = paginationAdapter.getMessages();

export class UpsertStaffBankMembershipRequestPageMessage extends StaffBankMembershipRequestPaginationMessages.UpsertPageMessage {}
export class UpsertStaffBankMembershipRequestMultiplePagesMessage extends StaffBankMembershipRequestPaginationMessages.UpsertMultiplePagesMessage {}
export class ResetStaffBankMembershipRequestPaginationMessage extends StaffBankMembershipRequestPaginationMessages.ResetPaginationMessage {}

export enum StaffBankMembershipRequestSignalTypes {
  LOAD_STAFF_BANK_MEMBERSHIP_REQUESTS = '[StaffBankMembershipRequest][Signal] Load Collection',
}

export enum StaffBankMembershipRequestMessageTypes {
  SET_COLLECTION = '[StaffBankMembershipRequest][Message] Set Collection',
  ADD_ONE = '[StaffBankMembershipRequest][Message] Add One',
  UPDATE_ONE = '[StaffBankMembershipRequest][Message] Update One',
  UPSERT_ONE = '[StaffBankMembershipRequest][Message] Upsert One',
  DELETE_ONE = '[StaffBankMembershipRequest][Message] Delete One',
  ADD_MULTIPLE = '[StaffBankMembershipRequest][Message] Add All',
  DELETE_MULTIPLE = '[StaffBankMembershipRequest][Message] Delete Many',
  UPSERT_MULTIPLE = '[StaffBankMembershipRequest][Message] Upsert Many',
  UPDATE_MULTIPLE = '[StaffBankMembershipRequest][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = StaffBankMembershipRequestMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IStaffBankMembershipRequestEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = StaffBankMembershipRequestMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IStaffBankMembershipRequestEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = StaffBankMembershipRequestMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IStaffBankMembershipRequestEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = StaffBankMembershipRequestMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IStaffBankMembershipRequestEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = StaffBankMembershipRequestMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IStaffBankMembershipRequestEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = StaffBankMembershipRequestMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IStaffBankMembershipRequestEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = StaffBankMembershipRequestMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IStaffBankMembershipRequestEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = StaffBankMembershipRequestMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = StaffBankMembershipRequestMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type StaffBankMembershipRequestMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
