import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { ISafeboxCertificateEntity } from '../../interfaces/api/safebox-certificate-entity';
import {
  createPaginatedSubresourceStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src';

export function sortByDate(a: ISafeboxCertificateEntity, b: ISafeboxCertificateEntity): number {
  return a.createdAt.valueOf() - b.createdAt.valueOf();
}

function getId(obj: ISafeboxCertificateEntity) {
  return obj.id;
}

export const adapter: EntityAdapter<ISafeboxCertificateEntity> =
  createEntityAdapter<ISafeboxCertificateEntity>({
    selectId: (entity: ISafeboxCertificateEntity) => entity.id,
    sortComparer: sortByDate,
  });

export const messageableFactory =
  MessageableFactory.forFeature<'SafeboxCertificate'>('SafeboxCertificate');
export const signalableFactory =
  SignalableFactory.forFeature<'SafeboxCertificate'>('SafeboxCertificate');
export const paginationAdapter = createPaginatedSubresourceStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter,
  getId
);

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedSafeboxCertificateId: null,
});
