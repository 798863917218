import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IApplicationHistoryEntity } from '../../interfaces/api/application-history-entity';
import { createPaginatedSubresourceStateAdapter } from '@locumsnest/core/src/lib/adapters/paginated-subresource-state-adapter';
import { MessageableFactory, SignalableFactory } from '@locumsnest/core';

export function sortByDate(a: IApplicationHistoryEntity, b: IApplicationHistoryEntity): number {
  return a.startDateTime > b.startDateTime ? 0 : 1;
}

function getId(obj: IApplicationHistoryEntity) {
  return obj.applicationId;
}

export const adapter: EntityAdapter<IApplicationHistoryEntity> =
  createEntityAdapter<IApplicationHistoryEntity>({
    selectId: (entity: IApplicationHistoryEntity) => entity.applicationId,
    sortComparer: sortByDate,
  });
export const messageableFactory =
  MessageableFactory.forFeature<'ApplicationHistory'>('ApplicationHistory');
export const signalableFactory =
  SignalableFactory.forFeature<'ApplicationHistory'>('ApplicationHistory');
export const paginationAdapter = createPaginatedSubresourceStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter,
  getId
);

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedApplicationHistoryId: null,
});
