import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ISelectOption } from '@locumsnest/components/src';
import { PaginatedStateService } from '@locumsnest/core/src';

import { IHospitalProfileLabelEntity } from '../../interfaces/api/hospital-profile-label-entity';
import { ProfileLabelService } from '../../profile-label/+state/profile-label.service';
import {
  paginationMessages,
  ResetHospitalProfileLabelPaginationMessage,
  UpsertHospitalProfileLabelPageMessage,
  UpsertMultipleMessage,
} from './hospital-profile-label.messages';
import { HospitalProfileLabelPersistenceService } from './hospital-profile-label.persistence.service';
import {
  hospitalProfileLabelPaginationSelectors,
  selectHospitalProfileLabelEntityState,
} from './hospital-profile-label.selectors';

@Injectable({
  providedIn: 'root',
})
export class HospitalProfileLabelService extends PaginatedStateService<
  IHospitalProfileLabelEntity,
  UpsertHospitalProfileLabelPageMessage,
  ResetHospitalProfileLabelPaginationMessage,
  UpsertMultipleMessage
> {
  constructor(
    protected store: Store,
    protected persistenceService: HospitalProfileLabelPersistenceService,
    private profileLabelService: ProfileLabelService
  ) {
    super();
  }

  get paginationMessages() {
    return paginationMessages;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return hospitalProfileLabelPaginationSelectors;
  }

  get entityStateSelector() {
    return selectHospitalProfileLabelEntityState;
  }

  getHospitalProfileLabelOptions(): Observable<ISelectOption[]> {
    return this.getAll().pipe(
      switchMap((hospitalProfileLabels) =>
        combineLatest(
          hospitalProfileLabels.map((hospitalProfileLabel) =>
            this.profileLabelService.getOneOrNone(hospitalProfileLabel.label).pipe(
              map((profileLabel) => {
                if (profileLabel)
                  return { ...profileLabel, hospitalProfileLabelId: hospitalProfileLabel.id };
              })
            )
          )
        )
      ),
      map((labels) => {
        const filteredLabels = labels.filter((x) => !!x);
        return filteredLabels.map((label) => {
          const option: ISelectOption = {
            id: label.hospitalProfileLabelId,
            name: label.display,
            description: label.officerDescription,
          };
          return option;
        });
      })
    );
  }

  fetch() {
    return this.loadAllPages('default', {});
  }
}
