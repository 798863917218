import { inject, Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import { combineLatest, filter, first, map, mergeMap, Observable, of } from 'rxjs';

import { PaginatedStateService } from '@locumsnest/core/src';

import { IApprovedRateEntity } from '../../interfaces/api/approved-rate-entity';
import { IExternalStaffingApprovedRateEntity } from '../../interfaces/api/external-staffing-approved-rate-entity';
import { loadingAdapter } from './approved-rate.adapter';
import {
  ApprovedRatePaginationMessages,
  ResetApprovedRatePaginationMessage,
  SetCollectionMessage,
  UpsertApprovedRatePageMessage,
  UpsertMultipleMessage,
} from './approved-rate.messages';
import { ApprovedRatePersistenceService } from './approved-rate.persistence.service';
import {
  approvedRatePaginationSelectors,
  getByGradeAndSpecialty,
  loadingStateSelectors,
  selectAllApprovedRates,
  selectApprovedRateEntityState,
  selectByGradeForSpecialty,
} from './approved-rate.selectors';

export interface IGradeRate<
  T extends IExternalStaffingApprovedRateEntity | IApprovedRateEntity = IApprovedRateEntity,
> {
  grade: number;
  rates: T[];
}

@Injectable({
  providedIn: 'root',
})
export class ApprovedRateService extends PaginatedStateService<
  IApprovedRateEntity,
  UpsertApprovedRatePageMessage,
  ResetApprovedRatePaginationMessage,
  UpsertMultipleMessage
> {
  protected persistenceService = inject(ApprovedRatePersistenceService);

  get paginationMessages() {
    return ApprovedRatePaginationMessages;
  }

  get paginationSelectors() {
    return approvedRatePaginationSelectors;
  }

  get entityStateSelector() {
    return selectApprovedRateEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get setCollectionMessage() {
    return SetCollectionMessage;
  }

  get loadingMessages() {
    return loadingAdapter.getMessages();
  }

  getAll() {
    return this.store.pipe(select(selectAllApprovedRates));
  }

  initializePaginationWithoutDependencies(namespace: string, resetState: boolean = false) {
    return this.initializePagination(namespace, {}, {}, resetState);
  }

  loadAllPagesWithoutLoadDependencies(namespace: string) {
    return this.loadAllPages(namespace, {}, {});
  }

  fetch() {
    return this.loadAllPages('default', {});
  }

  getAllAfterLoading() {
    return this.store.pipe(select(loadingStateSelectors.selectLoadingState)).pipe(
      filter((loadingState) => loadingState.isLoaded === true),
      mergeMap(() => this.getAll()),
    );
  }

  getByGradeAndSpecialty(gradeId: number, specialtyId: number, nonResidentOnCall: boolean) {
    return this.getAllAfterLoading().pipe(
      map(getByGradeAndSpecialty(gradeId, specialtyId, nonResidentOnCall)),
    );
  }

  getByGradeForSpecialty(specialtyId: number, nonResidentOnCall: boolean) {
    return this.store.pipe(select(selectByGradeForSpecialty(specialtyId, nonResidentOnCall)));
  }

  getGradeDefaults(
    grades: number[],
    specialty: number,
    nonResidentOnCall: boolean,
  ): Observable<IGradeRate[]> {
    if (grades.length > 0) {
      return combineLatest(
        grades.map((gradeId) =>
          this.getByGradeAndSpecialty(gradeId, specialty, nonResidentOnCall).pipe(
            map((rates) => ({
              grade: gradeId,
              rates,
            })),
          ),
        ),
      ).pipe(first());
    }

    return of([]);
  }
}
