import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AttributeStateService } from '@locumsnest/core';
import { filterNonEmpty } from '@locumsnest/core/src/lib/ngrx/operators';

import { IGmcStatusEntity } from '../../interfaces/api/gmc-status-entity';
import { SetCollectionMessage } from './gmc-status.messages';
import { GmcStatusPersistenceService } from './gmc-status.persistence.service';
import {
  selectAllGmcStatuses,
  selectGmcStatus,
  selectGmcStatusCodeById,
  selectGmcStatusOptions,
} from './gmc-status.selectors';

@Injectable({
  providedIn: 'root',
})
export class GmcStatusService extends AttributeStateService<IGmcStatusEntity> {
  constructor(private store: Store, protected persistenceService: GmcStatusPersistenceService) {
    super();
  }
  getAll() {
    return this.store.pipe(select(selectAllGmcStatuses), filterNonEmpty);
  }
  getGmcStatusOptions() {
    return this.store.pipe(
      select(selectGmcStatusOptions),
      filter((options) => options && options.length > 0)
    );
  }
  getOne(id: number) {
    return this.store.pipe(select(selectGmcStatus(id)));
  }

  getGmcStatusCodeById(id: number) {
    return this.store.pipe(select(selectGmcStatusCodeById(id)));
  }

  fetch() {
    return this.persistenceService
      .retrieve()
      .pipe(map((gmcStatuses) => new SetCollectionMessage({ gmcStatuses })));
  }

  public hasLicenseToPractice(id: number): Observable<boolean> {
    const statusCodes = [
      'REGISTERED_WITH_LICENSE',
      'PROVISIONALLY_REGISTERED',
      'TEMPORARY_REGISTRATION',
    ];

    return this.getGmcStatusCodeById(id).pipe(
      map((gmcStatusCode) => gmcStatusCode && statusCodes.indexOf(gmcStatusCode) !== -1)
    );
  }
}
