import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { StateService } from '@locumsnest/core/src';

import { IProfileStatisticsEntity } from '../../interfaces/api/profile-statistics-entity';
import { AddOneMessage } from './profile-statistics.messages';
import { ProfileStatisticsPersistenceService } from './profile-statistics.persistence.service';
import {
  selectAllProfileStatistics,
  selectProfileStatistics,
} from './profile-statistics.selectors';

@Injectable({
  providedIn: 'root',
})
export class ProfileStatisticsService extends StateService<
  IProfileStatisticsEntity,
  {},
  IProfileStatisticsEntity,
  IProfileStatisticsEntity
> {
  constructor(
    protected store: Store,
    protected persistenceService: ProfileStatisticsPersistenceService
  ) {
    super();
  }

  getAll() {
    return this.store.pipe(select(selectAllProfileStatistics));
  }

  getOne(profileId: string) {
    return this.store.pipe(select(selectProfileStatistics(profileId)));
  }

  loadProfileStatisticsByProfileId(profileId: string): Observable<Action> {
    return this.fetch(profileId) as Observable<AddOneMessage>;
  }

  fetch(profileId: string) {
    return this.persistenceService
      .retrieve(null, { pathParams: { profileId } })
      .pipe(map((entity) => new AddOneMessage({ entity })));
  }

  public hasWorkedShiftsInOurOrganisation(profileId: string): Observable<boolean> {
    return this.getOne(profileId).pipe(
      map((profileStatistics) => profileStatistics && profileStatistics.workedInTrust)
    );
  }

  public hasWorkedShiftsInSimilarDepartment(profileId: string): Observable<boolean> {
    return this.getOne(profileId).pipe(
      map((profileStatistics) => profileStatistics && profileStatistics.workedInSpecialty)
    );
  }

  public getWorkedHours(profileId: string): Observable<number> {
    return this.getOne(profileId).pipe(
      map((profileStatistics) => {
        if (profileStatistics) return profileStatistics ? profileStatistics.workedHours : null;
      })
    );
  }
}
