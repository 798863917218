import { Injectable } from '@angular/core';

import {
  HttpApiPersistenceService,
  IPaginatedResponse,
  RequestOptions,
} from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IReferenceEntity } from '../../interfaces/api/reference-entity';

interface IEndpointOptions {
  profileId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReferencePersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IReferenceEntity,
  IPaginatedResponse<IReferenceEntity>,
  IReferenceEntity,
  RequestOptions<IEndpointOptions>
> {
  protected readonly endpoint = 'reference';
}
