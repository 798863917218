import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IComplianceMessageEntity } from '../../interfaces/api/compliance-message-entity';
import { selectAll } from './compliance-message.reducer';
import {
  IComplianceMessageEntityState,
  IComplianceMessageFeatureState,
  IComplianceMessageSharedState,
} from './interfaces';
import { paginationAdapter } from './compliance-message.adapter';

export const getEntityState = (state: { entityState: IComplianceMessageEntityState }) =>
  state.entityState;

export const getComplianceMessagesByProfileId =
  (profileId: string) => (complianceMessages: IComplianceMessageEntity[]) =>
    complianceMessages.filter((complianceMessage) => complianceMessage.recipient === profileId);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectComplianceMessageState =
  createFeatureSelector<IComplianceMessageFeatureState & IComplianceMessageSharedState>(
    'complianceMessages'
  );

export const selectComplianceMessageEntityState = createSelector(
  selectComplianceMessageState,
  getEntityState
);

export const selectAllComplianceMessages = createSelector(
  selectComplianceMessageEntityState,
  selectAll
);

export const selectComplianceMessagesByProfileId = (profileId: string) =>
  createSelector(selectAllComplianceMessages, getComplianceMessagesByProfileId(profileId));

export const complianceMessagePaginationSelectors =
  paginationAdapter.getSubresourcePaginationSelectors(selectComplianceMessageEntityState);
