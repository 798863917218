import { Injectable } from '@angular/core';
import { LocumsNestEndpointConfig } from 'apps/hospital-admin/src/app/core/constants';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { IStaffbankRejectionReason } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class StaffbankRejectionReasonsPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IStaffbankRejectionReason
> {
  protected override readonly endpoint = 'staffbankRejectionReason';
}
