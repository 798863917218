import { Injectable } from '@angular/core';
import { PermissionService } from 'apps/hospital-admin/src/app/permission/+state/permission.service';
import { combineLatest, map } from 'rxjs';

import * as permissions from './job-listing.permissions';

@Injectable({
  providedIn: 'root',
})
export class JobListingPermissionService {
  constructor(private permissionService: PermissionService) {}

  canCascadeToExternalStaffingProviders() {
    return this.permissionService.hasCurrentPermission(
      permissions.CASCADE_TO_EXTERNAL_STAFFING_PROVIDERS,
    );
  }
  canImportBid() {
    return this.permissionService.hasCurrentPermission(permissions.IMPORT_BID);
  }

  canChangeProposedRates() {
    return combineLatest([this.canImportBid(), this.canCascadeToExternalStaffingProviders()]).pipe(
      map(([canImportBid, canAuthorizeCascade]) => !canImportBid || canAuthorizeCascade),
    );
  }

  hasUnlockPermission() {
    return this.permissionService.hasCurrentPermission(permissions.UNLOCK_JOB_LISTING_RATES);
  }

  hasLockPermission() {
    return this.permissionService.hasCurrentPermission(permissions.LOCK_JOB_LISTING_RATES);
  }

  hasCreateNonResidentShiftPermission() {
    return this.permissionService.hasCurrentPermission(permissions.CREATE_NON_RESIDENT_SHIFT);
  }
}
