import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IProfileTagFeatureState, IProfileTagSharedState } from './interfaces';
import { paginationAdapter } from './profile-tag.adapter';
import { featureKey } from './profile-tag.reducer';

export const selectProfileTagState = createFeatureSelector<
  IProfileTagFeatureState & IProfileTagSharedState
>(featureKey);

export const selectProfileTagEntityState = createSelector(
  selectProfileTagState,
  (state) => state.entityState
);

export const profileTagPaginationSelectors = paginationAdapter.paginationSelectors(
  selectProfileTagState,
  null,
  'pagination',
  'entityState',
  false
);
