import { IStaffBankMembershipRequestEntity } from '../../../interfaces/api/staff-bank-membership-request-entity';
import { IStaffBankMembershipRequestsSearchFilterFormState } from '../interfaces/staff-bank-membership-requests-search-filter-form-state';
import { messageableFactory as actionableFactory } from './staff-bank-membership-requests-search-filter-form.adapter';

export class InitializeSearchFilterFormMessage extends actionableFactory.create<
  'Initialize Listing Search Filter Form',
  {
    searchFilterFormState: IStaffBankMembershipRequestsSearchFilterFormState;
  }
>('Initialize Listing Search Filter Form') {}

export class UpdateSelectedPageMessage extends actionableFactory.create<
  'Update Selected Page Message',
  { selectedPage: number }
>('Update Selected Page Message') {}

export class AddSelectedStaffBankMembershipRequestMessage extends actionableFactory.create<
  'Add Selected StaffBankMembership Request Message',
  { id: number }
>('Add Selected StaffBankMembership Request Message') {}

export class RemoveSelectedStaffBankMembershipRequestMessage extends actionableFactory.create<
  'Remove Selected StaffBankMembership Request Message',
  { id: number }
>('Remove Selected StaffBankMembership Request Message') {}

export class SelectAllCurrentPageMessage extends actionableFactory.create<
  'Select All Current Page Message',
  { staffBankMembershipRequests: IStaffBankMembershipRequestEntity[] }
>('Select All Current Page Message') {}

export class UnselectAllCurrentPageMessage extends actionableFactory.create<
  'Unselect All Current Page Message',
  { staffBankMembershipRequests: IStaffBankMembershipRequestEntity[] }
>('Unselect All Current Page Message') {}

export class ClearSelectedStaffBankMembershipRequestsMessage extends actionableFactory.create<
  'Clear Selected StaffBankMembership Requests Message',
  Record<string, never>
>('Clear Selected StaffBankMembership Requests Message') {}

export class AddExpandedStaffBankRequestMessage extends actionableFactory.create<
  'Add Expanded StaffBank Request Message',
  { id: number }
>('Add Expanded StaffBank Request Message') {}

export class RemoveExpandedStaffBankRequestMessage extends actionableFactory.create<
  'Remove Expanded StaffBank Request Message',
  { id: number }
>('Remove Expanded StaffBank Request Message') {}

export class ClearProfileNoteMessage extends actionableFactory.create<
  'Clear Profile Note Message',
  { id: number }
>('Clear Profile Note Message') {}

export class AddRejectMembershipMessage extends actionableFactory.create<
  'Add Reject Membership Message',
  { id: number }
>('Add Reject Membership Message') {}

export class RemoveRejectMembershipMessage extends actionableFactory.create<
  'Remove Reject Membership Message',
  { id: number }
>('Remove Reject Membership Message') {}

export type StaffBankMembershipRequestsSearchFilterFormMessages =
  | InitializeSearchFilterFormMessage
  | UpdateSelectedPageMessage
  | AddSelectedStaffBankMembershipRequestMessage
  | RemoveSelectedStaffBankMembershipRequestMessage
  | SelectAllCurrentPageMessage
  | UnselectAllCurrentPageMessage
  | ClearSelectedStaffBankMembershipRequestsMessage
  | AddExpandedStaffBankRequestMessage
  | RemoveExpandedStaffBankRequestMessage
  | ClearProfileNoteMessage
  | AddRejectMembershipMessage
  | RemoveRejectMembershipMessage;
