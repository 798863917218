import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IStaffBankMembershipRequestStatusEntity } from '../../interfaces/api/staff-bank-membership-request-status-entity';

export function sortByName(
  a: IStaffBankMembershipRequestStatusEntity,
  b: IStaffBankMembershipRequestStatusEntity
): number {
  if (a > b) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

export const adapter: EntityAdapter<IStaffBankMembershipRequestStatusEntity> =
  createEntityAdapter<IStaffBankMembershipRequestStatusEntity>({
    sortComparer: sortByName,
  });

export const initialState = adapter.getInitialState({});
