import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ISelectOption } from '@locumsnest/components';

import {
  IStaffBankMembershipRequestStatusEntity,
  StaffBankMembershipRequestStatusEnum,
} from '../../interfaces/api/staff-bank-membership-request-status-entity';
import {
  IStaffBankMembershipRequestStatusEntityState,
  IStaffBankMembershipRequestStatusFeatureState,
  IStaffBankMembershipRequestStatusSharedState,
} from './interfaces';
import { selectAll } from './staff-bank-membership-request-status.reducer';

export const getEntityState = (state: {
  entityState: IStaffBankMembershipRequestStatusEntityState;
}) => state.entityState;

export const getStaffBankMembershipRequestStatus =
  (id: number) => (statuses: IStaffBankMembershipRequestStatusEntity[]) =>
    statuses.find((status) => status.id === id);

export const getStaffBankMembershipRequestStatusByCode =
  (code: string) => (statuses: IStaffBankMembershipRequestStatusEntity[]) =>
    statuses.find((status) => status.code === code);

export const getApprovedStaffBankMembershipRequestStatus = (
  statuses: IStaffBankMembershipRequestStatusEntity[],
): IStaffBankMembershipRequestStatusEntity =>
  statuses.find((status) => status.code === StaffBankMembershipRequestStatusEnum.APPROVED);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectStaffBankMembershipRequestStatusState = createFeatureSelector<
  IStaffBankMembershipRequestStatusFeatureState & IStaffBankMembershipRequestStatusSharedState
>('staffBankMembershipRequestStatuses');

export const selectStaffBankMembershipRequestStatusEntityState = createSelector(
  selectStaffBankMembershipRequestStatusState,
  getEntityState,
);
export const selectAllStaffBankMembershipRequestStatuses = createSelector(
  selectStaffBankMembershipRequestStatusEntityState,
  selectAll,
);

export const selectStaffBankMembershipRequestStatus = (id) =>
  createSelector(
    selectAllStaffBankMembershipRequestStatuses,
    getStaffBankMembershipRequestStatus(id),
  );

export const selectStaffBankMembershipRequestStatusByCode = (code) =>
  createSelector(
    selectAllStaffBankMembershipRequestStatuses,
    getStaffBankMembershipRequestStatusByCode(code),
  );

export const selectApprovedStaffBankMembershipRequestStatus = createSelector(
  selectAllStaffBankMembershipRequestStatuses,
  getApprovedStaffBankMembershipRequestStatus,
);

export const selectStaffBankMembershipRequestStatusOptions = (setForOfficer: boolean) =>
  createSelector(
    selectAllStaffBankMembershipRequestStatuses,
    (staffBankMembershipRequestStatuses) =>
      staffBankMembershipRequestStatuses
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map((staffBankMembershipRequestStatus) => ({
          id: staffBankMembershipRequestStatus.id,
          name: staffBankMembershipRequestStatus.display,
          disabled: setForOfficer && !staffBankMembershipRequestStatus.setByOfficer,
        })),
  );
