import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IComplianceMessageEntity } from '../../interfaces/api/compliance-message-entity';
import {
  MessageableFactory,
  SignalableFactory,
  createPaginatedSubresourceStateAdapter,
} from '@locumsnest/core/src';

export function sortByDate(a: IComplianceMessageEntity, b: IComplianceMessageEntity): number {
  return a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0;
}

export const adapter: EntityAdapter<IComplianceMessageEntity> =
  createEntityAdapter<IComplianceMessageEntity>({
    sortComparer: sortByDate,
  });

function getId(obj: IComplianceMessageEntity) {
  return obj.id;
}

export const messageableFactory =
  MessageableFactory.forFeature<'ComplianceMessage'>('ComplianceMessage');
export const signalableFactory =
  SignalableFactory.forFeature<'ComplianceMessage'>('ComplianceMessage');
export const paginationAdapter = createPaginatedSubresourceStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter,
  getId
);

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedComplianceMessageId: null,
});
