import { IPaginatedEntityState } from '@locumsnest/core/src/lib/interfaces/pagination';

import { IBankHolidayEntity } from '../../interfaces/api/bank-holiday-entity';
import { adapter, initialState, paginationAdapter } from './bank-holiday.adapter';
import {
  BankHolidayMessages,
  BankHolidayMessageTypes as MessageTypes,
} from './bank-holiday.messages';
import { IBankHolidayEntityState } from './interfaces';

export * from './interfaces';

export const featureKey = 'bankHolidays';

export const paginationReducer = paginationAdapter.createReducer();
export type IBankHolidayPaginatedEntityState = IBankHolidayEntityState &
  IPaginatedEntityState<IBankHolidayEntity>;
export function entityReducer(
  state = initialState,
  action: BankHolidayMessages
): IBankHolidayEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.entity, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.entity, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.entities, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.entities, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }
    default: {
      return state;
    }
  }
}

export function paginatedEntityStateReducer(
  state: IBankHolidayPaginatedEntityState,
  action: BankHolidayMessages
) {
  let pagination;

  if (state) {
    pagination = state.pagination;
  }

  return {
    ...entityReducer(state, action),
    pagination: {
      ...paginationReducer(pagination, action),
    },
  };
}
export const { selectIds, selectEntities, selectAll, selectTotal } =
  paginationAdapter.getEntitySelectors();
