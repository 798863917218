import { ApplicationService } from '../../application/+state/application.service';
import { ProfessionSpecialtyService } from '../../profession-specialty/+state/profession-specialty.service';
import { SpecialtyService } from '../../specialty/+state/specialty.service';
import { StaffBankMembershipRequestService } from '../../staff-bank-membership-request/+state/staff-bank-membership-request.service';
import { StaffingCascadeService } from '../../staffing-cascade/+state/staffing-cascade.service';
import { LayoutPersistenceService } from './persistence';
import { ConfigurationService, LayoutService } from './state';
import { FileDownloaderService } from './state/file-downloader.service';
import {
  APPLICATION_SERVICE_TOKEN,
  PROFESSION_SPECIALTY_SERVICE_TOKEN,
  SPECIALTY_SERVICE_TOKEN,
  STAFF_BANK_MEMBERSHIP_REQUEST_SERVICE_TOKEN,
  STAFFING_CASCADE_SERVICE_TOKEN,
} from './state/opaque-tokens';

export const PERSISTENCE_SERVICE_PROVIDERS = [LayoutPersistenceService];

export const STATE_SERVICE_PROVIDERS = [
  ConfigurationService,
  FileDownloaderService,
  LayoutService,
  { provide: APPLICATION_SERVICE_TOKEN, useExisting: ApplicationService },
  { provide: STAFFING_CASCADE_SERVICE_TOKEN, useExisting: StaffingCascadeService },
  {
    provide: STAFF_BANK_MEMBERSHIP_REQUEST_SERVICE_TOKEN,
    useExisting: StaffBankMembershipRequestService,
  },
  {
    provide: PROFESSION_SPECIALTY_SERVICE_TOKEN,
    useExisting: ProfessionSpecialtyService,
  },
  {
    provide: SPECIALTY_SERVICE_TOKEN,
    useExisting: SpecialtyService,
  },
];

export const SERVICE_PROVIDERS = [...STATE_SERVICE_PROVIDERS, ...PERSISTENCE_SERVICE_PROVIDERS];
