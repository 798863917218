import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { IRevalidationStatusEntity } from '../../interfaces/api/revalidation-status-entity';

export enum RevalidationStatusSignalTypes {
  LOAD_REVALIDATION_STATUSES = '[RevalidationStatus][Signal] Load Collection',
}
export enum RevalidationStatusMessageTypes {
  SET_COLLECTION = '[RevalidationStatus][Message] Set Collection',
  ADD_ONE = '[RevalidationStatus][Message] Add One',
  UPDATE_ONE = '[RevalidationStatus][Message] Update One',
  UPSERT_ONE = '[RevalidationStatus][Message] Upsert One',
  DELETE_ONE = '[RevalidationStatus][Message] Delete One',
  ADD_MULTIPLE = '[RevalidationStatus][Message] Add All',
  DELETE_MULTIPLE = '[RevalidationStatus][Message] Delete Many',
  UPSERT_MULTIPLE = '[RevalidationStatus][Message] Upsert Many',
  UPDATE_MULTIPLE = '[RevalidationStatus][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = RevalidationStatusMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IRevalidationStatusEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = RevalidationStatusMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IRevalidationStatusEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = RevalidationStatusMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IRevalidationStatusEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = RevalidationStatusMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IRevalidationStatusEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = RevalidationStatusMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IRevalidationStatusEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = RevalidationStatusMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IRevalidationStatusEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = RevalidationStatusMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IRevalidationStatusEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = RevalidationStatusMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = RevalidationStatusMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type RevalidationStatusMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
