/* eslint-disable @typescript-eslint/naming-convention */
import { ISelectOption } from '@locumsnest/components/src/lib/interfaces';
import { IEnumeration } from '@locumsnest/core/src/lib/interfaces';
import { IPaginatedEntityState } from '@locumsnest/core/src/lib/interfaces/pagination';
import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
} from '@locumsnest/core/src/lib/ngrx/cached-state';

import { IJobListingEntity } from '../../interfaces/api/job-listing-entity';
import { JobListingActions, JobListingMessageTypes as MessageTypes } from './job-listing.actions';
import { loadingAdapter, paginationAdapter } from './job-listing.adapter';

export * from './form/form.selectors';
export * from './interfaces';

/**
 * Interface to the part of the Store containing JobListingState
 * and other information related to JobListingData.
 */
export interface State extends EntityState<IJobListingEntity> {
  // additional entities state properties
  selectedJobListingId: number;
}

export function sortByStartDate(a: IJobListingEntity, b: IJobListingEntity): number {
  if (a.startTime < b.startTime) return -1;
  if (a.startTime > b.startTime) return 1;
  return 0;
}

export const adapter: EntityAdapter<IJobListingEntity> = createEntityAdapter<IJobListingEntity>({
  sortComparer: sortByStartDate,
});

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedJobListingId: null,
});

export * from './interfaces';

const _loadingStateReducer = loadingAdapter.createReducer();
const _paginationReducer = paginationAdapter.createReducer();
export type IJobListingPaginatedEntityState = State & IPaginatedEntityState<IJobListingEntity>;

export function loadingStateReducer(state, action) {
  return _loadingStateReducer(state, action);
}

export function paginationReducer(state, action) {
  return _paginationReducer(state, action);
}

export function entityReducer(state = initialState, action: JobListingActions): State {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.entity, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.entity, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.entities, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.entities, state);
    }

    case MessageTypes.SET_SELECTED: {
      return {
        ...state,
        selectedJobListingId: action.payload.id,
      };
    }

    default: {
      return state;
    }
  }
}

export const mapEnumToOptions = (enumeration: IEnumeration[]): ISelectOption[] =>
  enumeration.map((v) => ({ id: v.val, name: v.display }));

export const { selectIds, selectEntities, selectAll, selectTotal } =
  paginationAdapter.getEntitySelectors();
