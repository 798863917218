import {
  ApplicationMessageTypes as MessageTypes,
  ApplicationMessages,
} from './application.messages';
import { IApplicationEntityState } from './interfaces';
import { adapter, initialState, paginationAdapter, loadingAdapter } from './application.adapter';
import { IApplicationEntity } from '../../interfaces/api/application-entity';
import { IPaginatedEntityState } from '@locumsnest/core/src/lib/interfaces/pagination';

export * from './interfaces';

const _loadingStateReducer = loadingAdapter.createReducer();
const _paginationReducer = paginationAdapter.createReducer();

export type IApplicationPaginatedEntityState = IApplicationEntityState &
  IPaginatedEntityState<IApplicationEntity>;

export function loadingStateReducer(state, action) {
  return _loadingStateReducer(state, action);
}

export function paginationReducer(state, action) {
  return _paginationReducer(state, action);
}

export function entityReducer(
  state = initialState,
  action: ApplicationMessages
): IApplicationEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.entity, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.entity, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.entities, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.entities, state, action.payload.metaData);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }
    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  paginationAdapter.getEntitySelectors();
