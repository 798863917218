import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';

import { IExternalStaffingProviderEntity } from '../../interfaces/api/external-staffing-provider-entity';
import { paginationAdapter } from './external-staffing-provider.adapter';

export const {
  ResetLoadingStateMessage,
  ResetPaginationMessage,
  SetLoadingStateMessage,
  UpsertMultiplePagesMessage,
  UpsertPageMessage,
} = paginationAdapter.getMessages();

export const paginationMessages = paginationAdapter.getMessages();

export class UpsertExternalStaffingProviderPageMessage extends UpsertPageMessage {}
export class UpsertExternalStaffingProviderMultiplePagesMessage extends UpsertMultiplePagesMessage {}
export class ResetExternalStaffingProviderPaginationMessage extends ResetPaginationMessage {}

export enum ExternalStaffingProviderSignalTypes {
  LOAD_EXTERNAL_STAFFING_PROVIDERS = '[ExternalStaffingProvider][Signal] Load Collection',
}
export enum ExternalStaffingProviderMessageTypes {
  SET_COLLECTION = '[ExternalStaffingProvider][Message] Set Collection',
  ADD_ONE = '[ExternalStaffingProvider][Message] Add One',
  UPDATE_ONE = '[ExternalStaffingProvider][Message] Update One',
  UPSERT_ONE = '[ExternalStaffingProvider][Message] Upsert One',
  DELETE_ONE = '[ExternalStaffingProvider][Message] Delete One',
  ADD_MULTIPLE = '[ExternalStaffingProvider][Message] Add All',
  DELETE_MULTIPLE = '[ExternalStaffingProvider][Message] Delete Many',
  UPSERT_MULTIPLE = '[ExternalStaffingProvider][Message] Upsert Many',
  UPDATE_MULTIPLE = '[ExternalStaffingProvider][Message] Update Many',
  SET_ASSIGNED = '[ExternalStaffingProvider][Message] Set Assigned',
}
export class SetCollectionMessage implements Action {
  readonly type = ExternalStaffingProviderMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IExternalStaffingProviderEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = ExternalStaffingProviderMessageTypes.ADD_ONE;
  constructor(public payload: { entity: IExternalStaffingProviderEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = ExternalStaffingProviderMessageTypes.UPSERT_ONE;
  constructor(public payload: { entity: IExternalStaffingProviderEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = ExternalStaffingProviderMessageTypes.ADD_MULTIPLE;
  constructor(public payload: { entities: IExternalStaffingProviderEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = ExternalStaffingProviderMessageTypes.UPSERT_MULTIPLE;
  constructor(public payload: { entities: IExternalStaffingProviderEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = ExternalStaffingProviderMessageTypes.UPDATE_ONE;
  constructor(public payload: { entity: Update<IExternalStaffingProviderEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = ExternalStaffingProviderMessageTypes.UPDATE_MULTIPLE;
  constructor(public payload: { entities: Update<IExternalStaffingProviderEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = ExternalStaffingProviderMessageTypes.DELETE_ONE;
  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = ExternalStaffingProviderMessageTypes.DELETE_MULTIPLE;
  constructor(public payload: { ids: string[] }) {}
}

export class SetAssignedMessage implements Action {
  readonly type = ExternalStaffingProviderMessageTypes.SET_ASSIGNED;
  constructor(public payload: { id: number }) {}
}
export type ExternalStaffingProviderMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | SetAssignedMessage;
