import { TagsGroup } from '../interfaces/tag-form-state';
import { signalableFactory as actionableFactory } from './tag-form.adapter';

export class InitializeTagFormSignal extends actionableFactory.create<
  'Initialize Tag Form',
  Record<string, never>
>('Initialize Tag Form') {}

export class InitializeBulkTagSignal extends actionableFactory.create<
  'Initialize Bulk Tag',
  Record<string, never>
>('Initialize Bulk Tag') {}

export class InitializeListPageTagSignal extends actionableFactory.create<
  'Initialize List Page Tag',
  { listings: number[] }
>('Initialize List Page Tag') {}

export class AddTagSignal extends actionableFactory.create<'Add Tag', { tags: number[] }>(
  'Add Tag',
) {}

export class CreateTagSignal extends actionableFactory.create<
  'Create Tag Signal',
  Record<string, never>
>('Create Tag Signal') {}

export class SelectTagSignal extends actionableFactory.create<'Select Tag Signal', { id: number }>(
  'Select Tag Signal',
) {}

export class BulkSaveTagsSignal extends actionableFactory.create<
  'Bulk Save Tags Signal',
  { namespace: string }
>('Bulk Save Tags Signal') {}

export class ClearTagSearchSignal extends actionableFactory.create<
  'Clear Tag Search',
  Record<string, never>
>('Clear Tag Search') {}

export class ToggleDeleteModeSignal extends actionableFactory.create<
  'Toggle Delete Mode',
  { tags: TagsGroup }
>('Toggle Delete Mode') {}

export class DeleteTagsSignal extends actionableFactory.create<
  'Delete Tags Signal',
  Record<string, never>
>('Delete Tags Signal') {}

export class ToggleShowTagFormSignal extends actionableFactory.create<
  'Toggle Show Tag Form',
  Record<string, never>
>('Toggle Show Tag Form') {}

export class SearchTagsSignal extends actionableFactory.create<
  'Search Tags Signal',
  { search: string }
>('Search Tags Signal') {}

export class LoadMoreOrganisationTagsSignal extends actionableFactory.create<
  'Load More Organisation Tags',
  Record<string, never>
>('Load More Organisation Tags') {}

export class LoadTagsByIdsSignal extends actionableFactory.create<
  'Load Tags By Ids',
  { ids: number[] }
>('Load Tags By Ids') {}
