import {
  ProfileStatisticsMessageTypes as MessageTypes,
  ProfileStatisticsMessages,
} from './profile-statistics.messages';
import { IProfileStatisticsEntityState } from './interfaces';
import { adapter, initialState } from './profile-statistics.adapter';
import { combineReducers } from '@ngrx/store';

export * from './interfaces';

export function entityReducer(
  state = initialState,
  action: ProfileStatisticsMessages
): IProfileStatisticsEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.entity, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.entity, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.entity, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    default: {
      return state;
    }
  }
}
export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export function reducer(state, action) {
  return combineReducers({
    entityState: entityReducer,
  })(state, action);
}
