import { FormGroupState } from 'ngrx-forms';

import { IShiftCreationFormState, IShiftSchedulerFormState } from '../interfaces';
import {
  isApplicationDeadlineDateTimeInValid,
  isEmploymentPeriodInvalid,
  isRequiredDropdownSelect,
} from './form.selectors';

export const isRequiredDropdownOption =
  (state: FormGroupState<IShiftCreationFormState>, dropdownName: string) => () =>
    isRequiredDropdownSelect(state, dropdownName) ? { isRequired: true } : null;

export const isValidDate = (date: string) => () => {
  if (isNaN(Date.parse(date))) {
    return { invalidDate: true };
  }

  return null;
};

export const hasValidEmploymentPeriod = (state: FormGroupState<IShiftSchedulerFormState>) => () =>
  isEmploymentPeriodInvalid(state, state.userDefinedProperties.shiftHardMaxDuration)
    ? { invalidDateRange: true }
    : null;

export const hasValidApplicationDeadline =
  (state: FormGroupState<IShiftSchedulerFormState>) => () => {
    if (state.value.hasCustomApplicationDeadLine) {
      return isApplicationDeadlineDateTimeInValid(state) ? { invalidDateTime: true } : null;
    }
    return null;
  };
