import { createFeatureSelector, createSelector } from '@ngrx/store';

import { paginationAdapter } from './hospital-profile-label.adapter';
import { featureKey } from './hospital-profile-label.reducer';
import { IHospitalProfileLabelFeatureState, IHospitalProfileLabelSharedState } from './interfaces';

export const selectHospitalProfileLabelState = createFeatureSelector<
  IHospitalProfileLabelFeatureState & IHospitalProfileLabelSharedState
>(featureKey);

export const selectHospitalProfileLabelEntityState = createSelector(
  selectHospitalProfileLabelState,
  (state) => state.entityState
);

export const hospitalProfileLabelPaginationSelectors = paginationAdapter.paginationSelectors(
  selectHospitalProfileLabelState,
  null,
  'pagination',
  'entityState',
  false
);
