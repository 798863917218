import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IProfileNoteEntity } from '../../interfaces/api/profile-note-entity';

@Injectable({
  providedIn: 'root',
})
export class ProfileNotePersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IProfileNoteEntity
> {
  protected readonly endpoint = 'profileNote';

  markProfileNoteAsFlagged(id: number) {
    return this.create<{}, IProfileNoteEntity>(
      {},
      { pathParams: { id }, controllerResource: 'profileNoteFlag', skipSerializer: true },
    );
  }

  markProfileNoteAsUnFlagged(id: number) {
    return this.create<{}, IProfileNoteEntity>(
      {},
      { pathParams: { id }, controllerResource: 'profileNoteUnFlag', skipSerializer: true },
    );
  }
}
