import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IProfileLabelFeatureState, IProfileLabelSharedState } from './interfaces';
import { paginationAdapter } from './profile-label.adapter';
import { featureKey } from './profile-label.reducer';

export const selectProfileLabelState = createFeatureSelector<
  IProfileLabelFeatureState & IProfileLabelSharedState
>(featureKey);

export const selectProfileLabelEntityState = createSelector(
  selectProfileLabelState,
  (state) => state.entityState
);

export const profileLabelPaginationSelectors = paginationAdapter.paginationSelectors(
  selectProfileLabelState,
  null,
  'pagination',
  'entityState',
  false
);
