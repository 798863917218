import { createSelector } from '@ngrx/store';
import { FormGroupState } from 'ngrx-forms';
import { selectExternalStaffingCandidateBidState } from '../external-staffing-candidate-bid.selectors';
import { State } from '../interfaces';
import { IExternalStaffingCandidateBidUiFormState } from '../interfaces/external-staffing-candidate-bid-ui-form';

export const getUiFormState = (state: State) => state.uiFormState;

export const getCancellationReason = (
  state: FormGroupState<IExternalStaffingCandidateBidUiFormState>
) => state.value.cancellationReason;

export const selectExternalStaffingCandidateBidUiFormState = createSelector(
  selectExternalStaffingCandidateBidState,
  getUiFormState
);

export const selectUploadFileFormState = createSelector(
  selectExternalStaffingCandidateBidUiFormState,
  getCancellationReason
);
