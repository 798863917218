import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src/';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';
import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';

import { IBankHolidayEntity } from '../../interfaces/api/bank-holiday-entity';

export const adapter: EntityAdapter<IBankHolidayEntity> = createEntityAdapter<IBankHolidayEntity>({
  selectId: (pensionCategory: IBankHolidayEntity) => pensionCategory.id,
});

export const messageableFactory = MessageableFactory.forFeature<'BankHoliday'>('BankHoliday');
export const signalableFactory = SignalableFactory.forFeature<'BankHoliday'>('BankHoliday');
export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const loadingAdapter = createLoadingStateAdapter<'BankHoliday'>(messageableFactory);

export const initialState = adapter.getInitialState({
  selectedBankHolidayId: null,
});
