import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ISafeboxReferenceEntity } from '../../interfaces/api/safebox-reference-entity';
import { selectAll } from './safebox-reference.reducer';
import {
  ISafeboxReferenceEntityState,
  ISafeboxReferenceFeatureState,
  ISafeboxReferenceSharedState,
} from './interfaces';
import { paginationAdapter } from './safebox-reference.adapter';

export const getEntityState = (state: { entityState: ISafeboxReferenceEntityState }) =>
  state.entityState;

export const getSafeboxReferencesByProfileId =
  (profileId: string) => (safeboxReferences: ISafeboxReferenceEntity[]) =>
    safeboxReferences.filter((safeboxReference) => safeboxReference.profile === profileId);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectSafeboxReferenceState =
  createFeatureSelector<ISafeboxReferenceFeatureState & ISafeboxReferenceSharedState>(
    'safeboxReferences'
  );

export const selectSafeboxReferenceEntityState = createSelector(
  selectSafeboxReferenceState,
  getEntityState
);
export const selectAllSafeboxReferences = createSelector(
  selectSafeboxReferenceEntityState,
  selectAll
);
export const selectSafeboxReferencesByProfileId = (profileId: string) =>
  createSelector(selectAllSafeboxReferences, getSafeboxReferencesByProfileId(profileId));

export const safeboxReferencePaginationSelectors =
  paginationAdapter.getSubresourcePaginationSelectors(selectSafeboxReferenceEntityState);
