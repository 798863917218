import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IProfileFlagEntity } from '../../interfaces/api/profile-flag-entity';

@Injectable({
  providedIn: 'root',
})
export class ProfileFlagPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IProfileFlagEntity
> {
  protected readonly endpoint = 'profileFlag';
}
