import { signalableFactory as actionableFactory, alertStateAdapter } from './form.adapter';

const { ResetErrorSignal } = alertStateAdapter.getSignals();

export class InitializeProfileFormSignal extends actionableFactory.create<'Initialize Profile Form'>(
  'Initialize Profile Form'
) {}

export class AddOneProfileTagSignal extends actionableFactory.create<
  'Add One Profile Tag Signal',
  { id: number }
>('Add One Profile Tag Signal') {}

export class RemoveOneProfileTagSignal extends actionableFactory.create<
  'Remove One Profile Tag Signal',
  { id: number }
>('Remove One Profile Tag Signal') {}

export class AddOneProfileLabelSignal extends actionableFactory.create<
  'Add One Profile Label Signal',
  { id: number }
>('Add One Profile Label Signal') {}

export class RemoveOneProfileLabelSignal extends actionableFactory.create<
  'Remove One Profile Label Signal',
  { id: number }
>('Remove One Profile Label Signal') {}

export class ResetWorkingTimeRegulationAlertSignal extends ResetErrorSignal {}
