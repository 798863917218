import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { IStaffingCascadeTimeWindowEntity } from '../../interfaces/api/staffing-cascade-time-window-entity';

export enum StaffingCascadeTimeWindowSignalTypes {
  LOAD_STAFFING_CASCADE_TIME_WINDOWS = '[StaffingCascadeTimeWindow][Signal] Load Collection',
}
export enum StaffingCascadeTimeWindowMessageTypes {
  SET_COLLECTION = '[StaffingCascadeTimeWindow][Message] Set Collection',
  ADD_ONE = '[StaffingCascadeTimeWindow][Message] Add One',
  UPDATE_ONE = '[StaffingCascadeTimeWindow][Message] Update One',
  UPSERT_ONE = '[StaffingCascadeTimeWindow][Message] Upsert One',
  DELETE_ONE = '[StaffingCascadeTimeWindow][Message] Delete One',
  ADD_MULTIPLE = '[StaffingCascadeTimeWindow][Message] Add All',
  DELETE_MULTIPLE = '[StaffingCascadeTimeWindow][Message] Delete Many',
  UPSERT_MULTIPLE = '[StaffingCascadeTimeWindow][Message] Upsert Many',
  UPDATE_MULTIPLE = '[StaffingCascadeTimeWindow][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = StaffingCascadeTimeWindowMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IStaffingCascadeTimeWindowEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = StaffingCascadeTimeWindowMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IStaffingCascadeTimeWindowEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = StaffingCascadeTimeWindowMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IStaffingCascadeTimeWindowEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = StaffingCascadeTimeWindowMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IStaffingCascadeTimeWindowEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = StaffingCascadeTimeWindowMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IStaffingCascadeTimeWindowEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = StaffingCascadeTimeWindowMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IStaffingCascadeTimeWindowEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = StaffingCascadeTimeWindowMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IStaffingCascadeTimeWindowEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = StaffingCascadeTimeWindowMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = StaffingCascadeTimeWindowMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type StaffingCascadeTimeWindowMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
