import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { IJobPositionEntity } from '../../interfaces/api/job-position-entity';
import { paginationAdapter } from './job-position.adapter';

export const { UpsertPageMessage, ResetPaginationMessage } = paginationAdapter.getMessages();

export class UpsertJobPositionPageMessage extends UpsertPageMessage {}
export class ResetJobPositionPaginationMessage extends ResetPaginationMessage {}

export enum JobPositionSignalTypes {
  LOAD_JOB_POSITIONS = '[JobPosition][Signal] Load Collection',
}
export enum JobPositionMessageTypes {
  SET_COLLECTION = '[JobPosition][Message] Set Collection',
  ADD_ONE = '[JobPosition][Message] Add One',
  UPDATE_ONE = '[JobPosition][Message] Update One',
  UPSERT_ONE = '[JobPosition][Message] Upsert One',
  DELETE_ONE = '[JobPosition][Message] Delete One',
  ADD_MULTIPLE = '[JobPosition][Message] Add All',
  DELETE_MULTIPLE = '[JobPosition][Message] Delete Many',
  UPSERT_MULTIPLE = '[JobPosition][Message] Upsert Many',
  UPDATE_MULTIPLE = '[JobPosition][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = JobPositionMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IJobPositionEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = JobPositionMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IJobPositionEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = JobPositionMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IJobPositionEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = JobPositionMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IJobPositionEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = JobPositionMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IJobPositionEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = JobPositionMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IJobPositionEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = JobPositionMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IJobPositionEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = JobPositionMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = JobPositionMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type JobPositionMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | UpsertJobPositionPageMessage
  | ResetJobPositionPaginationMessage;
