import { createFeatureSelector, createSelector } from '@ngrx/store';

import { paginationAdapter } from './external-staffing-candidate-document.adapter';
import { selectAll } from './external-staffing-candidate-document.reducer';
import {
  IExternalStaffingCandidateDocumentEntityState,
  IExternalStaffingCandidateDocumentFeatureState,
  IExternalStaffingCandidateDocumentSharedState,
} from './interfaces';

export const getEntityState = (state: {
  entityState: IExternalStaffingCandidateDocumentEntityState;
}) => state.entityState;

export const getBidDocumentNamespace = (profileId: string, provider: number, trust: number) =>
  `profile ${profileId} ${provider} ${trust} documents for current agency`;
/**
 * Create Feature Selectors based on the above selectors
 */
export const selectExternalStaffingCandidateDocumentState = createFeatureSelector<
  IExternalStaffingCandidateDocumentFeatureState & IExternalStaffingCandidateDocumentSharedState
>('externalStaffingCandidateDocuments');

export const selectExternalStaffingCandidateDocumentEntityState = createSelector(
  selectExternalStaffingCandidateDocumentState,
  getEntityState,
);

export const selectAllExternalStaffingCandidateDocuments = createSelector(
  selectExternalStaffingCandidateDocumentEntityState,
  selectAll,
);

export const externalStaffingCandidateDocumentPaginationSelectors =
  paginationAdapter.paginationSelectors(
    selectExternalStaffingCandidateDocumentState,
    null,
    'pagination',
    'entityState',
    false,
  );
