import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { IJobListingConversationProfileEntity } from '../../interfaces/api/job-listing-conversation-profile-entity';
import { paginationAdapter } from './job-listing-conversation-profile.adapter';

export const JobListingConversationProfilePaginationMessages = paginationAdapter.getMessages();

export class UpsertJobListingConversationProfilePageMessage extends JobListingConversationProfilePaginationMessages.UpsertPageMessage {}
export class UpsertJobListingConversationProfileMultiplePagesMessage extends JobListingConversationProfilePaginationMessages.UpsertMultiplePagesMessage {}
export class ResetJobListingConversationProfilePaginationMessage extends JobListingConversationProfilePaginationMessages.ResetPaginationMessage {}

export enum JobListingConversationProfileSignalTypes {
  LOAD_JOB_LISTING_CONVERSATION_PROFILES = '[JobListingConversationProfile][Signal] LoadCollection',
}
export enum JobListingConversationProfileMessageTypes {
  SET_COLLECTION = '[JobListingConversationProfile][Message] Set Collection',
  ADD_ONE = '[JobListingConversationProfile][Message] Add One',
  UPDATE_ONE = '[JobListingConversationProfile][Message] Update One',
  UPSERT_ONE = '[JobListingConversationProfile][Message] Upsert One',
  DELETE_ONE = '[JobListingConversationProfile][Message] Delete One',
  ADD_MULTIPLE = '[JobListingConversationProfile][Message] Add All',
  DELETE_MULTIPLE = '[JobListingConversationProfile][Message] Delete Many',
  UPSERT_MULTIPLE = '[JobListingConversationProfile][Message] Upsert Many',
  UPDATE_MULTIPLE = '[JobListingConversationProfile][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = JobListingConversationProfileMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IJobListingConversationProfileEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = JobListingConversationProfileMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IJobListingConversationProfileEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = JobListingConversationProfileMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IJobListingConversationProfileEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = JobListingConversationProfileMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IJobListingConversationProfileEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = JobListingConversationProfileMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IJobListingConversationProfileEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = JobListingConversationProfileMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IJobListingConversationProfileEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = JobListingConversationProfileMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IJobListingConversationProfileEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = JobListingConversationProfileMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = JobListingConversationProfileMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type JobListingConversationProfileMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
