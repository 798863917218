import { createSelector } from '@ngrx/store';
import { isEqual, sortBy } from 'lodash-es';
import { box, unbox } from 'ngrx-forms';

import { ITagFormState, UnboxedITagFormState } from '../interfaces/tag-form-state';
import { selectTagState } from '../tag.selectors';

export const selectTagFormState = createSelector(selectTagState, (state) => state.formState);

export const selectSearchKeywordControl = createSelector(
  selectTagFormState,
  (form) => form.controls.searchKeyword,
);

export const selectSearchKeywordValue = createSelector(
  selectSearchKeywordControl,
  (control) => control.value,
);

export const selectSelectedTagsControl = createSelector(
  selectTagFormState,
  (form) => form.controls.selectedTags,
);
export const selectDeleteModeSelectedTagsControl = createSelector(
  selectTagFormState,
  (form) => form.controls.deleteModeSelectedTags,
);

export const selectInitialSelectedTagsControl = createSelector(
  selectTagFormState,
  (form) => form.controls.initialSelectedTagsState,
);
export const selectSelectedTagsValue = createSelector(
  selectSelectedTagsControl,
  (control) => control.value,
);

export const selectDeleteModeSelectedTagsValue = createSelector(
  selectDeleteModeSelectedTagsControl,
  (control) => control.value,
);

export const selectInitialSelectedTagsValue = createSelector(
  selectInitialSelectedTagsControl,
  (control) => control.value,
);

export const selectMaxTagsErrorValue = createSelector(
  selectTagFormState,
  (form) => form.value.maxTagsError,
);

export const selectEligibilityWarningValue = createSelector(
  selectTagFormState,
  (form) => form.value.eligibilityWarning,
);

export const selectMyTagsDeleteModeValue = createSelector(
  selectTagFormState,
  (form) => form.value.myTagsDeleteMode,
);

export const selectOrganisationTagsDeleteModeValue = createSelector(
  selectTagFormState,
  (form) => form.value.organisationTagsDeleteMode,
);

export const selectHasChangesOnTags = createSelector(
  selectSelectedTagsValue,
  selectInitialSelectedTagsValue,

  (selectedTags, initialSelectedTags) =>
    isEqual(sortBy(unbox(selectedTags)), sortBy(unbox(initialSelectedTags))),
);

export const getTagsForm = (values: Partial<UnboxedITagFormState>): ITagFormState => ({
  myTagsDeleteMode: values.myTagsDeleteMode || false,
  organisationTagsDeleteMode: values.organisationTagsDeleteMode || false,
  deleteModeSelectedTags: values.deleteModeSelectedTags
    ? box(values.deleteModeSelectedTags)
    : box([]),
  initialSelectedTagsState: values.initialSelectedTagsState
    ? box(values.initialSelectedTagsState)
    : box([]),
  selectedTags: values.selectedTags ? box(values.selectedTags) : box([]),
  invitationMessage: values.invitationMessage || '',
  maxTagsError: values.maxTagsError || false,
  searchKeyword: values.searchKeyword || '',
  showMyList: values.showMyList || false,
  eligibilityWarning: values.eligibilityWarning || false,
});
