import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IRevalidationStatusEntity } from '../../interfaces/api/revalidation-status-entity';

export function sortByName(a: IRevalidationStatusEntity, b: IRevalidationStatusEntity): number {
  if (a > b) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

export const adapter: EntityAdapter<IRevalidationStatusEntity> =
  createEntityAdapter<IRevalidationStatusEntity>({
    sortComparer: sortByName,
    selectId: (a: IRevalidationStatusEntity) => a.val,
  });

export const initialState = adapter.getInitialState({});
