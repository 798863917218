import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IProfileEntity } from '../../interfaces/api/profile-entity';
import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';

export function sortByGmc(a: IProfileEntity, b: IProfileEntity): number {
  return a.id.localeCompare(b.id);
}

export const adapter: EntityAdapter<IProfileEntity> = createEntityAdapter<IProfileEntity>({
  selectId: (profile: IProfileEntity) => profile.id,
  sortComparer: sortByGmc,
});

export const messageableFactory = MessageableFactory.forFeature<'Profile'>('Profile');
export const signalableFactory = SignalableFactory.forFeature<'Profile'>('Profile');
export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);
export const loadingAdapter = createLoadingStateAdapter<'Profile'>(messageableFactory);

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedProfileId: null,
});
