import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICertificateEntity } from '../../interfaces/api/certificate-entity';
import { selectAll } from './certificate.reducer';
import {
  ICertificateEntityState,
  ICertificateFeatureState,
  ICertificateSharedState,
} from './interfaces';
import { paginationAdapter } from './certificate.adapter';

export const getEntityState = (state: { entityState: ICertificateEntityState }) =>
  state.entityState;

export const getCertificatesByProfileId =
  (profileId: string) => (certificates: ICertificateEntity[]) =>
    certificates.filter((certificate) => certificate.profile === profileId);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectCertificateState =
  createFeatureSelector<ICertificateFeatureState & ICertificateSharedState>('certificates');

export const selectCertificateEntityState = createSelector(selectCertificateState, getEntityState);

export const selectAllCertificates = createSelector(selectCertificateEntityState, selectAll);

export const selectCertificatesByProfileId = (profileId: string) =>
  createSelector(selectAllCertificates, getCertificatesByProfileId(profileId));

export const certificatePaginationSelectors = paginationAdapter.getSubresourcePaginationSelectors(
  selectCertificateEntityState
);
