import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { IApprovedRateEntity } from '../../interfaces/api/approved-rate-entity';
import { paginationAdapter } from './approved-rate.adapter';

export const ApprovedRatePaginationMessages = paginationAdapter.getMessages();

export class UpsertApprovedRatePageMessage extends ApprovedRatePaginationMessages.UpsertPageMessage {}
export class UpsertApprovedRateMultiplePagesMessage extends ApprovedRatePaginationMessages.UpsertMultiplePagesMessage {}

export class ResetApprovedRatePaginationMessage extends ApprovedRatePaginationMessages.ResetPaginationMessage {}

export enum ApprovedRateSignalTypes {
  LOAD_APPROVED_RATES = '[ApprovedRate][Signal] Load Collection',
}
export enum ApprovedRateMessageTypes {
  SET_COLLECTION = '[ApprovedRate][Message] Set Collection',
  ADD_ONE = '[ApprovedRate][Message] Add One',
  UPDATE_ONE = '[ApprovedRate][Message] Update One',
  UPSERT_ONE = '[ApprovedRate][Message] Upsert One',
  DELETE_ONE = '[ApprovedRate][Message] Delete One',
  ADD_MULTIPLE = '[ApprovedRate][Message] Add All',
  DELETE_MULTIPLE = '[ApprovedRate][Message] DELETE MANY',
  UPSERT_MULTIPLE = '[ApprovedRate][Message] Upsert Many',
  UPDATE_MULTIPLE = '[ApprovedRate][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = ApprovedRateMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IApprovedRateEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = ApprovedRateMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IApprovedRateEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = ApprovedRateMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IApprovedRateEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = ApprovedRateMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IApprovedRateEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = ApprovedRateMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IApprovedRateEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = ApprovedRateMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IApprovedRateEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = ApprovedRateMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IApprovedRateEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = ApprovedRateMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = ApprovedRateMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type ApprovedRateMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
