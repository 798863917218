import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { PaginatedStateService } from '@locumsnest/core/src';

import { IProfileLabelEntity } from '../../interfaces/api/profile-label-entity';
import {
  paginationMessages,
  ResetProfileLabelPaginationMessage,
  UpsertMultipleMessage,
  UpsertProfileLabelPageMessage,
} from './profile-label.messages';
import { ProfileLabelPersistenceService } from './profile-label.persistence.service';
import {
  profileLabelPaginationSelectors,
  selectProfileLabelEntityState,
} from './profile-label.selectors';

@Injectable({
  providedIn: 'root',
})
export class ProfileLabelService extends PaginatedStateService<
  IProfileLabelEntity,
  UpsertProfileLabelPageMessage,
  ResetProfileLabelPaginationMessage,
  UpsertMultipleMessage
> {
  constructor(
    protected store: Store,
    protected persistenceService: ProfileLabelPersistenceService
  ) {
    super();
  }

  get paginationMessages() {
    return paginationMessages;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return profileLabelPaginationSelectors;
  }

  get entityStateSelector() {
    return selectProfileLabelEntityState;
  }

  fetch() {
    return this.loadAllPages('default', {});
  }
}
