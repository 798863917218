import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IBankHolidayEntity } from '../../interfaces/api/bank-holiday-entity';
import { loadingAdapter, paginationAdapter } from './bank-holiday.adapter';
import { featureKey, selectAll } from './bank-holiday.reducer';
import {
  IBankHolidayEntityState,
  IBankHolidayFeatureState,
  IBankHolidaySharedState,
} from './interfaces';

export const getEntityState = (state: { entityState: IBankHolidayEntityState }) =>
  state.entityState;

export const getBankHoliday = (bankHolidayId: number) => (bankHolidays: IBankHolidayEntity[]) =>
  bankHolidays.find((bankHoliday) => bankHoliday.id === bankHolidayId);

export const selectBankHolidayState = createFeatureSelector<
  IBankHolidayFeatureState & IBankHolidaySharedState
>(featureKey);

export const selectBankHolidayEntityState = createSelector(selectBankHolidayState, getEntityState);

export const selectAllBankHolidays = createSelector(selectBankHolidayEntityState, selectAll);

export const selectBankHoliday = (bankHolidayId) =>
  createSelector(selectAllBankHolidays, getBankHoliday(bankHolidayId));

export const bankHolidayPaginationSelectors = paginationAdapter.paginationSelectors(
  selectBankHolidayEntityState,
  loadingAdapter
);
