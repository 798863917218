import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IJobListingTypeEntity } from '../../interfaces/api/job-listing-type-entity';

export const adapter: EntityAdapter<IJobListingTypeEntity> =
  createEntityAdapter<IJobListingTypeEntity>({
    selectId: (jobListingType: IJobListingTypeEntity) => jobListingType.val,
  });

export const initialState = adapter.getInitialState({
  selectedJobListingTypeId: null,
});
