import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { get } from 'lodash-es';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

import { ISelectOption } from '@locumsnest/components/src';
import { PaginatedStateService } from '@locumsnest/core';

import { ExternalStaffingProviderService } from '../../external-staffing-provider/+state/external-staffing-provider.service';
import { IExternalStaffingProviderTierEntity } from '../../interfaces/api/external-staffing-provider-tier-entity';
import { TrustExternalStaffingProviderTierService } from '../../trust-external-staffing-provider-tier/+state/trust-external-staffing-provider-tier.service';
import { paginationAdapter } from './external-staffing-provider-tier.adapter';
import {
  paginationMessages,
  ResetExternalStaffingProviderTierPaginationMessage,
  UpsertExternalStaffingProviderTierPageMessage,
  UpsertMultipleMessage,
} from './external-staffing-provider-tier.messages';
import { ExternalStaffingProviderTierPersistenceService } from './external-staffing-provider-tier.persistence.service';
import {
  externalStaffingProviderTierPaginationSelectors,
  selectAllExternalStaffingProviderTiers,
  selectExternalStaffingProviderTierEntityState,
} from './external-staffing-provider-tier.selectors';

@Injectable({
  providedIn: 'root',
})
export class ExternalStaffingProviderTierService extends PaginatedStateService<
  IExternalStaffingProviderTierEntity,
  UpsertExternalStaffingProviderTierPageMessage,
  ResetExternalStaffingProviderTierPaginationMessage,
  UpsertMultipleMessage
> {
  constructor(
    protected store: Store,
    protected persistenceService: ExternalStaffingProviderTierPersistenceService,
    protected trustExternalStaffingProviderTierService: TrustExternalStaffingProviderTierService,
    protected externalStaffingProviderService: ExternalStaffingProviderService
  ) {
    super();
  }
  get paginationMessages() {
    return paginationMessages;
  }

  get paginationSelectors() {
    return externalStaffingProviderTierPaginationSelectors;
  }

  get entityStateSelector() {
    return selectExternalStaffingProviderTierEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get loadingMessages() {
    return paginationAdapter.getLoadingMessages();
  }

  getAll() {
    return this.store.pipe(select(selectAllExternalStaffingProviderTiers));
  }

  getOrderTiers(): Observable<IExternalStaffingProviderTierEntity[]> {
    return this.getAll().pipe(map((tiers) => tiers.sort((a, b) => (a.order > b.order ? 1 : -1))));
  }

  getOrderTiersOptions(): Observable<ISelectOption[]> {
    return this.getAvailableTiers().pipe(
      map((orderTiers) =>
        orderTiers.map((orderTier) => ({
          id: orderTier.order,
          name: orderTier.display,
        }))
      )
    );
  }

  getAvailableTiers() {
    return combineLatest([
      this.trustExternalStaffingProviderTierService.getAllAfterLoading(),
      this.getOrderTiers(),
    ]).pipe(
      map(([trustProviderTiers, tiers]) =>
        tiers.filter(
          (tier) =>
            !!trustProviderTiers.find((trustProviderTier) => trustProviderTier.tier === tier.id)
        )
      )
    );
  }

  getCurrentProviderTierForHospital(hospitalId: number) {
    return this.externalStaffingProviderService.getAssignedId().pipe(
      switchMap((providerId) =>
        this.trustExternalStaffingProviderTierService.getForProviderHospital(providerId, hospitalId)
      ),
      filter((x) => !!x),
      map((trustTier) => trustTier.tier)
    );
  }

  getMaxTierId() {
    return this.getAvailableTiers().pipe(
      map((tiers) => get(tiers, [tiers.length - 1, 'id'], null)),
      distinctUntilChanged()
    );
  }

  fetch() {
    return this.loadAllPages('default', {});
  }
}
