import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IProfileFlagEntity } from '../../interfaces/api/profile-flag-entity';
import {
  IProfileFlagEntityState,
  IProfileFlagFeatureState,
  IProfileFlagSharedState,
} from './interfaces';
import { loadingAdapter, paginationAdapter } from './profile-flag.adapter';
import { selectAll } from './profile-flag.reducer';

export const getEntityState = (state: { entityState: IProfileFlagEntityState }) =>
  state.entityState;

export const getProfileFlag = (profileFlagId: number) => (profileFlags: IProfileFlagEntity[]) =>
  profileFlags.find((profileFlag) => profileFlag.id === profileFlagId);

export const getProfileFlagsByProfileId =
  (profileId: string) => (profileFlags: IProfileFlagEntity[]) =>
    profileFlags.filter((profileFlag) => profileFlag.profileId === profileId);

export const selectProfileFlagState = createFeatureSelector<
  IProfileFlagFeatureState & IProfileFlagSharedState
>('profileFlags');

export const selectProfileFlagEntityState = createSelector(selectProfileFlagState, getEntityState);

export const selectAllProfileFlags = createSelector(selectProfileFlagEntityState, selectAll);

export const selectProfileFlag = (profileFlagId) =>
  createSelector(selectAllProfileFlags, getProfileFlag(profileFlagId));

export const selectProfileFlagsByProfileId = (profileId: string) =>
  createSelector(selectAllProfileFlags, getProfileFlagsByProfileId(profileId));

export const profileFlagPaginationSelectors = paginationAdapter.paginationSelectors(
  selectProfileFlagEntityState,
  loadingAdapter
);
