import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IDeclineApplicationReasonEntity } from '../../interfaces/api/decline-application-reason-entity';

export function sortByName(
  a: IDeclineApplicationReasonEntity,
  b: IDeclineApplicationReasonEntity
): number {
  if (a > b) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

export const adapter: EntityAdapter<IDeclineApplicationReasonEntity> =
  createEntityAdapter<IDeclineApplicationReasonEntity>({
    sortComparer: sortByName,
    selectId: (a: IDeclineApplicationReasonEntity) => a.id,
  });

export const initialState = adapter.getInitialState({});
