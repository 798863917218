import { IProfileNote } from '../../../interfaces/api/profile-note-entity';
import {
  ISetEmploymentHistoryFilterPayload,
  ISetProfileAssignmentNumbersFilterPayload,
  ProfileDocument,
} from '../interfaces';
import { IUpdateExpirationDate } from '../interfaces/update-expiration-date-payload';
import { signalableFactory as actionableFactory, alertStateAdapter } from './ui.adapter';

const { ResetErrorSignal } = alertStateAdapter.getSignals();

export class InitializeProfileUiSignal extends actionableFactory.create<
  'Initialize Profile Ui',
  { namespace: string }
>('Initialize Profile Ui') {}

export class SendComplianceMessageUiSignal extends actionableFactory.create<
  'Send Compliance Message Ui',
  Record<string, never>
>('Send Compliance Message Ui') {}

export class LoadMoreEmploymentHistoryUiSignal extends actionableFactory.create<
  'Load More Employment History Ui',
  { namespace: string }
>('Load More Employment History Ui') {}

export class SetEmploymentHistoryFilterUiSignal extends actionableFactory.create<
  'Set Employment History Filters Ui',
  ISetEmploymentHistoryFilterPayload
>('Set Employment History Filters Ui') {}

export class SetProfileAssignmentNumbersFilterUiSignal extends actionableFactory.create<
  'Set Profile Assignment Numbers Filters Ui',
  ISetProfileAssignmentNumbersFilterPayload
>('Set Profile Assignment Numbers Filters Ui') {}

export class RemoveFileSignal extends actionableFactory.create<'Remove File', void>(
  'Remove File'
) {}

export class UploadFileSignal extends actionableFactory.create<'Upload File', void>(
  'Upload File'
) {}

export class UpdateCertificateExpirationDateSignal extends actionableFactory.create<
  'Update Certificate Expiration Date Signal',
  { payload: IUpdateExpirationDate }
>('Update Certificate Expiration Date Signal') {}

export class UpdateReferenceExpirationDateSignal extends actionableFactory.create<
  'Update Reference Expiration Date Signal',
  { payload: IUpdateExpirationDate }
>('Update Reference Expiration Date Signal') {}

export class DeleteSafeboxCertificateSignal extends actionableFactory.create<
  'Delete Safebox Certificate Signal',
  { document: ProfileDocument }
>('Delete Safebox Certificate Signal') {}

export class DeleteSafeboxReferenceSignal extends actionableFactory.create<
  'Delete Safebox Reference Signal',
  { document: ProfileDocument }
>('Delete Safebox Reference Signal') {}

export class CopyCertificateToSafeboxSignal extends actionableFactory.create<
  'Copy Certificate To Safebox Signal',
  { document: ProfileDocument }
>('Copy Certificate To Safebox Signal') {}

export class CopyReferenceToSafeboxSignal extends actionableFactory.create<
  'Copy Reference To Safebox Signal',
  { document: ProfileDocument }
>('Copy Reference To Safebox Signal') {}

export class CloneReferenceToHospitalSignal extends actionableFactory.create<
  'Clone Reference To Hospital Signal',
  {}
>('Clone Reference To Hospital Signal') {}

export class CloneCertificateToHospitalSignal extends actionableFactory.create<
  'Clone Certificate To Hospital Signal',
  {}
>('Clone Certificate To Hospital Signal') {}

export class DeleteProfileNoteSignal extends actionableFactory.create<
  'Delete Profile Note Signal',
  { noteId: number }
>('Delete Profile Note Signal') {}

export class DeleteProfileNoteForSelectedProfileSignal extends actionableFactory.create<
  'Delete Profile Note For Selected Profile Signal',
  { note: IProfileNote; namespace?: string }
>('Delete Profile Note For Selected Profile Signal') {}

export class SubmitNewProfileNoteSignal extends actionableFactory.create<
  'Submit New Profile Note Signal',
  { note: string; profileId: string; staffBankMembershipRequestId?: number }
>('Submit New Profile Note Signal') {}

export class SubmitNewProfileNoteForSelectedProfileSignal extends actionableFactory.create<
  'Submit New Profile Note For Selected Profile Signal',
  { note: string; namespace: string }
>('Submit New Profile Note For Selected Profile Signal') {}

export class LoadMoreProfileNotesUiSignal extends actionableFactory.create<
  'Load More Profile Notes Ui',
  { namespace: string }
>('Load More Profile Notes Ui') {}

export class SelectTabSignal extends actionableFactory.create<'Select Tab Signal', { tab: string }>(
  'Select Tab Signal'
) {}

export class LoadMoreComplianceMessagesUiSignal extends actionableFactory.create<
  'Load More Compliance Messages Ui',
  { namespace: string }
>('Load More Compliance Messages Ui') {}

export class MarkProfileNoteAsFlaggedSignal extends actionableFactory.create<
  'Mark Profile Note As Flagged Signal',
  { profileNoteId: number }
>('Mark Profile Note As Flagged Signal') {}

export class MarkProfileNoteAsUnFlaggedSignal extends actionableFactory.create<
  'Mark Profile Note As UnFlagged Signal',
  { profileNoteId: number }
>('Mark Profile Note As UnFlagged Signal') {}

export class ToggleFlaggedProfileNotesSignal extends actionableFactory.create<
  'Toggle Flagged Profile Notes Signal',
  { namespace: string; profileId?: string }
>('Toggle Flagged Profile Notes Signal') {}

export class ToggleFlaggedProfileNotesForProfileSignal extends actionableFactory.create<
  'Toggle Flagged Profile Notes For Profile Signal',
  { profileId?: string }
>('Toggle Flagged Profile Notes For Profile Signal') {}

export class InitializeCloneDocumentToHospitalSignal extends actionableFactory.create<
  'Initialize Clone Document To Hospital Signal',
  {}
>('Initialize Clone Document To Hospital Signal') {}

export class ResetAlertSignal extends ResetErrorSignal {}
