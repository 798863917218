import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get } from 'lodash-es';

import { ISelectOption } from '@locumsnest/components/src/lib/interfaces';

import { IJobListingTypeEntity } from '../../interfaces/api/job-listing-type-entity';
import { DEFAULT_JOB_LISTING_TYPE } from '../constants';
import { IJobListingTypeEntityState, IJobListingTypeFeatureState } from './interfaces';
import { selectAll } from './job-listing-type.reducer';

export const getEntityState = (state: { entityState: IJobListingTypeEntityState }) =>
  state.entityState;

export const getJobListingType =
  (jobListingTypeId: number) => (jobListingTypes: IJobListingTypeEntity[]) =>
    jobListingTypes.find((jobListingType) => jobListingType.val === jobListingTypeId);

export const getJobListingTypeOptions = (
  jobListingTypes: IJobListingTypeEntity[]
): ISelectOption[] =>
  jobListingTypes.map((jobListingType) => ({
    id: jobListingType.val,
    name: jobListingType.display,
  }));

export const getDefaultJobListingType = (jobListingTypes: IJobListingTypeEntity[]) => {
  const jobListingType = jobListingTypes.find(
    (category) => get(category, 'code') === DEFAULT_JOB_LISTING_TYPE
  );

  if (jobListingType) return jobListingType.val;

  return null;
};

export const selectJobListingTypeState =
  createFeatureSelector<IJobListingTypeFeatureState>('jobListingTypes');

export const selectJobListingTypeEntityState = createSelector(
  selectJobListingTypeState,
  getEntityState
);

export const selectAllJobListingTypes = createSelector(selectJobListingTypeEntityState, selectAll);

export const selectJobListingTypeOptions = createSelector(
  selectAllJobListingTypes,
  getJobListingTypeOptions
);

export const selectJobListingType = (jobListingTypeId) =>
  createSelector(selectAllJobListingTypes, getJobListingType(jobListingTypeId));

export const selectDefaultJobListingType = createSelector(
  selectAllJobListingTypes,
  getDefaultJobListingType
);
