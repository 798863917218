import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IPayRateTypeEntity } from '../../interfaces/api/pay-rate-type-entity';

export function sortByName(a: IPayRateTypeEntity, b: IPayRateTypeEntity): number {
  return a.name.localeCompare(b.name);
}

export const adapter: EntityAdapter<IPayRateTypeEntity> = createEntityAdapter<IPayRateTypeEntity>({
  sortComparer: sortByName,
});

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedPayRateTypeId: null,
});
