/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  alertHandler,
  conditionalErrorHandler,
} from 'apps/hospital-admin/src/app/core/+state/ui/ui.adapter';
import { HospitalOfficerService } from 'apps/hospital-admin/src/app/hospital-officer/+state/hospital-officer.service';
import { JobListingService } from 'apps/hospital-admin/src/app/job-listing/+state/job-listing.service';
import { difference, groupBy } from 'lodash-es';
import { box, Boxed, SetValueAction, unbox } from 'ngrx-forms';
import {
  catchError,
  concat,
  concatMap,
  debounceTime,
  EMPTY,
  exhaustMap,
  filter,
  first,
  forkJoin,
  iif,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';

import { ofMessageType, ofSignalType } from '@locumsnest/core/src';
import { BaseEffects } from '@locumsnest/core/src/lib/ngrx/effect';
import { RouterService } from '@locumsnest/core/src/lib/router/router.service';
import {
  DeleteMultipleMessage,
  JobListingTagsMessageTypes,
  JobListingTagsService,
  UpsertMultipleMessage,
} from '@locumsnest/job-listing-tags/src';

import {
  DeleteMultipleMessage as DeleteMultipleTagsMessage,
  ResetLoadingStateMessage,
  ResetPaginationMessage,
  SetLoadingStateMessage,
} from '../tag.messages';
import { TagService } from '../tag.service';
import {
  ClearDeleteModeSelectedTagsMessage,
  ClearTagSearchMessage,
  InitializeTagFormMessage,
  ResetMaxTagsErrorMessage,
  SearchTagMessage,
  SetMaxTagsErrorMessage,
  ToggleMyTagsDeleteModeMessage,
  ToggleOrganisationTagsDeleteModeMessage,
} from './tag-form.messages';
import { FORM_ID } from './tag-form.reducer';
import { getTagsForm } from './tag-form.selectors';
import {
  BulkSaveTagsSignal,
  ClearTagSearchSignal,
  CreateTagSignal,
  DeleteTagsSignal,
  InitializeBulkTagSignal,
  InitializeListPageTagSignal,
  InitializeTagFormSignal,
  LoadMoreOrganisationTagsSignal,
  LoadTagsByIdsSignal,
  SearchTagsSignal,
  SelectTagSignal,
  ToggleDeleteModeSignal,
  ToggleShowTagFormSignal,
} from './tag-form.signals';

@Injectable()
export class TagFormEffects extends BaseEffects {
  private selectedTagsRegex = new RegExp(`^${FORM_ID}.selectedTags$`);
  private deleteModeSelectedTagsRegex = new RegExp(`^${FORM_ID}.deleteModeSelectedTags$`);
  private searchKeywordRegex = new RegExp(`^${FORM_ID}.searchKeyword$`);

  initializeTagSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(InitializeTagFormSignal),
      concatLatestFrom(() => [
        this.routerService.getPathParam('id', true).pipe(map((listingId: string) => [+listingId])),
      ]),
      this.initialize(),
    ),
  );

  initializeBulkTagSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(InitializeBulkTagSignal),
      concatLatestFrom(() => [this.jobListingService.getSelectedJobListings()]),
      this.initialize(),
    ),
  );

  initializeListPageTagSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(InitializeListPageTagSignal),
      concatLatestFrom((action) => [of([action.payload.listings])]),
      this.initialize(),
    ),
  );

  searchTags$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(SearchTagsSignal),
      switchMap(({ payload }) =>
        concat(
          of(new SetLoadingStateMessage({ namespace: 'default' })),
          of(new SearchTagMessage({ search: payload.search })),
        ),
      ),
    ),
  );

  searchTagAfterSetLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofMessageType(SearchTagMessage),
      debounceTime(1000),
      switchMap((action) => this.tagService.fetch(action.payload.search)),
    ),
  );

  loadMoreOrganisationTags$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(LoadMoreOrganisationTagsSignal),
      mergeMap(() => this.tagService.loadMoreOrganisationTags()),
    ),
  );

  loadTagsByIds$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(LoadTagsByIdsSignal),
      switchMap(({ payload }) => this.tagService.loadByIds(payload.ids)),
    ),
  );

  saveTagSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetValueAction<Boxed<number[]>>>(SetValueAction.TYPE),
      filter((action) => this.selectedTagsRegex.test(action.controlId)),
      debounceTime(1000),
      concatLatestFrom(() => [
        this.routerService.getPathParam('id', false),
        this.routerService
          .getPathParam('id', false)
          .pipe(map((id) => +id))
          .pipe(switchMap((id) => this.jobListingTagsService.getAllByListingId(id))),
      ]),
      concatMap(([{ value }, listingId, jobListingTags]) => {
        const toSave: { listingId: number; tagId: number }[] = [];
        const toRemove: number[] = [];

        const selectedTagsIds = unbox(value);
        const jobListingTagsIds = jobListingTags.map((jt) => jt.tag);

        const tagsToSave = difference(selectedTagsIds, jobListingTagsIds);
        const tagsToRemove = difference(jobListingTagsIds, selectedTagsIds);

        tagsToSave.forEach((tagId) => {
          toSave.push({ listingId, tagId });
        });

        tagsToRemove.forEach((tagId) => {
          const jobListingTagId = jobListingTags.find((lt) => lt.tag === tagId)?.id;
          if (jobListingTagId) toRemove.push(jobListingTagId);
        });

        const actions: Actions[] = [];

        if (toSave.length)
          actions.push(
            this.jobListingTagsService
              .assignTags(toSave)
              .pipe(map((entities) => new UpsertMultipleMessage({ entities }))),
          );
        if (toRemove.length)
          actions.push(
            this.jobListingTagsService
              .unassignTags(toRemove)
              .pipe(map(() => new DeleteMultipleMessage({ ids: toRemove }))),
          );

        return forkJoin(actions).pipe(
          switchMap((storeActions) => merge(...storeActions.map((action) => of(action)))),
        );
      }),
    ),
  );

  selectedTagForDeletionSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetValueAction<Boxed<number[]>>>(SetValueAction.TYPE),
      debounceTime(500),
      filter((action) => this.deleteModeSelectedTagsRegex.test(action.controlId)),
      concatLatestFrom(({ value }) => [
        this.jobListingTagsService.getNotExistsByTagIds(unbox(value)),
      ]),
      concatMap(([, notExists]) => {
        if (notExists.length) return this.jobListingTagsService.loadByTagIds(notExists);
        return EMPTY;
      }),
    ),
  );

  searchOrganisationTagsSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetValueAction<string>>(SetValueAction.TYPE),
      debounceTime(1000),
      filter(
        ({ controlId, value }) =>
          this.searchKeywordRegex.test(controlId) &&
          value.trim().length > this.tagService.minSearchStringLength,
      ),
      concatLatestFrom(() => this.hospitalOfficerService.getAssigned()),
      switchMap(([{ value }, hospitalOfficer]) =>
        merge(
          this.tagService.initializePagination(
            this.tagService.organisationSearchTagsNamespace,
            {},
            { search: value, officer_id__not: hospitalOfficer?.id || 0 },
          ),
          of(new ResetMaxTagsErrorMessage({})),
        ),
      ),
    ),
  );

  bulkSaveTagsSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(BulkSaveTagsSignal),
      concatLatestFrom(() => [
        this.tagService.getSelectedTagsIds(),
        this.tagService.getInitialSelectedTagsIds(),
        this.jobListingService
          .getSelectedJobListings()
          .pipe(
            switchMap((listings) => this.jobListingTagsService.getTagsGroupByListingIds(listings)),
          ),
      ]),
      switchMap(([, tagsIds, initialTagsIds, listings]) => {
        const toSave: { listingId: number; tagId: number }[] = [];
        const toRemove: number[] = [];

        listings.forEach((listing) => {
          const tagsToSave = difference(tagsIds, initialTagsIds);
          const tagsToRemove = difference(initialTagsIds, tagsIds);
          if (tagsToSave.length) {
            tagsToSave.forEach((tagId) => {
              if (!listing.tags.find((lt) => lt.tag === tagId)) {
                toSave.push({ listingId: listing.listingId, tagId });
              }
            });
          }
          if (tagsToRemove.length) {
            tagsToRemove.forEach((tagId) => {
              const jobListingTagId = listing.tags.find((lt) => lt.tag === tagId)?.id;
              if (jobListingTagId) toRemove.push(jobListingTagId);
            });
          }
        });

        const actions: Actions[] = [];
        if (toSave.length)
          actions.push(
            this.jobListingTagsService
              .assignTags(toSave)
              .pipe(map((entities) => new UpsertMultipleMessage({ entities }))),
          );
        if (toRemove.length)
          actions.push(
            this.jobListingTagsService
              .unassignTags(toRemove)
              .pipe(map(() => new DeleteMultipleMessage({ ids: toRemove }))),
          );

        return forkJoin(actions).pipe(
          switchMap((storeActions) =>
            merge(
              ...storeActions.map((action) => of(action)),
              of(new ToggleShowTagFormSignal({})),
              alertHandler({ message: 'Tags added/removed successfully!', type: 'success' }),
            ),
          ),
          catchError(
            conditionalErrorHandler({
              errorEventMessageHandler: (message) =>
                `An error occurred while assigning tags: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Cannot assign tags. The error was: ${message}`,
              unknownErrorMessage: `Sorry! Cannot assign tags. Please try again in a few minutes`,
            }),
          ),
        );
      }),
    ),
  );

  deleteTagsSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(DeleteTagsSignal),
      concatLatestFrom(() => [
        this.tagService.getDeleteModeSelectedTagsIds(),
        this.tagService.getEntityDict(),
        this.tagService
          .getDeleteModeSelectedTagsIds()
          .pipe(switchMap((ids) => this.jobListingTagsService.getIdsByTagsIds(ids))),
      ]),
      switchMap(([, selectedTagIds, tagsDict, jobListingTagIds]) => {
        const tagOfficerIds: number[] = [];
        const tagsIds: number[] = [];

        selectedTagIds.forEach((tagId) => {
          const tagOfficerId = tagsDict[tagId]?.id;
          if (tagOfficerId) {
            tagsIds.push(tagId);
            tagOfficerIds.push(tagOfficerId);
          }
        });

        return this.tagService.deleteTags(tagOfficerIds).pipe(
          switchMap(() =>
            concat(
              of(new ResetPaginationMessage({ namespace: this.tagService.myTagsNamespace })),
              of(
                new ResetPaginationMessage({
                  namespace: this.tagService.organisationTagsNamespace,
                }),
              ),
              of(new DeleteMultipleTagsMessage({ ids: tagsIds })),
              of(new InitializeBulkTagSignal({})),
              of(new DeleteMultipleMessage({ ids: jobListingTagIds })),
            ),
          ),
        );
      }),
    ),
  );

  createTagSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(CreateTagSignal),
      concatLatestFrom(() => [
        this.tagService.getSearchFieldValue(),
        this.tagService.getMyTagsCount(),
      ]),
      exhaustMap(([, value, tagsCount]) => {
        if (tagsCount === 9) return of(new SetMaxTagsErrorMessage({}));

        return this.tagService
          .createTag(value)
          .pipe(
            switchMap((res) =>
              merge(
                this.tagService
                  .loadMyTags()
                  .pipe(takeUntil(this.getDestroyAction(ResetLoadingStateMessage.TYPE))),
                of(new SelectTagSignal({ id: res.tag.id })).pipe(),
              ),
            ),
          );
      }),
    ),
  );

  selectTagSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(SelectTagSignal),
      concatLatestFrom(() => this.tagService.getSelectedTagsIds()),
      map(
        ([{ payload }, tagsIds]) =>
          new SetValueAction(
            `${FORM_ID}.selectedTags`,
            box([...new Set([...tagsIds, payload.id])]),
          ),
      ),
    ),
  );

  clearSearchSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(ClearTagSearchSignal),
      switchMap(() =>
        merge(of(new ClearTagSearchMessage({})), of(new ResetMaxTagsErrorMessage({}))),
      ),
    ),
  );

  toggleDeleteModeSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(ToggleDeleteModeSignal),
      switchMap(({ payload }) => {
        const action =
          payload.tags === 'myTags'
            ? new ToggleMyTagsDeleteModeMessage({})
            : new ToggleOrganisationTagsDeleteModeMessage({});
        return merge(of(new ClearDeleteModeSelectedTagsMessage({})), of(action));
      }),
    ),
  );

  constructor(
    protected actions$: Actions,
    private jobListingService: JobListingService,
    private jobListingTagsService: JobListingTagsService,
    private tagService: TagService,
    private hospitalOfficerService: HospitalOfficerService,
    protected routerService: RouterService,
  ) {
    super();
  }

  private initialize() {
    return switchMap(([, listings]) =>
      merge(
        this.tagService.loadMyTags(),
        this.tagService.loadOrganisationTags(),
        iif(
          () => !!listings.length,
          this.jobListingTagsService.loadAllByListingIds(listings).pipe(
            switchMap((action) => {
              const actions: Observable<Action>[] = [of(action)];

              // select common tags
              if (action.type === JobListingTagsMessageTypes.UPSERT_MULTIPLE) {
                const { entities } = action.payload;
                const selectedEntities = entities.filter((e) => listings.includes(e.listing));
                const groupByTag = groupBy(selectedEntities, (entity) => entity.tag);

                const selectedListingsLength = listings.length;
                const selectedTags: number[] = [];

                Object.entries(groupByTag).forEach(([tag, jobListingTags]) => {
                  if (jobListingTags.length === selectedListingsLength) {
                    selectedTags.push(+tag);
                  }
                });

                const tagsForm = getTagsForm({
                  selectedTags,
                  initialSelectedTagsState: selectedTags,
                  eligibilityWarning: !!selectedEntities.length,
                });

                actions.push(of(new InitializeTagFormMessage({ initialValues: tagsForm })));
              }
              return merge(...actions);
            }),
          ),
          of(new InitializeTagFormMessage({})),
        ),
        this.jobListingTagsService.getAllAfterLoading().pipe(
          first(),
          switchMap((jobTags) => this.tagService.tagsToBeFetched(jobTags.map((t) => t.tag))),
          first(),
          switchMap((tags) => {
            if (tags.length) return of(new LoadTagsByIdsSignal({ ids: tags }));
            return EMPTY;
          }),
        ),
      ),
    );
  }
}
