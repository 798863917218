import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IExternalStaffingProviderEntity } from '../../interfaces/api/external-staffing-provider-entity';

@Injectable({
  providedIn: 'root',
})
export class ExternalStaffingProviderPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IExternalStaffingProviderEntity
> {
  protected readonly endpoint = 'externalStaffingProvider';
}
