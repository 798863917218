import { createSelector } from '@ngrx/store';
import isNil from 'lodash-es/isNil';
import { FormGroupState } from 'ngrx-forms';

import { Time } from '@locumsnest/core/src/lib/helpers';

import {
  IBidFragment,
  IBidFragmentWithRates,
  IExternalStaffingCandidateBidEntity,
  IExternalStaffingCandidateBidEntityWithRates,
  IJobListingEntityWithRates,
} from '../../../interfaces/api/external-staffing-candidate-bid-entity';
import {
  IJobFragmentRatesEntity,
  IJobListingEntity,
  IJobListingGradeEntity,
} from '../../../interfaces/api/job-listing-entity';
import { INITIAL_UPLOAD_FILE_FORM_STATE } from '../../../profile/+state/ui';
import { isFlatFee, isPercentageFee } from '../../type-guards';
import { selectExternalStaffingCandidateBidState } from '../external-staffing-candidate-bid.selectors';
import { State } from '../interfaces';
import { IExternalStaffingCandidateBidFormState } from '../interfaces/external-staffing-candidate-bid-form';
import { formatProviderFee, LEGACY_APPROVED_FEE_USER_DEFINED_PROPERTY } from './form.reducer';

export const getFormState = (state: State) => state.formState;

export const getCurrentExternalCandidate = (
  state: FormGroupState<IExternalStaffingCandidateBidFormState>,
) => state.value.id;

export const getUploadFileFormState = (
  formState: FormGroupState<IExternalStaffingCandidateBidFormState>,
) => formState.controls.documentUploadFileFormState;

export const getProfileId = (formState: FormGroupState<IExternalStaffingCandidateBidFormState>) =>
  formState.value.profile as string;

export const getGradeId = (formState: FormGroupState<IExternalStaffingCandidateBidFormState>) =>
  formState.value.grade;

export const selectExternalStaffingCandidateBidFormState = createSelector(
  selectExternalStaffingCandidateBidState,
  getFormState,
);

export const selectProviderFeeIsInvalid = createSelector(
  selectExternalStaffingCandidateBidFormState,
  (f) => f.controls.providerFee.isInvalid,
);

export const selectUploadFileFormState = createSelector(
  selectExternalStaffingCandidateBidFormState,
  getUploadFileFormState,
);

export const selectCurrentExternalCandidate = createSelector(
  selectExternalStaffingCandidateBidFormState,
  getCurrentExternalCandidate,
);

export const selectProfileId = createSelector(
  selectExternalStaffingCandidateBidFormState,
  getProfileId,
);

export const selectGradeId = createSelector(
  selectExternalStaffingCandidateBidFormState,
  getGradeId,
);

export const selectStaffingProvider = createSelector(
  selectExternalStaffingCandidateBidFormState,
  (formState) => formState.value.staffingProvider,
);

export const selectUserDefinedProperties = createSelector(
  selectExternalStaffingCandidateBidFormState,
  (state) => state.userDefinedProperties,
);

export const selectLegacyApprovedFee = createSelector(
  selectUserDefinedProperties,
  (userDefinedProperties) => userDefinedProperties[LEGACY_APPROVED_FEE_USER_DEFINED_PROPERTY],
);
export const selectLegacyApprovedFeeExists = createSelector(
  selectLegacyApprovedFee,
  (fee) => !isNil(fee),
);
export const selectDirectEngagementCandidate = createSelector(
  selectExternalStaffingCandidateBidFormState,
  (formState) => formState.value.directEngagementCandidate,
);
export const selectFormFeeApproved = createSelector(
  selectExternalStaffingCandidateBidFormState,
  (formState) => formState.value.providerFee.approvedRate,
);

export const getFragmentsFormState = <T extends IBidFragmentWithRates>(bidFragments: T[]) =>
  bidFragments.map((bidFragment) => ({
    ...bidFragment,
    fromTime: +Time.getMoment(bidFragment.fromTime),
    toTime: +Time.getMoment(bidFragment.toTime),
  }));

export const getEntityFormState = (
  entityState: IExternalStaffingCandidateBidEntityWithRates,
  initialFormState: IExternalStaffingCandidateBidFormState,
): IExternalStaffingCandidateBidFormState => ({
  ...initialFormState,
  ...entityState,
  providerFee: formatProviderFee(entityState.providerFee),
  documentUploadFileFormState: INITIAL_UPLOAD_FILE_FORM_STATE,
  startTime: entityState.startTime ? Time.formatDateTimePickerFormat(entityState.startTime) : null,
  endTime: entityState.endTime ? Time.formatDateTimePickerFormat(entityState.endTime) : null,
  bidFragments: getFragmentsFormState(entityState.bidFragments),
  flatRate: entityState.flatRate,
});

export const getEntityStateFromFormState = (
  formState: FormGroupState<IExternalStaffingCandidateBidFormState>,
  bidFragments?: IBidFragment[],
  listing?: number,
  flatRate?: number,
): IExternalStaffingCandidateBidEntity & { listing: number } => {
  const { documentUploadFileFormState, approvedProposedRate, ...formValue } = formState.value;
  return {
    ...formValue,
    flatRate,
    flatRateCurrency: flatRate ? 'GBP' : null,
    startTime: Time.getMoment(formValue.startTime).toDate(),
    endTime: Time.getMoment(formValue.endTime).toDate(),
    bidFragments,
    listing,
  };
};

export const getTotalHours = (
  formState: FormGroupState<IExternalStaffingCandidateBidFormState>,
  shiftDetails: IJobListingEntity<Date>,
) => {
  let totalHours = 0;

  if (formState.value.approvedProposedRate) {
    const jobListingGrades = shiftDetails?.grades.find((x) => x.grade === formState.value.grade);

    jobListingGrades?.jobFragments.forEach((fragment) => {
      const hours =
        (fragment.timeFragment.toTime.getTime() - fragment.timeFragment.fromTime.getTime()) /
        (1000 * 60 * 60);
      totalHours += hours;
    });
  } else {
    formState.value.bidFragments.forEach((fragment) => {
      const hours = (fragment.toTime - fragment.fromTime) / (1000 * 60 * 60);
      totalHours += hours;
    });
  }
  return totalHours;
};

export const getFragmentsTotal = (
  formState: FormGroupState<IExternalStaffingCandidateBidFormState>,
  shiftDetails: IJobListingEntityWithRates<Date>,
) => {
  let totalRate = 0;

  if (formState.value.approvedProposedRate) {
    const jobListingGrade = shiftDetails?.grades.find((x) => x.grade === formState.value.grade);
    if (jobListingGrade.flatRate) {
      totalRate += +jobListingGrade.flatRate.rate;
    }
    jobListingGrade?.jobFragments.forEach((fragment) => {
      const hours =
        (fragment.timeFragment.toTime.getTime() - fragment.timeFragment.fromTime.getTime()) /
        (1000 * 60 * 60);
      totalRate += +fragment.payRate.rate * hours;
    });
  } else {
    formState.value.bidFragments.forEach((fragment) => {
      const hours = (fragment.toTime - fragment.fromTime) / (1000 * 60 * 60);
      totalRate += +fragment.payRate * hours;
    });
    if (formState.value.flatRate) {
      totalRate += +formState.value.flatRate;
    }
  }
  return totalRate;
};

export const getRateAdjustment = (
  formState: FormGroupState<IExternalStaffingCandidateBidFormState>,
  shiftDetails: IJobListingEntityWithRates<Date>,
) => {
  let rateAdjustment = 1;

  if (!formState.value.providerFee.excluded) {
    if (isPercentageFee(formState.value.providerFee)) {
      rateAdjustment = 1 - +formState.value.providerFee.feePercentage / 100;
    }
    if (isFlatFee(formState.value.providerFee)) {
      const agencyTotal = +formState.value.providerFee.fee;
      const fragmentsTotal = getFragmentsTotal(formState, shiftDetails);
      rateAdjustment = 1 - agencyTotal / fragmentsTotal;
    }
  }

  return rateAdjustment;
};

export const getAgencyTake = (
  formState: FormGroupState<IExternalStaffingCandidateBidFormState>,
  shiftDetails: IJobListingEntityWithRates<Date>,
) => {
  if (isPercentageFee(formState.value.providerFee)) {
    const fee = +formState.value.providerFee.feePercentage;
    const totalRate = getFragmentsTotal(formState, shiftDetails);
    const agencyTake = (totalRate * fee) / 100;
    return agencyTake;
  }

  if (isFlatFee(formState.value.providerFee)) {
    return formState.value.providerFee.fee;
  }
};
export const getAgencyFeeBreakDown = (
  jobListingGrade: IJobListingGradeEntity<IJobFragmentRatesEntity>,
) =>
  jobListingGrade.jobFragments
    .map((fragment) => {
      const agencyFee = fragment.agencyFee ? +fragment.agencyFee : null;
      const nonResidentCalloutAgencyFee = fragment.nonResidentCalloutAgencyFee
        ? +fragment.nonResidentCalloutAgencyFee
        : null;
      if (agencyFee) {
        const { fromTime, toTime } = fragment.timeFragment;
        const hours = (toTime.getTime() - fromTime.getTime()) / (1000 * 60 * 60);
        return {
          from: new Date(fragment.timeFragment.fromTime),
          to: new Date(fragment.timeFragment.toTime),
          rate: agencyFee,
          calloutRate: nonResidentCalloutAgencyFee,
          rateCurrency: 'GBP',
          hours,
          total: agencyFee * hours,
        };
      }
    })
    .filter((x) => !!x);

export const getBidAgencyFeeBreakDown = (bid: IExternalStaffingCandidateBidEntity) =>
  bid.bidFragments
    .map((fragment) => {
      const agencyFee = fragment.agencyFee ? +fragment.agencyFee : null;
      const nonResidentCalloutAgencyFee = fragment.nonResidentCalloutAgencyFee
        ? +fragment.nonResidentCalloutAgencyFee
        : null;
      if (agencyFee) {
        const { fromTime, toTime } = fragment;
        const hours = (toTime.getTime() - fromTime.getTime()) / (1000 * 60 * 60);
        return {
          from: new Date(fromTime),
          to: new Date(toTime),
          rate: agencyFee,
          calloutRate: nonResidentCalloutAgencyFee,
          rateCurrency: 'GBP',
          hours,
          total: agencyFee * hours,
        };
      }
    })
    .filter((x) => !!x);

export const getCandidateTake = (
  formState: FormGroupState<IExternalStaffingCandidateBidFormState>,
  shiftDetails: IJobListingEntityWithRates<Date>,
) => {
  const totalRate = getFragmentsTotal(formState, shiftDetails);
  return totalRate * getRateAdjustment(formState, shiftDetails);
};
