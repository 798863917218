import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  IHospitalStaffBankUnavailabilityEntityState,
  IHospitalStaffBankUnavailabilityFeatureState,
  IHospitalStaffBankUnavailabilitySharedState,
} from './interfaces';
import { IHospitalStaffBankUnavailabilityEntity } from '../../interfaces/api/hospital-staff-bank-unavailability-entity';
import { selectAll } from './hospital-staff-bank-unavailability.reducer';
import { paginationAdapter } from './hospital-staff-bank-unavailability.adapter';

export const getEntityState = (state: {
  entityState: IHospitalStaffBankUnavailabilityEntityState;
}) => state.entityState;

export const getHospitalStaffBankUnavailabilityById =
  (id: number) => (staffBankUnavailabilities: IHospitalStaffBankUnavailabilityEntity[]) =>
    staffBankUnavailabilities.find((staffBankUnavailability) => staffBankUnavailability.id === id);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectHospitalStaffBankUnavailabilityState = createFeatureSelector<
  IHospitalStaffBankUnavailabilityFeatureState & IHospitalStaffBankUnavailabilitySharedState
>('hospitalstaffBankUnavailabilities');

export const selectHospitalStaffBankUnavailabilityEntityState = createSelector(
  selectHospitalStaffBankUnavailabilityState,
  getEntityState
);

export const selectAllHospitalStaffBankUnavailabilities = createSelector(
  selectHospitalStaffBankUnavailabilityEntityState,
  selectAll
);

export const selectHospitalStaffBankUnavailabilityById = (id: number) => {
  createSelector(
    selectAllHospitalStaffBankUnavailabilities,
    getHospitalStaffBankUnavailabilityById(id)
  );
};

export const staffbankUnavailabilityPaginationSelectors =
  paginationAdapter.getSubresourcePaginationSelectors(
    selectHospitalStaffBankUnavailabilityEntityState
  );
