import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';

import { IProfileFlagEntity } from '../../interfaces/api/profile-flag-entity';
import { paginationAdapter } from './profile-flag.adapter';

export const ProfileFlagPaginationMessages = paginationAdapter.getMessages();

export class UpsertProfileFlagPageMessage extends ProfileFlagPaginationMessages.UpsertPageMessage {}
export class UpsertProfileFlagMultiplePagesMessage extends ProfileFlagPaginationMessages.UpsertMultiplePagesMessage {}
export class ResetProfileFlagPaginationMessage extends ProfileFlagPaginationMessages.ResetPaginationMessage {}

export enum ProfileFlagSignalTypes {
  LOAD_PROFILE_FLAGS = '[ProfileFlag][Signal] Load Collection',
}

export enum ProfileFlagMessageTypes {
  SET_COLLECTION = '[ProfileFlag][Message] Set Collection',
  ADD_ONE = '[ProfileFlag][Message] Add One',
  UPDATE_ONE = '[ProfileFlag][Message] Update One',
  UPSERT_ONE = '[ProfileFlag][Message] Upsert One',
  DELETE_ONE = '[ProfileFlag][Message] Delete One',
  ADD_MULTIPLE = '[ProfileFlag][Message] Add All',
  DELETE_MULTIPLE = '[ProfileFlag][Message] Delete Many',
  UPSERT_MULTIPLE = '[ProfileFlag][Message] Upsert Many',
  UPDATE_MULTIPLE = '[ProfileFlag][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = ProfileFlagMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IProfileFlagEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = ProfileFlagMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IProfileFlagEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = ProfileFlagMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IProfileFlagEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = ProfileFlagMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IProfileFlagEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = ProfileFlagMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IProfileFlagEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = ProfileFlagMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IProfileFlagEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = ProfileFlagMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IProfileFlagEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = ProfileFlagMessageTypes.DELETE_ONE;

  constructor(public payload: { id: number }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = ProfileFlagMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type ProfileFlagMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
