import { Injectable } from '@angular/core';
import { IHospitalProfessionConfigurationEntity } from 'apps/hospital-admin/src/app/interfaces/api/hospital-profession-configuration-entity';
import { isNil } from 'lodash-es';
import { distinctUntilChanged, EMPTY, first, map, switchMap } from 'rxjs';

import { PaginatedStateService } from '@locumsnest/core/src';

import {
  paginationMessages,
  ResetHospitalProfessionConfigurationPaginationMessage,
  UpsertHospitalProfessionConfigurationPageMessage,
  UpsertMultipleMessage,
} from './hospital-profession-configuration.messages';
import { HospitalProfessionConfigurationPersistenceService } from './hospital-profession-configuration.persistence.service';
import {
  hospitalProfessionConfigurationPaginationSelectors,
  selectHospitalProfessionConfigurationEntityState,
} from './hospital-profession-configuration.selectors';

@Injectable({
  providedIn: 'root',
})
export class HospitalProfessionConfigurationService extends PaginatedStateService<
  IHospitalProfessionConfigurationEntity,
  UpsertHospitalProfessionConfigurationPageMessage,
  ResetHospitalProfessionConfigurationPaginationMessage,
  UpsertMultipleMessage
> {
  constructor(protected persistenceService: HospitalProfessionConfigurationPersistenceService) {
    super();
  }

  get paginationMessages() {
    return paginationMessages;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return hospitalProfessionConfigurationPaginationSelectors;
  }

  get entityStateSelector() {
    return selectHospitalProfessionConfigurationEntityState;
  }

  fetch() {
    return this.loadAllPages('default', {});
  }
  loadByHospitalProfession(hospitalId: number, professionId: number) {
    return this.getConfigurationByHospitalProfessionId(hospitalId, professionId).pipe(
      first(),
      switchMap((x) =>
        isNil(x)
          ? this.persistenceService
              .retrieve({ hospital: hospitalId, profession: professionId })
              .pipe(map((response) => new UpsertMultipleMessage({ entities: response.results })))
          : EMPTY,
      ),
    );
  }
  getConfigurationByProfessionId(professionId: number) {
    return this.getAll().pipe(
      map((configurations) =>
        configurations.find((configuration) => configuration?.professionId === professionId),
      ),
    );
  }
  getConfigurationByHospitalProfessionId(hospitalId: number, professionId: number) {
    return this.getAll().pipe(
      map((configurations) =>
        configurations.find(
          (configuration) =>
            configuration?.hospitalId === hospitalId &&
            configuration?.professionId === professionId,
        ),
      ),
      distinctUntilChanged(),
    );
  }
  getEnforcesDirectEngagement(hospitalId: number, professionId: number) {
    return this.getConfigurationByHospitalProfessionId(hospitalId, professionId).pipe(
      map((x) => x?.enforceDirectEngagement),
      distinctUntilChanged(),
    );
  }
}
