import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IApprovedRateEntity } from '../../interfaces/api/approved-rate-entity';

@Injectable({
  providedIn: 'root',
})
export class ApprovedRatePersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IApprovedRateEntity
> {
  protected readonly endpoint = 'approvedRate';
}
