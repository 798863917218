import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IDeclineApplicationReasonEntity } from '../../interfaces/api/decline-application-reason-entity';

@Injectable({
  providedIn: 'root',
})
export class DeclineApplicationReasonPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IDeclineApplicationReasonEntity
> {
  protected readonly endpoint = 'declineApplicationReason';
}
