import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { isString, uniq } from 'lodash-es';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { IQueryParams, PaginatedStateService } from '@locumsnest/core/src';
import { UrlHelpers } from '@locumsnest/core/src/lib/helpers';

import { HospitalOfficerService } from '../../hospital-officer/+state/hospital-officer.service';
import { IHospitalOfficerWithUser } from '../../interfaces/api/hospital-officer-entity';
import {
  IProfileNote,
  IProfileNoteEntity,
  IProfileNoteParams,
} from '../../interfaces/api/profile-note-entity';
import {
  ProfileNoteMessageTypes,
  ProfileNotePaginationMessages,
  ResetProfileNotePaginationMessage,
  SetCollectionMessage,
  UpsertMultipleMessage,
  UpsertProfileNotePageMessage,
} from './profile-note.messages';
import { ProfileNotePersistenceService } from './profile-note.persistence.service';
import {
  profileNotePaginationSelectors,
  selectAllProfileNotes,
  selectProfileNote,
  selectProfileNoteEntityState,
} from './profile-note.selectors';

@Injectable({
  providedIn: 'root',
})
export class ProfileNoteService extends PaginatedStateService<
  IProfileNoteEntity,
  UpsertProfileNotePageMessage,
  ResetProfileNotePaginationMessage,
  UpsertMultipleMessage
> {
  constructor(
    protected store: Store,
    protected persistenceService: ProfileNotePersistenceService,
    protected hospitalOfficerService: HospitalOfficerService
  ) {
    super();
  }

  get paginationMessages() {
    return ProfileNotePaginationMessages;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return profileNotePaginationSelectors;
  }

  get entityStateSelector() {
    return selectProfileNoteEntityState;
  }

  getAll() {
    return this.store.pipe(select(selectAllProfileNotes));
  }

  getConsecutiveProfileNoteEntities(namespace: string) {
    return this.getConsecutivePageEntities(namespace);
  }

  getProfileNotesByProfileId(namespace: string, profileId: string) {
    return this.getConsecutiveProfileNoteEntities(namespace).pipe(
      map((profileNotes) =>
        profileNotes
          .filter((profileNote) => profileNote.profile === profileId)
          .sort((a, b) => (a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0))
      )
    );
  }

  getOne(id: number) {
    return this.store.pipe(select(selectProfileNote(id)));
  }

  fetch(profileId?: string) {
    if (isString(profileId))
      return this.persistenceService
        .retrieve(null, { pathParams: { profileId } })
        .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));

    return this.persistenceService
      .retrieve()
      .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));
  }

  loadAllPagesAndLoadDependencies(namespace: string, filters = {}) {
    return this.loadAllPages(namespace, null, filters).pipe(
      mergeMap((action) => this.loadDependencies(action)),
      mergeMap((x) => x)
    );
  }

  loadByProfileIds(namespace: string, ids: string[]) {
    return this.loadAllPagesAndLoadDependencies(namespace, {
      profile: ids,
    }) as Observable<UpsertMultipleMessage>;
  }

  initializePaginationAndLoadDependencies(
    namespace: string,
    profileNotesParams: IProfileNoteParams
  ) {
    let filters: IQueryParams = {};

    for (const param in profileNotesParams) {
      if (profileNotesParams.hasOwnProperty(param)) {
        filters = UrlHelpers.addQueryParams(filters, param, profileNotesParams[param]);
      }
    }

    return this.initializePagination(namespace, {}, filters).pipe(
      mergeMap((action) => this.loadDependencies(action)),
      mergeMap((x) => x)
    );
  }

  loadNextAndLoadDependencies(namespace: string, profileNotesParams: IProfileNoteParams) {
    let filters: IQueryParams = {};

    for (const param in profileNotesParams) {
      if (profileNotesParams.hasOwnProperty(param)) {
        filters = UrlHelpers.addQueryParams(filters, param, profileNotesParams[param]);
      }
    }

    return this.loadNext(namespace, {}, filters).pipe(
      mergeMap((action) => this.loadDependencies(action)),
      mergeMap((x) => x)
    );
  }

  private loadDependencies(action) {
    const actions: Observable<Action>[] = [of(action)];
    if (action.type === ProfileNoteMessageTypes.UPSERT_MULTIPLE) {
      const payload = (action as UpsertMultipleMessage).payload;
      const officers = payload.entities.map((x) => x.createdBy).filter((x) => x);

      if (officers.length > 0)
        actions.push(this.hospitalOfficerService.loadByIds(uniq(officers), true));
    }

    return actions;
  }

  getProfileNotesWithOfficers(namespace: string, profileId: string) {
    return combineLatest([
      this.getProfileNotesByProfileId(namespace, profileId),
      this.hospitalOfficerService.getAllHospitalOfficerWithUsers(),
    ]).pipe(
      map(
        ([profileNotes, officersWithUsers]: [
          IProfileNoteEntity[],
          IHospitalOfficerWithUser[]
        ]): IProfileNote[] =>
          profileNotes.map((profileNote) => {
            const user = officersWithUsers.find((x) => x.id === profileNote.createdBy);

            return {
              ...profileNote,
              createdBy: user === undefined ? null : user.user,
            };
          })
      )
    );
  }
}
