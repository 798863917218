import { Action } from '@ngrx/store';
import { IStaffingCascadeStatusEntity } from '../../interfaces/api/staffing-cascade-status-entity';

export enum StaffingCascadeStatusSignalTypes {
  LOAD_STAFFING_CASCADE_STATUSES = '[StaffingCascadeStatus][Signal] Load Collection',
}
export enum StaffingCascadeStatusMessageTypes {
  SET_COLLECTION = '[StaffingCascadeStatus][Message] Set Collection',
  ADD_ONE = '[StaffingCascadeStatus][Message] Add One',
  UPDATE_ONE = '[StaffingCascadeStatus][Message] Update One',
  UPSERT_ONE = '[StaffingCascadeStatus][Message] Upsert One',
  DELETE_ONE = '[StaffingCascadeStatus][Message] Delete One',
  ADD_MULTIPLE = '[StaffingCascadeStatus][Message] Add All',
  DELETE_MULTIPLE = '[StaffingCascadeStatus][Message] Delete Many',
  UPSERT_MULTIPLE = '[StaffingCascadeStatus][Message] Upsert Many',
  UPDATE_MULTIPLE = '[StaffingCascadeStatus][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = StaffingCascadeStatusMessageTypes.SET_COLLECTION;
  constructor(public payload: { staffingCascadeStatuses: IStaffingCascadeStatusEntity[] }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = StaffingCascadeStatusMessageTypes.UPSERT_ONE;

  constructor(public payload: { staffingCascadeStatus: IStaffingCascadeStatusEntity }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = StaffingCascadeStatusMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { staffingCascadeStatuses: IStaffingCascadeStatusEntity[] }) {}
}

export type StaffingCascadeStatusMessages =
  | SetCollectionMessage
  | UpsertOneMessage
  | UpsertMultipleMessage;
