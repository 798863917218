import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';

import { IJobListingTemplateEntity } from '../../interfaces/api/job-listing-template-entity';
import { paginationAdapter } from './job-listing-template.adapter';

export const {
  ResetLoadingStateMessage,
  ResetPaginationMessage,
  SetLoadingStateMessage,
  UpsertMultiplePagesMessage,
  UpsertPageMessage,
} = paginationAdapter.getMessages();

export const paginationMessages = paginationAdapter.getMessages();
export class UpsertJobListingTemplatePageMessage extends UpsertPageMessage {}
export class UpsertJobListingTemplateMultiplePagesMessage extends UpsertMultiplePagesMessage {}
export class ResetJobListingTemplatePaginationMessage extends ResetPaginationMessage {}

export enum JobListingTemplateMessageTypes {
  SET_COLLECTION = '[JobListingTemplate][Message] Set Collection',
  ADD_ONE = '[JobListingTemplate][Message] Add One',
  SELECT_ONE = '[JobListingTemplate][Message] Select One',
  UPDATE_ONE = '[JobListingTemplate][Message] Update One',
  UPSERT_ONE = '[JobListingTemplate][Message] Upsert One',
  DELETE_ONE = '[JobListingTemplate][Message] Delete One',
  ADD_MULTIPLE = '[JobListingTemplate][Message] Add All',
  DELETE_MULTIPLE = '[JobListingTemplate][Message] Delete Many',
  UPSERT_MULTIPLE = '[JobListingTemplate][Message] Upsert Many',
  UPDATE_MULTIPLE = '[JobListingTemplate][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = JobListingTemplateMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IJobListingTemplateEntity[] }) {}
}

export class SelectOneMessage implements Action {
  readonly type = JobListingTemplateMessageTypes.SELECT_ONE;
  constructor(public payload: { id: number }) {}
}
export class AddOneMessage implements Action {
  readonly type = JobListingTemplateMessageTypes.ADD_ONE;
  constructor(public payload: { entity: IJobListingTemplateEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = JobListingTemplateMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IJobListingTemplateEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = JobListingTemplateMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IJobListingTemplateEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = JobListingTemplateMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IJobListingTemplateEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = JobListingTemplateMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IJobListingTemplateEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = JobListingTemplateMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IJobListingTemplateEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = JobListingTemplateMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = JobListingTemplateMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type JobListingTemplateMessages =
  | SetCollectionMessage
  | AddOneMessage
  | SelectOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
