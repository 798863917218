import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { ITrustExternalStaffingProviderTierEntity } from '../../interfaces/api/trust-external-staffing-provider-tier-entity';

@Injectable({
  providedIn: 'root',
})
export class TrustExternalStaffingProviderTierPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  ITrustExternalStaffingProviderTierEntity
> {
  protected readonly endpoint = 'trustExternalStaffingProviderTier';
}
