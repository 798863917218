import { SignalableFactory } from '@locumsnest/core/src/lib/ngrx';

const actionableFactory = SignalableFactory.forFeature<'ProfileEntity'>('ProfileEntity');

export class InitializeCollectionSignal extends actionableFactory.create<
  'InitializeCollection',
  Record<string, never>
>('InitializeCollection') {}

export class LoadProfilesByIdsSignal extends actionableFactory.create<
  'Load Profiles By Ids Signal',
  { profileIds: string[] }
>('Load Profiles By Ids Signal') {}

export type ProfileSignals = InitializeCollectionSignal | LoadProfilesByIdsSignal;
