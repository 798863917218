import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { isString } from 'lodash-es';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { IMessage } from '@locumsnest/components/src/lib/interfaces/message';
import {
  IQueryParams,
  PaginatedSubresourceStateService,
  RequestOptions,
} from '@locumsnest/core/src';

import { IComplianceMessageEntity } from '../../interfaces/api/compliance-message-entity';
import { IUserEntity } from '../../interfaces/api/user-entity';
import { UserService } from '../../user/+state/user.service';
import {
  ComplianceMessageMessageTypes,
  ResetComplianceMessagePaginationMessage,
  SetCollectionMessage,
  UpsertComplianceMessagePageMessage,
  UpsertMultipleMessage,
} from './compliance-message.messages';
import { ComplianceMessagePersistenceService } from './compliance-message.persistence.service';
import {
  complianceMessagePaginationSelectors,
  selectAllComplianceMessages,
  selectComplianceMessageEntityState,
  selectComplianceMessagesByProfileId,
} from './compliance-message.selectors';

export interface ComplianceMessageRequestOptions {
  profileId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ComplianceMessageService extends PaginatedSubresourceStateService<
  IComplianceMessageEntity,
  UpsertComplianceMessagePageMessage,
  ResetComplianceMessagePaginationMessage,
  UpsertMultipleMessage,
  RequestOptions<ComplianceMessageRequestOptions>
> {
  constructor(
    protected store: Store,
    protected persistenceService: ComplianceMessagePersistenceService,
    protected userService: UserService
  ) {
    super();
  }

  get upsertMessageClass() {
    return UpsertComplianceMessagePageMessage;
  }
  get clearMessageClass() {
    return ResetComplianceMessagePaginationMessage;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return complianceMessagePaginationSelectors;
  }

  get entityStateSelector() {
    return selectComplianceMessageEntityState;
  }

  getAll() {
    return this.store.pipe(select(selectAllComplianceMessages));
  }

  loadComplianceMessagesByProfileId(id): Observable<Action> {
    return this.fetch(id) as Observable<SetCollectionMessage>;
  }

  getComplianceMessagesByProfileId(profileId: string) {
    return this.store.pipe(select(selectComplianceMessagesByProfileId(profileId)));
  }

  getComplianceMessagesWithOfficers(profileId: string) {
    return combineLatest([
      this.getComplianceMessagesByProfileId(profileId),
      this.userService.getAll(),
    ]).pipe(
      map(([complianceMessages, users]: [IComplianceMessageEntity[], IUserEntity[]]): IMessage[] =>
        complianceMessages.map((complianceMessage) => {
          const user = users.find((x) => x.id === complianceMessage.sender);

          const message: IMessage = {
            sender: user === undefined ? null : user,
            messageText: complianceMessage.messageText,
            createdAt: complianceMessage.createdAt,
          };

          return message;
        })
      )
    );
  }

  fetch(profileId?: string) {
    if (isString(profileId))
      return this.persistenceService
        .retrieve(null, { pathParams: { profileId } })
        .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));

    return this.persistenceService
      .retrieve()
      .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));
  }

  loadAllPagesAndLoadDependencies(
    namespace: string,
    profileId: string,
    requestOptions: RequestOptions<ComplianceMessageRequestOptions>,
    filters: IQueryParams = {}
  ) {
    return this.loadAllPages(namespace, profileId, requestOptions, filters).pipe(
      mergeMap((action) => this.loadDependencies(action)),
      mergeMap((x) => x)
    );
  }

  initializePaginationAndLoadDependencies(
    namespace: string,
    profileId: string,
    requestOptions: RequestOptions<ComplianceMessageRequestOptions>,
    filters: IQueryParams = {}
  ) {
    return this.initializePagination(namespace, profileId, requestOptions, filters).pipe(
      mergeMap((action) => this.loadDependencies(action)),
      mergeMap((x) => x)
    );
  }

  loadNextAndLoadDependencies(
    namespace: string,
    profileId: string,
    requestOptions: RequestOptions<ComplianceMessageRequestOptions>,
    filters: IQueryParams = {}
  ) {
    return this.loadNext(namespace, profileId, requestOptions, filters).pipe(
      mergeMap((action) => this.loadDependencies(action)),
      mergeMap((x) => x)
    );
  }

  private loadDependencies(action) {
    const actions: Observable<Action>[] = [of(action)];
    if (action.type === ComplianceMessageMessageTypes.UPSERT_MULTIPLE) {
      const payload = (action as UpsertMultipleMessage).payload;
      const users = payload.entities.map((x) => x.sender);

      if (users.length > 0) actions.push(this.userService.loadMultiple(users));
    }

    return actions;
  }

  getRequestOptions(profileId: string): RequestOptions<ComplianceMessageRequestOptions> {
    return { pathParams: { profileId } };
  }
}
