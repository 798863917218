import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { get, isNil, isNumber, isString } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';

import { StateService } from '@locumsnest/core';
import { Query } from '@locumsnest/core/src';
import { Time } from '@locumsnest/core/src/lib/helpers';

import { IJobListingEntity } from '../../interfaces/api/job-listing-entity';
import { IStaffingCascadeTimeWindowEntity } from '../../interfaces/api/staffing-cascade-time-window-entity';
import { IProfessionSpecialtyAttributes } from './../../interfaces/api/profession-specialty-entity';
import { ProfessionSpecialtyService } from './../../profession-specialty/+state/profession-specialty.service';
import { UpsertMultipleMessage, UpsertOneMessage } from './staffing-cascade-time-window.messages';
import { StaffingCascadeTimeWindowPersistenceService } from './staffing-cascade-time-window.persistence.service';
import {
  selectAllStaffingCascadeTimeWindows,
  selectStaffingCascadeTimeWindowByProfessionSpecialtyId,
  selectStaffingCascadeTimeWindowOptions,
} from './staffing-cascade-time-window.selectors';

@Injectable({
  providedIn: 'root',
})
export class StaffingCascadeTimeWindowService extends StateService<IStaffingCascadeTimeWindowEntity> {
  constructor(
    protected persistenceService: StaffingCascadeTimeWindowPersistenceService,
    protected professionSpecialtyService: ProfessionSpecialtyService,
    private store: Store
  ) {
    super();
  }
  getValidityForJobListing(listing: IJobListingEntity) {
    return this.getByProfessionSpecialty(listing.professionSpecialty).pipe(
      map((timeWindow) => {
        if (timeWindow) {
          const hoursBeforeShift = get(timeWindow, 'hoursBeforeShift');
          return (
            Time.getMoment() < Time.getMoment(listing.startTime) &&
            +Time.getMoment(listing.startTime).subtract(hoursBeforeShift, 'hours') < Date.now()
          );
        }
        return false;
      })
    );
  }
  getForProfessionSpecialtyAttributes(
    professionSpecialtyAttributes: IProfessionSpecialtyAttributes
  ) {
    return this.professionSpecialtyService.getOneByAttributes(professionSpecialtyAttributes).pipe(
      mergeMap((professionSpecialty) => {
        if (!isNil(professionSpecialty)) {
          return this.getForProfessionSpecialty(professionSpecialty.id);
        }
        return of(null);
      })
    );
  }

  getByProfessionSpecialty(professionSpecialty: number) {
    return this.getForProfessionSpecialty(professionSpecialty);
  }

  getForProfessionSpecialty(id) {
    return this.store.pipe(select(selectStaffingCascadeTimeWindowByProfessionSpecialtyId(id)));
  }
  getStaffingCascadeTimeWindowOptions() {
    return this.store.pipe(select(selectStaffingCascadeTimeWindowOptions));
  }
  loadByProfessionSpecialtyId(id: number): Observable<UpsertMultipleMessage> {
    return this.fetch({
      professionSpecialty: id,
    }) as Observable<UpsertMultipleMessage>;
  }
  loadByProfessionSpecialtyAttributes(
    professionSpecialtyAttributes: IProfessionSpecialtyAttributes
  ): Observable<UpsertMultipleMessage> {
    return this.professionSpecialtyService.getOneByAttributes(professionSpecialtyAttributes).pipe(
      first(),
      mergeMap((professionSpecialty) => {
        if (professionSpecialty) {
          return this.loadByProfessionSpecialtyId(professionSpecialty.id);
        }
        return of<UpsertMultipleMessage>();
      })
    );
  }

  loadByProfessionSpecialty(professionSpecialty: number): Observable<UpsertMultipleMessage> {
    return this.loadByProfessionSpecialtyId(professionSpecialty);
  }

  getAll() {
    return this.store.pipe(select(selectAllStaffingCascadeTimeWindows));
  }
  fetch(query?: Query) {
    //@todo generalize this and move it to base service
    if (isString(query) || isNumber(query))
      return this.persistenceService
        .retrieve(query)
        .pipe(map((entity) => new UpsertOneMessage({ entity })));
    return this.persistenceService
      .retrieve(query)
      .pipe(map(({ results }) => new UpsertMultipleMessage({ entities: results })));
  }
}
