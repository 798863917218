import { IMultipleIndexField, IUniqueIndexField } from '@locumsnest/core/src/lib/ngrx/interfaces';
import {
  createAlertStateAdapter,
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src';
import {
  createSingletonResourceStateAdapter,
  ISingletonResourceAdapter,
} from '@locumsnest/core/src/lib/adapters/singleton-resource-adapter';
import {
  createEntityAdapter,
  EntityAdapter,
  index,
} from '@locumsnest/core/src/lib/ngrx/cached-state';
import {
  IAdjacentBidEntity,
  IExternalStaffingCandidateBidEntity,
} from '../../interfaces/api/external-staffing-candidate-bid-entity';

export function sortByName(
  a: IExternalStaffingCandidateBidEntity,
  b: IExternalStaffingCandidateBidEntity
): number {
  return a.id - b.id;
}
export const STAFFING_CASCADE_INDEX: IMultipleIndexField = {
  fieldName: 'staffingCascade',
  unique: false,
  reAssignMent: false,
};
const indexEntity = index([STAFFING_CASCADE_INDEX]);
export const adapter: EntityAdapter<IExternalStaffingCandidateBidEntity> =
  createEntityAdapter<IExternalStaffingCandidateBidEntity>(
    {
      sortComparer: sortByName,
    },
    indexEntity
  );

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedExternalStaffingCandidateBidId: null,
  indexes: {},
});

export const messageableFactory = MessageableFactory.forFeature<'ExternalStaffingCandidateBid'>(
  'ExternalStaffingCandidateBid'
);
export const signalableFactory = SignalableFactory.forFeature<'ExternalStaffingCandidateBid'>(
  'ExternalStaffingCandidateBid'
);

export const alertStateAdapter = createAlertStateAdapter(signalableFactory, messageableFactory);
export const { errorHandler, conditionalErrorHandler, alertHandler } =
  alertStateAdapter.getAlertHandlers();
export const { ResetErrorMessage, AlertErrorMessage } = alertStateAdapter.getMessages();
export const { alertSignalToMessage } = alertStateAdapter.getEffectOperators();

export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);
export const adjacentBidsAdapter: ISingletonResourceAdapter<string, IAdjacentBidEntity[]> =
  createSingletonResourceStateAdapter<'ExternalStaffingCandidateBid', string, IAdjacentBidEntity[]>(
    messageableFactory,
    'adjacentBidsState'
  );
