import { AsyncPipe, JsonPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  signal,
} from '@angular/core';
import { Boxed, FormControlState, NgrxFormsModule } from 'ngrx-forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import {
  AtomsModule,
  InputWithIconComponent,
  MoleculesModule,
  TagComponent,
  TagGroupComponent,
} from '@locumsnest/components/src';
import { TextTruncatePipe } from '@locumsnest/components/src/lib/pipes/text-truncate/text-truncate.pipe';
import { slideAnimation } from '@locumsnest/core';

import { ISelectedTag, TagsGroup } from '../../+state/interfaces/tag-form-state';

@Component({
  selector: 'ln-tag-widget',
  standalone: true,
  imports: [
    TagComponent,
    InputWithIconComponent,
    AsyncPipe,
    JsonPipe,
    NgrxFormsModule,
    MoleculesModule,
    TagGroupComponent,
    AtomsModule,
    NgClass,
    NgTemplateOutlet,
    InfiniteScrollModule,
    TextTruncatePipe,
  ],
  templateUrl: './tag-widget.component.html',
  styleUrl: './tag-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideAnimation('slideDownUp', 'Y')],
})
export class TagWidgetComponent {
  @Input({ required: true }) myTagsDeleteMode = false;
  @Input({ required: true }) organisationTagsDeleteMode = false;
  @Input({ required: true }) selectedTagsControl!: FormControlState<Boxed<number[]>>;
  @Input({ required: true }) searchKeywordControl!: FormControlState<string>;
  @Input({ required: true }) myTags: ISelectedTag[] = [];
  @Input({ required: true }) organisationTags: ISelectedTag[] = [];
  @Input({ required: true }) showCreateNewTag = false;
  @Input({ required: true }) hasMaxTagsError = false;
  @Input({ required: true }) organisationTagsCount = 0;
  @Input() eligibilityWarning = false;
  @Input() disableApplyTagsButton = false;
  @Input() myTagsSearchResult: ISelectedTag[] = [];
  @Input() organisationTagsSearchResult: ISelectedTag[] = [];
  @Input({ transform: booleanAttribute }) @HostBinding('class.display-as-row') displayAsRow = false;
  @Input({ required: true }) hasDeleteModeWarning = false;
  @Input({ required: true }) canDeleteOrganisationTags = false;

  @Output() enterEditMode = new EventEmitter<void>();
  @Output() exitEditMode = new EventEmitter<void>();
  @Output() saveTags = new EventEmitter<void>();
  @Output() clearSearch = new EventEmitter<void>();
  @Output() createTag = new EventEmitter<void>();
  @Output() selectedTag = new EventEmitter<number>();
  @Output() deleteTags = new EventEmitter<void>();
  @Output() toggleDeleteMode = new EventEmitter<TagsGroup>();
  @Output() loadMoreOrganisationTags = new EventEmitter<void>();

  public showConfirmation = signal(false);

  onEdit() {
    this.enterEditMode.emit();
  }

  onExit() {
    this.exitEditMode.emit();
  }

  onSave() {
    this.saveTags.emit();
  }

  onClearSearch() {
    this.clearSearch.emit();
  }

  onCreateTag() {
    if (!this.hasMaxTagsError) {
      this.createTag.emit();
    }
  }

  onSelectedTag(id: number) {
    this.selectedTag.emit(id);
  }

  toggleShowConfirmation() {
    this.showConfirmation.set(!this.showConfirmation());
  }

  onDeleteTags() {
    this.deleteTags.emit();
  }

  onToggleMyTagsDeleteMode() {
    this.toggleDeleteMode.emit('myTags');
    this.showConfirmation.set(false);
  }

  onToggleOrganisationTagsDeleteMode() {
    this.toggleDeleteMode.emit('organisationTags');
    this.showConfirmation.set(false);
  }

  onOrganisationScroll() {
    this.loadMoreOrganisationTags.emit();
  }
}
