import { IHospitalStaffBankUnavailabilityEntityState } from './interfaces';
import { IPaginatedSubresourceEntityState } from '@locumsnest/core/src';
import {
  HospitalStaffBankUnavailabilityMessageTypes as MessageTypes,
  AddOneMessage,
  SetCollectionMessage,
  UpsertOneMessage,
  AddMultipleMessage,
  UpsertMultipleMessage,
  UpdateOneMessage,
  UpdateMultipleMessage,
  DeleteOneMessage,
  DeleteMultipleMessage,
  HospitalStaffBankUnavailabilityMessages,
} from './hospital-staff-bank-unavailability.messages';
import {
  initialState,
  adapter,
  paginationAdapter,
} from './hospital-staff-bank-unavailability.adapter';
import { IHospitalStaffBankUnavailabilityEntity } from '../../interfaces/api/hospital-staff-bank-unavailability-entity';

export * from './interfaces';

export const paginationReducer = paginationAdapter.createReducer();

export type IHospitalStaffBankUnavailabilityPaginatedEntityState =
  IHospitalStaffBankUnavailabilityEntityState &
    IPaginatedSubresourceEntityState<IHospitalStaffBankUnavailabilityEntity>;
export function entityReducer(
  state = initialState,
  action: HospitalStaffBankUnavailabilityMessages
): IHospitalStaffBankUnavailabilityEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne((action as AddOneMessage).payload.entity, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne((action as UpsertOneMessage).payload.entity, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll((action as SetCollectionMessage).payload.entities, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany((action as AddMultipleMessage).payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany((action as UpsertMultipleMessage).payload.entities, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne((action as UpdateOneMessage).payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany((action as UpdateMultipleMessage).payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne((action as DeleteOneMessage).payload.id, state);
    }
    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany((action as DeleteMultipleMessage).payload.ids, state);
    }
    default: {
      return state;
    }
  }
}
export function paginatedEntityStateReducer(
  state: IHospitalStaffBankUnavailabilityPaginatedEntityState,
  action: HospitalStaffBankUnavailabilityMessages
) {
  let pagination;

  if (state) {
    pagination = state.pagination;
  }

  return {
    ...entityReducer(state, action),
    pagination: {
      ...paginationReducer(pagination, action),
    },
  };
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  paginationAdapter.getEntitySelectors();
