import {
  createCloneDocumentToHospitalFormStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src';
import { createAlertStateAdapter } from '@locumsnest/core/src/lib/adapters/alert-state-adapter';
import { createContactFormAdapter } from '@locumsnest/core/src/lib/adapters/contact-form-adapter';
import { createExpandableStateAdapter } from '@locumsnest/core/src/lib/adapters/expandable-state-adapter';
import { createNoteComponentAdapter } from '@locumsnest/core/src/lib/adapters/note-component-adapter';

export const messageableFactory = MessageableFactory.forFeature<'ProfileUI'>('ProfileUI');
export const signalableFactory = SignalableFactory.forFeature<'ProfileUI'>('ProfileUI');
export const alertStateAdapter = createAlertStateAdapter(signalableFactory, messageableFactory);
export const { errorHandler, conditionalErrorHandler, alertHandler } =
  alertStateAdapter.getAlertHandlers();
export const { ResetErrorMessage, AlertErrorMessage } = alertStateAdapter.getMessages();
export const { alertSignalToMessage } = alertStateAdapter.getEffectOperators();

export const expandableStateAdapter = createExpandableStateAdapter<'ProfileUI'>(
  signalableFactory,
  messageableFactory
);
export const { ToggleExpandableSignal, SetExpandExpandableSignal } =
  expandableStateAdapter.getSignals();

export const contactFromAdapter = createContactFormAdapter('COMPLIANCE_MESSAGE_FORM');
export const noteFromAdapter = createNoteComponentAdapter('PROFILE_NOTE_FORM');
export const cloneDocumentToHospitalFromAdapter = createCloneDocumentToHospitalFormStateAdapter(
  'CLONE_DOCUMENT_TO_HOSPITAL_FORM'
);
