import { inject, Injectable } from '@angular/core';
import { difference, flatMap, keyBy } from 'lodash-es';
import { combineLatest, map, Observable, startWith } from 'rxjs';

import { PaginatedStateService } from '@locumsnest/core/src';

import { IJobListingTags } from './interfaces';
import { LISTING_INDEX } from './job-listing-tags.adapter';
import {
  paginationMessages,
  ResetJobListingTagsPaginationMessage,
  UpsertJobListingTagsPageMessage,
  UpsertMultipleMessage,
} from './job-listing-tags.messages';
import { JobListingTagsPersistenceService } from './job-listing-tags.persistence.service';
import {
  jobListingTagsPaginationSelectors,
  selectJobListingTagsEntityState,
} from './job-listing-tags.selectors';

@Injectable({
  providedIn: 'root',
})
export class JobListingTagsService extends PaginatedStateService<
  IJobListingTags,
  UpsertJobListingTagsPageMessage,
  ResetJobListingTagsPaginationMessage,
  UpsertMultipleMessage
> {
  protected persistenceService = inject(JobListingTagsPersistenceService);

  get paginationMessages() {
    return paginationMessages;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return jobListingTagsPaginationSelectors;
  }

  get entityStateSelector() {
    return selectJobListingTagsEntityState;
  }

  loadAllByListingIds(listings: number[]): Observable<UpsertMultipleMessage> {
    return this.loadAllPages('default', {}, { listing: listings });
  }

  loadByTagIds(tagIds: number[]) {
    return this.loadAllPages('default', { queryParams: { tag: tagIds } });
  }

  fetch() {
    return this.loadAllPages('default', {});
  }

  getIdsByTagsIds(tagsIds: number[]) {
    return this.getAll().pipe(
      map((entities) => entities.filter((e) => tagsIds.includes(e.tag))),
      map((entities) => entities.map((e) => e.id)),
    );
  }

  getNotExistsByTagIds(tagsIds: number[]) {
    return this.getAll().pipe(
      map((entities) => {
        const jobListingTagsIds = entities.map((e) => e.tag);
        return difference(tagsIds, jobListingTagsIds);
      }),
    );
  }

  getAllByListingId(listingId: number) {
    return this.getMultipleByIndex(LISTING_INDEX, listingId).pipe(startWith<IJobListingTags[]>([]));
  }

  getTagsIdsByListingId(listing: number) {
    return this.getAllByListingId(listing).pipe(map((ll) => ll.map((l) => l.tag)));
  }

  getTagsIdsGroupByListingIds(listings: number[]) {
    return combineLatest(
      listings.map((listing) =>
        this.getTagsIdsByListingId(listing).pipe(map((tags) => ({ listingId: listing, tags }))),
      ),
    );
  }

  getTagsGroupByListingIds(listings: number[]) {
    return combineLatest(
      listings.map((listing) =>
        this.getAllByListingId(listing).pipe(map((tags) => ({ listingId: listing, tags }))),
      ),
    );
  }

  getUniqTagForListingIds(listings: number[]) {
    return combineLatest(listings.map((listing) => this.getTagsIdsByListingId(listing))).pipe(
      map((res) => [...new Set(flatMap(res))]),
    );
  }

  getTagsIdsDictionaryByListingIds(listings: number[]) {
    return this.getTagsIdsGroupByListingIds(listings).pipe(map((k) => keyBy(k, 'listingId')));
  }

  assignTags(payload: { listingId: number; tagId: number }[]) {
    const entities = payload.map((t) => ({
      id: t.listingId + t.tagId,
      listing: t.listingId,
      tag: t.tagId,
    }));

    return this.persistenceService.bulkCreateFilter(entities);
  }

  unassignTags(ids: number[]) {
    return this.persistenceService.bulkDelete({ ids });
  }
}
