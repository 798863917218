import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get } from 'lodash-es';

import { EMPTY_ARRAY } from '@locumsnest/core/src/lib/ngrx/constants';

import { paginationAdapter } from './external-staffing-provider.adapter';
import { featureKey, selectAll } from './external-staffing-provider.reducer';
import {
  IExternalStaffingProviderFeatureState,
  IExternalStaffingProviderSharedState,
} from './interfaces';

export const selectExternalStaffingProviderState = createFeatureSelector<
  IExternalStaffingProviderFeatureState & IExternalStaffingProviderSharedState
>(featureKey);

export const selectExternalStaffingProviderEntityState = createSelector(
  selectExternalStaffingProviderState,
  (state) => state.entityState,
);

export const selectAllExternalStaffingProviders = createSelector(
  selectExternalStaffingProviderEntityState,
  selectAll,
);

export const selectExternalStaffingProviderOptions = createSelector(
  selectAllExternalStaffingProviders,
  (externalStaffingProviders) =>
    externalStaffingProviders.map((externalStaffingProvider) => ({
      id: externalStaffingProvider.id,
      name: externalStaffingProvider.name,
    })),
);

export const selectAssignedExternalStaffingProviderId = createSelector(
  selectExternalStaffingProviderEntityState,
  (state) => state.assignedExternalStaffingProviderId,
);

export const selectAssignedExternalStaffingProvider = createSelector(
  selectExternalStaffingProviderEntityState,
  selectAssignedExternalStaffingProviderId,
  (state, assignedId) => state.entities[assignedId],
);

export const selectAssignedExternalStaffingProviderName = createSelector(
  selectAssignedExternalStaffingProvider,
  (state) => state?.name,
);

export const externalStaffingProviderPaginationSelectors = paginationAdapter.paginationSelectors(
  selectExternalStaffingProviderState,
  null,
  'pagination',
  'entityState',
  false,
);
