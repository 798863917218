import { Injectable } from '@angular/core';

import {
  HttpApiPersistenceService,
  IPaginatedResponse,
  RequestOptions,
} from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IApplicationHistoryEntity } from '../../interfaces/api/application-history-entity';

interface IEndpointOptions {
  profileId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApplicationHistoryPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IApplicationHistoryEntity,
  IPaginatedResponse<IApplicationHistoryEntity>,
  IApplicationHistoryEntity,
  RequestOptions<IEndpointOptions>
> {
  protected readonly endpoint = 'applicationHistory';
}
