import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { AttributeStateService } from '@locumsnest/core';
import { filterNonEmpty } from '@locumsnest/core/src/lib/ngrx/operators';

import { StaffingCascadeStatusCode } from '../../interfaces/api/job-listing-entity';
import { IStaffingCascadeStatusEntity } from '../../interfaces/api/staffing-cascade-status-entity';
import { SetCollectionMessage } from './staffing-cascade-status.messages';
import { StaffingCascadeStatusPersistenceService } from './staffing-cascade-status.persistence.service';
import {
  selectAllStaffingCascadeStatuses,
  selectStaffingCascadeStatus,
  selectStaffingCascadeStatusCode,
  selectStaffingCascadeStatusCodeById,
  selectStaffingCascadeStatusOptions,
} from './staffing-cascade-status.selectors';

@Injectable({
  providedIn: 'root',
})
export class StaffingCascadeStatusService extends AttributeStateService<IStaffingCascadeStatusEntity> {
  constructor(
    private store: Store,
    protected persistenceService: StaffingCascadeStatusPersistenceService
  ) {
    super();
  }
  getAll() {
    return this.store.pipe(select(selectAllStaffingCascadeStatuses), filterNonEmpty);
  }

  getOne(id: number) {
    return this.store.pipe(select(selectStaffingCascadeStatus(id)));
  }
  getStatusCode(id: number) {
    return this.store.pipe(select(selectStaffingCascadeStatusCode(id)));
  }

  getStaffingCascadeStatusCodeById(id: number) {
    return this.store.pipe(select(selectStaffingCascadeStatusCodeById(id)));
  }

  getAllOptions() {
    return this.store.pipe(
      select(selectStaffingCascadeStatusOptions),
      map((options) => [
        ...options,
        { id: -1, name: 'Not Cascaded' },
        { id: -2, name: 'Cascades: All' },
      ]),
      map((options) => options.sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0)))
    );
  }

  getPendingCascadesId() {
    return this.store.pipe(
      select(selectStaffingCascadeStatusOptions),
      filter((x) => !!x),
      map((options) => options.find((option) => option?.name === 'Pending Cascades')?.id)
    );
  }

  getStaffingCascadeStatusByCode(code: StaffingCascadeStatusCode) {
    return this.getAllAfterLoading().pipe(
      map((statuses) => statuses.find((status) => status.code === code)),
      distinctUntilChanged()
    );
  }

  getPendingStatus() {
    return this.getStaffingCascadeStatusByCode('PENDING');
  }

  getPendingStatusValue() {
    return this.getPendingStatus().pipe(
      map(({ val }) => val),
      distinctUntilChanged()
    );
  }

  getRejectedStatus() {
    return this.getStaffingCascadeStatusByCode('REJECTED');
  }

  getRejectedStatusValue() {
    return this.getRejectedStatus().pipe(
      map(({ val }) => val),
      distinctUntilChanged()
    );
  }

  getApprovedStatus() {
    return this.getStaffingCascadeStatusByCode('AUTHORIZED');
  }

  getApprovedStatusValue() {
    return this.getApprovedStatus().pipe(
      map(({ val }) => val),
      distinctUntilChanged()
    );
  }

  getCancelledStatus() {
    return this.getStaffingCascadeStatusByCode('CANCELED');
  }

  getCancelledStatusValue() {
    return this.getCancelledStatus().pipe(
      map(({ val }) => val),
      distinctUntilChanged()
    );
  }

  fetch() {
    return this.persistenceService
      .retrieve()
      .pipe(
        map((staffingCascadeStatuses) => new SetCollectionMessage({ staffingCascadeStatuses }))
      );
  }
}
