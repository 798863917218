import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IStaffBankCollaborationGroupEntity } from '../../interfaces/api/staff-bank-collaboration-group-entity';

@Injectable({
  providedIn: 'root',
})
export class StaffBankCollaborationGroupPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IStaffBankCollaborationGroupEntity
> {
  protected readonly endpoint = 'staffBankCollaborationGroup';
}
