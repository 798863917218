import { createFeatureSelector, createSelector } from '@ngrx/store';

import { paginationAdapter } from './hospital-profession-configuration.adapter';
import {
  featureKey,
  IHospitalProfessionConfigurationFeatureState,
} from './hospital-profession-configuration.reducer';

const selectHospitalProfessionConfigurationState =
  createFeatureSelector<IHospitalProfessionConfigurationFeatureState>(featureKey);

export const selectHospitalProfessionConfigurationEntityState = createSelector(
  selectHospitalProfessionConfigurationState,
  (state) => state.entityState
);

export const hospitalProfessionConfigurationPaginationSelectors =
  paginationAdapter.paginationSelectors(
    selectHospitalProfessionConfigurationState,
    null,
    'pagination',
    'entityState',
    false
  );
