import { createSelector } from '@ngrx/store';
import { State } from '../interfaces';
import { selectPassportDashboardState } from '../passport-dashboard.selectors';

// #region getSelectors

export const getStatsState = (state: State) => state.statsState;

// #endregion getSelectors

// #region selectSelectors

export const selectStatsState = createSelector(selectPassportDashboardState, getStatsState);

// #endregion selectSelectors
