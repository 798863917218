import { IHospitalProfessionConfigurationEntity } from 'apps/hospital-admin/src/app/interfaces/api/hospital-profession-configuration-entity';

import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src/';
import { createEntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';

const namespace = 'HospitalProfessionConfiguration';

const adapter = createEntityAdapter<IHospitalProfessionConfigurationEntity>({
  selectId: (entity) => entity.id,
});

const messageableFactory = MessageableFactory.forFeature<typeof namespace>(namespace);
const signalableFactory = SignalableFactory.forFeature<typeof namespace>(namespace);
const initialState = adapter.getInitialState({});
const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export { adapter, initialState, paginationAdapter };
