import { createFeatureSelector, createSelector } from '@ngrx/store';

import { alertHandler, conditionalErrorHandler } from '../../../core/+state/ui/ui.adapter';
import { alertHandler as jobListingTemplateAlertHandler } from '../../../job-listing-templates/+state/job-listing-template.adapter';
import { State as IJobListingFeatureState } from '../interfaces';
import { creteJobListingFormAdapter } from './form.feature.adapter';

export const selectJobListingFormWizardState = createSelector(
  createFeatureSelector<IJobListingFeatureState>('jobListings'),
  (state) => state.formState,
);

export const adapter = creteJobListingFormAdapter(
  'JobListing',
  selectJobListingFormWizardState,
  'job-listings',
  {
    alertHandler,
    jobListingTemplateAlertHandler,
    conditionalErrorHandler,
  },
);
