import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { paginationAdapter } from './external-staffing-candidate-document.adapter';
import { IExternalStaffingCandidateDocumentEntity } from '../../interfaces/api/external-staffing-candidate-document-entity';

export const externalStaffingCandidateDocumentPaginationMessages = paginationAdapter.getMessages();

export class UpsertExternalStaffingCandidateDocumentPageMessage extends externalStaffingCandidateDocumentPaginationMessages.UpsertPageMessage {}
export class UpsertExternalStaffingCandidateDocumentMultiplePagesMessage extends externalStaffingCandidateDocumentPaginationMessages.UpsertMultiplePagesMessage {}
export class ResetExternalStaffingCandidateDocumentPaginationMessage extends externalStaffingCandidateDocumentPaginationMessages.ResetPaginationMessage {}

export enum ExternalStaffingCandidateDocumentSignalTypes {
  LOAD_DOCUMENTS = '[ExternalStaffingCandidateDocument][Signal] Load Collection',
}
export enum ExternalStaffingCandidateDocumentMessageTypes {
  SET_COLLECTION = '[ExternalStaffingCandidateDocument][Message] Set Collection',
  ADD_ONE = '[ExternalStaffingCandidateDocument][Message] Add One',
  UPDATE_ONE = '[ExternalStaffingCandidateDocument][Message] Update One',
  UPSERT_ONE = '[ExternalStaffingCandidateDocument][Message] Upsert One',
  DELETE_ONE = '[ExternalStaffingCandidateDocument][Message] Delete One',
  ADD_MULTIPLE = '[ExternalStaffingCandidateDocument][Message] Add All',
  DELETE_MULTIPLE = '[ExternalStaffingCandidateDocument][Message] Delete Many',
  UPSERT_MULTIPLE = '[ExternalStaffingCandidateDocument][Message] Upsert Many',
  UPDATE_MULTIPLE = '[ExternalStaffingCandidateDocument][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = ExternalStaffingCandidateDocumentMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IExternalStaffingCandidateDocumentEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = ExternalStaffingCandidateDocumentMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IExternalStaffingCandidateDocumentEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = ExternalStaffingCandidateDocumentMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IExternalStaffingCandidateDocumentEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = ExternalStaffingCandidateDocumentMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IExternalStaffingCandidateDocumentEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = ExternalStaffingCandidateDocumentMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IExternalStaffingCandidateDocumentEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = ExternalStaffingCandidateDocumentMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IExternalStaffingCandidateDocumentEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = ExternalStaffingCandidateDocumentMessageTypes.UPDATE_MULTIPLE;

  constructor(
    public payload: {
      entities: Update<IExternalStaffingCandidateDocumentEntity>[];
    }
  ) {}
}

export class DeleteOneMessage implements Action {
  readonly type = ExternalStaffingCandidateDocumentMessageTypes.DELETE_ONE;

  constructor(public payload: { id: number }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = ExternalStaffingCandidateDocumentMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: number[] }) {}
}

export type ExternalStaffingCandidateDocumentMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
