import { Action } from '@ngrx/store';
import {
  createFormGroupState,
  createFormStateReducerWithUpdate,
  FormGroupState,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

import { INoteComponentState, INoteComponentValidator } from './interfaces';

export const DEFAULT_INITIAL_FORM_STATE = {
  note: '',
};

export const DEFAULT_VALIDATORS = {
  note: [required],
};

const createNoteComponentGroup = (validators: INoteComponentValidator) =>
  updateGroup<INoteComponentState>({
    note: validate<string>(validators.note),
  });

export const createNoteComponentReducer = (
  formId,
  validators: INoteComponentValidator = DEFAULT_VALIDATORS,
  INITIAL_FORM_STATE = DEFAULT_INITIAL_FORM_STATE
) => {
  const group = createNoteComponentGroup(validators);
  const INITIAL_FORM_REDUCER_STATE = createFormGroupState(formId, INITIAL_FORM_STATE);
  const reducer = createFormStateReducerWithUpdate<INoteComponentState>(group);
  return (state: FormGroupState<INoteComponentState> = INITIAL_FORM_REDUCER_STATE, note: Action) =>
    reducer(state, note);
};
