import { Injectable } from '@angular/core';
import * as chmln from '@chamaeleonidae/chmln';

import { environment } from '../../../../../hospital-admin/src/environments/environment';
import { IHospitalOfficerEntity } from '../../interfaces/api/hospital-officer-entity';

@Injectable({
  providedIn: 'root',
})
export class ChameleonService {
  constructor() {
    chmln.init(environment.chameleonKey);
  }
  identify(
    officer: IHospitalOfficerEntity & { firstName: string; lastName: string },
    sites: string,
    groups: string,
    professions: string,
    specialties: string,
    hospitalName: string
  ) {
    chmln.identify(officer.user, {
      firstName: officer.firstName,
      lastName: officer.lastName,
      email: officer.email,
      officer: officer.id,
      sites,
      groups,
      professions,
      specialties,
      company: {
        uid: officer.hospital,
        name: hospitalName,
      },
    });
  }

  trackProductChange(product: string) {
    chmln.track(`Product Change: ${product}`);
  }
}
