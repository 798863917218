import { createFeatureSelector, createSelector } from '@ngrx/store';

import { paginationAdapter } from './external-staffing-candidate-bid.adapter';
import { selectAll, selectEntities } from './external-staffing-candidate-bid.reducer';
import {
  IExternalStaffingCandidateBidFeatureState,
  IExternalStaffingCandidateBidSharedState,
} from './interfaces';

export const selectExternalStaffingCandidateBidState = createFeatureSelector<
  IExternalStaffingCandidateBidFeatureState & IExternalStaffingCandidateBidSharedState
>('externalStaffingCandidateBids');

export const selectExternalStaffingCandidateBidEntityState = createSelector(
  selectExternalStaffingCandidateBidState,
  (state) => state.entityState
);

export const selectAllExternalStaffingCandidateBids = createSelector(
  selectExternalStaffingCandidateBidEntityState,
  selectAll
);
export const selectExternalStaffingCandidateBid = (id: number) =>
  createSelector(
    selectExternalStaffingCandidateBidEntityState,
    selectEntities,
    ({ entities }) => entities[id]
  );

export const externalStaffingCandidateBidPaginationSelectors =
  paginationAdapter.paginationSelectors(
    selectExternalStaffingCandidateBidState,
    null,
    'pagination',
    'entityState',
    false
  );
