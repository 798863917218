import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { isString } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PaginatedSubresourceStateService, RequestOptions } from '@locumsnest/core/src';

import { IReferenceEntity } from '../../interfaces/api/reference-entity';
import {
  ResetReferencePaginationMessage,
  SetCollectionMessage,
  UpsertMultipleMessage,
  UpsertReferencePageMessage,
} from './reference.messages';
import { ReferencePersistenceService } from './reference.persistence.service';
import {
  referencePaginationSelectors,
  selectAllReferences,
  selectReferenceEntityState,
  selectReferencesByProfileId,
} from './reference.selectors';

export interface ReferenceRequestOptions {
  profileId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReferenceService extends PaginatedSubresourceStateService<
  IReferenceEntity,
  UpsertReferencePageMessage,
  ResetReferencePaginationMessage,
  UpsertMultipleMessage,
  RequestOptions<ReferenceRequestOptions>
> {
  constructor(protected store: Store, protected persistenceService: ReferencePersistenceService) {
    super();
  }

  get upsertMessageClass() {
    return UpsertReferencePageMessage;
  }
  get clearMessageClass() {
    return ResetReferencePaginationMessage;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return referencePaginationSelectors;
  }

  get entityStateSelector() {
    return selectReferenceEntityState;
  }

  getAll() {
    return this.store.pipe(select(selectAllReferences));
  }

  loadByProfileId(id): Observable<Action> {
    return this.fetch(id) as Observable<SetCollectionMessage>;
  }

  getByProfileId(profileId: string) {
    return this.store.pipe(select(selectReferencesByProfileId(profileId)));
  }

  fetch(profileId?: string) {
    if (isString(profileId))
      return this.persistenceService
        .retrieve(null, { pathParams: { profileId } })
        .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));

    return this.persistenceService
      .retrieve()
      .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));
  }

  getRequestOptions(profileId: string): RequestOptions<ReferenceRequestOptions> {
    return { pathParams: { profileId } };
  }
}
