import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IGmcStatusEntity } from '../../interfaces/api/gmc-status-entity';

@Injectable({
  providedIn: 'root',
})
export class GmcStatusPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IGmcStatusEntity,
  IGmcStatusEntity[]
> {
  protected readonly endpoint = 'gmcStatus';
}
