import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { ISafeboxReferenceEntity } from '../../interfaces/api/safebox-reference-entity';
import { paginationAdapter } from './safebox-reference.adapter';

export const { UpsertPageMessage, ResetPaginationMessage } = paginationAdapter.getMessages();

export class UpsertSafeboxReferencePageMessage extends UpsertPageMessage {}
export class ResetSafeboxReferencePaginationMessage extends ResetPaginationMessage {}

export enum SafeboxReferenceSignalTypes {
  LOAD_SAFEBOX_REFERENCES = '[Reference][Signal] Load Collection',
}
export enum SafeboxReferenceMessageTypes {
  SET_COLLECTION = '[SafeboxReference][Message] Set Collection',
  ADD_ONE = '[SafeboxReference][Message] Add One',
  UPDATE_ONE = '[SafeboxReference][Message] Update One',
  UPSERT_ONE = '[SafeboxReference][Message] Upsert One',
  DELETE_ONE = '[SafeboxReference][Message] Delete One',
  ADD_MULTIPLE = '[SafeboxReference][Message] Add All',
  DELETE_MULTIPLE = '[SafeboxReference][Message] Delete Many',
  UPSERT_MULTIPLE = '[SafeboxReference][Message] Upsert Many',
  UPDATE_MULTIPLE = '[SafeboxReference][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = SafeboxReferenceMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: ISafeboxReferenceEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = SafeboxReferenceMessageTypes.ADD_ONE;

  constructor(public payload: { entity: ISafeboxReferenceEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = SafeboxReferenceMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: ISafeboxReferenceEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = SafeboxReferenceMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: ISafeboxReferenceEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = SafeboxReferenceMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: ISafeboxReferenceEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = SafeboxReferenceMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<ISafeboxReferenceEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = SafeboxReferenceMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<ISafeboxReferenceEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = SafeboxReferenceMessageTypes.DELETE_ONE;

  constructor(public payload: { id: number }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = SafeboxReferenceMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: number[] }) {}
}

export type SafeboxReferenceMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | UpsertSafeboxReferencePageMessage
  | ResetSafeboxReferencePaginationMessage;
