import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { reducers } from './+state';
import { featureKey } from './+state/hospital-profession-configuration.reducer';

@NgModule({
  imports: [StoreModule.forFeature(featureKey, reducers)],
})
export class HospitalProfessionConfigurationModule {}
