import { IPaginatedSubresourceEntityState } from '@locumsnest/core/src';
import { ISafeboxCertificateEntity } from '../../interfaces/api/safebox-certificate-entity';
import { ISafeboxCertificateEntityState } from './interfaces';
import { adapter, initialState, paginationAdapter } from './safebox-certificate.adapter';
import {
  AddMultipleMessage,
  AddOneMessage,
  DeleteMultipleMessage,
  DeleteOneMessage,
  SafeboxCertificateMessages,
  SafeboxCertificateMessageTypes as MessageTypes,
  SetCollectionMessage,
  UpdateMultipleMessage,
  UpdateOneMessage,
  UpsertMultipleMessage,
  UpsertOneMessage,
} from './safebox-certificate.messages';

export * from './interfaces';

export const paginationReducer = paginationAdapter.createReducer();

export type ISafeboxCertificatePaginatedEntityState = ISafeboxCertificateEntityState &
  IPaginatedSubresourceEntityState<ISafeboxCertificateEntity>;

export function entityReducer(
  state = initialState,
  action: SafeboxCertificateMessages
): ISafeboxCertificateEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne((action as AddOneMessage).payload.entity, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne((action as UpsertOneMessage).payload.entity, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll((action as SetCollectionMessage).payload.entities, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany((action as AddMultipleMessage).payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany((action as UpsertMultipleMessage).payload.entities, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne((action as UpdateOneMessage).payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany((action as UpdateMultipleMessage).payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne((action as DeleteOneMessage).payload.id, state);
    }
    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany((action as DeleteMultipleMessage).payload.ids, state);
    }
    default: {
      return state;
    }
  }
}
export function paginatedEntityStateReducer(
  state: ISafeboxCertificatePaginatedEntityState,
  action: SafeboxCertificateMessages
) {
  let pagination;

  if (state) {
    pagination = state.pagination;
  }

  return {
    ...entityReducer(state, action),
    pagination: {
      ...paginationReducer(pagination, action),
    },
  };
}
export const { selectIds, selectEntities, selectAll, selectTotal } =
  paginationAdapter.getEntitySelectors();
