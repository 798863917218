import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { ISafeboxCertificateEntity } from '../../interfaces/api/safebox-certificate-entity';
import { paginationAdapter } from './safebox-certificate.adapter';

export const { UpsertPageMessage, ResetPaginationMessage } = paginationAdapter.getMessages();

export class UpsertSafeboxCertificatePageMessage extends UpsertPageMessage {}
export class ResetSafeboxCertificatePaginationMessage extends ResetPaginationMessage {}

export enum SafeboxCertificateSignalTypes {
  LOAD_SAFEBOX_CERTIFICATES = '[SafeboxCertificate][Signal] Load Collection',
}
export enum SafeboxCertificateMessageTypes {
  SET_COLLECTION = '[SafeboxCertificate][Message] Set Collection',
  ADD_ONE = '[SafeboxCertificate][Message] Add One',
  UPDATE_ONE = '[SafeboxCertificate][Message] Update One',
  UPSERT_ONE = '[SafeboxCertificate][Message] Upsert One',
  DELETE_ONE = '[SafeboxCertificate][Message] Delete One',
  ADD_MULTIPLE = '[SafeboxCertificate][Message] Add All',
  DELETE_MULTIPLE = '[SafeboxCertificate][Message] Delete Many',
  UPSERT_MULTIPLE = '[SafeboxCertificate][Message] Upsert Many',
  UPDATE_MULTIPLE = '[SafeboxCertificate][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = SafeboxCertificateMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: ISafeboxCertificateEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = SafeboxCertificateMessageTypes.ADD_ONE;

  constructor(public payload: { entity: ISafeboxCertificateEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = SafeboxCertificateMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: ISafeboxCertificateEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = SafeboxCertificateMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: ISafeboxCertificateEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = SafeboxCertificateMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: ISafeboxCertificateEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = SafeboxCertificateMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<ISafeboxCertificateEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = SafeboxCertificateMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<ISafeboxCertificateEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = SafeboxCertificateMessageTypes.DELETE_ONE;

  constructor(public payload: { id: number }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = SafeboxCertificateMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: number[] }) {}
}

export type SafeboxCertificateMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | UpsertSafeboxCertificatePageMessage
  | ResetSafeboxCertificatePaginationMessage;
