import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IProfileLabelEntity } from '../../interfaces/api/profile-label-entity';

@Injectable({
  providedIn: 'root',
})
export class ProfileLabelPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IProfileLabelEntity
> {
  protected readonly endpoint = 'profileLabel';
}
