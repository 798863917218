import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  IStaffBankCollaborationGroupFeatureState,
  IStaffBankCollaborationGroupSharedState,
} from './interfaces';
import { selectAll, selectEntities } from './staff-bank-collaboration-group.reducer';

export const selectStaffBankCollaborationGroupState = createFeatureSelector<
  IStaffBankCollaborationGroupFeatureState & IStaffBankCollaborationGroupSharedState
>('staffBankCollaborationGroups');

export const selectStaffBankCollaborationGroupEntityState = createSelector(
  selectStaffBankCollaborationGroupState,
  (state) => state.entityState
);

const selectStaffBankCollaborationGroupEntities = createSelector(
  selectStaffBankCollaborationGroupEntityState,
  selectEntities
);

export const selectAllStaffBankCollaborationGroups = createSelector(
  selectStaffBankCollaborationGroupEntityState,
  selectAll
);

export const selectStaffBankCollaborationGroup = (id: number) =>
  createSelector(selectStaffBankCollaborationGroupEntities, (entities) => entities[id]);

export const selectStaffBankCollaborationGroupsByHospitalIds = (ids: number[]) =>
  createSelector(selectAllStaffBankCollaborationGroups, (staffBankCollaborationGroups) =>
    staffBankCollaborationGroups.filter((group) =>
      group.hospitals.some((hospitalId) => ids.indexOf(hospitalId) >= 0)
    )
  );
