import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IGmcStatusEntity } from '../../interfaces/api/gmc-status-entity';

export function sortByName(a: IGmcStatusEntity, b: IGmcStatusEntity): number {
  return a.display.localeCompare(b.display);
}

export const adapter: EntityAdapter<IGmcStatusEntity> = createEntityAdapter<IGmcStatusEntity>({
  sortComparer: sortByName,
  selectId: (GmcStatus: IGmcStatusEntity) => GmcStatus.val,
});

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedGmcStatusId: null,
});
