import { createSelector } from '@ngrx/store';

import { selectJobListingState } from '../job-listing.selectors';

export const selectJobListingUiState = createSelector(
  selectJobListingState,
  (state) => state.uiState,
);

export const selectSubmissionInProgress = createSelector(
  selectJobListingUiState,
  (uiState) => uiState.submissionInProgress,
);

export const selectShowCascadeForm = createSelector(
  selectJobListingUiState,
  (uiState) => uiState.showCascadeForm,
);

export const selectShowBulkNoteForm = createSelector(
  selectJobListingUiState,
  (uiState) => uiState.showNoteForm,
);

export const selectShowTagForm = createSelector(
  selectJobListingUiState,
  (uiState) => uiState.showTagForm,
);

export const selectShowBulkUnpublishForm = createSelector(
  selectJobListingUiState,
  (uiState) => uiState.showBulkUnpublishForm,
);

export const selectShowBulkPrematchWidget = createSelector(
  selectJobListingUiState,
  (uiState) => uiState.showBulkPrematchWidget,
);

export const selectSummarySubmission = createSelector(
  selectJobListingUiState,
  (uiState) => uiState.summarySubmission,
);

export const selectApplicationSelectedPage = createSelector(
  selectJobListingUiState,
  (uiState) => uiState.applicationSelectedPage,
);

export const selectShowShiftDetailsCard = createSelector(
  selectJobListingUiState,
  (uiState) => uiState.showShiftDetailsCard,
);

export const selectLoadingInProgress = createSelector(
  selectJobListingUiState,
  (uiState) => uiState.loadingInProgress,
);

export const selectListingNotFound = createSelector(
  selectJobListingUiState,
  (uiState) => uiState.listingNotFound,
);

export const selectShowingAnyForm = createSelector(
  selectShowCascadeForm,
  selectShowBulkNoteForm,
  selectShowBulkUnpublishForm,
  selectShowBulkPrematchWidget,
  selectShowTagForm,
  selectSummarySubmission,
  (cascadeForm, notesForm, unpublishForm, showBulkPrematchWidget, showTagForm, summarySubmission) =>
    cascadeForm ||
    notesForm ||
    unpublishForm ||
    showBulkPrematchWidget ||
    showTagForm ||
    !!summarySubmission,
);
