import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';
import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IJobListingConversationProfileEntity } from '../../interfaces/api/job-listing-conversation-profile-entity';

export const adapter: EntityAdapter<IJobListingConversationProfileEntity> =
  createEntityAdapter<IJobListingConversationProfileEntity>({});

export const messageableFactory = MessageableFactory.forFeature<'JobListingConversationProfile'>(
  'JobListingConversationProfile'
);
export const signalableFactory = SignalableFactory.forFeature<'JobListingConversationProfile'>(
  'JobListingConversationProfile'
);
export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const loadingAdapter =
  createLoadingStateAdapter<'JobListingConversationProfile'>(messageableFactory);

export const initialState = adapter.getInitialState({
  selectedJobListingConversationProfileId: null,
});
