import {
  selectAll,
  IStaffBankUnavailabilityReasonEntityState,
  IStaffBankUnavailabilityReasonFeatureState,
  IStaffBankUnavailabilityReasonSharedState,
} from './staff-bank-unavailability-reason.reducer';
import { ISelectOption } from '@locumsnest/components/src/lib/interfaces';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStaffBankUnavailabilityReasonEntity } from '../../interfaces/api/staff-bank-unavailability-reason-entity';

export const getEntityState = (state: { entityState: IStaffBankUnavailabilityReasonEntityState }) =>
  state.entityState;

export const getStaffBankUnavailabilityReasonOptions = (
  reasons: IStaffBankUnavailabilityReasonEntity[]
): ISelectOption[] => reasons.map((reason) => ({ id: reason.id, name: reason.display }));

export const getStaffBankUnavailabilityReasonById =
  (id: number) => (staffBankUnavailabilityReasons: IStaffBankUnavailabilityReasonEntity[]) =>
    staffBankUnavailabilityReasons.find(
      (staffBankUnavailabilityReason) => staffBankUnavailabilityReason.id === id
    );

export const selectStaffBankUnavailabilityReasonState = createFeatureSelector<
  IStaffBankUnavailabilityReasonFeatureState & IStaffBankUnavailabilityReasonSharedState
>('staffBankUnavailabilityReasons');

export const selectStaffBankUnavailabilityReasonEntityState = createSelector(
  selectStaffBankUnavailabilityReasonState,
  getEntityState
);
export const selectAllStaffBankUnavailabilityReasons = createSelector(
  selectStaffBankUnavailabilityReasonEntityState,
  selectAll
);
export const selectStaffBankUnavailabilityReasonOptions = createSelector(
  selectAllStaffBankUnavailabilityReasons,
  getStaffBankUnavailabilityReasonOptions
);
export const selectStaffBankUnavailabilityReasonById = (id: number) =>
  createSelector(selectAllStaffBankUnavailabilityReasons, getStaffBankUnavailabilityReasonById(id));
