import { IBidFragmentWithRates } from '../../../interfaces/api/external-staffing-candidate-bid-entity';
import { IExternalStaffingCandidateBidFormState } from '../interfaces/external-staffing-candidate-bid-form';
import { messageableFactory as actionableFactory, alertStateAdapter } from './form.adapter';

export const {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ResetErrorMessage: ResetErrorAdapterMessage,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AlertErrorMessage: AlertErrorAdapterMessage,
} = alertStateAdapter.getMessages();
export class ResetErrorMessage extends ResetErrorAdapterMessage {}
export class AlertErrorMessage extends AlertErrorAdapterMessage {}

export class InitializeExternalStaffingCandidateBidFormMessage extends actionableFactory.create<
  'Initialize External Staffing Candidate Form',
  {
    externalStaffingCandidateBidFormState?: IExternalStaffingCandidateBidFormState;
    userDefinedProperties?: Record<string, any>;
  }
>('Initialize External Staffing Candidate Form') {}

export class RemoveDocumentMessage extends actionableFactory.create<'Remove Document', void>(
  'Remove Document',
) {}
export type ExternalStaffingCandidateBidFormMessages =
  | InitializeExternalStaffingCandidateBidFormMessage
  | ResetErrorMessage
  | AlertErrorMessage;
