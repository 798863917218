import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { IPayRateTypeEntity } from '../../interfaces/api/pay-rate-type-entity';

export enum PayRateTypeSignalTypes {
  LOAD_PAY_RATE_TYPES = '[PayRateType][Signal] Load Collection',
}
export enum PayRateTypeMessageTypes {
  SET_COLLECTION = '[PayRateType][Message] Set Collection',
  ADD_ONE = '[PayRateType][Message] Add One',
  UPDATE_ONE = '[PayRateType][Message] Update One',
  UPSERT_ONE = '[PayRateType][Message] Upsert One',
  DELETE_ONE = '[PayRateType][Message] Delete One',
  ADD_MULTIPLE = '[PayRateType][Message] Add All',
  DELETE_MULTIPLE = '[PayRateType][Message] Delete Many',
  UPSERT_MULTIPLE = '[PayRateType][Message] Upsert Many',
  UPDATE_MULTIPLE = '[PayRateType][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = PayRateTypeMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IPayRateTypeEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = PayRateTypeMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IPayRateTypeEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = PayRateTypeMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IPayRateTypeEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = PayRateTypeMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IPayRateTypeEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = PayRateTypeMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IPayRateTypeEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = PayRateTypeMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IPayRateTypeEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = PayRateTypeMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IPayRateTypeEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = PayRateTypeMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = PayRateTypeMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type PayRateTypeMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
