import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';

import { IBankHolidayEntity } from '../../interfaces/api/bank-holiday-entity';
import { paginationAdapter } from './bank-holiday.adapter';

export const bankHolidayPaginationMessages = paginationAdapter.getMessages();

export const { UpsertPageMessage, UpsertMultiplePagesMessage, ResetPaginationMessage } =
  paginationAdapter.getMessages();

export class UpsertBankHolidayPageMessage extends UpsertPageMessage {}
export class UpsertBankHolidayMultiplePagesMessage extends UpsertMultiplePagesMessage {}
export class ResetBankHolidayPaginationMessage extends ResetPaginationMessage {}

export enum BankHolidaySignalTypes {
  LOAD_BANK_HOLIDAYS = '[BankHoliday][Signal] Load Collection',
}

export enum BankHolidayMessageTypes {
  SET_COLLECTION = '[BankHoliday][Message] Set Collection',
  ADD_ONE = '[BankHoliday][Message] Add One',
  UPDATE_ONE = '[BankHoliday][Message] Update One',
  UPSERT_ONE = '[BankHoliday][Message] Upsert One',
  DELETE_ONE = '[BankHoliday][Message] Delete One',
  ADD_MULTIPLE = '[BankHoliday][Message] Add All',
  DELETE_MULTIPLE = '[BankHoliday][Message] Delete Many',
  UPSERT_MULTIPLE = '[BankHoliday][Message] Upsert Many',
  UPDATE_MULTIPLE = '[BankHoliday][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = BankHolidayMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IBankHolidayEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = BankHolidayMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IBankHolidayEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = BankHolidayMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IBankHolidayEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = BankHolidayMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IBankHolidayEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = BankHolidayMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IBankHolidayEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = BankHolidayMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IBankHolidayEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = BankHolidayMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IBankHolidayEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = BankHolidayMessageTypes.DELETE_ONE;

  constructor(public payload: { id: number }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = BankHolidayMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type BankHolidayMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
