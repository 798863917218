import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { isString } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PaginatedSubresourceStateService, RequestOptions } from '@locumsnest/core/src';

import { ICertificateEntity } from '../../interfaces/api/certificate-entity';
import {
  ResetCertificatePaginationMessage,
  SetCollectionMessage,
  UpsertCertificatePageMessage,
  UpsertMultipleMessage,
} from './certificate.messages';
import { CertificatePersistenceService } from './certificate.persistence.service';
import {
  certificatePaginationSelectors,
  selectAllCertificates,
  selectCertificateEntityState,
  selectCertificatesByProfileId,
} from './certificate.selectors';

export interface CertificateRequestOptions {
  profileId: string;
}

@Injectable({
  providedIn: 'root',
})
export class CertificateService extends PaginatedSubresourceStateService<
  ICertificateEntity,
  UpsertCertificatePageMessage,
  ResetCertificatePaginationMessage,
  UpsertMultipleMessage,
  RequestOptions<CertificateRequestOptions>
> {
  constructor(protected store: Store, protected persistenceService: CertificatePersistenceService) {
    super();
  }

  get upsertMessageClass() {
    return UpsertCertificatePageMessage;
  }
  get clearMessageClass() {
    return ResetCertificatePaginationMessage;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return certificatePaginationSelectors;
  }

  get entityStateSelector() {
    return selectCertificateEntityState;
  }

  getAll() {
    return this.store.pipe(select(selectAllCertificates));
  }

  loadByProfileId(id): Observable<Action> {
    return this.fetch(id) as Observable<SetCollectionMessage>;
  }

  getByProfileId(profileId: string) {
    return this.store.pipe(select(selectCertificatesByProfileId(profileId)));
  }

  fetch(profileId?: string) {
    if (isString(profileId))
      return this.persistenceService
        .retrieve(null, { pathParams: { profileId } })
        .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));

    return this.persistenceService
      .retrieve()
      .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));
  }

  getRequestOptions(profileId: string): RequestOptions<CertificateRequestOptions> {
    return { pathParams: { profileId } };
  }
}
