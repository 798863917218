import moment from 'moment';
import { FormGroupState } from 'ngrx-forms';

import { required } from '@locumsnest/core/src/lib/ngrx/helpers/ngrx-forms/error-messages.helper';

import { IProfileSearchFormState } from '../interfaces';
import { getProfileSearchFormRequiresDob } from '../profile.selectors';

export const hasValidDob = (state: FormGroupState<IProfileSearchFormState>) => () =>
  getProfileSearchFormRequiresDob(state)
    ? moment(state.value.dob, 'DD/MM/YYYY', true).isValid()
      ? null
      : { invalidDateRange: true }
    : null;

export const requiredWhen =
  <T>(userDefinedProperty) =>
  (state: FormGroupState<T>, negate: boolean) =>
  () => {
    const isTruthy = !!state.userDefinedProperties[userDefinedProperty];

    return (isTruthy && !negate) || (!isTruthy && negate) ? required : null;
  };

export const requiredWhenProfession = requiredWhen('registeredProfession');
