import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IApplicationHistoryEntity } from '../../interfaces/api/application-history-entity';
import { paginationAdapter } from './application-history.adapter';
import { selectAll } from './application-history.reducer';
import {
  IApplicationHistoryEntityState,
  IApplicationHistoryFeatureState,
  IApplicationHistorySharedState,
} from './interfaces';

export const getEntityState = (state: { entityState: IApplicationHistoryEntityState }) =>
  state.entityState;

export const getApplicationHistoriesByProfileId =
  (profileId: string) => (applicationHistories: IApplicationHistoryEntity[]) =>
    applicationHistories.filter((applicationHistory) => applicationHistory.profile === profileId);

export const selectApplicationHistoryState = createFeatureSelector<
  IApplicationHistoryFeatureState & IApplicationHistorySharedState
>('applicationHistories');
export const selectApplicationHistoryEntityState = createSelector(
  selectApplicationHistoryState,
  getEntityState,
);

export const selectAllApplicationHistories = createSelector(
  selectApplicationHistoryEntityState,
  selectAll,
);

export const selectApplicationHistoriesByProfileId = (profileId: string) =>
  createSelector(selectAllApplicationHistories, getApplicationHistoriesByProfileId(profileId));

export const applicationHistoryPaginationSelectors =
  paginationAdapter.getSubresourcePaginationSelectors(selectApplicationHistoryEntityState);
