import { Injectable } from '@angular/core';
import { catchError, of } from 'rxjs';

import {
  HttpApiPersistenceService,
  IPaginatedResponse,
  RequestOptions,
} from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import {
  ICloneReferenceToHospitalEntity,
  ICopyFromReferenceEntity,
  ISafeboxReferenceEntity,
  ISafeboxReferencePostEntity,
} from '../../interfaces/api/safebox-reference-entity';

interface IEndpointOptions {
  profileId: string;
}

@Injectable({
  providedIn: 'root',
})
export class SafeboxReferencePersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  ISafeboxReferenceEntity,
  IPaginatedResponse<ISafeboxReferenceEntity>,
  ISafeboxReferencePostEntity,
  RequestOptions<IEndpointOptions>
> {
  protected readonly endpoint = 'safeboxReference';

  copyFromReference(referenceId: number, profileId: string) {
    const data: ICopyFromReferenceEntity = {
      id: referenceId,
    };

    return this.create<ICopyFromReferenceEntity, ISafeboxReferenceEntity>(data, {
      pathParams: { profileId },
      controllerResource: 'copyFromReference',
      skipSerializer: true,
    }).pipe(catchError((e) => of(e)));
  }

  cloneReferenceToHospital(data: ICloneReferenceToHospitalEntity, profileId: string) {
    return this.create<ICloneReferenceToHospitalEntity, ISafeboxReferenceEntity>(data, {
      pathParams: { profileId },
      controllerResource: 'cloneReferenceToHospital',
      skipSerializer: true,
    }).pipe(catchError((e) => of(e)));
  }
}
