import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IStaffBankMembershipRequestStatusEntity } from '../../interfaces/api/staff-bank-membership-request-status-entity';

@Injectable({
  providedIn: 'root',
})
export class StaffBankMembershipRequestStatusPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IStaffBankMembershipRequestStatusEntity
> {
  protected readonly endpoint = 'staffBankMembershipRequestStatus';
}
