import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { isString } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IQueryParams, PaginatedStateService } from '@locumsnest/core/src';

import { HospitalOfficerService } from '../../hospital-officer/+state/hospital-officer.service';
import { IProfileFlagEntity } from '../../interfaces/api/profile-flag-entity';
import {
  ProfileFlagPaginationMessages,
  ResetProfileFlagPaginationMessage,
  SetCollectionMessage,
  UpsertMultipleMessage,
  UpsertProfileFlagPageMessage,
} from './profile-flag.messages';
import { ProfileFlagPersistenceService } from './profile-flag.persistence.service';
import {
  profileFlagPaginationSelectors,
  selectAllProfileFlags,
  selectProfileFlag,
  selectProfileFlagEntityState,
} from './profile-flag.selectors';

@Injectable({
  providedIn: 'root',
})
export class ProfileFlagService extends PaginatedStateService<
  IProfileFlagEntity,
  UpsertProfileFlagPageMessage,
  ResetProfileFlagPaginationMessage,
  UpsertMultipleMessage
> {
  constructor(
    protected store: Store,
    protected persistenceService: ProfileFlagPersistenceService,
    protected hospitalOfficerService: HospitalOfficerService
  ) {
    super();
  }

  get paginationMessages() {
    return ProfileFlagPaginationMessages;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return profileFlagPaginationSelectors;
  }

  get entityStateSelector() {
    return selectProfileFlagEntityState;
  }

  getAll() {
    return this.store.pipe(select(selectAllProfileFlags));
  }

  getProfileFlagsByProfileId(profileId: string) {
    return this.getAll().pipe(
      map((profileFlags) =>
        profileFlags.filter((profileFlag) => profileFlag.profileId === profileId)
      )
    );
  }

  getProfileFlagsByProfileIds(profileIds: string[]) {
    return this.getAll().pipe(
      map((profileFlags) =>
        profileFlags
          ? profileFlags.filter((profileFlag) => profileIds.indexOf(profileFlag.profileId) >= 0)
          : []
      )
    );
  }

  getOne(id: number) {
    return this.store.pipe(select(selectProfileFlag(id)));
  }

  fetch(profileId?: string) {
    if (isString(profileId))
      return this.persistenceService
        .retrieve(null, { pathParams: { profileId } })
        .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));

    return this.persistenceService
      .retrieve()
      .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));
  }

  loadByProfileIds(ids: string[]) {
    const params: IQueryParams = {
      profileId: ids,
    };

    return this.loadAllPages('default', {}, params) as Observable<UpsertMultipleMessage>;
  }
}
