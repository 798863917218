import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IProfileStatisticsEntity } from '../../interfaces/api/profile-statistics-entity';

export function sortByGmc(a: IProfileStatisticsEntity, b: IProfileStatisticsEntity): number {
  return a.profile.localeCompare(b.profile);
}

export const adapter: EntityAdapter<IProfileStatisticsEntity> =
  createEntityAdapter<IProfileStatisticsEntity>({
    selectId: (stats: IProfileStatisticsEntity) => stats.profile,
    sortComparer: sortByGmc,
  });

export const initialState = adapter.getInitialState({
  selectedProfileStatisticsId: null,
});
