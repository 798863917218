import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { flatMap } from 'lodash-es';
import { box, unbox } from 'ngrx-forms';

import { Time } from '@locumsnest/core/src/lib/helpers';

import { JobListingFormGroupState } from '../job-listing.reducer';
import * as fromForm from './form.selectors';

export function createJobListingFormSelectors(
  selectJobListingFormWizardState: MemoizedSelector<
    Record<string, unknown>,
    JobListingFormGroupState,
    DefaultProjectorFn<JobListingFormGroupState>
  >,
) {
  const selectShiftCreationFormControl = createSelector(
    selectJobListingFormWizardState,
    (form) => form.controls.shiftCreation,
  );

  const selectShiftCreationControlIsInvalid = createSelector(
    selectShiftCreationFormControl,
    (control) => control.isInvalid,
  );

  const selectGradesSectionControl = createSelector(
    selectJobListingFormWizardState,
    (form) => form.controls.gradesSection,
  );

  const selectRateViolationReasonControl = createSelector(
    selectGradesSectionControl,
    (form) => form.controls.rateViolationReason,
  );

  const selectRateViolationReasonValue = createSelector(
    selectGradesSectionControl,
    (form) => form.controls.rateViolationReason.value,
  );

  const selectGradesSectionControlIsInvalid = createSelector(
    selectGradesSectionControl,
    (control) => control.isInvalid,
  );

  const selectJobListingFormWardValue = createSelector(
    selectShiftCreationFormControl,
    (form) => form.value.ward,
  );

  const selectJobListingFormRosterValue = createSelector(
    selectShiftCreationFormControl,
    (form) => form.value.roster,
  );

  const selectJobListingFormPrimaryProfessionSpecialtyValue = createSelector(
    selectShiftCreationFormControl,
    (form) => form.value.professionSpecialty,
  );

  const selectJobListingFormCostCentreNumberValue = createSelector(
    selectJobListingFormWizardState,
    fromForm.getCostCentreNumberValue,
  );

  const selectJobListingFormProfessionValue = createSelector(
    selectShiftCreationFormControl,
    (form) => form.value.profession,
  );
  const selectJobListingFormNonResidentOnCallValue = createSelector(
    selectShiftCreationFormControl,
    (form) => form.value.nonResidentOnCall,
  );
  const selectJobListingFormCrossCoveringProfessionSpecialtiesObjectList = createSelector(
    selectShiftCreationFormControl,
    (form) => form.value.crossCoveringProfessionSpecialties,
  );
  const selectIsCrossCoveringForm = createSelector(
    selectJobListingFormCrossCoveringProfessionSpecialtiesObjectList,
    (value) => !!value.length,
  );
  const selectJobListingFormCrossCoveringProfessionSpecialtiesValue = createSelector(
    selectJobListingFormCrossCoveringProfessionSpecialtiesObjectList,
    (crossCoveringProfessionSpecialties) =>
      flatMap(crossCoveringProfessionSpecialties, (obj) => unbox(obj.professionSpecialties)),
  );
  const selectJobListingFormProfessionSpecialtyValues = createSelector(
    selectJobListingFormPrimaryProfessionSpecialtyValue,
    selectJobListingFormCrossCoveringProfessionSpecialtiesValue,
    (primary, crossCovering) => [primary, ...crossCovering],
  );
  const selectJobListingFormCrossCoveringProfessions = createSelector(
    selectJobListingFormCrossCoveringProfessionSpecialtiesObjectList,
    (value) => value.map((x) => x.profession),
  );
  const selectJobListingFormNotesValue = createSelector(
    selectShiftCreationFormControl,
    (form) => form.value.jobListingNotes,
  );

  const selectCopyNoteAcrossRepetitionDateFormValue = createSelector(
    selectShiftCreationFormControl,
    (form) => form.value.copyNoteAcrossRepetitionDate,
  );

  const selectJobListingFormSiteValue = createSelector(
    selectShiftCreationFormControl,
    (form) => form.value.site,
  );

  const selectJobListingFormGradeValues = createSelector(
    selectJobListingFormWizardState,
    fromForm.getJobListingGradeValues,
  );

  const selectExtendedJobListingFormWizardState = createSelector(
    selectJobListingFormWizardState,
    fromForm.getExtendedFormState,
  );
  const selectJobListingFormVacancyReason = createSelector(
    selectJobListingFormWizardState,
    fromForm.getFormVacancyReason,
  );
  const selectJobListingFormId = createSelector(
    selectShiftCreationFormControl,
    (form) => form.value.id,
  );
  const selectJobListingFromForm = createSelector(
    selectExtendedJobListingFormWizardState,
    fromForm.getEntity(),
  );
  const selectLocalJobListingFromForm = createSelector(
    selectExtendedJobListingFormWizardState,
    fromForm.getEntity((timestamp) => Time.getDate(timestamp)),
  );

  const selectIsSectionDetailsInvalid = createSelector(
    selectJobListingFormWizardState,
    fromForm.isSectionDetailsInvalid,
  );

  const selectIsSectionDescriptionInvalid = createSelector(
    selectJobListingFormWizardState,
    fromForm.isSectionDescriptionInvalid,
  );

  const selectIsSectionGradesInvalid = createSelector(
    selectExtendedJobListingFormWizardState,
    fromForm.isSectionGradesInvalid,
  );

  const selectIsExternalJobListingMode = createSelector(
    selectJobListingFormWizardState,
    fromForm.getIsExternalJobListingMode,
  );

  const selectIsFormInvalid = createSelector(
    selectIsSectionDetailsInvalid,
    selectIsSectionDescriptionInvalid,
    selectIsSectionGradesInvalid,
    (isSectionDetailsInvalid, isSectionDescriptionInvalid, isSectionGradesInvalid) =>
      isSectionDetailsInvalid || isSectionDescriptionInvalid || isSectionGradesInvalid,
  );

  const selectJobListingGradeControl = (id) =>
    createSelector(selectExtendedJobListingFormWizardState, fromForm.getJobListingGrade(id));

  const selectEnabledJobListingGrades = createSelector(
    selectJobListingFormWizardState,
    (formState) =>
      formState.controls.gradesSection.controls.grades.controls.filter(
        (grade) => grade.controls.grade.isEnabled,
      ),
  );

  const selectJobListingGradesAreValid = (grades: number[]) =>
    createSelector(selectEnabledJobListingGrades, (selectedListingGrades) => {
      const validatedGrades = selectedListingGrades.filter(({ value }) =>
        grades.includes(value.grade),
      );
      return !!validatedGrades.length && validatedGrades.every(({ isValid }) => isValid);
    });

  const selectProfessionGradesAreValid = (profession: number) =>
    createSelector(selectEnabledJobListingGrades, (selectedListingGrades) => {
      const validatedGrades = selectedListingGrades.filter(
        ({ userDefinedProperties }) => userDefinedProperties.profession === profession,
      );
      return !!validatedGrades.length && validatedGrades.every(({ isValid }) => isValid);
    });

  const selectShiftSchedulerControl = createSelector(
    selectExtendedJobListingFormWizardState,
    (form) => form.controls.shiftScheduler,
  );

  const selectCostCentreNumberValue = createSelector(
    selectShiftSchedulerControl,
    (form) => form.value.costCentreNumber,
  );

  const selectConsentBackdatedShiftsControl = createSelector(
    selectShiftSchedulerControl,
    (form) => form.controls.consentBackdatedShifts,
  );

  const selectShiftSchedulerControlIsInvalid = createSelector(
    selectShiftSchedulerControl,
    (control) => control.isInvalid,
  );

  const selectStartTimeValue = createSelector(
    selectShiftSchedulerControl,
    (state) => state.value.startTime,
  );

  const selectEndTimeValue = createSelector(
    selectShiftSchedulerControl,
    (state) => state.value.endTime,
  );

  const selectIsEmploymentPeriodInPast = createSelector(selectStartTimeValue, (startTime) =>
    fromForm.isEmploymentPeriodInThePast(startTime),
  );

  const selectIsRepeating = createSelector(
    selectShiftSchedulerControl,
    (state) => state.value.isRepeating,
  );

  const selectRepetitionDatesValue = createSelector(
    selectShiftSchedulerControl,
    (state) => state.value.repetitionDates,
  );

  const selectRemainingPositionsToFill = createSelector(
    selectShiftSchedulerControl,
    (state) => state.value.remainingPositionsToFill,
  );
  const selectTimeFragmentsControl = createSelector(
    selectShiftSchedulerControl,
    (state) => state.controls.timeFragments,
  );

  const selectIsRepeatingDateInPast = createSelector(
    selectRepetitionDatesValue,
    selectIsRepeating,
    selectStartTimeValue,
    (repetitionDates, isRepeating, startTime) =>
      fromForm.isRepeatingDateInPast(repetitionDates, isRepeating, startTime),
  );

  const selectJobListingFormStartTimeValue = createSelector(selectStartTimeValue, (startTimeStr) =>
    Time.getMoment(startTimeStr).toDate(),
  );

  const selectExtendedFormGradesArray = createSelector(
    selectExtendedJobListingFormWizardState,
    (form) => form.controls.gradesSection.controls.grades,
  );

  const selectStartEndRepetition = createSelector(
    selectStartTimeValue,
    selectEndTimeValue,
    selectRepetitionDatesValue,
    (startTime, endTime, repetitionDates) => {
      const formatRepetitionDates = unbox(repetitionDates).map((d) =>
        Time.getTimeFromDateAndSetToOtherDate(startTime, d).toISOString(),
      );
      return { startTime, endTime, repetitionDates: box(formatRepetitionDates) };
    },
  );

  const selectJobListingNotesControl = createSelector(
    selectShiftCreationFormControl,
    (state) => state.controls.jobListingNotes,
  );

  return {
    selectJobListingFormWardValue,
    selectJobListingFormRosterValue,
    selectJobListingFormPrimaryProfessionSpecialtyValue,
    selectCostCentreNumberValue,
    selectJobListingFormProfessionSpecialtyValues,
    selectJobListingFormProfessionValue,
    selectJobListingFormNonResidentOnCallValue,
    selectJobListingFormNotesValue,
    selectCopyNoteAcrossRepetitionDateFormValue,
    selectJobListingFormSiteValue,
    selectJobListingFormCostCentreNumberValue,
    selectRepetitionDatesValue,
    selectJobListingFormId,
    selectJobListingFormGradeValues,
    selectExtendedJobListingFormWizardState,
    selectJobListingFormVacancyReason,
    selectJobListingFromForm,
    selectIsSectionDetailsInvalid,
    selectIsSectionDescriptionInvalid,
    selectIsSectionGradesInvalid,
    selectIsFormInvalid,
    selectRemainingPositionsToFill,
    selectIsExternalJobListingMode,
    selectJobListingGradeControl,
    selectJobListingFormWizardState,
    selectLocalJobListingFromForm,
    selectEnabledJobListingGrades,
    selectIsEmploymentPeriodInPast,
    selectIsRepeatingDateInPast,
    selectJobListingFormStartTimeValue,
    selectShiftCreationControlIsInvalid,
    selectShiftSchedulerControlIsInvalid,
    selectGradesSectionControlIsInvalid,
    selectConsentBackdatedShiftsControl,
    selectShiftSchedulerControl,
    selectTimeFragmentsControl,
    selectExtendedFormGradesArray,
    selectRateViolationReasonControl,
    selectRateViolationReasonValue,
    selectStartEndRepetition,
    selectJobListingNotesControl,
    selectJobListingFormCrossCoveringProfessionSpecialtiesValue,
    selectJobListingFormCrossCoveringProfessions,
    selectIsCrossCoveringForm,
    selectJobListingGradesAreValid,
    selectProfessionGradesAreValid,
  };
}
