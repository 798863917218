import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get } from 'lodash-es';

import { ISelectOption } from '@locumsnest/components/src/lib/interfaces';

import { ICertificateTypeEntity } from '../../interfaces/api/certificate-type-entity';
import { selectAll, selectEntities } from './certificate-type.reducer';
import { ICertificateTypeFeatureState, ICertificateTypeSharedState } from './interfaces';

export const getCertificateTypeOptions = (
  certificateTypes: ICertificateTypeEntity[]
): ISelectOption[] =>
  certificateTypes
    .map((certificateType) => ({
      id: certificateType.id,
      name: certificateType.name,
      slug: certificateType.slug,
    }))
    .sort((a, b) => {
      if (a.slug === 'other') return 1;
      if (b.slug === 'other') return -1;
      return +get(a, 'disabled', false) - +get(b, 'disabled', false);
    });

export const getCertificateTypeBySlug =
  (slug: string) =>
  (types: ICertificateTypeEntity[]): ICertificateTypeEntity =>
    types.find((type) => type.slug === slug);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectCertificateTypeState = createFeatureSelector<
  ICertificateTypeFeatureState & ICertificateTypeSharedState
>('certificateTypes');

export const selectCertificateTypeEntityState = createSelector(
  selectCertificateTypeState,
  (state) => state.entityState
);

export const selectCertificateTypeEntities = createSelector(
  selectCertificateTypeEntityState,
  selectEntities
);

export const selectAllCertificateTypes = createSelector(
  selectCertificateTypeEntityState,
  selectAll
);

export const selectCertificateTypeOptions = createSelector(
  selectAllCertificateTypes,
  getCertificateTypeOptions
);

export const selectCertificateTypeById = (id: number) =>
  createSelector(selectCertificateTypeEntities, (entities) => entities[id]);

export const selectCertificateTypeBySlug = (slug: string) =>
  createSelector(selectAllCertificateTypes, getCertificateTypeBySlug(slug));
