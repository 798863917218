import { createFeatureSelector, createSelector } from '@ngrx/store';
import { groupBy } from 'lodash-es';

import { IApprovedRateEntity } from '../../interfaces/api/approved-rate-entity';
import { loadingAdapter, paginationAdapter } from './approved-rate.adapter';
import { featureKey, selectAll } from './approved-rate.reducer';
import {
  IApprovedRateEntityState,
  IApprovedRateFeatureState,
  IApprovedRateSharedState,
} from './interfaces';

export const getEntityState = (state: { entityState: IApprovedRateEntityState }) =>
  state.entityState;

export const getByGradeAndSpecialty =
  (gradeId: number, specialtyId: number, nonResidentOnCall: boolean) =>
  (rates: IApprovedRateEntity[]) =>
    rates.filter(
      (rate) =>
        rate.grade === gradeId &&
        rate.specialties.includes(specialtyId) &&
        rate.onCall === !!nonResidentOnCall,
    );

export const getByGradeForSpecialty =
  (specialtyId: number, nonResidentOnCall) => (rates: IApprovedRateEntity[]) =>
    groupBy(
      rates.filter(
        (rate) => rate.specialties.includes(specialtyId) && rate.onCall === !!nonResidentOnCall,
      ),
      (rate) => rate.grade,
    );

export const selectApprovedRateState = createFeatureSelector<
  IApprovedRateFeatureState & IApprovedRateSharedState
>(featureKey);

export const selectApprovedRateEntityState = createSelector(
  selectApprovedRateState,
  getEntityState,
);

export const selectAllApprovedRates = createSelector(selectApprovedRateEntityState, selectAll);

export const selectByGradeAndSpecialty = (gradeId, specialtyId, nonResidentOnCall: boolean) =>
  createSelector(
    selectAllApprovedRates,
    getByGradeAndSpecialty(gradeId, specialtyId, nonResidentOnCall),
  );

export const selectByGradeForSpecialty = (specialtyId: number, nonResidentOnCall: boolean) =>
  createSelector(selectAllApprovedRates, getByGradeForSpecialty(specialtyId, nonResidentOnCall));

export const approvedRatePaginationSelectors = paginationAdapter.paginationSelectors(
  selectApprovedRateEntityState,
  loadingAdapter,
  'pagination',
  'entityState',
  false,
);

export const loadingStateSelectors = loadingAdapter.getSelectors(selectApprovedRateState);
