import { createFeatureSelector, createSelector } from '@ngrx/store';

import { paginationAdapter } from './external-staffing-provider-tier.adapter';
import { featureKey, selectAll } from './external-staffing-provider-tier.reducer';
import {
  IExternalStaffingProviderTierFeatureState,
  IExternalStaffingProviderTierSharedState,
} from './interfaces';

export const selectExternalStaffingProviderTierState = createFeatureSelector<
  IExternalStaffingProviderTierFeatureState & IExternalStaffingProviderTierSharedState
>(featureKey);

export const selectExternalStaffingProviderTierEntityState = createSelector(
  selectExternalStaffingProviderTierState,
  (state) => state.entityState
);

export const selectAllExternalStaffingProviderTiers = createSelector(
  selectExternalStaffingProviderTierEntityState,
  selectAll
);

export const externalStaffingProviderTierPaginationSelectors =
  paginationAdapter.paginationSelectors(
    selectExternalStaffingProviderTierState,
    null,
    'pagination',
    'entityState',
    false
  );
