import { ActionReducerMap } from '@ngrx/store';

import { ITagFeatureState } from './interfaces';
import { reducer as formReducer } from './tag-form/tag-form.reducer';
import { entityReducer, paginationReducer } from './tag.reducer';

export const reducers: ActionReducerMap<ITagFeatureState> = {
  entityState: entityReducer,
  pagination: paginationReducer,
  formState: formReducer,
};
