@if (myTagsDeleteMode || organisationTagsDeleteMode) {
  <div class="tags-overlay-left" [ngClass]="{ 'display-as-row': displayAsRow }"></div>
  @if (displayAsRow) {
    <div class="tags-overlay-buttons"></div>
  }
}

<div class="title" [ngClass]="{ 'display-as-row': displayAsRow }">
  <div>Add tag{{ displayAsRow ? '(s)' : 's' }}</div>

  @if (displayAsRow) {
    <div>to selected shifts</div>
  }
</div>

<locumsnest-input-with-icon
  class="search-box"
  [maxLength]="100"
  [ngClass]="{ 'display-as-row': displayAsRow }"
  [placeholder]="'Type and press enter'"
  [iconSize]="!!searchKeywordControl.value ? 10 : 15"
  [inputControl]="searchKeywordControl"
  [iconName]="!!searchKeywordControl.value ? 'x-new' : 'search'"
  [nonClickable]="!searchKeywordControl.value"
  (buttonClicked)="onClearSearch()"
/>

<div class="your-tags-search" [ngClass]="{ 'display-as-row': displayAsRow }">
  @if (searchKeywordControl.value.trim().length > 2) {
    <div class="your-tags-search__title">Your tags</div>
    <div class="your-tags-search__results">
      @for (result of myTagsSearchResult; track result.id) {
        <div
          class="your-tags-search__results__result"
          [ngClass]="{ added: result.isAdded }"
          (click)="onSelectedTag(result.id)"
        >
          {{ result.title }}
        </div>
      } @empty {
        <ng-template [ngTemplateOutlet]="noSearchResults" />
      }
    </div>
  }
</div>

<div class="organisation-tags-search" [ngClass]="{ 'display-as-row': displayAsRow }">
  @if (searchKeywordControl.value.trim().length > 2) {
    <div class="organisation-tags-search__title">Your organisation's tags</div>
    <div class="organisation-tags-search__results">
      @for (result of organisationTagsSearchResult; track $index) {
        <div
          class="organisation-tags-search__results__result"
          [ngClass]="{ added: result.isAdded }"
          (click)="onSelectedTag(result.id)"
        >
          {{ result.title }}
        </div>
      } @empty {
        <ng-template [ngTemplateOutlet]="noSearchResults" />
      }
    </div>
  }
</div>

<div class="create-new-tag" [ngClass]="{ 'display-as-row': displayAsRow }">
  @if (showCreateNewTag) {
    <div class="create-new-tag__action" [class.disabled]="hasMaxTagsError" (click)="onCreateTag()">
      <locumsnest-icon iconName="plus-circle" width="15" height="15" />
      <span>Create new tag "{{ searchKeywordControl.value.trim() | textTruncate: 30 }}"</span>
    </div>
  }
</div>

<div class="max-tags-error" [ngClass]="{ 'display-as-row': displayAsRow }">
  @if (hasMaxTagsError) {
    <div>
      <div class="max-tags-error__warning-icon"></div>
    </div>
    <span>
      You have reached the maximum number of tags in your library. Please delete one or more tags
      from your Tag Library and try again.
    </span>
  }
</div>

@if (!organisationTagsDeleteMode) {
  <div
    class="tags"
    [ngClass]="{
      'inverse-area': !myTags.length || organisationTagsDeleteMode,
      'display-as-row': displayAsRow,
      'empty-state': !displayAsRow && !myTags.length
    }"
  >
    @if (!!myTags.length) {
      <div class="tags__title">
        <div>
          <span>Your tag library ({{ myTags.length }})</span>
          <span class="tags__title__edit-button" (click)="onToggleMyTagsDeleteMode()"
            >{{ myTagsDeleteMode ? 'Close' : 'Edit' }}
          </span>
        </div>
        @if (selectedTagsControl.value.value.length && myTagsDeleteMode && displayAsRow) {
          <ng-template [ngTemplateOutlet]="deleteSelectedAndConfirmation" />
        }
      </div>

      <ln-tag-group
        class="tags__tags-group"
        [ngClass]="{ 'display-as-row': displayAsRow }"
        [ngrxFormControlState]="selectedTagsControl"
      >
        @for (tag of myTags; track tag.id) {
          <ln-tag
            [isNew]="tag.isNew"
            [deleteMode]="myTagsDeleteMode"
            [value]="tag.id"
            [hasWarning]="myTagsDeleteMode && tag.hasWarning"
          >
            <div class="tag-ellipsis" [tp]="tag.title" [tpOnlyTextOverflow]="true">
              {{ tag.title }}
            </div>
          </ln-tag>
        }
      </ln-tag-group>
    }
  </div>
}

<div
  class="organisation-tags"
  [ngClass]="{
    'inverse-area': !myTags.length || organisationTagsDeleteMode,
    'empty-state': !displayAsRow && !organisationTags.length
  }"
>
  @if (!!organisationTags.length && !myTagsDeleteMode) {
    <div class="organisation-tags__title">
      <div>
        <span> Your organisation's tag library ({{ organisationTagsCount }})</span>
        @if (canDeleteOrganisationTags) {
          <span
            class="organisation-tags__title__edit-button"
            (click)="onToggleOrganisationTagsDeleteMode()"
            >{{ organisationTagsDeleteMode ? 'Close' : 'Edit' }}
          </span>
        }
      </div>
      @if (selectedTagsControl.value.value.length && organisationTagsDeleteMode && displayAsRow) {
        <ng-template [ngTemplateOutlet]="deleteSelectedAndConfirmation" />
      }
    </div>
    <ln-tag-group
      class="organisation-tags__tags-group"
      infiniteScroll
      [ngClass]="{ 'display-as-row': displayAsRow }"
      [scrollWindow]="false"
      [ngrxFormControlState]="selectedTagsControl"
      (scrolled)="onOrganisationScroll()"
    >
      @for (tag of organisationTags; track tag.id) {
        <ln-tag
          [value]="tag.id"
          [deleteMode]="organisationTagsDeleteMode"
          [hasWarning]="organisationTagsDeleteMode && tag.hasWarning"
          [isNew]="tag.isNew"
        >
          <div class="tag-ellipsis" [tp]="tag.title" [tpOnlyTextOverflow]="true">
            {{ tag.title }}
          </div>
        </ln-tag>
      }
    </ln-tag-group>
  }
</div>

@if (displayAsRow) {
  <div class="buttons">
    <locumsnest-button
      buttonType="transparent"
      size="medium"
      [hasBoldFont]="true"
      (click)="onExit()"
    >
      EXIT
    </locumsnest-button>
    <locumsnest-button
      buttonType="go"
      size="medium"
      [disabled]="disableApplyTagsButton"
      [hasBoldFont]="true"
      (click)="onSave()"
    >
      APPLY TAGS
    </locumsnest-button>
  </div>
}

@if (!displayAsRow) {
  <div class="delete-message-empty">
    @if (
      (myTagsDeleteMode || organisationTagsDeleteMode) && selectedTagsControl.value.value.length
    ) {
      <div class="delete-message" @slideDownUp>
        <ng-template [ngTemplateOutlet]="deleteSelectedAndConfirmation" />
      </div>
    }
  </div>
}

@if (hasDeleteModeWarning) {
  <div class="warning" [ngClass]="{ 'display-as-row': displayAsRow }">
    <locumsnest-icon iconName="warning-info" width="15" height="15" />
    <span class="warning__text">
      One or more tags are assigned to shifts. Deleting a tag also removes it from the shifts it is
      assigned to.
    </span>
  </div>
}

@if (displayAsRow) {
  <div class="eligibility-warning">
    @if (eligibilityWarning) {
      <div>
        <div class="eligibility-warning__icon"></div>
      </div>
      <span class="warning__text">
        One or more tags have already been applied to one or more shifts
      </span>
    }
  </div>
}

<ng-template #deleteSelectedAndConfirmation>
  <div class="delete-button">
    <div class="delete-button__icon" (click)="toggleShowConfirmation()"></div>
    @if (!showConfirmation()) {
      <span class="delete-button__text" (click)="toggleShowConfirmation()">
        Permanently delete ({{ selectedTagsControl.value.value.length }}) tag(s)
      </span>
    } @else {
      <span
        >Are you sure?
        <span class="delete-button__text" (click)="onDeleteTags()">Yes</span>
        or
        <span class="delete-button__text" (click)="toggleShowConfirmation()"> No </span>
      </span>
    }
  </div>
</ng-template>

<ng-template #noSearchResults>
  <span class="no-search-results">No results found</span>
</ng-template>
