import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IProfileNoteEntity } from '../../interfaces/api/profile-note-entity';
import {
  MessageableFactory,
  SignalableFactory,
  createPaginatedStateAdapter,
} from '@locumsnest/core/src/';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';

export const adapter: EntityAdapter<IProfileNoteEntity> = createEntityAdapter<IProfileNoteEntity>({
  selectId: (pensionCategory: IProfileNoteEntity) => pensionCategory.id,
});

export const messageableFactory = MessageableFactory.forFeature<'ProfileNote'>('ProfileNote');
export const signalableFactory = SignalableFactory.forFeature<'ProfileNote'>('ProfileNote');
export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const loadingAdapter = createLoadingStateAdapter<'ProfileNote'>(messageableFactory);

export const initialState = adapter.getInitialState({
  selectedProfileNoteId: null,
});
