import { AbstractControlState, FormGroupState, updateGroup, validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

import { IRejectRequestFormState } from '../interfaces';

const validateRejectDetails =
  (
    state: AbstractControlState<IRejectRequestFormState[0]['rejectDetails']>,
    val: AbstractControlState<IRejectRequestFormState[0]>,
  ) =>
  () => {
    if (val.value && !val.value.isOtherReasonSelected) return null;
    if (state.value) return null;
    return {
      required: {
        actual: true,
      },
    };
  };

export const rejectionValidation = (
  formState: FormGroupState<IRejectRequestFormState>,
): FormGroupState<IRejectRequestFormState> => {
  if (!Object.entries(formState.controls).length) return formState;

  for (const [key, value] of Object.entries(formState.controls)) {
    formState = updateGroup<IRejectRequestFormState>({
      [key]: updateGroup<any>({
        rejectReason: validate<number>([required]),
        rejectDetails: (state) => validate(validateRejectDetails(state, value))(state),
      }),
    })(formState);
  }
  return formState;
};
