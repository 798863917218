import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { ICertificateEntity } from '../../interfaces/api/certificate-entity';

import { paginationAdapter } from './certificate.adapter';

export const { UpsertPageMessage, ResetPaginationMessage } = paginationAdapter.getMessages();

export class UpsertCertificatePageMessage extends UpsertPageMessage {}
export class ResetCertificatePaginationMessage extends ResetPaginationMessage {}

export enum CertificateSignalTypes {
  LOAD_CERTIFICATES = '[Certificate][Signal] Load Collection',
}
export enum CertificateMessageTypes {
  SET_COLLECTION = '[Certificate][Message] Set Collection',
  ADD_ONE = '[Certificate][Message] Add One',
  UPDATE_ONE = '[Certificate][Message] Update One',
  UPSERT_ONE = '[Certificate][Message] Upsert One',
  DELETE_ONE = '[Certificate][Message] Delete One',
  ADD_MULTIPLE = '[Certificate][Message] Add All',
  DELETE_MULTIPLE = '[Certificate][Message] Delete Many',
  UPSERT_MULTIPLE = '[Certificate][Message] Upsert Many',
  UPDATE_MULTIPLE = '[Certificate][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = CertificateMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: ICertificateEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = CertificateMessageTypes.ADD_ONE;

  constructor(public payload: { entity: ICertificateEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = CertificateMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: ICertificateEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = CertificateMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: ICertificateEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = CertificateMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: ICertificateEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = CertificateMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<ICertificateEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = CertificateMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<ICertificateEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = CertificateMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = CertificateMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type CertificateMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | UpsertCertificatePageMessage
  | ResetCertificatePaginationMessage;
