import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get } from 'lodash-es';

import { IStaffingCascadeEntity } from '../../interfaces/api/staffing-cascade-entity';
import {
  IStaffingCascadeEntityState,
  IStaffingCascadeFeatureState,
  IStaffingCascadeSharedState,
} from './interfaces';
import { selectAll, selectEntities } from './staffing-cascade.reducer';
import { getWidgetUiState, selectAlertState } from './widget-ui/widget-ui.selectors';

export const getEntityState = (state: { entityState: IStaffingCascadeEntityState }) =>
  state.entityState;

export const getByListingId = (listingId) => (cascades: IStaffingCascadeEntity[]) =>
  cascades.find((cascade) => cascade.listing === listingId);

export const getByJobListingIds =
  (jobListingIds: number[]) => (staffingCascades: IStaffingCascadeEntity[]) =>
    staffingCascades ? staffingCascades.filter((x) => jobListingIds.indexOf(x.listing) >= 0) : [];

export const getId = (cascade: IStaffingCascadeEntity) => get(cascade, 'id');

export const getStaffingCascadesOptions = (cascades: IStaffingCascadeEntity[]) =>
  cascades.map((grade) => ({ id: grade.id, name: grade.notes }));

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectStaffingCascadeState = createFeatureSelector<
  IStaffingCascadeFeatureState & IStaffingCascadeSharedState
>('staffingCascades');

export const selectStaffingCascadeEntityState = createSelector(
  selectStaffingCascadeState,
  getEntityState
);
export const selectWidgetUiState = createSelector(selectStaffingCascadeState, getWidgetUiState);
export const selectWidgetUiAlertState = createSelector(selectWidgetUiState, selectAlertState);
export const selectWidgetSubmitConfirmation = createSelector(
  selectWidgetUiState,
  (uiState) => uiState.submitConfirmation
);
export const selectWidgetReverseConfirmation = createSelector(
  selectWidgetUiState,
  (uiState) => uiState.reverseConfirmation
);
export const selectAllStaffingCascades = createSelector(
  selectStaffingCascadeEntityState,
  selectAll
);
export const selectAllStaffingCascadesDict = createSelector(
  selectStaffingCascadeEntityState,
  selectEntities
);
export const selectStaffingCascade = (id) =>
  createSelector(selectAllStaffingCascadesDict, (cascades) => cascades[id]);
export const selectStaffingCascadesOptions = createSelector(
  selectAllStaffingCascades,
  getStaffingCascadesOptions
);
export const selectStaffingCascadeByListingId = (listingId) =>
  createSelector(selectAllStaffingCascades, getByListingId(listingId));

export const selectStaffingCascadeIdByListingId = (listingId) =>
  createSelector(selectStaffingCascadeByListingId(listingId), getId);

export const selectStaffingCascadeByJobListingIds = (jobListingIds: number[]) =>
  createSelector(selectAllStaffingCascades, getByJobListingIds(jobListingIds));

export const selectFormState = createSelector(
  selectStaffingCascadeState,
  (state) => state.formState
);

export const selectExternalStaffingCandidateBid = (id: number) =>
  createSelector(selectStaffingCascadeEntityState, selectEntities, ({ entities }) => entities[id]);

export const selectNotes = createSelector(selectFormState, (state) => state.value.notes);
