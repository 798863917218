import { inject, Injectable } from '@angular/core';
import { IApplicationEntity } from 'apps/hospital-admin/src/app/interfaces/api/application-entity';
import { IHospitalProfessionConfigurationEntity } from 'apps/hospital-admin/src/app/interfaces/api/hospital-profession-configuration-entity';
import { IProfileAssignmentNumbersEntity } from 'apps/hospital-admin/src/app/interfaces/api/profile-assignment-numbers-entity';
import { isNil } from 'lodash-es';
import { map, Observable } from 'rxjs';

import { ISelectOption } from '@locumsnest/components/src';
import { IQueryParams, PaginatedStateService } from '@locumsnest/core/src';

import {
  paginationMessages,
  ResetProfileAssignmentNumbersPaginationMessage,
  UpsertMultipleMessage,
  UpsertProfileAssignmentNumbersPageMessage,
} from './profile-assignment-numbers.messages';
import { ProfileAssignmentNumbersPersistenceService } from './profile-assignment-numbers.persistence.service';
import {
  profileAssignmentNumbersPaginationSelectors,
  selectProfileAssignmentNumbersEntityState,
} from './profile-assignment-numbers.selectors';

@Injectable({
  providedIn: 'root',
})
export class ProfileAssignmentNumbersService extends PaginatedStateService<
  IProfileAssignmentNumbersEntity,
  UpsertProfileAssignmentNumbersPageMessage,
  ResetProfileAssignmentNumbersPaginationMessage,
  UpsertMultipleMessage
> {
  protected persistenceService = inject(ProfileAssignmentNumbersPersistenceService);

  get paginationMessages() {
    return paginationMessages;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return profileAssignmentNumbersPaginationSelectors;
  }

  get entityStateSelector() {
    return selectProfileAssignmentNumbersEntityState;
  }

  fetch() {
    return this.loadAllPages('default', {});
  }

  loadByParams(params: IQueryParams, active = true) {
    if (!isNil(active)) {
      params = { ...params, active };
    }

    return this.loadAllPages('default', {}, params);
  }

  loadByProfileIds(profileIds: string[], active = true) {
    let params: IQueryParams = {
      profile: profileIds,
    };

    if (!isNil(active)) {
      params = { ...params, active };
    }

    return this.loadAllPages('default', {}, params);
  }

  public getHasAssignmentNumberWarningForTimesheet(
    hospitalProfessionConfiguration: IHospitalProfessionConfigurationEntity[],
    assignmentNumbers: IProfileAssignmentNumbersEntity[],
    profile: string,
    gradeId: number,
    professionId: number,
  ): boolean {
    let assignmentNumberWarning = false;

    const hasProfessionConfiguration = hospitalProfessionConfiguration.find(
      (hpc) => hpc.professionId === professionId,
    );

    if (hasProfessionConfiguration?.warningForGradeWithoutAssignmentNumber) {
      const hasAssignmentNumber = assignmentNumbers.find(
        (assignmentNumber) =>
          assignmentNumber.profile === profile &&
          (assignmentNumber.grade === null || assignmentNumber.grade === gradeId),
      );
      assignmentNumberWarning = !hasAssignmentNumber;
    }
    return assignmentNumberWarning;
  }

  public getHasAssignmentNumberWarningPerApplication(
    hospitalProfessionConfiguration: IHospitalProfessionConfigurationEntity[],
    professionId: number,
    assignmentNumbers: IProfileAssignmentNumbersEntity[],
    application: Pick<IApplicationEntity, 'grade' | 'profile'>,
  ) {
    let assignmentNumberWarning = false;

    const hasProfessionConfiguration = hospitalProfessionConfiguration.find(
      (hpc) => hpc.professionId === professionId,
    );

    if (hasProfessionConfiguration?.warningForGradeWithoutAssignmentNumber) {
      const hasAssignmentNumber = assignmentNumbers.find(
        (assignmentNumber) =>
          assignmentNumber.profile === application.profile &&
          (assignmentNumber.grade === null || assignmentNumber.grade === application.grade),
      );
      assignmentNumberWarning = !hasAssignmentNumber;
    }
    return assignmentNumberWarning;
  }

  public getHasAssignmentNumberWarningPerJobListing(
    hospitalProfessionConfiguration: IHospitalProfessionConfigurationEntity[],
    professionId: number,
    assignmentNumbers: IProfileAssignmentNumbersEntity[],
    grades: number[],
    profile: string,
  ) {
    let assignmentNumberWarning = false;

    const hasProfessionConfiguration = hospitalProfessionConfiguration.find(
      (hpc) => hpc.professionId === professionId,
    );

    if (hasProfessionConfiguration?.warningForGradeWithoutAssignmentNumber) {
      const hasAssignmentNumber = assignmentNumbers.find(
        (assignmentNumber) =>
          assignmentNumber.profile === profile &&
          (assignmentNumber.grade === null || grades.includes(assignmentNumber.grade)),
      );
      assignmentNumberWarning = !hasAssignmentNumber;
    }
    return assignmentNumberWarning;
  }

  public getLoadAssignmentNumbersAction(entities: IApplicationEntity<number, string, number>[]) {
    const profileIds = [...new Set(entities.map((x) => x.profile))];
    if (profileIds.length) {
      return this.loadByProfileIds(profileIds);
    }
    return null;
  }

  public getActiveAssignmentNumberOptionsByProfileId(
    profileId: string,
    grade: number,
  ): Observable<ISelectOption<string>[]> {
    return this.getAll().pipe(
      map((assignmentNumbers) => {
        const selectedAssignmentNumbers = assignmentNumbers.filter(
          (assignmentNumber) =>
            assignmentNumber.profile === profileId &&
            assignmentNumber.active === true &&
            (assignmentNumber.grade === grade || assignmentNumber.grade === null),
        );

        const options = selectedAssignmentNumbers.map((selectedAssignmentNumber) => {
          const assignmentCategory = selectedAssignmentNumber.assignmentCategory
            ? ' (' + selectedAssignmentNumber.assignmentCategory + ')'
            : '';

          const option: ISelectOption<string> = {
            id: selectedAssignmentNumber.assignmentNumber,
            name: selectedAssignmentNumber.assignmentNumber + assignmentCategory,
            code: selectedAssignmentNumber.assignmentCategory,
          };
          return option;
        });

        return options;
      }),
    );
  }
}
