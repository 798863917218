import { IStaffingCascadeEntityState } from './interfaces';
import { adapter, initialState } from './staffing-cascade.adapter';
import {
  StaffingCascadeMessages,
  StaffingCascadeMessageTypes as MessageTypes,
} from './staffing-cascade.messages';

export * from './interfaces';

export function reducer(
  state = initialState,
  action: StaffingCascadeMessages
): IStaffingCascadeEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.staffingCascade, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.staffingCascade, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.staffingCascades, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.staffingCascades, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.staffingCascades, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.staffingCascade, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.staffingCascades, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }
    default: {
      return state;
    }
  }
}
export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
