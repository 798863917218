import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ITagFeatureState } from './interfaces';
import { paginationAdapter } from './tag.adapter';
import { featureKey } from './tag.reducer';

export const selectTagState = createFeatureSelector<ITagFeatureState>(featureKey);
export const selectTagEntityState = createSelector(selectTagState, (state) => state.entityState);
export const tagPaginationSelectors = paginationAdapter.paginationSelectors(
  selectTagState,
  null,
  'pagination',
  'entityState',
  false,
);
