import { IArrowDirectionsEnum } from '@locumsnest/core/src';

import { signalableFactory as actionableFactory } from './staff-bank-membership-requests-search-filter-form.adapter';

export class InitializeSearchFilterFormSignal extends actionableFactory.create<
  'Initialize Search Filter Form',
  { namespace: string }
>('Initialize Search Filter Form') {}

export class UpdateSelectedPageUsingArrowsSignal extends actionableFactory.create<
  'Update Selected Page Using Arrows Signal',
  { direction: IArrowDirectionsEnum; namespace: string }
>('Update Selected Page Using Arrows Signal') {}

export class AddSelectedStaffBankMembershipRequestSignal extends actionableFactory.create<
  'Add Selected StaffBank Membership Request Signal',
  { id: number }
>('Add Selected StaffBank Membership Request Signal') {}

export class RemoveSelectedStaffBankMembershipRequestSignal extends actionableFactory.create<
  'Remove Selected StaffBank Membership Request Signal',
  { id: number }
>('Remove Selected StaffBank Membership Request Signal') {}

export class SelectAllInCurrentPageSignal extends actionableFactory.create<
  'Select All In Current Page Signal',
  { namespace: string }
>('Select All In Current Page Signal') {}

export class UnselectAllInCurrentPageSignal extends actionableFactory.create<
  'Unselect All In Current Page Signal',
  { namespace: string }
>('Unselect All In Current Page Signal') {}

export class SubmitSearchFilterFormSignal extends actionableFactory.create<
  'Submit Search Filter Form Signal',
  { namespace: string }
>('Submit Search Filter Form Signal') {}

export class ExportStaffBankMembershipRequestsSignal extends actionableFactory.create<
  'Export StaffBankMembershipRequest Requests Signal',
  Record<string, never>
>('Export StaffBankMembershipRequest Requests Signal') {}

export class UpdateSelectedPageUsingInputSignal extends actionableFactory.create<
  'Update Selected Page Using Input Signal',
  { newSelectedPage: number; namespace: string }
>('Update Selected Page Using Input Signal') {}

export class UpdateStaffBankMembershipRequestSignal extends actionableFactory.create<
  'Update Staff Bank Membership Request Signal',
  { id: number; membershipStatus: number }
>('Update Staff Bank Membership Request Signal') {}

export class RejectStaffBankMembershipRequestSignal extends actionableFactory.create<
  'Reject Staff Bank Membership Request Signal',
  { id: number }
>('Reject Staff Bank Membership Request Signal') {}

export class AddRemoveExpandedStaffBankRequestSignal extends actionableFactory.create<
  'Add Remove Expanded StaffBank Request Signal',
  { id: number }
>('Add Remove Expanded StaffBank Request Signal') {}

export class ToggleRejectMembershipSignal extends actionableFactory.create<
  'Toggle Reject Membership Signal',
  { id: number }
>('Toggle Reject Membership Signal') {}
