import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IProfileNoteEntity } from '../../interfaces/api/profile-note-entity';
import {
  IProfileNoteEntityState,
  IProfileNoteFeatureState,
  IProfileNoteSharedState,
} from './interfaces';
import { loadingAdapter, paginationAdapter } from './profile-note.adapter';
import { selectAll } from './profile-note.reducer';

export const getEntityState = (state: { entityState: IProfileNoteEntityState }) =>
  state.entityState;

export const getProfileNote = (profileNoteId: number) => (profileNotes: IProfileNoteEntity[]) =>
  profileNotes.find((profileNote) => profileNote.id === profileNoteId);

export const getProfileNotesByProfileId =
  (profileId: string) => (profileNotes: IProfileNoteEntity[]) =>
    profileNotes
      .filter((profileNote) => profileNote.profile === profileId)
      .sort((a, b) => (a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0));

export const selectProfileNoteState =
  createFeatureSelector<IProfileNoteFeatureState & IProfileNoteSharedState>('profileNotes');

export const selectProfileNoteEntityState = createSelector(selectProfileNoteState, getEntityState);

export const selectAllProfileNotes = createSelector(selectProfileNoteEntityState, selectAll);

export const selectProfileNote = (profileNoteId) =>
  createSelector(selectAllProfileNotes, getProfileNote(profileNoteId));

export const selectProfileNotesByProfileId = (profileId: string) =>
  createSelector(selectAllProfileNotes, getProfileNotesByProfileId(profileId));

export const profileNotePaginationSelectors = paginationAdapter.paginationSelectors(
  selectProfileNoteEntityState,
  loadingAdapter
);
