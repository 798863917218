import { IUpdateMetaData } from './../../../../../../libs/core/src/lib/ngrx/cached-state';
import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { IExternalStaffingCandidateBidEntity } from '../../interfaces/api/external-staffing-candidate-bid-entity';
import { paginationAdapter } from './external-staffing-candidate-bid.adapter';

export const externalStaffingCandidateBidPaginationMessages = paginationAdapter.getMessages();

export class UpsertExternalStaffingCandidateBidPageMessage extends externalStaffingCandidateBidPaginationMessages.UpsertPageMessage {}
export class UpsertExternalStaffingCandidateBidMultiplePagesMessage extends externalStaffingCandidateBidPaginationMessages.UpsertMultiplePagesMessage {}
export class ResetExternalStaffingCandidateBidPaginationMessage extends externalStaffingCandidateBidPaginationMessages.ResetPaginationMessage {}

export enum ExternalStaffingCandidateBidSignalTypes {
  LOAD_EXTERNAL_STAFFING_CANDIDATE_BIDS = '[ExternalStaffingCandidateBid][Signal] Load Collection',
}
export enum ExternalStaffingCandidateBidMessageTypes {
  SET_COLLECTION = '[ExternalStaffingCandidateBid][Message] Set Collection',
  ADD_ONE = '[ExternalStaffingCandidateBid][Message] Add One',
  UPDATE_ONE = '[ExternalStaffingCandidateBid][Message] Update One',
  UPSERT_ONE = '[ExternalStaffingCandidateBid][Message] Upsert One',
  DELETE_ONE = '[ExternalStaffingCandidateBid][Message] Delete One',
  ADD_MULTIPLE = '[ExternalStaffingCandidateBid][Message] Add All',
  DELETE_MULTIPLE = '[ExternalStaffingCandidateBid][Message] Delete Many',
  UPSERT_MULTIPLE = '[ExternalStaffingCandidateBid][Message] Upsert Many',
  UPDATE_MULTIPLE = '[ExternalStaffingCandidateBid][Message] Update Many',
  SET_SELECTED = '[ExternalStaffingCandidateBid][Message] Set Selected',
}
export class SetCollectionMessage implements Action {
  readonly type = ExternalStaffingCandidateBidMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IExternalStaffingCandidateBidEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = ExternalStaffingCandidateBidMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IExternalStaffingCandidateBidEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = ExternalStaffingCandidateBidMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IExternalStaffingCandidateBidEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = ExternalStaffingCandidateBidMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IExternalStaffingCandidateBidEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = ExternalStaffingCandidateBidMessageTypes.UPSERT_MULTIPLE;

  constructor(
    public payload: { entities: IExternalStaffingCandidateBidEntity[]; metaData?: IUpdateMetaData }
  ) {}
}

export class UpdateOneMessage implements Action {
  readonly type = ExternalStaffingCandidateBidMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IExternalStaffingCandidateBidEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = ExternalStaffingCandidateBidMessageTypes.UPDATE_MULTIPLE;

  constructor(
    public payload: {
      entities: Update<IExternalStaffingCandidateBidEntity>[];
    }
  ) {}
}

export class DeleteOneMessage implements Action {
  readonly type = ExternalStaffingCandidateBidMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = ExternalStaffingCandidateBidMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export class SetSelectedMessage implements Action {
  readonly type = ExternalStaffingCandidateBidMessageTypes.SET_SELECTED;

  constructor(public payload: { id: number }) {}
}
export type ExternalStaffingCandidateBidMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | SetSelectedMessage;
