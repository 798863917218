import { IPaginatedEntityState } from '@locumsnest/core/src/lib/interfaces/pagination';
import { IProfileEntity } from '../../interfaces/api/profile-entity';
import { IProfileEntityState } from './interfaces';
import { adapter, initialState, loadingAdapter, paginationAdapter } from './profile.adapter';
import { ProfileMessages, ProfileMessageTypes as MessageTypes } from './profile.messages';

export * from './interfaces';

const _paginationReducer = paginationAdapter.createReducer();
export type IProfilePaginatedEntityState = IProfileEntityState &
  IPaginatedEntityState<IProfileEntity>;

export function paginationReducer(state, action) {
  return _paginationReducer(state, action);
}
const _loadingStateReducer = loadingAdapter.createReducer();

export function loadingStateReducer(state, action) {
  return _loadingStateReducer(state, action);
}

export function reducer(state = initialState, action: ProfileMessages): IProfileEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.entity, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.entity, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.entities, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.entities, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case MessageTypes.SELECT_PROFILE: {
      return { ...state, selectedProfileId: action.payload.id };
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  paginationAdapter.getEntitySelectors();
