import { IStatsState } from '../interfaces/stats-state';
import { InitializeStatsMessage, SetStatsMessage, StatsMessages } from './stats.messages';

export { IPassportDashboardUiState } from '../interfaces';
export * from './stats.selectors';
export type State = IStatsState;

export const INITIAL_STATS_STATE: IStatsState = {
  registeredOnApp: null,
  sixMonths: null,
  oneMonth: null,
  allTime: null,
};

export function reducer(state: State = INITIAL_STATS_STATE, action: StatsMessages) {
  switch (action.type) {
    case InitializeStatsMessage.TYPE:
      return { ...INITIAL_STATS_STATE, ...action.payload };
    case SetStatsMessage.TYPE:
      state = { ...(action as SetStatsMessage).payload.stats };
      break;
    default:
      break;
  }

  return state;
}
