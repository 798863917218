/* eslint-disable @typescript-eslint/naming-convention */
import { messageableFactory as actionableFactory, alertStateAdapter } from './widget-ui.adapter';

export const {
  ResetErrorMessage: ResetErrorAdapterMessage,
  AlertErrorMessage: AlertErrorAdapterMessage,
} = alertStateAdapter.getMessages();
export class ResetErrorMessage extends ResetErrorAdapterMessage {}
export class AlertErrorMessage extends AlertErrorAdapterMessage {}

export class InitializeConfirmationDialogMessage extends actionableFactory.create<
  'Initialize Confirmation Message',
  Record<string, never>
>('Initialize Confirmation Message') {}

export class ResetConfirmationDialogMessage extends actionableFactory.create<
  'Reset Confirmation Message',
  Record<string, never>
>('Reset Confirmation Message') {}

export class InitializeReverseConfirmationDialogMessage extends actionableFactory.create<
  'Initialize Reverse Confirmation Message',
  Record<string, never>
>('Initialize Reverse Confirmation Message') {}

export class ResetReverseConfirmationDialogMessage extends actionableFactory.create<
  'Reset Reverse Confirmation Message',
  Record<string, never>
>('Reset Reverse Confirmation Message') {}

export class InitializeWidgetUIMessage extends actionableFactory.create<
  'Initialize Widget UI Message',
  Record<string, never>
>('Initialize Widget UI Message') {}

export type StaffingCascadeWidgetUiMessages =
  | InitializeConfirmationDialogMessage
  | ResetConfirmationDialogMessage
  | InitializeReverseConfirmationDialogMessage
  | ResetReverseConfirmationDialogMessage
  | AlertErrorMessage
  | ResetErrorMessage
  | InitializeWidgetUIMessage;
