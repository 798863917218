import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';

import { IJobListingTags } from './interfaces';
import { paginationAdapter } from './job-listing-tags.adapter';

export const {
  ResetLoadingStateMessage,
  ResetPaginationMessage,
  SetLoadingStateMessage,
  UpsertMultiplePagesMessage,
  UpsertPageMessage,
} = paginationAdapter.getMessages();

export const paginationMessages = paginationAdapter.getMessages();
export class UpsertJobListingTagsPageMessage extends UpsertPageMessage {}
export class UpsertJobListingTagsMultiplePagesMessage extends UpsertMultiplePagesMessage {}
export class ResetJobListingTagsPaginationMessage extends ResetPaginationMessage {}

export enum JobListingTagsMessageTypes {
  SET_COLLECTION = '[JobListingTags][Message] Set Collection',
  ADD_ONE = '[JobListingTags][Message] Add One',
  UPDATE_ONE = '[JobListingTags][Message] Update One',
  UPSERT_ONE = '[JobListingTags][Message] Upsert One',
  DELETE_ONE = '[JobListingTags][Message] Delete One',
  ADD_MULTIPLE = '[JobListingTags][Message] Add All',
  DELETE_MULTIPLE = '[JobListingTags][Message] Delete Many',
  UPSERT_MULTIPLE = '[JobListingTags][Message] Upsert Many',
  UPDATE_MULTIPLE = '[JobListingTags][Message] Update Many',
}

export class SetCollectionMessage implements Action {
  readonly type = JobListingTagsMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IJobListingTags[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = JobListingTagsMessageTypes.ADD_ONE;
  constructor(public payload: { entity: IJobListingTags }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = JobListingTagsMessageTypes.UPSERT_ONE;
  constructor(public payload: { entity: IJobListingTags }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = JobListingTagsMessageTypes.ADD_MULTIPLE;
  constructor(public payload: { entities: IJobListingTags[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = JobListingTagsMessageTypes.UPSERT_MULTIPLE;
  constructor(public payload: { entities: IJobListingTags[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = JobListingTagsMessageTypes.UPDATE_ONE;
  constructor(public payload: { entity: Update<IJobListingTags> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = JobListingTagsMessageTypes.UPDATE_MULTIPLE;
  constructor(public payload: { entities: Update<IJobListingTags>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = JobListingTagsMessageTypes.DELETE_ONE;
  constructor(public payload: { id: number }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = JobListingTagsMessageTypes.DELETE_MULTIPLE;
  constructor(public payload: { ids: number[] }) {}
}

export type JobListingTagsMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
