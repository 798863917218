import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src/';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';
import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';

import { IProfileFlagEntity } from '../../interfaces/api/profile-flag-entity';

export const adapter: EntityAdapter<IProfileFlagEntity> = createEntityAdapter<IProfileFlagEntity>({
  selectId: (pensionCategory: IProfileFlagEntity) => pensionCategory.id,
});

export const messageableFactory = MessageableFactory.forFeature<'ProfileFlag'>('ProfileFlag');
export const signalableFactory = SignalableFactory.forFeature<'ProfileFlag'>('ProfileFlag');
export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const loadingAdapter = createLoadingStateAdapter<'ProfileFlag'>(messageableFactory);

export const initialState = adapter.getInitialState({
  selectedProfileFlagId: null,
});
