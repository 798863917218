import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  ITrustExternalStaffingProviderTierEntityState,
  ITrustExternalStaffingProviderTierFeatureState,
  ITrustExternalStaffingProviderTierSharedState,
} from './interfaces';
import { paginationAdapter } from './trust-external-staffing-provider-tier.adapter';
import { selectAll } from './trust-external-staffing-provider-tier.reducer';

export const getEntityState = (state: {
  entityState: ITrustExternalStaffingProviderTierEntityState;
}) => state.entityState;

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectTrustExternalStaffingProviderTierState = createFeatureSelector<
  ITrustExternalStaffingProviderTierFeatureState & ITrustExternalStaffingProviderTierSharedState
>('trustExternalStaffingProviderTiers');

export const selectTrustExternalStaffingProviderTierEntityState = createSelector(
  selectTrustExternalStaffingProviderTierState,
  getEntityState,
);

export const selectAllTrustExternalStaffingProviderTiers = createSelector(
  selectTrustExternalStaffingProviderTierEntityState,
  selectAll,
);
export const selectAllTrustExternalStaffingProviderTiersForHospital = (trust) =>
  createSelector(selectAllTrustExternalStaffingProviderTiers, (tiers) =>
    tiers.filter((tier) => tier.trust === trust),
  );

export const trustExternalStaffingProviderTierPaginationSelectors =
  paginationAdapter.paginationSelectors(
    selectTrustExternalStaffingProviderTierState,
    null,
    'pagination',
    'entityState',
    false,
  );

export const selectByProviderHospital = (providerId: number, trustId: number) =>
  createSelector(selectAllTrustExternalStaffingProviderTiers, (state) =>
    state.find((tier) => tier.provider === providerId && tier.trust === trustId),
  );
export const selectNegotiatedRatesByProviderHospital = (providerId: number, trustId: number) =>
  createSelector(
    selectByProviderHospital(providerId, trustId),
    (state) => state?.negotiatedRates || '',
  );
