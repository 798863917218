import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { ICertificateTypeEntity } from '../../interfaces/api/certificate-type-entity';

export function sortByName(a: ICertificateTypeEntity, b: ICertificateTypeEntity): number {
  return a.name.localeCompare(b.name);
}

export const adapter: EntityAdapter<ICertificateTypeEntity> =
  createEntityAdapter<ICertificateTypeEntity>({
    sortComparer: sortByName,
    selectId: (certificateType: ICertificateTypeEntity) => certificateType.id,
  });

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedCertificateTypeId: null,
});
