import { SignalableFactory } from '@locumsnest/core/src';
import { MessageableFactory } from '@locumsnest/core/src';
import { createAlertStateAdapter } from '@locumsnest/core/src/lib/adapters/alert-state-adapter';

export const signalableFactory = SignalableFactory.forFeature<'StaffBankUnavailabilitiesForm'>(
  'StaffBankUnavailabilitiesForm'
);
export const messageableFactory = MessageableFactory.forFeature<'StaffBankUnavailabilitiesForm'>(
  'StaffBankUnavailabilitiesForm'
);

export const alertStateAdapter = createAlertStateAdapter(signalableFactory, messageableFactory);
export const { errorHandler, conditionalErrorHandler, alertHandler } =
  alertStateAdapter.getAlertHandlers();
export const { ResetErrorMessage, AlertErrorMessage } = alertStateAdapter.getMessages();
