import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { isString } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PaginatedSubresourceStateService, RequestOptions } from '@locumsnest/core/src';

import {
  ICloneReferenceToHospitalEntity,
  ISafeboxReferenceEntity,
  ISafeboxReferencePostEntity,
} from '../../interfaces/api/safebox-reference-entity';
import { AppState } from '../../reducers';
import {
  ResetSafeboxReferencePaginationMessage,
  SetCollectionMessage,
  UpsertMultipleMessage,
  UpsertSafeboxReferencePageMessage,
} from './safebox-reference.messages';
import { SafeboxReferencePersistenceService } from './safebox-reference.persistence.service';
import {
  safeboxReferencePaginationSelectors,
  selectAllSafeboxReferences,
  selectSafeboxReferenceEntityState,
  selectSafeboxReferencesByProfileId,
} from './safebox-reference.selectors';

export interface SafeboxReferenceRequestOptions {
  profileId: string;
}

@Injectable({
  providedIn: 'root',
})
export class SafeboxReferenceService extends PaginatedSubresourceStateService<
  ISafeboxReferenceEntity,
  UpsertSafeboxReferencePageMessage,
  ResetSafeboxReferencePaginationMessage,
  UpsertMultipleMessage,
  RequestOptions<SafeboxReferenceRequestOptions>,
  ISafeboxReferencePostEntity
> {
  constructor(
    protected store: Store,
    protected persistenceService: SafeboxReferencePersistenceService
  ) {
    super();
  }

  get upsertMessageClass() {
    return UpsertSafeboxReferencePageMessage;
  }
  get clearMessageClass() {
    return ResetSafeboxReferencePaginationMessage;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return safeboxReferencePaginationSelectors;
  }

  get entityStateSelector() {
    return selectSafeboxReferenceEntityState;
  }

  getAll() {
    return this.store.pipe(select(selectAllSafeboxReferences));
  }

  loadByProfileId(id): Observable<Action> {
    return this.fetch(id) as Observable<SetCollectionMessage>;
  }

  getByProfileId(profileId: string) {
    return this.store.pipe(select(selectSafeboxReferencesByProfileId(profileId)));
  }

  fetch(profileId?: string) {
    if (isString(profileId))
      return this.persistenceService
        .retrieve(null, { pathParams: { profileId } })
        .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));

    return this.persistenceService
      .retrieve()
      .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));
  }

  getRequestOptions(profileId: string): RequestOptions<SafeboxReferenceRequestOptions> {
    return { pathParams: { profileId } };
  }

  copyFromReference(referenceId: number, profileId: string): Observable<ISafeboxReferenceEntity> {
    return this.persistenceService.copyFromReference(referenceId, profileId);
  }

  cloneReferenceToHospital(
    data: ICloneReferenceToHospitalEntity,
    profileId: string
  ): Observable<ISafeboxReferenceEntity> {
    return this.persistenceService.cloneReferenceToHospital(data, profileId);
  }
}
