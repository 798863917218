import { ActionReducerMap } from '@ngrx/store';

import { entityReducer, paginationReducer } from './hospital-profession-configuration.reducer';
import { IHospitalProfessionConfigurationFeatureState } from './interfaces';

export * from './hospital-profession-configuration.messages';

export const reducers: ActionReducerMap<IHospitalProfessionConfigurationFeatureState> = {
  entityState: entityReducer,
  pagination: paginationReducer,
};
