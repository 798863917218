import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ISelectOption } from '@locumsnest/components';

import { selectAll, selectEntities } from './decline-application-reason.reducer';
import {
  IDeclineApplicationReasonFeatureState,
  IDeclineApplicationReasonSharedState,
} from './interfaces';

export const selectDeclineApplicationReasonState = createFeatureSelector<
  IDeclineApplicationReasonFeatureState & IDeclineApplicationReasonSharedState
>('declineApplicationReasons');

export const selectDeclineApplicationReasonEntityState = createSelector(
  selectDeclineApplicationReasonState,
  (state) => state.entityState
);

const selectDeclineApplicationReasonEntities = createSelector(
  selectDeclineApplicationReasonEntityState,
  selectEntities
);

export const selectAllDeclineApplicationReasons = createSelector(
  selectDeclineApplicationReasonEntityState,
  selectAll
);

export const selectDeclineApplicationReason = (id: number) =>
  createSelector(selectDeclineApplicationReasonEntities, (entities) => entities[id]);

export const selectAllDeclineApplicationReasonsSetByOfficer = createSelector(
  selectAllDeclineApplicationReasons,
  (reasons) => reasons.filter((r) => r.setByOfficer)
);

export const selectDeclineApplicationReasonByCode = (code: string) =>
  createSelector(selectAllDeclineApplicationReasons, (reasons) =>
    reasons.find((status) => status.code === code)
  );

export const selectDeclineApplicationReasonOptions = createSelector(
  selectAllDeclineApplicationReasonsSetByOfficer,
  (declineApplicationReasons) =>
    declineApplicationReasons.map(
      (declineApplicationReason) =>
        ({
          id: declineApplicationReason.id,
          name: declineApplicationReason.display,
        } as ISelectOption)
    )
);
