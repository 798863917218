import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ApplicationStatusEnum } from '@locumsnest/dashboard-ui/src/lib/interfaces';

import { IApplicationEntity } from '../../interfaces/api/application-entity';
import { selectAllApplicationStatuses } from './../../application-status/+state/application-status.selectors';
import { IApplicationStatusEntity } from './../../interfaces/api/application-status-entity';
import { loadingAdapter, paginationAdapter } from './application.adapter';
import { selectAll } from './application.reducer';
import {
  IApplicationEntityState,
  IApplicationFeatureState,
  IApplicationSharedState,
} from './interfaces';

export const getEntityState = (state: { entityState: IApplicationEntityState }) =>
  state.entityState;

const getJobListingId = (state: IApplicationEntity) => state.listing;

export const getApplication = (applicationId: number) => (applications: IApplicationEntity[]) =>
  applications.find((application) => application.id === applicationId);

export const getPendingApplications = (applications: IApplicationEntity[]) =>
  applications.filter(
    (application) => application.bookingStatus === ApplicationStatusEnum.APPLICATION_RECEIVED
  );

export const getPendingApplicationsAlerts = (
  applications: IApplicationEntity[],
  applicationStatuses: IApplicationStatusEntity[]
) => {
  const applicationStatus = applicationStatuses.find((x) => x.code === 'CANCELED');

  return applications
    .filter(
      (application) =>
        application.bookingStatus === applicationStatus.val &&
        application.cancellationSeen === false
    )
    .sort((a, b) =>
      a.cancelledDate > b.cancelledDate ? -1 : a.cancelledDate < b.cancelledDate ? 1 : 0
    );
};

const getByJobListingIds = (jobListingIds: number[]) => (applications: IApplicationEntity[]) =>
  applications ? applications.filter((x) => jobListingIds.indexOf(x.listing) >= 0) : [];

export const selectApplicationState = createFeatureSelector<
  IApplicationFeatureState & IApplicationSharedState
>('applications');

export const selectApplicationEntityState = createSelector(selectApplicationState, getEntityState);

export const selectAllApplications = createSelector(selectApplicationEntityState, selectAll);

export const selectJobListingId = getJobListingId;

export const selectApplication = (applicationId) =>
  createSelector(selectAllApplications, getApplication(applicationId));

export const selectAllPendingApplications = createSelector(
  selectAllApplications,
  getPendingApplications
);

export const selectAllPendingApplicationsAlerts = createSelector(
  selectAllApplications,
  selectAllApplicationStatuses,
  (allApplications, applicationStatuses) =>
    getPendingApplicationsAlerts(allApplications, applicationStatuses)
);

export const selectApplicationsByJobListingIds = (jobListingIds: number[]) =>
  createSelector(selectAllApplications, getByJobListingIds(jobListingIds));

export const applicationPaginationSelectors = paginationAdapter.paginationSelectors(
  selectApplicationState,
  loadingAdapter,
  'pagination',
  'entityState',
  false
);

export const loadingStateSelectors = loadingAdapter.getSelectors(selectApplicationState);
