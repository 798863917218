import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { AttributeStateService } from '@locumsnest/core/src';
import { filterNonEmpty } from '@locumsnest/core/src/lib/ngrx/operators';

import { IRevalidationStatusEntity } from '../../interfaces/api/revalidation-status-entity';
import { SetCollectionMessage } from './revalidation-status.messages';
import { RevalidationStatusPersistenceService } from './revalidation-status.persistence.service';
import {
  selectAllRevalidationStatuses,
  selectRevalidationStatus,
} from './revalidation-status.selectors';

@Injectable({
  providedIn: 'root',
})
export class RevalidationStatusService extends AttributeStateService<IRevalidationStatusEntity> {
  constructor(
    private store: Store,
    protected persistenceService: RevalidationStatusPersistenceService
  ) {
    super();
  }
  getAll() {
    return this.store.pipe(select(selectAllRevalidationStatuses), filterNonEmpty);
  }
  getRevalidationStatus(val: number) {
    return this.store.pipe(select(selectRevalidationStatus(val)));
  }

  fetch() {
    return this.persistenceService
      .retrieve()
      .pipe(map((results) => new SetCollectionMessage({ entities: results })));
  }
}
