import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IExternalStaffingCandidateDocumentEntity } from '../../interfaces/api/external-staffing-candidate-document-entity';

@Injectable({
  providedIn: 'root',
})
export class ExternalStaffingCandidateDocumentPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IExternalStaffingCandidateDocumentEntity
> {
  protected readonly endpoint = 'externalStaffingCandidateDocument';
}
