import { Injectable } from '@angular/core';
import { LocumsNestEndpointConfig } from 'apps/hospital-admin/src/app/core/constants';
import { IProfileAssignmentNumbersEntity } from 'apps/hospital-admin/src/app/interfaces/api/profile-assignment-numbers-entity';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

@Injectable({
  providedIn: 'root',
})
export class ProfileAssignmentNumbersPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IProfileAssignmentNumbersEntity
> {
  protected override readonly endpoint = 'profileAssignmentNumbers';
}
