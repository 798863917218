import { IAdjacentApplicationEntity } from './../../interfaces/api/application-entity';
import {
  createEntityAdapter,
  EntityAdapter,
  index,
} from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IApplicationEntity } from '../../interfaces/api/application-entity';
import {
  MessageableFactory,
  SignalableFactory,
  createPaginatedStateAdapter,
} from '@locumsnest/core/src';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';
import {
  createSingletonResourceStateAdapter,
  ISingletonResourceAdapter,
} from '@locumsnest/core/src/lib/adapters/singleton-resource-adapter';
import { IMultipleIndexField } from '@locumsnest/core/src/lib/ngrx/interfaces';

export const LISTING_INDEX: IMultipleIndexField = {
  fieldName: 'listing',
  unique: false,
  reAssignMent: false,
};
const indexEntity = index([LISTING_INDEX]);
export const adapter: EntityAdapter<IApplicationEntity> = createEntityAdapter<IApplicationEntity>(
  {},
  indexEntity
);

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedApplicationId: null,
  indexes: {},
});

export const messageableFactory = MessageableFactory.forFeature<'Application'>('Application');
export const signalableFactory = SignalableFactory.forFeature<'Application'>('Application');
export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const loadingAdapter = createLoadingStateAdapter<'Application'>(messageableFactory);

export const adjacentApplicationsAdapter: ISingletonResourceAdapter<
  string,
  IAdjacentApplicationEntity[]
> = createSingletonResourceStateAdapter<'Application', string, IAdjacentApplicationEntity[]>(
  messageableFactory,
  'adjacentApplicationsState'
);
