import { selectAll } from './revalidation-status.reducer';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import {
  IRevalidationStatusFeatureState,
  IRevalidationStatusSharedState,
  IRevalidationStatusEntityState,
} from './interfaces';
import { IRevalidationStatusEntity } from '../../interfaces/api/revalidation-status-entity';

export const getEntityState = (state: { entityState: IRevalidationStatusEntityState }) =>
  state.entityState;

export const getRevalidationStatus = (val: number) => (statuses: IRevalidationStatusEntity[]) =>
  statuses.find((status) => status.val === val);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectRevalidationStatusState =
  createFeatureSelector<IRevalidationStatusFeatureState & IRevalidationStatusSharedState>(
    'revalidationStatuses'
  );

export const selectRevalidationStatusEntityState = createSelector(
  selectRevalidationStatusState,
  getEntityState
);
export const selectAllRevalidationStatuses = createSelector(
  selectRevalidationStatusEntityState,
  selectAll
);

export const selectRevalidationStatus = (statusVal) =>
  createSelector(selectAllRevalidationStatuses, getRevalidationStatus(statusVal));
