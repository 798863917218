import { FormGroupState } from 'ngrx-forms';
import { createSelector } from '@ngrx/store';
import { IAlertSearchFilterFormState } from '../interfaces/alert-search-filter-form-state';
import { State } from '../interfaces';
import { selectApplicationState } from '../application.selectors';

export const getAlertSearchFilterFormState = (state: State) => state.alertSearchFilterFormState;

const getSearchKeyword = (formState: FormGroupState<IAlertSearchFilterFormState>) =>
  formState.value.searchKeyword;

const getSortedField = (formState: FormGroupState<IAlertSearchFilterFormState>) =>
  formState.value.sortedField;

export const selectAlertSearchFilterFormState = createSelector(
  selectApplicationState,
  getAlertSearchFilterFormState
);

export const selectSearchKeyword = createSelector(
  selectAlertSearchFilterFormState,
  getSearchKeyword
);

export const selectSortedField = createSelector(selectAlertSearchFilterFormState, getSortedField);
