import { MessageableFactory } from '@locumsnest/core/src';
import { SignalableFactory } from '@locumsnest/core/src';
import { createExpandableStateAdapter } from '@locumsnest/core/src/lib/adapters/expandable-state-adapter';

export const messageableFactory = MessageableFactory.forFeature<'JobListingSearchFilterForm'>(
  'JobListingSearchFilterForm'
);
export const signalableFactory = SignalableFactory.forFeature<'JobListingSearchFilterForm'>(
  'JobListingSearchFilterForm'
);

export const expandableStateAdapter = createExpandableStateAdapter<'JobListingSearchFilterForm'>(
  signalableFactory,
  messageableFactory
);
export const { ToggleExpandableSignal, SetExpandExpandableSignal } =
  expandableStateAdapter.getSignals();
